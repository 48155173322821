<template>
  <div class="podiumArea" v-if=" aAdDoc.getADCatsInUnivers() === 'DefiPodometre'">
    <div class="subtitle" v-if="ADColPodiums && ADColPodiums.length > 0">{{ t("podiumTitle") }}</div>
    <div v-for="aTransaction in sortAndAddFootStepsPodium(ADColPodiums)"
        :key="aTransaction.ATId"
        class="podiumItem">
            <span>

              <AdDocViewTransactionFootStep :aAdDoc="aAdDoc" :aAdTransaction="aTransaction" :footPodium="aTransaction.ATPodium">
              </AdDocViewTransactionFootStep>
              <MiniProfileViewLink :userMiniProfile="aTransaction.ATMUser"
                  :prefixTextId="''"
                  :displayAppBoss="false">ADColTransaction
              </MiniProfileViewLink>

            </span>
      </div>


  </div>
</template>

<script lang="ts">
import { inject } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { useDocumStore } from '@/engineproject/store/documStore';
import { useAppStore } from '@/engineproject/store/appStore';
import { useUserStore } from '@/engineproject/store/userStore';

import MiniProfileViewLink from '@/engineproject/components/UserProfile/MiniProfileViewLink.vue';
import AdDocViewTransactionFootStep from '@/engineproject/components/AdDocTransaction/AdDocViewTransactionFootStep.vue';
import { AdDocumentProjectTypes } from "@/engineproject/default/common/domainModel/adDocumentProjectTypes";


export default {
  props: {
    ADColPodiums: {
      type: Object as () => Array<any>,
      required: false,
    },
    aAdDoc: {
      type: Object as () => Array<any>,
      required: true,
    },
    userIsOwner: {
      type: Boolean,
      required: true,
    },
    userCanEdit: {
      type: Boolean,
      required: true,
    },
  },
  setup(props: any, context: any) {
    const { t } = useI18n();

    const sortAndAddFootStepsPodium = (colTransactions: AdDocumentProjectTypes.AdTransaction[]) => {
      return colTransactions.sort((tA, tB) => {
        if (!tA.ATFootStepCount && tB.ATFootStepCount) {
          return 1
        }
        // if (!tB.ATFootStepCount) {
        //   return -1
        // }
        return (tB.ATFootStepCount - tA.ATFootStepCount)
      }).map((tr, index, arr) => {
        const firstWithStepCountEqualTo = arr.findIndex((trInArray: AdDocumentProjectTypes.AdTransaction) => {
          return (trInArray.ATFootStepCount === tr.ATFootStepCount)
        })
        const podium = firstWithStepCountEqualTo + 1
        return {
          ...tr,
          ATPodium: podium,
        }
      })
    }
    return {
      sortAndAddFootStepsPodium,
      t,
    };
  },
  components: { 
    MiniProfileViewLink,
    AdDocViewTransactionFootStep,
  },
};
</script>
<style lang="scss" scoped>
.podiumItem {
  margin: 4px;
  border: 2px solid #4472c4;
  padding: 4px;
  border-radius: 12px;
}
.podiumArea {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createBlock(_component_AutoComplete, {
    modelValue: $setup.selectedCountry1,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedCountry1) = $event)),
    inputClass: "inputClass",
    "data-cy": "cyGeoFromZipCodeInput",
    suggestions: $setup.filteredCountries,
    onComplete: _cache[1] || (_cache[1] = ($event: any) => ($setup.searchCountry($event))),
    field: $setup.getZip,
    onClear: _cache[2] || (_cache[2] = ($event: any) => ($setup.removeLocation())),
    delay: 800,
    minLength: 2,
    onItemSelect: _cache[3] || (_cache[3] = ($event: any) => ($setup.itemSelect($event)))
  }, {
    item: _withCtx((slotProps) => [
      _createElementVNode("div", null, _toDisplayString(slotProps.item["place name"]) + " " + _toDisplayString(slotProps.item["postal code"]), 1)
    ]),
    _: 1
  }, 8, ["modelValue", "suggestions", "field"]))
}
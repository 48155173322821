
import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import {allFiltersConfig, filtersForEachTheme} from '@/engineproject/default/common/store/filterAttributesConfig'
import { UserProjectTypes } from '../default/common/domainModel/userProjectTypes'
import router from '@/router'
import { UserState } from './userStore'
import { UserTypes } from '../domainModel/userTypes'
import { EnterpriseInfoTypes } from '../domainModel/enterpriseInfoTypes'


export interface FilterObject {
  filterName: string;
  filterValues: Array<any>;
  filterSelected: string | undefined;
  filterDataNameInDoc: string; 
  // ProductLocationOrOwner ProductSaleType - ProductCategories - ProductSize - ProductFormat - ProductState - ProductDistributionMode
}

export interface CalendarFilterObject {
  calendarFilterDay365: Array<number>;
  calendarFilterSoiree: boolean;
  calendarFilterWeekend: boolean;
}

export interface FilterAttributesState {
  allFilters: Array<FilterObject>;
  searchLocationFilter: UserProjectTypes.LocationPoint | undefined;
  searchDistanceInMFilter: number;
  searchFileTypeFilter: string;
  searchCalendarFilter: CalendarFilterObject;
  searchEnterpriseFilter: EnterpriseInfoTypes.EnterpriseInfo | undefined;
  searchIsUSerEnterprise: boolean;
  oneOrMoreSearchFilterUpdated: boolean;
}


export const getOneOfAllFilters = (state: FilterAttributesState, filterName: string) => {
  let ret = {
    filterName: "",
    filterValues: [],
    filterSelected: undefined,
    filterDataNameInDoc: ""
  } as FilterObject
  state.allFilters.forEach((aFilterObject: FilterObject) => {
    if (aFilterObject.filterName === filterName) {
      ret = aFilterObject
      if (filterName === "allLocalMyFilter" && ret.filterSelected === undefined) {
        ret.filterSelected = "allAdDocs" // Default filter is 1 // TODO Put this in a config
      }
    }
  })
  return ret
}

export const getOneOfAllFiltersValues = (state: FilterAttributesState, filterName: string) => {
  //let ret
  const aFilterObject = getOneOfAllFilters(state, filterName)
  return aFilterObject.filterValues
}

const addNewValueInFirebaseFilters = (firebaseFilters: any, filterDataNameInDoc: string, newValue: string) => {
  if (firebaseFilters[filterDataNameInDoc] === undefined) {
    firebaseFilters[filterDataNameInDoc] = [newValue]
  } else {
    firebaseFilters[filterDataNameInDoc].push(newValue)
  }
}

export const getUniversAndCatFilter = (state: FilterAttributesState) => {
  const firebaseFilters = {} //[] as Array<Array<string>>
  try {
    const currentUnivers = getOneOfAllFilters(state, "universFilter")
    if (currentUnivers !== undefined && currentUnivers.filterSelected !== undefined
      && currentUnivers.filterSelected !== "") {
      //firebaseFilters.push([currentUnivers.filterDataNameInDoc, "==",  currentUnivers.filterSelected])
      addNewValueInFirebaseFilters(firebaseFilters, currentUnivers.filterDataNameInDoc, currentUnivers.filterSelected)
      if (filtersForEachTheme !== undefined && filtersForEachTheme[currentUnivers.filterSelected] !== undefined) {
        filtersForEachTheme[currentUnivers.filterSelected].forEach((aFilter: FilterObject) => {
          if (aFilter.filterSelected !== undefined && aFilter.filterSelected !== "") {
            //firebaseFilters.push([aFilter.filterDataNameInDoc, "==",  aFilter.filterSelected])
            addNewValueInFirebaseFilters(firebaseFilters, aFilter.filterDataNameInDoc, aFilter.filterSelected)
          } else {
            aFilter.filterValues.forEach((curr) => {
              if (curr.checked === true) {
                addNewValueInFirebaseFilters(firebaseFilters, aFilter.filterDataNameInDoc, curr.id)
              }
            })
          }
        }); 
      } else {
        console.error("filtersForEachTheme undefined: " + currentUnivers.filterSelected)
      }
    }
  } catch(e) {
    console.error("getUniversAndCatFilter error: " + e)
  }

  return firebaseFilters

}


export const getDisplayMapCenter = (filterAttributesStoreState: FilterAttributesState, 
  userStoreState: UserState) => {
  if (((getOneOfAllFilters(filterAttributesStoreState, "allLocalMyFilter")).filterSelected === "allLocalAdDocs") === false) {
    return undefined
  }
    const location = (filterAttributesStoreState.searchLocationFilter) ? 
    filterAttributesStoreState.searchLocationFilter :
    userStoreState.aUserProfile.getUPLocation() 
  if (location !== undefined) {
    return { lat: location?.LPLat, lng: location?.LPLng }
  }
  return undefined
}


export const filterAttributesKey: InjectionKey<Store<FilterAttributesState>> = Symbol()

// define your own `useStore` composition function
export function useFilterAttributesStore () {
  return baseUseStore(filterAttributesKey)
}
export const filterAttributesStore = createStore<FilterAttributesState>({
  state: {
    allFilters: allFiltersConfig,
    searchLocationFilter: undefined,
    searchDistanceInMFilter: 5000,
    searchFileTypeFilter: "",
    searchCalendarFilter: {
      calendarFilterDay365: [],
      calendarFilterSoiree: false,
      calendarFilterWeekend: false,
    },
    searchEnterpriseFilter: undefined,
    searchIsUSerEnterprise: false,
    oneOrMoreSearchFilterUpdated: false,
  },
  mutations: {
    setOneOfAllFilters(state, {filterName, translatedAsset}) {
      // console.error("setOneOfAllFilters commit " + filterName + " ===== " + translatedAsset)
      state.allFilters.forEach((aFilterObject) => {
        if (aFilterObject.filterName === filterName) {
          aFilterObject.filterValues = translatedAsset
        }
      })
      state.oneOrMoreSearchFilterUpdated = !state.oneOrMoreSearchFilterUpdated

    },
    resetAllFilters(state, {filterName, newSelectedValue, adDocFileType}) {
      // console.error("resetAllFilters commit " + filterName + " ===== " + newSelectedValue)
      state.allFilters.forEach((aFilterObject) => {
        if (aFilterObject.filterName === filterName) {
          aFilterObject.filterSelected = newSelectedValue
        }
      })
      state.searchFileTypeFilter = adDocFileType
      state.oneOrMoreSearchFilterUpdated = !state.oneOrMoreSearchFilterUpdated
    },
    setSelectedOneOfAllFilters(state, {filterName, newSelectedValue}) {
      // console.error("setSelectedOneOfAllFilters commit " + filterName + " ===== " + newSelectedValue)
      state.allFilters.forEach((aFilterObject) => {
        if (aFilterObject.filterName === filterName) {
          aFilterObject.filterSelected = newSelectedValue
        }
      })
      state.oneOrMoreSearchFilterUpdated = !state.oneOrMoreSearchFilterUpdated

    },
    setSearchSuggestionFilter(state, {adDocFileType}) {
      state.searchFileTypeFilter = adDocFileType
    },
    changeOneOrMoreSearchFilterUpdated(state) {
      state.oneOrMoreSearchFilterUpdated = !state.oneOrMoreSearchFilterUpdated
    },
    setSearchLocationFilter(state, {searchLocationFilter}) {
      state.searchLocationFilter = searchLocationFilter
      state.oneOrMoreSearchFilterUpdated = !state.oneOrMoreSearchFilterUpdated
    },
    searchDistanceInMFilter(state, {searchDistanceInMFilter}) {
      state.searchDistanceInMFilter = searchDistanceInMFilter
      state.oneOrMoreSearchFilterUpdated = !state.oneOrMoreSearchFilterUpdated
    },
    setSearchCalendarFilter(state, searchCalendarFilter) {
      state.searchCalendarFilter = searchCalendarFilter
      state.oneOrMoreSearchFilterUpdated = !state.oneOrMoreSearchFilterUpdated
    },
    setSearchEnterpriseFilter(state, { newEnterprise, isUSerEnterprise}) {
      state.searchEnterpriseFilter = newEnterprise
      state.searchIsUSerEnterprise = isUSerEnterprise
      state.oneOrMoreSearchFilterUpdated = !state.oneOrMoreSearchFilterUpdated
    },
  },
  getters: {
    locationFilterLatLng: (state, getters) => {
      if (state.searchLocationFilter !== undefined &&
        state.searchLocationFilter.LPLat !== undefined  &&
        state.searchLocationFilter.LPLng !== undefined &&
        state.searchLocationFilter.LPLat !== 0  &&
        state.searchLocationFilter.LPLng !== 0 ) {
          return [state.searchLocationFilter.LPLat, state.searchLocationFilter.LPLng]
        }
      return undefined
    },
    universAndCatFilter: (state) => {
      return getUniversAndCatFilter(state)
      
    },
    getAllFiltersForCurrentTheme: (state) => {
      const currentTheme = getOneOfAllFilters(state, "universFilter").filterSelected
      if (currentTheme !== undefined) {
        return filtersForEachTheme[currentTheme] 
      }
      return []
    },
    getAllowedThemeFiltersForTheDoc: (state) => (aUnivers: string) => {
      if (aUnivers !== undefined) {
        return filtersForEachTheme[aUnivers] 
      }
      return []
    }

  },
  actions: {
    async initFilterAttributesStore({ state, commit }, langObject) {
      const translateArrayFilter = (fObj: FilterObject, commitAction: string) => {
        const translatedAsset = Array<any>()
        //console.error("fObj.filterValues " + JSON.stringify(fObj.filterValues))
        fObj.filterValues.forEach((assetObj) => {
          //console.error("assetObj.id " + assetObj.id )
          //console.error("assetObj.id   t " + langObject.t(assetObj.id))
          translatedAsset.push({
            ...assetObj,
            lang: langObject.t(assetObj.id)
          })
        })
        
        commit(commitAction, {
          filterName: fObj.filterName,
          translatedAsset
        })
      }
      state.allFilters.forEach((aFilterArray) => {
        translateArrayFilter(aFilterArray, "setOneOfAllFilters")
      })
      // TODO, the next call here is only used to init allLocalMyFilter to it's default 
      // filterSelected = "allAdDocs"
      const test = getOneOfAllFilters(state, "allLocalMyFilter")
      // console.error("currentFilterAttributes " + JSON.stringify(test))
    },
    async initFilterAttributesFromBackend({ dispatch, commit }, form) {
      //test
    },
    async filterHasChanged({ dispatch, commit }) {
      commit("changeOneOrMoreSearchFilterUpdated")
    },
    async updateCalendarFilterDay365({ state, commit },  {in365Days, actionUpdate}) {
      const removeANumberFromArray = (aNumber: number, anArray: Array<number>) => {
        return anArray.reduce((prev: Array<number>, curr: number) => {
          if (curr !== aNumber) {
            prev.push(curr)
          }
          return prev
        }, Array<number>())
      }
      const newFilter =  {...state.searchCalendarFilter}
      console.error("newFilter 1 " + actionUpdate + " " + JSON.stringify(newFilter))
      newFilter.calendarFilterDay365 = removeANumberFromArray(in365Days, newFilter.calendarFilterDay365)
      console.error("newFilter 2 " + JSON.stringify(newFilter))
      if (actionUpdate === "A") {
        console.error("Add this day in array")
        newFilter.calendarFilterDay365.push(in365Days)
      } else {
        console.error("Remove this day in array")
      }      
      commit("setSearchCalendarFilter", newFilter);
    },
    async swapCalendarFilterSoiree({ state, commit }) {
      const newFilter = {...state.searchCalendarFilter}
      newFilter.calendarFilterSoiree = !newFilter.calendarFilterSoiree
      commit("setSearchCalendarFilter", newFilter);
    },
    async swapCalendarFilterWeekend({ state, commit }) {
      const newFilter =  {...state.searchCalendarFilter}
      newFilter.calendarFilterWeekend = !newFilter.calendarFilterWeekend
      commit("setSearchCalendarFilter", newFilter);
    },
    async goToDashboardWithLastFilters({ state, commit }) {
      const currentFilterAttributes = getOneOfAllFilters(state, "allLocalMyFilter")
      router.push('/' + currentFilterAttributes.filterSelected)

    },
    setSelectedOneOfAllFilters({ state, commit }, options) {
      commit("setSelectedOneOfAllFilters", options);
    },
    resetAllFilters({ state, commit }, options) {
      commit("resetAllFilters", options);
    },
  },
  modules: {
  }
})

{
  "updateYourProfile": "Modifier votre profil utilisateur: ",
  "otherActionsOnYourProfile": "Autres actions: ",
  "buttonDefaultLocation": "Géolocalisation",
  "buttonLatLngLocation": "Valider",
  "editLatLngLocationPH": "latitude , longitude",
  "setLocationUsingLatLng": "Ajouter la localisation en utilisant la latitude et longitude",
  "setLocationUsingGeolocation": "Ajouter la localisation en utilisant la géolocalisation",
  "setDefaultLocationForLocalFilter": "Ajouter votre géolocalisation dans votre profil pour pouvoir filtrer localement plus facilement!",
  "setDefaultLocationForLocalFilterLink": "Cliquez ici pour compléter votre profil",
  "editLocationUsingTextSearchPH": "Ou chercher un lieu ...",
  "buttonRemoveDefaultLocation": "Enlever la position",
  "openLocationInMap": "Voir la position sur la carte",
  "openLocationInMapShort": "Localiser: ",
  "openZipCodeLocationInMap": "Situer le code postal {aZipCode} sur une carte",
  "addPicture": "Ajouter une image",
  "replacePicture": "Changer l'image",
  "removePicture": "Enlever l'image",

  "closeLink": "Fermer ",

  "removeCommentTitle": "Supprimer le commentaire",

  "openProfileButtonText": "Contacter",
  "openProfile": "Afficher le profil"
}

import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "userProfileViewContent" }
const _hoisted_2 = {
  key: 0,
  class: "profilImgContainer"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 2,
  class: "appBossArea"
}
const _hoisted_6 = { class: "logoAppBossContainer" }
const _hoisted_7 = ["title"]
const _hoisted_8 = {
  key: 3,
  class: "appBossArea"
}
const _hoisted_9 = { class: "logoAppBossContainer" }
const _hoisted_10 = ["title"]
const _hoisted_11 = { key: 4 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 2 }
const _hoisted_16 = ["href"]
const _hoisted_17 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhoneNumberLinks = _resolveComponent("PhoneNumberLinks")!
  const _component_UserProfileViewTransactionsWithMe = _resolveComponent("UserProfileViewTransactionsWithMe")!
  const _component_UserProfileBanControl = _resolveComponent("UserProfileBanControl")!
  const _component_UserProfileClosedControl = _resolveComponent("UserProfileClosedControl")!
  const _component_UserProfileExternOfEnterprise = _resolveComponent("UserProfileExternOfEnterprise")!
  const _component_UserProfileAllowedEnterprises = _resolveComponent("UserProfileAllowedEnterprises")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      ($props.aUserProfile.getUPPictureUrl())
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("img", {
              src: $props.aUserProfile.getUPPictureUrl()
            }, null, 8, _hoisted_3)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, _toDisplayString($setup.t("userProfilePseudoTitle")) + _toDisplayString($props.aUserProfile.getUPPseudo()), 1),
      ($props.aUserProfile.isPremiumUser() === true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($setup.t("userProfilePremiumTitle")), 1))
        : _createCommentVNode("", true),
      ($props.aUserProfile.isAppBoss() === true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", null, _toDisplayString($setup.t("userProfileAppBossTitle")) + ": ", 1),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: "logoAppBoss",
                title: $setup.t('appBossTitle')
              }, null, 8, _hoisted_7)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", null, _toDisplayString($setup.t("userProfileWannaBeABossTitle")) + ": ", 1),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", {
                class: "logoAppBossGreenAndRed",
                title: $setup.t('userProfileWannaBeABossTitle')
              }, null, 8, _hoisted_10)
            ])
          ])),
      _createElementVNode("div", null, " Nbre de contacts: " + _toDisplayString($props.aUserProfile.getNbContacts()), 1),
      ($props.aUserProfile.getUPDescription())
        ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString($setup.t("userProfileDescTitle")) + _toDisplayString($props.aUserProfile.getUPDescription()), 1))
        : _createCommentVNode("", true)
    ]),
    ($props.aUserProfile.getUPEmail())
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("h4", null, _toDisplayString($setup.t("userProfilePersonnalSharedDataTitle")), 1),
          ($props.aUserProfile.getUPName())
            ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString($setup.t("userProfileNameTitle")) + " " + _toDisplayString($props.aUserProfile.getUPName()), 1))
            : _createCommentVNode("", true),
          ($props.aUserProfile.getUPPhoneNumber())
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createTextVNode(_toDisplayString($setup.t("userProfilePhoneTitle")) + " " + _toDisplayString($props.aUserProfile.getUPPhoneNumber()) + " ", 1),
                _createVNode(_component_PhoneNumberLinks, {
                  linkToPhoneNumber: $props.aUserProfile.getUPPhoneNumber()
                }, null, 8, ["linkToPhoneNumber"])
              ]))
            : _createCommentVNode("", true),
          ($props.aUserProfile.getUPEmail())
            ? (_openBlock(), _createElementBlock("p", _hoisted_15, [
                _createTextVNode(_toDisplayString($setup.t("userProfileEmailTitle")) + " ", 1),
                _createElementVNode("a", {
                  href: 'mailto:' + $props.aUserProfile.getUPEmail()
                }, _toDisplayString($props.aUserProfile.getUPEmail()), 9, _hoisted_16)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_UserProfileViewTransactionsWithMe, {
            "onRefresh:display": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('refresh:display', $event))),
            myContactUserProfile: $props.aUserProfile
          }, null, 8, ["myContactUserProfile"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("p", null, _toDisplayString($setup.t("userProfileNotInContactListTitle")), 1),
          _createElementVNode("p", null, _toDisplayString($setup.t("userProfileNoFullProfileDataTitle")), 1)
        ])),
    _createVNode(_component_UserProfileBanControl, { aUserProfile: $props.aUserProfile }, null, 8, ["aUserProfile"]),
    _createVNode(_component_UserProfileClosedControl, { aUserProfile: $props.aUserProfile }, null, 8, ["aUserProfile"]),
    _createVNode(_component_UserProfileExternOfEnterprise, { aUserProfile: $props.aUserProfile }, null, 8, ["aUserProfile"]),
    _createVNode(_component_UserProfileAllowedEnterprises, { aUserProfile: $props.aUserProfile }, null, 8, ["aUserProfile"])
  ]))
}
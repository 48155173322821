import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "adDocActionButtons"
}
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.aAdDoc.isTemplate() === true)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "pi pi-plus",
          title: $setup.t('applyModeleToCreateNewAdDoc'),
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.createAdDocUsingTemplate()), ["stop"]))
        }, null, 8, _hoisted_2),
        (1 === 1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "pi pi-clone",
              title: $setup.t('duplicateModeleInYourModeleList'),
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.createAdDocUsingTemplate('T')), ["stop"]))
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
{
  "leftHomeName": "The Engine Project",
  "leftHomeDescription": "The easiest framework to develop vue3.js application connected to Google Cloud",
  "serverConfigStateWarning":"Attention l'application va temporairement passée en maintenance ... Enregistrez votre travail si nécessaire ...",
  "serverConfigStateMaintenance":"L'application est temporairement en maintenance ... Reconnectez vous plus tard!",
  "home": "Accueil",
  "adsListing": "Acheter",
  "adsListingTitle": "Acheter un service",
  "addItem": "Vendre",
  "addItemTitle": "Vendre un service",
  "settings": "Paramètres",
  "settingsMainTitle": "Paramètres dans Engine Project",
  "closingMainTitle": "Clôture de votre compte",
  "closingAboStatusTitle": "Statut de votre abonnement",
  "closingAdDocsStatusTitle": "Statut de vos documents",
  "closingTransactionsStatusTitle": "Statut de vos participations",
  "settingsTitle": "Modifier les données de votre profil utilisateur",
  "myProfile": "Mon profil",
  "myNotifications": "Notifications",
  "myNotificationsTitle": "Afficher vos notifications",
  "invitationWithGainReceived": "Vous avez {invitationsNumber} invitation(s) avec réduction non lue(s)",
  "refreshToSeeNewNotifs": "Cliquez pour actualiser",
  "myAds": "Mes annonces",
  "myCalendar": "Mon agenda",
  "myPromotionUsingQR": "Ma communication",
  "myContributions": "Mes contributions",
  "adminViewProfiles": "Gérer les profils",
  "adminViewEnterprises": "Gérer les entreprises",
  "adminViewMySuggestions": "Mes suggestions (publiées ou non publiées)",
  "adminViewAllSuggestions": "Toutes les suggestions (publiées)",
  "adminAddSuggestions": "Ajouter une suggestion",
  "premiumViewMyModels": "Mes modèles",
  "premiumViewAllPublicModels": "Les idées de modèles",
  "premiumAddModels": "Ajouter un modèle",
  "adminViewOther": "Autres fonctions",
  "adminInfo": "A propos Admin",
  "about": "A propos",
  "mentionsLegales": "Mentions Légales",
  "logout": "Déconnexion",
  "logoutTitle": "Déconnexion de votre compte",
  "newAppVersionMenu": "Installer",
  "adminMode": "Admin",
  "newAppVersionTitle": "Installer la nouvelle version de l'application",
  "newAppVersionNotif": "Nouvelle version de l'application disponible.",
  "newAppVersionNotifLink": "Cliquez sur 'Installer'",
  "newUpdateFoundInfoMsg": "Nouvelle version de l'application en cours de téléchargement ...'",
  "switchAdminModeDescription": "Attention en mode Admin vous pouvez supprimer/modifier les données des autres utilisateurs!"
}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 6 }
const _hoisted_9 = { key: 7 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormatDate = _resolveComponent("FormatDate")!
  const _component_ImagesView = _resolveComponent("ImagesView")!
  const _component_DefaultImagesView = _resolveComponent("DefaultImagesView")!
  const _component_FormatPrice = _resolveComponent("FormatPrice")!
  const _component_GeolocationView = _resolveComponent("GeolocationView")!
  const _component_OwnerProfileViewLink = _resolveComponent("OwnerProfileViewLink")!
  const _component_EnterpriseDisplay = _resolveComponent("EnterpriseDisplay")!
  const _component_CommentsLikes = _resolveComponent("CommentsLikes")!
  const _component_AdDocumentViewMainPartTemplate = _resolveComponent("AdDocumentViewMainPartTemplate")!

  return (_openBlock(), _createBlock(_component_AdDocumentViewMainPartTemplate, {
    displayInModal: $props.displayInModal,
    displayCommentsLikesOnly: false,
    displayFromList: false
  }, {
    content: _withCtx(({ aAdDoc, displayCommentsLikesOnly }) => [
      _createElementVNode("h3", null, [
        (aAdDoc.OADData.ADDateHourStartsOn)
          ? (_openBlock(), _createBlock(_component_FormatDate, {
              key: 0,
              prefix: "Le ",
              inputDate: aAdDoc.OADData.ADDateHourStartsOn
            }, null, 8, ["inputDate"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("h3", null, _toDisplayString(aAdDoc.OADData.ADTitle), 1),
      (aAdDoc.OADData.ADColAttDoc && aAdDoc.OADData.ADColAttDoc.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ImagesView, {
              listOfImages: aAdDoc.OADData.ADColAttDoc
            }, null, 8, ["listOfImages"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_DefaultImagesView, { aAdDoc: aAdDoc }, null, 8, ["aAdDoc"])
          ])),
      _createElementVNode("div", null, _toDisplayString($setup.t("adDocDescriptionTitle")), 1),
      _createElementVNode("p", null, _toDisplayString(aAdDoc.OADData.ADDescription), 1),
      (aAdDoc.OADData.ADDisponibility)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($setup.t("adDocDetailPlacesTitle")) + " " + _toDisplayString(aAdDoc.OADData.ADDisponibility), 1))
        : _createCommentVNode("", true),
      (aAdDoc.OADData.ADIntention || aAdDoc.OADData.ADMixed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createTextVNode(_toDisplayString($setup.t("adDocCreateTabIntentionTitle")) + ": ", 1),
            (aAdDoc.OADData.ADIntention)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                  _createTextVNode(_toDisplayString($setup.t(aAdDoc.OADData.ADIntention)) + " ", 1),
                  (aAdDoc.OADData.ADMixed)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, " - "))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (aAdDoc.OADData.ADMixed)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($setup.t(aAdDoc.OADData.ADMixed)), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (aAdDoc.OADData.ADYourPrice)
        ? (_openBlock(), _createBlock(_component_FormatPrice, {
            key: 4,
            inputPrice: aAdDoc.OADData.ADYourPrice,
            inputPaidByOwner: aAdDoc.OADData.ADPercentagePaidByOwner,
            inputPriceLabel: 
          aAdDoc.isFromPremiumAccount() === true
            ? 'adDocPremiumPrice'
            : 'adDocNormalPrice'
        
          }, null, 8, ["inputPrice", "inputPaidByOwner", "inputPriceLabel"]))
        : _createCommentVNode("", true),
      (aAdDoc.OADData.ADDateHourEndsOn)
        ? (_openBlock(), _createBlock(_component_FormatDate, {
            key: 5,
            prefix: "Termine le:",
            inputDate: aAdDoc.OADData.ADDateHourEndsOn
          }, null, 8, ["inputDate"]))
        : _createCommentVNode("", true),
      (displayCommentsLikesOnly === true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", null, [
              _createVNode(_component_GeolocationView, {
                aLocation: aAdDoc.OADData.ADLocation
              }, null, 8, ["aLocation"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_OwnerProfileViewLink, {
                ownerMiniProfile: aAdDoc.OADData.ADOwnerMiniProfile
              }, null, 8, ["ownerMiniProfile"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_EnterpriseDisplay, {
                displayNoEnterprise: false,
                aEnterprise: aAdDoc.OADData.ADEnterprise
              }, null, 8, ["aEnterprise"])
            ]),
            _createElementVNode("div", null, _toDisplayString($setup.t("itemPublishedOn")) + " " + _toDisplayString($setup.formatDate(aAdDoc.OADData.ADCreatedOn)), 1),
            (aAdDoc.canHaveLikesAndComments() === true)
              ? (_openBlock(), _createBlock(_component_CommentsLikes, {
                  key: 0,
                  aAdDoc: aAdDoc,
                  dataCYClass: "InDetailView"
                }, null, 8, ["aAdDoc"]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_9, [
            (aAdDoc.canHaveLikesAndComments() === true)
              ? (_openBlock(), _createBlock(_component_CommentsLikes, {
                  key: 0,
                  aAdDoc: aAdDoc,
                  dataCYClass: "InDetailView"
                }, null, 8, ["aAdDoc"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createVNode(_component_GeolocationView, {
                aLocation: aAdDoc.OADData.ADLocation
              }, null, 8, ["aLocation"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_OwnerProfileViewLink, {
                ownerMiniProfile: aAdDoc.OADData.ADOwnerMiniProfile
              }, null, 8, ["ownerMiniProfile"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_EnterpriseDisplay, {
                displayNoEnterprise: false,
                aEnterprise: aAdDoc.OADData.ADEnterprise
              }, null, 8, ["aEnterprise"])
            ]),
            _createElementVNode("div", null, _toDisplayString($setup.t("itemPublishedOn")) + " " + _toDisplayString($setup.formatDate(aAdDoc.OADData.ADCreatedOn)), 1)
          ]))
    ]),
    _: 1
  }, 8, ["displayInModal"]))
}
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-cy": "cyTransactionsLoading"
}
const _hoisted_2 = {
  key: 1,
  "data-cy": "cyTransactionsLoaded"
}
const _hoisted_3 = { class: "subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.aAdDoc.geTransactionsIsLoading() === true )
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.t('transactionIsLoadingText')), 1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString($setup.t("itemTransactions")), 1),
        _createTextVNode(" " + _toDisplayString($setup.t("itemTransactionsEmpty")), 1)
      ]))
}
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "filter-container" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.filterTitle && _ctx.filterTitle !== '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.t(_ctx.filterTitle)), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_Dropdown, {
      modelValue: _ctx.newcurrentFilterAttributesObjectsFilterSelected.filterSelected,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newcurrentFilterAttributesObjectsFilterSelected.filterSelected) = $event)),
      options: _ctx.newcurrentFilterAttributesObjects,
      optionLabel: "lang",
      optionValue: "id"
    }, null, 8, ["modelValue", "options"])
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-568958f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { "data-cy": "" }
const _hoisted_2 = {
  key: 0,
  class: "pi pi-check requiredState"
}
const _hoisted_3 = { "data-cy": "" }
const _hoisted_4 = { "data-cy": "" }
const _hoisted_5 = { "data-cy": "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterMyAds = _resolveComponent("FilterMyAds")!
  const _component_FilterGeolocalisation = _resolveComponent("FilterGeolocalisation")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_FilterCheckbox = _resolveComponent("FilterCheckbox")!
  const _component_FilterSelect = _resolveComponent("FilterSelect")!
  const _component_FilterUsingCalendar = _resolveComponent("FilterUsingCalendar")!
  const _component_FilterEnterpriseAds = _resolveComponent("FilterEnterpriseAds")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_FilterAdDocNavTemplate = _resolveComponent("FilterAdDocNavTemplate")!

  return (_openBlock(), _createBlock(_component_FilterAdDocNavTemplate, null, {
    filterContent: _withCtx(({}) => [
      _createVNode(_component_Accordion, {
        activeIndex: $setup.pageActive,
        multiple: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AccordionTab, null, {
            header: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString($setup.t('filterLocalisationAccordeonTitle')), 1),
              (_ctx.fGeoOn)
                ? (_openBlock(), _createElementBlock("i", _hoisted_2))
                : _createCommentVNode("", true)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_FilterMyAds, { adDocFileType: $props.adDocFileType }, null, 8, ["adDocFileType"]),
              _createVNode(_component_FilterGeolocalisation)
            ]),
            _: 1
          }),
          _createVNode(_component_AccordionTab, null, {
            header: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, _toDisplayString($setup.t('filterCategoriesAccordeonTitle')), 1)
            ]),
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentThemeFilters, (aFilter) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "pContainer",
                  key: aFilter.filterName
                }, [
                  (aFilter.filterDataNameInDoc == 'ADCatsInUnivers')
                    ? (_openBlock(), _createBlock(_component_FilterCheckbox, {
                        key: 0,
                        stateObject: aFilter.filterName
                      }, null, 8, ["stateObject"]))
                    : _createCommentVNode("", true),
                  (aFilter.filterDataNameInDoc != 'ADCatsInUnivers')
                    ? (_openBlock(), _createBlock(_component_FilterSelect, {
                        key: 1,
                        stateObject: aFilter.filterName
                      }, null, 8, ["stateObject"]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_AccordionTab, null, {
            header: _withCtx(() => [
              _createElementVNode("span", _hoisted_4, _toDisplayString($setup.t('filterDatesAccordeonTitle')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_FilterUsingCalendar, { titleTag: "" })
            ]),
            _: 1
          }),
          (_ctx.canSelectEntreprise)
            ? (_openBlock(), _createBlock(_component_AccordionTab, { key: 0 }, {
                header: _withCtx(() => [
                  _createElementVNode("span", _hoisted_5, _toDisplayString($setup.t('filterEnterpriseAccordeonTitle')), 1)
                ]),
                default: _withCtx(() => [
                  (($props.adDocFileType !== 'T' && $props.adDocFileType !== 'S'))
                    ? (_openBlock(), _createBlock(_component_FilterEnterpriseAds, {
                        key: 0,
                        titleTag: ""
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["activeIndex"])
    ]),
    _: 1
  }))
}
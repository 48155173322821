import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "appContainer"
}
const _hoisted_2 = {
  key: 1,
  class: "hidden",
  "data-cy": "cyNewAppVersionIsComing"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppServerConfigInfo = _resolveComponent("AppServerConfigInfo")!
  const _component_AdDocDisplayMapInModal = _resolveComponent("AdDocDisplayMapInModal")!
  const _component_ProjectSiteNav = _resolveComponent("ProjectSiteNav")!
  const _component_AdDocumentViewModalContainer = _resolveComponent("AdDocumentViewModalContainer")!
  const _component_ProfilesListModalContainer = _resolveComponent("ProfilesListModalContainer")!
  const _component_UserProfileModalContainer = _resolveComponent("UserProfileModalContainer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BlockAppUI = _resolveComponent("BlockAppUI")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppServerConfigInfo),
    _createVNode(_component_AdDocDisplayMapInModal),
    (_ctx.appServerConfigIsLocked === false)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            id: "loadingMsg",
            class: _normalizeClass({ loadingModal: true, locked: $setup.loadingMsg != '' }),
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.removeLoadingModal()))
          }, null, 2),
          _createVNode(_component_ProjectSiteNav, {
            OnSmallScreenOnly: false,
            OnLargeScreenOnly: true
          }),
          _createVNode(_component_AdDocumentViewModalContainer),
          _createVNode(_component_ProfilesListModalContainer),
          _createVNode(_component_UserProfileModalContainer),
          _createVNode(_component_router_view),
          _createVNode(_component_ProjectSiteNav, {
            OnSmallScreenOnly: true,
            OnLargeScreenOnly: false
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.newAppVersionIsComing === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " . "))
      : _createCommentVNode("", true),
    _createVNode(_component_BlockAppUI)
  ], 64))
}
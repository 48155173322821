<template>
  <div class="c-modal">
    <div class="c-container">
      <div class="headerLinks">
        <a @click="$emit('close')" class="closeComment">{{ t("closeAdDocument") }}</a>
      </div>
      <p>{{ t("addReportDetails") }}</p>
      <form @submit.prevent>
        <textarea v-model.trim="comment"></textarea>
        <button @click="sendReport()" :disabled="comment == ''" class="button">{{ t("sendReport") }}</button>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { AdDocumentProjectTypes } from '@/engineproject/default/common/domainModel/adDocumentProjectTypes';

import { reactive, toRefs } from "vue";
import { useUserStore } from '@/engineproject/store/userStore'
import { useAppStore } from '@/engineproject/store/appStore'
import { useI18n } from '@/engineproject/default/common/i18n';

export default {
  props: {
    aAdDoc: {
      type: Object as () => AdDocumentProjectTypes.AdDocument,
      required: true
    }
  },
  setup(props: any, context: any) {
    const userStore = useUserStore()
    const appStore = useAppStore()
    const {t} = useI18n()
    const page = reactive({
      comment: '',
      ACContentAdded: false,
    });

    const sendReport = async () => {
      // create comment
      if (props.aAdDoc === undefined || page.comment === "") {
        return
      }
      const res = await userStore.dispatch('sendReportAboutAdDoc', {
        aAdDoc: props.aAdDoc,
        ACContent: page.comment,
        appStore: appStore
      })
      if (res === true) {
        context.emit('close')
      }
    }

    return {
      ...toRefs(page),
      t,
      sendReport,
    }
  },
}


</script>

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "banUserArea inlineSeparatedBlockContainer adminSuperPower" }
const _hoisted_2 = {
  key: 0,
  class: "inline"
}
const _hoisted_3 = { class: "inlineInput" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormatDate = _resolveComponent("FormatDate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loggedUserProfile.isAdmin() === true && _ctx.userClosed === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", _hoisted_3, _toDisplayString($setup.t('closedUser')), 1),
          _createElementVNode("div", null, [
            _createVNode(_component_FormatDate, {
              prefix: "Le ",
              inputDate: $props.aUserProfile.getClosedDate() 
            }, null, 8, ["inputDate"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
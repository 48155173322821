<template>
  <div v-if="moreElementsInCollection === true" class="listNavBottomMenu">
    <a v-observe-visibility="visibilityChanged" @click="$emit('nextSubList')" :title="t('moreLinkTitle')">{{ t("moreLink") }}</a>
  </div>
  <div v-else>
    <AdDocListNavCallToAction :adDocFileType="adDocFileType"></AdDocListNavCallToAction>
  </div>
</template>

<script lang="ts">
import { computed, reactive, toRefs } from 'vue';
import router from '@/router';
import { useI18n } from '@/engineproject/default/common/i18n';
import { useDocumStore } from '@/engineproject/store/documStore';

import AdDocListNavCallToAction from "@/engineproject/components/AdDocList/AdDocListNavCallToAction.vue";

export default {
  props: {
    adDocFileType: {
      type: String,
      required: false,
      default: ""
    },
    aAdDocSubList: {
      type: Object as () => Array<any>,
      required: true
    },
  },
  emits: ["nextSubList"],
  setup(props: any, context: any) {
    const documStore = useDocumStore();
    const {t} = useI18n()
    const page = reactive({
      moreElementsInCollection: computed(() => documStore.state.moreElementsInCollection),
    });

    const openPropose = () => {
      router.push("/add")
    }

    const askCoaching = () => {
      window.location.href = "mailto:askToCoach@alomigo.com?subject=Besoin d'un coach pour ...";
    }

    const visibilityChanged = (isVisible: boolean, entry: any) => {
      if (isVisible === true) {
        context.emit('nextSubList')
      }
    }
      return {
        ...toRefs(page),
        t,
        openPropose,
        askCoaching,
        visibilityChanged,
      }
  },
  components: { 
    AdDocListNavCallToAction,
  },
}


</script>


import { firebaseProjectConfig } from "@/projects/firebaseProjectUsedByDefault"
// import {du, wikimediaSubDir} from "@/engineproject/store/filterAttributesTools"


//const du = "https://firebasestorage.googleapis.com/v0/b/alomigoid.appspot.com/o/DefaultImages%2Fwikimedia.org%2F"
const wikimediaSubDir = "/"
const duUsingProjectConfig = "@/engineproject/default/common/assets/svg/univers/"
const duniSport = "uniSport" + wikimediaSubDir
const duniEntertainment =  "uniEntertainment" + wikimediaSubDir
const duniTravel = duUsingProjectConfig + "uniTravel" + wikimediaSubDir
const duniSocial = duUsingProjectConfig + "uniSocial" + wikimediaSubDir
const duniOther = duUsingProjectConfig + "uniOther" + wikimediaSubDir
import { faRunning , faFutbol, faTheaterMasks, faPizzaSlice, 
  faGlassCheers, faGlassMartiniAlt, faMask,
  faHiking, faBiking,
  faChild, faUsers, faVolleyballBall, faTableTennis, faBowlingBall,
  faCoffee,
  faHandHoldingHeart, faHandHolding, faHandHoldingMedical,
  faFilm } from "@fortawesome/free-solid-svg-icons";

// https://fontawesome.com/v5.15/icons/users?style=solid    OtherSportExt
// https://fontawesome.com/v5.15/icons?d=gallery&p=9&m=free

const defUrlsSport1 = {
    "Rando": [
      {
        faSVG: faHiking,
      },
    ],
    "DefiPodometre": [
      {
        faSVG: faHiking,
      },
    ],
    "VTT": [
      {
        faSVG: faBiking,
      },
    ],
    "OtherSportExt": [
      {
        faSVG: faRunning,
      },
    ]
}


const defUrlsSport2 = {
    "FootEnSalle": [
      {
        faSVG: faFutbol,
      },
    ],
    "Squash": [
      {
        faSVG: faTableTennis,
      },
    ],
    "Crossfit": [
      {
        faSVG: faChild,
      },
    ]
}

const defUrlsSportAll = {
    "default": [
      {
        faSVG: faVolleyballBall,
      },
    ],
}

const defUrlsSport   = {
  uniSport: {
    ...defUrlsSport1,
    ...defUrlsSport2,
    ...defUrlsSportAll,
    }
}

const defUrlsEntertainmentAll = {
  "default": [
    {
      faSVG: faMask,
    },
  ],
}

// catFoodRestaurant,
const defUrlsEntertainment1 = {
  "Restaurant": [
    {
      faSVG: faPizzaSlice,
    },
  ],
}

// catDrink,
const defUrlsEntertainment3 = {
  ...defUrlsEntertainment1,
  "Nightclub": [
    {
      faSVG: faGlassMartiniAlt,
    },
  ],
  "Bar": [
    {
      faSVG: faGlassCheers,
    },
  ],
  "Cafe": [
    {
      faSVG: faCoffee,
    },
  ]
}

// catCulture
const defUrlsEntertainment2 = {
  "Cinema": [
    {
      faSVG: faFilm,
    }
  ],
  "Theater": [
    {
      faSVG: faTheaterMasks,
    },
  ]
}


const defUrlsEntertainment = {
  uniEntertainment: {
    //...defUrlsEntertainment1,
    ...defUrlsEntertainment2,
    ...defUrlsEntertainment3,
    ...defUrlsEntertainmentAll,
  }
}


const defUrlsSocial1 = {
  "PetitDejeunerSolidaire": [
    {
      faSVG: faHandHoldingHeart,
    }
  ],
  "CafeSolidaire": [
    {
      faSVG: faHandHoldingHeart,
    },
  ],
  "OtherSocialRepas": [
    {
      faSVG: faHandHoldingHeart,
    },
  ]
}

const defUrlsSocial2 = {
  "Maraude": [
    {
      faSVG:  faHandHoldingMedical,
    }
  ],
  "DistributionAlimentaire": [
    {
      faSVG: faHandHolding,
    },
  ]
}

const defUrlsSocialAll = {
  "default": [
    {
      faSVG: faHandHoldingHeart,
    },
  ],
}

const defUrlsSocial = {
  uniSocial: {
    ...defUrlsSocial1,
    ...defUrlsSocial2,
    ...defUrlsSocialAll,
  }
}


export const defaultMapSVGIconForEachUniversAndCats = {
  ...defUrlsSport,
  ...defUrlsEntertainment,
  ...defUrlsSocial
} as any
  
{
    "notPremiumAccount": "Aucun abonnement en cours.",
    "cancelYourPremiumAccountFirst": "Vous devez résilier votre abonnement avant de pouvoir clôturer votre compte.",
    "removeYourTransactionsFirstDetails": "(annonces créées par d'autres membres avec des transactions actives avec vous)",
    "noTransactionRunning": "Aucune participation en cours",
    "closingWarning1": "Vos contacts n'auront plus accès à vos coordonnées.",
    "closingWarning2": "Tous vos commentaires et \"J'aime\" seront supprimés.",
    "closingWarning3": "Toutes vos notifications seront supprimées.",
    "closingWarning4": "Tous les évènements de votre agenda seront supprimés.",
    "closingWarning5": "Votre accès à l'application ne sera plus possible.",
    "noPublishedAdDoc": "Aucune annonce en cours.",
    "unpublishYourAdDocFirst": "Vous devez supprimer vos annonces avant de pouvoir clôturer votre compte.",
    "removeYourTransactionsFirst": "Vous devez retirer vos participations aux annonces suivantes avant de pouvoir clôturer votre compte.",
    "closingInfoIsLoading": "Chargement de vos données...",
    "closingQueryProcessNotStartedYet": "Le chargement va commencer, attendez..."
}
import { PPConfig } from "@/projects/paypalConfigUsedByDefault";
import { firebaseStorage } from "../backend/firebase";
import { DEFAULT_I18N, fieldIsDefined } from "./globalTypes";
import { UserTypes } from "./userTypes";
import { appConfig } from "@/engineproject/default/common/commonWithBackend/config/app"
import { userProfileControler } from "./controlers/userProfileControler";
import { EnterpriseInfoTypes } from "./enterpriseInfoTypes";
import { EnterpriseInfoProjectObject } from "../default/common/domainModel/enterpriseInfoProjectToolsTypes";


const nbContactsToBeAnAppBoss = appConfig(process.env.VUE_APP_CONFIG_ENV).nbContactsToBeAnAppBoss
// console.error("nbContactsToBeAnAppBoss " + nbContactsToBeAnAppBoss);


export class UserProfileObject  {
  OUPData!: UserTypes.UserProfile;
  mandatoryDataFieldNames!: Array<string>;

  constructor();
  constructor(srcAdDocumentObjectt: UserProfileObject) 
  constructor(srcOADData: UserTypes.UserProfile) 
  constructor(srcMiniProfile: UserTypes.UserMiniProfile);

  constructor(src?: any) {
    if (src !== undefined ) {
      if (src.OUPData !== undefined) {
        //console.error("constructor src.OADData " + JSON.stringify(src.OADData))
        // this.OADData =  new AdDocumentDataObject(src.OADData)
        this.OUPData = this.deepCopyDocOUPData(src.OADData)
      } else {
        //console.error("constructor src " + JSON.stringify(src))
        // this.OADData =  new AdDocumentDataObject(src)
        if (src.UCMUser !== undefined) {
          this.OUPData = {
            ...this.getProfileFromMiniProfile(src.UCMUser)
          }
      
        } else {
          this.OUPData = this.deepCopyDocOUPData(src)
        }
      }
    } else {
      this.OUPData = {} as UserTypes.UserProfile
    }
    //console.error("OADData in constructor " + JSON.stringify(this))
  }

  exportToFirebaseObject() {
    const preparedDoc: any = {};
    Object.assign(preparedDoc, this.OUPData);

    delete preparedDoc.UPColContacts
    delete preparedDoc.UPColNotifications
    delete preparedDoc.UPColCalendarEvents
    delete preparedDoc.UPColAllowedEnterprises
    // console.error("exportToFirebaseObject preparedDoc " + JSON.stringify(preparedDoc))
    return preparedDoc
  }

  userProfilePropIsEditableByUser(prop: string, newValue: any) {
    let ret = true
    if (prop === "UPLastInvitationRequest" || 
      prop === "UPColContacts" ||
      prop === "UPEmailValidationDate"|| 
      prop === "UPEmailValidated"|| 
      prop === "UPColAllowedEnterprises" || 
      prop === "UPEnterprise"  || 
      prop === "UPLastInvitationRequest"  || 
      prop === "UPInvitationsSentDuringLastInvitationRequest"  || 
      (prop === "UPPhoneNumber" && newValue === "00-00-00-00-00")
  
      ) {
      ret = false
    }
    //console.error("userProfilePropIsEditableByUser " + prop + " ret " + ret)
    return ret
  }

  detectObjectChanges(obj1: any, obj2: any) {
    if (!obj1 || !obj2) {
      return JSON.stringify(obj1) !== JSON.stringify(obj2)
    }
    return JSON.stringify(obj1, Object.keys(obj1).sort()) !==  JSON.stringify(obj2, Object.keys(obj2).sort()) 

  }

  detectChangesInUserProfile(newUserProfileProject: UserProfileObject) {
    const originalUserProfile = this.OUPData as any
    const newValue = newUserProfileProject.OUPData as any
    let changed = false
    for (const prop in originalUserProfile ) {
      if (this.detectObjectChanges(originalUserProfile[prop], newValue[prop]) &&
        this.userProfilePropIsEditableByUser(prop, newValue[prop]) === true) {
          // console.error("JSON.stringify(originalUserProfile[prop])  " + JSON.stringify(originalUserProfile[prop]) )
          //console.error("JSON.stringify(newValue[prop])  " + JSON.stringify(newValue[prop]) )
          changed = true
      }
    }
    // test also the newValue object because when the profile is created without phone number
    // the previous loop is not enought to detect an addition of a phone number  for instance.
    for (const prop in newValue) {
      if (this.detectObjectChanges(originalUserProfile[prop], newValue[prop]) &&
        this.userProfilePropIsEditableByUser(prop, newValue[prop]) === true) {
        changed = true
      }
    }
    return changed
  }
/*
  detectChangesInMiniProfile(newUserProfileProject: UserProfileObject) {
    let updateMiniProfile = true
    let newMiniProfile
    if (newUserProfileProject !== undefined) {
      newMiniProfile = newUserProfileProject.getMiniProfile()
      const oldMinitProfile = this.getMiniProfile()
      // newMiniProfile = getMiniProfile(newUserProfileProject.OUPData)
      // const oldMinitProfile = getMiniProfile(this.OUPData)
      if (JSON.stringify(oldMinitProfile) === JSON.stringify(newMiniProfile)) {
        updateMiniProfile = false
      }
    }

    return {
      updateMiniProfile,
      newMiniProfile
    }
  }
*/
  detectEmailChange(newUserProfileProject: UserProfileObject) {
    const updateEmail = {} as any
    if (newUserProfileProject && newUserProfileProject.OUPData.UPEmail 
        != this.OUPData.UPEmail) {
      updateEmail["previousEmail"] = this.OUPData.UPEmail
      updateEmail["newEmail"] = newUserProfileProject.OUPData.UPEmail
    }
    return updateEmail
  }
  getUPPseudo() {
    return (this.OUPData.UPPseudo !== undefined) ? this.OUPData.UPPseudo : ""
  }
  getUPUserId() {
    return this.OUPData.UPUserId
  }
  getUPEmail() {
    return this.OUPData.UPEmail
  }
  getUPNameOrPseudo() {
    return (this.OUPData.UPName !== undefined && this.OUPData.UPName !== "") ? 
      this.OUPData.UPName : this.getUPPseudo()
  }
  getUPName() {
    return this.OUPData.UPName
  }
  getUPPhoneNumber() {
    return this.OUPData.UPPhoneNumber
  }
  getUPEmailValidated() {
    return this.OUPData.UPEmailValidated
  }
  getPartnerName() {
    if (this.OUPData.UPLocation && this.OUPData.UPLocation.LPName !== undefined) {
      return this.OUPData.UPLocation.LPName
    }
    return this.getUPName()
  }
  getSubscriptionPlanName() {
    if (this.OUPData.UPSubscriptionPlanId) {
      const plan = PPConfig.planConfigs.find((ele) => {
        return ele.planId === this.OUPData.UPSubscriptionPlanId
      })
      if (plan) {
        return plan.planTitle
      }
    }
    return ""
  }
  getPublicUrl(hashTag: string | undefined) {
    return window.location.protocol + "//" + window.location.host + "/p/" + this.OUPData.UPPseudo +
      (hashTag !== undefined ? hashTag : "");
  }
  getUPEnterprise() {
   // console.error('getUPEnterprise ' + JSON.stringify(this.OUPData.UPEnterprise))
    return this.OUPData.UPEnterprise
  }
  getUPColAllowedEnterprises() {
    return (this.OUPData.UPColAllowedEnterprises !== undefined) ? 
      this.OUPData.UPColAllowedEnterprises : []
  }
  getUPLocation() {
    return this.OUPData.UPLocation
  }
  getUPCreatedOn() {
    return this.OUPData.UPCreatedOn
  }
  getUPDescription() {
    return this.OUPData.UPDescription
  }
  getUserNameOrPseudo() {
    return (this.OUPData.UPName !== undefined && this.OUPData.UPName !== "") ? 
      this.OUPData.UPName : this.OUPData.UPPseudo
  }
  getUPEventNotViewed() {
    return (this.OUPData.UPEventNotViewed !== undefined) ?
      this.OUPData.UPEventNotViewed : 0
  }
  getCalendarEventsArray() {
    return (this.OUPData.UPColCalendarEvents !== undefined) ? 
      this.OUPData.UPColCalendarEvents : []
  }
  invitsProcessNeedsToBeDone() {
    // TODO
    // UPLastInvitationRequest more than 7 days
    // and UPInvitationsSentDuringLastInvitationRequest is set to 0
    // Auto invitation only once successfully
    //   If no invit found retry every 7 days
    //   If invit found and sent one day, stop for ever the auto invit process
    const AUTO_INVIT_DELAY_IN_DAYS = 7
    const dateTimeMaxForRetry = new Date();
    dateTimeMaxForRetry.setDate(dateTimeMaxForRetry.getDate() - AUTO_INVIT_DELAY_IN_DAYS);
    return (this.OUPData.UPLastInvitationRequest === undefined) ||
      (this.OUPData.UPInvitationsSentDuringLastInvitationRequest === 0 &&
        this.OUPData.UPLastInvitationRequest.getTime() < dateTimeMaxForRetry.getTime())
  }
  getUPLastInvitationRequest() {
    return this.OUPData.UPLastInvitationRequest
  }
  getUPInvitationsSentDuringLastInvitationRequest() {
    return this.OUPData.UPInvitationsSentDuringLastInvitationRequest
  }
  getUPInvitWithGainNotViewed() {
    return (this.OUPData.UPInvitWithGainNotViewed !== undefined) ?
      this.OUPData.UPInvitWithGainNotViewed : 0
  }
  getUPNotifNotViewed() {
    return (this.OUPData.UPNotifNotViewed !== undefined) ?
      this.OUPData.UPNotifNotViewed : 0
  }
  getNotificationsArray() {
    return (this.OUPData.UPColNotifications !== undefined) ? 
      this.OUPData.UPColNotifications : []
  }
  getIdForDefaultPicture() {
    return "DefPic"
    // return "UD__" + this.OUPData.UPUserId
  }
  getFileDataDetailsForDefaultPicture() {
    return {
      imgUrl: firebaseStorage.getLongBucketStorageUrl(this.getDefaultImgFileUrl()),
      imgFileName: this.getDefaultImgFileName(),
      imgId: this.getIdForDefaultPicture(),
      imgStorageName: this.getDefaultImgStorageName(),
    }
  }
  getFileDataDetailsForUserPicture() {
    return {
      imgUrl: this.getUPPictureUrl(),
      imgFileName: this.getImgFileName(),
      imgId: this.getUPUserId(),
      imgToken: this.getUPUserId(),
      imgStorageName: this.getImgStorageName(),
    }
  }
  getImgFileName() {
    return 'UserDocs/' + this.getUPUserId() + '.jpg'
  }
  getDefaultImgFileName() {
    return this.OUPData.UPDefaults?.UDFileName
    // return this.getDefaultPicturePathAndFile() // + ".jpg"
    // return 'UserDocs/' + this.getUPUserId() + '/DefPic.jpg'
  }
  getImgStorageName() {
    return this.getProfileImgPathAndFile()
    //return 'UserDocs/' + this.getUPUserId()
  }
  
  getDefaultImgPathOnly(sep?: string) {
    return firebaseStorage.getDefaultPicturePathOnly(((sep !== undefined) ? sep : "/"), this.OUPData?.UPUserId)
  }
  getDefaultImgStorageName() {
    return this.getDefaultPicturePathAndFile()
    // return 'UserDocs/' + this.getUPUserId() + '/DefPic'
  }
  getUPPictureUrl() {
    let ret = ""
    const urlType = firebaseStorage.isDefaultProfileUrl(this.OUPData.UPPictureUrl)
    if (urlType !== false) {
      ret = firebaseStorage.getDefaultLongBucketStorageUrlForProfile(this.OUPData?.UPUserId, urlType)
    } else {
      ret = (this.OUPData.UPPictureUrl !== undefined) ? this.OUPData.UPPictureUrl : ""
    }
    return ret
  }
  getDefaultPrefAttachedDoc() {
    if (this.OUPData.UPDefaults !== undefined &&
      this.OUPData.UPDefaults.UDDocumentUrl !== undefined &&
      this.OUPData.UPDefaults.UDDocumentUrl !== "") {
      return {
        AADId: "",
        // AADFromSuggestion: false,
        AADDocumentUrl: firebaseStorage.getLongBucketStorageUrl(this.OUPData.UPDefaults.UDDocumentUrl),
        AADDocumentName: this.OUPData.UPDefaults.UDFileName
      }
    }
    return undefined
  }
  getDefaultImgFileUrl() {
    let ret = ""
    ret = (this.OUPData.UPDefaults !== undefined && this.OUPData.UPDefaults.UDDocumentUrl !== undefined) ? 
      this.OUPData.UPDefaults.UDDocumentUrl : ""
    return ret
  }
  getDefaultPicturePathAndFile(sep?: string) {
    return firebaseStorage.getDefaultPicturePathOnly((sep !== undefined) ? sep : "/", this.OUPData?.UPUserId) +
      this.OUPData.UPDefaults?.UDFileName
    // return firebaseStorage.getDefaultPicturePathAndFile(((sep !== undefined) ? sep : "/"), this.OUPData?.UPUserId)
  }
  getProfileImgPathAndFile(sep?: string) {
    return firebaseStorage.getProfileImgPathAndFile(((sep !== undefined) ? sep : "/"), this.OUPData?.UPUserId)
  }
  getUPSubscriptionId() {
    return (this.OUPData.UPSubscriptionId !== undefined) ? this.OUPData.UPSubscriptionId : ""
  }
  getUPSubscriptionPlanId() {
    return (this.OUPData.UPSubscriptionPlanId !== undefined) ? this.OUPData.UPSubscriptionPlanId : ""
  }
  getUPSubscriptionCreatedOn() {
    return (this.OUPData.UPSubscriptionCreatedOn !== undefined) ? this.OUPData.UPSubscriptionCreatedOn : ""
  }
  getUPSubscriptionEndedOn() {
    return this.OUPData.UPSubscriptionEndedOn
  }
  
  getContactsArray() {
    return (this.OUPData.UPColContacts !== undefined) ? 
      this.OUPData.UPColContacts : []
  }
  
  getContactFromContactsArray(aUserId: string) {
    if (this.OUPData.UPColContacts !== undefined) {
      return this.OUPData.UPColContacts.find((ele: UserTypes.UserProfileContact) => {
        return ele.UCMUser.UMUserId == aUserId
      })
    }
    return false
  }

  getNbContacts() {
    return (this.OUPData.UPNbContacts !== undefined) ? this.OUPData.UPNbContacts : 0
  }

  subscriptionCancelled() {
    // this.OUPData.UPSubscriptionId = ""
    // this.OUPData.UPSubscriptionPlanId = ""
    //delete this.OUPData.UPSubscriptionCreatedOn
  }
  canHaveModels() {
    if (this.isCurrentLoggedUser() !== true) {
      return false
    }
    if (this.isPremiumUser() === true) {
      return true
    }
    if (this.isAdmin() === true) {
      return true
    }
    return false
  }
  canSetADOpenToAutoInvitation() {
    if (this.isCurrentLoggedUser() !== true) {
      return false
    }
    if (this.isPremiumUser() === true) {
      return true
    }
    if (this.isAdmin() === true) {
      return true
    }
    return false
  }
  isPremiumUser() {
    // Partenaire 
    if (this.OUPData.UPColAllowedEnterprises && this.OUPData.UPColAllowedEnterprises.length > 0) {
      return true
    }
    // If subscription
    const today = new Date()
    return (this.OUPData.UPSubscriptionId !== undefined && this.OUPData.UPSubscriptionId !== "" &&
      (this.OUPData.UPSubscriptionEndedOn === undefined || this.OUPData.UPSubscriptionEndedOn.getTime() > today.getTime())) 
  }
  isAppBoss() {
    // TODO CLEANUP true to false
    // return (this.OUPData.UPAppBoss !== undefined) ? this.OUPData.UPAppBoss : 
    //  (this.getContactsArray().length > 1)
    return this.getNbContacts() > nbContactsToBeAnAppBoss
  }
  isSubscriptionCanBeCancelled() {
    return (this.OUPData.UPSubscriptionEndedOn === undefined && this.isPremiumUser())
  }

  setUserProfileUrl(val: string) {
    this.OUPData.UPPictureUrl = val
  }
  getDefaults() {
    return this.OUPData.UPDefaults
  }
  getUserTransaction() {
    return this.OUPData.UPActiveTransactions
  }
  getDefaultUniversAndCatsInUnivers() {
    if (this.OUPData.UPDefaults !== undefined) {
      return {
        defaultUnivers: (this.OUPData.UPDefaults.UDUnivers !== undefined) ? this.OUPData.UPDefaults.UDUnivers : "", 
        defaultCatInUnivers: (this.OUPData.UPDefaults.UDCatsInUnivers !== undefined) ? this.OUPData.UPDefaults.UDCatsInUnivers : ""
      }
    } else {
      return {
        defaultUnivers: "", 
        defaultCatInUnivers: ""
      }
    }
  }
  setDefaultCatsInUnivers(newDefaultCatsInUnivers: string) {
    if (this.OUPData.UPDefaults !== undefined) {
      this.OUPData.UPDefaults.UDCatsInUnivers = newDefaultCatsInUnivers
    } else {
      // This case should never occur????
      this.OUPData.UPDefaults = {
        // UDDocumentUrl: "",
        // UDFileName: "",
        // UDUnivers: "", 
        UDCatsInUnivers: newDefaultCatsInUnivers
      }
    }
  }
  setDefaultUnivers(newDefaultUnivers: string) {
    if (this.OUPData.UPDefaults !== undefined) {
      this.OUPData.UPDefaults.UDUnivers = newDefaultUnivers
    } else {
      this.OUPData.UPDefaults = {
        // UDDocumentUrl: "",
        // UDFileName: "",
        UDUnivers: newDefaultUnivers, 
        // UDCatsInUnivers: ""
      }
    }
  }
  isEmptyDefaultUnivers() {
    return (this.OUPData.UPDefaults === undefined ||
      this.OUPData.UPDefaults.UDUnivers === undefined ||
      this.OUPData.UPDefaults.UDUnivers === ""
      )
  }

  initDefaults() {
    if (this.OUPData.UPDefaults === undefined) {
      this.OUPData.UPDefaults = {}
    }
  }

  setDefaultPictureUrl(pictureUrl: string, pictureFileName: string) {
    if (this.OUPData.UPDefaults !== undefined) {
      this.OUPData.UPDefaults.UDDocumentUrl = pictureUrl
      this.OUPData.UPDefaults.UDFileName = pictureFileName
    } else {
      this.OUPData.UPDefaults = {
        UDDocumentUrl: pictureUrl,
        UDFileName: pictureFileName,
        // UDUnivers: "", 
        // UDCatsInUnivers: ""
      }
    }
  }
  setUserProfileLocation(val: UserTypes.LocationPoint | undefined) {
    this.OUPData.UPLocation = val
  }
  getLastModelsUsed() {
    if (this.OUPData.UPLastModelsUsed === undefined) {
      return []
    }
    return this.OUPData.UPLastModelsUsed
  }
  addOneModelInLastModelsUsed(fromModel: UserTypes.MiniAdDocModel) {
    // TODO remove duplicate and push at first position
    if (this.OUPData.UPLastModelsUsed === undefined) {
      this.OUPData.UPLastModelsUsed = []
      this.OUPData.UPLastModelsUsed.push(fromModel)
    } else {
      this.OUPData.UPLastModelsUsed = [
        fromModel,
        ... this.OUPData.UPLastModelsUsed.reduce((accumulator:  Array<UserTypes.MiniAdDocModel>, 
          currentValue: UserTypes.MiniAdDocModel) => {
            if (fromModel.MADocId !== currentValue.MADocId &&
              accumulator.length <= 0) {
              accumulator.push(currentValue)
            }
            return accumulator
          }, Array<UserTypes.MiniAdDocModel>())
      ]
    }
    return this.OUPData.UPLastModelsUsed
  }
  setHasBeenViewedInArray(newData: { 
    UPUserId: string; updatedNotificationId: string; hasBeenViewed: boolean; }) {
    if (this.OUPData && this.OUPData.UPUserId == newData.UPUserId &&
      this.OUPData.UPColNotifications) {
        // updatedNotificationId
        this.OUPData.UPColNotifications = this.OUPData.UPColNotifications.reduce((accumulator:  Array<UserTypes.UserNotification>, 
            currentValue: UserTypes.UserNotification) => {
              if (currentValue.UNNotificationId === newData.updatedNotificationId) {
                currentValue.UNHasBeenViewed = newData.hasBeenViewed
              }
              accumulator.push(currentValue)
          return accumulator
        },  Array<UserTypes.UserNotification>())
    }
  }

  removeNotificationInArray(newData: { 
    UPUserId: string; removedNotificationId: string; }) {
    if (this.OUPData && this.OUPData.UPUserId == newData.UPUserId &&
      this.OUPData.UPColNotifications) {
        this.OUPData.UPColNotifications = this.OUPData.UPColNotifications.reduce((accumulator:  Array<UserTypes.UserNotification>, 
            currentValue: UserTypes.UserNotification) => {
              if (currentValue.UNNotificationId !== newData.removedNotificationId) {
                accumulator.push(currentValue)
              }
          return accumulator
        },  Array<UserTypes.UserNotification>())
    }
  }

  removeCalendarEventInArray(newData: { 
    aUEOwnerUserId: string; calendarEventId: string; }) {
    if (this.OUPData && this.OUPData.UPUserId == newData.aUEOwnerUserId &&
      this.OUPData.UPColCalendarEvents) {
        this.OUPData.UPColCalendarEvents = this.OUPData.UPColCalendarEvents.reduce((accumulator:  Array<UserTypes.UserCalendarEvent>, 
            currentValue: UserTypes.UserCalendarEvent) => {
              if (currentValue.UECalendarEventId !== newData.calendarEventId) {
                accumulator.push(currentValue)
              }
          return accumulator
        },  Array<UserTypes.UserCalendarEvent>())
    }
  }

  setNotificationsArrayInMyProfile(newData: { 
    UPUserId: string; UPColNotifications: UserTypes.UserNotification[]; }) {
    if (this.OUPData && this.OUPData.UPUserId == newData.UPUserId) {
      this.OUPData.UPColNotifications = newData.UPColNotifications
    }
  }

  setCalendarEventsArrayInMyProfile(newData: { 
    UPUserId: string; UPColCalendarEvents: UserTypes.UserCalendarEvent[]; }) {
    if (this.OUPData && this.OUPData.UPUserId == newData.UPUserId) {
      this.OUPData.UPColCalendarEvents = newData.UPColCalendarEvents
    }
  }

  setContactsArrayInUserProfile(newData: { 
    UPUserId: string; aUPColContacts: UserTypes.UserProfileContact[]; }) {
    if (this.OUPData !== undefined && this.OUPData.UPUserId == newData.UPUserId) {
      this.OUPData.UPColContacts = newData.aUPColContacts
    }
  }

  setAllowedEnterprisesArrayInUserProfile(newData: { 
    //UPUserId: string; aUPColAllowedEnterprises: EnterpriseInfoTypes.EnterpriseInfo[]; }) {
     UPUserId: string; aUPColAllowedEnterprises: EnterpriseInfoProjectObject[]; }) {
    if (this.OUPData !== undefined && this.OUPData.UPUserId == newData.UPUserId) {
      this.OUPData.UPColAllowedEnterprises = newData.aUPColAllowedEnterprises
    }
  }

  setBannedInUserProfile(newData: { 
     UPUserId: string; aBannedState: boolean; }) {
    if (this.OUPData !== undefined && this.OUPData.UPUserId == newData.UPUserId) {
      this.OUPData.UPBanned = newData.aBannedState
    }
  }

  getUserProfileLatLng() {
    if (this.OUPData !== undefined &&
      this.OUPData.UPLocation !== undefined &&
      this.OUPData.UPLocation.LPLat !== undefined  &&
      this.OUPData.UPLocation.LPLng !== undefined &&
      this.OUPData.UPLocation.LPLat !== 0  &&
      this.OUPData.UPLocation.LPLng !== 0 ) {
        return [this.OUPData.UPLocation.LPLat, this.OUPData.UPLocation.LPLng]
      }
    return undefined
  }
  
  isAdmin() {
    if (this.OUPData !== undefined) {
      return (this.OUPData.UPIsAdmin !== undefined) ? this.OUPData.UPIsAdmin : false
    }
    return false
  }

  isBannedAndIMNotAdmin(aUser: UserProfileObject) {
    return aUser.isBanned() === true && this.isAdmin() === false
  }
  
  isBanned() {
    if (this.OUPData !== undefined) {
      return (this.OUPData.UPBanned !== undefined) ? this.OUPData.UPBanned : false
    }
    return false
  }

  isClosedAndIMNotAdmin(aUser: UserProfileObject) {
    return aUser.isClosed() === true && this.isAdmin() === false
  }
  
  isClosed() {
    if (this.OUPData !== undefined) {
      return (this.OUPData.UPClosed !== undefined) ? this.OUPData.UPClosed : false
    }
    return false
  }

  getClosedDate() {
    return this.OUPData.UPClosedOn
  }

  isOwnerOrAdmin(aUserId: string) {
    
    return ((aUserId == this.OUPData.UPUserId) || 
      this.OUPData.UPIsAdmin === true)
  }

  canAccesToUserPrivateData() {
    if (this.OUPData !== undefined) {
      return (fieldIsDefined(this.OUPData.UPUserId) &&
      fieldIsDefined(this.OUPData.UPPseudo) &&
      fieldIsDefined(this.OUPData.UPEmail))
    }
    return false
  }

  isCurrentLoggedUser() {
    if (this.OUPData !== undefined) {
      return (this.canAccesToUserPrivateData() &&
        userProfileControler.isCurrentLoggedUser(this.OUPData.UPUserId))
    }
    return false
  }

  isUserIsConnectedToMe() {
    if (this.OUPData !== undefined) {
      return (this.canAccesToUserPrivateData())
    }
    return false
  }

  isValidUser() {
    if (this.OUPData !== undefined) {
      return (fieldIsDefined(this.OUPData.UPUserId) &&
      fieldIsDefined(this.OUPData.UPPseudo))
    }
    return false
  }

  isConnectedNoMoreUsed() {
    if (this.OUPData !== undefined) {
      return (fieldIsDefined(this.OUPData.UPUserId) &&
      fieldIsDefined(this.OUPData.UPPseudo) &&
      fieldIsDefined(this.OUPData.UPEmail))
    }
    return false
  }

  deepCopyDocOUPData(aOUPData: UserTypes.UserProfile) {
    const newOUPData = {
      ...aOUPData,
      UPLocation: (aOUPData.UPLocation) ? {...aOUPData.UPLocation} : undefined,
      UPColContacts: (aOUPData.UPColContacts) ? [...aOUPData.UPColContacts] : [],
      UPColNotifications: (aOUPData.UPColNotifications) ? [...aOUPData.UPColNotifications] : [],
      UPColCalendarEvents: (aOUPData.UPColCalendarEvents) ? [...aOUPData.UPColCalendarEvents] : [],
    }
    return newOUPData 
  }
  setMandatoryDataFieldNames(val: Array<string>) {
    this.mandatoryDataFieldNames = val
  }
  isEmailValidated() {
    const userProfileData = this.OUPData
    if (userProfileData === undefined) {
      return false
    }
   return userProfileData.UPEmailValidated
  }
  isCompleted() {
    let completed = true
    const userProfileData = this.OUPData
    if (userProfileData === undefined) {
      return false
    }
    this.mandatoryDataFieldNames.forEach((aDataFieldName: string) => {
      if (userProfileData[aDataFieldName as keyof UserTypes.UserProfile] == undefined || 
        userProfileData[aDataFieldName as keyof UserTypes.UserProfile] == "") {
        completed = false
      }
    })
    return completed
  }
  uncompleteProfilMessage(toAdd: boolean) {
    if (this.isEmailValidated() !== true) {
      return (toAdd) ? 'missingEmailValidationToAdd' : 'missingEmailValidation'
    } else if (this.isCompleted() !== true) {
        return (toAdd) ? 'missingDataInProfileToAdd' : 'missingDataInProfile'
    }
    return ''
  }

  getProfileFromMiniProfile(aMiniProfile: UserTypes.UserMiniProfile): any {
    const newOUPData = {
      UPUserId: aMiniProfile.UMUserId,
      UPPseudo: aMiniProfile.UMPseudo,
      UPName: aMiniProfile.UMName,
      UPPictureUrl: aMiniProfile.UMPictureUrl,
      UPDescription: aMiniProfile.UMDescription,
      UPI18N: (aMiniProfile.UMI18N !== undefined) ? aMiniProfile.UMI18N : DEFAULT_I18N,
      UPLocation: undefined,
      UPColContacts: [],
      UPColNotifications: [],
      UPColCalendarEvents: [],
    }
    return newOUPData 
  }

  getMiniProfileForContact(): UserTypes.UserMiniProfile  {
    return {
      UMUserId: this.OUPData.UPUserId,
      UMPseudo: this.OUPData.UPPseudo,
      UMName: this.OUPData.UPName,
      UMPictureUrl: firebaseStorage.getShortBucketStorageUrl(this.OUPData.UPPictureUrl),
      UMDescription: this.OUPData.UPDescription,
      UMFromAppBoss: this.isAppBoss(),
      UMI18N: (this.OUPData.UPI18N !== undefined) ? this.OUPData.UPI18N : DEFAULT_I18N,
    }
  }

  getMiniProfileForTransaction(): UserTypes.UserMiniProfile  {
    return {
      UMUserId: this.OUPData.UPUserId,
      UMPseudo: (this.OUPData.UPPseudo !== undefined) ? this.OUPData.UPPseudo : "",
      UMName: this.OUPData.UPName,
      UMPictureUrl: firebaseStorage.getShortBucketStorageUrl(this.OUPData.UPPictureUrl),
      UMDescription: (this.OUPData.UPDescription !== undefined) ? this.OUPData.UPDescription : "",
      UMFromAppBoss: this.isAppBoss(),
      UMI18N: (this.OUPData.UPI18N !== undefined) ? this.OUPData.UPI18N : DEFAULT_I18N,

    }
  }

  getOwnerMiniProfile(isEnterpriseDoc = false): UserTypes.OwnerMiniProfile {
    return {
      UOPseudo: this.OUPData.UPPseudo,
      UODescription: this.OUPData.UPDescription,
      UOPictureUrl: firebaseStorage.getShortBucketStorageUrl(this.OUPData.UPPictureUrl),
      UOName: (isEnterpriseDoc) ? this.OUPData.UPName : "",  
      UOUserId: this.OUPData.UPUserId, 
      UOFromPremiumAccount: this.isPremiumUser(), 
      UOFromAppBoss: this.isAppBoss(),
      UOI18N: this.OUPData.UPI18N,
    }
  }
  setUserEnterprise(newEnterprise: EnterpriseInfoTypes.EnterpriseInfo) {
    this.OUPData.UPEnterprise = {...newEnterprise}
  }

  findEnterprise(enterprisesList: EnterpriseInfoProjectObject[]): EnterpriseInfoProjectObject | undefined {
    const enterpriseFound = enterprisesList.find((enter) => {
      return this.OUPData.UPEmail.endsWith(enter.getEISearchId())
    })
    return enterpriseFound
  }

  sameEnterprise(newEnterprise: EnterpriseInfoProjectObject | undefined): boolean {
    const newMiniEnterprise = (newEnterprise && newEnterprise.getMiniEnterprise) ? newEnterprise.getMiniEnterprise() : newEnterprise
    const JSONEntrepriseInProfile  = (this.OUPData.UPEnterprise) ?
      JSON.stringify(this.OUPData.UPEnterprise, Object.keys(this.OUPData.UPEnterprise).sort()): 
      JSON.stringify(this.OUPData.UPEnterprise)
    const JSONNewEntrepriseInProfile  = (newMiniEnterprise) ?
      JSON.stringify(newMiniEnterprise, Object.keys(newMiniEnterprise).sort()) :
      JSON.stringify(newMiniEnterprise)

    return JSONEntrepriseInProfile === JSONNewEntrepriseInProfile 
  }

  
}


export const unmaskAndInt = (formatedPhone: string, internationalVersion: boolean) => {
  if (formatedPhone && formatedPhone.replaceAll) {
    if (internationalVersion === undefined || internationalVersion === true) {
      // console.error("unmaskAndInt " + formatedPhone.replaceAll("-","").substr(1))
      return formatedPhone.replaceAll("-","").substr(1)
    } else {
      // console.error("unmaskAndInt " + formatedPhone.replaceAll("-",""))
      return formatedPhone.replaceAll("-","")
    }
  } else {
    console.error("unmaskAndInt " + (typeof formatedPhone))
  }
  return undefined
}



<template>
  <div class="profileListViewItemRightPart">
    <div>
      {{ aUserProfile.OUPData.UPEmail }}
      {{ aUserProfile.OUPData.UPName }}
      {{ aUserProfile.OUPData.UPPseudo }}
      {{ aUserProfile.OUPData.UPDescription }}
      {{ aUserProfile.OUPData.UPPhoneNumber }}
      <div v-if="aUserProfile.OUPData.UPSubscriptionPlanId" 
        :title="aUserProfile.OUPData.UPSubscriptionPlanId">
        <span class="pi pi-euro smallPiIcon planName"></span>{{ aUserProfile.getSubscriptionPlanName() }}
      </div>
    </div>
    <div>
      <FormatDate prefix="Fin d'abonnement " :inputDate="aUserProfile.OUPData.UPSubscriptionEndedOn"></FormatDate>
    </div>
    <div v-if="aUserProfile.OUPData.UPLocation">
      <GeolocationView :aLocation="aUserProfile.OUPData.UPLocation"></GeolocationView>
    </div>
  </div>
</template>

<script lang="ts">
import { inject, reactive, toRefs } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { UserProfileProjectObject } from '@/engineproject/default/common/domainModel/userProfileProjectToolsTypes';

import GeolocationView from '@/engineproject/components/Geolocation/GeolocationView.vue';
import FormatDate from '@/engineproject/components/UIElements/FormatDate.vue';

export default {
  props: {
    aUserProfile: {
      type: Object as () => UserProfileProjectObject,
      required: true
    },
  },

  setup(props: any, context: any) {
    const formatDate = inject('formatDate') as any;
    const {t} = useI18n()
    const page = reactive({
    });

    return {
      ...toRefs(page),
      formatDate,   
      t
    }
  },
  components: {
    GeolocationView,
    FormatDate, 
  },
}
</script>

import * as fb from './firebase'
import { geohashQueryBounds,  distanceBetween} from "geofire-common";

//import { Types } from "@/engineproject/domainModel/NOMOREUSEDadDocumentTypes"
import { AdDocumentProjectTypes } from '@/engineproject/default/common/domainModel/adDocumentProjectTypes';

import { UserProjectTypes } from "@/engineproject/default/common/domainModel/userProjectTypes"
import { adDocInvitationsFirebase, adDocTransactionsFirebase, userFirebase } from '@/engineproject/backend/firebase';
import firebase from "firebase/app";
//import "firebase/storage";
import { firebaseStorage, zipcodeFirebase } from '@/engineproject/backend/firebase';

import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes';
import { allPublicWithoutEnterprise } from '../domainModel/enterpriseInfoTypes';

const docIdSepWithOwnerId = "__"

export interface AdDocAPIClient {
  queryAdDoc(optionsQuery: any): Promise<any>;
  removeAdDoc(aAdDoc: AdDocumentProjectObject, 
    aUserId: string): Promise<{
    //  user: UserProjectTypes.UserProfile): Promise<{
    removedDoc: AdDocumentProjectObject | undefined;
    backendActionDone: boolean;
    errorMsg: string;
    userId: string | undefined;
  }>;
  updateExistingAdDocUsingDocIdOrCache(aAdDoc: AdDocumentProjectObject, 
    aUserId: string): Promise<{
    //  aUser: UserProjectTypes.UserProfile): Promise<{
    updatedDoc: AdDocumentProjectObject | undefined;
    backendActionDone: boolean;
    errorMsg: string;
    userId: string | undefined;
  }>;
   createANewAdDoc(aAdDoc: AdDocumentProjectObject,
    aUserAbstractData: {
      UPUserId: string;
      UPLocation: UserProjectTypes.LocationPoint |undefined;
      // userNameOrPseudo: string;
      // premiumAccount: boolean;
      ownerData: UserProjectTypes.OwnerMiniProfile;
    },
    //user: UserProjectTypes.UserProfile, 
    cacheCreatedDoc: boolean): Promise<any>;

}

class AdDocFirebase implements AdDocAPIClient {
  externalURL = "externalURL" as string
  cachedFirebaseDocuments = {} as any;

  getEndedDate(docData: AdDocumentProjectTypes.AdDocument): Date | undefined  {
    if (docData &&
      docData.ADDateHourEndsOn) {
        return docData.ADDateHourEndsOn
    }
    if (docData &&
      docData.ADDateHourStartsOn) {
        return docData.ADDateHourStartsOn
    }
    return
  }

  async getZipcodes(zipcode: string) {
    const zipcodesRes = await zipcodeFirebase.getZipcodes()
    //console.error("getZipcodes " + JSON.stringify(zipcodes))
    return zipcodesRes
  }

  async doClosingAccount(options: { 
    trRequesterUserId: string; 
  }) {
    let ret;
    const errorsArray = []
    ret = await adDocInvitationsFirebase.doClosingAccountInvitationsPart(options)
    if (ret.backendActionDone === false) {
      errorsArray.push(ret.errorMsg)
    }
    ret = await adDocTransactionsFirebase.doClosingAccountTransactionsPart(options)
    if (ret.backendActionDone === false) {
      errorsArray.push(ret.errorMsg)
    }
    ret = await this.doClosingAccountCommentsPart(options)
    if (ret.backendActionDone === false) {
      errorsArray.push(ret.errorMsg)
    }
    ret = await this.doClosingAccountLikesPart(options)
    if (ret.backendActionDone === false) {
      errorsArray.push(ret.errorMsg)
    }
    return {
      backendActionDone: (errorsArray.length === 0) ? true : false,
      errorMsg: (errorsArray.length === 0) ? undefined : errorsArray.join(" - ")
    }
  }

  async doClosingAccountLikesPart(options: { 
    trRequesterUserId: string; 
  }) {
    try {
      console.log("doClosingAccountLikesPart ");
      const adDocFBDs2 = await fb.db.collectionGroup("ADColLikes").
      where("ALLUserId", "==", options.trRequesterUserId).
      get();
      console.log("doClosingAccountLikesPart before batch");
      const batch = fb.db.batch();
      adDocFBDs2.forEach(async (doc: any) => {
        console.log("doClosingAccountLikesPart in batch");
        batch.delete(doc.ref);
        // await doc.ref.delete()
      })
      await batch.commit();
      console.log("doClosingAccountLikesPart Finished async");
    } catch (e) {
      const errorMsg = "error doClosingAccountLikesPart " + e
      console.error(errorMsg);
      return {
        backendActionDone: false,
        errorMsg
      }
    }
    return {
      backendActionDone: true,
      errorMsg: ""
    }
  }

  async doClosingAccountCommentsPart(options: { 
    trRequesterUserId: string; 
  }) {
    try {
      const adDocFBDs2 = await fb.db.collectionGroup("ADColComments").
      where("ACAUserId", "==", options.trRequesterUserId).
      get();
      const batch = fb.db.batch();
      adDocFBDs2.forEach(async (doc: any) => {
        batch.delete(doc.ref);
        // await doc.ref.delete()
      })
      await batch.commit();
    } catch (e) {
      const errorMsg = "error doClosingAccountCommentsPart " + e
      console.error(errorMsg);
      return {
        backendActionDone: false,
        errorMsg
      }
    }
    return {
      backendActionDone: true,
      errorMsg: ""
    }
  }



  getPathForAttachedDocs(docId: string, AADId: string) {
    const pathOnly = "AdDocs/" + docId + "/"
    if (AADId === "") {
      const imgId = Date.now().toString()
      const pathAndFile = pathOnly + imgId
      return { 
        pathAndFile, 
        pathOnly,
        imgId
      }
    }
    const pathAndFile = pathOnly + AADId
    return { 
      pathAndFile,
      pathOnly,
      imgId: AADId,
    }
  }
  async removeOneAttachedFiles(docId: string, AADId: string) {
    try {
      if (AADId === "" || AADId ===  this.externalURL) {
        console.error("external url picture found for docId: " + docId)
        return {
          backendActionDone: true,
          errorMsg: ""
        }
      }
      const { pathAndFile } = this.getPathForAttachedDocs(docId, AADId)
      return firebaseStorage.deleteAnAttachedFile(pathAndFile).then(function () {
        // File deleted successfully 
        // console.error("removeAttachedFiles DONE " + pathAndFile)
        return {
          backendActionDone: true,
          errorMsg: ""
        }
      }).catch(function (error: any) {
        // There has been an error      
        console.error("error removeOneAttachedFiles " + pathAndFile + "  " + error)
        return {
          backendActionDone: false,
          errorMsg: error
        }
      });
    } catch (e) {
      console.error("error removeOneAttachedFiles " + e)
      return {
        backendActionDone: false,
        errorMsg: e + ""
      }
    }

  }
  async removeAttachedFiles(docId: string, docAttachedFiles: Array<AdDocumentProjectTypes.AdAttachedDocument>) {
    try {
      if (docAttachedFiles !== undefined) {
        const errors = Array<string>()
        for (const key in docAttachedFiles) {
          const aAttachedFile = docAttachedFiles[key]
          if (aAttachedFile.AADId !== "" && aAttachedFile.AADId !==  this.externalURL
            /*&& aAttachedFile.AADFromSuggestion !== true*/) {
            const retStatus = await this.removeOneAttachedFiles(docId, aAttachedFile.AADId)
            if (retStatus.backendActionDone === false) {
              errors.push(aAttachedFile.AADDocumentUrl + " " + retStatus.errorMsg)
            }
          } else {
            if (aAttachedFile.AADId !== this.externalURL /*||  aAttachedFile.AADFromSuggestion !== true*/) {
              console.error("error removeAttachedFiles 1 " + errors)
              errors.push(aAttachedFile.AADDocumentUrl)
            } else {
              console.error("external url picture or from suggestion url found: " + aAttachedFile.AADDocumentUrl)
            }
          }
        }
        //docAttachedFiles.forEach(async (aAttachedFile, pos) => {
        //})
        if (errors.length > 0) {
          console.error("error removeAttachedFiles 2 " + errors)
          return {
            backendActionDone: false,
            errorMsg: "Invalid file ID for urls: " + errors
          }
        }
        return {
          backendActionDone: true,
          errorMsg: ""
        }
      }
    } catch (e) {
      console.error("error removeAttachedFiles 3 " + e)
      return {
        backendActionDone: false,
        errorMsg: e + ""
      }
    }
  }
  buildAttachedDocs(aAdDoc: AdDocumentProjectObject, doc: any) {
    let docAttachedFiles = Array<AdDocumentProjectTypes.AdAttachedDocument>()
    let docDataFirebase
    if (doc !== undefined && doc.data() !== undefined) {
      docDataFirebase = doc.data()
      if (docDataFirebase && docDataFirebase!.ADColAttDoc) {
        docAttachedFiles = [...docDataFirebase!.ADColAttDoc]
      }
    }
    docAttachedFiles = aAdDoc.mergeAttachedFiles(docAttachedFiles)
    return docAttachedFiles
  }

  async removeAllDocsInCollection(commentDocs: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) {
    //console.error("removeAllDocsInCollection  " + commentDocs)
    try {
      commentDocs.forEach(async (doc: any) => {
        //console.error("removeAllDocsInCollection delete : " + doc.id)
        await doc.ref.delete()
      })
    } catch (e) {
      console.error("error removeAllDocsInCollection " + e)
      return {
        backendActionDone: false,
        removedDoc: undefined,
        errorMsg: e + ""
      }
    }
  }

  async removeSubCollectionsDocs(collectionsArray: any) {
    let allErrorMsg = ""
    collectionsArray.forEach(async (aCol: any) => {
      const transationsResult = await this.removeAllDocsInCollection(aCol) 
      if (transationsResult && transationsResult.backendActionDone === false) {
        allErrorMsg = allErrorMsg + "\n" + transationsResult.errorMsg
      }
    })
    return allErrorMsg
  }

  async removeAdDoc(aAdDoc: AdDocumentProjectObject, 
    aUserId: string): Promise<{
    //  user: UserProjectTypes.UserProfile): Promise<{
        removedDoc: AdDocumentProjectObject | undefined;
    backendActionDone: boolean;
    errorMsg: string;
    userId: string | undefined;
  }> {
    try {
      // console.error("removeAdDoc")
      // create post in firebase
      const isC = userFirebase.isUserOK(aUserId)
      //const isC = userFirebase.isUserOK(user.UPUserId)
      if (isC.userId === "") {
        return {
          ...isC,
          errorMsg: "",
          removedDoc: undefined,
          backendActionDone: false,
        }
      }
      if (aAdDoc.getADId() === undefined || aAdDoc.getADId() === "") {
        return {
          ...isC,
          errorMsg: "InvalidADId",
          removedDoc: undefined,
          backendActionDone: false,
        }
      }
      const userId = isC.userId
      if (this.cachedFirebaseDocuments[aAdDoc.getADId()] !== undefined) {
        this.cachedFirebaseDocuments[aAdDoc.getADId()] = undefined
      }
      // const adDocFBD = fb.adDocCollection.doc(aAdDoc.getADId())
      const adDocFBD = aAdDoc.getCollectionFromDocType(fb).doc(aAdDoc.getADId())

      const doc = await adDocFBD.get()
      if (!doc.exists) {
        // console.error("doc doesn't exist " + JSON.stringify(aAdDoc))
        return {
          ...isC,
          errorMsg: "AdDoc doc doesn't exist ",
          removedDoc: undefined,
          backendActionDone: false
        }
      }
      const removedDoc = this.cleanupDocumentSnapshotFromBackend(doc)
      return await adDocFBD.delete().then(async () => {
        try {
          const allErrorMsg = ""
          if (allErrorMsg !== "") {
            return {
              ...isC,
              backendActionDone: false,
              removedDoc: undefined,
              errorMsg: allErrorMsg
            }
          }
          if (removedDoc !== undefined) {
            return {
              ...isC,
              removedDoc: new AdDocumentProjectObject(removedDoc),
              backendActionDone: true,
              errorMsg: ""
            }
          } else {
            return {
              ...isC,
              backendActionDone: false,
              removedDoc: undefined,
              errorMsg: "removedDocUndefined"
            }
            }
        } catch (e) {
          console.error("error removeAdDoc comments part " + e)
          return {
            ...isC,
            backendActionDone: false,
            removedDoc: undefined,
            errorMsg: e + ""
          }
        }
      }).catch((error: any) => {
        const errorMsg = "Error removing document: " + error
        console.error(errorMsg)
        //  + error.message + error.code
        return {
          ...isC,
          errorMsg,
          removedDoc: undefined,
          backendActionDone: false
        }
      });

    } catch (e) {
      console.error("error removeAdDoc " + e)
      return {
        userId: undefined,
        backendActionDone: false,
        removedDoc: undefined,
        errorMsg: e + ""
      }
    }
  }
  initLocationUsingUser(ADLocation: any, UPLocation: any) {
    let newLocation = ADLocation
    // console.error("newLocation " + JSON.stringify(newLocation))
    if (newLocation === undefined) {
      newLocation = (UPLocation !== undefined) ? UPLocation : undefined
    }
    return newLocation
  }

  async updateExistingAdDocUsingDocIdOrCache(aAdDoc: AdDocumentProjectObject, 
    aUserId: string) {
    //  aUser: UserProjectTypes.UserProfile) {
        if (this.cachedFirebaseDocuments[aAdDoc.getADId()] !== undefined) {
      const ret =  await this.updateAnExistingAdDoc(aAdDoc, 
        aUserId, 
        //aUser, 
        this.cachedFirebaseDocuments[aAdDoc.getADId()])
      this.cachedFirebaseDocuments[aAdDoc.getADId()] = undefined
      return ret
    } else {
      return await this.updateAnExistingAdDoc(aAdDoc, 
        aUserId, 
        //aUser, 
        aAdDoc.getCollectionFromDocType(fb).doc(aAdDoc.getADId()))
    }
  }
  
  async updateExistingAdDocUsingDocId(aAdDoc: AdDocumentProjectObject, user: UserProjectTypes.UserProfile) {
    try {

      // console.error("updateExistingAdDocUsingDocId")
      // create post in firebase
      const isC = userFirebase.isUserOK(user.UPUserId)
      if (isC.userId === "") {
        return {
          ...isC,
          backendActionDone: false,
          updatedDoc: undefined
        }
      }
      const userId = isC.userId
      return aAdDoc.getCollectionFromDocType(fb).doc(aAdDoc.getADId()).update(aAdDoc.exportToFirebaseObject()).
        then(async () => {
        console.error("updateExistingAdDocUsingDocId 33")
        // const updatedDoc = await fb.adDocCollection.doc(aAdDoc.getADId()).get()
        const updatedDoc = await aAdDoc.getCollectionFromDocType(fb).doc(aAdDoc.getADId()).get()
        let updatedAdDoc
        //console.error("updateExistingAdDocUsingDocId updatedDoc" + JSON.stringify(updatedDoc.data()))
        if (updatedDoc) {
          updatedAdDoc = this.cleanupDocumentSnapshotFromBackend(updatedDoc)
        }
        console.error("updateExistingAdDocUsingDocId updatedAdDoc " + JSON.stringify(updatedAdDoc))
        if (updatedAdDoc) {
          return {
            backendActionDone: true,
            errorMsg: undefined,
            updatedDoc: new AdDocumentProjectObject(updatedAdDoc) //updatedAdDoc //updatedDoc.data()
          }
        } else {
          return {
            backendActionDone: false,
            errorMsg: "undefinedupdatedAdDoc",
            updatedDoc: undefined
          }    
          }
      }, (reason: any) => {
        return {
          backendActionDone: false,
          errorMsg: reason.toString(),
          updatedDoc: undefined
        }    
      })

    } catch (e) {
      console.error("error updateExistingAdDocUsingDocId " + e)
      return {
        backendActionDone: false,
        errorMsg: e + "",
        updatedDoc: undefined
      }
    }
  }
  async updateAnExistingAdDoc(aAdDoc: AdDocumentProjectObject, 
    aUserId: string,
    // user: UserProjectTypes.UserProfile,
    firebaseDoc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>) {
    try {
      // console.error("updateAnExistingAdDoc")
      // create post in firebase
      const isC = userFirebase.isUserOK(aUserId)
      // const isC = userFirebase.isUserOK(user.UPUserId)
      if (isC.userId === "") {
        return {
          ...isC,
          backendActionDone: false,
          updatedDoc: undefined
        }
      }
      const userId = isC.userId

      return firebaseDoc.update(aAdDoc.exportToFirebaseObject()).
        then(async () => {
        // console.error("updateAnExistingAdDoc 33")
        
        // const updatedDoc = await fb.adDocCollection.doc(aAdDoc.getADId()).get()
        const updatedDoc = await aAdDoc.getCollectionFromDocType(fb).doc(aAdDoc.getADId()).get()
        let updatedAdDoc
        //console.error("updateAnExistingAdDoc updatedDoc" + JSON.stringify(updatedDoc.data()))
        if (updatedDoc) {
          updatedAdDoc = this.cleanupDocumentSnapshotFromBackend(updatedDoc)
        }
        // console.error("updateAnExistingAdDoc updatedAdDoc " + JSON.stringify(updatedAdDoc))
        if (updatedAdDoc) {
          return {
            ...isC,
            backendActionDone: true,
            errorMsg: "",
            updatedDoc: new AdDocumentProjectObject(updatedAdDoc) //updatedAdDoc //updatedDoc.data()
          }
        } else {
          return {
            ...isC,
            backendActionDone: false,
            errorMsg: "undefinedupdatedAdDoc",
            updatedDoc: undefined
          }    
          }
      }, (reason: any) => {
        return {
          ...isC,
          backendActionDone: false,
          errorMsg: reason.toString(),
          updatedDoc: undefined
        }    
      })

    } catch (e) {
      console.error("error updateAnExistingAdDoc " + e)
      return {
        userId: "",
        backendActionDone: false,
        errorMsg: e + "",
        updatedDoc: undefined
      }
    }
  }

  async createANewAdDoc(aAdDoc: AdDocumentProjectObject,
    aUserAbstractData: {
      UPUserId: string;
      UPLocation: UserProjectTypes.LocationPoint |undefined;
      // userNameOrPseudo: string;
      // premiumAccount: boolean;
      ownerData: UserProjectTypes.OwnerMiniProfile;
    },
    //user: UserProjectTypes.UserProfile, 
    cacheCreatedDoc: boolean) {
    try {
      //console.error("createANewAdDoc")
      // create post in firebase
      const isC = userFirebase.isUserOK(aUserAbstractData.UPUserId)
      //const isC = userFirebase.isUserOK(user.UPUserId)
      if (isC.userId === "") {
        return {
          ...isC,
          backendActionDone: false,
          addedDoc: undefined,
          newDocId: undefined
        }
      }
      const newLocation = this.initLocationUsingUser(aAdDoc.getADLocation(), 
        aUserAbstractData.UPLocation)
        //user.UPLocation)
      const addedDoc = {
        ADWorkFlowStates: {
          ADResetDone: null,
          ADContributionsDone: null,
          ADState1: null,
          ADState2: null,
          ADState3: null,
        },
        ADDocFileType: "",
        // ...this.cleanupAdDocumentToBackend(aAdDoc),
        ...aAdDoc.exportToFirebaseObject(),
        ADCreatedOn: new Date(),
        //ADCreatedOn: new Date().toISOString(),
        ADLocation: (newLocation !== undefined) ? newLocation : null,
        // ADOwnerName: aUserAbstractData.userNameOrPseudo,
        ADOwnerMiniProfile: aUserAbstractData.ownerData,
        // ADOwnerUserId: isC.userId,
        // ADOwnerName: aUserAbstractData.userNameOrPseudo,
        //ADOwnerName: (user.UPName !== undefined && user.UPName !== "") ? 
        //  user.UPName : user.UPPseudo,
        ADTotComments: 0,
        ADTotLikes: 0
      }
      /*
      if (aUserAbstractData.premiumAccount !== undefined) {
        addedDoc.ADFromPremiumAccount = aUserAbstractData.premiumAccount
      }*/
      //console.error("addedDoc " + JSON.stringify(addedDoc))
      const newId = aUserAbstractData.ownerData.UOUserId + docIdSepWithOwnerId + Date.now()

      //const newDoc = await fb.adDocCollection.add(addedDoc)
      // const newDoc = fb.adDocCollection.doc(newId)
      const newDoc = aAdDoc.getCollectionFromDocType(fb).doc(newId)
      await newDoc.set(addedDoc)
      addedDoc.ADId = newDoc.id
      if (cacheCreatedDoc === true) {
        this.cachedFirebaseDocuments[newDoc.id] = newDoc
      }
      return {
        ...isC,
        backendActionDone: true,
        addedDoc: new AdDocumentProjectObject(addedDoc),
        // newDoc: newDoc
      }

    } catch (e) {
      console.error("error createANewAdDoc " + e)
      return {
        backendActionDone: false,
        errorMsg: e + "",
        addedDoc: undefined,
        // newDoc: undefined
      }
    }
  }

  async likeAdDocDeleteStep(likesCol: any, docId: string, reason: string) {
    // console.error("error likeAdDoc 44")
    //return fb.likesCollection.doc(docId).delete().then(() => {
    return likesCol.doc(docId).delete().then(() => {
      // console.log("Doc Like successfully deleted after error in AdDoc update!");
      return {
        backendActionDone: false,
        errorMsg: "error " + reason
      }
    }).catch((error: any) => {
      console.error("Error removing document Like: " + error);
      return {
        backendActionDone: false,
        errorMsg: "error " + reason + ", Error removing document Like: " + error
      }
    });
  }
  likeAdDocCreateStep(likesCol: any, aAdDoc: AdDocumentProjectObject, docId: string, 
    aUserAbstractData: {
      aPseudo: string;
      aUserId: string;      
    }) {
    //userProfile: UserProjectTypes.UserProfile) {
    // create posliket
    //return fb.likesCollection.doc(docId).set({
    // postId: aAdDoc.getADId(),
    // userId: userId
    return likesCol.doc(docId).set({
      ALLPseudo: aUserAbstractData.aPseudo,
      ALLUserId: aUserAbstractData.aUserId
      // ALLPseudo: userProfile.UPPseudo,
      // ALLUserId: userProfile.UPUserId
    }).then(() => {
      // console.log('Write succeeded!');
      // update AdDoc likes count
      //return this.likeAdDocUpdateAdDocStep(likesCol, aAdDoc, docId, 1)
      return {
        backendActionDone: true,
        addToCounter: 1,
        errorMsg: undefined
      }

    }, (reason: any) => {
      console.error("error likeAdDoc 33")
      return {
        backendActionDone: false,
        errorMsg: "error " + reason
      }
    });
  }


  async likeAdDoc(aAdDoc: AdDocumentProjectObject, 
    aUserAbstractData: {
      aPseudo: string;
      aUserId: string;      
    }) {
    //userProfile: UserProjectTypes.UserProfile) {
    try {
      if (fb.auth === null || fb.auth.currentUser === null ||
        fb.auth.currentUser.uid !== aUserAbstractData.aUserId) {
        //  fb.auth.currentUser.uid !== userProfile.UPUserId) {
            console.error("likeAdDoc 11")
        return {
          backendActionDone: false,
          errorMsg: "Not connected or invalid userids in likeAdDoc ..."
        }
      }
      const userId = fb.auth.currentUser.uid
      const docId = `${userId}_${aAdDoc.getADId()}`

      // check if user has already liked post
      const likesCol = fb.adDocCollection.doc(aAdDoc.getADId()).collection('ADColLikes')
      const likeDocs = await likesCol.doc(docId).get()
      if (likeDocs.exists) {
        likeDocs.ref.delete()
        return {
          backendActionDone: true,
          addToCounter: -1,
          errorMsg: undefined
        }
        //return this.likeAdDocUpdateAdDocStep(likesCol, aAdDoc, docId, -1)
      }
      return this.likeAdDocCreateStep(likesCol, aAdDoc, docId, 
        aUserAbstractData)
        // userProfile)

    } catch (e) {
      console.error("error likeAdDoc ")
      console.error("error likeAdDoc " + e)
      return {
        backendActionDone: false,
        errorMsg: e + ""
      }
    }
  }

  async removeCommentFromAdDoc(aCommentData: {
    aAdDoc: AdDocumentProjectObject;
    commentId: string;
    aUserId: string;
    //aUser: UserProjectTypes.UserProfile;
  }) {
    try {
      if (!fb.auth.currentUser ||
        fb.auth.currentUser.uid !== aCommentData.aUserId) {
        //  fb.auth.currentUser.uid !== aCommentData.aUser.UPUserId) {
        return {
          backendActionDone: false,
          errorMsg: "No currentUser or invalid userids ...",
        }
      }
      const adDocFBD = fb.adDocCollection.doc(aCommentData.aAdDoc.getADId())
      const coComments = adDocFBD.collection('ADColComments').doc(aCommentData.commentId)
      const coCommentDoc = await coComments.get()
      if (coCommentDoc.exists) {
        return await coComments.delete().then(async () => {
          /* adDocFBD.update({
            ADTotComments: aCommentData.aAdDoc.getADTotComments() - 1
          })*/
          return {
            backendActionDone: true,
            errorMsg: undefined,
          }
          }).catch((error) => {
            console.error("Error removing comment: " + error);
            console.dir(error)
            //  + error.message + error.code
            return {
              errorMsg: "Error removing comment from document: " + error.message,
              backendActionDone: false
            }
          });

      } else {
        return {
          backendActionDone: false,
          errorMsg: "Comment Not Found",
        }
      }
    } catch (e) {
      console.error("error removeCommentFromAdDoc ")
      console.error("error removeCommentFromAdDoc " + e)
      return {
        backendActionDone: false,
        errorMsg: e + "",
      }
    }
  }

  async addCommentToAdDoc(aCommentData: {
    aAdDoc: AdDocumentProjectObject;
    ACContent: string;
    aUserAbstractData: {
      UPUserId: string;
      UPPseudo: string;
    };
    //aUser: UserProjectTypes.UserProfile;
  }) {
    try {
      if (!fb.auth.currentUser ||
        fb.auth.currentUser.uid !== aCommentData.aUserAbstractData.UPUserId) {
        //  fb.auth.currentUser.uid !== aCommentData.aUser.UPUserId) {
        return {
          backendActionDone: false,
          errorMsg: "No currentUser or invalid userids ...",
          newAddedComment: undefined,
        }
      }
      const adDocFBD = fb.adDocCollection.doc(aCommentData.aAdDoc.getADId())
      const newAddedComment = {
        ACCreatedOn: new Date(),
        ACContent: aCommentData.ACContent,
        ACAUserId: fb.auth.currentUser.uid,
        ACAPseudo: aCommentData.aUserAbstractData.UPPseudo
        //ACAPseudo: aCommentData.aUser.UPPseudo
      }
      const coComments = await adDocFBD.collection('ADColComments').add(newAddedComment)
      // update comment count on AdDoc
      /*await adDocFBD.update({
        ADTotComments: aCommentData.aAdDoc.getADTotComments() + 1
      })*/
      return {
        backendActionDone: true,
        errorMsg: undefined,
        newAddedComment: {
          ...newAddedComment,
          ACId: coComments.id
        },
      }
    } catch (e) {
      console.error("error addComment ")
      console.error("error addComment " + e)
      return {
        backendActionDone: false,
        errorMsg: e + "",
        newAddedComment: undefined,
      }
    }
  }

  async getLikesForAdDoc(optionsQuery: any) {
    const res = Array<any>();
    try {
      const docs = await fb.adDocCollection.doc(optionsQuery.ADId).collection('ADColLikes').get()
      docs.forEach((doc: any) => {
        const like: any = doc.data()
        like.ALId = doc.id
        res.push(like)
      })
    } catch (e) {
      console.error("error getLikesForAdDoc ")
      console.error("error getLikesForAdDoc " + e)
      return {
        backendActionDone: false,
        errorMsg: e + "",
        aADTotLikes: Array<any>()
      }
    }
    return {
      backendActionDone: true,
      errorMsg: undefined,
      aADTotLikes: res
    }
  }

  async getCommentsForAdDoc(optionsQuery: any) {
    const res = Array<any>();
    try {
      const docs = await fb.adDocCollection.doc(optionsQuery.ADId).collection('ADColComments').get()
      docs.forEach((doc: any) => {
        const comment: any = doc.data()
        comment.ACId = doc.id
        res.push(comment)
      })
    } catch (e) {
      console.error("error getCommentsForAdDoc ")
      console.error("error getCommentsForAdDoc " + e)
      return {
        backendActionDone: false,
        errorMsg: e + "",
        aADColComments: [] as Array<any>
      }
    }
    return {
      backendActionDone: true,
      errorMsg: undefined,
      aADColComments: res
    }
  }

  getMinDateAndHourToDisplayAdDoc = () => {
    const today0h0m = new Date()
    // today0h0m.setHours(0, 0, 0)
    today0h0m.setHours(today0h0m.getHours() - 2)
    return today0h0m
  }

  async queryLocalAdDoc(optionsQuery: any, filteredCollection: any) {
    // Find cities within 50km of London
    const center = optionsQuery.nearLatLng; //[51.5074, 0.1278];
    const radiusInM = ((optionsQuery.nearRadiusInM !== undefined) ?
      optionsQuery.nearRadiusInM : 50000); //50 * 1000;

    // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    // a separate query for each pair. There can be up to 9 pairs of bounds
    // depending on overlap, but in most cases there are 4.
    const bounds = geohashQueryBounds(center, radiusInM);
    const promises = [];
    const queryLocalAdDocLimit = (optionsQuery.queryLocalAdDocLimit !== undefined) ? optionsQuery.queryLocalAdDocLimit : 2
    // console.error("queryLocalAdDoc bounds " + bounds.length)
    for (const b of bounds) {
      const q = filteredCollection //db.collection('cities')
      // .orderBy('ADDateHourStartsOn')
      .limit(queryLocalAdDocLimit)

      .orderBy('ADLocation.LPGeoHash')
      .startAt(b[0])
        .endAt(b[1]);

      promises.push(q.get());
    }
    // console.error("queryLocalAdDoc promises " + promises.length)

    // Collect all the query results together into a single list
    const snapshotAdDoc = await Promise.all(promises)
    const matchingAdDocs = Array<AdDocumentProjectObject>();
    // const today0h0m = new Date()
    // today0h0m.setHours(0, 0, 0)
    const today0h0m = this.getMinDateAndHourToDisplayAdDoc()
    let limitMaxReached = 0
    for (const snap of snapshotAdDoc) {
      // console.error("snap.docs.length " + snap.docs.length)
      if (queryLocalAdDocLimit === snap.docs.length) {
        limitMaxReached++
      }
      for (const doc of snap.docs) {
        if (doc !== undefined && doc.data() !== undefined) {
          const lat = doc.get('ADLocation.LPLat');
          const lng = doc.get('ADLocation.LPLng');

          // We have to filter out a few false positives due to GeoHash
          // accuracy, but most will match
          const distanceInKm = distanceBetween([lat, lng], center);
          const distanceInM = distanceInKm * 1000;
          if (distanceInM <= radiusInM) {
            /*const adDocFBD = doc.data()
            adDocFBD.ADId = doc.id
            matchingAdDocs.push(new AdDocumentProjectObject(adDocFBD) )*/
            const adDocFBD = this.cleanupDocumentSnapshotFromBackend(doc)
            if (adDocFBD !== undefined) {
              const endedDate = this.getEndedDate(adDocFBD)
              // if (adDocFBD.ADDateHourStartsOn > today0h0m) {
              if ((endedDate !== undefined && endedDate > today0h0m) ) {
                  matchingAdDocs.push(new AdDocumentProjectObject(adDocFBD))
              }
            }
          }
        }
      }
    }
    if (limitMaxReached > 0) {
      console.error("limitMaxReached " + limitMaxReached)
    }
    // Sort the AdDocs
    matchingAdDocs.sort((a: AdDocumentProjectObject, b: AdDocumentProjectObject) => {
      return (a.getADDateHourStartsOn().getTime() - b.getADDateHourStartsOn().getTime())
    })
    return {
      AdDocsArray: matchingAdDocs,
      limitMaxReached,
    }
  }

  getTheLimit(optionsQuery: any) {
    return (optionsQuery !== undefined && optionsQuery.maxLimit !== undefined) ? optionsQuery.maxLimit : 3
  }


  async queryRecentAdDoc(optionsQuery: {
      previousAdDoc: AdDocumentProjectObject;
      nextAdDoc: AdDocumentProjectObject;
      maxLimit: number;
      suggestionFilter: boolean;
      templateFilter: boolean;
      adDocFileType: string;
      currentFilter: string;
      nearLatLng: number;
      nearRadiusInM: number;
      universAndCatFilter: string;
    }, filteredCollection: any) {
    let firebaseQuery
    // console.error("queryRecentAdDoc " + JSON.stringify(optionsQuery))
    const theLimit = this.getTheLimit(optionsQuery)
    const mustUseADCreatedOn = optionsQuery.currentFilter === "myAdDocs" || 
      optionsQuery.suggestionFilter === true || 
      optionsQuery.templateFilter === true || 
      optionsQuery.adDocFileType !== ""
    // ADDateHourStartsOn
    if (optionsQuery.nextAdDoc !== undefined) {
      if (mustUseADCreatedOn === true) {
          firebaseQuery = filteredCollection.orderBy('ADCreatedOn', 'desc')
          firebaseQuery = firebaseQuery.startAfter(optionsQuery.nextAdDoc.getADCreatedOn())
        } else {
          // firebaseQuery = filteredCollection.orderBy('ADDateHourStartsOn', 'asc').orderBy('ADCreatedOn', 'desc')
          firebaseQuery = filteredCollection.orderBy('ADDateHourEndsOn', 'asc').orderBy('ADCreatedOn', 'desc')
          // console.error("queryAdDoc startAfter " + optionsQuery.nextAdDoc.getValueADDateHourStartsOn())
          //firebaseQuery = firebaseQuery.startAfter(optionsQuery.nextAdDoc.getValueADDateHourStartsOn())
          firebaseQuery = firebaseQuery.startAfter(optionsQuery.nextAdDoc.getValueADDateHourEndsOn())
        }
      firebaseQuery = firebaseQuery.limit(theLimit)
    } else if (optionsQuery.previousAdDoc !== undefined) {
      //firebaseQuery = filteredCollection.orderBy('ADDateHourStartsOn', 'asc')
      firebaseQuery = filteredCollection.orderBy('ADDateHourEndsOn', 'asc')
        .orderBy('ADCreatedOn', 'desc')
        .endAt(optionsQuery.previousAdDoc.getValueADDateHourStartsOn())
        //.endAt(optionsQuery.previousAdDoc.getADCreatedOn())
        .limit(theLimit)
    } else {
      if (mustUseADCreatedOn === true) {
        // my suggestion adDocs do not have a ADDateHourStartsOn
        firebaseQuery = filteredCollection.orderBy('ADCreatedOn', 'desc').limit(theLimit)
      } else {
        //console.error("queryAdDoc ADDateHourStartsOn asc ADCreatedOn desc ")
        //firebaseQuery = filteredCollection.orderBy('ADDateHourStartsOn', 'asc').orderBy('ADCreatedOn', 'desc').limit(theLimit)
        firebaseQuery = filteredCollection.orderBy('ADDateHourEndsOn', 'asc').orderBy('ADCreatedOn', 'desc').limit(theLimit)
      }
    }
    // console.error("queryRecentAdDoc 2")
    const snapshotAdDoc = await firebaseQuery.get()
    const matchingAdDocs = Array<any>();
    let snapshotAdDocLength = 0
    try {
      snapshotAdDoc.forEach((doc: any) => {
        snapshotAdDocLength++
        if (doc !== undefined && doc.data() !== undefined) {
          const adDocFBD = this.cleanupDocumentSnapshotFromBackend(doc)
          //console.error("queryAdDoc mustUseADCreatedOn " + mustUseADCreatedOn)
          //console.error("queryAdDoc adDocFBD " + adDocFBD)
          //console.error("queryAdDoc adDocFBD!.ADDescription " + adDocFBD!.ADDescription)
          if (adDocFBD !== undefined /*&& 
            ATTENTION THE FOLLOWING TEST adDocFBD!.ADDescription != null
            CAN RETURN AN EMPTY LIST IS THE DATABASE IS CORRUPTED
            4 DOCS WITH NULL WILL BE DETECTED AS AN EMPTY LIST AND THE VALID OTHER RECORDS
            WILL NEVER BE SHOWN
            (mustUseADCreatedOn === true || (adDocFBD!.ADDescription != "" && adDocFBD!.ADDescription != null))*/) {
              //console.error("queryAdDoc adDocFBD " + JSON.stringify(adDocFBD))
              matchingAdDocs.push(new AdDocumentProjectObject(adDocFBD))
          }
        }
      })
    } catch (e) {
      console.error("queryRecentAdDoc 1 " + e)
    }
    return {
      AdDocsArray: matchingAdDocs,
      limitMaxReached: 0,
      snapshotAdDocLength: snapshotAdDocLength
    }
  }

  cleanupDocumentSnapshotFromBackend(doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>): AdDocumentProjectTypes.AdDocument | undefined{
    const aAdDocFirebase = doc.data()
    if (aAdDocFirebase) {
      aAdDocFirebase.ADId = doc.id
      return this.cleanupAdDocumentFromBackend(aAdDocFirebase)
    }
    return undefined
  }

  cleanupAdDocumentFromBackend(doc: firebase.firestore.DocumentData): any {
    //const aAdDoc = doc.data()
    const aAdDocFirebase = doc
    //aAdDoc!.ADId = doc.id
    if (aAdDocFirebase && aAdDocFirebase.ADLocation == undefined) {
      aAdDocFirebase.ADLocation = {}
    }

    if (aAdDocFirebase && aAdDocFirebase.ADCreatedOn !== undefined && aAdDocFirebase.ADCreatedOn.seconds !== undefined ) {
      aAdDocFirebase.ADCreatedOn = aAdDocFirebase.ADCreatedOn.toDate()
    }
    if (aAdDocFirebase && aAdDocFirebase.ADDateHourStartsOn !== undefined && aAdDocFirebase.ADDateHourStartsOn.seconds !== undefined ) {
      // console.error("queryAdDoc aAdDocFirebase.ADDateHourStartsOn " + aAdDocFirebase.ADDateHourStartsOn)
      // console.error("queryAdDoc aAdDocFirebase.ADDateHourStartsOn.toDate() " + aAdDocFirebase.ADDateHourStartsOn.toDate())
      aAdDocFirebase.ADDateHourStartsOn = aAdDocFirebase.ADDateHourStartsOn.toDate()
    }
    if (aAdDocFirebase && aAdDocFirebase.ADDateHourEndsOn !== undefined && aAdDocFirebase.ADDateHourEndsOn.seconds !== undefined ) {
      aAdDocFirebase.ADDateHourEndsOn = aAdDocFirebase.ADDateHourEndsOn.toDate()
    } else {
      // If no endsOn (old document not migrated), set the endsOn to startsOn + 1 hour
      aAdDocFirebase.ADDateHourEndsOn = aAdDocFirebase.ADDateHourStartsOn
      if (aAdDocFirebase.ADDateHourEndsOn) {
        aAdDocFirebase.ADDateHourEndsOn.setHours(aAdDocFirebase.ADDateHourEndsOn.getHours() + 1)
      }  
    }
    return aAdDocFirebase
  }

  async getAdDocumentUsingId(docId: string, shouldBeAdDocFileType: string) {
    if (fb.auth === null || fb.auth.currentUser === null) {
      return {
        aAdDoc: undefined,
        deconnected: true,
        backendActionDone: false,
        errorMsg: undefined
      }
    }
    try {
      if (docId && docId !== "") {
        const doc = await this.getCollection(shouldBeAdDocFileType, false, false).doc(docId).get()
        if (doc !== undefined && doc.data() !== undefined) {
          const aAdDoc = this.cleanupDocumentSnapshotFromBackend(doc)
          if (aAdDoc) {
            return {
              aAdDoc: new AdDocumentProjectObject(aAdDoc),
              deconnected: false,
              backendActionDone: true,
              errorMsg: undefined
            }
          } else {
            return {
              aAdDoc: undefined,
              deconnected: false,
              backendActionDone: false,
              errorMsg: "docNotInDatabaseaAdDoc",
            }
          }
        } else {
          return {
            aAdDoc: undefined,
            deconnected: false,
            backendActionDone: false,
            errorMsg: "docNotInDatabase",
          }
        }
    
      } else {
        return {
          aAdDoc: undefined,
          deconnected: false,
          backendActionDone: false,
          errorMsg: "missingDocIdInRequest",
        }
      }
    } catch(e) {
      console.error("getAdDocumentUsingId 1 " + e)
      return {
        aAdDoc: undefined,
        deconnected: false,
        backendActionDone: false,
        errorMsg: e + "",
      }
    }
  }

  getCollection(adDocFileType: string, typeSuggestion: boolean, typeTemplate: boolean) {
    if (typeSuggestion === true || adDocFileType === "S") {
      return fb.adSugCollection
    }
    if (typeTemplate === true || adDocFileType === "T") {
      return fb.adTemCollection
    }
    return fb.adDocCollection
  }

  async queryAdDoc(optionsQuery: any) {
    const AdDocsArray = Array<any>()
    let moreElementsInCollection = false
    let filteredCollection: any
    if (fb.auth === null || fb.auth.currentUser === null) {
      return {
        OADDataArrayFromBackend: AdDocsArray,
        limitMaxReached: 0,
        deconnected: true,
        moreElementsInCollection,
        backendActionDone: false,
        errorMsg: "Deconnected"
      }
    }
    try {
      const docCol = this.getCollection(optionsQuery.adDocFileType , optionsQuery.suggestionFilter, optionsQuery.templateFilter)
      if (optionsQuery.currentFilter === "myAdDocs") {
        filteredCollection = docCol.where("ADOwnerMiniProfile.UOUserId", "==", fb.auth.currentUser.uid)
      } else {
        if (optionsQuery.suggestionFilter === true) {
          filteredCollection = docCol.where("ADSuggestionVisibleStatus" , "==", true)
        } else if (optionsQuery.templateFilter === true) {
          console.error("templateFilter")
            filteredCollection = docCol.where("ADTemplatePublicStatus" , "==", true)
          } else if (optionsQuery.adDocFileType !== "") {
          if (optionsQuery.adDocFileType === "T") {
            filteredCollection = docCol.where("ADTemplatePublicStatus" , "==", true)
          } else {
            filteredCollection = docCol     
          }
        } else {
          // console.error("queryAdDoc ADPublishedStatus true")
          filteredCollection = docCol.where("ADPublishedStatus" , "==", true)
          if (optionsQuery.nearLatLng === undefined) {
            // console.error("queryAdDoc optionsQuery.nearLatLng " + optionsQuery.nearLatLng)
            // const today0h0m = new Date()
            // today0h0m.setHours(0, 0, 0)
            const today0h0m = this.getMinDateAndHourToDisplayAdDoc()
            /*const dateGoogle = firebase.firestore.Timestamp.fromDate(today0h0m);
            console.error("queryAdDoc today0h0m.toISOString() " + today0h0m.toISOString())
            console.error("queryAdDoc dateGoogle " + dateGoogle)
            filteredCollection = filteredCollection.where("ADDateHourStartsOn" , ">", dateGoogle)*/
            
            //// filteredCollection = filteredCollection.where("ADDateHourStartsOn" , ">", firebase.firestore.Timestamp.fromDate(today0h0m))
            filteredCollection = filteredCollection.where("ADDateHourEndsOn" , ">", firebase.firestore.Timestamp.fromDate(today0h0m))
          }
          if (optionsQuery.invitationFilter === true) {
            filteredCollection = filteredCollection.where("ADOpenToAutoInvitation" , "==", true)
            // filteredCollection = filteredCollection.where("ADOwnerMiniProfile.UOUserId", "!=", fb.auth.currentUser.uid)
          }
        }
      }
      if (optionsQuery.universAndCatFilter !== undefined) {
        for (const aFirebaseFilter in optionsQuery.universAndCatFilter ) {
          if (optionsQuery.universAndCatFilter[aFirebaseFilter].length > 1) {
            filteredCollection = filteredCollection.where(aFirebaseFilter, 
              "in", optionsQuery.universAndCatFilter[aFirebaseFilter])
          } else if (optionsQuery.universAndCatFilter[aFirebaseFilter].length === 1) {
            filteredCollection = filteredCollection.where(aFirebaseFilter, 
              "==", optionsQuery.universAndCatFilter[aFirebaseFilter][0])
          }
        }
      }

     // ADDayStartsOn ADSoireeStartsOn ADWeekendStartsOn
     // calendarFilterDay365: [], calendarFilterSoiree calendarFilterWeekend
      if (optionsQuery.calendarFilter !== undefined) {
        if (optionsQuery.calendarFilter.calendarFilterDay365.length > 1) {
          filteredCollection = filteredCollection.where("ADDayStartsOn", 
            "in", optionsQuery.calendarFilter.calendarFilterDay365)
        } else if (optionsQuery.calendarFilter.calendarFilterDay365.length === 1) {
          filteredCollection = filteredCollection.where("ADDayStartsOn", 
            "==", optionsQuery.calendarFilter.calendarFilterDay365[0])
        }
        if (optionsQuery.calendarFilter.calendarFilterSoiree === true) {
          filteredCollection = filteredCollection.where("ADSoireeStartsOn", 
            "==", true)
        }
        if (optionsQuery.calendarFilter.calendarFilterWeekend === true) {
          filteredCollection = filteredCollection.where("ADWeekendStartsOn", 
            "==", true)
        }
      }

      if (!(optionsQuery.suggestionFilter === true || optionsQuery.templateFilter === true || 
        optionsQuery.adDocFileType === "T" || optionsQuery.adDocFileType === "S")) {
        if (optionsQuery.enterpriseFilter  &&
          optionsQuery.enterpriseFilter.EIEnterpriseId !== "") {
            //filteredCollection = filteredCollection.where("ADEnterprise.EISearchId",
            if (optionsQuery.isUSerEnterprise === false &&
              optionsQuery.enterpriseFilter.EIEnterpriseId !== allPublicWithoutEnterprise.EIEnterpriseId ) {

              filteredCollection = filteredCollection.where("ADOwnerMiniProfile.UOUserId", "==", fb.auth.currentUser.uid)
            }
            filteredCollection = filteredCollection.where("ADEnterprise.EIEnterpriseId", 
              "==", optionsQuery.enterpriseFilter.EIEnterpriseId)

        } else {
          //filteredCollection = filteredCollection.where("ADEnterprise.EISearchId", 
          filteredCollection = filteredCollection.where("ADEnterprise.EIEnterpriseId", 
          "==", allPublicWithoutEnterprise.EIEnterpriseId)
        }
      }

      let queryResult
      if (optionsQuery.nearLatLng !== undefined) {
        
        // JUST FOR A TEST 
        // filteredCollection = filteredCollection.where("ADYourPrice" , "in", [0, 1, 10])
        // filteredCollection = filteredCollection.where("ADYourPrice" , ">", 0)
        queryResult = await this.queryLocalAdDoc(optionsQuery, filteredCollection)
        // TODO ADD where("ADDateHourStartsOn" , ">", today0h0m) here
      } else {
        queryResult = await this.queryRecentAdDoc(optionsQuery, filteredCollection)
        const theLimit = this.getTheLimit(optionsQuery)
        // console.error("queryAdDoc AdDocsArray.length " + queryResult.AdDocsArray.length + " ==== " + theLimit)
        if (queryResult.snapshotAdDocLength === theLimit) {
          moreElementsInCollection = true
        }
      }
      //console.error("queryAdDoc AdDocsArray " + JSON.stringify(queryResult.AdDocsArray))
      return {
        OADDataArrayFromBackend: queryResult.AdDocsArray,
        limitMaxReached: queryResult.limitMaxReached,
        deconnected: false,
        moreElementsInCollection,
        backendActionDone: true,
        errorMsg: undefined
      }
    } catch(e) {
      console.error("queryAdDoc 1 " + e)
      return {
        OADDataArrayFromBackend: AdDocsArray,
        limitMaxReached: 0,
        deconnected: false,
        moreElementsInCollection,
        backendActionDone: false,
        errorMsg: e + "",
      }
    }

  }
}

export default AdDocFirebase

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "filter-container"
}
const _hoisted_2 = { for: "filterEnterprise" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EnterpriseSelect = _resolveComponent("EnterpriseSelect")!

  return (_ctx.canSelectEntreprise)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("label", _hoisted_2, _toDisplayString($setup.t($props.titleTag)), 1),
          _createVNode(_component_EnterpriseSelect, {
            aEnterprise: _ctx.searchEnterpriseFilter,
            allowedEnterprises: _ctx.allowedEnterprisesInfo,
            onSelectedEnterprise: $setup.updateEnterprise
          }, null, 8, ["aEnterprise", "allowedEnterprises", "onSelectedEnterprise"])
        ])
      ]))
    : _createCommentVNode("", true)
}
import { AppServerConfigTypes } from "./appServerConfigTypes";


export class AppServerConfigObject  {
  OASCData!: AppServerConfigTypes.AppServerConfig;

  constructor();
  constructor(srcAdDocumentObjectt: AppServerConfigObject) 
  constructor(srcOADData: AppServerConfigTypes.AppServerConfig) 

  constructor(src?: any) {
    if (src !== undefined ) {
      if (src.OASCData !== undefined) {
        //console.error("constructor src.OADData " + JSON.stringify(src.OADData))
        // this.OADData =  new AdDocumentDataObject(src.OADData)
        this.OASCData = {...src.OASCData}
      } else {
        //console.error("constructor src " + JSON.stringify(src))
        // this.OADData =  new AdDocumentDataObject(src)
        this.OASCData = {...src}
      }
    } else {
      this.OASCData = {} as AppServerConfigTypes.AppServerConfig
    }
    //console.error("OADData in constructor " + JSON.stringify(this))
  }
  getQueryLocalAdDocLimit() {
    return this.OASCData.ASCQueryLocalAdDocLimit
  }
  getServerState(): any {
    return this.OASCData.ASCState
  }
  serverIsOk(): any {
    if (this.OASCData !== undefined &&
      this.OASCData.ASCState === "OK") {
        return true
      }
      return false
  }
  serverIsLocked(): any {
    if (this.OASCData !== undefined &&
      this.OASCData.ASCState === "Maintenance") {
        return true
      }
      return false
  }

  updateAppServerConfig = (newOASCData: AppServerConfigTypes.AppServerConfig) => {
    const newDoc = new AppServerConfigObject({
      ...this.OASCData,
      ...newOASCData
    });
    return newDoc 
  }


}


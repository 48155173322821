<template>
<div>
  <div :title="t('transactionUpdateFootStepTitle')" class="pi pi-pencil" @click="login"></div>
  <div class="status" v-if="loginStarted === true">
      <div v-if="!accessToken || accessToken === ''">
        Non connecté à votre compte Google.
      </div>
      <div v-else-if="!dataFromFitnessApi || !dataFromFitnessApi.data || !dataFromFitnessApi.data.dataSource || dataFromFitnessApi.data.dataSource.length === 0">
        Connecté à votre compte Google, mais aucune données Google Fitness.
        <br/>
        Vous devez utiliser Google Fitness: 
          <a target="_blank"
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.fitness&hl=fr&gl=US&pli=1" >
            pour Android
          </a> ou
          <a target="_blank"
            href="https://support.google.com/fit/answer/6075067?hl=fr&co=GENIE.Platform%3DiOS">
            pour ios (instruction Google Fit sur votre montre)
          </a> - 
          <a target="_blank"
            href="https://apps.apple.com/app/id1433864494?sjid=5315449962916796590-EU">
            App Store
          </a>
          
        <br/>
        ou une application compatible avec Google Fitness API.
      </div>

      
    </div>

  <div v-if="0 === 1">

    Steps Counter V8

    <button @click="login">Login Using Google</button>


    <button @click="refresh">Refresh Steps Data</button>

    <div class="status">
      <div v-if="!accessToken || accessToken === ''">
        Non connecté à votre compte Google.
      </div>
      <div v-else-if="!dataFromFitnessApi || !dataFromFitnessApi.data || !dataFromFitnessApi.data.dataSource || dataFromFitnessApi.data.dataSource.length === 0">
        Connecté à votre compte Google, mais aucune données Google Fitness.
        <br/>
        Vous devez utiliser Google Fitness: 
          <a target="_blank"
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.fitness&hl=fr&gl=US&pli=1" >
            pour Android
          </a> ou
          <a target="_blank"
            href="https://support.google.com/fit/answer/6075067?hl=fr&co=GENIE.Platform%3DiOS">
            pour ios (instruction Google Fit sur votre montre)
          </a> - 
          <a target="_blank"
            href="https://apps.apple.com/app/id1433864494?sjid=5315449962916796590-EU">
            App Store
          </a>
          
        <br/>
        ou une application compatible avec Google Fitness API.
      </div>

      
    </div>
    <div class="border">
      <div>
        Nombre de pas par période:
      </div>
      <div v-if="stepsDataFromFitnessApi">
        <div v-for="dataForOneDay, index in stepsDataFromFitnessApi" :key="index">
          {{ index + 1 }}
          Du {{ dataForOneDay.start }}
          au {{ dataForOneDay.end }}, 
          <span @click="showDetail = !showDetail">nombre de pas: {{ dataForOneDay.totalNumberOfSteps }}</span>
          <div  class="detailSteps" v-if="showDetail === true && dataForOneDay.stepsDataset && dataForOneDay.stepsDataset[0] &&
            dataForOneDay.stepsDataset[0].points  && dataForOneDay.stepsDataset[0].points.length > 0">
            <pre>
              {{ JSON.stringify(dataForOneDay.stepsDataset, null, 4) }}
            </pre>
          </div>
        </div>
      </div>
    </div>
    <div class="debug">
      <div>
        FITNESS STEPS DATA FORMATTED WITH DETAILS:
      </div>
      <div v-if="stepsDataFromFitnessApi">
        <div v-for="dataForOneDay, index in stepsDataFromFitnessApi" :key="index">
          {{ index + 1 }}
          Du {{ dataForOneDay.start }}
          au {{ dataForOneDay.end }}, 
          nombre de pas: {{ dataForOneDay.totalNumberOfSteps }}
          <div class="detailSteps" v-if="dataForOneDay.stepsDataset && dataForOneDay.stepsDataset[0] &&
            dataForOneDay.stepsDataset[0].points  && dataForOneDay.stepsDataset[0].points.length > 0">
            <pre>
              {{ JSON.stringify(dataForOneDay.stepsDataset, null, 4) }}
            </pre>
          </div>
        </div>
      </div>
      <div>
        FITNESS STEPS DATA NO FORMAT:
      </div>
      <div v-if="stepsDataFromFitnessApi">
        {{ JSON.stringify(stepsDataFromFitnessApi, null , 4) }}
      </div>
      <div>
        FITNESS STEPS BRUT DATA:
      </div>
      <div v-if="stepsBrutDataFromFitnessApi">
        {{ JSON.stringify(stepsBrutDataFromFitnessApi, null , 4) }}
      </div>
      <div>
        FITNESS DATA:
      </div>
      <div v-if="dataFromFitnessApi">
        {{ JSON.stringify(dataFromFitnessApi.data, null , 4) }}
      </div>
      <div>
        DEBUG INFO dataFromLoginApi
      </div>
      <div>
        {{ dataFromLoginApi }}
      </div>
      <div>
        DEBUG INFO dataFromFitnessApi
      </div>
      <div>
        {{ JSON.stringify(dataFromFitnessApi) }}
      </div>
    </div>
  </div>

</div>
</template>

<script lang="ts">

import { reactive, toRefs, computed, ref } from "vue";
import { useI18n } from '@/engineproject/default/common/i18n';
import axios, {Method} from "axios"
import { CallbackTypes, decodeCredential, googleSdkLoaded } from "vue3-google-login";
import {firebaseProjectConfig} from "@/engineproject/default/common/envConfigs/firebaseProject"
import { useDocumStore } from '@/engineproject/store/documStore';


export default {
  props: {
    startTime: {
      type: String,
      required: true
    },
    endTime: {
      type: String,
      required: true
    },
  },
  setup(props: any, context: any) {
    const documStore = useDocumStore();

    const {t} = useI18n()
    const page = reactive({
    });

  //   const callback = async (response: any) => {
  //   // This callback will be triggered when the user selects or login to
  //   // his Google account from the popup
  //   console.log("Handle the response", response)
  //   dataFromLoginApi.value = JSON.stringify(response)
  //   await getData(response.credential)
  // }
  const callback = async (response: any) => {
  console.log("Access token", response.access_token);
  const userData = decodeCredential(response.credential)
  console.log("Handle the userData", userData)
  dataFromLoginApi.value = JSON.stringify(response)
    await getData(response.access_token)

};
const webAppClientIdForGoogleAPI = firebaseProjectConfig.webAppClientIdForGoogleAPI

  const dataFromLoginApi = ref();
  const dataFromFitnessApi = ref();
  const stepsBrutDataFromFitnessApi = ref();
  const stepsDataFromFitnessApi = ref();
  const accessToken = ref();
  const showDetail = ref()
  showDetail.value = false
  const loginStarted = ref()
  loginStarted.value = false
  
  const getData = async (token: string) => {
    const options = {
      url: "https://www.googleapis.com/fitness/v1/users/me/dataSources",
      method: "get" as Method,
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
    }
    try {
      const resAx = await axios(options);
      // if (resAx.status === 200) {
      //   console.error("callFunction " + JSON.stringify(resAx));
      // }
      // console.error("callFunction " + JSON.stringify(resAx));
      dataFromFitnessApi.value = resAx
    } catch (err) {
      console.error("callFunction " + err);
      dataFromFitnessApi.value = err
    }
  }

  const convertStepsDataset = (stepsDataset: any) => {
    return stepsDataset.reduce((acc: any, curr: any) => {
      if (curr && curr.point) {
        acc = acc + curr.point.reduce((acc2: any, curr2: any) => {
          if (curr2 && curr2.value) {
            acc2 = acc2 + curr2.value.reduce((acc3: any, curr3: any) => {
              if (curr3 && curr3.intVal) {
                acc3 = acc3 + curr3.intVal
              }
              return acc3
            }, 0)
          }
          return acc2
        }, 0)
      }
      return acc
    }, 0)
  }

  const convertStepsData = (response: any) => {
    if (response && response.data && response.data.bucket) {
      return response.data.bucket.map((dataForPeriod: any) => {
        return {
          start: new Date(parseInt(dataForPeriod.startTimeMillis, 10)).toLocaleString(),
          end: new Date(parseInt(dataForPeriod.endTimeMillis, 10)).toLocaleString(),
          totalNumberOfSteps: convertStepsDataset(dataForPeriod.dataset),

          stepsDataset: dataForPeriod.dataset.map((stepsBySource: any) => {
            return {
              source: stepsBySource.dataSourceId,
              type: stepsBySource.dataTypeName,
              points: stepsBySource.point
            }
          })
        }
      })
    }
    return []
  }
  
  const getStepsData = async (token: string) => {
    // const endTime = new Date()
    // const startTime = new Date()
    // startTime.setDate(startTime.getDate() - 30);
    const endTime = new Date(props.endTime)
    const startTime = new Date(props.startTime)

    const body = {
      "aggregateBy": [{
        "dataTypeName": "com.google.step_count.delta",
        "dataSourceId": "derived:com.google.step_count.delta:com.google.android.gms:estimated_steps"
      }],
      "bucketByTime": {
        // period: {
        //   type: "day",
        //   value: 1,
        // },
        "durationMillis": 86400000 
      },
      "startTimeMillis": startTime.getTime(),
      "endTimeMillis": endTime.getTime()
    }
    const data = {
      "aggregateBy": [
        {
          "dataTypeName": "com.google.step_count.delta",
          "dataSourceId": "derived:com.google.step_count.delta:com.google.android.gms:estimated_steps"
        }
      ],
      "bucketByTime": { "durationMillis": 86400000 }, // 1 day per bucket
      "startTimeMillis": startTime.getTime(),
      "endTimeMillis": endTime.getTime()
    }
    const options = {
      url: "https://www.googleapis.com/fitness/v1/users/me/dataset:aggregate",
      method: "post" as Method,
      data: data,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      },
    }
    try {
      const resAx = await axios(options);
      // if (resAx.status === 200) {
      //   console.error("callFunction " + JSON.stringify(resAx));
      // }
      // console.error("getStepsData " + JSON.stringify(resAx));
      stepsBrutDataFromFitnessApi.value = resAx
      const allPeriods = convertStepsData(resAx)
      stepsDataFromFitnessApi.value = allPeriods
      const sumAllPeriods = allPeriods.reduce((partialSum: number, period: any) => partialSum + period.totalNumberOfSteps, 0);
      updateStore(sumAllPeriods)
      // updateStore(sumAllPeriods + 1)
    } catch (err: any) {
      console.error("getStepsData ERROR " + err);
      stepsDataFromFitnessApi.value = []
      stepsBrutDataFromFitnessApi.value = err.toString()
    }
  }

const updateStore = async (newValue: number) => {
  context.emit("newFootStepsValueFromGoogleAPI", {
    newFootStepsFromGoogleAPI:Math.round(newValue)
  });
  
}

const loginTest = async () => {
  loginStarted.value = true
  await updateStore((new Date().getTime() / 1000) - 1696147000)

}

const login = async () => {
  loginStarted.value = true

  googleSdkLoaded((google) => {
      // google.accounts.oauth2.initCodeClient({
      //   client_id: webAppClientIdForGoogleAPI,
      //   scope: 'email profile openid https://www.googleapis.com/auth/fitness.activity.read https://www.googleapis.com/auth/fitness.location.read',
      //   callback: (response) => {
      //     console.log("Handle the response", response)
      //   }
      // }).requestCode()
      google.accounts.oauth2.initTokenClient({
        client_id: webAppClientIdForGoogleAPI,
        scope: 'email profile openid https://www.googleapis.com/auth/fitness.activity.read https://www.googleapis.com/auth/fitness.location.read',
        callback: async (response) => {
          // console.log("Handle the response", response)
          dataFromLoginApi.value = JSON.stringify(response)
          accessToken.value = response.access_token

          await getData(response.access_token)
          await getStepsData(response.access_token)

        }
      }).requestAccessToken()

    })
  }
  const refresh = async () => {
    await getStepsData(accessToken.value)

  }


  return {
      ...toRefs(page),
      t,
      callback,
      dataFromLoginApi,
      dataFromFitnessApi,
      stepsDataFromFitnessApi,
      stepsBrutDataFromFitnessApi,
      showDetail,
      accessToken,
      login,
      refresh,
    }
  },
  components: { 
  },

}


</script>
<style>
.c-container  > div {
  overflow: auto;
  max-height: 50vh;
}
.border {
  border: 1px solid green;
  margin: 10px;
  padding: 10px;
}
.debug {
  border: 1px solid orangered;
  margin: 10px;
  padding: 10px;
}
.detailSteps {
  border: 1px solid gray;
  margin: 10px;
  padding: 10px;
  font-size: 10px;
}
.status {
  color: red;
  margin: 10px;
  padding: 10px;
}

a {
  color: blue;
  text-decoration: underline; /* no underline */
}
</style>

<template>
  <AutoComplete v-model="selectedCountry1"  inputClass="inputClass"
    data-cy="cyGeoFromZipCodeInput"
    :suggestions="filteredCountries" @complete="searchCountry($event)" :field="getZip" 
    @clear="removeLocation()" :delay="800" :minLength="2"
    @item-select="itemSelect($event)">
    <template #item="slotProps">
      <div>{{slotProps.item["place name"]}} {{slotProps.item["postal code"]}}</div>
    </template>
  </AutoComplete>
</template>

<script lang="ts">
import { reactive, toRefs, ref, onMounted } from "vue";

import { UserProjectTypes } from  "@/engineproject/default/common/domainModel/userProjectTypes"
import { useDocumStore } from "@/engineproject/store/documStore";
import { useAppStore } from '@/engineproject/store/appStore';

import AutoComplete from 'primevue/autocomplete';

import { geohashForLocation } from "geofire-common";

export default {
  props: {
    currentLocation: {
      type: Object as () => UserProjectTypes.LocationPoint,
      required: false
    },
    noViewState: {
      type: Boolean,
      required: false,
      default: false
    },
    locateMeButton: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  setup(props: any, context: any) {
    const documStore = useDocumStore();
    const appStore = useAppStore()
    const page = reactive({
      //location: computed(() => props.currentLocation),
    });
    
    const filteredCountries = ref();
    const selectedCountry1 = ref();
    const countries = ref();

    const setLocation = (params: {latitude: any; longitude: any; zipcode: string; city: string}) => {
      context.emit('update:location', {
        LPLat: params.latitude,
        LPLng: params.longitude,
        LPZipCode: params.zipcode,
        LPGeoHash: geohashForLocation([params.latitude, params.longitude]),
        LPCity: params.city
      })
    }

    const itemSelect = (event: any) => {
      console.error("itemSelect event " + JSON.stringify(event))
      const obj = event.value
      setTimeout(()=> {
        setLocation({latitude: parseFloat(obj.latitude), longitude: parseFloat(obj.longitude), 
          zipcode: obj["postal code"], city: obj["place name"]})
      }, 10)
    };
    /*
    accuracy: "5"
"admin code1": "84"
"admin code2": "42"
"admin code3": "422"
"admin name1": "Auvergne-Rhône-Alpes"
"admin name2": "Loire"
"admin name3": "Arrondissement de Roanne"
coordinates: "45.9833,4.2167"
"country code": "FR"
latitude: "45.9833"
longitude: "4.2167"
"place name": "Régny"
"postal code": "42630"
    */
   const getZip = (obj: any) => {
     return obj["postal code"]
   }
    const searchCountry = (event: any) => {
      if (countries.value) {
        setTimeout(() => {
          if (!event.query.trim().length) {
            filteredCountries.value = [...countries.value];
          }
          else {
            const obj = countries.value.filter((country: any) => {
                return country["postal code"].toLowerCase().startsWith(event.query.toLowerCase());
            });
            filteredCountries.value = obj
          }
        }, 250);
      }
    };

    const removeLocation = () => {
      context.emit('update:location', undefined)
    }

    onMounted(async () => {
      setTimeout(async () => {
        countries.value = await documStore.dispatch("getZipcodes", {
          aZipcode: "01",
          appStore: appStore,
        });
      }, 50)
      if (props.currentLocation) {
        selectedCountry1.value = props.currentLocation.LPZipCode
      }
    })

    return {
      ...toRefs(page),
      removeLocation, 
      filteredCountries, 
      itemSelect,
      searchCountry,
      getZip,
      selectedCountry1
    }
  },
  components: { 
    AutoComplete, 
  },
}
</script>

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["title"]
const _hoisted_6 = {
  key: 2,
  class: "inlineSeparatedBlockContainer"
}
const _hoisted_7 = ["title"]
const _hoisted_8 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.aAdDoc.isAdDoc() === true)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.aUserTransaction !== undefined && _ctx.aUserTransaction[$props.aAdDoc.getADId()] !== undefined)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString($setup.t("itemYourTransactionsStatus")) + " " + _toDisplayString($setup.displayStatus(_ctx.aUserTransaction[$props.aAdDoc.getADId()].UTStatus)), 1)
            ]))
          : (_ctx.aUserProfile.isAdmin() === true || $props.aAdDoc.isOwner(_ctx.aUserProfile.getUPUserId()) === true)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createElementVNode("button", {
                    class: "button buttonCentered buttonClearBlue",
                    "data-cy": "cy*openForPromotionAdDocFromList",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.openForPromotion()), ["stop"])),
                    title: $setup.t('adDocPromotionRequestTitle')
                  }, _toDisplayString($setup.t("adDocPromotionRequest")), 9, _hoisted_5)
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", null, [
                  _createElementVNode("button", {
                    class: "button buttonCentered",
                    "data-cy": "cyAddTransactionAdDocFromList",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.openAndAddTransaction()), ["stop"])),
                    title: $setup.t('adDocParticipateRequestTitle')
                  }, _toDisplayString($setup.t("adDocParticipateRequest")), 9, _hoisted_7)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("button", {
                    class: "button buttonCentered",
                    "data-cy": "cySee%oreAdDocFromList",
                    title: $setup.t('adDocSeeMoreTitle')
                  }, _toDisplayString($setup.t("adDocSeeMore")), 9, _hoisted_8)
                ])
              ]))
      ]))
    : _createCommentVNode("", true)
}
<template>
  <div
    class="adDocumentViewMainPart"
    :class="
      aAdDoc.isFromPremiumAccount() === true
        ? ' documentFromPremiumAccount'
        : ''
    "
    @click="viewAdDocOnClick(aAdDoc)"
  >
    <AdDocActionButtonsInListOfDocs
      v-if="displayInModal === true"
      :aAdDoc="aAdDoc"
    ></AdDocActionButtonsInListOfDocs>
    <div class="documentViewMainPart">
      <slot
        name="content"
        :aAdDoc="aAdDoc"
        :indexDocInList="indexDocInList"
        :displayCommentsLikesOnly="displayCommentsLikesOnly"
      ></slot>
    </div>
    <SocialNetworksButtons
      v-if="displayFromList !== true && displayInModal !== true && 1 === 3 + 2"
      :aAdDoc="aAdDoc"
    ></SocialNetworksButtons>
    <AdDocumentPromotion
      v-if="displayFromList !== true && displayInModal !== true"
      :aAdDoc="aAdDoc"
    ></AdDocumentPromotion>
  </div>
</template>

<script lang="ts">
import { computed, reactive, toRefs } from "vue";
import { useAppStore } from "@/engineproject/store/appStore";
import { useDocumStore } from "@/engineproject/store/documStore";
import { useUserStore } from "@/engineproject/store/userStore";
import { AdDocumentProjectTypes } from "@/engineproject/default/common/domainModel/adDocumentProjectTypes";

import SocialNetworksButtons from "@/engineproject/components/SocialNetworks/SocialNetworksButtons.vue";
import AdDocumentPromotion from "@/engineproject/components/Promotion/AdDocumentPromotion.vue";
import AdDocActionButtonsInListOfDocs from "@/engineproject/components/AdDocAction/AdDocActionButtonsInListOfDocs.vue";

export default {
  props: {
    aAdDoc: {
      type: Object as () => AdDocumentProjectTypes.AdDocument,
      required: true,
    },
    displayInModal: {
      type: Boolean,
      required: true,
    },
    displayFromList: {
      type: Boolean,
      required: true,
    },
    indexDocInList: {
      type: Number,
      required: false,
    },
    displayCommentsLikesOnly: {
      type: Boolean,
      required: true,
    },
  },
  setup(props: any, context: any) {
    const appStore = useAppStore();
    const documStore = useDocumStore();
    const userStore = useUserStore();
    const page = reactive({});

    const viewAdDocOnClick = (aAdDoc: AdDocumentProjectTypes.AdDocument) => {
      // console.error("viewAdDocOnClick props.displayFromList " + props.displayFromList + " props.displayInModal " + props.displayInModal)
      if (props.displayInModal === true) {
        return;
      }
      if (props.displayFromList === false) {
        return;
      }
      documStore.dispatch("openAdDocInModal", {
        aAdDoc: aAdDoc,
        appStore: appStore,
        userStore: userStore,
      });
    };
    return {
      ...toRefs(page),
      viewAdDocOnClick,
    };
  },
  components: {
    SocialNetworksButtons,
    AdDocumentPromotion,
    AdDocActionButtonsInListOfDocs,
  },
};
</script>


import { firebaseProjectConfig } from "@/projects/firebaseProjectUsedByDefault"
import {du, wikimediaSubDir} from "@/engineproject/store/filterAttributesTools"


//const du = "https://firebasestorage.googleapis.com/v0/b/alomigoid.appspot.com/o/DefaultImages%2Fwikimedia.org%2F"

const duUsingProjectConfig = du(firebaseProjectConfig)
const duniSport = duUsingProjectConfig + "uniSport" + wikimediaSubDir
const duniEntertainment = duUsingProjectConfig + "uniEntertainment" + wikimediaSubDir
const duniTravel = duUsingProjectConfig + "uniTravel" + wikimediaSubDir
const duniSocial = duUsingProjectConfig + "uniSocial" + wikimediaSubDir
const duniOther = duUsingProjectConfig + "uniOther" + wikimediaSubDir

const defUrlsSport1 = {
    "Rando": [
      {
        docUrl: duniSport + "800px-Tour_to_the_Quebrada_de_las_Conchas.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "0b4fcd33-3ebb-4afc-80ae-3c969fcbc2be",
        docLicense:"Jlla00, CC BY-SA 3.0, via Wikimedia Commons"
      },
      {
        docUrl: duniSport + "800px-Alta_via_meranese_2005-05.JPG?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "2020fd1a-8da6-47a8-a638-4c4b5a4ed612",
        docLicense:""
      },
      {
        docUrl: duniSport + "800px-Br%C3%A9hat_22_Marcheurs_Paon_2010.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "94aab768-32f5-43f9-b748-1fdbeb979681",
        docLicense:"JLPC, CC BY-SA 3.0, via Wikimedia Commons"
      },
    ],
    "DefiPodometre": [
      {
        docUrl: duniSport + "800px-Tour_to_the_Quebrada_de_las_Conchas.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "0b4fcd33-3ebb-4afc-80ae-3c969fcbc2be",
        docLicense:"Jlla00, CC BY-SA 3.0, via Wikimedia Commons"
      },
      {
        docUrl: duniSport + "800px-Alta_via_meranese_2005-05.JPG?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "2020fd1a-8da6-47a8-a638-4c4b5a4ed612",
        docLicense:""
      },
      {
        docUrl: duniSport + "800px-Br%C3%A9hat_22_Marcheurs_Paon_2010.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "94aab768-32f5-43f9-b748-1fdbeb979681",
        docLicense:"JLPC, CC BY-SA 3.0, via Wikimedia Commons"
      },
    ],
    "VTT": [
      {
        docUrl: duniSport + "Rando_VTT.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "1db26bfe-a5ba-4eea-af4f-742fe03cf4c9",
        docLicense:"La Cigale sur Wikipédia français, Public domain, via Wikimedia Commons"
      },
      {
        docUrl: duniSport + "800px-Mtb_in_snow_crop.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "642fa402-9c75-4842-813b-7d7f00c69188",
        docLicense:"Original image photographed by WolfgangE*derivative work: – Kerαunoςcopia◁galaxies, CC BY 2.5, via Wikimedia Commons"
      },
      {
        docUrl: duniSport + "800px-All_mountain_au_col_de_la_Seigne_France_-Italie.JPG?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "a32743ba-1eac-4f9c-b4bd-1f971495a5e0",
        docLicense:"le ded ©, CC BY-SA 3.0, via Wikimedia Commons"
      },
    ]
}


const defUrlsSport2 = {
    "FootEnSalle": [
      {
        docUrl: duniSport + "Aoba_Sports_park_-_soccer_court.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "96a1b324-fa19-4270-a86e-f8f9b089153f",
        docLicense:"Yoh-Plus, CC BY 4.0, via Wikimedia Commons"
      },
      {
        docUrl: duniSport + "2018-10-08_Futsal_IRQ_vs_SVK_Boys_First_Round_at_2018_Summer_Youth_Olympics_by_Sandro_Halank%E2%80%93066.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "23771e6b-a004-4fbe-a83c-13263953bbce",
        docLicense:"Sandro Halank, Wikimedia Commons, CC BY-SA 4.0, CC BY-SA 4.0, via Wikimedia Commons"
      },
      {
        docUrl: duniSport + "Partido_Guadalcac%C3%ADn_FS_CD-Viaxes_Amarelle_FS_2019-P1360408.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "372667c3-7f6a-4602-b267-49e7d6569ee9",
        docLicense:"El Pantera, CC BY-SA 4.0, via Wikimedia Commons"
      },
      {
        docUrl: duniSport + "Partido_Guadalcac%C3%ADn_FS_CD-Viaxes_Amarelle_FS_2019-P1360402.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "c3be9e06-758d-488f-bf0b-18bbb9fd29a9",
        docLicense:"El Pantera, CC BY-SA 4.0, via Wikimedia Commons"
      },
    ],
    "Squash": [
      {
        docUrl: duniSport + "La_salle_de_squash.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "c9ea42c8-379a-4caf-81b8-04a1e7af4906",
        docLicense:"PASCALOUE, CC BY-SA 3.0, via Wikimedia Commons"
      },
      {
        docUrl: duniSport + "Aberdeen_Tennis_Squash_Centre.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "88639fd2-ce76-46be-83f1-276ecd889b3d",
        docLicense:"Exploringlife, CC BY-SA 4.0, via Wikimedia Commons"
      },
      {
        docUrl: duniSport + "Squash_players.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "ac38e647-29ad-4d12-a606-589bf838c287",
        docLicense:"Steve McFarland, CC BY-SA 2.0, via Wikimedia Commons"
      },
    ],
    "Crossfit": [
      {
        docUrl: duniSport + "Crossfit_gym_cz.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "f68fa762-fd34-4602-80ad-dc750979741e",
        docLicense:"IKjub, CC BY-SA 4.0, via Wikimedia Commons"
      },
      {
        docUrl: duniSport + "Crossfit_Basel.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "752ddecd-aeca-4dc7-bfb5-05c2f5d36e3a",
        docLicense:"Big Titan, CC BY-SA 2.0, via Wikimedia Commons"
      },
      {
        docUrl: duniSport + "Superman_crossfit.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "b4093365-2418-4609-ba53-b5cbbdace625",
        docLicense:"Ex Machina 007, CC BY-SA 3.0, via Wikimedia Commons"
      },
    ]
}

/*
"Yoh-Plus, CC BY 4.0, via Wikimedia Commons",
"Sandro Halank, Wikimedia Commons, CC BY-SA 4.0, CC BY-SA 4.0, via Wikimedia Commons",
"El Pantera, CC BY-SA 4.0, via Wikimedia Commons",
"El Pantera, CC BY-SA 4.0, via Wikimedia Commons",

"PASCALOUE, CC BY-SA 3.0, via Wikimedia Commons",
"Exploringlife, CC BY-SA 4.0, via Wikimedia Commons",
"Steve McFarland, CC BY-SA 2.0, via Wikimedia Commons",

"IKjub, CC BY-SA 4.0, via Wikimedia Commons",
"Big Titan, CC BY-SA 2.0, via Wikimedia Commons",
"Ex Machina 007, CC BY-SA 3.0, via Wikimedia Commons",
*/

const defUrlsSportAll = {
    "default": [
      {
        docUrl: duniSport + "La_horde_-_Surfers_riding_a_wave_in_Paea%2C_Tahiti_small.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "75bb5d20-7e2b-47b5-be4d-4c76c1c35632",
        docLicense:'Brigittebourger Brigitte Bourger, CC BY-SA 4.0, via Wikimedia Commons'
      },
    ],
}

/*
"Brigittebourger Brigitte Bourger, CC BY-SA 4.0, via Wikimedia Commons",
*/
const defUrlsSport   = {
  uniSport: {
    ...defUrlsSport1,
    ...defUrlsSport2,
    ...defUrlsSportAll,
    }
}


const defUrlsEntertainmentAll = {
  "default": [
    {
      docUrl: duniEntertainment + "800px-Berlinale2007.jpg?alt=media",
      docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "e0db8e7e-a5dc-46e0-8e3f-54ca3b20fbc3",
      docLicense:'Maharepa, CC BY 2.0, via Wikimedia Commons'
    },
  ],
}

// catFoodRestaurant,
const defUrlsEntertainment1 = {
  "Restaurant": [
    {
      docUrl: duniEntertainment + "Vegetarian_Curry.jpeg?alt=media",
      docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "a5cf47cb-4e4c-4f28-8c2d-253735e79325",
      docLicense:"Jlla0GracinhaMarco Abundo, CC BY 2.0 , via Wikimedia Commons"
    },
    {
      docUrl: duniEntertainment + "800px-Pot-au-feu2.jpg?alt=media",
      docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "b2733118-2e55-4810-bc75-15ead9a2a76a",
      docLicense:"Andre, CC BY-SA 3.0, via Wikimedia Commons"
    },
  ],
  // NO MORE USED
  /*
  "Indian": [
    {
      docUrl: duniEntertainment + "Vegetarian_Curry.jpeg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "a5cf47cb-4e4c-4f28-8c2d-253735e79325",
      docLicense:"Jlla0GracinhaMarco Abundo, CC BY 2.0 , via Wikimedia Commons"
    },
  ],
  "Organic": [
    {
      docUrl: duniEntertainment + "800px-Pot-au-feu2.jpg?alt=media",
        docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "b2733118-2e55-4810-bc75-15ead9a2a76a",
      docLicense:"Andre, CC BY-SA 3.0, via Wikimedia Commons"
    },
  ]
  */
}

// catDrink,
const defUrlsEntertainment3 = {
  ...defUrlsEntertainment1,
  "Nightclub": [
    {
      docUrl: duniEntertainment + "Nightclub-in-neon-108681294533967qk7.jpg?alt=media",
      docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "b2b30829-f6a0-4119-b4d5-6a23b5601ce7",
      docLicense:"Martin Birkin, CC0, via Wikimedia Commons"
    },
  ],
  "Bar": [
    {
      docUrl: duniEntertainment + "Interieur_muziekcaf%C3%A9__Hof_van_Heden_Korte_Veerstraat_3._NL-HlmNHA_54015340-Uitsnede1.jpg?alt=media",
      docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "15256cf7-7477-459b-8b8e-72908828f997",
      docLicense:"Arnaud 25, Public domain, via Wikimedia Commons"
    },
  ],
  "Cafe": [
    {
      docUrl: duniEntertainment + "512px-Caf%C3%A9_de_Flore.jpg?alt=media",
      docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "7540c028-4752-42b3-b4a5-610294cd58fc",
      docLicense:"Fotopersbureau de Boer, CC0, via Wikimedia Commons"
    },
  ]
}

// catCulture
const defUrlsEntertainment2 = {
  "Cinema": [
    {
      docUrl: duniEntertainment + "Comoedia-Lyon.JPG?alt=media",
      docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "3208e407-e66e-48f4-a186-f1d895434684",
      docLicense:"TwoWings, Public domain, via Wikimedia Commons"
    },
    {
      docUrl: duniEntertainment + "Marseille_Panier_Movie_Theater.jpg?alt=media",
      docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "3880bc14-4580-4e72-9843-d04c6aafeb5e",
      docLicense:"Benh LIEU SONG, CC BY-SA 3.0, via Wikimedia Commons"
    },
  ],
  "Theater": [
    {
      docUrl: duniEntertainment + "800px-3%C3%A8me_Coup_de_th%C3%A9%C3%A2tre_-_Cyrano_de_Bergerac_sur_le_Parvis_de_la_Pr%C3%A9fecture_dAnnecy_2014.jpg?alt=media",
      docTokenUrl: "684712",
        docTokenUrlNOMOREUSED: "256f137d-4879-49af-a3e4-6df35659d70a",
      docLicense:"Julie Agitateurs, CC BY-SA 4.0, via Wikimedia Commons"
    },
  ]
}


const defUrlsEntertainment = {
  uniEntertainment: {
    //...defUrlsEntertainment1,
    ...defUrlsEntertainment2,
    ...defUrlsEntertainment3,
    ...defUrlsEntertainmentAll,
  }
}

export const defUrls = {
  ...defUrlsSport,
  ...defUrlsEntertainment
} as any
  
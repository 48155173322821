<template>
<div v-if="aAdDoc !== undefined && aAdDoc.canBeSharedAndGetPromotion()" class="shareButtonContainer">
 <a href="javascript:void(0);"
    @click="share(network)"
    :class="'share-network-' + network.network"
    v-for="network in networks"
    :network="network.network"
    :key="network.network"
    :style="{backgroundColor: network.color}"
    :url="sharing.url"
    :title="sharing.title"
    :description="sharing.description"
    :quote="sharing.quote"
    :hashtags="sharing.hashtags"
    :twitterUser="sharing.twitterUser"
  >
    <i :class="network.icon"></i>
    <span>{{ network.name }}</span>
  </a>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs, computed } from "vue";

import {AvailableNetworks} from './network'
import { AdDocumentProjectTypes } from '@/engineproject/default/common/domainModel/adDocumentProjectTypes';

export default {
  props: {
    aAdDoc: {
      type: Object as () => AdDocumentProjectTypes.AdDocument,
      required: true,
    },
    popup: {
      type: Object,
      default: () => ({
        width: 626,
        height: 436
      })
    },
},
  setup(props: any, context: any) {
    const sharing = {
        url: props.aAdDoc.getADDocUrlForOGAndSEO(), //'https://news.vuejs.org/issues/180',
        title: props.aAdDoc.getADTitle(), //'Say hi to Vite! A brand new, extremely fast development setup for Vue.',
        description:  props.aAdDoc.getADDescription(), //'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
        quote: props.aAdDoc.getADDescription().substr(0, 100), //'The hot reload is so fast it\'s near instant. - Evan You',
        hashtags: 'alomigo',
        twitterUser: ''
      }
    const networks = [
        // { network: 'baidu', name: 'Baidu', icon: 'fas fah fa-lg fa-paw', color: '#2529d8' },
        // { network: 'buffer', name: 'Buffer', icon: 'fab fah fa-lg fa-buffer', color: '#323b43' },
        { network: 'facebook', name: 'Facebook', icon: 'pi pi-facebook fab fah fa-lg fa-facebook-f', color: '#1877f2' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
        { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
        { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
        { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff' },
        { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
        { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
        { network: 'pinterest', name: 'Pinterest', icon: 'fab fah fa-lg fa-pinterest', color: '#bd081c' },
        { network: 'pocket', name: 'Pocket', icon: 'fab fah fa-lg fa-get-pocket', color: '#ef4056' },
        { network: 'quora', name: 'Quora', icon: 'fab fah fa-lg fa-quora', color: '#a82400' },
        { network: 'reddit', name: 'Reddit', icon: 'fab fah fa-lg fa-reddit-alien', color: '#ff4500' },
        { network: 'skype', name: 'Skype', icon: 'fab fah fa-lg fa-skype', color: '#00aff0' },
        { network: 'stumbleupon', name: 'StumbleUpon', icon: 'fab fah fa-lg fa-stumbleupon', color: '#eb4924' },
        { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
        { network: 'tumblr', name: 'Tumblr', icon: 'fab fah fa-lg fa-tumblr', color: '#35465c' },
/*        { network: 'instapaper', name: 'Instapaper', icon: 'fas fah fa-lg fa-italic', color: '#428bca' },
        { network: 'evernote', name: 'Evernote', icon: 'fab fah fa-lg fa-evernote', color: '#2dbe60' },
        { network: 'flipboard', name: 'Flipboard', icon: 'fab fah fa-lg fa-flipboard', color: '#e12828' },
        { network: 'hackernews', name: 'HackerNews', icon: 'fab fah fa-lg fa-hacker-news', color: '#ff4000' },
        { network: 'line', name: 'Line', icon: 'fab fah fa-lg fa-line', color: '#00c300' },
        { network: 'odnoklassniki', name: 'Odnoklassniki', icon: 'fab fah fa-lg fa-odnoklassniki', color: '#ed812b' },
        { network: 'viber', name: 'Viber', icon: 'fab fah fa-lg fa-viber', color: '#59267c' },
        { network: 'vk', name: 'Vk', icon: 'fab fah fa-lg fa-vk', color: '#4a76a8' },
        { network: 'weibo', name: 'Weibo', icon: 'fab fah fa-lg fa-weibo', color: '#e9152d' },
        { network: 'wordpress', name: 'Wordpress', icon: 'fab fah fa-lg fa-wordpress', color: '#21759b' },
        { network: 'xing', name: 'Xing', icon: 'fab fah fa-lg fa-xing', color: '#026466' },
        { network: 'yammer', name: 'Yammer', icon: 'fab fah fa-lg fa-yammer', color: '#0072c6' },
        { network: 'fakeblock', name: 'Custom Network', icon: 'fab fah fa-lg fa-vuejs', color: '#41b883' }*/
      ]
    const page = reactive({
      networksObject: computed(() => Object.assign(AvailableNetworks, networks || {})),
      popupTop: 0,
      popupLeft: 0,
      popupWindow: undefined as any,
      popupInterval: null as any     
    });

    const rawLink = (key: string) => {
      const ua = navigator.userAgent.toLowerCase()

      /**
       * On IOS, SMS sharing link need a special formatting
       * Source: https://weblog.west-wind.com/posts/2013/Oct/09/Prefilling-an-SMS-on-Mobile-Devices-with-the-sms-Uri-Scheme#Body-only
       */
      if (key === 'sms' && (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1)) {
        return page.networksObject[key].replace(':?', ':&')
      } 
        return page.networksObject[key as keyof Array<string> ]

    }
    const encodedHashtags = (key: string, hashtags: string) => {
      if (key === 'facebook' && hashtags.length) {
        return '%23' + hashtags.split(',')[0]
      }

      return hashtags
    }
    /**
     * Create the url for sharing.
     */
    const shareLink = (aNetwork: any) => {
      let link = rawLink(aNetwork.network) as string

      const networkObj = page.networksObject[aNetwork.network as keyof Array<string> ] as any
      console.error("networkObj " + JSON.stringify(networkObj))
      /**
       * Twitter sharing shouldn't include empty parameter
       * Source: https://github.com/nicolasbeauvais/vue-social-sharing/issues/143
       */
      if (aNetwork.network === 'twitter') {
        if (!sharing.hashtags.length) link = link.replace('&hashtags=@h', '')
        if (!sharing.twitterUser.length) link = link.replace('@tu', '')
      }

      const ret =  link
        .replace(/@tu/g, '&via=' + encodeURIComponent(sharing.twitterUser))
        .replace(/@u/g, encodeURIComponent(sharing.url))
        .replace(/@t/g, encodeURIComponent(sharing.title))
        .replace(/@d/g, encodeURIComponent(sharing.description))
        .replace(/@q/g, encodeURIComponent(sharing.quote))
        .replace(/@h/g, encodedHashtags(aNetwork.network, sharing.hashtags))
        .replace(/@m/g, encodeURIComponent(props.media))

      console.error("shareLink " + ret)
      return ret
    }

     const share = (aNetwork: any) => {
      console.error("aNetwork " + JSON.stringify(aNetwork))
     try {
      page.popupWindow = window.open(
        shareLink(aNetwork),
        'sharer-' + aNetwork.key,
        ',height=' + props.popup.height +
        ',width=' + props.popup.width +
        ',left=' + page.popupLeft +
        ',top=' + page.popupTop +
        ',screenX=' + page.popupLeft +
        ',screenY=' + page.popupTop
      )

     } catch(e) {
       console.error("error in shareLink: " + e)
     }
      // If popup are prevented (AdBlocker, Mobile App context..), popup.window stays undefined and we can't display it
      if (!page.popupWindow) return

      page.popupWindow.focus()
    }
    return {
      ...toRefs(page),
      sharing,
      networks,
      share,
    };
  },
};
</script>
<style lang="scss" scoped>
a[class^="share-network-"] {
    flex: none;
    color: #FFFFFF;
    background-color: #333;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px 10px 0;
    text-decoration: none;
}
.shareButtonContainer {
  > a {
    margin: 2px;
    padding: 4px;
    display: inline-block;
  }
}
</style>

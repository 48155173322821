<template>
  <div class="profileListViewItemRightPart">
    <div>
      <input
        type="checkbox"
        v-model="allowedChecked"
        @click="allowedEnterpriseClicked($event)"/>

      {{ aEnterpriseInfo.OEIData.EIName }}
      {{ aEnterpriseInfo.OEIData.EISearchId }}
      {{ aEnterpriseInfo.OEIData.EIEnterpriseId }}
    </div>
  </div>
</template>

<script lang="ts">
import { inject, reactive, toRefs, computed } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";
import { useUserStore } from '@/engineproject/store/userStore';
import { useAppStore } from '@/engineproject/store/appStore';

import { EnterpriseInfoProjectObject } from '@/engineproject/default/common/domainModel/enterpriseInfoProjectToolsTypes';
import { UserProfileProjectObject } from "@/projects/alomigo/common/domainModel/userProfileProjectToolsTypes";


export default {
  props: {
    aUserProfile: {
      type: Object as () => UserProfileProjectObject,
      required: true,
    },
    aEnterpriseInfo: {
      type: Object as () => EnterpriseInfoProjectObject,
      required: true
    },
    allowedEnterprise: {
      type: Boolean,
      default: false
    }
  },

  setup(props: any, context: any) {
    const appStore = useAppStore();
    const userStore = useUserStore();

    const formatDate = inject('formatDate') as any;
    const {t} = useI18n()
    const page = reactive({
      allowedChecked: computed(() => props.allowedEnterprise)
    });
    

    const allowedEnterpriseClicked = async () => {
      const checked = page.allowedChecked
      if (checked === false) {
          await userStore.dispatch("addNewAllowedEnterprise", {
            newAllowedEnterprise: props.aEnterpriseInfo.exportToFirebaseObject(),
            inContactUserId: props.aUserProfile.getUPUserId(), 
            appStore
          })
      } else {
          await userStore.dispatch("removeAllowedEnterprise", {
            enterpriseId: props.aEnterpriseInfo.getEIEnterpriseId(),
            inContactUserId: props.aUserProfile.getUPUserId(), 
            appStore
          })
      }
      const result = await userStore.dispatch("resetAllowedEnterprisesForUser", {
          aUserProfile: props.aUserProfile,
          appStore,
        });

    }


    return {
      ...toRefs(page),
      formatDate,   
      allowedEnterpriseClicked,
      t
    }
  },
  components: {
  },
}
</script>

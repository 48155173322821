<template>
    <div>
      <Dropdown class="" v-model="selectedEnterprise" 
        v-on:change="onChange($event.value)"
        placeholder="..." :options="publicAndAllowedEnterprises" optionValueKKK="EISearchId" optionLabelLLL="EIName">
        <template #value="slotProps">
          <div class="" v-if="slotProps.value">
            {{buildTitle(slotProps.value)}}
          </div> 
        </template>
        <template #option="slotProps">
          <div class="">
            <span class="">{{buildTitle(slotProps.option)}}</span>
          </div>
        </template>
      </Dropdown>
    </div>
<!--
    selectedEnterprise<EnterpriseDisplay :aEnterprise="selectedEnterprise"></EnterpriseDisplay>
    <EnterpriseDisplay :aEnterprise="aEnterprise"></EnterpriseDisplay>
    -->
        <!--<template #value="slotProps">
          <div class="country-item country-item-value" v-if="slotProps.value">
            {{buildTitle(slotProps.value)}}
          </div> 
        </template>
        <template #option="slotProps">
          <div class="country-item">
            <span class="titleInOptions">{{buildTitle(slotProps.option)}}</span>
          </div>
        </template>
        -->

</template>

<script lang="ts">
import { toRefs, reactive, computed, watch } from 'vue'
import { useI18n } from "@/engineproject/default/common/i18n";

//import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import EnterpriseDisplay from "@/engineproject/components/Enterprise/EnterpriseDisplay.vue";
import EditSelect from '@/engineproject/components/EditThemeAndCats/EditSelect.vue';


import { useAppStore } from '@/engineproject/store/appStore';
import { useUserStore } from '@/engineproject/store/userStore';
import { useEnterpriseStore } from '@/engineproject/store/enterpriseStore';
import { UserTypes } from "@/engineproject/domainModel/userTypes"
import Dropdown from 'primevue/dropdown';
import { allPublicWithoutEnterprise, EnterpriseInfoTypes } from '@/engineproject/domainModel/enterpriseInfoTypes';
import { EnterpriseInfoProjectObject } from '@/projects/alomigo/common/domainModel/enterpriseInfoProjectToolsTypes';

export default {
  props: {
    aEnterprise: {
      type: Object as () => EnterpriseInfoTypes.EnterpriseInfo,
      required: true,
    },
    onSelectedEnterprise: {
      type: Function as () => any,
      required: true
    },
    allowedEnterprises: {
      type: Array as () => EnterpriseInfoTypes.EnterpriseInfo[],
      required: true,
    },
  },

  setup(props: any, context: any) {
    const userStore  = useUserStore()
    const page = reactive({
      userEnterprise: computed(() => (userStore.state.aUserProfile.getUPEnterprise())),
      selectedEnterprise: computed(() => (props.aEnterprise /*|| userStore.state.aUserProfile.getUPEnterprise() || allPublicWithoutEnterprise*/)),
      publicAndAllowedEnterprises: computed(() => {
        //console.error('userStore.state.aUserProfile.getUPEnterprise() '  +  JSON.stringify(userStore.state.aUserProfile.getUPEnterprise()))
        return (userStore.state.aUserProfile.getUPEnterprise() ? [userStore.state.aUserProfile.getUPEnterprise() ] : []).concat([
        allPublicWithoutEnterprise,
          ...props.allowedEnterprises])
      }),
      //selectedEnterprise: props.aEnterprise, 
    });
    const onChange = (newEnterprise: any) => {
      console.error('onchange newValue ' + JSON.stringify(newEnterprise))
      props.onSelectedEnterprise(/*(newEnterprise.EISearchId !== "*") ? */newEnterprise/* : null*/)
    }
    const buildTitle = (param: any) => {
      if (param.EISearchId) {
        return (param.EISearchId !== "*") ? "salariés de " + param.EIName : param.EIName
      }
      return "Sélectionner une entreprise"
    }

    return { 
      ...toRefs(page),
      buildTitle,
      onChange,
    }
  },
  components: { 
    Dropdown,
    //EnterpriseDisplay,
  },
};
</script>

<style lang="scss" scoped>
input {
  width: 100%;
}

button {
  display: block;
  margin-top: 10px;
}
</style>

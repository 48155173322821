<template>
  <div class="partnerPromotionViewButtons">
    <span>{{t(posterElementId + 'Title')}}</span> 
    <span class="pi pi-eye" :title="t('previewButton')"
        @click="vueToHtml(posterElementId + 'Id', true)">
    </span>
    <span class="pi pi-print" :title="t('printButton')"
        @click="vueToHtml(posterElementId + 'Id')">
    </span>
    <span  v-if="expandState===false" @click="$emit('update:expandState', !expandState)" 
      class="pi pi-sliders-v"  :title="t('expandButton')">
    </span>
    <span  v-else-if="expandState===true" @click="$emit('update:expandState', !expandState)"
      class="pi pi-chevron-up"  :title="t('reduceButton')">
    </span>
  </div>

</template>
<script lang="ts">
import { getCurrentInstance } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import {styleRulesA4Vertical, styleRulesPoster} from './PagePreviewAndPrintCSSRules'

export default {
  props: {
    posterElementId: {
      type: String,
      required: true
    },
    posterAdditionalClass: {
      type: String,
      required: false,
      default: ""
    },
    expandState: {
      type: Boolean,
      required: false,
    },
  },
  setup(props: any, context: any) {
    const {t} = useI18n()
    const internalInstance = getCurrentInstance() // works
    const vueToHtmlNow = (domId: string, previewAction: boolean) => {
      console.log("vueToHtmlNow " + internalInstance)
      if (internalInstance) {
        console.log(internalInstance.appContext.config.globalProperties.$vueToHtml) 

       /*
       https://stackoverflow.com/questions/16649943/css-to-set-a4-paper-size
       http://jsfiddle.net/mturjak/2wk6Q/1949/
       styleRulesA5
       */
        internalInstance.appContext.config.globalProperties.$vueToHtml(domId, {
          previewAction: previewAction,
          styleTextRules: styleRulesA4Vertical + styleRulesPoster + 
            props.posterAdditionalClass
        })
      }
    }

    const vueToHtml = (domId: string, previewAction: boolean) => {
      console.log("vueToHtml " + internalInstance)
      if (props.expandState === false) {
        context.emit('update:expandState', true)
        setTimeout(() => {
          vueToHtmlNow(domId, previewAction)
        }, 1000)
      } else {
        vueToHtmlNow(domId, previewAction)
      }
    }
    return { 
      t,
      vueToHtml
    }
  },
}


</script>

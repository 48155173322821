import { UserTypes } from "../userTypes";
import { AdDocAPIClient } from "../../backend/AdDocFirebase";
import { AdDocTransactionsAPIClient } from "../../backend/AdDocTransactionsFirebase";
import { adDocFirebase, userFirebase } from "../../backend/firebase";
import { UserAPIClient } from "../../backend/UserFirebase";
import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes';
import { UserProfileProjectObject } from "../../default/common/domainModel/userProfileProjectToolsTypes";
import { UserProjectTypes } from "@/engineproject/default/common/domainModel/userProjectTypes";


/*
Domain Model, and only Domain Model:
- no vue3.js object or code or concept
- just domain objects AdDocumentProjectObject, UserProjectTypes.UserProfile, ...
- Dependency Inversion Principle (DIP) regarding the lower backend modules (xxxAPIClient)

Design issues:
- some APIClient methods, return a low level object (Firebase Document) in order to
  improve the performances. This Firebase Object is just pass back to the store, and the store
  will give it back.
  => solution : implement a cache in the low level code using the Doc Id. The Firebase Document is not
  returned but moved in the library cache. When the action can use the cached Firebase Doc, the
  low level code should be able to do it!
*/
export class AdDocumentTransactionsControler  {
  adDocBackendAPI?: AdDocAPIClient;
  adDocTransactionsBackendAPI?: AdDocTransactionsAPIClient;
  setAPI(aDocAPI: AdDocAPIClient, aDocTransactionsBackendAPI: AdDocTransactionsAPIClient) {
    this.adDocBackendAPI = aDocAPI
    this.adDocTransactionsBackendAPI = aDocTransactionsBackendAPI
  }

  async getMyActiveTransactionsWithAll(options: any) {

    return await this.adDocTransactionsBackendAPI?.getMyActiveTransactionsWithAll(options)

  }

  async getActiveTransactionsWithAContact(options: any) {

    return await this.adDocTransactionsBackendAPI?.getActiveTransactionsWithAContact(options)

  }

  async addTransactionAdDocCreateStep(transactionsCol: any, aAdDoc: AdDocumentProjectObject, docId: string, 
    aUserMiniProfile: UserProjectTypes.UserMiniProfile, 
    addTransactionText: string) {

      return await this.adDocTransactionsBackendAPI?.addTransactionAdDocCreateStep(transactionsCol, aAdDoc, docId, 
        aUserMiniProfile, 
        addTransactionText)
  
    }

    async removeMyTransactionAdDoc(aAdDoc: AdDocumentProjectObject, 
    aUserId: string) {

      return await this.adDocTransactionsBackendAPI?.removeMyTransactionAdDoc(aAdDoc, 
        aUserId)
  
    }

    async updateTransactionAdDoc(updateParams: any, 
      aUserId: string) {
  
        return await this.adDocTransactionsBackendAPI?.updateTransactionAdDoc(updateParams, 
          aUserId)
    
      }

    async updateFootStepCounterInTransactionAdDoc(updateParams: any, 
      aUserId: string) {
  
        return await this.adDocTransactionsBackendAPI?.updateFootStepCounterInTransactionAdDoc(updateParams, 
          aUserId)
    
      }
        
    async addTransactionAdDoc(aAdDoc: AdDocumentProjectObject,
      aUserMiniProfile: UserProjectTypes.UserMiniProfile,
      addTransactionText: string) {

      return await this.adDocTransactionsBackendAPI?.addTransactionAdDoc(aAdDoc,
        aUserMiniProfile,
        addTransactionText)
  
    }

    async getMyTransactionsForAdDoc(ADId: string, ADOwnerId: string, 
    aUserId: string) {

      return await this.adDocTransactionsBackendAPI?.getMyTransactionsForAdDoc(ADId, ADOwnerId, 
        aUserId)
  
    }
  

  async getAllTransactionsForAdDoc(options: any) {

    return await this.adDocTransactionsBackendAPI?.getAllTransactionsForAdDoc(options)

  }
}

// console.error("adDocFirebase in adDocumentToolsTypes 1 " + adDocFirebase)


export const adDocumentTransactionsControler = new AdDocumentTransactionsControler()


//import  "@/engineproject/domainModel/userProjectTypes"
import { UserProfileObject } from "@/engineproject/domainModel/userToolsTypes";
import { UserTypes } from "@/engineproject/domainModel/userTypes";
import { UserProjectTypes } from "./userProjectTypes";



export class UserProfileProjectObject extends UserProfileObject  {
  //constructor(src: UserTypes.User);
  constructor();
  constructor(src: UserTypes.UserProfile);
  constructor(src: UserProjectTypes.UserProfile);
  constructor(src?: any) {
    super(src)
    // console.error(" IN constructor " + JSON.stringify(this))
    // console.error(" IN constructor " + this.getADId())
    this.setMandatoryDataFieldNames([
      "UPName","UPEmail","UPPhoneNumber",
      "UPPictureUrl"
    ])
  }

  deepCopyProjectUserProfile = () => {
    const newDoc = new UserProfileProjectObject(this.OUPData);
    return newDoc 
  }
  detectChangesInProjectUserProfile = (newUserProfileProject: UserProfileProjectObject) => {
    return this.detectChangesInUserProfile(newUserProfileProject)
  }
  updateUserProfile = (newOUPData: UserTypes.UserProfile) => {
    const newDoc = new UserProfileProjectObject({
      ...this.OUPData,
      ...newOUPData
    });
    return newDoc 
  }
  
  exportToFirebaseObject() {
    const preparedDoc: any = super.exportToFirebaseObject()
    return preparedDoc
  }
}
  
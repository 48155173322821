{
  "welcomeBack": "Content de vous revoir!",
  "email": "Email",
  "password": "Mot de passe",
  "signupLink": "S'inscrire ",
  "appLoginText": "Chargement ...",
  "loginLink": "Cliquez ici pour vous connecter",
  "homeLink": "Home",
  "noDispo": "Non disponible pour votre compte",
  "loginButton": "S'identifier",
  "signUpButton": "S'inscrire",
  "forgotPassword": "Mot de passe oublié?",
  "backToLogIn": "Retourner à la page de connexion",
  "getStarted": "Commençons!",
  "resetPassword": "Réinitialiser le mot de passe",
  "resetPasswordIntro": "Saisissez votre email pour réinitialiser votre mot de passe",
  "resetButton": "Réinitialiser",
  "resetEmailDone": "Vérifiez vos emails pour utiliser le lien de réinitialisation. ",
  "auth-weak-password": "Mot de passe trop simple",
  "auth-invalid-email": "L'adresse email n'est pas correctement formattée.",
  "auth-wrong-password": "Le mot de passe est incorrect ou l'utilisateur n'a pas de mot de passe.",
  "auth-user-not-found": "Cet utilisateur n'existe pas ou il a été supprimé.",
  "auth-internal-error": "Erreur de configuration du serveur d'authentification",

  "profileUpdated": "Profil mis à jour!",
  "profileUpdatedEnterprisePart": "Entreprise du profil mise à jour!",
  "pPseudo": "Pseudo:",
  "pPseudoPH": "Saisissez votre pseudo (1 majuscule suivi de minuscules ou chiffres)...",
  "pPseudoProfilePicture":"Ajouter votre photo",
  "pName": "Nom:",
  "pNamePH": "Saisissez votre nom ...",
  "pEmail": "Email:",
  "pEmailPH": "Saisissez votre email ...",
  "pPassword": "Mot de passe:",
  "pPasswordPH": "Saisissez votre mot de passe (min 6 characters)...",
  "pPhoneNumber": "N° Téléphone:",
  "pPhoneNumberPH": "Saisissez votre N° téléphone ...",
  "pUserDescription": "Une ligne pour vous présenter:",
  "pUserDescriptionPH": "Saisissez une brève description ...",
  "pMandatoryInputHelp": "Obligatoire",
  "pMandatoryAndUnchangeableInputHelp": "Non modifiable - Obligatoire",
  "pAcceptCGU": "J'ai lu et j'approuve les Conditions Générales d'Utilisation et la Politique de confidentialité d'Alomigo disponibles ici.",
  
  "banUser": "Compte utilisateur bloqué",
  "UserBanned": "Ce compte utilisateur a été bloqué par l'administrateur",
  "UserBannedOnlyAdmin": "Ce compte utilisateur a été bloqué. Il n'est visible que par les administrateurs.",
  "cannotAccessToPrivateUserData": "Ce compte utilisateur est inaccessible (no private user data)",
  "closedUser": "Compte utilisateur clôturé.",
  "UserClosed": "Ce compte utilisateur a été clôturé par son propriétaire",
  "UserClosedOnlyAdmin": "Ce compte utilisateur a été clôturé par son propriétaire. Il n'est visible que par les administrateurs.",
  
  "InvalidPassword": "Mot de passe invalide",
  "InvalidEmail": "Email invalide",
  "InvalidPseudo": "Pseudo invalide",
  "PseudoAlreadyUsed": "Pseudo déjà utilisé, veuillez en sélectionner un autre!",

  "InvalidPseudoUpperLetter": "Le Pseudo doit commencer par une lettre majuscule",
  "InvalidPseudoLetterOrNumber": 
  "Le Pseudo doit comporter uniquement des lettres ou des chiffres. La première lettre en majusule, ensuite des minuscules ou des chiffres",
  "InvalidPseudoLength": "Le Pseudo doit comporter au minimum 3 caractères",

  "InvalidAccept": "Vous devez accepter les CGU et la Politique de confidentialité.",
  
  "closingAccountButtonTitle":"Clôture définitive du compte",
  "closingAccountButton":"Clôture",
  "closingAccountStep2Button":"Confirmer la clôture",
  "closingAccount":"Clôture...",
  "closingAccountTitle":"Clôture de votre compte utilisateur",
  "closingAccountDone":"Clôture de votre compte terminée avec succès",
  
  "previewProfile":"Aperçu...",
  "previewProfileTitle":"Aperçu de votre profil en tant que contact",
  "getInvitationsBasedOnProfile":"Invitations...",
  "getInvitationsBasedOnProfileTitle":"Demander des invitations basé sur votre profil",
  "getInvitationsBasedOnProfileWitheLastDateTitle":
    "Demander des invitations basé sur votre profil (dernière demande: {lastInvitationRequest}, invitation envoyée: {nbInvitationsSent})",
  "enterYourLocationToReceiveInvitations": "Géolocaliser votre profil pour recevoir des invitations pertinentes",
  "viewProfileTitle":"Afficher le profil",
  "viewProfilePromotionTitle":"Afficher la page 'Communication' du profil",
  "loginUsingProfileTitle":"Ouvrir la page de closing avec un login pré-renseigné",

  "paypalUnsubscribeButton":"Arrêter mon abonnement",
  "paypalPlanEndedOn":"Votre abonnement sera définitivement terminé à l'échéance: ",

  "missingEmailValidationToAdd": 
  "Ajout impossible car vous n'avez pas validé votre email. Cliquez sur le lien disponible dans votre boîte mail.",
  "missingEmailValidation": 
  "Inscription impossible  car vous n'avez pas validé votre email. Cliquez sur le lien disponible dans votre boîte mail.",
  "missingDataInProfileToAdd": 
  "Ajout impossible car votre profil est incomplet. Vérifiez que vos nom, email, photo et numéro de téléphone sont présents dans votre profil.",
  "missingDataInProfile": 
  "Inscription impossible car votre profil est incomplet. Vérifiez que vos nom, email, photo et numéro de téléphone sont présents dans votre profil.",
  "itemPublicProfileData": "Informations publiques",
  "itemContactProfileData": "Visibles par vos contacts",
  "itemPrivateProfileData": "Informations privées",
  "itemDefaultProfileData": "Vos préférences",
  "itemBusinessProfileData": "Compte professionnel",
  "cancelCalendarEvent": "Evénement annulé",
  "subscriptionCancelled": "Abonnement annulé",
  "subscriptionUpdated": "Abonnement mis à jour",
  "defaultPictureInPref": "Choisissez une image par défaut.",
  "defaultPictureInPref2": "Utilisée pour les modèles.",
  "resetDefaultsUniversAndCats": "Réinitilisez",
  "resetDefaultsUniversAndCatsTitle": "Réinitilisez le type d'annonce par défaut",
  "defaultDocTypeInPref": "Choisissez un type d'annonce par défaut.",
  "defaultDocTypeInPref2": "Utilisé pour les recherches et les modèles.",
  "defaultLanguage": "Choisissez une langue: ",
  
  
  "contactRemoved": "Contact retiré!",
  "appBossTitle": "Boss de l'app!",
  "premiumAccount": "Utilisateur Premium",
  "pContactsList":"La liste de vos contacts:",
  "textConnectedInContactsList":"Connecté ",
  "updateProfileButton":"Enregistrer",
  "updateProfileButtonPH":"Enregistrer les informations"
}

import * as fb from './firebase'

import { UserProjectTypes } from "@/engineproject/default/common/domainModel/userProjectTypes"

import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes';

const docIdSepWithOwnerId = "__"

export interface AdDocInvitationsAPIClient {
  doClosingAccountInvitationsPart(options: any): Promise<any>;
  
  autoAddInvitationAdDoc(aAdDoc: AdDocumentProjectObject,
    aUserMiniProfile: UserProjectTypes.UserMiniProfile,
    invitationStatus: string,
    addInvitationText: string): Promise<any>;

  ownerAddInvitationAdDoc(aAdDoc: AdDocumentProjectObject,
    aUserMiniProfile: UserProjectTypes.UserMiniProfile,
    addInvitationText: string): Promise<any>;

  removeOneInvitationAdDoc(aAdDoc: AdDocumentProjectObject, 
        aInvitationId: string): Promise<any>;
    
  getAllInvitationsForAdDoc(optionsQuery: any): Promise<any>;
}

class AdDocInvitationsFirebase implements AdDocInvitationsAPIClient {

  async doClosingAccountInvitationsPart(options: { 
    trRequesterUserId: string; 
  }) {
    try {
      const adDocFBDs2 = await fb.db.collectionGroup("ADColInvitations").
      where("AIMUser.UMUserId", "==", options.trRequesterUserId).
      get();
      const batch = fb.db.batch();
      adDocFBDs2.forEach(async (doc: any) => {
        batch.delete(doc.ref);
      })
      await batch.commit();
      console.log("doClosingAccountInvitationsPart Finished async");
    } catch (e) {
      const errorMsg = "error doClosingAccountInvitationsPart " + e
      console.error(errorMsg);
      return {
        backendActionDone: false,
        errorMsg
      }
    }
    return {
      backendActionDone: true,
      errorMsg: ""
    }
  }

  addInvitationAdDocCreateStep(invitationsCol: any, invitationDocId: string, 
    aUserMiniProfile: UserProjectTypes.UserMiniProfile,
    invitationStatus: string,
    addInvitationText: string) {
    const newInvitation = {
      AIId: invitationDocId,
      AICreatedOn: new Date(),
      AIStatus: invitationStatus, // S Sent manually by owner or A Auto invited by app or R invitation Requested by user
      AIMUser: aUserMiniProfile,
      ... (addInvitationText !== undefined && addInvitationText !== "") && {AIComment: addInvitationText}
    }
    return invitationsCol.doc(invitationDocId).set(newInvitation).then(() => {
      return {
        backendActionDone: true,
        addedInvitation: newInvitation,
        errorMsg: undefined
      }
    }, (reason: any) => {
      console.error("error addInvitationAdDocCreateStep" + reason)
      return {
        backendActionDone: false,
        addedInvitation: undefined,
        errorMsg: "addInvitationErrorDocNotFoundOrNotAuthorized"
      }
    });
  }

  async autoAddInvitationAdDoc(aAdDoc: AdDocumentProjectObject,
    aUserMiniProfile: UserProjectTypes.UserMiniProfile,
    invitationStatus: string,
    addInvitationText: string) {
      if (fb.auth === null || fb.auth.currentUser === null /*||
        fb.auth.currentUser.uid !== aUserMiniProfile.UMUserId*/) {
        return {
          backendActionDone: false,
          addedInvitation: undefined,
          errorMsg: "Not connected or invalid userids in addInvitationAdDoc ..."
        }
      }
      const userId = fb.auth.currentUser.uid
      const ownerUserId = aAdDoc.getOwnerUserId()
      if (userId !== aUserMiniProfile.UMUserId) {
        return {
          backendActionDone: false,
          addedInvitation: undefined,
          errorMsg: "You are not the invited ..."
        }
      }
      // sJSimTNwsMQy6peyPAGRrHz43Tw2__qnRmJtbxZcfRD4mWb2RSAfFJQEh1__qnRmJtbxZcfRD4mWb2RSAfFJQEh1__1642135159524
      const adDocId = aAdDoc.getADId()
      const invitationDocId = ownerUserId + docIdSepWithOwnerId + aUserMiniProfile.UMUserId + docIdSepWithOwnerId + adDocId
      return this.addInvitationAdDoc(adDocId, invitationDocId, aUserMiniProfile, invitationStatus, addInvitationText)
  }

  async ownerAddInvitationAdDoc(aAdDoc: AdDocumentProjectObject,
    aUserMiniProfile: UserProjectTypes.UserMiniProfile,
    addInvitationText: string) {
      if (fb.auth === null || fb.auth.currentUser === null /*||
        fb.auth.currentUser.uid !== aUserMiniProfile.UMUserId*/) {
        return {
          backendActionDone: false,
          addedInvitation: undefined,
          errorMsg: "Not connected or invalid userids in addInvitationAdDoc ..."
        }
      }
      const userId = fb.auth.currentUser.uid
      const ownerUserId = aAdDoc.getOwnerUserId()
      if (userId !== ownerUserId) {
        return {
          backendActionDone: false,
          addedInvitation: undefined,
          errorMsg: "You are not the owner ..."
        }
      }
      // sJSimTNwsMQy6peyPAGRrHz43Tw2__qnRmJtbxZcfRD4mWb2RSAfFJQEh1__qnRmJtbxZcfRD4mWb2RSAfFJQEh1__1642135159524
      const adDocId = aAdDoc.getADId()
      const invitationDocId = ownerUserId + docIdSepWithOwnerId + aUserMiniProfile.UMUserId + docIdSepWithOwnerId + adDocId
      return this.addInvitationAdDoc(adDocId, invitationDocId, aUserMiniProfile, "S", addInvitationText)
  }

  async addInvitationAdDoc(aAdDocId: string, invitationDocId: string,
    aUserMiniProfile: UserProjectTypes.UserMiniProfile,
    invitationStatus: string,
    addInvitationText: string) {
    try {

      const invitationsCol = fb.adDocCollection.doc(aAdDocId).collection('ADColInvitations')
      const invitationDocs = await invitationsCol.doc(invitationDocId).get()
      if (invitationDocs.exists) {
        console.error("addInvitationAdDoc 22")
        return {
          backendActionDone: false,
          addedInvitation: undefined,
          errorMsg: "invitationAlreadySentMsg"
        }
      }
      console.error("addInvitationAdDoc aUserMiniProfile " + JSON.stringify(aUserMiniProfile))
      return this.addInvitationAdDocCreateStep(invitationsCol, invitationDocId,
        aUserMiniProfile,
        invitationStatus,
        addInvitationText)

    } catch (e) {
      console.error("error addInvitationAdDoc ")
      console.error("error addInvitationAdDoc " + e)
      return {
        backendActionDone: false,
        addedInvitation: undefined,
        errorMsg: e + ""
      }
    }
  }

  async removeOneInvitationAdDoc(aAdDoc: AdDocumentProjectObject, 
    aInvitationId: string) {
    //  userProfile: UserProjectTypes.UserProfile) {
    const removeInvitationDoc = async (transactionsCol: any, invitationId: string) => {
      const transactionDoc = transactionsCol.doc(invitationId)
      //const transactionDocs = await transactionDoc.get()
      return await transactionDoc.get().then(async (transactionDocs: any) => {
        if (transactionDocs.exists) {
          // console.error("removeMyInvitationAdDoc 22")
          const removedInvitationData = transactionDocs.data()
          return await transactionDoc.delete().then(async () => {
            // console.log("Invitation successfully deleted!");
            return {
              backendActionDone: true,
              removedInvitationId: invitationId,
              removedInvitationData,
              errorMsg: undefined
            }
          }).catch((error: any) => {
            console.error("Error removing transaction: " + error);
            console.dir(error)
            //  + error.message + error.code
            return {
              backendActionDone: false,
              removedInvitationId: undefined,
              removedInvitationData: undefined,
              errorMsg: "Error removing transactionin  document: " + error.message,
            }
          });
        } else {
          console.error("removeMyInvitationAdDoc 33")
          return {
            backendActionDone: false,
            removedInvitationId: undefined,
            removedInvitationData: undefined,
            errorMsg: "Invitation doesn't exist, so it cannot be removed"
          }
        }
      })
    }
    try {
      // console.error("removeMyInvitationAdDoc")
      if (fb.auth === null || fb.auth.currentUser === null ) {
        //  fb.auth.currentUser.uid !== userProfile.UPUserId) {
            console.error("removeMyInvitationAdDoc 11")
        return {
          backendActionDone: false,
          removedInvitationId: undefined,
          removedInvitationData: undefined,
          errorMsg: "Not connected or invalid userids in removeMyInvitationAdDoc ..."
        }
      }

      const transactionsCol = fb.adDocCollection.doc(aAdDoc.getADId()).collection('ADColInvitations')
      const retRemove = await removeInvitationDoc(transactionsCol, aInvitationId)
      return retRemove

    } catch (e) {
      console.error("error removeOneInvitationAdDoc ")
      console.error("error removeOneInvitationAdDoc " + e)
      return {
        backendActionDone: false,
        removedInvitationId: undefined,
        removedInvitationData: undefined,
        errorMsg: e + ""
      }
    }
  }
  
  async getAllInvitationsForAdDoc(optionsQuery: any) {
    const res = Array<any>();
    try {
      const docs = await fb.adDocCollection.doc(optionsQuery.ADId).collection('ADColInvitations').get()
      docs.forEach((doc: any) => {
        const invitation: any = doc.data()
        invitation.ATId = doc.id
        res.push(invitation)
      })
    } catch (e) {
      return {
        backendActionDone: false,
        errorMsg: e + "",
        aADColInvitations: Array<any>()
      }
    }
    // console.error("getAllInvitationsForAdDoc res " + res.length)
    return {
      backendActionDone: true,
      errorMsg: undefined,
      aADColInvitations: res
    }
  }

}

export default AdDocInvitationsFirebase

<template>
    <div class="filter-container"  v-if="canSelectEntreprise">
      <div>
        <label for="filterEnterprise">{{ t(titleTag) }}</label>
        <EnterpriseSelect :aEnterprise="searchEnterpriseFilter"
          :allowedEnterprises="allowedEnterprisesInfo"
          :onSelectedEnterprise="updateEnterprise"
        ></EnterpriseSelect>
      </div>

    </div>

</template>

<script lang="ts">
import {  reactive, toRefs, computed } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";
import { useUserStore } from '@/engineproject/store/userStore';
import EnterpriseSelect from "@/engineproject/components/Enterprise/EnterpriseSelect.vue";

import { useFilterAttributesStore } from '@/engineproject/store/filterAttributesStore'
import { EnterpriseInfoProjectObject } from "@/projects/alomigo/common/domainModel/enterpriseInfoProjectToolsTypes";
import { allPublicWithoutEnterprise } from '@/engineproject/domainModel/enterpriseInfoTypes';

export default {
  props: {
    titleTag: {
      type: String,
      required: false,
      default: 'filterEnterpriseTitle'
    },
  },
  setup(props: any) {
    const filterAttributesStore = useFilterAttributesStore()
    const {t, locale} = useI18n()
    const userStore = useUserStore();

    const page: any = reactive({
      allowedEnterprisesInfo: computed(() => userStore.state.aUserProfile.getUPColAllowedEnterprises().map((enterprise: EnterpriseInfoProjectObject) => enterprise.OEIData)),
      allowedEnterprises: computed(() => userStore.state.aUserProfile.getUPColAllowedEnterprises()),
      getUPEnterprise: computed(() => userStore.state.aUserProfile.getUPEnterprise()),
      canSelectEntreprise: computed(() => {
          const ret =  userStore.state.aUserProfile.getUPColAllowedEnterprises().length > 0 || userStore.state.aUserProfile.getUPEnterprise() !== undefined
        return ret
       }
      ),
      searchEnterpriseFilter: computed(() =>  filterAttributesStore.state.searchEnterpriseFilter),
    });
    const currentLocale = computed(() => locale.value);

    const updateEnterprise = async (newEnterprise: any) => {
      //const enterprise = event.currentTarget.value
      await filterAttributesStore.commit("setSearchEnterpriseFilter", {
        newEnterprise,
        isUSerEnterprise: userStore.state.aUserProfile.sameEnterprise(newEnterprise)
      });
    }

    if (page.searchEnterpriseFilter === undefined) {
       filterAttributesStore.commit("setSearchEnterpriseFilter", {
        newEnterprise: userStore.state.aUserProfile.getUPEnterprise() || allPublicWithoutEnterprise,
        isUSerEnterprise: (userStore.state.aUserProfile.getUPEnterprise()) ? true : false
      });
    }

  
    return {
      ...toRefs(page),
      t,
      updateEnterprise,
    }
  },
  components: {
    EnterpriseSelect
  },
}


</script>
<style lang="scss" >
.inputClass {
  width: 100px !important;
  min-width: 100px !important;
}
#fZipcode, .p-autocomplete, .p-autocomplete > input  {
  width: 100px !important;
}
#fZipcode, .p-autocomplete, .p-autocomplete > input  {
  min-width: 100px !important;
}
#fDistance {
  width: 100px;
}
.filter-container {
  margin: 4px;
  padding: 2px;
}
</style>

<template>
  <div v-if="userCanEdit === true" :class="userIsOwner === false ? 'adminSuperPower' : ''"
    class="invitationArea">
    <div v-if="ADColInvitations !== undefined && ADColInvitations.length > 0" data-cy="cyInvitationsLoaded">
      <AdDocViewInvitationsRemoveButton :aAdDoc="aAdDoc" :ADColInvitations="ADColInvitations">
      </AdDocViewInvitationsRemoveButton>
    </div>
    <div v-else>
      <AdDocViewInvitationsEmpty :aAdDoc="aAdDoc"></AdDocViewInvitationsEmpty>
    </div>
    <AdDocViewInvitationsOpenAddPopupButton :aAdDoc="aAdDoc"></AdDocViewInvitationsOpenAddPopupButton>
  </div>
</template>

<script lang="ts">

import AdDocViewInvitationsRemoveButton from '@/engineproject/components/AdDocInvitation/AdDocViewInvitationsRemoveButton.vue';
import AdDocViewInvitationsOpenAddPopupButton from '@/engineproject/components/AdDocInvitation/AdDocViewInvitationsOpenAddPopupButton.vue';
import AdDocViewInvitationsEmpty from '@/engineproject/components/AdDocInvitation/AdDocViewInvitationsEmpty.vue';

export default {
  props: {
    ADColInvitations: {
      type: Object as () => Array<any>,
      required: false,
    },
    aAdDoc: {
      type: Object as () => Array<any>,
      required: true,
    },
    userIsOwner: {
      type: Boolean,
      required: true,
    },
    userCanEdit: {
      type: Boolean,
      required: true,
    },
  },
  setup(props: any, context: any) {

    return {
    };
  },
  components: { 
    AdDocViewInvitationsRemoveButton,
    AdDocViewInvitationsOpenAddPopupButton,
    AdDocViewInvitationsEmpty,
  },
};
</script>

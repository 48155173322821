{
    "PosterPartnerTitle": "Affiche",
    "posterTitle1": "Engine Project",
    "posterTitle2": "est partenaire de",
    "posterTitle3": " Découvrez et participez à toutes les annonces sur l'application: ",
    "posterTitle4": " Découvrez et participez à toutes les soirées de votre restaurant sur l'application Engine Project:",
    "previewButton": "Aperçu",
    "printButton": "Imprimer",
    "expandButton": "Déplier",
    "reduceButton": "Replier",
    "contentEditable": "Cliquez pour modifier le texte ...",
    "promotionPagePremiumOnly": "Partner Promotion functionnality is available for premium account only.",
    "PosterAdDocumentTitle": "QR Codes"
}
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b10f32e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.ADDateHourStartsOnNotRequiredAndNotDefined.isUndefined(_ctx.convertedDate))
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($props.prefix), 1))
    : (_ctx.convertedDate && _ctx.convertedDate.toLocaleDateString)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($props.prefix) + _toDisplayString(_ctx.convertedDate.toLocaleDateString('fr-FR',  { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })) + " " + _toDisplayString(_ctx.convertedDate.toLocaleTimeString('fr-FR', {hour: '2-digit', minute:'2-digit'})), 1))
      : (_ctx.convertedDate)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($props.prefix) + _toDisplayString(_ctx.convertedDate), 1))
        : _createCommentVNode("", true)
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e2b05000"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "shareButtonContainer"
}
const _hoisted_2 = ["onClick", "network", "url", "title", "description", "quote", "hashtags", "twitterUser"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.aAdDoc !== undefined && $props.aAdDoc.canBeSharedAndGetPromotion())
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.networks, (network) => {
          return (_openBlock(), _createElementBlock("a", {
            href: "javascript:void(0);",
            onClick: ($event: any) => ($setup.share(network)),
            class: _normalizeClass('share-network-' + network.network),
            network: network.network,
            key: network.network,
            style: _normalizeStyle({backgroundColor: network.color}),
            url: $setup.sharing.url,
            title: $setup.sharing.title,
            description: $setup.sharing.description,
            quote: $setup.sharing.quote,
            hashtags: $setup.sharing.hashtags,
            twitterUser: $setup.sharing.twitterUser
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(network.icon)
            }, null, 2),
            _createElementVNode("span", null, _toDisplayString(network.name), 1)
          ], 14, _hoisted_2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
<template>
  <div class="filterArea">
    <slot v-if="filtersVisible===true" name="filterContent"
    ></slot>
  </div>
</template>

<script lang="ts">
import {  reactive, toRefs, computed } from "vue";
import { useAppStore } from '@/engineproject/store/appStore'

export default {
  emits: ["filterChanged"],
  setup(props: any, context: any) {
    const appStore = useAppStore()
    const page = reactive({
      filtersVisible: computed(() => appStore.state.filtersVisible),
    });
    return {
      ...toRefs(page),
    }
  },
}


</script>

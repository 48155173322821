<template>
  <div  class="blockAppUI" :class="(blockedApplication === true) ? 
    'blockedApplicationFromParentTrue' : 'blockedApplicationFromParentFalse'"
    style="borderDEBUG: 2px solid red;">
    <BlockUI :blocked="/*blockedApplication === true || */blockedApplication === true" :fullScreen="true" :baseZIndex="5000"></BlockUI>
    <ProgressSpinner v-if="/*blockedApplication === true || */blockedApplication === true" 
      class="centered" style="width:50px;height:50px;z-index:5000;" strokeWidth="8" 
      fill="#EEEEEE" animationDuration=".5s"/>
    <div class="hiddenElement">
        <span v-if="blockedApplicationCounter > 0" data-cy="cyBlockedApplicationIndicatorTrue">{{ blockedApplicationCounter }}</span>
        <span v-else data-cy="cyBlockedApplicationIndicatorFalse">{{ blockedApplicationCounter }}</span>
      </div>
    <div v-if="1 === 0" style="position: fixed;top: 0px;left: 0px;z-index: 500;">
      <span>{{ blockedApplication }} {{ blockedApplicationCounter }}
        </span>
      <div  v-if="blockedApplication === true" class="blockAppUIIndicatorTrue">blockAppUIIndicatorTrue</div>
      <div  v-if="blockedApplication !== true" class="blockAppUIIndicatorFalse">blockAppUIIndicatorFalse</div>
      <div   class="blockedApplicationCounter">{{blockedApplicationCounter}}</div>
    </div>
  </div>
</template>
<script lang="ts">
import { reactive, toRefs, computed, watch } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { useAppStore, timerToFixBlockUIValue } from '@/engineproject/store/appStore';

import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';

export default {
  props: {
    blockedApplicationFromParent: {
      type: Object,
      required: false
    },
  },
  setup(props: any, context: any) {
    const {t} = useI18n()
    const appStore = useAppStore();
    const page = reactive({
      blockedApplication: computed(() => appStore.state.blockedApplication),
      timerToFixBlockUI: 0 as any,
    });
    
    /*
    There is a random bug in PrimeVue BlockUI : p-blockui-leave overlay element is some times never removed
    Then the UI of the application is blocked.
    In order to fix this issue, blockedApplicationCounter is watched and 200 ms after passing to 0,
    p-blockui-leave elements are removed!
    https://github.com/primefaces/primevue/issues/1537
    https://github.com/primefaces/primevue/issues/486
    */ 
    const blockedApplicationCounter = computed(() => appStore.state.blockedApplicationCounter)

    watch(blockedApplicationCounter,
      (newValue, oldValue) => {        
        if (newValue === 0) {
          page.timerToFixBlockUI = setTimeout(() => {
            // p-blockui p-blockui-document p-component-overlay p-blockui-leave
            // p-blockui p-component-overlay p-component-overlay-enter p-blockui-document p-component-overlay-leave
            // const testElements = document.getElementsByClassName("p-blockui p-blockui-document p-component-overlay p-blockui-leave");
            const testElements = document.getElementsByClassName("p-blockui p-blockui-document p-component-overlay p-component-overlay-leave");
            Array.prototype.filter.call(testElements, (element: any) => {
              if (element !== undefined && element.parentElement !== undefined) {
                element.parentElement.removeChild(element);
              }
            });
            document.body.classList.remove("p-overflow-hidden")
          }, timerToFixBlockUIValue)
        } else {
          clearTimeout(page.timerToFixBlockUI)
        }
        
      });
      
    return {
      ...toRefs(page),
      t,
      blockedApplicationCounter,
    }
  },
  components: { 
    BlockUI,
    ProgressSpinner
  },
}


</script>
<style lang="scss" scoped>
.pi {
  font-size: 1rem;
}

.iconsContainer {
  display: flex;
}

.iconsContainer > a {
  margin: 20px;
}
</style>

{
    "applyModeleToCreateNewAdDoc": "Appliquer le modèle pour créer un nouveau document",
    "useModeleToCreateNewAdDoc": "Utiliser ce modèle pour créer le document.",
    "modeleFromYourHistory": "Ce modèle vient de votre historique des derniers modèles utilisés pour créer un document",
    "displayAllYourModeles": "Voir tous vos modèles de document",
    "duplicateModeleInYourModeleList": "Cloner le modèle dans votre liste de modèles",
    "displayModeleDetails": "Afficher les détails de ce modèle ...",
    "lastModelesUsed": "Les derniers modèles que vous avez utilisés:",
    "modeleFromYourList": "Ce modèle est présent dans votre liste de modèles, cliquez pour afficher votre liste de modèles",
    "displayModeleIdeas": "Cet élément est une idée de modèle proposée par l'application, cliquez pour afficher toutes les idées de modèles",
    "modelePropositionUsingStrategy": "Ce modèle est une proposition basée sur vos préférences. Stratégie {currentStrategy}.",
    "displayAllTheModeleIdeas": "Découvrir des idées de modèles"
}
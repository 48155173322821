import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b423457"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: ""
}
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dropdown, {
      class: "",
      modelValue: _ctx.selectedEnterprise,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedEnterprise) = $event)),
      onChange: _cache[1] || (_cache[1] = ($event: any) => ($setup.onChange($event.value))),
      placeholder: "...",
      options: _ctx.publicAndAllowedEnterprises,
      optionValueKKK: "EISearchId",
      optionLabelLLL: "EIName"
    }, {
      value: _withCtx((slotProps) => [
        (slotProps.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.buildTitle(slotProps.value)), 1))
          : _createCommentVNode("", true)
      ]),
      option: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString($setup.buildTitle(slotProps.option)), 1)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "options"])
  ]))
}
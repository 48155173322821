import { AdDocInvitationsAPIClient } from "@/engineproject/backend/AdDocInvitationsFirebase";
import { AdDocTransactionsAPIClient } from "@/engineproject/backend/AdDocTransactionsFirebase";
import { PPConfig } from "@/projects/paypalConfigUsedByDefault";
import { AdDocAPIClient } from "../../backend/AdDocFirebase";
import { adDocFirebase, adminUsersCollection, userFirebase } from "../../backend/firebase";
import { UserAPIClient } from "../../backend/UserFirebase";
import { UserProfileProjectObject } from "../../default/common/domainModel/userProfileProjectToolsTypes";

export class UserProfileControler {
  adDocTransactionsBackendAPI?: AdDocTransactionsAPIClient;
  adDocInvitationsBackendAPI?: AdDocInvitationsAPIClient;
  adDocBackendAPI?: AdDocAPIClient;
  userBackendAPI?: UserAPIClient;

  setAPI(aDocAPI: AdDocAPIClient, aDocInvitationsBackendAPI: AdDocInvitationsAPIClient,
    aDocTransactionsAPI: AdDocTransactionsAPIClient, aUserAPI: UserAPIClient) {
    this.adDocBackendAPI = aDocAPI
    this.adDocInvitationsBackendAPI = aDocInvitationsBackendAPI
    this.adDocTransactionsBackendAPI = aDocTransactionsAPI
    this.userBackendAPI = aUserAPI
  }

  removeInvitsFromUserId(aInvits: Array<any>, aUserId: string) {
    console.error("aInvits " + JSON.stringify(aInvits))
    return aInvits.reduce((prev, curr) => {
      if (curr.OADData !== undefined && curr.OADData.ADOwnerMiniProfile !== undefined &&
        curr.OADData.ADOwnerMiniProfile.UOUserId !== aUserId) {
        prev.push(curr)
      }
      return prev
    }, [])
  }

  getAdDocIdsFromAdDocs(aInvits: Array<any>) {
    return aInvits.reduce((prev, curr) => {
      if (curr.OADData !== undefined && curr.OADData.ADId !== undefined) {
        prev.push(curr.OADData.ADId)
      }
      return prev
    }, [])
  }

  async queryInvitationAdDoc(nearLatLng: any, aUserId: string) {
    // const nearLatLng = aUser.getUserProfileLatLng()
    const nearRadiusInM = 14000

    const optionsQuery = {
      previousAdDoc: undefined,
      nextAdDoc: undefined,
      maxLimit: 10,
      invitationFilter: true,
      currentFilter: "allAdDocs",
      nearLatLng,
      nearRadiusInM,
      //    universAndCatFilter: universAndCatFilter,
      adDocFileType: "",
    }
    const queryResult = await this.adDocBackendAPI?.queryAdDoc(optionsQuery)
    const invitsNotFromMe = this.removeInvitsFromUserId(queryResult.OADDataArrayFromBackend, aUserId)
    return invitsNotFromMe
  }

  async triggerInvitationProcess(options: {
    aUser: UserProfileProjectObject;
    manualCallAndDisplayInfoMsg: boolean;
  }) {
    const buildInvitationStatus = (manualCallAndDisplayInfoMsg: boolean) => {
      // S Sent manually by owner or A Auto invited by app or R invitation Requested by user
      if (manualCallAndDisplayInfoMsg === true) {
        return "R"
      }
      return "A"
    }
    let rForDoActionsOnUserBecomesAlive = {
      backendActionDone: true,
      errorMsg: "",
      adDocsOpenToAutoInvitation: undefined,
      retUpdateInvitationStatusInProfile: undefined,
    }
    let rForUpdateInvitationStatusInProfile = {
      backendActionDone: true,
      errorMsg: "",
    }
    if (options.manualCallAndDisplayInfoMsg === true ||
      options.aUser.invitsProcessNeedsToBeDone() === true) {
      // Search for adDocs with auto invitation flag set to true (geolocation if any)
      const adDocsOpenToAutoInvitation = await this.queryInvitationAdDoc(options.aUser.getUserProfileLatLng(),
        options.aUser.getUPUserId())
      // Verify not yet invited
      // adDocsOpenToAutoInvitation.getFirstAdDocsNotInInvitation()
      // Ask to backend to send an auto invitation
      let invitationSent = false
      let currentInvitation = 0
      // S Sent manually by owner or A Auto invited by app or R invitation Requested by user
      const invitationStatus = buildInvitationStatus(options.manualCallAndDisplayInfoMsg)
      while (invitationSent === false && currentInvitation < adDocsOpenToAutoInvitation.length) {
        console.error("Try to send invitation #" + currentInvitation)
        rForDoActionsOnUserBecomesAlive = await this.adDocInvitationsBackendAPI?.autoAddInvitationAdDoc(adDocsOpenToAutoInvitation[currentInvitation],
          options.aUser.getMiniProfileForTransaction(),
          invitationStatus,
          "")
        if (rForDoActionsOnUserBecomesAlive.backendActionDone === true) {
          invitationSent = true
        }
        currentInvitation++;
      }
      rForUpdateInvitationStatusInProfile =
        await this.userBackendAPI?.updateInvitationStatusInProfile(options.aUser.getUPUserId(), invitationSent)

      if (adDocsOpenToAutoInvitation.length === 0 ||
        invitationSent === false) {
        const errorMsg = "noAdDocFoundByAutoInvitationProcess"
        console.error(errorMsg)
        if (options.manualCallAndDisplayInfoMsg === true) {
          rForDoActionsOnUserBecomesAlive.backendActionDone = false
          rForDoActionsOnUserBecomesAlive.errorMsg = errorMsg
        }
      }
    }
    return {
      rForDoActionsOnUserBecomesAlive,
      rForUpdateInvitationStatusInProfile
    }
  }

  async getMyUserProfile(userId: string, userPrivateProfileDocObserverReceived: any, userPublicProfileDocObserverReceived: any) {
    const rForGetMyUserProfile = await this.userBackendAPI?.getMyUserProfile(userId, userPrivateProfileDocObserverReceived, userPublicProfileDocObserverReceived)
    return {
      rForGetMyUserProfile
    }
  }

  async removeMyContact(options: {
    contactUserId: string;
    requesterUserId: string;
  }) {
    const rForRemoveMyContact = await this.userBackendAPI?.removeMyContact(options)
    return {
      rForRemoveMyContact
    }
  }

  async doClosingAccount(options: {
    trRequesterUserId: string;
  }) {
    let rForDoClosingAccount = await adDocFirebase.doClosingAccount(options)
    if (rForDoClosingAccount.backendActionDone === true && this.userBackendAPI) {
      rForDoClosingAccount = await this.userBackendAPI.doClosingAccount(options)
    }
    return {
      rForDoClosingAccount
    }
  }


  async sendEmailValidationLinkToUser() {
    const rForSendEmailValidationLinkToUser = await this.userBackendAPI?.sendEmailValidationLinkToUser()
    return {
      rForSendEmailValidationLinkToUser
    }
  }

  async validationLinkFromUser(options: {
    linkHash: string;
  }) {
    const rForValidationLinkFromUser = await this.userBackendAPI?.validationLinkFromUser(options.linkHash)
    return {
      rForValidationLinkFromUser
    }
  }

  async getMyActiveTransactionsWithAll(options: {
    trRequesterUserId: string;
  }) {
    const rForGetActiveTransactionsWithAContact = await this.adDocTransactionsBackendAPI?.getMyActiveTransactionsWithAll(options)
    return {
      rForGetActiveTransactionsWithAContact
    }
  }

  async getActiveTransactionsWithAContact(options: {
    trDocOwnerUserId: string;
    trRequesterUserId: string;
  }) {
    const rForGetActiveTransactionsWithAContact = await this.adDocTransactionsBackendAPI?.getActiveTransactionsWithAContact(options)
    return {
      rForGetActiveTransactionsWithAContact
    }
  }

  async updateProfilePlan(options: {
    aUserId: string;
    subscriptionId: string; planId: string;
  }) {
    const rForUpdateProfilePlan = await this.userBackendAPI?.updateProfilePlan(options.aUserId, options.subscriptionId,
      options.planId)
    return {
      rForUpdateProfilePlan
    }
  }

  async sendPasswordResetEmail(anEmail: string) {
    const rForSendPasswordResetEmail = await this.userBackendAPI?.sendPasswordResetEmail(anEmail)
    return {
      rForSendPasswordResetEmail
    }
  }

  async updateProfile(user: UserProfileProjectObject, updateEmail: any) {
    console.error('updateProfile ' + JSON.stringify(user))
    const rForUpdateProfile = await this.userBackendAPI?.updateProfile(user, updateEmail)
    return {
      rForUpdateProfile
    }
  }

  async cancelUserSubscription(options: {
    aUserId: string;
    subscriptionId: string; planId: string;
  }) {
    const rForUpdateProfilePlan = await this.userBackendAPI?.cancelUserSubscription({
      aUserId: options.aUserId,
      aSubscriptionId: options.subscriptionId
    })
    return {
      rForUpdateProfilePlan
    }
  }

  isCurrentLoggedUser(aUserId: string) {
    const isC = userFirebase.isUserOK(aUserId)
    if (isC.userId === "") {
      return false
    }
    return true
  }

}


export const userProfileControler = new UserProfileControler()


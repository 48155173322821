<template>
    <div class="m-container" @click.stop="">
      <GMapMap
          :center="mapCenter"
          :zoom="15"
          :options="mapOptions"
          class="googleMap"
          style=""
          @click="clickOnMap($event)"
          @dblclick="dblClickOnMap($event)"
        >
          <GMapCluster
          :minimumClusterSize="2"
          :styles="clusterIcon"
          :zoomOnClick="true">
          <GMapMarker
              :key="index"
              v-for="(m, index) in arrayOfMarkers"
              :title="m.title"
              :label="m.label"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              :icon= "m.icon"
              @click="openAdDocFromMap(m.aAdDoc)">
          </GMapMarker>
          </GMapCluster>
      </GMapMap>

    </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs, inject } from 'vue'
import { useI18n } from "@/engineproject/default/common/i18n";

import { useFilterAttributesStore , getOneOfAllFilters} from '@/engineproject/store/filterAttributesStore'
import { useUserStore } from '@/engineproject/store/userStore'
import { useAppStore } from '@/engineproject/store/appStore';
import { useDocumStore } from '@/engineproject/store/documStore';
import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes';
import { getDefaultUrlForUnivers} from '@/engineproject/default/common/store/filterAttributesConfig'
import { defaultMapSVGIconForEachUniversAndCats } from '@/engineproject/default/common/store/defaultMapSVGIconForEachUniversAndCats'

// import { faMapMarkerAlt , faFutbol} from "@fortawesome/free-solid-svg-icons";
//import {} from 'google.maps';
// import {} from '@types/google.maps';
//import {} from 'googlemaps';
// declare let google: { maps: { Point: new (arg0: number, arg1: number) => any; }; }; 
// declare let google: any;
// const google = window.google

export default defineComponent({
components: { 
  
  },
  setup() {
    const formatDate = inject('formatDate') as any;
    const filterAttributesStore = useFilterAttributesStore()
    const documStore = useDocumStore();
    const appStore = useAppStore();
    const userStore = useUserStore();
    const {t} = useI18n()
    const dupOffsetObject = {} as any
    const getDupOffset = (lat: number, lng: number) => {
      /*
      0 0
      0 1
      0 2
      1 0
      1 1
      1 2
      2 0
      */
      if (dupOffsetObject["" + lat + " ; " + lng] === undefined) {
        dupOffsetObject["" + lat + " ; " + lng] = {dupOffsetLat: 0,
          dupOffsetLng: 0
        }
        
      } else {
        if (dupOffsetObject["" + lat + " ; " + lng].dupOffsetLng > 2) {
          dupOffsetObject["" + lat + " ; " + lng] = {
            dupOffsetLat: dupOffsetObject["" + lat + " ; " + lng].dupOffsetLat + 1,
            dupOffsetLng : 0
          }
        } else {
          dupOffsetObject["" + lat + " ; " + lng] = {
            dupOffsetLat: dupOffsetObject["" + lat + " ; " + lng].dupOffsetLat,
            dupOffsetLng : dupOffsetObject["" + lat + " ; " + lng].dupOffsetLng + 1
          }
        }
      }
      return dupOffsetObject["" + lat + " ; " + lng]
    }
    const convertAdDocsToMarkerOptions = (adDocsArray: Array<AdDocumentProjectObject>) => {
      const res = adDocsArray.reduce((prev: Array<any>, curr: AdDocumentProjectObject) => {
        if (curr !== undefined &&
          curr.getADLocation() !== undefined) {
            let lat = curr.getADLocation()!.LPLat // + (prev.length / 10000)
            let lng = curr.getADLocation()!.LPLng
            const {dupOffsetLat, dupOffsetLng} = getDupOffset(lat, lng)
            lat = lat + dupOffsetLat / 20000
            lng = lng + dupOffsetLng / 20000
            const getIconColorAndSizeBasedOnDocDate = (aDate: Date) => {
              const today = new Date()
              const tomorrow = new Date()
              tomorrow.setDate(tomorrow.getDate() + 1);
              if (aDate < today) {
                return {
                    fillColor: '#FF2200',
                    fillOpacity: .8,
                    strokeWeight: 0.1,
                    scale: 0.03
                }
              } else if (aDate > tomorrow) {
                return {
                    fillColor: '#6495ED',
                    fillOpacity: .8,
                    strokeWeight: 0.1,
                    scale: 0.03
                }
              }
              return {
                  fillColor: '#228B22',
                  fillOpacity: .99,
                  strokeWeight: 0.1,
                  scale: 0.06
              }
            }
            const getIconUsingCategory = (curr: any) => {
              const aUnivers = curr.getADUnivers()
              const aCatInUnivers = curr.getADCatsInUnivers()
              const defObj = getDefaultUrlForUnivers(defaultMapSVGIconForEachUniversAndCats, aUnivers, aCatInUnivers)
              const iconColorAndSize = getIconColorAndSizeBasedOnDocDate(curr.getADDateHourStartsOn())
              if (defObj !== undefined && defObj.faSVG !== undefined) {
                const ret =  {
                  ...iconColorAndSize,
                  path: defObj.faSVG.icon[4] as string,
                  anchor: {
                    x: defObj.faSVG.icon[0] / 2, 
                    y: defObj.faSVG.icon[1]
                  },
                  /*
                  Does not work well, random bug with google
                  anchor: new google.maps.Point(
                    defObj.faSVG.icon[0] / 2, // width
                    defObj.faSVG.icon[1] // height
                  ),*/
                  // Good but we need to manually copy each path for each icon ...
                  // path: "M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zm-48 0l-.003-.282-26.064 22.741-62.679-58.5 16.454-84.355 34.303 3.072c-24.889-34.216-60.004-60.089-100.709-73.141l13.651 31.939L256 139l-74.953-41.525 13.651-31.939c-40.631 13.028-75.78 38.87-100.709 73.141l34.565-3.073 16.192 84.355-62.678 58.5-26.064-22.741-.003.282c0 43.015 13.497 83.952 38.472 117.991l7.704-33.897 85.138 10.447 36.301 77.826-29.902 17.786c40.202 13.122 84.29 13.148 124.572 0l-29.902-17.786 36.301-77.826 85.138-10.447 7.704 33.897C442.503 339.952 456 299.015 456 256zm-248.102 69.571l-29.894-91.312L256 177.732l77.996 56.527-29.622 91.312h-96.476z",
                  // Good but the color is set inside the svg file, so we have to duplicate the files in different colors
                  //url: require(`@/engineproject/default/common/assets/svg/univers/${defObj.docUrl}` ),
                }
                return ret
              }
              return undefined

            }
            const mMarker ={ 
              id: curr.getADId(),
              position: {lat, lng}, 
              title: formatDate(curr.getADDateHourStartsOn(), undefined)  + " " + curr.getADTitle(),
              ADDateHourStartsOn: curr.getADDateHourStartsOn(),
              aAdDoc: curr,
            } as any
            const mIcon = getIconUsingCategory(curr)
            if (mIcon !== undefined) {
              mMarker["icon"] = mIcon
            }
            prev.push(mMarker)
          }
        return prev
      },  Array<any>())
      return res
    }
    const getMapCenter = () => {
      const location = (filterAttributesStore.state.searchLocationFilter) ? 
        filterAttributesStore.state.searchLocationFilter :
        userStore.state.aUserProfile.getUPLocation() 
      if (location !== undefined) {
        return { lat: location?.LPLat, lng: location?.LPLng }
      }
      return undefined
    }
    const page: any = reactive({
      openedMarkerID: null,
      searchLocationFilter: computed(() => (filterAttributesStore.state.searchLocationFilter) ? 
        filterAttributesStore.state.searchLocationFilter :
        userStore.state.aUserProfile.getUPLocation()), 
        //userStore.state.userProfile?.UPLocation), 
      arrayOfAdDocs: computed(() => documStore.state.AdDocsArray),
      arrayOfMarkers: computed(() => convertAdDocsToMarkerOptions(documStore.state.AdDocsArray)),
      fGeoOn: computed(() => 
        ((getOneOfAllFilters(filterAttributesStore.state, "allLocalMyFilter")).filterSelected === "allLocalAdDocs")), 
      mapCenter: computed(() => {return getMapCenter()})

    });
    const searchDistanceInMFilter = computed(() => filterAttributesStore.state.searchDistanceInMFilter)
    const closeModal = () => {
      appStore.commit("setShowMapModal", {showMapModal: false});
    }
    const mapStyles =[
  {
    "featureType": "administrative",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "landscape",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
];
    const mapOptions = {
      styles: mapStyles,
      fullscreenControl: false,
    }
    const openAdDocFromMap = (aAdDoc: any) => {
      documStore.dispatch("openAdDocInModal", {
        aAdDoc: aAdDoc,
        appStore: appStore,
        userStore: userStore,
      });
    }
    const clusterIcon = [{
        width: 30,
        height: 30,
        className: "custom-clustericon custom-clustericon-1",
      },
      {
        width: 40,
        height: 40,
        className: "custom-clustericon custom-clustericon-2",
      },
      {
        width: 50,
        height: 50,
        className: "custom-clustericon custom-clustericon-3",
      },
    ]

    let updateTimeout = undefined as any;

    const askForAddAdDoc = (ev: any) => {
      const openAddAdDoc = window.confirm(t('addAdDocOnMap'))
      if (openAddAdDoc === true) {
        documStore.dispatch("createNewAdDocAtLocation", {
          appStore: appStore,
          userStore: userStore,
          latLng: {
            lat: ev.latLng.lat(),
            lng: ev.latLng.lng()
             // {"lat":45.746943247708906,"lng":4.840842055511492}
          }
        });
        // router.push("/add")
        closeModal()
      }
    }

    const clickOnMap = (ev: any) => {
      console.error('map CLICKED')
      console.error('map CLICKED ev ' + JSON.stringify(ev))
      updateTimeout = setTimeout(function(){
        askForAddAdDoc(ev)
    }, 200);   
            

    }

    const dblClickOnMap = (ev: any) => {
      console.error('map dblClickOnMap')
      console.error('map dblClickOnMap ev ' + JSON.stringify(ev))
      clearTimeout(updateTimeout);
    }

    return {
      ...toRefs(page), 
      mapOptions,
      openAdDocFromMap,
      clusterIcon,
      clickOnMap,
      dblClickOnMap,
      closeModal
    }
  },
})

/*
Code using popup from google
          <GMapMarker
              :key="index"
              v-for="(m, index) in arrayOfMarkers"
              :title="m.title"
              :label="m.label"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              :icon= "m.icon"
              @click="openMarker(m.id)">
              <GMapInfoWindow v-if="1 === 0" :closeclick="true"
                @closeclick="openMarker(null)"
                :opened="openedMarkerID === m.id">
                <div>
                  <ProjectAdDocumentView
                    :aAdDoc="m.aAdDoc"
                    :userIsOwner="false"
                    :userCanEdit="false"
                    :displayInModal="true"
                    @closeMapModal="closeModal()"
                    @closeAdDocument="openMarker(null)"
                  ></ProjectAdDocumentView>

                </div>
              </GMapInfoWindow>
          </GMapMarker>
    const openMarker = (id: string) => {
       page.openedMarkerID = id
    }

*/
</script>

<template>
  <p class="noResults" data-cy="cyDocumentNotFoundInSearchList">{{ t("noResults") }}</p>
  <div>
    <AdDocListNavCallToAction :adDocFileType="adDocFileType"></AdDocListNavCallToAction>
  </div>
</template>

<script lang="ts">
import { toRefs, reactive } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";
import AdDocListNavCallToAction from "@/engineproject/components/AdDocList/AdDocListNavCallToAction.vue";

export default {
  props: {
    adDocFileType: {
      type: String,
      required: false,
      default: ""
    },
  },
  name:"DashboardSearchNoResult",
  setup(props: any) {
    const { t } = useI18n();
    const page = reactive({
    });

    return {
      ...toRefs(page),
      t
    };
  },
  components: {
    AdDocListNavCallToAction
  },
};
</script>


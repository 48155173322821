// service-worker.js

/// <reference lib="webworker" />

declare const self: ServiceWorkerGlobalScope;

 /* eslint-disable no-console */
import { register } from 'register-service-worker'

const displayNotif = (notifMsg: string) => {
  console.error("displayNotif " + notifMsg)
  const event = new CustomEvent("displayNotif", {
    detail: {
      notifMsg
    }
  });
  document.dispatchEvent(event);
}
// console.error("DOM")
/*setTimeout(() => {
  displayNotif("test")
}, 5000)
*/

let forceReloadDone = false
const forceReload = () => {
  console.error("forceReload " + forceReloadDone)
  // if (forceReloadDone === false) {
    forceReloadDone = true
    // eslint-disable-next-line 
    // @ts-ignore
    window.location.reload(true);
  //  }
}

let newContentAvailablePleaseDisplayRefreshDone = false
const sendNewContentAvailablePleaseDisplayRefresh = () => {
  // console.error("NewContentAvailablePleaseDisplayRefreshDone " + newContentAvailablePleaseDisplayRefreshDone)
  if (newContentAvailablePleaseDisplayRefreshDone === false) {
    newContentAvailablePleaseDisplayRefreshDone = true
    setTimeout(() => {
      const event = new Event("NewContentAvailablePleaseDisplayRefresh", {"bubbles":true, "cancelable":true});
      document.dispatchEvent(event);
      document.addEventListener('RefreshServiceWorkerReceived', function (e: any) {
        //displayNotif('RefreshServiceWorkerReceived 999 ')
        forceReload();
      }, false);
    }, 5000)
  } else {
    console.error("newContentAvailablePleaseDisplayRefreshDone is already true, nothing done!")
  }
}

const sendNewUpdateFoundInfoMsg = () => {
  // console.error("NewContentAvailablePleaseDisplayRefreshDone " + newContentAvailablePleaseDisplayRefreshDone)
  setTimeout(() => {
    const event = new Event("NewUpdateFoundInfoMsg", {"bubbles":true, "cancelable":true});
    document.dispatchEvent(event);
  }, 500)
}

/*
document.addEventListener('RefreshServiceWorkerReceived', function (e) {
  forceReload()
})
*/

let updatefound = false;

// console.error("register-service-worker: " + process.env.NODE_ENV)
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered (reg) {
      console.log('Service worker has been registered 4.')
      // displayNotif('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
      if (updatefound === true) {
        setTimeout(() => {
          console.log('New content was cached 50 s. ago, display a refresh if not already done ... newContentAvailablePleaseDisplayRefreshDone: ' + newContentAvailablePleaseDisplayRefreshDone)
          
          sendNewContentAvailablePleaseDisplayRefresh()
        }, 50000)
      }
    },
    updatefound () {
      console.log('New content is downloading.')
      updatefound = true;
      sendNewUpdateFoundInfoMsg()

    },
    updated (registration) {
      console.log('New content is available; please refresh. ')
      //displayNotif('New content is available; please refresh.')
      //const event = document.createEvent('Event');
      //event.initEvent('NewContentAvailablePleaseDisplayRefresh', true, true);
      sendNewContentAvailablePleaseDisplayRefresh()
      const waitingServiceWorker = registration.waiting
      /*setTimeout(() => {
        if (registration && registration.waiting) {
          displayNotif('RefreshServiceWorkerReceived addEventListener skipWaiting ')
          registration.waiting.addEventListener('message', (event: any) => {
            if (event.data === 'skipWaiting') {
              if (registration.waiting) {
                displayNotif('RefreshServiceWorkerReceived self.skipWaiting() ')
                //self.skipWaiting();
                self.skipWaiting().then(function() {
                  //Do something
                  forceReload();
                });
              }
            }
          });
        } else {
          displayNotif('RefreshServiceWorkerReceived registration.waiting is undefined ... ...')
        }
      }, 2000)*/
      document.addEventListener('RefreshServiceWorkerReceived', function (e: any) {
        // displayNotif('RefreshServiceWorkerReceived 1 ')
        newContentAvailablePleaseDisplayRefreshDone = true
        //setTimeout(() => { DO NOT PUT THIS TIMEOUT THE APPLICATION NEVER UPGRADE ON ANDROID AND CHROME APP
          if (waitingServiceWorker) {
            // displayNotif('RefreshServiceWorkerReceived 2 ')
            waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
            forceReload();
          } else if (registration.waiting) {
            // displayNotif('RefreshServiceWorkerReceived 3 ')
          // eslint-disable-next-line 
          // @ts-ignore
          registration.waiting.postMessage({ type: 'SKIP_WAITING' })
            forceReload();
          }
        //}, 1000)
      }, false);
      
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
      displayNotif('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
      displayNotif('Error during service worker registration:' + error)
    }
  })
  
}

<template>
  <div class="filter-container" data-cy="cyFilterContainerInLine" >
    <ul class="inline">
      <li @click="filterChanged('all')" 
        :class="(currentFilterAttributesObjects === 'all') ? 'filerSelectedInline' : ''">all</li>
      <li @click="filterChanged('premium')"
          :class="(currentFilterAttributesObjects === 'premium') ? 'filerSelectedInline' : ''">premium</li>
      </ul>
  </div>
</template>

<script lang="ts">
import {  reactive, toRefs, computed } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { useFilterUsersStore} from '@/engineproject/store/filterUsersStore'

export default {
  props: {
    stateObject: {
      type: String,
      required: true
    },
    emptyValueAccepted: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  emits: ["filterChanged"],
  setup(props: any, context: any) {
    const filterUsersStore = useFilterUsersStore()
    const {t} = useI18n()
    const page = reactive({
      currentFilterAttributesObjects: computed(() => filterUsersStore.state.mainFilter),

    });
    const filterChanged = (newFilterValue: string) => {
      filterUsersStore.commit("setMainFilter", {mainFilterValue: newFilterValue})
      context.emit("filterChanged", newFilterValue)
    }
    return {
      ...toRefs(page),
      t,
      filterChanged,
    }
  },
  components: { 
  },
}


</script>
<style lang="scss" scoped>

.filterVisibleCommand {
  font-size: 0.8rem;
  margin-left: 14px;
}
.filter-container {
  margin: 4px;
  padding: 2px;
}

.filerSelectedInline {
  text-decoration: underline;
}
  ul {
    //float: right;
    //text-align: right;

    li {
      margin-top: 4px;
      margin-left: 6px;
      cursor: pointer;

      a {
        display: block;
        padding: 5px;
      }
    }
  }

</style>

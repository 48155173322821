<template>
  <UserProfileViewModalTemplate>
    <template #content="{aUserProfile}">
      <transition name="fade">
        <div  class="pModal">
          <div class="pContainer">
            <div class="headerLinks">
              <a @click="$emit('close')" class="closeUserProfile">{{ t("closeAdDocument") }}</a>
            </div>
            <UserProfileViewContent
              v-on:refresh:display="$emit('close')"
              :aUserProfile="aUserProfile"></UserProfileViewContent>
          </div>
        </div>
      </transition>
    </template>
  </UserProfileViewModalTemplate>
</template>
<script lang="ts">
import { useI18n } from "@/engineproject/default/common/i18n";

import UserProfileViewModalTemplate from '@/engineproject/components/UserProfile/UserProfileViewModalTemplate.vue';
import UserProfileViewContent from '@/engineproject/default/common/components/UserProfile/UserProfileViewContent.vue';

export default  {
  emits: ["remove", "close"],
  setup(props: any, context: any) {
    const {t} = useI18n()
    return {
      t,
    }
  },
  components: {
    UserProfileViewModalTemplate,
    UserProfileViewContent,
  },
}

</script>

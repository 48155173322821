//import { UserTypes } from "./userTypes";
import { EnterpriseInfoTypesCWB } from "./commonWithBackend/enterpriseInfoTypesCWB";

/*
export interface EnterpriseContributionsEvent {
  ECActivityId: string;
  ECActivityTitle: string;
  ECActivityDateHourStartsOn: Date;
  ECActivityUnivers: string;
  ECActivityCatsInUnivers: string;
  ECActivityOwnerMiniProfile: UserTypes.OwnerMiniProfile;
  ECMiniProfile: UserTypes.UserMiniProfile;
  ECNbHours: number;
}
*/
//declare module "lazy-cookies" {

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace EnterpriseInfoTypes {
// eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface EnterpriseInfo extends EnterpriseInfoTypesCWB.EnterpriseInfo {
  }
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EnterpriseContributionsEvent extends EnterpriseInfoTypesCWB.EnterpriseContributionsEvent {
  }

/*
  export interface EnterpriseInfo {
    EIEnterpriseId: string;
    EISearchId: string; // Used to filter the enterprise ads
    EIName: string;
    EILat?: number;
    EILng?: number;
    EIZipCode?: string;
    EIGeoHash?: string;
    EICity?: string;
    EIAddress?: string;
    EIPhone?: string;
    EIWeb?: string;
    EIColContributionsEvents?: Array<EnterpriseContributionsEvent>;

  }
*/

}

export const allPublicWithoutEnterprise = EnterpriseInfoTypesCWB.allPublicWithoutEnterprise

/*
export const allPublicWithoutEnterprise = {
  EIEnterpriseId: "a",
  EIName: "tout public",
  EISearchId: "*"
}
*/
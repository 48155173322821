import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "profileListViewItemRightPart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.allowedChecked) = $event)),
        onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.allowedEnterpriseClicked($event)))
      }, null, 512), [
        [_vModelCheckbox, _ctx.allowedChecked]
      ]),
      _createTextVNode(" " + _toDisplayString($props.aEnterpriseInfo.OEIData.EIName) + " " + _toDisplayString($props.aEnterpriseInfo.OEIData.EISearchId) + " " + _toDisplayString($props.aEnterpriseInfo.OEIData.EIEnterpriseId), 1)
    ])
  ]))
}
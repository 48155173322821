{
  "itemViewDetails": "Voir les details",
  "itemProposedBy": "Proposé par:",
  "itemPublishedOn": "Publié:",
  "itemPremiumTitle": " - Promotion",
  "itemSeller": "Vendeur:",
  "itemLikes": "J'aime:",
  "itemComments": "Commentaires:",
  "itemLikedBy": "Aimé par:",
  "loadingComments": "Chargement des commentaires ...",
  "loadingLikes": "Chargement des 'J'aime' ...",
  "itemRemainingPlacesTitle": "Il ne reste que {ramainingPlaces} places!",
  "itemRemainingOnePlaceTitle": "Il ne reste que 1 place!",
  "removeAdDocument": "Supprimer",
  "reportAdDocument": "Signaler",
  "editAdDocument": "Modifier",
  "maximizeAdDocument": "Maximize",
  "closeAdDocument": "Fermer",
  "addAComment": "Ajouter le commentaire",
  "addComment": "Ajouter",
  "sendReport": "Signaler un abus",
  "addReportDetails": "Préciser les raisons du signalement:",
  "moreLink": "Suite...",
  "moreLinkTitle": "Afficher la suite des éléments ",
  "proposeAnAdDoc": "Prenez l'initiative en créant votre propre annonce...",
  "proposeAnAdDocTitle": "Proposer votre annonce ",
  "proposeAnAdDocModel": "Créer votre modèle ",
  "proposeAnAdDocModelTitle": "Créez votre modèle pour pouvoir créer rapidement et périodiquement des activités ",
  "proposeAnAdDocSuggestion": "Créer votre suggestion ",
  "proposeAnAdDocSuggestionTitle": "Créez votre suggestion et publiez là pour que les utilisateurs puissent l'utiliser ",
  "askCoaching": "Demandez de l'aide à votre coach...",
  "askCoachingTitle": "Envoyer une demande particulière à un coach qui évaluera avec vous une stratégie à mettre en place",
  "docNotInDatabase": "Le document n'existe pas ou plus!",
  "docLoadingFromDatabase": "Chargement du document ...",
  "leaveWithoutSaving": "Quitter sans sauvegarder?",
  "DocChangeTitleADDateHourStartsOn": "Changement d'horaire",
  "DocChangeTitleADLocation": "Changement de lieu",
  "DocChangeTitleADYourPrice": "Changement de prix",
  "DocChangeTitleADFreeSubscription": "Changement du mode d'inscription",
  "DocChangeTitleADTitle": "Changement du titre",
  "DocChangeTitleADDescription": "Changement de la description",
  "DocChangeTitle": "Changement",
  "aDocChangeNewDate": "Nouvelle date:",
  "aDocChangeNewLocation": "Nouveau lieu:",
  "aDocChangeNewPrice": "Nouveau prix:",
  "aDocChangeOldValue": "Ancienne valeur:",
  "aDocChangeNewValue": "Nouvelle valeur:",
  "aDocChangeNoChange": "Pas de changement",
  "adDocDetailDescriptionTitle": "Description:",
  "adDocDetailPlacesTitle": "Nombre de places:",
  "adDocDescriptionTitle": "Description:",
  "noResults": "Il n'y a actuellement aucun élément",
  "modelsTitle": "MODELES",
  "helpButtonTitle": "Cliquez ici pour afficher plus d'informations concernant l'utilisation des modèles.",
  "modelsHelpText": "Les modèles sont créées et visibles uniquement par les comptes Premium (et les administrateurs).",
  "modelsHelpText2": "Si vous créez régulièrement le même type d'activité promotionnelle, un modèle d'activité vous permet de gagner du temps.",
  "modelsHelpText3": "Par exemple, si toutes les semaines vous organisez une promotion -10% sur l'accès à votre futsal. ",
  "modelsHelpText4": "Vous créez un modèle avec le lieu de la salle, les conditions, le titre et le texte de votre modèle d'activité.",
  "modelsHelpText5": "Toutes les semaines, au moment de publier votre activité, vous n'aurez qu'à préciser la date et l'horaire.",
  "modelsHelpText6": "Vos modèles sont personnels et ne sont visibles que par vous.",
  "modelsHelpAdminText": "Pour les ADMINISTRATEURS uniquement:",
  "modelsHelpAdminText2": "Les administrateurs de l'application peuvent créer des modèles particuliers: des idées de modèle.",
  "modelsHelpAdminText3": "Ces modèles sont visibles par tous les utilisateurs Premium et utilisables à partir du formulaire de création d'activité. ",
  "modelsHelpAdminText4": "Le choix de la création d'une idée de modèle est possible au moment de l'enregistrement. ",
  "suggestionsTitle": "SUGGESTIONS",
  "suggestionsHelpText": "Les suggestions sont créées uniquement par les administrateurs.",
  "suggestionsHelpText2": "Les suggestions sont visibles par tout les utilisateurs non partenaire sous la forme d'un carrousel au moment du choix du type d'activité à créer. ",
  "suggestionsHelpText3": "Les suggestions ne sont pas visibles par les utilisateurs partenaires (Premium), un partenaire ne va jamais crééer une activité chez un partenaire concurrent. ",
  "suggestionsHelpText4": "Les suggestions ne sont pas des modèles d'activité.",
  "suggestionsHelpText5": "Les suggestions d'activités servent à promouvoir les partenaires Alomigo auprès des utilisateurs non Premium.",
  "suggestionsHelpText6": "Les suggestions simplifient aussi la création d'une activité, comme les modèles, mais sont en général liées à un partenaire et donc à une localisation précise (restaurant, salle de sport, ...).",
  "searchMaxLimitReachedText1": "Attention vos critères de recherche ne sont pas assez précis pour garantir une liste de résultats exhaustive.",
  "searchMaxLimitReachedText2": "Réduisez le périmètre de votre recherche, utilisez un filtre par catégorie ou par date.",
  "freeSubscriptionInListViewTitle": "Inscription libre, non modérée "
}
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 4 }
const _hoisted_6 = { key: 6 }
const _hoisted_7 = { key: 7 }
const _hoisted_8 = { key: 8 }
const _hoisted_9 = { key: 9 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DisplayRemainingPlaces = _resolveComponent("DisplayRemainingPlaces")!
  const _component_FormatDate = _resolveComponent("FormatDate")!
  const _component_ImagesView = _resolveComponent("ImagesView")!
  const _component_DefaultImagesView = _resolveComponent("DefaultImagesView")!
  const _component_FormatPrice = _resolveComponent("FormatPrice")!
  const _component_GeolocationView = _resolveComponent("GeolocationView")!
  const _component_AdDocViewMyTransactionInAdDocList = _resolveComponent("AdDocViewMyTransactionInAdDocList")!
  const _component_OwnerProfileViewLink = _resolveComponent("OwnerProfileViewLink")!
  const _component_EnterpriseDisplay = _resolveComponent("EnterpriseDisplay")!
  const _component_CommentsLikes = _resolveComponent("CommentsLikes")!
  const _component_AdDocumentViewMainPartTemplate = _resolveComponent("AdDocumentViewMainPartTemplate")!

  return (_openBlock(), _createBlock(_component_AdDocumentViewMainPartTemplate, {
    displayInModal: false,
    displayCommentsLikesOnly: false,
    displayFromList: true,
    indexDocInList: $props.indexDocInList
  }, {
    content: _withCtx(({ aAdDoc, indexDocInList }) => [
      _createElementVNode("div", null, [
        (indexDocInList < 3 && aAdDoc.OADData.ADDisponibility < 5)
          ? (_openBlock(), _createBlock(_component_DisplayRemainingPlaces, { key: 0 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.t(
                aAdDoc.OADData.ADDisponibility > 1
                  ? "itemRemainingPlacesTitle"
                  : "itemRemainingOnePlaceTitle",
                {
                  ramainingPlaces: aAdDoc.OADData.ADDisponibility,
                }
              )), 1)
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        _createElementVNode("h4", null, [
          _createVNode(_component_FormatDate, {
            prefix: "Le ",
            inputDate: aAdDoc.OADData.ADDateHourStartsOn
          }, null, 8, ["inputDate"]),
          (aAdDoc.isFromPremiumAccount() === true)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($setup.t("itemPremiumTitle")), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("h4", null, _toDisplayString(aAdDoc.OADData.ADTitle), 1),
        (aAdDoc.OADData.ADColAttDoc && aAdDoc.OADData.ADColAttDoc.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_ImagesView, {
                listOfImages: aAdDoc.OADData.ADColAttDoc,
                firstImageOnly: true
              }, null, 8, ["listOfImages"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_DefaultImagesView, { aAdDoc: aAdDoc }, null, 8, ["aAdDoc"])
            ])),
        (_ctx.toggleViewDetails === true)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(aAdDoc.OADData.ADDescription), 1))
          : _createCommentVNode("", true),
        (_ctx.toggleViewDetails === true && aAdDoc.OADData.ADDisponibility)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($setup.t("adDocDetailPlacesTitle")) + " " + _toDisplayString(aAdDoc.OADData.ADDisponibility), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_FormatPrice, {
          inputPrice: aAdDoc.OADData.ADYourPrice,
          inputPaidByOwner: aAdDoc.OADData.ADPercentagePaidByOwner,
          inputPriceLabel: 
            aAdDoc.isFromPremiumAccount() === true
              ? 'adDocPremiumPrice'
              : 'adDocNormalPrice'
          
        }, null, 8, ["inputPrice", "inputPaidByOwner", "inputPriceLabel"]),
        (_ctx.toggleViewDetails === true)
          ? (_openBlock(), _createBlock(_component_FormatDate, {
              key: 5,
              prefix: "Termine le: ",
              inputDate: aAdDoc.OADData.ADDateHourEndsOn
            }, null, 8, ["inputDate"]))
          : _createCommentVNode("", true),
        (aAdDoc.OADData.ADLocation)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_GeolocationView, {
                aLocation: aAdDoc.OADData.ADLocation
              }, null, 8, ["aLocation"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_AdDocViewMyTransactionInAdDocList, { aAdDoc: aAdDoc }, null, 8, ["aAdDoc"]),
        (_ctx.toggleViewDetails === true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_OwnerProfileViewLink, {
                ownerMiniProfile: aAdDoc.OADData.ADOwnerMiniProfile,
                displayWannaBeAppBoss: false
              }, null, 8, ["ownerMiniProfile"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.toggleViewDetails === true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_EnterpriseDisplay, {
                displayNoEnterprise: false,
                aEnterprise: aAdDoc.OADData.ADEnterprise
              }, null, 8, ["aEnterprise"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.toggleViewDetails === true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString($setup.t("itemPublishedOn")) + " " + _toDisplayString($setup.formatDate(aAdDoc.OADData.ADCreatedOn)), 1))
          : _createCommentVNode("", true),
        (_ctx.toggleViewDetails === true)
          ? (_openBlock(), _createBlock(_component_CommentsLikes, {
              key: 10,
              aAdDoc: aAdDoc,
              dataCYClass: "InListView"
            }, null, 8, ["aAdDoc"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["indexDocInList"]))
}
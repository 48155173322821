import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "filtersVisibleArea" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.filtersVisible===false)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "moreFilters",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.changeFiltersVisible()))
        }, _toDisplayString($setup.t('moreFiltersMenu')), 1))
      : _createCommentVNode("", true),
    (_ctx.filtersVisible===true)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "moreFilters",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.changeFiltersVisible()))
        }, _toDisplayString($setup.t('lessFiltersMenu')), 1))
      : _createCommentVNode("", true)
  ]))
}
<template>
  <div class="iconsContainer" v-if="phoneNumberUnmasked !== undefined">
    <a target="_blank" :href="'tel:' + phoneNumberUnmasked " :title="'Appeler le ' + linkToPhoneNumber">
      <i class="pi pi-phone"></i>
    </a> 
    <a target="_blank" :href="'sms:' + phoneNumberUnmasked " :title="'Envoyer un message au ' + linkToPhoneNumber">
              <i class="pi pi-comment"></i>

      </a> 
    <a target="_blank" :href="'https://wa.me/33' + phoneNumberUnmaskedAndInt + '?text=Je%20suis%20intéressé%20par%20la%20sortie%20VTT'"
        :title="'Discuter sur WhapsApp le ' + linkToPhoneNumber">
                        <i class="pi pi-comments"></i>
    </a>
    <a target="_blank" :href="'whatsapp://send?phone=33' + phoneNumberUnmaskedAndInt + '&text=Je%20suis%20intéressé%20par%20la%20sortie%20VTT'"
        :title="'Discuter sur WhapsApp le ' + linkToPhoneNumber">
                        <i class="pi pi-comments"></i>
    </a>

  </div>
</template>
<script lang="ts">
import { reactive, toRefs } from "vue";
import { unmaskAndInt } from '@/engineproject/domainModel/userToolsTypes';

import { useI18n } from "@/engineproject/default/common/i18n";
export default {
  props: {
    linkToPhoneNumber: {
      type: String,
      required: true
    },
  },
  setup(props: any, context: any) {
    const {t} = useI18n()
    const page = reactive({
      linkToPhoneNumber: props.linkToPhoneNumber,
      phoneNumberUnmaskedAndInt: unmaskAndInt(props.linkToPhoneNumber, true),
      phoneNumberUnmasked: unmaskAndInt(props.linkToPhoneNumber, false)
    });
    return {
      ...toRefs(page),
      t,   
    }
  },
}
</script>

<style lang="scss" scoped>
.pi {
  font-size: 1rem;
}

.iconsContainer {
  display: flex;
}

.iconsContainer > a {
  margin: 20px;
}
</style>

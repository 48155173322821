<template>
  <section>
    <div
      v-if="adDocFileType === 'S'"
      class="adminDisplayColor suggestionsTitle"
    >
      {{ t("suggestionsTitle") }}
    </div>
    <div v-if="adDocFileType === 'T'" class="modelsTitle">
      {{ t("modelsTitle") }}
    </div>
    <FilterInLine
      stateObject="universFilter"
      :emptyValueAccepted="false"
    ></FilterInLine>
  </section>
  <section :class="'classAdDocFileType' + adDocFileType">
    <div class="col1">
      <ProjectFilterAdDocNav
        :adDocFileType="adDocFileType"
      ></ProjectFilterAdDocNav>
    </div>
    <div class="col2">
      <DashboardSearchMaxLimitReached></DashboardSearchMaxLimitReached>
      <div v-if="getDisplayMapCenter" id="mapContainerInMainDisplay">
        <AdDocDisplayMap></AdDocDisplayMap>
      </div>
      <div v-if="arrayOfAdDocs.length">
        <!-- next-previous navigation menu -->
        <div
          class="postRow"
          :class="'postRow' + numberOfPostInRow"
          v-for="i in calculateNumberOfRows(arrayOfAdDocs)"
          :key="i"
        >
          <div
            v-for="aAdDoc in getArrayElementsAtPosition(arrayOfAdDocs, i)"
            :key="aAdDoc?.OADData?.ADId"
            class="post"
            :class="aAdDoc?.getCSSClassForPost()"
          >
            <ProjectAdDocumentListView
              :aAdDoc="aAdDoc"
              :indexDocInList="i"
            ></ProjectAdDocumentListView>
          </div>
        </div>
        <!-- next-previous navigation menu -->
        <AdDocListNav
          :adDocFileType="adDocFileType"
          :aAdDocSubList="arrayOfAdDocs"
          @previousSubList="previousSubList()"
          @nextSubList="nextSubList()"
        >
        </AdDocListNav>
      </div>
      <div v-else>
        <DashboardSearchNoResult
          :adDocFileType="adDocFileType"
        ></DashboardSearchNoResult>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import {
  toRefs,
  reactive,
  computed,
  watch,
  onMounted,
  onBeforeMount,
} from "vue";
import { useRoute } from "vue-router";
import router from "@/router";

import { useAppStore } from "@/engineproject/store/appStore";
import { useDocumStore } from "@/engineproject/store/documStore";
import {
  useFilterAttributesStore,
  getOneOfAllFilters,
  getUniversAndCatFilter,
  getDisplayMapCenter,
} from "@/engineproject/store/filterAttributesStore";
import { useUserStore } from "@/engineproject/store/userStore";

import { AdDocumentProjectObject } from "@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes";
import { AdDocumentProjectTypes } from "@/engineproject/default/common/domainModel/adDocumentProjectTypes";
import { useI18n } from "@/engineproject/default/common/i18n";

import ProjectAdDocumentListView from "@/engineproject/default/common/components/AdDocument/ProjectAdDocumentListView.vue";
import DashboardSearchMaxLimitReached from "@/engineproject/components/AdDocument/DashboardSearchMaxLimitReached.vue";
import DashboardSearchNoResult from "@/engineproject/components/AdDocument/DashboardSearchNoResult.vue";
import AdDocListNav from "@/engineproject/components/AdDocList/AdDocListNav.vue";
import ProjectFilterAdDocNav from "@/engineproject/default/common/components/filters/ProjectFilterAdDocNav.vue";
import FilterInLine from "@/engineproject/components/Filter/FilterInLine.vue";
import AdDocDisplayMap from "@/engineproject/components/AdDocInMap/AdDocDisplayMap.vue";

export default {
  props: {
    adDocFileType: {
      type: String,
      required: false,
      default: "",
    },
  },
  name: "DashboardSearchAndListView",
  setup(props: any) {
    const documStore = useDocumStore();
    const appStore = useAppStore();
    const filterAttributesStore = useFilterAttributesStore();
    const userStore = useUserStore();
    const route = useRoute();

    const { t } = useI18n();
    const calcNumberOfPostInRow = (ww: number) => (ww > 742 ? 2 : 1);
    const page = reactive({
      defaultProcessDone: computed(() => appStore.state.defaultProcessDone),
      selectedAdDoc: {} as AdDocumentProjectTypes.AdDocument,
      moreElementsInCollection: computed(
        () => documStore.state.moreElementsInCollection
      ),
      maxLimitInResultList: computed(() => appStore.state.maxLimitInResultList),
      aUserProfile: computed(() => userStore.state.aUserProfile),
      arrayOfAdDocs: computed(() => documStore.state.AdDocsArray),
      currentFilterAttributes: computed(() =>
        getOneOfAllFilters(filterAttributesStore.state, "allLocalMyFilter")
      ),
      numberOfPostInRow: calcNumberOfPostInRow(appStore.state.windowWidth),
      getDisplayMapCenter: computed(() => {
        return getDisplayMapCenter(
          filterAttributesStore.state,
          userStore.state
        );
      }),
    });
    const adScope = computed(
      () =>
        getOneOfAllFilters(filterAttributesStore.state, "allLocalMyFilter")
          .filterSelected
    );

    let universAndCatFilter = getUniversAndCatFilter(
      filterAttributesStore.state
    ); //filterAttributesStore.getters.universAndCatFilter
    const searchFileTypeFilter = computed(
      () => filterAttributesStore.state.searchFileTypeFilter
    );
    const oneOrMoreSearchFilterUpdated = computed(
      () => filterAttributesStore.state.oneOrMoreSearchFilterUpdated
    );
    const windowWidth = computed(() => appStore.state.windowWidth);
    let currentRoute = "";
    watch(windowWidth, (newValue, oldValue) => {
      page.numberOfPostInRow = calcNumberOfPostInRow(newValue);
    });

    const likeAdDoc = async (aAdDoc: AdDocumentProjectObject) => {
      if (aAdDoc === undefined) {
        return;
      }
      await documStore.dispatch("likeAdDoc", {
        aAdDoc,
        aUserProfile: page.aUserProfile,
        //aUser: page.userProfile,
        appStore: appStore,
      });
    };

    const calculateNumberOfRows = (arrayOfAdDocs: any) => {
      return Math.ceil(arrayOfAdDocs.length / page.numberOfPostInRow);
    };

    const getArrayElementsAtPosition = (arrayOfAdDocs: any, i: number) => {
      return arrayOfAdDocs.slice(
        (i - 1) * page.numberOfPostInRow,
        i * page.numberOfPostInRow
      );
    };

    const isLocalFilter = () => {
      return (
        page.currentFilterAttributes !== undefined &&
        page.currentFilterAttributes.filterSelected === "allLocalAdDocs"
      );
    };

    const getFilterParameters = () => {
      if (page.currentFilterAttributes !== undefined) {
        universAndCatFilter = getUniversAndCatFilter(
          filterAttributesStore.state
        );
        if (isLocalFilter() === true) {
          const nearLatLng =
            filterAttributesStore.getters.locationFilterLatLng !== undefined
              ? filterAttributesStore.getters.locationFilterLatLng
              : userStore.getters.userProfileLatLng;
          const nearRadiusInM =
            filterAttributesStore.state.searchDistanceInMFilter;
          return {
            currentFilter: page.currentFilterAttributes.filterSelected,
            calendarFilter: filterAttributesStore.state.searchCalendarFilter,
            enterpriseFilter: filterAttributesStore.state.searchEnterpriseFilter,
            isUSerEnterprise: filterAttributesStore.state.searchIsUSerEnterprise,
            nearLatLng,
            nearRadiusInM,
            universAndCatFilter: universAndCatFilter,
            adDocFileType: searchFileTypeFilter.value,
          };
        }
        return {
          currentFilter: page.currentFilterAttributes.filterSelected,
          calendarFilter: filterAttributesStore.state.searchCalendarFilter,
          enterpriseFilter: filterAttributesStore.state.searchEnterpriseFilter,
          isUSerEnterprise: filterAttributesStore.state.searchIsUSerEnterprise,
          nearLatLng: undefined,
          nearRadiusInM: undefined,
          universAndCatFilter: universAndCatFilter,
          adDocFileType: searchFileTypeFilter.value,
        };
      } else {
        console.error(
          "page.currentFilterAttributes " + page.currentFilterAttributes
        );
        return {
          currentFilter: "allAdDocs",
          calendarFilter: filterAttributesStore.state.searchCalendarFilter,
          enterpriseFilter: filterAttributesStore.state.searchEnterpriseFilter,
          isUSerEnterprise: filterAttributesStore.state.searchIsUSerEnterprise,
          nearLatLng: undefined,
          nearRadiusInM: undefined,
          universAndCatFilter: universAndCatFilter,
          adDocFileType: searchFileTypeFilter.value,
        };
      }
    };

    const nextSubList = async () => {
      // console.error("nextSubList " + JSON.stringify(page.arrayOfAdDocs));
      await documStore.dispatch("queryAdDoc", {
        optionsQuery: {
          nextAdDoc: page.arrayOfAdDocs[page.arrayOfAdDocs.length - 1],
          previousAdDoc: undefined,
          ...getFilterParameters(),
          maxLimit: page.maxLimitInResultList,
        },
        appStore,
        userStore,
      });
    };

    const reQuery = async () => {
      documStore.commit("setMoreElementsInCollection", true);
      documStore.commit("setAdDocs", []);
      await documStore.dispatch("queryAdDoc", {
        optionsQuery: {
          previousAdDoc: undefined,
          nextAdDoc: undefined,
          ...getFilterParameters(),
          maxLimit: page.maxLimitInResultList,
        },
        appStore,
        userStore,
      });
    };

    const filterChanged = async () => {
      if (
        isLocalFilter() === true &&
        appStore.state.defaultLocationForLocalFilterAlreadyDisplayed ===
          false &&
        filterAttributesStore.getters.locationFilterLatLng === undefined &&
        userStore.getters.userProfileLatLng === undefined
      ) {
        appStore.commit(
          "setDefaultLocationForLocalFilterAlreadyDisplayed",
          true
        );
        appStore.commit("setToastObject", {
          toastObject: {
            msgId: "setDefaultLocationForLocalFilter",
            msgLinkId: "setDefaultLocationForLocalFilterLink",
            toastAction: () => {
              router.push("/settings/itemContactProfileData");
            },
            toastType: "I",
          },
        });
      }
      await reQuery();
    };

    const getBaseRoute = (aRoute: string) => {
      const routeParts = aRoute.split("/");
      if (routeParts.length > 1) {
        return routeParts[0];
      }
      return "";
    };
    const isSameBaseRoute = (newRoute: string) => {
      const currentRoute = router.currentRoute.value;
      //console.error(
      //  "isSameRoute " + currentRoute.path.toLowerCase() + " ==== " + newRoute
      //);
      return (
        getBaseRoute(currentRoute.path.toLowerCase()) === getBaseRoute(newRoute)
      );
    };
    const getDefaultFilterUsingProfileLocation = () => {
      const location = userStore.state.aUserProfile.getUPLocation();
      //console.error("onBeforeMount location " + location);

      return "allAdDocs";
      //return location !== undefined ? "allLocalAdDocs" : "allAdDocs";
    };
    watch(oneOrMoreSearchFilterUpdated, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        filterChanged();
      }
    });
    watch(
      () => route.params.routeParamFilter,
      async (newValue, oldValue) => {
        universAndCatFilter = getUniversAndCatFilter(
          filterAttributesStore.state
        );
        if (
          newValue !== undefined &&
          newValue !== universAndCatFilter &&
          isSameBaseRoute(currentRoute) === true
        ) {
          await filterAttributesStore.dispatch("resetAllFilters", {
            filterName: "allLocalMyFilter",
            newSelectedValue:
              newValue !== ""
                ? newValue
                : getDefaultFilterUsingProfileLocation(),
            adDocFileType: props.adDocFileType,
          });
        }
      }
    );

    onBeforeMount(async () => {
      documStore.commit("setMoreElementsInCollection", true);
      documStore.commit("setAdDocs", []);
    });

    onMounted(async () => {
      currentRoute = router.currentRoute.value.path.toLowerCase();
      // If not in setTimeOuthe filter chande is never detected ...
      setTimeout(async () => {
        const { defaultUnivers, defaultCatInUnivers } =
          page.aUserProfile.getDefaultUniversAndCatsInUnivers();
        if (defaultUnivers !== "") {
          if (
            (props.adDocFileType === "" && page.defaultProcessDone === false) ||
            props.adDocFileType === "T"
          ) {
            appStore.commit("setDefaultProcessDone", {
              defaultProcessDone: true,
            });
            const newcurrentFilterAttributesObjectsFilterSelected =
              getOneOfAllFilters(filterAttributesStore.state, "universFilter");
            newcurrentFilterAttributesObjectsFilterSelected.filterSelected =
              defaultUnivers;
          }
        }
        if (route.params.routeParamFilter) {
          await filterAttributesStore.dispatch("resetAllFilters", {
            filterName: "allLocalMyFilter",
            newSelectedValue: route.params.routeParamFilter,
            adDocFileType: props.adDocFileType,
          });
        } else {
          await filterAttributesStore.dispatch("resetAllFilters", {
            filterName: "allLocalMyFilter",
            newSelectedValue: getDefaultFilterUsingProfileLocation(),
            adDocFileType: props.adDocFileType,
          });
        }
      }, 300);
    });

    return {
      ...toRefs(page),
      t,
      likeAdDoc,
      adScope,
      nextSubList,
      calculateNumberOfRows,
      getArrayElementsAtPosition,
    };
  },
  components: {
    AdDocListNav,
    DashboardSearchNoResult,
    ProjectFilterAdDocNav,
    ProjectAdDocumentListView,
    FilterInLine,
    AdDocDisplayMap,
    DashboardSearchMaxLimitReached,
  },
};
</script>


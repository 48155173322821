import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-cy": "cyCommentsLoaded"
}
const _hoisted_2 = { class: "subtitle" }
const _hoisted_3 = { class: "docComments" }
const _hoisted_4 = { class: "commentHeader" }
const _hoisted_5 = ["onClick", "title"]
const _hoisted_6 = ["onClick", "title"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  "data-cy": "cyCommentsLoading"
}
const _hoisted_9 = {
  key: 1,
  "data-cy": "cyCommentsLoaded"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.aAdDoc.OADData.ADColComments && $props.aAdDoc.OADData.ADColComments.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString($setup.t("itemComments")), 1),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.aAdDoc.OADData.ADColComments, (comment) => {
            return (_openBlock(), _createElementBlock("div", {
              key: comment.ACId,
              class: "comment"
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createElementVNode("a", {
                    onClick: ($event: any) => ($setup.viewUserProfile(comment.ACAUserId)),
                    title: $setup.t('openProfile')
                  }, _toDisplayString(comment.ACAPseudo), 9, _hoisted_5)
                ]),
                _createElementVNode("div", null, " - " + _toDisplayString($setup.formatDate(comment.ACCreatedOn)), 1),
                ($setup.canRemoveComment(comment.ACAUserId) === true)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: ($event: any) => ($setup.removeMyComment(comment.ACId)),
                      class: "removeComment",
                      title: $setup.t('removeCommentTitle')
                    }, "X", 8, _hoisted_6))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("pre", null, _toDisplayString(comment.ACContent), 1)
            ]))
          }), 128))
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
        ($props.aAdDoc.getCommentsIsLoading() === true )
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString($setup.t('loadingComments')), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_9))
      ]))
}
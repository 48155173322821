import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "imgPiUser pi pi-user"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.pUserPictureUrl != '')
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: $props.pUserPictureUrl
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2))
}
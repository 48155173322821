<template>
  <div class="banUserArea inlineSeparatedBlockContainer adminSuperPower">
    <div v-if="loggedUserProfile.isAdmin() === true && userClosed === true" class="inline" >

      <label class="inlineInput" >{{ t('closedUser') }}</label>
      <div>
        <FormatDate prefix="Le " :inputDate="aUserProfile.getClosedDate() "></FormatDate>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, reactive, toRefs } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { UserProfileProjectObject } from '@/engineproject/default/common/domainModel/userProfileProjectToolsTypes';
import { userStore } from '@/engineproject/store/userStore';

import FormatDate from '@/engineproject/components/UIElements/FormatDate.vue';

export default {
  props: {
    aUserProfile: {
      type: Object as () => UserProfileProjectObject,
      required: true
    },
  },
  emits: ["banUser"],
  setup(props: any, context: any) {
    const {t} = useI18n()
    const page = reactive({
      userClosed: computed(() => (props.aUserProfile.isClosed())),
      loggedUserProfile: computed(() => userStore.state.aUserProfile),
    });
    return {
      ...toRefs(page),
      t, 
    }
  },
  components: {
    FormatDate 
  },
}


</script>

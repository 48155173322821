import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "filter-container"
}
const _hoisted_2 = { for: "filterGeoOn" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "inlineSeparatedBlockContainer" }
const _hoisted_6 = { for: "fZipcode" }
const _hoisted_7 = { for: "fDistance" }
const _hoisted_8 = { class: "inlineSeparatedBlockContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GeolocationFromZipCodes = _resolveComponent("GeolocationFromZipCodes")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_GeolocationView = _resolveComponent("GeolocationView")!

  return (_ctx.fMyContactUsersOn === false || _ctx.fMyContactUsersOn === true)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("label", _hoisted_2, _toDisplayString($setup.t('filterGeolocalisationTitle')), 1),
          _createElementVNode("input", {
            "data-cy": "cyFilterProfileGeoOn",
            value: _ctx.fUserProfileGeoOn,
            type: "checkbox",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.setProfileGeoOnOff && $setup.setProfileGeoOnOff(...args))),
            id: "filterGeoOn"
          }, null, 8, _hoisted_3)
        ]),
        (_ctx.fUserProfileGeoOn === true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_6, _toDisplayString($setup.t('filterZipcode')), 1),
                  _createVNode(_component_GeolocationFromZipCodes, {
                    currentLocation: _ctx.searchLocationFilter,
                    id: "fZipcode",
                    placeholder: $setup.t('filterZipcodePH'),
                    "onUpdate:location": _cache[1] || (_cache[1] = ($event: any) => ($setup.updateLocation($event))),
                    inputClass: "inputClass"
                  }, null, 8, ["currentLocation", "placeholder"])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_7, _toDisplayString($setup.t('filterDistance')), 1),
                  _createVNode(_component_InputText, {
                    modelValue: $setup.searchDistanceInKMFilter,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.searchDistanceInKMFilter) = $event)),
                    type: "number",
                    placeholder: $setup.t('filterDistancePH'),
                    id: "fDistance",
                    onChange: _cache[3] || (_cache[3] = ($event: any) => ($setup.updateDistance($event)))
                  }, null, 8, ["modelValue", "placeholder"])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_GeolocationView, { aLocation: _ctx.searchLocationFilter }, null, 8, ["aLocation"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-cy": "cyLikeLoaded"
}
const _hoisted_2 = { class: "subtitle" }
const _hoisted_3 = ["onClick", "title"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  "data-cy": "cyLikesLoading"
}
const _hoisted_6 = {
  key: 1,
  "data-cy": "cyLikeLoaded"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.ADColLikes!== undefined && _ctx.ADColLikes.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString($setup.t("itemLikedBy")), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ADColLikes, (like) => {
          return (_openBlock(), _createElementBlock("div", {
            key: like.ALId,
            class: "likes"
          }, [
            _createElementVNode("p", null, [
              _createElementVNode("a", {
                onClick: ($event: any) => ($setup.viewUserProfile(like.ALLUserId)),
                title: $setup.t('openProfile')
              }, _toDisplayString(like.ALLPseudo), 9, _hoisted_3)
            ])
          ]))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        ($props.aAdDoc.getLikesIsLoading() === true )
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString($setup.t('loadingLikes')), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_6))
      ]))
}
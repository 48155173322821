<template>

  <div v-if="ADColTransactions !== undefined && ADColTransactions.length " data-cy="cyTransactionsLoaded">
    <div class="subtitle">{{ t("itemYourTransactionsStatus") }}</div>
    <div
      v-for="aTransaction in ADColTransactions"
      :key="aTransaction.ATId"
      class="transactions" >
      <div class="transactionElement">
        <div class="transactionElementTitleArea">
          <div>
            <span>{{ formatDate(aTransaction.ATCreatedOn)  }}
            {{ displayStatus(aTransaction.ATStatus) }}</span>

          </div>
          <div v-if="aTransaction.ATStatus == 'P' || aTransaction.ATStatus == 'F' " class="leftAlign">
            <button class="button" 
                data-cy="cyRemoveMyTransactionAdDoc"
              @click="removeMyTransactionAdDoc(aAdDoc)"
            >
            {{ t((aAdDoc.OADData.ADFreeSubscription === true) ? "adDocRemoveMyFreeSubscription" : "adDocRemoveMyTransaction") }}
            </button>
          </div>
          <div v-else-if="aTransaction.ATStatus == 'A'" class="leftAlign">
            <button class="button" 
                data-cy="cyOpenProfileFromA"
                :title="t('openProfile')" @click="viewUserProfile(aAdDoc.OADData.ADOwnerMiniProfile.UOUserId)">
            <OwnerProfileViewLink :ownerMiniProfile="aAdDoc.OADData.ADOwnerMiniProfile"
              prefixTextId="openProfileButtonText"></OwnerProfileViewLink>
            </button>
            <a 
                data-cy="cyRemoveMyTransactionAdDoc"
              @click="removeMyTransactionAdDoc(aAdDoc)">
            X
            </a> 
          </div>
        </div>
        <div class="transactionComment" :title="t('itemTransactionCommentTitle')">
          <pre>{{ aTransaction.ATComment }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { inject } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";
import { useDocumStore } from '@/engineproject/store/documStore';
import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes';
import { useAppStore } from '@/engineproject/store/appStore';
import { useUserStore } from '@/engineproject/store/userStore';

import OwnerProfileViewLink from '@/engineproject/components/UserProfile/OwnerProfileViewLink.vue';
import AdDocViewTransactionFootStep from '@/engineproject/components/AdDocTransaction/AdDocViewTransactionFootStep.vue';

export default {
  props: {
    ADColTransactions: {
      type: Object as () => Array<any>,
      required: false,
    },
    aAdDoc: {
      type: Object as () => Array<any>,
      required: true,
    },
  },
  setup(props: any, context: any) {
    const formatDate = inject('formatDate') as any;
    const documStore = useDocumStore();
    const { t } = useI18n();
    const appStore = useAppStore();
    const userStore = useUserStore();

    const displayStatus = (aStatusValue: string) => {
      const readableStatus = t("transactionStatus_" + aStatusValue)
      return t("transactionStatusTitle", {theStatus : readableStatus})
    }

    const viewUserProfile = (aUserId: string) => {
      appStore.commit("setInModalUserProfileId", {inModalUserProfileId: aUserId});
    }

    const removeMyTransactionAdDoc = async (aAdDoc: AdDocumentProjectObject) => {
      if (aAdDoc === undefined) {
        return;
      }
      await documStore.dispatch("removeMyTransactionAdDoc", {
        aAdDoc,
        appStore: appStore,
        userStore: userStore,
      });
    };

    return {
      t,
      displayStatus,
      formatDate,
      removeMyTransactionAdDoc,
      viewUserProfile,
    };
  },
  components: { 
    OwnerProfileViewLink,
  },
};
</script>
<style lang="scss" scoped>
</style>

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-090a0ca8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "filter-container",
  "data-cy": "cyFilterContainerInLine"
}
const _hoisted_2 = { class: "inline" }
const _hoisted_3 = ["data-cy", "onClick"]
const _hoisted_4 = { class: "filterVisibleCommand" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterVisibleCommand = _resolveComponent("FilterVisibleCommand")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newcurrentFilterAttributesObjects, (assetObj) => {
        return (_openBlock(), _createElementBlock("li", {
          key: assetObj.id,
          "data-cy": 'cy'+assetObj.id,
          class: _normalizeClass({filerSelectedInline : _ctx.newcurrentFilterAttributesObjectsFilterSelected.filterSelected == assetObj.id}),
          onClick: ($event: any) => ($setup.changeFilterSelected(assetObj.id))
        }, _toDisplayString(assetObj.lang), 11, _hoisted_3))
      }), 128)),
      _createElementVNode("li", _hoisted_4, [
        _createVNode(_component_FilterVisibleCommand)
      ])
    ])
  ]))
}
import * as fb from './firebase'

import { AppServerConfigTypes } from '../domainModel/appServerConfigTypes';

const defaultAppServerConfig = {
  ASCState: "OKDefaultWarning",
  ASCQueryLocalAdDocLimit: 2,
} as AppServerConfigTypes.AppServerConfig

export interface AppServerConfigAPIClient {
  getAppServerConfig( userPrivateProfileDocObserverReceived: any): Promise<any>;

}

class AppServerConfigFirebase implements AppServerConfigAPIClient {
  

  async getAppServerConfig(appServerConfigDocObserverReceived: any) {
    // console.error("getMyUserProfile")
    try {
      // fetch user profile
      // console.error("user " + userId)
      const appServerConfigFBDoc = fb.appServerConfigCollection.doc("main")
      const appServerConfigFB = await appServerConfigFBDoc.get()
      let appServerConfigObj = {} as AppServerConfigTypes.AppServerConfig
      // set user profile in state
      if (appServerConfigFB === undefined || appServerConfigFB.data() === undefined) {
        appServerConfigObj = {
          ... defaultAppServerConfig
        }
      } else {
        appServerConfigObj = {
          ... defaultAppServerConfig,
          ...appServerConfigFB.data()
        }
      }

      let appServerConfigDocObserver = undefined
      if (appServerConfigDocObserverReceived) {
        appServerConfigDocObserver = appServerConfigFBDoc.onSnapshot(docSnapshot => {
          // console.error(`Received doc snapshot: ${docSnapshot}`);
          if (docSnapshot.exists) {
            appServerConfigDocObserverReceived(docSnapshot.data())
          }
          // ...
        }, err => {
          console.error(`Encountered error: ${err}`);
        });
      }


        return {
          errorMsg: "",
          appServerConfigObj: appServerConfigObj,
          backendActionDone: true,
          appServerConfigDocObserver,
        }
    } catch(e) {
      console.error(e)
      return {
        backendActionDone: false,
        errorMsg: ""+e,
        appServerConfigObj: undefined,
        appServerConfigDocObserver: undefined
      }
    }
  }
}

export default AppServerConfigFirebase

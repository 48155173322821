import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38f3a134"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "filter-container"
}
const _hoisted_2 = { for: "filterMyAdsOn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.fMyAdsOn === true)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("label", _hoisted_2, _toDisplayString(($props.adDocFileType === 'T') ? $setup.t('filterMyModelsTitle') : 
          ( ($props.adDocFileType === 'S') ? $setup.t('filterMySuggestionsTitle') : $setup.t('filterMyAdsTitle'))), 1),
          _withDirectives(_createElementVNode("input", {
            "data-cy": "cyFilterMyAdsOn",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fMyAdsOn) = $event)),
            type: "checkbox",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.setMyAdsOnOff && $setup.setMyAdsOnOff(...args))),
            id: "filterMyAdsOn"
          }, null, 512), [
            [_vModelCheckbox, _ctx.fMyAdsOn]
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
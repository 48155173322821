import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f6bdf22"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "filter-container",
  "data-cy": "cyFilterContainerInLine"
}
const _hoisted_2 = { class: "inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.filterChanged('all'))),
        class: _normalizeClass((_ctx.currentFilterAttributesObjects === 'all') ? 'filerSelectedInline' : '')
      }, "all", 2),
      _createElementVNode("li", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.filterChanged('premium'))),
        class: _normalizeClass((_ctx.currentFilterAttributesObjects === 'premium') ? 'filerSelectedInline' : '')
      }, "premium", 2)
    ])
  ]))
}
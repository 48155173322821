import { AdDocumentProjectTypes } from "@/engineproject/default/common/domainModel/adDocumentProjectTypes"

export const DEFAULT_I18N = "fr"

export enum mandatoryState {
  notRequired = -1,
  requiredAndNotOk = 0,
  requiredAndOk = 1,
}

export const imageFileTypes = [
  ".jpg", ".png", ".jpeg", ".gif", ".ico"
]
export const docFileTypes = [
  ".pdf", ".doc", ".docx"
]
export const ankiFileTypes = [
  ".apkg"
]
export const endsMatchOneElement = (includesTypes: Array<string>, url: string) => {
  const matched = includesTypes.reduce((accumulator: Array<string>, currentValue: string) => {
    if (url.endsWith(currentValue)) {
      accumulator.push(currentValue)
    }
    return accumulator
  }, Array<string>())
  return (matched.length > 0) ? true : false
}
export const isImage = (fileName: string) => {
  return endsMatchOneElement(imageFileTypes, fileName)
}
export const getAttachedDocIncludingTypes = (includesTypes: Array<string>, docs: Array<AdDocumentProjectTypes.AdAttachedDocument>) => {
  if (docs === undefined) {
    return []
  }
  return docs.reduce((accumulator:  Array<AdDocumentProjectTypes.AdAttachedDocument>, 
      currentValue: AdDocumentProjectTypes.AdAttachedDocument) => {
        if (endsMatchOneElement(includesTypes, currentValue.AADDocumentName) === true) {
          accumulator.push(currentValue)
        }
        return accumulator
      },  Array<AdDocumentProjectTypes.AdAttachedDocument>())
}
export const getAttachedDocExcludingTypes = (excludesTypes: Array<string>, docs: Array<AdDocumentProjectTypes.AdAttachedDocument>) => {
  if (docs === undefined) {
    return []
  }
  return docs.reduce((accumulator:  Array<AdDocumentProjectTypes.AdAttachedDocument>, 
      currentValue: AdDocumentProjectTypes.AdAttachedDocument) => {
        if (endsMatchOneElement(excludesTypes, currentValue.AADDocumentName) === false) {
          accumulator.push(currentValue)
        }
        return accumulator
      },  Array<AdDocumentProjectTypes.AdAttachedDocument>())
}

export const fieldIsDefined = (aDate: any) => {
  return  (aDate !== null &&
    aDate !== undefined &&
    aDate != ""
    )
}

export const convertAttachedImagesToExternalImages= (aADColAttDoc: Array<AdDocumentProjectTypes.AdAttachedDocument>) => {
  // convert attached images to external images
  let newAttached = [...aADColAttDoc]
  newAttached = newAttached.map((anAttachedDoc) => {
    anAttachedDoc.AADId = "externalURL"
    return anAttachedDoc
  })
  return newAttached
}

/* Not used, externalUrl should be enought
export const mergeTwoADColAttDocFlaggingSuggestionUrl = (aADColAttDocFromSuggestion: Array<AdDocumentProjectTypes.AdAttachedDocument>,
  aADColAttDoc2: Array<AdDocumentProjectTypes.AdAttachedDocument>) => {
      // Keep the current attached docs (aADColAttDoc2) if any
      return [
        ...aADColAttDocFromSuggestion.map((attDoc) => {
          attDoc.AADFromSuggestion = true
          return attDoc
        }),
        ...aADColAttDoc2
      ]
}
*/

export const mergeTwoADColAttDocRemovingDupURLs = (aADColAttDocFromSuggestionOrTemplate: Array<AdDocumentProjectTypes.AdAttachedDocument>,
  aADColAttDoc2: Array<AdDocumentProjectTypes.AdAttachedDocument>) => {
    const contains = (a: Array<AdDocumentProjectTypes.AdAttachedDocument>, obj: AdDocumentProjectTypes.AdAttachedDocument) => {
      let i = a.length;
      while (i--) {
         if (a[i].AADDocumentUrl === obj.AADDocumentUrl) {
             return true;
         }
      }
      return false;
    }

    return [
      //...aADColAttDocFromSuggestionOrTemplate.filter(v => !contains(aADColAttDoc2, v)),
      ...convertAttachedImagesToExternalImages(aADColAttDocFromSuggestionOrTemplate).filter(v => !contains(aADColAttDoc2, v)),
      ...aADColAttDoc2
    ]
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-cy": "cyInvitationsLoaded"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdDocViewInvitationsRemoveButton = _resolveComponent("AdDocViewInvitationsRemoveButton")!
  const _component_AdDocViewInvitationsEmpty = _resolveComponent("AdDocViewInvitationsEmpty")!
  const _component_AdDocViewInvitationsOpenAddPopupButton = _resolveComponent("AdDocViewInvitationsOpenAddPopupButton")!

  return ($props.userCanEdit === true)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([$props.userIsOwner === false ? 'adminSuperPower' : '', "invitationArea"])
      }, [
        ($props.ADColInvitations !== undefined && $props.ADColInvitations.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_AdDocViewInvitationsRemoveButton, {
                aAdDoc: $props.aAdDoc,
                ADColInvitations: $props.ADColInvitations
              }, null, 8, ["aAdDoc", "ADColInvitations"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_AdDocViewInvitationsEmpty, { aAdDoc: $props.aAdDoc }, null, 8, ["aAdDoc"])
            ])),
        _createVNode(_component_AdDocViewInvitationsOpenAddPopupButton, { aAdDoc: $props.aAdDoc }, null, 8, ["aAdDoc"])
      ], 2))
    : _createCommentVNode("", true)
}
<template>
  <div class="toastTextContainer" :data-cy="'cyToastErrorID' + toastIdOrText">
    {{ toastText }}
    <a v-if="toastLinkText !== ''" 
      class="toastLinkText" @click="linkClicked">{{ toastLinkText }}</a>
  </div>
</template>
<script lang="ts">
export default {
  props: {
    toastText: {
      type: String,
      required: true
    },
    toastIdOrText: {
      type: String,
      required: true
    },
    toastLinkText: {
      type: String,
      required: false,
      default: ""
    },
    toastLinkClicked: {
      type: Function,
      required: false,
      default: undefined
    },
  },
  setup(props: any, context: any) {
    const linkClicked = () => {
      console.error("linkClicked")
      if (props.toastLinkClicked !== undefined) {
        props.toastLinkClicked()
      }
    }
    return {
      linkClicked
    }
  },
}


</script>

<template>
  <div v-if="aAdDoc.geTransactionsIsLoading() === true " data-cy="cyTransactionsLoading">
    {{ t('transactionIsLoadingText') }}
  </div>
  <div v-else  data-cy="cyTransactionsLoaded">
    <div class="subtitle">{{ t("itemTransactions") }}</div>
    {{ t("itemTransactionsEmpty") }}
  </div>
</template>

<script lang="ts">
import { useI18n } from "@/engineproject/default/common/i18n";

export default {
  props: {
    aAdDoc: {
      type: Object as () => Array<any>,
      required: true,
    },
  },
  setup(props: any, context: any) {
    const { t } = useI18n();

    return {
      t,
    };
  },
  components: { 
  },
};
</script>

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectUserProfileViewModal = _resolveComponent("ProjectUserProfileViewModal")!

  return (_ctx.showUserProfileModal)
    ? (_openBlock(), _createBlock(_component_ProjectUserProfileViewModal, {
        key: 0,
        aUserProfile: _ctx.selectedUserProfile,
        onClose: _cache[0] || (_cache[0] = ($event: any) => ($setup.closeUserProfileModal()))
      }, null, 8, ["aUserProfile"]))
    : _createCommentVNode("", true)
}
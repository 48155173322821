
import { computed, ComputedRef, InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes'
import { UserProjectTypes } from '../default/common/domainModel/userProjectTypes'
import { firebaseStorage } from '../backend/firebase'
import { applyToStoreDomainResults, blockApplicationUI } from './store'
import { AppServerConfigObject } from '../domainModel/appServerConfigToolsTypes'
import { appServerConfigControler } from '../domainModel/controlers/appServerConfigControler'

export const timerToFixBlockUIValue = 500;

export const waitTimeout = async (aDurationInMS: number) => {
  await new Promise((resolve) => {
    setTimeout(resolve, aDurationInMS);
  });
};

export interface ToastObject {
  msgId: string;
  msgLinkId: string;
  toastAction: any;
  toastType: string;
}

export interface AppState {
  mainMenuSelected: string;
  loadingMsg: string;
  errorMsg: string;
  notifMsg: string;
  toastObject: ToastObject | undefined;
  menuOpen: boolean;
  filtersVisible: boolean; 
  windowWidth: number;
  newAppVersion: string;
  newAppVersionIsComing: boolean;
  blockedApplication: boolean;
  blockedApplicationCounter: number;
  maxLimitInResultList: number;
  maxLimitInResultListForProfile: number;
  defaultProcessDone: boolean; 
  appDisplayOptions: Record<string, unknown>;
  inModalUserProfileId: string;
  inModalAdDocument: AdDocumentProjectObject;
  showProfilesListModal: boolean;
  showAdDocModal: boolean;
  showCommentModal: boolean;
  showReportModal: boolean;
  showCGUModal: boolean;
  showMapModal: boolean;
  showSuggestionDetailsModal: boolean;
  defaultLocationForLocalFilterAlreadyDisplayed: boolean;
  suggestionLocation: UserProjectTypes.LocationPoint | undefined;
  appStorageUrl: {
    [key: string]: string;
   };
  appServerConfigDocObserver: any;
  aAppServerConfig: AppServerConfigObject;

}

export const appKey: InjectionKey<Store<AppState>> = Symbol()

export function useAppStore () {
  return baseUseStore(appKey)
}

export const appStore = createStore<AppState>({
  state: {
    mainMenuSelected: "mainMenuHome",
    errorMsg: "",
    notifMsg: "",
    loadingMsg: "",
    toastObject: undefined,
    menuOpen: false,
    filtersVisible: (window.innerWidth > 742) ? true : false, 
    windowWidth: window.innerWidth,
    newAppVersion: "",
    newAppVersionIsComing: false,
    blockedApplication: false,
    blockedApplicationCounter: 0,
    maxLimitInResultList: 4,
    maxLimitInResultListForProfile: 20,
    defaultProcessDone: false,
    appDisplayOptions: {
      displayMiniProfileUsingPicture: false,
    },
    inModalUserProfileId: "",
    inModalAdDocument: new AdDocumentProjectObject(),
    showProfilesListModal: false,
    showAdDocModal: false,
    showCommentModal: false,
    showReportModal: false,
    showCGUModal: false,
    showMapModal: false,
    showSuggestionDetailsModal: false,
    defaultLocationForLocalFilterAlreadyDisplayed: false,
    suggestionLocation: undefined,
    appStorageUrl: {},
    appServerConfigDocObserver: undefined,
    aAppServerConfig: new AppServerConfigObject(),
  },
  mutations: {
    setWindowWidth(state) {
      if (state.windowWidth == window.innerWidth) {
        return
      }
      state.windowWidth = window.innerWidth;
      state.filtersVisible = (state.windowWidth > 742) ? true : false
    },
    swapFiltersVisible(state) {
      state.filtersVisible = ! state.filtersVisible
      // console.error("swapFiltersVisible " + state.filtersVisible)
    },
    /*setMenuOpen(state, {menuOpen}) {
      console.error("menuOpen in store" + menuOpen)
      state.menuOpen = menuOpen
    },*/
    setMainMenuSelected(state, {mainMenuSelected}) {
      state.mainMenuSelected = mainMenuSelected
    },
    setNewAppVersion(state, {newAppVersion}) {
      state.newAppVersion = newAppVersion
    },
    setNewAppVersionIsComing(state, {newAppVersionVal}) {
      state.newAppVersionIsComing = newAppVersionVal
    },
    /*setBlockedApplication(state, {blockedApplication}) {
      state.blockedApplication = blockedApplication
    },*/
    resetBlockedApplicationCounter(state) {
      state.blockedApplicationCounter = 0
      state.blockedApplication = false 
      // console.error("resetBlockedApplicationCounter " + state.blockedApplicationCounter)
    },
    setBlockedApplicationCounter(state, {incrementCouter}) {
      // console.error("setBlockedApplicationCounter Start " + state.blockedApplicationCounter)
      state.blockedApplicationCounter = (incrementCouter === true) ? state.blockedApplicationCounter + 1 : 
        Math.max(0, state.blockedApplicationCounter - 1)
      state.blockedApplication = (state.blockedApplicationCounter === 0) ? false : true
      // console.error("setBlockedApplicationCounter End " + state.blockedApplicationCounter)
    },
    setInModalUserProfileId(state, {inModalUserProfileId}) {
      state.inModalUserProfileId = inModalUserProfileId
    },
    setDefaultProcessDone(state, {defaultProcessDone}) {
      state.defaultProcessDone = defaultProcessDone
    },
    setInModalAdDocument(state, params: {inModalAdDocument: AdDocumentProjectObject}) {
      // console.error("setInModalAdDocument " + params?.inModalAdDocument?.getADId())
      state.inModalAdDocument = params.inModalAdDocument
    },
    setShowAdDocModal(state, {showAdDocModal}) {
      // console.error("setShowAdDocModal " + state.showAdDocModal + "  === " + showAdDocModal)
      state.showAdDocModal = showAdDocModal
    },
    setShowProfilesListModal(state, {showProfilesListModal}) {
      console.error("setShowProfilesListModal " + showProfilesListModal)
      state.showProfilesListModal = showProfilesListModal
    },
    setShowCommentModal(state, {showCommentModal}) {
      // console.error("setShowCommentModal " + state.showCommentModal + "  === " + showCommentModal)
      state.showCommentModal = showCommentModal
    },
    setShowReportModal(state, {showReportModal}) {
      state.showReportModal = showReportModal
    },
    setShowCGUModal(state, {show}) {
      state.showCGUModal = show
    },
    setShowMapModal(state, {showMapModal}) {
      state.showMapModal = showMapModal
    },
    setShowSuggestionDetailsModal(state, {showSuggestionDetailsModal, suggestionLocation}) {
      state.showSuggestionDetailsModal = showSuggestionDetailsModal
      state.suggestionLocation = suggestionLocation
    },
    setDefaultLocationForLocalFilterAlreadyDisplayed(state, newValue: boolean) {
      state.defaultLocationForLocalFilterAlreadyDisplayed = newValue
    },
    setNotifMsg(state, {notifMsg}) {
      state.notifMsg = notifMsg
    },
    setToastObject(state, {toastObject}) {
      state.toastObject = toastObject
    },
    setErrorMsg(state, {errorMsg}) {
      state.errorMsg = errorMsg
    },
    setLoadingMsg(state, {loadingMsg}) {
      state.loadingMsg = loadingMsg
    },
    setAppStorageUrl(state, {storageURI, url}) {
      state.appStorageUrl[storageURI] = url
    },
    updateAppServerConfig(state, newData) {
      //state.aAdDoc = (val === undefined) ? new AdDocumentProjectObject() /*val*/ : new AdDocumentProjectObject(val)
      state.aAppServerConfig = state.aAppServerConfig.updateAppServerConfig({
        ...newData.docUpdate
      })
    },
    setAppServerConfig(state, newData) {
      state.aAppServerConfig = new AppServerConfigObject({...newData.appServerConfigObj})
      if (state.appServerConfigDocObserver) {
        state.appServerConfigDocObserver()
      }
      state.appServerConfigDocObserver = newData.appServerConfigDocObserver
    },
  },
  getters: {
    // ...
  },
  actions: {
    /*async setNotifMsg({ state, commit }, notifMsg) {
      commit('setNotifMsg', {notifMsg})
    },*/
    async installNewAppVersion({ state, commit }, ) {
      console.error("installNewAppVersion 1")
      if (navigator !== undefined && navigator.serviceWorker !== undefined) {
        console.error("installNewAppVersion 2")
        const event = document.createEvent('Event');
        // Define that the event name is 'RefreshServiceWorkerReceived'.
        event.initEvent('RefreshServiceWorkerReceived', true, true);
        document.dispatchEvent(event);
        commit("setNewAppVersionIsComing", { newAppVersionVal: false });
      }
    },
    async displayNotifMsg({ state, commit }, {notifMsg}) {
      commit("setNotifMsg", {notifMsg});
    },
    async closeAllModal({ state, commit }) {
      commit("setInModalAdDocument", {inModalAdDocument: new AdDocumentProjectObject()});
      commit("setShowAdDocModal", {showAdDocModal: false});
      commit("setInModalUserProfileId", {inModalUserProfileId: ""});
      commit("setShowMapModal", {showMapModal: false});

    },
    async setAppStorageUrl({ state, commit }, storageURI: string) {
      // console.error("state.appStorageUrl[storageURI] " + storageURI + " ===> " + state.appStorageUrl[storageURI])
      if (state.appStorageUrl[storageURI] === undefined) {
        // To avoid several calls during the await and get the valid url
        // Set the url to empty string for the moment
        commit('setAppStorageUrl', {storageURI, url: ""})
        const url = await firebaseStorage.getStorageUrl(storageURI)
        commit('setAppStorageUrl', {storageURI, url})
      }
    },
    async fetchAppServerConfig({ state, commit }, options: { 
      appStore: Store<AppState>;}) {
      const userPrivateProfileDocObserverReceived = (docUpdate: any) => {
        commit("updateAppServerConfig", {
          docUpdate
        })
      }

      await blockApplicationUI(options, async () => {
        const resultFor = await appServerConfigControler.getAppServerConfig( userPrivateProfileDocObserverReceived)
        const rStoreProcess = [{
          processInputResult: resultFor.rForGetgetAppServerConfig,
          unknownErrorMsg: "Unknown error during getAppServerConfig process",
          processAction: commit,
          processActionTag: 'setAppServerConfig',
          validObj: {
            appServerConfigObj: resultFor.rForGetgetAppServerConfig.appServerConfigObj,
            appServerConfigDocObserver: resultFor.rForGetgetAppServerConfig.appServerConfigDocObserver,
            },
        }]
        await applyToStoreDomainResults( {
          appStore: options.appStore,
        }, rStoreProcess);
      })
    },
  },
  modules: {
  }
})

export const computeStorageUrisArray = (arrayOfUris: Array<Array<string>>) => {
  return arrayOfUris.reduce((prev: {[key: string]: ComputedRef<string>;
    }, curr) => {
    prev[curr[0]] = computed(() => appStore.state.appStorageUrl[curr[1]])
    return prev
  }, {})
}

export const setAppStorageUrlArray = (arrayOfUris: Array<Array<string>>) => {
  arrayOfUris.forEach((ele) => {
    appStore.dispatch("setAppStorageUrl", ele[1]);
  })
}


//export default store

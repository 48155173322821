<template>
  <AppLoading v-if="aUserProfile.isCurrentLoggedUser() !== true"></AppLoading>
  <div id="dashboard" class="dashboardArea" v-else>
    <DashboardSearchAndListView adDocFileType="">
    </DashboardSearchAndListView>
  </div>
</template>

<script lang="ts">
import { toRefs, reactive, computed } from "vue";
import { useUserStore } from "@/engineproject/store/userStore";
import { useI18n } from "@/engineproject/default/common/i18n";
import AppLoading from "@/engineproject/components/AppMenu/AppLoading.vue";
import DashboardSearchAndListView from "@/engineproject/components/AdDocument/DashboardSearchAndListView.vue";

export default {
  setup() {
    const userStore = useUserStore();
    const { t } = useI18n();
    const page = reactive({
      aUserProfile: computed(() => userStore.state.aUserProfile),
    });

    return {
      ...toRefs(page),
      t,
    };
  },
  components: { 
    DashboardSearchAndListView,
    AppLoading,
  },
};
</script>


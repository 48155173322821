
import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store, ActionContext } from 'vuex'

import router from "@/router/index"
import { adDocFirebase, userFirebase } from '@/engineproject/backend/firebase';
import { AdDocumentProjectTypes } from '@/engineproject/default/common/domainModel/adDocumentProjectTypes';
import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes';
import { mandatoryState, mergeTwoADColAttDocRemovingDupURLs } from '@/engineproject/domainModel/globalTypes';

import { saveDomainResultsInStores, applyToStoreDomainResults, blockApplicationUI } from '@/engineproject/store/store'
import { AppState } from './appStore';
import { UserState } from './userStore';
// import { adDocumentControler } from '../domainModel/adDocumentToolsTypes';

import { editAdDocOptionsType, removeAdDocOptionsType  } from './documStoreTypes';
import {fullLocale} from '@/engineproject/i18nApp'
import { UserProfileProjectObject } from "@/engineproject/default/common/domainModel/userProfileProjectToolsTypes";
import { UserProjectTypes } from '@/engineproject/default/common/domainModel/userProjectTypes';
import { getStrategiesToFindBestModels } from './filterAttributesTools';
import { UserTypes } from '../domainModel/userTypes';
import { FilterAttributesState } from './filterAttributesStore';
import { adDocumentControler } from '../domainModel/controlers/adDocumentControler';
import { adDocumentInvitationsControler } from '../domainModel/controlers/adDocumentInvitationsControler';
import { adDocumentTransactionsControler } from '../domainModel/controlers/adDocumentTransactionsControler';

// const adDocumentControler = new AdDocumentControler(adDocFirebase, userFirebase)

const setInAdDocsAndCurrentDoc = (state: DocumState, newData: AdDocumentProjectTypes.AdDocument, 
    setAction: any) => {
  if (newData.ADId === undefined) {
    console.error("DOMDOM newData.ADId undefined")
  }
  if (state.aAdDoc && state.aAdDoc.isADId(newData.ADId)) {
    
    state.aAdDoc = setAction(state.aAdDoc, newData)
  }
  state.AdDocsArray.forEach((item: AdDocumentProjectObject) => {
    if (item.isADId(newData.ADId)) {
      item = setAction(item, newData)
      //item.ADTotLikes = newData.newLikes
    }
  });
}


export interface DocumState {
  // adDocumentControler: AdDocumentControler;
  emptyDocState: any;
  AdDocsArray: Array<AdDocumentProjectObject>;
  suggestionAdDocsArray: Array<AdDocumentProjectObject>;
  modelAdDocsArray: Array<AdDocumentProjectObject>;
  moreElementsInCollection: boolean;
  limitMaxReachedInCollection: number;
  aAdDoc: AdDocumentProjectObject;
  aInitialDoc: AdDocumentProjectObject | undefined;
  selectedSuggestion: string;
  fromMiniModel: UserTypes.MiniAdDocModel;
  newAdDocumentLatLng: {
    lat: number;
    lng: number;
    } | undefined;
}

export const documKey: InjectionKey<Store<DocumState>> = Symbol()

// define your own `useStore` composition function
export function useDocumStore() {
  return baseUseStore(documKey)
}


const isRequiredUsingPublishedState = (state: DocumState, definedCB: any) => {
  if (state.aAdDoc === undefined ||
    state.aAdDoc.isPublished() === false) {
    return mandatoryState.notRequired
  }
  if (state.aAdDoc.isPublished() === true &&
    definedCB() === true) {
      return mandatoryState.requiredAndOk
    }
  return mandatoryState.requiredAndNotOk
}


export const documStore = createStore<DocumState>({
  state: {
    // adDocumentControler: new AdDocumentControler(adDocFirebase, userFirebase),
    emptyDocState: {},
    AdDocsArray: [],
    suggestionAdDocsArray: [],
    modelAdDocsArray: [],
    moreElementsInCollection: true,
    limitMaxReachedInCollection: 0,
    aAdDoc: new AdDocumentProjectObject(),
    aInitialDoc: undefined,
    selectedSuggestion: "",
    fromMiniModel: {},
    newAdDocumentLatLng: undefined,
  },
  mutations: {
    setInitialDoc(state, val) {
      // console.error("setInitialDoc")
      state.aInitialDoc = val
    },
    setSuggestionAdDocs(state, val) {
      state.suggestionAdDocsArray = val
    },
    setModelAdDocs(state, val) {
      state.modelAdDocsArray = val
    },
    addNewModelAdDocs(state, val: {
      newModelAdDocsArray: Array<AdDocumentProjectObject>;
      currentStrategy: string;
    }) {
      const contains = (a: any[], obj: any) => {
        let i = a.length;
        while (i--) {
           if (a[i].OADData.ADId === obj.OADData.ADId) {
               return true;
           }
        }
        return false;
      }
      const newModelAdDocsArray = val.newModelAdDocsArray.map((aModelDoc: any) => {
        aModelDoc["currentStrategy"] = val.currentStrategy
        return aModelDoc
      })
      const mergedData = [...state.modelAdDocsArray, 
        ...(newModelAdDocsArray.filter(v => !contains(state.modelAdDocsArray, v)))];
      state.modelAdDocsArray = mergedData
    },
    setAdDocs(state, val: Array<AdDocumentProjectObject>) {
      state.AdDocsArray = val
    },
    setLimitMaxReachedInCollection(state, val) {
      state.limitMaxReachedInCollection = val
    },
    setMoreElementsInCollection(state, val) {
      state.moreElementsInCollection = val
    },
    setADCollAttDoc(state, newADCollAttDoc) {
      state.aAdDoc.setADColAttDoc(newADCollAttDoc)
    },
    addNewAttDoc(state, anAttachedDoc) {
      state.aAdDoc.addNewAttDoc(anAttachedDoc)
    },
    setAdDocEnterprise(state, newEnterprise) {
      state.aAdDoc.setAdDocEnterprise(newEnterprise)
    },
    setAdDocId(state, val) {
      state.aAdDoc.setADId(val)
    },
    setFromMiniModel(state, val) {
      state.fromMiniModel = val
    },
    ResetADCatsInUnivers(state) {
      state.aAdDoc.setADCatsInUnivers(undefined)
    },
    setAdDocUsingAdDocObject(state, val: {addedDoc: AdDocumentProjectObject | undefined}) {
      state.aAdDoc = (val.addedDoc === undefined) ? new AdDocumentProjectObject() : val.addedDoc
    },
    setEmptyDocState(state, val: boolean | undefined) {
      state.emptyDocState[state.aAdDoc.getADId()] = val
    },
    setAdDoc(state, val: AdDocumentProjectTypes.AdDocument | undefined) {
      state.aAdDoc = (val === undefined) ? new AdDocumentProjectObject() /*val*/ : new AdDocumentProjectObject(val)
    },
    setNewAdDocumentLatLng(state, options: {latLng: {
      lat: number;
      lng: number;
      };
    }) {
      if (options.latLng !== undefined) {
        state.newAdDocumentLatLng = {
          ...options.latLng
        }
      } else {
        state.newAdDocumentLatLng = undefined
      }
    },
    /*
    setLikesInAdDocs(state, newData) {
      setInAdDocsAndCurrentDoc(state, newData, (cDoc: AdDocumentProjectObject, newData: any) => {
        cDoc.setADTotLikes(newData.newLikes)
        return cDoc.deepCopyProjectDoc()
      })
    },*/

    setLikesArrayInAdDocs(state, newData) {
      setInAdDocsAndCurrentDoc(state, newData, (cDoc: AdDocumentProjectObject, newData: any) => {
        cDoc.setADColLikes(newData.aADTotLikes)
        cDoc.setLikesIsLoading(newData.likesIsLoading)
        return cDoc.deepCopyProjectDoc()
      })
    },

    setCommentsArrayInAdDocs(state, newData) {
      setInAdDocsAndCurrentDoc(state, newData, (cDoc: AdDocumentProjectObject, newData: any) => {
        cDoc.setADColComments(newData.aADColComments)
        cDoc.setCommentsIsLoading(newData.commentsIsLoading)
        return cDoc.deepCopyProjectDoc()
      })
    },
/*
    addCommentInArray(state, newData) {
      setInAdDocsAndCurrentDoc(state, newData, (cDoc: AdDocumentProjectObject, newData: any) => {
        cDoc.addNewComment(newData.newAddedComment)
        return cDoc.deepCopyProjectDoc()
      })
    },

    setCommentsTotalInAdDocs(state, newData) {
      setInAdDocsAndCurrentDoc(state, newData, (cDoc: AdDocumentProjectObject, newData: any) => {
        cDoc.setADTotComments(newData.newComments)
        return cDoc.deepCopyProjectDoc()
      })
    },
*/
    setInvitationsArrayInAdDocs(state, newData) {
      setInAdDocsAndCurrentDoc(state, newData, (cDoc: AdDocumentProjectObject, newData: any) => {
        cDoc.setADColInvitations(newData.aADColInvitations)
        cDoc.setInvitationsIsLoading(newData.invitationsIsLoading)
        return cDoc.deepCopyProjectDoc()
      })
    },
    setPodiumArrayInAdDocs(state, newData) {
      setInAdDocsAndCurrentDoc(state, newData, (cDoc: AdDocumentProjectObject, newData: any) => {
        // console.error("newData.aADColPodiums " + JSON.stringify(newData.aADColPodiums))
        cDoc.setADColPodiums(newData.aADColPodiums)
        return cDoc.deepCopyProjectDoc()
      })
    },
    setTransactionsArrayInAdDocs(state, newData) {
      setInAdDocsAndCurrentDoc(state, newData, (cDoc: AdDocumentProjectObject, newData: any) => {
        // console.error("newData.aADColTransactions " + JSON.stringify(newData.aADColTransactions))
        cDoc.setADColTransactions(newData.aADColTransactions)
        cDoc.setTransactionsIsLoading(newData.transactionsIsLoading)
        return cDoc.deepCopyProjectDoc()
      })
    },
    addInvitation(state, newData) {
      setInAdDocsAndCurrentDoc(state, newData, (cDoc: AdDocumentProjectObject, newData: any) => {
        cDoc.replaceOrAddInvitation(newData.addedInvitation)
        return cDoc.deepCopyProjectDoc()
      })
    },
    addTransaction(state, newData) {
      setInAdDocsAndCurrentDoc(state, newData, (cDoc: AdDocumentProjectObject, newData: any) => {
        cDoc.replaceOrAddTransaction(newData.addedTransaction)
        return cDoc.deepCopyProjectDoc()
      })
    },
    updateTransaction(state, newData) {
      setInAdDocsAndCurrentDoc(state, newData, (cDoc: AdDocumentProjectObject, newData: any) => {
        cDoc.updateTransaction(newData.updatedTransaction)
        return cDoc.deepCopyProjectDoc()
      })
    },
    updatePodium(state, newData) {
      setInAdDocsAndCurrentDoc(state, newData, (cDoc: AdDocumentProjectObject, newData: any) => {
        cDoc.updatePodium(newData.updatedPodium)
        return cDoc.deepCopyProjectDoc()
      })
    },
    removeInvitation(state, newData) {
      setInAdDocsAndCurrentDoc(state, newData, (cDoc: AdDocumentProjectObject, newData: any) => {
        cDoc.removeInvitation(newData.removedInvitationId)
        return cDoc.deepCopyProjectDoc()
      })
    },
    removeTransaction(state, newData) {
      setInAdDocsAndCurrentDoc(state, newData, (cDoc: AdDocumentProjectObject, newData: any) => {
        cDoc.removeTransaction(newData.removedTransactionId)
        return cDoc.deepCopyProjectDoc()
      })
    },
    removeOneAdDocInAdDocs(state, options: {
      appStore: Store<AppState>; 
      removedAdDoc: AdDocumentProjectObject;
      cleanUpEmptyDoc: boolean;
    }) {
      if (state.AdDocsArray && options.removedAdDoc) {
        state.AdDocsArray = state.AdDocsArray.reduce((accumulator:  Array<AdDocumentProjectObject>, currentValue: AdDocumentProjectObject) => {
          if (currentValue.isADId(options.removedAdDoc.getADId()) === false) {
              accumulator.push(currentValue)
          } else {
            console.error("removedAdDoc.getADId FOUND in removeOneAdDocInAdDocs " + options.removedAdDoc.getADId())
          }
          return accumulator
        },  Array<AdDocumentProjectObject>())
      }
      if (options.cleanUpEmptyDoc !== true) {
        options.appStore.commit("setNotifMsg", { notifMsg: "adDocRemoved"}); //"Annonce supprimée" });
      }
    },
    updateOneAdDocInAdDocs(state, newData) {
      // console.error("updateOneAdDocInAdDocs")
      if (state.AdDocsArray && newData.updatedDoc !== undefined) {
        state.AdDocsArray = state.AdDocsArray.reduce((accumulator:  Array<AdDocumentProjectObject>, currentValue: AdDocumentProjectObject) => {
          if (currentValue.isADId(newData.updatedDoc.getADId()) === false) {
              accumulator.push(currentValue)
          } else {
            console.error("updatedDoc.ADId FOUND in updateOneAdDocInAdDocs " + newData.updatedDoc.getADId())
            accumulator.push(newData.updatedDoc)
          }
          return accumulator
        },  Array<AdDocumentProjectObject>())
      }
    },

    addNewAdDocs(state, val) {
      state.AdDocsArray = state.AdDocsArray.concat(val)
    },
  },
  getters: {
    // ...
    isEmptyDoc(state) {
      // console.error("isEmptyDoc " + state.aAdDoc.getADId() + "   ____ " + state.emptyDocState[state.aAdDoc.getADId()])
      if (state.aAdDoc.isDefinedADId() !== true) {
        return false
      }
      return (state.emptyDocState[state.aAdDoc.getADId()] === undefined) ? false : state.emptyDocState[state.aAdDoc.getADId()]
    },
    isEditing: (state) => {
      const bRet = (state.aAdDoc.isEditing())
      return bRet
    },

    isLocationRequired: (state) => {
      if (state.aAdDoc.isTemplate() === true) {
        return mandatoryState.notRequired
      }
      if (state.aAdDoc.isRequiredInPublishedState("ADLocation") === false) {
        return mandatoryState.notRequired
      }
      return isRequiredUsingPublishedState(state, () => {
        return state.aAdDoc!.isDefinedLocation()
      })
    },

    isDateHourStartsOnRequired: (state) => {
      if (state.aAdDoc.isTemplate() === true ||
        state.aAdDoc.isSuggestion() === true) {
        return mandatoryState.notRequired
      }
      if (state.aAdDoc.isRequiredInPublishedState("ADDateHourStartsOn") === false) {
        return mandatoryState.notRequired
      }
      return isRequiredUsingPublishedState(state, () => {
        return state.aAdDoc!.isDefinedDateHourStartsOn()
      })
    },

    isUniversRequired: (state, getters) => {
      // Published or not, the univers is always mandatory
      // If not set the document will never be displayed due to filters
      if (getters.isUniversDefined === true) {
          return mandatoryState.requiredAndOk
        }
      return mandatoryState.requiredAndNotOk
    },

    isTitleAndDescriptionRequired: (state) => {
      if (state.aAdDoc.isRequiredInPublishedState("ADTitle") === false &&
        state.aAdDoc.isRequiredInPublishedState("ADDescription") === false) {
        return mandatoryState.notRequired
      }
      return isRequiredUsingPublishedState(state, () => {
        return state.aAdDoc!.isDefinedTitleAndDescription()
      })
    },

    canBeConvertedToVisibleSuggestion: (state) => {
      const bRet =  state.aAdDoc.isSuggestion() 
      return bRet
    },

    canBeConvertedToSuggestion: (state) => {
      const bRet = state.aAdDoc.isPublished() === false && 
      (state.aInitialDoc === undefined || 
        state.aInitialDoc.isPublished() === false)
      return bRet
    },

    canChangePublishedStatus: (state) => {
      const bRet = state.aAdDoc.isSuggestion() !== true && 
      state.aAdDoc.isTemplate() !== true && 
      (state.aInitialDoc === undefined || 
        state.aInitialDoc.isPublished() === false)
      return bRet
    },
    canBeSaved: (state, getters) => {
      const bRet = getters.isUniversDefined === true 
      return bRet
    },
    canBePublished: (state, getters) => {
      try {
        return getters.canBeSaved === true &&
          state.aAdDoc.canBePublished()
      } catch(e) {
        console.error("getCanBePublished " + e)
        return false
      }
    },
    isUniversDefined: (state, getters) => {
      try {
        return state.aAdDoc.isDefinedUnivers() 
      } catch(e) {
        console.error("isUniversDefined " + e)
        return false
      }
    },
    newAdDocumentLatLng: (state, getters) => {
      return state.newAdDocumentLatLng 
    },
  },

  actions: {
    resetAdDocState({commit}) {
      commit("setInitialDoc", undefined);
      commit("setEmptyDocState", undefined)
      commit("setAdDoc", undefined);
    },

    async createNewAdDocAtLocation({ commit }, options: { 
      // aUser: UserProjectTypes.UserProfile;
      appStore: Store<AppState>; 
      userStore: Store<UserState>;
      latLng: {
        lat: number;
        lng: number;
      };
    }) {

      commit("setNewAdDocumentLatLng", {latLng: options.latLng});
      router.push("/add")
      
    },

    async openAdDocInModal({ dispatch }, options: { 
      // aUser: UserProjectTypes.UserProfile;
      appStore: Store<AppState>; 
      aAdDoc: AdDocumentProjectObject;
      userStore: Store<UserState>;}) {

      options.appStore.commit("setInModalAdDocument", {inModalAdDocument: options.aAdDoc});
      options.appStore.commit("setShowAdDocModal", {showAdDocModal: true});
      await dispatch("viewOnMountedAdDoc", {
        aAdDoc: options.aAdDoc,
        appStore: options.appStore,
        userStore: options.userStore,
      });
      
    },

    async viewOnMountedAdDoc({ commit }, options1: { 
      // aUser: UserProjectTypes.UserProfile;
      appStore: Store<AppState>; aAdDoc: AdDocumentProjectObject; 
      userStore: Store<UserState>;}) {
      
      const viewAdDoc = async (options: {
        appStore: Store<AppState>; aAdDoc: AdDocumentProjectObject; 
        userStore: Store<UserState>;}) => {
        //console.error("viewOnMountedAdDoc")
        const aUserProfile = options.userStore.state.aUserProfile
        // console.error("viewAdDoc aUserProfile.getUPUserId() " + aUserProfile.getUPUserId())
        documStore.commit("setAdDoc", {
            ...options.aAdDoc.OADData
        });
        if (options.aAdDoc.getADId() === undefined) {
          return
        }
        documStore.dispatch("getCommentsForAdDoc", {
          ADId: options.aAdDoc.getADId(),
          appStore: options.appStore,
        });
        documStore.dispatch("getLikesForAdDoc", {
          ADId: options.aAdDoc.getADId(),
          appStore: options.appStore,
        });
        const isOwner = (options.aAdDoc.isOwner(aUserProfile.getUPUserId()) === true)
        // console.error("viewAdDoc isOwner " + isOwner)
        //const isOwner = (page.userProfile &&  aAdDoc.isOwner(page.userProfile.UPUserId) === true)
        const isAdmin = (aUserProfile.isAdmin() === true)
        //const isAdmin = (page.userProfile && (page.userProfile.UPIsAdmin === true) )
        if (isOwner === true ||isAdmin === true) {
          documStore.dispatch("getAllTransactionsForAdDoc", {
            ADId: options.aAdDoc.getADId(),
            appStore: options.appStore,
          });
          documStore.dispatch("getAllInvitationsForAdDoc", {
            ADId: options.aAdDoc.getADId(),
            appStore: options.appStore,
          });
        } else {
          documStore.dispatch("getAllTransactionsForAdDoc", {
            ADId: options.aAdDoc.getADId(),
            appStore: options.appStore,
            onlyPodium: true,
          });

          documStore.dispatch("getMyTransactionsForAdDoc", {
            ADId: options.aAdDoc.getADId(),
            ADOwnerId: options.aAdDoc.getOwnerUserId(),
            appStore: options.appStore,
            // aUserProfile: aUserProfile,
            userStore: options.userStore,
          });
        }        
      }

        await blockApplicationUI(options1, async () => {

          const resultFor = await viewAdDoc(options1)
          /*
          const rStoreProcess = [ {
            processInputResult: resultFor.rForRemoveCommentFromAdDoc,
            unknownErrorMsg: "Unknown error during remove comment process "
          }]
          await applyToStoreDomainResults( {
            appStore: options.appStore,
          }, rStoreProcess);
    */
        })
  

    },

    async getMyTransactionsForAdDoc({ commit }, { ADId, ADOwnerId, appStore
      , userStore /*, aUserProfile*/ }) {
      //  , userProfile }) {
      const aUserProfile = userStore.state.aUserProfile
      commit("setTransactionsArrayInAdDocs", {ADId: ADId, aADColTransactions: [], transactionsIsLoading: true})

      const result = await adDocumentTransactionsControler.getMyTransactionsForAdDoc(ADId, ADOwnerId,
            aUserProfile.getUPUserId() )
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'setTransactionsArrayInAdDocs',
        validObj: {
          ADId: ADId,
          aADColTransactions: result.aADColTransactions, 
          transactionsIsLoading: false,
        },
        unknownErrorMsg: "Unknown error during getMyTransactionsForAdDoc For AdDoc  process"
      })
    },

    async getAllInvitationsForAdDoc({ commit }, { ADId, appStore }) {
      // console.error("getAllInvitationsForAdDoc")
      commit("setInvitationsArrayInAdDocs", {ADId: ADId, aADColInvitations: [], invitationsIsLoading: true})
      const result = await adDocumentInvitationsControler.getAllInvitationsForAdDoc({ ADId })
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'setInvitationsArrayInAdDocs',
        validObj: {
          ADId: ADId,
          aADColInvitations: result.aADColInvitations, 
          invitationsIsLoading: false,
        },
        unknownErrorMsg: "Unknown error during getAllInvitationsForAdDoc For AdDoc  process"
      })
    },

    async getAllTransactionsForAdDoc({ commit }, { ADId, appStore, onlyPodium }) {
      // console.error("getAllTransactionsForAdDoc")
      commit("setTransactionsArrayInAdDocs", {ADId: ADId, aADColTransactions: [], transactionsIsLoading: true})
      //const result = await adDocFirebase.getAllTransactionsForAdDoc({ ADId })
      const result = await adDocumentTransactionsControler.getAllTransactionsForAdDoc({ ADId })
      if (onlyPodium !== true) {
        saveDomainResultsInStores(appStore, commit, result, {
          processTag: 'setTransactionsArrayInAdDocs',
          validObj: {
            ADId: ADId,
            aADColTransactions: result.aADColTransactions, 
            transactionsIsLoading: false,
          },
          unknownErrorMsg: "Unknown error during getAllTransactionsForAdDoc For AdDoc  process"
        })
      }
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'setPodiumArrayInAdDocs',
        validObj: {
          ADId: ADId,
          aADColPodiums: result.aADColTransactions.filter((tr: any) => {
            return (tr.ATStatus === "A" || tr.ATStatus === "F")
          }), 
          transactionsIsLoading: false,
        },
        unknownErrorMsg: "Unknown error during getAllTransactionsForAdDoc For AdDoc  process"
      })

    },

    async getLikesForAdDoc({ commit }, { ADId, appStore }) {
      commit("setLikesArrayInAdDocs", {ADId: ADId, aADTotLikes: [], likesIsLoading: true})
      const result = await adDocFirebase.getLikesForAdDoc({ ADId })
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'setLikesArrayInAdDocs',
        validObj: {
          ADId: ADId,
          aADTotLikes: result.aADTotLikes,
          likesIsLoading: false,
        },
        unknownErrorMsg: "Unknown error during get Likes For AdDoc  process"
      })
    },
    async getCommentsForAdDoc({ commit }, { ADId, appStore }) {
      commit("setCommentsArrayInAdDocs", {ADId: ADId, aADColComments: [], commentsIsLoading: true})
      const result = await adDocFirebase.getCommentsForAdDoc({ ADId })
      const validObj = {
        ADId: ADId,
        aADColComments: result.aADColComments,
        commentsIsLoading: false,
      }
      // console.error("validObj :" + JSON.stringify(validObj))
      // console.error("ADId :" + ADId)
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'setCommentsArrayInAdDocs',
        validObj,
        unknownErrorMsg: "Unknown error during get Comments For AdDoc  process"
      })
    },

    async editAdDocFromUser(actionContext: ActionContext<DocumState, DocumState>, options: editAdDocOptionsType) {
      console.error("editAdDoc")
      if (options.aAdDoc === undefined) {
        return
      }
      // options.appStore.commit("setInModalAdDocument", {inModalAdDocument: new AdDocumentProjectObject()});
      // options.appStore.commit("setShowAdDocModal", {showAdDocModal: false});
      options.appStore.dispatch("closeAllModal");
      
      router.push('/edit' + (options.aAdDoc.isSuggestion() ? 'sug' : (options.aAdDoc.isTemplate() ? 'tem' : '')) + '/' + options.aAdDoc.getADId())
    },

    async removeAdDocFromUser(actionContext: ActionContext<DocumState, DocumState>, options: removeAdDocOptionsType) {
      options.appStore.commit("setInModalAdDocument", {inModalAdDocument: new AdDocumentProjectObject()});
      options.appStore.commit("setShowAdDocModal", {showAdDocModal: false});
      //options.appStore.dispatch("closeAllModal");
      await documStore.dispatch("removeAdDoc", options);
      options.filterAttributesStore.dispatch("goToDashboardWithLastFilters")
      //router.push('/')
    },

    async removeAdDoc(actionContext: ActionContext<DocumState, DocumState>, options: removeAdDocOptionsType) {
        // { aAdDoc, aUser, appStore, userStore, cleanUpEmptyDoc }) {
      const aUserProfile = options.userStore.state.aUserProfile
      // const aUser = options.userStore.state.userProfile
      const aAdDoc = actionContext.state.aAdDoc
      if (aUserProfile.isCurrentLoggedUser() === false) {
      //  if (aUser === undefined) {
          return
      }
      await blockApplicationUI(options, async () => {
        const resultFor = await adDocumentControler.removeAdDoc({
          aAdDoc, aUserId: aUserProfile.getUPUserId(), 
          // aAdDoc, aUser, 
          cleanUpEmptyDoc: options.cleanUpEmptyDoc})
        const rStoreProcess = [{
          processInputResult: resultFor.rForRemoveOneAdDocInAdDocs,
          processAction: actionContext.commit,
          processActionTag: 'removeOneAdDocInAdDocs',
          validObj: {
            removedAdDoc: aAdDoc,
            cleanUpEmptyDoc: options.cleanUpEmptyDoc,
          },
          unknownErrorMsg: "Unknown error during remove AdDoc process - rForRemoveOneAdDocInAdDocs"
        }, {
          processInputResult: resultFor.rForCancelCalendarEvent,
          unknownErrorMsg: "Unknown error during remove AdDoc process - rForCancelCalendarEvent"
        }]
        await applyToStoreDomainResults( {
          appStore: options.appStore,
        }, rStoreProcess);
        actionContext.dispatch("resetAdDocState")

      })
    },
    async setAdDocs({ commit }, newData) {
      commit('setAdDocs', newData.AdDocsArray)
    },
    async getZipcodes({ state, commit }, { appStore, aZipcode }) {
        //console.error("getZipcodes " + aZipcode)
        const result = await adDocFirebase.getZipcodes(aZipcode)
        saveDomainResultsInStores(appStore, commit, result, {
          processTag: '',
          validObj: {
          }, unknownErrorMsg: "Unknown error during getZipcodes process"
        })
        //console.error("getZipcodes result.zipcodes " + JSON.stringify(result.zipcodes))
        return result.zipcodes
    },

    async removeCommentFromAdDoc({ state }, options: {
      aAdDoc: AdDocumentProjectObject;
      commentId: string;
      aUserId: string;
      //aUser: UserProjectTypes.UserProfile;
      appStore: Store<AppState>;
    }) {
      const res = await blockApplicationUI(options, async () => {
        const resultFor = await adDocumentControler.removeCommentFromAdDoc(options)
        const rStoreProcess = [ {
          processInputResult: resultFor.rForRemoveCommentFromAdDoc,
          unknownErrorMsg: "Unknown error during remove comment process "
        }]
        await applyToStoreDomainResults( {
          appStore: options.appStore,
        }, rStoreProcess);
      })

    },

    async addCommentToAdDoc({ state }, options: {
      aAdDoc: AdDocumentProjectObject;
      ACContent: string;
      aUserProfile: UserProfileProjectObject; // UserProjectTypes.UserProfile;
      appStore: Store<AppState>;
    }) {
      /*
      const result = await adDocFirebase.addCommentToAdDoc(options)
      const res = saveDomainResultsInStores(options.appStore, commit, result, {
        processTag: 'setCommentsTotalInAdDocs',
        validObj: {
          ADId: options.aAdDoc.getADId(),
          newComments: options.aAdDoc.getADTotComments() + 1
        },
        unknownErrorMsg: "Unknown error during 'Add Comment' process"
      })
      if (result && result.backendActionDone === true && result.newAddedComment) {
        commit("addCommentInArray", {
          ADId: options.aAdDoc.getADId(),
          newAddedComment: result.newAddedComment})
      }
*/
      
      const res = await blockApplicationUI(options, async () => {
        const resultFor = await adDocumentControler.addCommentToAdDoc(options)
        const rStoreProcess = [ {
          processInputResult: resultFor.rForAddCommentToAdDoc,
          unknownErrorMsg: "Unknown error during add comment process "
        }]
        await applyToStoreDomainResults( {
          appStore: options.appStore,
        }, rStoreProcess);
        return resultFor.rForAddCommentToAdDoc.backendActionDone
      })
      console.error(" res " + res)
      return res

    },
    
    async removeOneInvitationAdDoc({ commit }, { aAdDoc, 
      aInvitationId, 
      appStore }) {
      const result = await adDocumentInvitationsControler.removeOneInvitationAdDoc(aAdDoc, 
        aInvitationId)
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'removeInvitation',
        validObj: {
          ADId: aAdDoc.getADId(),
          removedInvitationId: result.removedInvitationId,
          notifMsg: "invitationRemovedNotifMsg"
        },
        unknownErrorMsg: "Unknown error during like process"
      })
    },
    
    async removeMyTransactionAdDoc({ commit }, { aAdDoc, 
      //aUser, 
      appStore, userStore }) {
      const aUserProfile = userStore.state.aUserProfile as UserProfileProjectObject
      const aUserId = aUserProfile.getUPUserId()
      const resultRemovedAnAcceptedTransaction = (result: any) => {
        return result && result.removedTransactionId !== undefined &&
        result.removedTransactionData !== undefined &&
        result.removedTransactionData.ATStatus === 'A'
      }
      const resultRemovedATransactionAlreadyInMyAgenda = (result: any) => {
        return result && result.removedTransactionId !== undefined &&
        result.removedTransactionData !== undefined &&
        result.removedTransactionData.ATStatus !== 'P' &&
        result.removedTransactionData.ATStatus !== 'R'
      }
      const result = await adDocumentTransactionsControler.removeMyTransactionAdDoc(aAdDoc, 
        aUserId)
      //  aUser)
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'removeTransaction',
        validObj: {
          ADId: aAdDoc.getADId(),
          removedTransactionId: result.removedTransactionId,
          notifMsg: "transactionRemovedNotifMsg"
        },
        unknownErrorMsg: "Unknown error during like process"
      })
      if (resultRemovedAnAcceptedTransaction(result)) {
          await userStore.dispatch("addNotification", {
            aUNOwnerUserId: aAdDoc.OADData.ADOwnerMiniProfile.UOUserId,
            aAdDoc: aAdDoc,
            notifType: "TC", 
            appStore: appStore,
          });
        }
      if (resultRemovedATransactionAlreadyInMyAgenda(result)) {
        await userStore.dispatch("removeCalendarEvent", {
          aUNOwnerUserId: aUserId,  // The current user
          // aUNOwnerUserId: aUser.UPUserId,  // The current user
          aAdDoc: aAdDoc, 
          eventType: "??", // Not the correct value, but not used?
          appStore: appStore,
        });
      }
    },

    async updateTransactionAdDoc({ commit }, { updateParams, 
      // aUser, 
      appStore, userStore }) {
      const aUserProfile = userStore.state.aUserProfile as UserProfileProjectObject
      const result = await adDocumentTransactionsControler.updateTransactionAdDoc(updateParams, 
        aUserProfile.getUPUserId())
        // aUser)
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'updateTransaction',
        validObj: {
          ADId: updateParams.aAdDoc.getADId(),
          updatedTransaction: result.updatedTransaction,
          notifMsg: (result.updatedTransaction.ATStatus === "A") ?
            "transactionUpdatedToAcceptedNotifMsg" : "transactionUpdatedToRefusedNotifMsg"
        },
        unknownErrorMsg: "Unknown error during like process"
      })
      if (updateParams.newStatus === "A" && result.updatedTransaction !== undefined &&
        result.updatedTransaction.ATStatus === "A" && 
        aUserProfile.isCurrentLoggedUser() === true) {
        //  aUser !== undefined) {
            await userStore.dispatch("addMutualContacts", {
            ATMUser: updateParams.aTransaction.ATMUser,
            UPMUser: aUserProfile.getMiniProfileForContact(),
            appStore: appStore,
          });
      }
      /*
      For newStatus = "A" or "R"
        ATMUser: updateParams.aTransaction.ATMUser,  // The user asking to participate
        UPMUser: getMiniProfile(aUser), // The owner of the AdDoc

      */
      if ((updateParams.newStatus === "A" || updateParams.newStatus === "R") && result.updatedTransaction !== undefined &&
        result.updatedTransaction.ATStatus === updateParams.newStatus && 
        aUserProfile.isCurrentLoggedUser() === true) {
        //  aUser !== undefined) {
            await userStore.dispatch("addNotification", {
          aUNOwnerUserId: updateParams.aTransaction.ATMUser.UMUserId,  // The user asking to participate
          aAdDoc: updateParams.aAdDoc,
          notifType: (updateParams.newStatus === "A") ? "TA" : "TR", 
          appStore: appStore,
        });
      }
      if ((updateParams.newStatus === "A" ) && result.updatedTransaction !== undefined &&
        result.updatedTransaction.ATStatus === updateParams.newStatus && 
        aUserProfile.isCurrentLoggedUser() === true) {
        //  aUser !== undefined) {
            await userStore.dispatch("addCalendarEvent", {
          aUNOwnerUserId: updateParams.aTransaction.ATMUser.UMUserId,  // The user asking to participate
          aAdDoc: updateParams.aAdDoc,
          eventType: "EP", 
          appStore: appStore,
        });
      }
      return result
    },
    async updateFootStepCounterInTransactionAdDoc({ commit }, { updateParams, 
      appStore, userStore }) {
      const aUserProfile = userStore.state.aUserProfile as UserProfileProjectObject
      const result = await adDocumentTransactionsControler.updateFootStepCounterInTransactionAdDoc(updateParams, 
        aUserProfile.getUPUserId())
        // aUser)
        console.error('saveDomainResultsInStores 1')
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'updateTransaction',
        validObj: {
          ADId: updateParams.aAdDoc.getADId(),
          updatedTransaction: result.updatedTransaction,
          notifMsg: "transactionFootStepUpdatedMsg"
        },
        unknownErrorMsg: "Unknown error during update foot steps process"
      })
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'updatePodium',
        validObj: {
          ADId: updateParams.aAdDoc.getADId(),
          updatedPodium: result.updatedTransaction,
        },
        unknownErrorMsg: "Unknown error during getAllTransactionsForAdDoc For AdDoc  process"
      })

      console.error('saveDomainResultsInStores 2')

      return result
    },

    async ownerAddInvitationAdDoc({  commit }, { aAdDoc, 
      // aUser, 
      appStore, 
      userStore, 
      addInvitationText,
      anInvitedUserProfile }) {
      let result
      const msg = userStore.state.aUserProfile.uncompleteProfilMessage(false)
      if (msg !== '' ) {
          result = {
            backendActionDone: false,
            addedInvitation: undefined,
            errorMsg: msg
        }
      } else {
        result = await adDocumentInvitationsControler.ownerAddInvitationAdDoc(aAdDoc, 
          anInvitedUserProfile.getMiniProfileForTransaction(), 
          addInvitationText)
      }
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'addInvitation',
        validObj: {
          ADId: aAdDoc.getADId(),
          addedInvitation: result.addedInvitation,
          notifMsg: "invitationAddedNotifMsg"
        },
        unknownErrorMsg: "Unknown error during ownerAddInvitationAdDoc process "
      })
    },

    async addTransactionAdDoc({  commit }, { aAdDoc, 
      // aUser, 
      appStore, 
      userStore, 
      addTransactionText }) {
      let result
      const msg = userStore.state.aUserProfile.uncompleteProfilMessage(false)
      if (msg !== '' ) {
          result = {
            backendActionDone: false,
            addedTransaction: undefined,
            errorMsg: msg
        }
      } else {
        result = await adDocumentTransactionsControler.addTransactionAdDoc(aAdDoc, 
          userStore.state.aUserProfile.getMiniProfileForTransaction(), 
          addTransactionText)
      }
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'addTransaction',
        validObj: {
          ADId: aAdDoc.getADId(),
          addedTransaction: result.addedTransaction,
          notifMsg: (result.addedTransaction && result.addedTransaction.ATStatus === 'F') ? 
            "transactionAddedFreeSubscriptionNotifMsg" :  "transactionAddedNotifMsg"
        },
        unknownErrorMsg: "Unknown error during addTransactionAdDoc process"
      })
      if (result && result.addedTransaction !== undefined &&
        result.addedTransaction.ATStatus === 'P') {
        await userStore.dispatch("addNotification", {
          aUNOwnerUserId: aAdDoc.OADData.ADOwnerMiniProfile.UOUserId,  // The owner of the adDoc
          aAdDoc: aAdDoc, 
          notifType: "TP", 
          appStore: appStore,
        });
      } else if (result && result.addedTransaction !== undefined &&
        result.addedTransaction.ATStatus === 'F') {
        await userStore.dispatch("addNotification", {
          aUNOwnerUserId: aAdDoc.OADData.ADOwnerMiniProfile.UOUserId,  // The owner of the adDoc
          aAdDoc: aAdDoc, 
          notifType: "TF", 
          appStore: appStore,
        });
        await userStore.dispatch("addCalendarEvent", {
          aUNOwnerUserId: userStore.state.aUserProfile.getUPUserId(),  // The current user
          // aUNOwnerUserId: aUser.UPUserId,  // The current user
          aAdDoc: aAdDoc, 
          eventType: "EF", 
          appStore: appStore,
        });

      }
    },

    async likeAdDoc({state}, options: {
      aUserProfile: UserProfileProjectObject;
      // aUser: UserProjectTypes.UserProfile;
      appStore: Store<AppState>; aAdDoc: AdDocumentProjectObject;}
      ) {

      await blockApplicationUI(options, async () => {
        const resultFor = await adDocumentControler.likeAdDoc(options)
        const rStoreProcess = [ {
          processInputResult: resultFor.rForLikeAdDoc,
          unknownErrorMsg: "Unknown error during like process "
        }]
        await applyToStoreDomainResults( {
          appStore: options.appStore,
        }, rStoreProcess);

      })
    },
    async cleanUpEmptyDoc({ state, dispatch }, options: {
      /*aUser: UserProjectTypes.UserProfile | undefined;*/
      userStore: Store<UserState>;
      appStore: Store<AppState>;
      filterAttributesStore: Store<FilterAttributesState>;
    }) {
      // console.error("cleanUpEmptyDoc 1")
      // console.error("cleanUpEmptyDoc 2 " + JSON.stringify(state.aAdDoc))
      if (documStore.getters.isEmptyDoc === true) {
          
      await dispatch("removeAdDoc", {
          userStore: options.userStore,
          appStore: options.appStore,
          filterAttributesStore: options.filterAttributesStore,
          cleanUpEmptyDoc: true
        } as  removeAdDocOptionsType )
      }
    },

    async saveAdDoc({ state, commit, dispatch }, options: {
      userStore: Store<UserState>;
      appStore: Store<AppState>;
      filterAttributesStore: Store<FilterAttributesState>;
    }) {
      if (state.aAdDoc === undefined || 
        state.aAdDoc.isDefinedUnivers() !== true) {
        return
      }
      const aUserProfile =  options.userStore.state.aUserProfile

      await blockApplicationUI(options, async () => {
        //console.error("saveAdDoc state.fromMiniModel " + state.fromMiniModel)

        const resultFor = await adDocumentControler.saveAdDoc({
          aUserProfile, 
          fromMiniModel: state.fromMiniModel,
          aAdDoc: state.aAdDoc, fullLocale: fullLocale,
          aInitialDoc: state.aInitialDoc  })
        const  rStoreProcess = [
          {
            processInputResult: resultFor.rForCreateANewAdDoc?.rForSetAdDocUsingAdDocObject,
            processAction: commit,
            processActionTag: 'setAdDocUsingAdDocObject',
            validObj: {
              notifMsg: "adDocSaved" 
            },
            unknownErrorMsg: "Unknown error during createANewDoc process - rForSetAdDocUsingAdDocObject"
          }, {
            processInputResult: resultFor.rForCreateANewAdDoc?.rForAddCalendarEvent,
            unknownErrorMsg: "Unknown error during createANewDoc process - rForAddCalendarEvent"
          },{
            processInputResult: resultFor.rForUpdateExistingAdDocUsingDocIdOrCache?.rForUpdateOneAdDocInAdDocs,
            processAction: commit,
            processActionTag: 'updateOneAdDocInAdDocs',
            validObj: {
              notifMsg: (state.aInitialDoc !== undefined) ? "adDocUpdated" : "adDocAdded" 
            },
            unknownErrorMsg: "Unknown error during updateExistingAdDocUsingDocIdOrCache process"
          }, {
            processInputResult: resultFor.resultForDetectChangesInDoc?.rForDetectChangesInDocAndNotifyTransactionsOwners,
            unknownErrorMsg: "Unknown error during resultForDetectChangesInDoc process - rForDetectChangesInDocAndNotifyTransactionsOwners"
          }, {
          processInputResult: resultFor.resultForDetectChangesInDoc?.rForDetectDocOwnerCalendarChanges,
          unknownErrorMsg: "Unknown error during resultForDetectChangesInDoc process - rForDetectDocOwnerCalendarChanges"
          }
        ]

        await applyToStoreDomainResults( {
          appStore: options.appStore,
        }, rStoreProcess);
      

      })
      documStore.dispatch("resetAdDocState")
      options.filterAttributesStore.dispatch("goToDashboardWithLastFilters")
      // router.push({ name: "Dashboard" });

    },

    async createANewDoc(actionContext, options: {
      aUserProfile: UserProfileProjectObject;
      //aUser: UserProjectTypes.UserProfile;
      appStore: Store<AppState>;
      notifMsg: string;
      cacheCreatedDoc: boolean;
    }) {
      await blockApplicationUI(options, async () => {
        const resultFor = await adDocumentControler.createANewAdDoc({
          aUserProfile: options.aUserProfile, 
          // aUser: options.aUser, 
          cleanUpEmptyDoc: options.cacheCreatedDoc,
          aAdDoc: actionContext.state.aAdDoc, 
          fullLocale: fullLocale
        })
        const rStoreProcess = [{
          processInputResult: resultFor.rForSetAdDocUsingAdDocObject,
          processAction: actionContext.commit,
          processActionTag: 'setAdDocUsingAdDocObject',
          validObj: {
            notifMsg: options.notifMsg 
          },
          unknownErrorMsg: "Unknown error during createANewDoc process - rForSetAdDocUsingAdDocObject"
        }, {
          processInputResult: resultFor.rForAddCalendarEvent,
          unknownErrorMsg: "Unknown error during createANewDoc process - rForAddCalendarEvent"
        }]
        await applyToStoreDomainResults( {
          appStore: options.appStore,
        }, rStoreProcess);
      })
      actionContext.commit("setEmptyDocState", true)
    },

    async getAdDocumentUsingId({ state, commit }, param: {docId: string;
      shouldBeAdDocFileType: string;
      appStore: Store<AppState>; userStore: Store<UserState>;
      cancelCalendarEventForAlreadyRemovedDoc: boolean;}) {
      let foundDoc: any
      if (state.aAdDoc && 
        state.aAdDoc.isADId(param.docId) === true) {
        return state.aAdDoc
      }
      state.aAdDoc = new AdDocumentProjectObject() //undefined
      const queryResult = await adDocFirebase.getAdDocumentUsingId(param.docId, param.shouldBeAdDocFileType)
      if (queryResult.deconnected === true) {
        commit('setAdDocs', [])
        param.userStore.dispatch("logout", {appStore: param.appStore})
        foundDoc = undefined
      } else {
        if (queryResult.aAdDoc && queryResult.aAdDoc.getADId() === undefined) {
          // Important to set the docID here, in order to be able to update the doc in saveAdDoc
          queryResult.aAdDoc.setADId(param.docId)
        }
        saveDomainResultsInStores(param.appStore, commit, queryResult, {
          processTag: 'setAdDoc',
          validObj: {
            ...queryResult.aAdDoc
          },
          unknownErrorMsg: "Unknown error during getAdDocumentUsingId process"
        })
        foundDoc = queryResult.aAdDoc
      }

      if (foundDoc === undefined && 
          param.cancelCalendarEventForAlreadyRemovedDoc === true) {
        const aUserProfile =  param.userStore.state.aUserProfile
        if (aUserProfile.isCurrentLoggedUser() === true) {
          adDocumentControler.cancelCalendarEventForAlreadyRemovedDoc({
            aAdDocId: param.docId, 
            aUserId: aUserProfile.getUPUserId()
          })
        }
      }

      return foundDoc
    },

    async querySuggestionAdDoc({ state, commit }, param: {optionsQuery: any;
      appStore: Store<AppState>; userStore: Store<UserState>;}) {
      const queryResult = await adDocFirebase.queryAdDoc(param.optionsQuery)
      saveDomainResultsInStores(param.appStore, commit, queryResult, {
        processTag: 'setSuggestionAdDocs',
        validObj: queryResult.OADDataArrayFromBackend,
        unknownErrorMsg: "Unknown error during querySuggestionAdDoc process"
      })
    },

    async queryTemplateAdDoc({ state, commit }, param: {optionsQuery: any;
      appStore: Store<AppState>; userStore: Store<UserState>;}) {
      const queryResult = await adDocFirebase.queryAdDoc(param.optionsQuery)
      saveDomainResultsInStores(param.appStore, commit, queryResult, {
        processTag: 'setModelAdDocs',
        validObj: queryResult.OADDataArrayFromBackend,
        unknownErrorMsg: "Unknown error during queryTemplateAdDoc process"
      })
    },

    async queryProposedModelsAdDoc({ state, commit }, param: {optionsQuery: any;
      appStore: Store<AppState>; userStore: Store<UserState>;}) {
        commit("setModelAdDocs", [])
        //const {defaultUnivers, defaultCatInUnivers} = userStore.getters.getDefaultUniversAndCatsInUnivers
        const strategiesToFindBestModels = getStrategiesToFindBestModels(param.userStore.getters.getDefaultUniversAndCatsInUnivers, 
          param.optionsQuery)
        let currentStrategy = 0
        let backendActionDone = true
        while(currentStrategy < strategiesToFindBestModels.length && 
          state.modelAdDocsArray.length < 3 &&
          backendActionDone === true) {

          const queryResultMyModels = await adDocFirebase.queryAdDoc(strategiesToFindBestModels[currentStrategy])
          backendActionDone = queryResultMyModels.backendActionDone
          saveDomainResultsInStores(param.appStore, commit, queryResultMyModels, {
            processTag: 'addNewModelAdDocs',
            validObj: {
              newModelAdDocsArray: queryResultMyModels.OADDataArrayFromBackend,
              currentStrategy: (currentStrategy + 1) + "/" + strategiesToFindBestModels.length
            },
            unknownErrorMsg: "Unknown error during queryTemplateAdDoc queryResultMyModels process"
          })
          currentStrategy++;
        }
        /*
        currentFilter: page.currentFilterAttributes.filterSelected,
        universAndCatFilter: universAndCatFilter,
        universAndCatFilter:{"ADUnivers":["uniSport"]},
         {"ADUnivers":["uniTravel"],"ADCatsInUnivers":["SportTravel"]}
        */
  
    },

    async queryProposedModelsAdDocOLD({ state, commit }, param: {optionsQuery: any;
      appStore: Store<AppState>; userStore: Store<UserState>;}) {
        /*
      param.optionsQuery.templateFilter = true
      param.optionsQuery.currentFilter = "myAdDocs"
      param.optionsQuery.adDocFileType = "T"
        */
      const queryResultMyModels = await adDocFirebase.queryAdDoc({
        ...param.optionsQuery,
        currentFilter: "myAdDocs",
        adDocFileType: "T",
      })
      if (queryResultMyModels.backendActionDone === false) {
        // Error stop now
        saveDomainResultsInStores(param.appStore, commit, queryResultMyModels, {
          processTag: 'setModelAdDocs',
          validObj: queryResultMyModels.OADDataArrayFromBackend,
          unknownErrorMsg: "Unknown error during queryTemplateAdDoc queryResultMyModels process"
        })
      } else {
        if (queryResultMyModels.OADDataArrayFromBackend.length < 3) {
          // need more models, add ideas models
          const queryResultModelsIdeas = await adDocFirebase.queryAdDoc({
            ...param.optionsQuery,
            "templateFilter": true,
          })
          const contains = (a: any[], obj: any) => {
            let i = a.length;
            while (i--) {
               if (a[i].OADData.ADId === obj.OADData.ADId) {
                   return true;
               }
            }
            return false;
          }
          const mergedData = [...queryResultMyModels.OADDataArrayFromBackend, 
            ...(queryResultModelsIdeas.OADDataArrayFromBackend.filter(v => !contains(queryResultMyModels.OADDataArrayFromBackend, v)))];
          //const mergedData = Array.from(new Set(queryResultMyModels.OADDataArrayFromBackend.concat(queryResultModelsIdeas.OADDataArrayFromBackend)))
          //const mergedData = mergeUnique(queryResultMyModels.OADDataArrayFromBackend, queryResultModelsIdeas.OADDataArrayFromBackend)
          saveDomainResultsInStores(param.appStore, commit, queryResultModelsIdeas, {
            processTag: 'setModelAdDocs',
            validObj: mergedData,
            unknownErrorMsg: "Unknown error during queryTemplateAdDoc queryResultModelsIdeas process"
          })
        } else {
          // enough data from my models, display them
          saveDomainResultsInStores(param.appStore, commit, queryResultMyModels, {
            processTag: 'setModelAdDocs',
            validObj: queryResultMyModels.OADDataArrayFromBackend,
            unknownErrorMsg: "Unknown error during queryTemplateAdDoc queryResultMyModels process"
          })
        }
      }
    },

    async queryAdDoc({ state, commit }, options: {optionsQuery: any;
       appStore: Store<AppState>; userStore: Store<UserState>;}) {
      const res = await blockApplicationUI(options, async () => {
        const queryLocalAdDocLimit = options.appStore.state.aAppServerConfig.getQueryLocalAdDocLimit()
        options.optionsQuery["queryLocalAdDocLimit"] = queryLocalAdDocLimit
        const queryResult = await adDocFirebase.queryAdDoc(options.optionsQuery)
        const rStoreProcess = [ {
          processInputResult: queryResult,
          unknownErrorMsg: "Unknown error during queryAdDoc process "
        }]
        if (queryResult.deconnected === true) {
          commit('setAdDocs', [])
          options.userStore.dispatch("logout", {appStore: options.appStore})
        } else {
          commit('setMoreElementsInCollection', queryResult.moreElementsInCollection)
          commit('setLimitMaxReachedInCollection', queryResult.limitMaxReached)
          if (queryResult.OADDataArrayFromBackend.length === 0) {
            commit('setMoreElementsInCollection', false)
            await applyToStoreDomainResults( {
              appStore: options.appStore,
            }, rStoreProcess);
            return queryResult.backendActionDone
          }

          await saveDomainResultsInStores(options.appStore, commit, queryResult, {
            processTag: 'addNewAdDocs',
            validObj: queryResult.OADDataArrayFromBackend, //AdDocumentProjectObject.convertOADDataArrayFromBackend(queryResult.OADDataArrayFromBackend),
            unknownErrorMsg: "Unknown error during query AdDoc process"
          })
        }
        await applyToStoreDomainResults( {
          appStore: options.appStore,
        }, rStoreProcess);
        return queryResult.backendActionDone
      })
      return res
    },

    async updateUrlOfAttachedFile({ state, commit }, newUrlAndFile: {
      imgId: string;
      url: string;
      fileName: string;
    }) {
      if (state.aAdDoc === undefined) {
        return
      }
      if (newUrlAndFile.url !== "") {
        // Add a new attached url file
        commit('addNewAttDoc', {
          AADId: newUrlAndFile.imgId,
          // AADFromSuggestion: false,
          AADDocumentUrl: newUrlAndFile.url,
          AADDocumentName: newUrlAndFile.fileName
        })
      } else {
        const getAttDocWithOut = state.aAdDoc.getAttDocWithOut(newUrlAndFile.imgId)
        if (getAttDocWithOut !== undefined) {
          commit('setADCollAttDoc', getAttDocWithOut.newColAttDoc)
          await adDocFirebase.removeOneAttachedFiles(state.aAdDoc.getADId(), newUrlAndFile.imgId)
        }
      }
    },
    

    async updateAdDocUsingSuggestion({ state, commit }, data: {
      aAdDoc: AdDocumentProjectObject;
      selectedSuggestion: string;
    }) {
      if (state.aAdDoc === undefined) {
        return
      }
      state.selectedSuggestion = data.selectedSuggestion
      console.error("state.selectedSuggestion " + state.selectedSuggestion)
      console.error("data.aAdDoc.OADData " + JSON.stringify(data.aAdDoc.OADData))
      // To avoid to change the suggestion data, make a deep copy
      const deepCopyOfSuggestionObject = (data.aAdDoc.OADData) ? JSON.parse(JSON.stringify(data.aAdDoc.OADData)) : data.aAdDoc.OADData
      deepCopyOfSuggestionObject.ADColAttDoc = mergeTwoADColAttDocRemovingDupURLs(deepCopyOfSuggestionObject?.ADColAttDoc,
        state.aAdDoc.OADData.ADColAttDoc)
      // Keep the current attached docs if any
      //data.aAdDoc.OADData.ADColAttDoc = [
      //  ...data.aAdDoc.OADData?.ADColAttDoc,
      //  ...state.aAdDoc.OADData.ADColAttDoc
      //]
      commit("setAdDoc", {
          ...state.aAdDoc.OADData,
          ...deepCopyOfSuggestionObject
          // ...data.aAdDoc.OADData
      });
    },

    async updateAdDocUsingTemplate({ state, commit }, param: {
      modelId: string;
      loggedUserProfileLocation: UserProjectTypes.LocationPoint | undefined;
      shouldBeAdDocFileType: string;
      appStore: Store<AppState>; userStore: Store<UserState>;
    }) {
      if (state.aAdDoc === undefined) {
        return
      }
      const queryResult = await adDocFirebase.getAdDocumentUsingId(param.modelId, "T")
      if (queryResult.deconnected === true) {
        commit('setAdDocs', [])
        param.userStore.dispatch("logout", {appStore: param.appStore})
      } else {
        if (queryResult.aAdDoc !== undefined) {
          const docData = queryResult.aAdDoc.OADData as any
          const newADDocFileType =  (param.shouldBeAdDocFileType === undefined) ? "" : param.shouldBeAdDocFileType
          // set from mini model now before removing the ADId
          if (newADDocFileType === '') {
            commit("setFromMiniModel", queryResult.aAdDoc.convertToMiniModel());
          }
          // Important to set the docID here, in order to be able to update the doc in saveAdDoc
          if (docData.ADId !== undefined) {
            delete docData.ADId
          }
          if (docData.ADTemplatePublicStatus !== undefined) {
            delete docData.ADTemplatePublicStatus
          }
          if (docData.ADOwnerMiniProfile !== undefined) {
            delete docData.ADOwnerMiniProfile
          }
          if (docData.ADEnterprise !== undefined) {
            delete docData.ADEnterprise
          }
          const loca = (docData.ADLocation && JSON.stringify(docData.ADLocation) !== "{}") ? {...docData.ADLocation} : {... param.loggedUserProfileLocation};
          
          // Keep the current attached docs if any
          // But take care of duplicated urls ...
          // And change to externalUrl
          docData.ADColAttDoc = mergeTwoADColAttDocRemovingDupURLs(docData.ADColAttDoc, state.aAdDoc.OADData.ADColAttDoc)
          const newDoc = {
            ...state.aAdDoc.OADData,
            ...docData,
            ADLocation: loca,
            ADDocFileType: newADDocFileType,
            // ADPublishedStatus: true,
          }
          if (newDoc.ADDocFileType === '') {
              newDoc["ADPublishedStatus"] = true
              // newDoc["ADOpenToAutoInvitation"] = false
          }
          console.error("updateAdDocUsingTemplate " + JSON.stringify(newDoc))
          commit("setAdDoc", newDoc);
        }
      }
    }

  },
  modules: {
  }
})


<template>
  <div  v-if="aAdDoc.isAdDoc() === true">
    <div v-if="aUserTransaction !== undefined && aUserTransaction[aAdDoc.getADId()] !== undefined">
      <div class="subtitle">
        {{ t("itemYourTransactionsStatus") }}
        {{ displayStatus(aUserTransaction[aAdDoc.getADId()].UTStatus) }}
      </div>
    </div>
    <div v-else-if="aUserProfile.isAdmin() === true || aAdDoc.isOwner(aUserProfile.getUPUserId()) === true">
      <div>
        <button class="button buttonCentered buttonClearBlue" data-cy="cy*openForPromotionAdDocFromList"
          @click.stop="openForPromotion()"
          :title="t('adDocPromotionRequestTitle')">
        {{ t("adDocPromotionRequest") }}
        </button>
      </div>
    </div>
    <div v-else class="inlineSeparatedBlockContainer">
      <div>
        <button class="button buttonCentered" data-cy="cyAddTransactionAdDocFromList"
          @click.stop="openAndAddTransaction()"
          :title="t('adDocParticipateRequestTitle')">
        {{ t("adDocParticipateRequest") }}
        </button>
      </div>
      <div>
        <button class="button buttonCentered" data-cy="cySee%oreAdDocFromList"
          :title="t('adDocSeeMoreTitle')">
        {{ t("adDocSeeMore") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, inject, reactive, toRefs } from "vue";
import router from '@/router';
import { useI18n } from "@/engineproject/default/common/i18n";
import { useDocumStore } from '@/engineproject/store/documStore';
import { useUserStore } from '@/engineproject/store/userStore';
import { useAppStore } from '@/engineproject/store/appStore';
//import AdDocViewTransactionFootStep from '@/engineproject/components/AdDocTransaction/AdDocViewTransactionFootStep.vue';

export default {
  props: {
    aAdDoc: {
      type: Object as () => Array<any>,
      required: true,
    },
  },
  setup(props: any, context: any) {
    const formatDate = inject('formatDate') as any;
    const appStore = useAppStore();
    const userStore = useUserStore();
    const documStore = useDocumStore();
    const { t } = useI18n();
    const page = reactive({
      aUserProfile: computed(() => userStore.state.aUserProfile),
      aUserTransaction: computed(() => userStore.state.aUserProfile.getUserTransaction()),
    });

    const displayStatus = (aStatusValue: string) => {
      const readableStatus = t("transactionStatus_" + aStatusValue)
      return t("transactionStatusTitle", {theStatus : readableStatus})
    }

    const openForPromotion = () => {
      router.push(props.aAdDoc.getADDocUrlRoute())
    }

    const openAndAddTransaction = async () => {
      await documStore.dispatch("openAdDocInModal", {
        aAdDoc: props.aAdDoc,
        appStore: appStore,
        userStore: userStore,
      });
      await documStore.dispatch("addTransactionAdDoc", {
        aAdDoc: props.aAdDoc,
        appStore: appStore,
        userStore: userStore,
        addTransactionText: "",
      });
    }
    return {
      ...toRefs(page),
      t,
      displayStatus,
      formatDate,
      openForPromotion,
      openAndAddTransaction,
    };
  },
  components: {
    //AdDocViewTransactionFootStep
  },
};
</script>

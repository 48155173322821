<template>
  <AppServerConfigInfo></AppServerConfigInfo>
  <AdDocDisplayMapInModal></AdDocDisplayMapInModal>
  <div id="appContainer" v-if="appServerConfigIsLocked === false">
    <div
      id="loadingMsg"
      :class="{ loadingModal: true, locked: loadingMsg != '' }"
      @click="removeLoadingModal()"
    ></div>
    <ProjectSiteNav :OnSmallScreenOnly="false"  :OnLargeScreenOnly="true"></ProjectSiteNav>
    <AdDocumentViewModalContainer></AdDocumentViewModalContainer>
    <ProfilesListModalContainer></ProfilesListModalContainer>
    <UserProfileModalContainer></UserProfileModalContainer>
    <router-view />
    <ProjectSiteNav :OnSmallScreenOnly="true"  :OnLargeScreenOnly="false"></ProjectSiteNav>
  </div>
  <div
    v-if="newAppVersionIsComing === true"
    class="hidden"
    data-cy="cyNewAppVersionIsComing"
  >
    .
  </div>
  <BlockAppUI> </BlockAppUI>
</template>
<script lang="ts">
import { userFirebase } from "./backend/firebase";
import { onBeforeMount } from "vue";
import { reactive, computed, toRefs, watch } from "vue";
import { useToast } from "vue-toastification";
import { ToastObject, useAppStore } from "@/engineproject/store/appStore";
import { useFilterAttributesStore } from "@/engineproject/store/filterAttributesStore";
import { useUserStore } from "@/engineproject/store/userStore";
import { useEnterpriseStore } from "@/engineproject/store/enterpriseStore";

import { useI18n } from "@/engineproject/default/common/i18n";

import ProfilesListModalContainer from "@/engineproject/components/UserProfile/ProfilesListModalContainer.vue";
import UserProfileModalContainer from "@/engineproject/components/UserProfile/UserProfileModalContainer.vue";
import AdDocumentViewModalContainer from "@/engineproject/components/AdDocument/AdDocumentViewModalContainer.vue";
import AppServerConfigInfo from "@/engineproject/components/AppMenu/AppServerConfigInfo.vue";
import AdDocDisplayMapInModal from "@/engineproject/components/AdDocInMap/AdDocDisplayMapInModal.vue";
import ProjectSiteNav from "@/engineproject/default/common/components/AppMenu/ProjectSiteNav.vue";
import BlockAppUI from "@/engineproject/components/UIElements/BlockAppUI.vue";
import AppToast from "@/engineproject/components/UIElements/AppToast.vue";

interface Props {
  value?: string | number | boolean;
}

export default {
  setup(props: Props, context: any) {
    const appStore = useAppStore();
    const filterAttributesStore = useFilterAttributesStore();
    const userStore = useUserStore();
    const enterpriseStore = useEnterpriseStore();

    /*
    const debugStore = (storeName: string, aStore: any) => {
        console.error("debugStore " + storeName + " actions " + Object.keys(aStore._actions).length)
        console.error("debugStore _mutations " + Object.keys(aStore._mutations).length)
    }

    debugStore("appStore" , appStore)
    debugStore("userStore" ,userStore)
    debugStore("documStore" ,documStore)
    debugStore("filterAttributesStore" ,filterAttributesStore)
    debugStore("filterUsersStore" ,filterUsersStore)
*/

    const { t, locale } = useI18n();
    // Get toast interface
    const toast = useToast();

    const page = reactive({
      blockedApplication: computed(() => {
        //console.error("appStore.state.blockedApplication " + appStore.state.blockedApplication)
        return appStore.state.blockedApplication;
      }),
      newAppVersionIsComing: computed(
        () => appStore.state.newAppVersionIsComing
      ),
      appServerConfigIsLocked: computed(() =>
        appStore.state.aAppServerConfig.serverIsLocked()
      ),
    });
    const currentLocale = computed(() => locale.value);
    const notifMsg = computed(() => appStore.state.notifMsg);
    const errorMsg = computed(() => appStore.state.errorMsg);
    const loadingMsg = computed(() => appStore.state.loadingMsg);
    const toastObject = computed(() => appStore.state.toastObject);

    watch(currentLocale, (newValue, oldValue) => {
      filterAttributesStore.dispatch("initFilterAttributesStore", {
        lang: newValue,
        t: t,
      });
    });

    const getToastContent = (idOrText: string) => {
      return {
        // Your component or JSX template
        component: AppToast,
        // Props are just regular props, but these won't be reactive
        props: {
          toastText: t(idOrText),
          toastIdOrText: idOrText.split(" ")[0],
        },
      };
    };

    const getToastObjectContent = (toastObject: ToastObject) => {
      return {
        // Your component or JSX template
        component: AppToast,
        // Props are just regular props, but these won't be reactive
        props: {
          toastText: t(toastObject.msgId),
          toastIdOrText: toastObject.msgId,
          toastLinkText: t(toastObject.msgLinkId),
          toastLinkClicked: () => {
            if (toastObject.toastAction !== undefined) {
              toastObject.toastAction();
            }
          },
        },
      };
    };

    watch(toastObject, (newValue, oldValue) => {
      // console.error("notifMsg change" + newValue);
      if (newValue !== undefined) {
        // console.error("notifMsg change " + newValue);
        // Use it!
        if (newValue.toastType == "I") {
          toast.info(getToastObjectContent(newValue));
        } else {
          toast.error(getToastObjectContent(newValue));
        }
        appStore.commit("setToastObject", { toastObject: undefined });
      }
    });

    watch(notifMsg, (newValue, oldValue) => {
      // console.error("notifMsg change" + newValue);
      if (newValue && newValue !== "") {
        // console.error("notifMsg change " + newValue);
        // Use it!
        toast.info(getToastContent(newValue));
        appStore.commit("setNotifMsg", { notifMsg: "" });
      }
    });

    watch(errorMsg, (newValue, oldValue) => {
      if (newValue && newValue !== "") {
        console.error("errorMsg change " + newValue);
        // Use it!
        toast.error(getToastContent(newValue));
        appStore.commit("setErrorMsg", { errorMsg: "" });
      }
    });

    const removeLoadingModal = () => {
      appStore.commit("setLoadingMsg", { loadingMsg: "" });
    };
    document.addEventListener("displayNotif", function (e: any) {
      appStore.commit("setNotifMsg", { notifMsg: e.detail.notifMsg });
    });
    document.addEventListener(
      "NewContentAvailablePleaseDisplayRefresh",
      function (e: any) {
        console.error("RefreshServiceWorkerReceived");
        // appStore.commit("setNotifMsg", { notifMsg: "newAppVersionNotif" });
        appStore.commit("setToastObject", {
          toastObject: {
            msgId: "newAppVersionNotif",
            msgLinkId: "newAppVersionNotifLink",
            toastAction: () => {
              appStore.dispatch("installNewAppVersion");
            },
            toastType: "I",
          },
        });
        appStore.commit("setNewAppVersion", { newAppVersion: "installed" });
        appStore.commit("setNewAppVersionIsComing", { newAppVersionVal: true });
      },
      false
    );
    document.addEventListener(
      "NewUpdateFoundInfoMsg",
      function (e: any) {
        console.error("NewUpdateFoundInfoMsg");
        appStore.commit("setNewAppVersionIsComing", { newAppVersionVal: true });
      },
      false
    );

    onBeforeMount(async () => {
      const reloadUser = async () => {
        const user: any = await userFirebase.getCurrentUser();
        if (user !== null) {
          userStore.dispatch("fetchUserProfile", {
            userId: user.uid,
            appStore,
            afterSignup: false,
          });
        } else {
          userStore.dispatch("logoutAction", {
            appStore,
          });
        }
      };
      const loadAppServerConfig = async () => {
        appStore.dispatch("fetchAppServerConfig", {
          appStore,
        });
      };
      const initFilterAttributesStore = async (lang: string) => {
        filterAttributesStore.dispatch("initFilterAttributesStore", {
          lang: lang,
          t: t,
        });
      };
      await loadAppServerConfig();
      await reloadUser();
      await initFilterAttributesStore("userLangOrAppDefault");
      // TODO Find a way to remove it for non admin user
      await enterpriseStore.dispatch("queryAllEnterprises", {optionsQuery: {},
        appStore,
        userStore,
      });


      window.addEventListener("resize", () => {
        appStore.commit("setWindowWidth");
        // TEST ONLY
        // const event = new Event("NewContentAvailablePleaseDisplayRefresh", {"bubbles":true, "cancelable":true});
        // document.dispatchEvent(event);
      });
      appStore.commit("setWindowWidth");
    });

    return {
      ...toRefs(page),
      locale,
      loadingMsg,
      removeLoadingModal,
    };
  },
  components: {
    ProjectSiteNav,
    UserProfileModalContainer,
    AdDocumentViewModalContainer,
    BlockAppUI,
    AppServerConfigInfo,
    AdDocDisplayMapInModal,
    ProfilesListModalContainer,
  },
};
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#loadingMsg:not(.locked) {
  display: none;
}
#loadingMsg.locked {
  display: block;
}
.loadingModal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.OnLargeScreenOnly {
  display:flex;
  height: 10dvh;
  overflow: auto;
  > * {
    width: 100%;
    display: flex;
  }

  @media screen and (max-width: 742px) {
    display: none;
  }
}


.OnSmallScreenOnly {
  display:none;
  .mainMenu.col1  .floatLeft {
    display: none;
  }
  @media screen and (max-width: 742px) {
    display: flex;
    height: 10dvh;
    overflow: auto;
    > * {
    width: 100%;
    display: flex;
  }

  }
}

.mainMenu {
    font-size: 1.6vh !important;
    margin: auto;

    h3 {
      font-size: 4vh !important;
    }
    &.col1, &col2 {
      padding-top: 0rem;
      padding-bottom: 0rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
}



</style>

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "banUserArea inlineSeparatedBlockContainer adminSuperPower" }
const _hoisted_2 = {
  key: 0,
  class: "inline"
}
const _hoisted_3 = { class: "inlineInput" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loggedUserProfile.isAdmin() === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", _hoisted_3, _toDisplayString($setup.t('banUser')) + " : ", 1),
          _createVNode(_component_InputSwitch, {
            modelValue: _ctx.userBanned,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userBanned) = $event)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => ($setup.banUser({ aUserProfile: $props.aUserProfile })))
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
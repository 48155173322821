import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "adminDisplayColor suggestionsTitle"
}
const _hoisted_2 = {
  key: 1,
  class: "modelsTitle"
}
const _hoisted_3 = { class: "col1" }
const _hoisted_4 = { class: "col2" }
const _hoisted_5 = {
  key: 0,
  id: "mapContainerInMainDisplay"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterInLine = _resolveComponent("FilterInLine")!
  const _component_ProjectFilterAdDocNav = _resolveComponent("ProjectFilterAdDocNav")!
  const _component_DashboardSearchMaxLimitReached = _resolveComponent("DashboardSearchMaxLimitReached")!
  const _component_AdDocDisplayMap = _resolveComponent("AdDocDisplayMap")!
  const _component_ProjectAdDocumentListView = _resolveComponent("ProjectAdDocumentListView")!
  const _component_AdDocListNav = _resolveComponent("AdDocListNav")!
  const _component_DashboardSearchNoResult = _resolveComponent("DashboardSearchNoResult")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", null, [
      ($props.adDocFileType === 'S')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.t("suggestionsTitle")), 1))
        : _createCommentVNode("", true),
      ($props.adDocFileType === 'T')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.t("modelsTitle")), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_FilterInLine, {
        stateObject: "universFilter",
        emptyValueAccepted: false
      })
    ]),
    _createElementVNode("section", {
      class: _normalizeClass('classAdDocFileType' + $props.adDocFileType)
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ProjectFilterAdDocNav, { adDocFileType: $props.adDocFileType }, null, 8, ["adDocFileType"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_DashboardSearchMaxLimitReached),
        (_ctx.getDisplayMapCenter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_AdDocDisplayMap)
            ]))
          : _createCommentVNode("", true),
        (_ctx.arrayOfAdDocs.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.calculateNumberOfRows(_ctx.arrayOfAdDocs), (i) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["postRow", 'postRow' + _ctx.numberOfPostInRow]),
                  key: i
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getArrayElementsAtPosition(_ctx.arrayOfAdDocs, i), (aAdDoc) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: aAdDoc?.OADData?.ADId,
                      class: _normalizeClass(["post", aAdDoc?.getCSSClassForPost()])
                    }, [
                      _createVNode(_component_ProjectAdDocumentListView, {
                        aAdDoc: aAdDoc,
                        indexDocInList: i
                      }, null, 8, ["aAdDoc", "indexDocInList"])
                    ], 2))
                  }), 128))
                ], 2))
              }), 128)),
              _createVNode(_component_AdDocListNav, {
                adDocFileType: $props.adDocFileType,
                aAdDocSubList: _ctx.arrayOfAdDocs,
                onPreviousSubList: _cache[0] || (_cache[0] = ($event: any) => (_ctx.previousSubList())),
                onNextSubList: _cache[1] || (_cache[1] = ($event: any) => ($setup.nextSubList()))
              }, null, 8, ["adDocFileType", "aAdDocSubList"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_DashboardSearchNoResult, { adDocFileType: $props.adDocFileType }, null, 8, ["adDocFileType"])
            ]))
      ])
    ], 2)
  ], 64))
}
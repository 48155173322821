<template>
  <div>
    <Dropdown class="countrySelect" v-model="locale" :options="langsFlag" optionValue="language" optionLabel="title">
      <template #value="slotProps">
        <div class="country-item country-item-value" v-if="slotProps.value">
          <img class="flag flag-au" :src="getFlagFromValue(slotProps.value)" />
        </div> 
      </template>
      <template #option="slotProps">
        <div class="country-item">
          <img class="flag flag-au" :src="slotProps.option.flagImg" />
          <span class="titleInOptions">{{slotProps.option.title}}</span>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs, } from 'vue'
import { useI18n } from 'vue-i18n'

import Dropdown from 'primevue/dropdown';

export default {
  setup(props: any, context: any) {
    const { locale  } = useI18n()
    const page = reactive({
      selectedCity2: "France",
      countries: [
          {name: 'France', code: 'FR'},
          {name: 'United States', code: 'US'}
      ],
      langs: [
        'en',
        'fr'
      ],
      langsFlag: [
        { flag: 'en', language: 'en', title: 'English',
            flagImg: require("@/engineproject/default/common/assets/images/en.svg") },
        { flag: 'fr', language: 'fr', title: 'Français',
            flagImg: require("@/engineproject/default/common/assets/images/fr.svg") }
      ]
    });
    const getOptionValue = (param: any) => {
      return param.language
    }
    const getFlagFromValue = (param: any) => {
      return page.langsFlag.find((ele: any) => {
        return ele.language == param
      })?.flagImg
    }
    return { 
      ...toRefs(page),
      locale,
      getOptionValue,
      getFlagFromValue,
    }
  },
  components: { 
    Dropdown,
  },
}


</script>
<style lang="scss" >

.country-item {
  img {
    width: 14px;
  }
  .titleInOptions {
    margin-left: 4px;
  }
}
.countrySelect {
  border: 0px !important;

  .p-inputtext {
    padding: 0px;
  }

  .p-dropdown-trigger {
    display: none;
  }
}
    

</style>
import { createApp } from 'vue'

import { documStore, documKey } from '@/engineproject/store/documStore'
import { filterAttributesStore, filterAttributesKey } from '@/engineproject/store/filterAttributesStore'
import { filterUsersStore, filterUsersKey } from '@/engineproject/store/filterUsersStore'
import { userStore, userKey } from '@/engineproject/store/userStore'
import { enterpriseStore, enterpriseKey } from '@/engineproject/store/enterpriseStore'
import { appStore, appKey } from '@/engineproject/store/appStore'

import App from '@/engineproject/App.vue' 
import './registerServiceWorker'
import router from '@/router'
import {i18n} from '@/engineproject/i18nApp'
import '@/engineproject/default/common/assets/scss/app.scss';
//import './assets/scss/app.scss'
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import VueObserveVisibility from 'vue-observe-visibility'

import  vueToHtml from '@/engineproject/plugins/vueToHtml/vueToHtml'
import  dateUtils from '@/engineproject/plugins/dateUtils/dateUtils'

import PrimeVue from 'primevue/config';
////import 'primevue/resources/themes/saga-blue/theme.css';
import '@/engineproject/assets/scss/theme.css';
//import 'primevue/resources/themes/luna-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
// import VueSocialSharing from 'vue-social-sharing'
// import VueMeta from 'vue-meta'
//import { createMetaManager, defaultConfig } from 'vue-meta'
//import { createMetaManager } from 'vue-meta'

// Import the CSS or use your own!
//import "vue-toastification/dist/index.css";
import '@/engineproject/assets/scss/toast/index.css';

// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faUserSecret, faFutbol } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// library.add(faUserSecret, faFutbol)

import VueGoogleMaps from '@fawmi/vue-google-maps'
import {firebaseProjectConfig} from "@/engineproject/default/common/envConfigs/firebaseProject"
const googleMapAPIKey = firebaseProjectConfig.apiKey

import vue3GoogleLogin from "vue3-google-login"
const webAppClientIdForGoogleAPI = firebaseProjectConfig.webAppClientIdForGoogleAPI



const options: PluginOptions = {
  // You can set your default options here
  position: POSITION.TOP_LEFT
};
//console.error("process.env.APPLICATION_VERSION " + process.env.APPLICATION_VERSION)
// console.error("process.env.VUE_APP_VERSION " + process.env.VUE_APP_VERSION)

//const metaManager = createMetaManager()

console.error('window.location.href '  + window.location.href)
if ( window.location.href.startsWith('https://alomigo.com/')) {
  window.location.href  = 'https://www.alomigo.com/'
} else {


const app = createApp(App).use(i18n)
  // .component('font-awesome-icon', FontAwesomeIcon)
  //.use(metaManager)
  .use(appStore, appKey).use(userStore, userKey).use(enterpriseStore, enterpriseKey).use(documStore, documKey)
  .use(filterAttributesStore, filterAttributesKey)
  .use(filterUsersStore, filterUsersKey)
  .use(router).use(Toast, options)
  .use(vueToHtml)
    // eslint-disable-next-line 
    // @ts-ignore
    .use(dateUtils)
  //.use(VueMeta as any ,{refreshOnceOnNavigation:true})
  //.use(createMetaManager(defaultConfig)) 
  //.use(VueMeta)
//  .use(VueSocialSharing)
  .use(PrimeVue, {
    locale: {
      startsWith: 'Starts with',
      contains: 'Contains',
      notContains: 'Not contains',
      endsWith: 'Ends with',
      equals: 'Equals',
      notEquals: 'Not equals',
      noFilter: 'No Filter',
      lt: 'Less than',
      lte: 'Less than or equal to',
      gt: 'Greater than',
      gte: 'Greater than or equal to',
      dateIs: 'Date is',
      dateIsNot: 'Date is not',
      dateBefore: 'Date is before',
      dateAfter: 'Date is after',
      clear: 'Clear',
      apply: 'Apply',
      matchAll: 'Match All',
      matchAny: 'Match Any',
      addRule: 'Add Rule',
      removeRule: 'Remove Rule',
      accept: 'Yes',
      reject: 'No',
      choose: 'Choose',
      upload: 'Upload',
      cancel: 'Cancel',
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su","Mo","Tu","We","Th","Fr","Sa"],
      monthNames: ["January","February","March","April","May","June","July","August","September","October","November","December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',
      weekHeader: 'Wk',
      firstDayOfWeek: 0,
      dateFormat: 'dd/mm/yy',
      weak: 'Weak',
      medium: 'Medium',
      strong: 'Strong',
      passwordPrompt: 'Enter a password',
      emptyFilterMessage: 'No results found',
      emptyMessage: 'No available options'
        }})
  .use(VueObserveVisibility)
  .use(VueGoogleMaps, {
    load: {
        key: googleMapAPIKey,
        libraries: "places",
        // libraries: "visualization"
    },
  }).use(vue3GoogleLogin, {
    clientId: webAppClientIdForGoogleAPI,
  })

app.config.errorHandler = (err, vm, info) => {
  // err: error trace
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.
  console.error("app.config.errorHandler " + err)
  // console.error("app.config.errorHandler " + info)
  // console.error("app.config.errorHandler " + vm)
  // console.error("app.config.errorHandler info " + JSON.stringify(info))
  // console.error("app.config.errorHandler vm " + JSON.stringify(vm))
  // TODO: Perform any custom logic or log to server

};


app.mount('#app')

}

// console.error("mount app just done")


function addStyles(win: Window, styles: any[]) {
    styles.forEach((style: any) => {
      const link = win.document.createElement("link");
      link.setAttribute("rel", "stylesheet");
      link.setAttribute("type", "text/css");
      link.setAttribute("href", style);
      if (win) {
        win.document.getElementsByTagName("head")[0].appendChild(link);
      }
    });
  }
  
  const addStylesheetRules = (win: Window, tabIdActiveArea: string, rules: any) => {
    console.error("addStylesheetRules" + JSON.stringify(rules))
		let styleEl = win.document.querySelector("#handidatScrullRules" + tabIdActiveArea) as any
		if (styleEl !== undefined && styleEl !== null && styleEl.parentElement !== null) {
			styleEl.parentElement.removeChild(styleEl);
		}
		styleEl = win.document.createElement('style');
		styleEl.setAttribute("id","handidatScrullRules" + tabIdActiveArea)
		// Append <style> element to <head>
		win.document.head.appendChild(styleEl);
		// Grab style element's sheet
		// const styleSheet = styleEl.
		const styleSheet = styleEl.sheet;

		for (let i = 0; i < rules.length; i++) {
			let j = 1;
			let rule = rules[i]; 
			const selector = rule[0];
			let propStr = '';
			// If the second argument of a rule is an array of arrays, correct our variables.
			if (Array.isArray(rule[1][0])) {
				rule = rule[1];
				j = 0;
			}

			for (let pl = rule.length; j < pl; j++) {
				const prop = rule[j];
				propStr += prop[0] + ': ' + prop[1] + (prop[2] ? ' !important' : '') + ';\n';
			}

			// Insert CSS Rule
			styleSheet.insertRule(selector + '{' + propStr + '}', styleSheet.cssRules.length);
		}
	}

  
  const buildStylesheetRules = (win: Window, tabIdActiveArea: string, rules: any) => {
    console.error("buildStylesheetRules" + JSON.stringify(rules))
    let ret = ""
		for (let i = 0; i < rules.length; i++) {
			let j = 1;
			let rule = rules[i]; 
			const selector = rule[0];
			let propStr = '';
			// If the second argument of a rule is an array of arrays, correct our variables.
			if (Array.isArray(rule[1][0])) {
				rule = rule[1];
				j = 0;
			}

			for (let pl = rule.length; j < pl; j++) {
				const prop = rule[j];
				propStr += prop[0] + ': ' + prop[1] + (prop[2] ? ' !important' : '') + ';\n';
			}

			// Insert CSS Rule
			ret = ret + selector + '{' + propStr + '}';
		}
    return  "<style  type='text/css' >" + ret +  "</style>"
	}
  const vueToHtml = {
    install(app: any) {
      app.config.globalProperties.$vueToHtml = (
        el: string,
        localOptions: { 
          name: any; specs: any; replace: any; styles: any; styleRules: any; styleTextRules: string;
          previewAction: boolean;
        } | undefined,
        cb = () => true
      ) => {
        const defaultName = "_blank",
          defaultSpecs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
          defaultReplace = true,
          defaultPreviewAction = false,
          defaultStyles: never[] = [],
          defaultStyleRules: never[] = [],
          defaultStyleTextRules = "";
        let name = defaultName,
          specs = defaultSpecs as any,
          replace = defaultReplace,
          previewAction = defaultPreviewAction,
          styles = defaultStyles,
          styleRules = defaultStyleRules,
          styleTextRules = defaultStyleTextRules
        ;
  
        // If has localOptions
        // TODO: improve logic
        if (localOptions !== undefined) {
          if (localOptions.name) name = localOptions.name;
          if (localOptions.specs) specs = localOptions.specs;
          if (localOptions.replace) replace = localOptions.replace;
          if (localOptions.previewAction) previewAction = localOptions.previewAction;
          if (localOptions.styles) styles = localOptions.styles;
          if (localOptions.styleRules) styleRules = localOptions.styleRules;
          if (localOptions.styleTextRules) styleTextRules = localOptions.styleTextRules;
        }
  
        specs = (specs.length > 0) ? specs.join(",") : "";
  
        const element = window.document.getElementById(el);
  
        if (!element) {
          alert(`Element to print #${el} not found!`);
          return;
        }
  
        const url = "";
        const win = window.open(url, name, specs /*, replace*/);
        if (win) {
          //const cssText = buildStylesheetRules(win, "1", styleRules) +
          const cssText = "<style  type='text/css' >" + styleTextRules +  "</style>"
          let elementOuterHTML = `
            ${element.outerHTML}
          `
          if (elementOuterHTML.indexOf(" poster6") !== -1) {
            elementOuterHTML = `
              ${element.outerHTML}
              ${element.outerHTML}
              ${element.outerHTML}
              ${element.outerHTML}
              ${element.outerHTML}
              ${element.outerHTML}
            `
          }
          win.document.write(`
          <html>
            <head>
              <title>${window.document.title}</title>
              ${cssText}
            </head>
            <body>
              <div class="pageContainer pageContainerA4Vertical">
                <div class="pageContent pageContentAnimation">
                ${elementOuterHTML}
                </div>
              </div>
            </body>
          </html>
        `);
  
          addStyles(win, styles);
          //addStylesheetRules(win, "1", styleRules);
      
          if (previewAction !== true) {
            Array.from(win.document.getElementsByClassName("contentEditable")).forEach(element => {
              element.removeAttribute("contentEditable")
              element.removeAttribute("title")
            });
          }
  
          setTimeout(() => {
            win.document.close();
            win.focus();
            if (previewAction !== true) {
              win.print();
              win.close();
            }
            cb();
          }, 1500);
  
        }
        return true;
      };
    }
  };
  
  export default vueToHtml;
  
<template>
  <div v-if="fMyContactUsersOn === false || fMyContactUsersOn === true" class="filter-container" >
    <div>
      <label for="filterGeoOn">{{ t('filterGeolocalisationTitle') }}</label>
      <input  data-cy="cyFilterProfileGeoOn" :value="fUserProfileGeoOn" type="checkbox" @click="setProfileGeoOnOff"
          id="filterGeoOn" />
    </div>
    <div v-if="fUserProfileGeoOn === true">
      <div class="inlineSeparatedBlockContainer">
        <div>
          <label for="fZipcode">{{ t('filterZipcode') }}</label>
          <GeolocationFromZipCodes :currentLocation="searchLocationFilter"  id="fZipcode"  :placeholder="t('filterZipcodePH')"
          v-on:update:location="updateLocation($event)" inputClass="inputClass">
          </GeolocationFromZipCodes>
        </div>
        <div>
          <label for="fDistance">{{ t('filterDistance') }}</label>
          <InputText  v-model="searchDistanceInKMFilter" type="number" 
            :placeholder="t('filterDistancePH')" id="fDistance"
            @change="updateDistance($event)" />
        </div>
      </div>
      <div class="inlineSeparatedBlockContainer">
        <GeolocationView :aLocation="searchLocationFilter"
        ></GeolocationView>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {  reactive, toRefs, computed, ref, watch } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { useFilterUsersStore} from '@/engineproject/store/filterUsersStore'
import { useUserStore } from '@/engineproject/store/userStore';
import { UserProjectTypes } from '@/engineproject/default/common/domainModel/userProjectTypes';

import InputText from 'primevue/inputtext';

import GeolocationView from '@/engineproject/components/Geolocation/GeolocationView.vue';
import GeolocationFromZipCodes from "@/engineproject/components/Geolocation/GeolocationFromZipCodes.vue";

export default {
  props: {
    stateObject: {
      type: String,
      required: false
    },
  },
  setup(props: any) {
    const filterUsersStore = useFilterUsersStore()
    const userStore = useUserStore();
    const {t} = useI18n()
    const page: any = reactive({
      searchLocationFilter: computed(() => (filterUsersStore.state.searchLocationFilter) ? 
        filterUsersStore.state.searchLocationFilter :
        userStore.state.aUserProfile.getUPLocation()), 
        //userStore.state.userProfile?.UPLocation), 
      fUserProfileGeoOn: computed(() => 
        (filterUsersStore.state.filterType === "allLocalUsers")), 
      fMyContactUsersOn: computed(() => 
        (filterUsersStore.state.filterType  === "myContactUsers")), 
    });
    const searchDistanceInMFilter = computed(() => filterUsersStore.state.searchDistanceInMFilter)
    const searchDistanceInKMFilter = ref(searchDistanceInMFilter.value / 1000 as number) // computed(() => searchDistanceInMFilter .value/ 1000)
    watch(searchDistanceInMFilter, (newValue, oldValue) => {
      console.error("searchDistanceInMFilter " + newValue);
      searchDistanceInKMFilter.value = newValue / 1000
    });

    // "allLocalUsers", "allUsers", "myContactUsers"
    const setProfileGeoOnOff = () => {
      console.error("filterTypeValue")
      filterUsersStore.commit("setFilterType", {filterTypeValue : ((page.fUserProfileGeoOn !== true) ? "allLocalUsers" : "allUsers")})
    }
    const updateLocation = (newLocation: UserProjectTypes.LocationPoint | undefined) => {
      filterUsersStore.commit("setSearchLocationFilter", {
        searchLocationFilter: newLocation
      });
    };
    const updateDistance = (event: any) => {
      const searchDistanceInKFilter = parseInt(event.currentTarget.value)
      console.error("updateDistance " + JSON.stringify(searchDistanceInKFilter))
      filterUsersStore.commit("searchDistanceInMFilter", {
        searchDistanceInMFilter: searchDistanceInKFilter * 1000
      });
    };
  
    return { 
      ...toRefs(page),
      /// props,
      t,
      setProfileGeoOnOff,
      updateLocation,
      updateDistance,
      searchDistanceInKMFilter,
    }
  },
  components: { 
    GeolocationFromZipCodes,
    InputText,
    GeolocationView
  },
}


</script>
<style lang="scss" >
.inputClass {
  width: 100px !important;
  min-width: 100px !important;
}
#fZipcode, .p-autocomplete, .p-autocomplete > input  {
  width: 100px !important;
}
#fZipcode, .p-autocomplete, .p-autocomplete > input  {
  min-width: 100px !important;
}
#fDistance {
  width: 100px;
}
.filter-container {
  margin: 4px;
  padding: 2px;
}
</style>

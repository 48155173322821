import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-cy": "cyInvitationsLoading"
}
const _hoisted_2 = {
  key: 1,
  "data-cy": "cyInvitationsLoaded"
}
const _hoisted_3 = { class: "invitationButtonArea" }
const _hoisted_4 = ["title"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["title"]
const _hoisted_7 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.aAdDoc.getInvitationsIsLoading() === true )
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.t('invitationIsLoadingText')), 1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "button",
            "data-cy": "cyOpenInvitationPopup",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.openInvitationPopup($props.aAdDoc, _ctx.addInvitationText))),
            title: $setup.t('adDocOpenInvitationPopupTitle')
          }, _toDisplayString($setup.t( "adDocOpenInvitationPopup")), 9, _hoisted_4),
          _createElementVNode("a", {
            href: $setup.buildMailTo()
          }, [
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.copyMailContent())),
              class: "button",
              "data-cy": "cyOpenInvitationPopup",
              title: $setup.t('adDocOpenInvitationPerMailPopupTitle')
            }, _toDisplayString($setup.t( "adDocOpenInvitationPerMailPopup")), 9, _hoisted_6)
          ], 8, _hoisted_5),
          _createElementVNode("textarea", {
            class: "invisible",
            ref: "mailBodyText",
            value: $setup.buildMailToBodyText()
          }, "\r\n      ", 8, _hoisted_7)
        ])
      ]))
}
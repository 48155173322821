<template>

  <PartnerPromotionViewButtons :posterElementId="posterElementBaseId" 
    v-model:expandState="showPreview"
    :posterAdditionalClass="getPosterAdditionalClass()">
  </PartnerPromotionViewButtons>
  <div class="poster" :id="posterElementBaseId + 'Id'"
    v-show="showPreview">
    <div class="subpage">
      <component :is="dyncomp"
        :subpageClassPos="getSubpageClass(1)"
        v-if="mustBeVisible(1)"
        :subpageSizeA="subpageSizeA"
        :dyncompData="dyncompData"
      ></component>
      <component :is="dyncomp"
        :subpageClassPos="getSubpageClass(2)"
        v-if="mustBeVisible(2)"
        :subpageSizeA="subpageSizeA"
        :dyncompData="dyncompData"
      ></component>
      <component :is="dyncomp"
        :subpageClassPos="getSubpageClass(3)"
        v-if="mustBeVisible(3)"
        :subpageSizeA="subpageSizeA"
        :dyncompData="dyncompData"
      ></component>
      <component :is="dyncomp"
        :subpageClassPos="getSubpageClass(4)"
        v-if="mustBeVisible(4)"
        :subpageSizeA="subpageSizeA"
        :dyncompData="dyncompData"
      ></component>
      <component :is="dyncomp"
        :subpageClassPos="getSubpageClass(5)"
        v-if="mustBeVisible(5)"
        :subpageSizeA="subpageSizeA"
        :dyncompData="dyncompData"
      ></component>
      <component :is="dyncomp"
        :subpageClassPos="getSubpageClass(6)"
        v-if="mustBeVisible(6)"
        :subpageSizeA="subpageSizeA"
        :dyncompData="dyncompData"
      ></component>
    </div>
  </div>

</template>
<script lang="ts">
import { reactive, toRefs } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import PartnerPromotionViewButtons from '@/engineproject/components/Promotion/PartnerPromotionViewButtons.vue'

export default {
  props: {
    // A4 A5 or 6 (6 posters in A4)
    subpageSizeA: {
      type: String,
      required: false,
      default: "A5"
    },
    posterElementBaseId: {
      type: String,
      required: true,
    },
    dyncomp: {
      type: Object as () => any,
      required: true
    },
    dyncompData: {
      type: Object as () => any,
      required: false
    },
  },
  setup(props: any, context: any) {
    const {t} = useI18n()

    const subpageClass1 = {
      "A4": "",
      "A5": "subpageA5Top",
      "A6": "subpageA6TopLeft",
      "6": "subpage6TopLeft",
    }
    const subpageClass2 = {
      "A4": "",
      "A5": "",
      "A6": "subpageA6TopRight",
      "6": "subpage6TopRight",
    }
    const subpageClass3 = {
      "A4": "",
      "A5": "",
      "A6": "subpageA6BottomLeft",
      "6": "subpage6MiddleLeft",
    }
    const subpageClass4 = {
      "A4": "",
      "A5": "",
      "A6": "subpageA6BottomRight",
      "6": "subpage6MiddleRight",
    }
    const subpageClass5 = {
      "A4": "",
      "A5": "",
      "A6": "",
      "6": "subpage6BottomLeft",
    }
    const subpageClass6 = {
      "A4": "",
      "A5": "",
      "A6": "",
      "6": "subpage6BottomRight",
    }

    const subpageClassArray = [
      subpageClass1,
      subpageClass2,
      subpageClass3,
      subpageClass4,
      subpageClass5,
      subpageClass6,
    ] as Array<any>;
    
    const getPosterAdditionalClass = () => {
      return "styleRules" + props.subpageSizeA
    }
    const page = reactive({
      showPreview: false,
    });
    const getSubpageClass = (posi: number) => {
      return subpageClassArray[posi-1][props.subpageSizeA]
    }
    const mustBeVisible = (position: number) => {
      if (position === 2 && props.subpageSizeA === "A4") {
        return false
      }
      if (position === 3 && props.subpageSizeA !== "A6" && props.subpageSizeA !== "6") {
        return false
      }
      if (position === 4 && props.subpageSizeA !== "A6" && props.subpageSizeA !== "6") {
        return false
      }
      if (position === 5 &&  props.subpageSizeA !== "6") {
        return false
      }
      if (position === 6 && props.subpageSizeA !== "6") {
        return false
      }
      return true
    }
    return {
      ...toRefs(page),
      mustBeVisible,
      getSubpageClass,
      getPosterAdditionalClass,
      t,
    }
  },
  components: { 
    PartnerPromotionViewButtons
  },
}


</script>

import firebase from "firebase/app";
import "firebase/storage";
// files copied from https://github.com/adaltas/node-csv/tree/master/packages/csv-parse/dist/esm
// because parse is no more loaded in last version of webpack 
import {parse} from './node-csv/sync.js';
// import parse from 'csv-parse/lib/sync';
// import parse from 'csv-parse/lib/sync';
//import * as csv from 'csv-parse/browser/esm/index.js';
/*
import { Stream } from 'stream';
const ms = new Stream();
const Transform = Stream.Transform;
console.error("stream " + Stream);
console.error("Transform " + Transform);
*/
//      import {parse} from 'csv-parse' ///your/path/lib/parse/index.js'
// import {parse} from './buffer@6.0.3' //'your/path/lib/parse/index.js'
// const Buffer = require('buffer/').Buffer
// import csvtojson from 'csvtojson';
// import parse from 'csv-parse';

//const stream = require('stream');

import { firebaseStorage } from "./firebase";

class ZipcodeFirebase {
  zipcodesData = undefined as any
  async getZipcodes() {
    if (this.zipcodesData !== undefined) {
      return {
        backendActionDone: true,
        zipcodes: this.zipcodesData,
        errorMsg: ""
      }
      //return this.zipcodesData
    }
    try {
      const url = await firebaseStorage.getStorageUrl("/countries/fr/postal-code-FR.csv")
      /// const  storage = firebase.storage();
      // Create a reference from a Google Cloud Storage URI
      /// const  gsReference = storage.refFromURL('gs://' + firebaseProjectConfig.storageBucket + '/countries/fr/postal-code-FR.csv');
      /// const url = await gsReference.getDownloadURL()
      const response = await fetch(url);
      const data = await response.text();
      

      const records = parse(data, {
        columns: true,
        delimiter: [";"],
        "skip_empty_lines": true
      });
      /*
      const records = await csvtojson({
        delimiter: [";"],
        // output: "csv"
      })
      .fromString(data);
      */
      // console.error("records " + JSON.stringify(records));
      this.zipcodesData = records;
      return {
        backendActionDone: true,
        zipcodes: this.zipcodesData,
        errorMsg: ""
      };
      // return records
    } catch(error) {
      const errorMsg = "Error in getZipcode: " + error;
      console.error(errorMsg);
      return {
        backendActionDone: false,
        zipcodes: undefined,
        errorMsg: errorMsg
      };
    }

  }
}

export default ZipcodeFirebase

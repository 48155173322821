//     To be configured in Paypal production account
const PPConfigProduction = {
  clientId: "AcXdMumE7rwVOdOsWzYbUW1ITcGguP73jZKiDXf_kibYp1CzEEJKEU-Rwhhre8iIcIHhXqeZi539xdvA",
  secureMerchantAccountId: "9QKK4R3EVCYGU",
  serverName: "www.paypal.com",
  planConfigs: [/*
    To be configured in Paypal production account
      {
        planId: "P-6A7440045H446553NMDXKFSI",
        planTitle: "Basic Plan",
        planSubTitle: "30€"
      },{
        planId: "P-6HH464802G832890GMDXNJ2Y",
        planTitle: "Premium Plan",
        planSubTitle: "335€"
      },{
        planId: "P-37T45588KY053453GMDXNK7A",
        planTitle: "Enterprise Plan",
        planSubTitle: "420€"
      },*/
  ]
}
export {
  PPConfigProduction
}
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7229fff6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "podiumArea"
}
const _hoisted_2 = {
  key: 0,
  class: "subtitle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdDocViewTransactionFootStep = _resolveComponent("AdDocViewTransactionFootStep")!
  const _component_MiniProfileViewLink = _resolveComponent("MiniProfileViewLink")!

  return ( $props.aAdDoc.getADCatsInUnivers() === 'DefiPodometre')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($props.ADColPodiums && $props.ADColPodiums.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.t("podiumTitle")), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortAndAddFootStepsPodium($props.ADColPodiums), (aTransaction) => {
          return (_openBlock(), _createElementBlock("div", {
            key: aTransaction.ATId,
            class: "podiumItem"
          }, [
            _createElementVNode("span", null, [
              _createVNode(_component_AdDocViewTransactionFootStep, {
                aAdDoc: $props.aAdDoc,
                aAdTransaction: aTransaction,
                footPodium: aTransaction.ATPodium
              }, null, 8, ["aAdDoc", "aAdTransaction", "footPodium"]),
              _createVNode(_component_MiniProfileViewLink, {
                userMiniProfile: aTransaction.ATMUser,
                prefixTextId: '',
                displayAppBoss: false
              }, {
                default: _withCtx(() => [
                  _createTextVNode("ADColTransaction ")
                ]),
                _: 2
              }, 1032, ["userMiniProfile"])
            ])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
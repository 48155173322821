import { Store } from "vuex";
import { AppState } from "./appStore";


const commitErrorMsg = (appStore: any, result: any, unknownErrorMsg: string) => {
  // ATTENTION result.errorMsg can be an error id at this point so do no add test to it
  const errorMsg =  (result && result.errorMsg !== undefined ) ? (result.errorMsg ) : unknownErrorMsg
  console.error("commitErrorMsg " + errorMsg + " (" + unknownErrorMsg + ")")
  appStore.commit("setErrorMsg", {errorMsg})
};

const saveDomainResultsInStores = async (appStore: any, processAction: any, result: any, options: {
  processTag: string;
  validObj: any;
  unknownErrorMsg: string;
}) => {
  if (result && result.backendActionDone === true && processAction !== undefined) {
    if (options.processTag && options.processTag !== "") {
      await processAction(options.processTag, options.validObj)
    }
    if (options.validObj && options.validObj.notifMsg !== undefined && options.validObj.notifMsg !== "") {
      appStore.commit("setNotifMsg", {notifMsg: options.validObj.notifMsg})
    }
  } else {
    commitErrorMsg(appStore, result, options.unknownErrorMsg)
  }
  return (result !== undefined && result.backendActionDone)
};

const applyToStoreOneDomainResult = async (options: {
  processInputResult: any;
  appStore:  Store<AppState>;
  processAction: any;
  processActionTag: string;
  validObj: any;
  unknownErrorMsg: string;
}) => {
  if (options.validObj !== undefined) {
    options.validObj.appStore = options.appStore
  }
  if (options.processInputResult && options.processInputResult.validObj) {
    options.validObj = {
      ...options.processInputResult.validObj,
      ...options.validObj,
    }
  }

  if (options.processInputResult && options.processInputResult.backendActionDone === true) {
    if (options.processAction !== undefined && options.processActionTag && options.processActionTag !== "") {
      await options.processAction(options.processActionTag, options.validObj)
    }
    if (options.validObj && options.validObj.notifMsg !== undefined && options.validObj.notifMsg !== "") {
      options.appStore.commit("setNotifMsg", {notifMsg: options.validObj.notifMsg})
    }
  } else {
    if (options.processInputResult) {
      console.error("options.processInputResult " + JSON.stringify(options.processInputResult))
      console.error("options.unknownErrorMsg " + JSON.stringify(options.unknownErrorMsg))
      commitErrorMsg(options.appStore, options.processInputResult, options.unknownErrorMsg)
    }
  }
  return (options.processInputResult !== undefined && options.processInputResult.backendActionDone)
};
const applyToStoreDomainResults = async (rStoreProcessDefault: any, rStoreProcess: Array<any>) => {
  try {
    for (let aStoreProcess of rStoreProcess) {
      // rStoreProcess.forEach(async (aStoreProcess) => {
        aStoreProcess = {
          ...rStoreProcessDefault,
          ...aStoreProcess,
        }
        await applyToStoreOneDomainResult(aStoreProcess)
      } //)
  } catch(e) {
    const errorMsg = "Error in applyToStoreDomainResults " + e
    console.error("Error in applyToStoreDomainResults " + e)
    console.error("Error in applyToStoreDomainResults rStoreProcessDefault " + JSON.stringify(rStoreProcessDefault))
    console.error("Error in applyToStoreDomainResults rStoreProcess " + JSON.stringify(rStoreProcess))
    rStoreProcessDefault.appStore.commit("setErrorMsg", {errorMsg})

  }
}

const blockApplicationUI = async (options: any, processDoneDuringBlockingUI: any) => {
  options.appStore.commit("setBlockedApplicationCounter", {incrementCouter: true})
  const res = await processDoneDuringBlockingUI()
  // setTimeout(() => {
    options.appStore.commit("setBlockedApplicationCounter", {incrementCouter: false})
  //}, 60000)
  return res
}


export {
  commitErrorMsg,
  saveDomainResultsInStores,
  applyToStoreDomainResults,
  blockApplicationUI,
}

<template>
  <div v-if="location !== null && location !== undefined && location.LPLat !== undefined && location.LPLng !== undefined">
    <a @click.stop 
      target="_blank"
      :href="'https://www.google.com/maps/search/?api=1&query=' + location.LPLat +
        ',' + location.LPLng"
      :title="'lat/lng: ' + location.LPLat +
        ' - ' + location.LPLng +
        ' - ' + location.LPGeoHash">
      <span v-if="!location.LPAddress && !location.LPZipCode">{{ t("openLocationInMap") }}</span>
      <span v-else>{{ t("openLocationInMapShort") }}</span>
      <span v-if="location.LPAddress">{{ location.LPAddress }}</span>
      <span v-if="location.LPZipCode">&nbsp;{{ location.LPZipCode }}</span>
      <span v-if="location.LPCity">&nbsp;{{ location.LPCity }}</span>
    </a>
  </div>
  <div v-else-if="location !== null && location !== undefined && location.LPZipCode !== undefined">
    <a @click.stop 
      target="_blank"
      :href="'https://maps.google.com/maps?q=France+' + location.LPZipCode"
      :title="'Code Postal: ' + location.LPZipCode">
      {{ t("openZipCodeLocationInMap", {aZipCode: location.LPZipCode}) }}
      <span v-if="location.LPCity">&nbsp;{{ location.LPCity }}</span>
      <span v-if="location.LPAddress">&nbsp;{{ location.LPAddress }}</span>
    </a>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs, computed } from "vue";
import { UserProjectTypes } from "@/engineproject/default/common/domainModel/userProjectTypes";
import { useI18n } from "@/engineproject/default/common/i18n";

export default {
  props: {
    aLocation: {
      type: Object as () => UserProjectTypes.LocationPoint | undefined,
      required: false,
    },
  },
  setup(props: any, context: any) {
    const {t} = useI18n()
    const page = reactive({
      location: computed(() => props.aLocation),
    });
    return {
      ...toRefs(page),
      t,
    };
  },
};
</script>

import * as fb from './firebase'

import { UserProjectTypes } from "@/engineproject/default/common/domainModel/userProjectTypes"

import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes';

const docIdSepWithOwnerId = "__"

export interface AdDocTransactionsAPIClient {
  doClosingAccountTransactionsPart(options: any): Promise<any>;

  getMyActiveTransactionsWithAll(options: any): Promise<any>;

  getActiveTransactionsWithAContact(options: any): Promise<any>;

  addTransactionAdDocCreateStep(transactionsCol: any, aAdDoc: AdDocumentProjectObject, docId: string, 
    aUserMiniProfile: UserProjectTypes.UserMiniProfile, 
    addTransactionText: string): Promise<any>;

  removeMyTransactionAdDoc(aAdDoc: AdDocumentProjectObject, 
    aUserId: string): Promise<any>;

  updateTransactionAdDoc(updateParams: any, 
    aUserId: string): Promise<any>;

  updateFootStepCounterInTransactionAdDoc(updateParams: any, 
      aUserId: string) : Promise<any>;
  
  addTransactionAdDoc(aAdDoc: AdDocumentProjectObject,
    aUserMiniProfile: UserProjectTypes.UserMiniProfile,
    addTransactionText: string): Promise<any>;

  getMyTransactionsForAdDoc(ADId: string, ADOwnerId: string, 
    aUserId: string): Promise<any>;
  
  getAllTransactionsForAdDoc(optionsQuery: any): Promise<any>;

}

class AdDocTransactionsFirebase implements AdDocTransactionsAPIClient {
  async doClosingAccountTransactionsPart(options: { 
    trRequesterUserId: string; 
  }) {
    try {
      const adDocFBDs2 = await fb.db.collectionGroup("ADColTransactions").
      where("ATMUser.UMUserId", "==", options.trRequesterUserId).
      // where("ATStatus", "in", ["A", "F  "]).
      get();
      const batch = fb.db.batch();
      adDocFBDs2.forEach(async (doc: any) => {
        batch.delete(doc.ref);
        // await doc.ref.delete()
      })
      await batch.commit();
      console.log("doClosingAccountTransactionsPart Finished async");
    } catch (e) {
      const errorMsg = "error doClosingAccountTransactionsPart " + e
      console.error(errorMsg);
      return {
        backendActionDone: false,
        errorMsg
      }
    }
    return {
      backendActionDone: true,
      errorMsg: ""
    }
  }

  async getMyActiveTransactionsWithAll(options: { 
    trRequesterUserId: string; 
  }) {
    let oneError = ""
    const foundData = {
      trRequesterUserId: options.trRequesterUserId,
      listOfAdDocs: [] as any
    }
    const recordOneDoc = async (doc: any, foundData: any) => {
      try {
        console.error("getMyActiveTransactionsWithAll " + doc.id);
        const aTR = doc.data();
        if (aTR !== undefined) {
          const aDoc = doc.ref.parent.parent
          if (aDoc !== null) {
            const aDocRef = await aDoc.get()
            const dataDoc = aDocRef.data()
              if (dataDoc !== undefined) {
              foundData.listOfAdDocs.push({
                adDocIdInList: aDoc!.id,
                adDocTitleInList: dataDoc.ADTitle, //JSON.stringify(dataDoc)
              })
            }
          }
        }
      } catch (e) {
        const errorMsg = "error getMyActiveTransactionsWithAll " + e
        console.error(errorMsg);
        oneError = errorMsg
      }
  }
    try {
      const adDocFBDs2 = await fb.db.collectionGroup("ADColTransactions").
      where("ATMUser.UMUserId", "==", options.trRequesterUserId).
      where("ATStatus", "in", ["A", "F  "]).
      get();
      
      for (const doc of adDocFBDs2.docs){
        await recordOneDoc(doc,foundData)
      }
      console.log("Finished async");
    } catch (e) {
      const errorMsg = "error getMyActiveTransactionsWithAll " + e
      console.error(errorMsg);
      return {
        backendActionDone: false,
        activeTransactionsWithAContact: foundData,
        errorMsg
      }
    }
    if (oneError !== "") {
      return {
        backendActionDone: false,
        activeTransactionsWithAContact: foundData,
        errorMsg: oneError
      }
    }
    return {
      backendActionDone: true,
      activeTransactionsWithAContact: foundData,
      errorMsg: ""
    }
  }

  async getActiveTransactionsWithAContact(options: { 
    trDocOwnerUserId: string; 
    trRequesterUserId: string; 
  }) {
    let oneError = ""
    const foundData = {
      trDocOwnerUserId: options.trDocOwnerUserId,
      trRequesterUserId: options.trRequesterUserId,
      listOfAdDocs: [] as any
    }
    const recordOneDoc = async (doc: any, foundData: any) => {
      // TODO Cleanup when all doc id will use the correct format
      // UserId + "__" + timestamp
      // Optimized function from docid to verify the owner id
      const getAdDocIfOwner = async (aDoc: any, aUserId: string) => {
        if (aDoc.id.startsWith(aUserId + docIdSepWithOwnerId) === true) {
          const aDocRef = await aDoc.get()
          const dataDoc = aDocRef.data()
          return dataDoc
        } else if (aDoc.id.indexOf(docIdSepWithOwnerId) !== -1) {
          return undefined
        } else {
          const aDocRef = await aDoc.get()
          const dataDoc = aDocRef.data()
          if (dataDoc.ADOwnerMiniProfile.UOUserId === aUserId) {
            return dataDoc
          }
          return undefined
        }
      }
      try {
        const aTR = doc.data();
        if (aTR !== undefined) {
          const aDoc = doc.ref.parent.parent
          if (aDoc !== null) {
            const dataDoc = await getAdDocIfOwner(aDoc, options.trDocOwnerUserId)
            if (dataDoc !== undefined) {
              foundData.listOfAdDocs.push({
                adDocIdInList: aDoc!.id,
                adDocTitleInList: dataDoc.ADTitle, //JSON.stringify(dataDoc)
              })
            }
          }
        }
      } catch (e) {
        const errorMsg = "error getActiveTransactionsWithAContact " + e
        console.error(errorMsg);
        oneError = errorMsg
      }
  }
    try {
      const adDocFBDs2 = await fb.db.collectionGroup("ADColTransactions").
      where("ATMUser.UMUserId", "==", options.trRequesterUserId).
      where("ATStatus", "in", ["A", "F  "]).
      get();
      
      for (const doc of adDocFBDs2.docs){
        await recordOneDoc(doc,foundData)
      }
      
      /*
      https://dev.to/gautemeekolsen/til-firestore-get-collection-with-async-await-a5l
      https://advancedweb.hu/how-to-use-async-functions-with-array-foreach-in-javascript/
      DOES NOT WORK await issue in forEach :
      adDocFBDs2.forEach(async (doc) => {
        await recordOneDoc(doc,foundData)
  
  
      });
      */
      console.log("Finished async");
    } catch (e) {
      const errorMsg = "error getActiveTransactionsWithAContact " + e
      console.error(errorMsg);
      return {
        backendActionDone: false,
        activeTransactionsWithAContact: foundData,
        errorMsg
      }
    }
    if (oneError !== "") {
      return {
        backendActionDone: false,
        activeTransactionsWithAContact: foundData,
        errorMsg: oneError
      }
    }
    return {
      backendActionDone: true,
      activeTransactionsWithAContact: foundData,
      errorMsg: ""
    }
  }


  addTransactionAdDocCreateStep(transactionsCol: any, aAdDoc: AdDocumentProjectObject, docId: string, 
    aUserMiniProfile: UserProjectTypes.UserMiniProfile, 
    //userProfile: UserProjectTypes.UserProfile, 
    addTransactionText: string) {
  const newTransaction = {
    ATId: docId,
    ATCreatedOn: new Date(),
    ATStatus: (aAdDoc.isFreeSubscription() === true) ? 'F' : 'P', // Libre or Pending
    ATMUser: aUserMiniProfile,
    //ATMUser: getMiniProfile(userProfile),
    ... (addTransactionText !== undefined && addTransactionText !== "") && {ATComment: addTransactionText}
  }
  return transactionsCol.doc(docId).set(newTransaction).then(() => {
    return {
      backendActionDone: true,
      addedTransaction: newTransaction,
      errorMsg: undefined
    }
  }, (reason: any) => {
    console.error("error addTransactionAdDocCreateStep" + reason)
    return {
      backendActionDone: false,
      addedTransaction: undefined,
      errorMsg: "addTransactionErrorDocNotFoundOrNotAuthorized"
    }
  });
  }

  async removeMyTransactionAdDoc(aAdDoc: AdDocumentProjectObject, 
    aUserId: string) {
    //  userProfile: UserProjectTypes.UserProfile) {
    const removeTransactionDoc = async (transactionsCol: any, docId: string) => {
      const transactionDoc = transactionsCol.doc(docId)
      //const transactionDocs = await transactionDoc.get()
      return await transactionDoc.get().then(async (transactionDocs: any) => {
        if (transactionDocs.exists) {
          // console.error("removeMyTransactionAdDoc 22")
          const removedTransactionData = transactionDocs.data()
          return await transactionDoc.delete().then(async () => {
            // console.log("Transaction successfully deleted!");
            return {
              backendActionDone: true,
              removedTransactionId: docId,
              removedTransactionData,
              errorMsg: undefined
            }
          }).catch((error: any) => {
            console.error("Error removing transaction: " + error);
            console.dir(error)
            //  + error.message + error.code
            return {
              backendActionDone: false,
              removedTransactionId: undefined,
              removedTransactionData: undefined,
              errorMsg: "Error removing transactionin  document: " + error.message,
            }
          });
        } else {
          console.error("removeMyTransactionAdDoc 33")
          return {
            backendActionDone: false,
            removedTransactionId: undefined,
            removedTransactionData: undefined,
            errorMsg: "Transaction doesn't exist, so it cannot be removed"
          }
        }
      })
    }
    try {
      // console.error("removeMyTransactionAdDoc")
      if (fb.auth === null || fb.auth.currentUser === null ||
        fb.auth.currentUser.uid !== aUserId) {
        //  fb.auth.currentUser.uid !== userProfile.UPUserId) {
            console.error("removeMyTransactionAdDoc 11")
        return {
          backendActionDone: false,
          removedTransactionId: undefined,
          removedTransactionData: undefined,
          errorMsg: "Not connected or invalid userids in removeMyTransactionAdDoc ..."
        }
      }
      const userId = fb.auth.currentUser.uid
      // const docId = `${userId}_${aAdDoc.getADId()}`
      const docId = userId + docIdSepWithOwnerId + aAdDoc.getOwnerUserId() + docIdSepWithOwnerId + aAdDoc.getADId()

      const transactionsCol = fb.adDocCollection.doc(aAdDoc.getADId()).collection('ADColTransactions')
      const retRemove = await removeTransactionDoc(transactionsCol, docId)
      // TODO remove old id process after migration
      if (retRemove.backendActionDone === false) {
        // try with old id
        const docId = `${userId}_${aAdDoc.getADId()}`
        return await removeTransactionDoc(transactionsCol, docId)
      } else {
        return retRemove
      }
      /*
      const transactionDoc = transactionsCol.doc(docId)
      const transactionDocs = await transactionDoc.get()
      if (transactionDocs.exists) {
        // console.error("removeMyTransactionAdDoc 22")
        const removedTransactionData = transactionDocs.data()
        return await transactionDoc.delete().then(async () => {
          // console.log("Transaction successfully deleted!");
          return {
            backendActionDone: true,
            removedTransactionId: docId,
            removedTransactionData,
            errorMsg: undefined
          }
          }).catch((error) => {
          console.error("Error removing transaction: " + error);
          console.dir(error)
          //  + error.message + error.code
          return {
            backendActionDone: false,
            removedTransactionId: undefined,
            removedTransactionData: undefined,
            errorMsg: "Error removing transactionin  document: " + error.message,
          }
        });
      } else {
        console.error("removeMyTransactionAdDoc 33")
        return {
          backendActionDone: false,
          removedTransactionId: undefined,
          removedTransactionData: undefined,
          errorMsg: "Transaction doesn't exist, so it cannot be removed"
        }
      }*/

    } catch (e) {
      console.error("error addTransactionAdDoc ")
      console.error("error addTransactionAdDoc " + e)
      return {
        backendActionDone: false,
        removedTransactionId: undefined,
        removedTransactionData: undefined,
        errorMsg: e + ""
      }
    }
  }

  async updateTransactionAdDoc(updateParams: any, 
    aUserId: string) {
    //  userProfile: UserProjectTypes.UserProfile) {
        try {
      // console.error("updateTransactionAdDoc")
      if (fb.auth === null || fb.auth.currentUser === null ||
        fb.auth.currentUser.uid !== aUserId) {
        //  fb.auth.currentUser.uid !== userProfile.UPUserId) {
            console.error("updateTransactionAdDoc 11")
        return {
          backendActionDone: false,
          updatedTransaction: undefined,
          errorMsg: "Not connected or invalid userids in updateTransactionAdDoc..."
        }
      }
      const docId = updateParams.aTransaction.ATId
      const updatedTransaction = {
        ...updateParams.aTransaction,
        ATStatus: updateParams.newStatus
      }
      // check if user has already liked post
      const transactionsCol = fb.adDocCollection.doc(updateParams.aAdDoc.getADId()).collection('ADColTransactions')
      const transactionDoc = transactionsCol.doc(docId)
      const transactionDocs = await transactionDoc.get()
      if (transactionDocs.exists) {
        // console.error("updateTransactionAdDoc 22")
        return await transactionDoc.update(updatedTransaction).then(async () => {
          // console.log("Transaction successfully updated!");
          return {
            backendActionDone: true,
            updatedTransaction: updatedTransaction,
            errorMsg: undefined
          }
          }).catch((error) => {
          console.error("Error removing transaction: " + error);
          console.dir(error)
          //  + error.message + error.code
          return {
            backendActionDone: false,
            updatedTransaction: undefined,
            errorMsg: "Error removing transactionin  document: " + error.message,
          }
        });
        } else {
        console.error("updateTransactionAdDoc 33")
        return {
          backendActionDone: false,
          updatedTransaction: undefined,
          errorMsg: "Transaction doesn't exist, so it cannot be removed"
        }
      }

    } catch (e) {
      console.error("error addTransactionAdDoc ")
      console.error("error addTransactionAdDoc " + e)
      return {
        backendActionDone: false,
        updatedTransaction: undefined,
        errorMsg: e + ""
      }
    }
  }

  async updateFootStepCounterInTransactionAdDoc(updateParams: any, 
    aUserId: string) {
    //  userProfile: UserProjectTypes.UserProfile) {
        try {
      console.error("updateFootStepCounterInTransactionAdDoc")
      if (fb.auth === null || fb.auth.currentUser === null ||
        fb.auth.currentUser.uid !== aUserId) {
        //  fb.auth.currentUser.uid !== userProfile.UPUserId) {
            console.error("updateFootStepCounterInTransactionAdDoc 11")
        return {
          backendActionDone: false,
          updatedTransaction: undefined,
          errorMsg: "Not connected or invalid userids in updateFootStepCounterInTransactionAdDoc..."
        }
      }
      const docId = updateParams.aTransaction.ATId
      const updatedTransaction = {
        ...updateParams.aTransaction,
        ATFootStepCount: updateParams.newFootStepCount
      }
      const transactionsCol = fb.adDocCollection.doc(updateParams.aAdDoc.getADId()).collection('ADColTransactions')
      const transactionDoc = transactionsCol.doc(docId)
      const transactionDocs = await transactionDoc.get()
      if (transactionDocs.exists) {
        console.error("updateFootStepCounterInTransactionAdDoc 22")
        console.error("updateFootStepCounterInTransactionAdDoc 22 docId " + docId)
        console.error("updateFootStepCounterInTransactionAdDoc 22 updateParams.aAdDoc.getADId() " + updateParams.aAdDoc.getADId())
        return await transactionDoc.update(updatedTransaction).then(async () => {
          console.log("Transaction successfully updated!");
          return {
            backendActionDone: true,
            updatedTransaction: updatedTransaction,
            errorMsg: undefined
          }
          }).catch((error) => {
            console.error("Error updateFootStepCounterInTransactionAdDoc: " + error);
            console.dir(error)
            //  + error.message + error.code
            return {
              backendActionDone: false,
              updatedTransaction: undefined,
              errorMsg: "Error updateFootStepCounterInTransactionAdDoc  document: " + error.message,
            }
          });
        } else {
        console.error("updateFootStepCounterInTransactionAdDoc 33")
        return {
          backendActionDone: false,
          updatedTransaction: undefined,
          errorMsg: "Transaction doesn't exist, so it cannot be updated"
        }
      }

    } catch (e) {
      console.error("error updateFootStepCounterInTransactionAdDoc ")
      console.error("error updateFootStepCounterInTransactionAdDoc " + e)
      return {
        backendActionDone: false,
        updatedTransaction: undefined,
        errorMsg: e + ""
      }
    }
  }

  async addTransactionAdDoc(aAdDoc: AdDocumentProjectObject,
    aUserMiniProfile: UserProjectTypes.UserMiniProfile,
    addTransactionText: string) {
    try {
      if (fb.auth === null || fb.auth.currentUser === null ||
        fb.auth.currentUser.uid !== aUserMiniProfile.UMUserId) {
        return {
          backendActionDone: false,
          addedTransaction: undefined,
          errorMsg: "Not connected or invalid userids in addTransactionAdDoc ..."
        }
      }
      const userId = fb.auth.currentUser.uid
      const docId = userId + docIdSepWithOwnerId + aAdDoc.getOwnerUserId() + docIdSepWithOwnerId + aAdDoc.getADId()

      const transactionsCol = fb.adDocCollection.doc(aAdDoc.getADId()).collection('ADColTransactions')
      const transactionDocs = await transactionsCol.doc(docId).get()
      if (transactionDocs.exists) {
        console.error("addTransactionAdDoc 22")
        return {
          backendActionDone: false,
          addedTransaction: undefined,
          errorMsg: "Transaction already added"
        }
      }
      return this.addTransactionAdDocCreateStep(transactionsCol, aAdDoc, docId,
        aUserMiniProfile,
        addTransactionText)

    } catch (e) {
      console.error("error addTransactionAdDoc ")
      console.error("error addTransactionAdDoc " + e)
      return {
        backendActionDone: false,
        addedTransaction: undefined,
        errorMsg: e + ""
      }
    }
  }

  async getMyTransactionsForAdDoc(ADId: string, ADOwnerId: string, 
    aUserId: string /*userProfile: UserProjectTypes.UserProfile*/) {
    const res = Array<any>();
    console.error("getMyTransactionsForAdDoc ADId " + ADId)
    console.error("getMyTransactionsForAdDoc ADOwnerId " + ADOwnerId)
    console.error("getMyTransactionsForAdDoc aUserId " + aUserId)
    try {
      // console.error("getMyTransactionsForAdDoc")
      if (fb.auth === null || fb.auth.currentUser === null ||
        fb.auth.currentUser.uid !== aUserId) {
          // console.error("getMyTransactionsForAdDoc aUserId " + aUserId)
          // console.error("getMyTransactionsForAdDoc fb.auth.currentUser.uid " + fb.auth?.currentUser?.uid)
        // fb.auth.currentUser.uid !== userProfile.UPUserId) {
          return {
          backendActionDone: false,
          errorMsg: "Not connected or invalid userids in getMyTransactionsForAdDoc ...",
          aADColTransactions: Array<any>()
        }
      }
      const userId = fb.auth.currentUser.uid
      // const docId = `${userId}_${ADId}`
      const docId = userId + docIdSepWithOwnerId + ADOwnerId + docIdSepWithOwnerId + ADId

      const transactionsCol = fb.adDocCollection.doc(ADId).collection('ADColTransactions')
      const transactionDocs = await transactionsCol.doc(docId).get()
      if (transactionDocs.exists) {
        // .error("getMyTransactionsForAdDoc 22")
        const transaction: any = transactionDocs.data()
        transaction.ATId = transactionDocs.id
        res.push(transaction)
      } else {

        // TODO remove after migration
        const docIdOLDFORMATTODO = `${userId}_${ADId}`
        const transactionDocsTODO = await transactionsCol.doc(docIdOLDFORMATTODO).get()
        if (transactionDocsTODO.exists) {
          // .error("getMyTransactionsForAdDoc 22")
          const transaction: any = transactionDocsTODO.data()
          transaction.ATId = transactionDocsTODO.id
          res.push(transaction)
        }
        
      }
      // console.error("getMyTransactionsForAdDoc res " + JSON.stringify(res))


      return {
        backendActionDone: true,
        errorMsg: undefined,
        aADColTransactions: res
      }

    } catch (e) {
      console.error("error getMyTransactionsForAdDoc ")
      console.error("error getMyTransactionsForAdDoc " + e)
      return {
        backendActionDone: false,
        errorMsg: e + ""
      }
    }
  }

  async getAllTransactionsForAdDoc(optionsQuery: any) {
    const res = Array<any>();
    try {
      const docs = await fb.adDocCollection.doc(optionsQuery.ADId).collection('ADColTransactions').get()
      docs.forEach((doc: any) => {
        const transaction: any = doc.data()
        transaction.ATId = doc.id
        res.push(transaction)
      })
    } catch (e) {
      // console.error("error getAllTransactionsForAdDoc ")
      // console.error("error getAllTransactionsForAdDoc " + e)
      return {
        backendActionDone: false,
        errorMsg: e + "",
        aADColTransactions: Array<any>()
      }
    }
    console.error("getAllTransactionsForAdDoc res " + res.length)
    console.error("getAllTransactionsForAdDoc res " + JSON.stringify(res))
    return {
      backendActionDone: true,
      errorMsg: undefined,
      aADColTransactions: res
    }
  }

}

export default AdDocTransactionsFirebase

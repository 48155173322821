<template>
  <div v-if="canUDisplayFootSteps(aAdTransaction)" class="footStepsArea">
    <div>
      <span v-if="footPodium" class="podium">{{ footPodium }} <span class="pi pi-flag"></span> , </span>
    
      {{ displayFootStepCounter(aAdTransaction.ATFootStepCount) }}
    </div>
    <div v-if="canUpdateFootSteps(aAdTransaction)" class="footStepUpdate">
      <GoogleAPIStepsCounter @newFootStepsValueFromGoogleAPI="updateFootStep"
        :startTime="aAdDoc.getValueADDateHourStartsOn()"
        :endTime="aAdDoc.getValueADDateHourEndsOn()"
        ></GoogleAPIStepsCounter>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, inject, watch } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";
import { useDocumStore } from '@/engineproject/store/documStore';
import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes';
import { useAppStore } from '@/engineproject/store/appStore';
import { useUserStore } from '@/engineproject/store/userStore';

import GoogleAPIStepsCounter from '@/engineproject/components/GoogleAPI/StepsCounter.vue';
import { UserProfileProjectObject } from "@/engineproject/default/common/domainModel/userProfileProjectToolsTypes";
import { AdDocumentProjectTypes } from "@/engineproject/default/common/domainModel/adDocumentProjectTypes";

export default {
  props: {
    aAdTransaction: {
      type: Object as () => any,
      required: false,
    },
    aAdDoc: {
      type: Object as () => Array<any>,
      required: true,
    },
    footPodium: {
      type: Number,
      required: false
    }
  },
  setup(props: any, context: any) {
    const formatDate = inject('formatDate') as any;
    const documStore = useDocumStore();
    const { t } = useI18n();
    const appStore = useAppStore();
    const userStore = useUserStore();
    
    const canUDisplayFootSteps = (aTransaction: AdDocumentProjectTypes.AdTransaction) => {

      return (aTransaction !== undefined &&
        (aTransaction.ATStatus === 'A' || aTransaction.ATStatus === 'F') &&
        props.aAdDoc.getADCatsInUnivers() === 'DefiPodometre')
    }
    
    const canUpdateFootSteps = (aTransaction: AdDocumentProjectTypes.AdTransaction) => {
      const aUserProfile = userStore.state.aUserProfile as UserProfileProjectObject

      return aTransaction.ATMUser.UMUserId === aUserProfile.getUPUserId()
    }


    const displayFootStepCounter = (footStep: number) => {
      if (!footStep) {
        footStep = 0
      }

      return t("transactionFootStepTitle", {theFootStepCount : footStep.toLocaleString("fr-FR") }, footStep)
    }


    const viewUserProfile = (aUserId: string) => {
      appStore.commit("setInModalUserProfileId", {inModalUserProfileId: aUserId});
    }



    const updateFootStep = async (param: {newFootStepsFromGoogleAPI: number}) => {
      if (props.aAdDoc === undefined) {
        return;
      }
      if (props.aAdTransaction === undefined) {
        return;
      }
      if (param.newFootStepsFromGoogleAPI === undefined) {
        return;
      }
      const updateParams = {
        aTransaction: props.aAdTransaction,
        aAdDoc: props.aAdDoc,
        newFootStepCount: (param.newFootStepsFromGoogleAPI) ? param.newFootStepsFromGoogleAPI : 0
      }
      const result = await documStore.dispatch("updateFootStepCounterInTransactionAdDoc", {
        updateParams,
        appStore: appStore,
        userStore: userStore,
      });
    };

    return {
      t,
      displayFootStepCounter,
      formatDate,
      updateFootStep,
      viewUserProfile,
      canUDisplayFootSteps,
      canUpdateFootSteps,
    };
  },
  components: {
    GoogleAPIStepsCounter,
  },
};
</script>
<style lang="scss" scoped>

.pi-pencil {
  cursor: pointer;

}
.podium {
  color: green
}
.footStepsArea{
  display: flex;
  > * {
    flex: 1 1 90%;
  }
  .footStepUpdate {
    text-align: right;
    padding-right: 10px;
    flex: 1 1 10% !important;

  }

}
</style>

<template>
  <a v-if="userCanEdit === true" @click="removeAdDocument()" class="removeAdDocument profilMenu"
    data-cy="cyRemoveAdDocument"
    :class="userIsOwner === false ? 'adminSuperPower' : ''">
    <div class="pi pi-trash" ></div>
    <div>{{ t("removeAdDocument") }}</div>
  </a>
  <a v-if="userCanEdit === true" @click="editAdDocument()" class="editAdDocument profilMenu"
    :class="userIsOwner === false ? 'adminSuperPower' : ''">
    <div class="pi pi-pencil" ></div>
    <div>{{ t("editAdDocument") }}</div>
  </a>
  <a v-if="1 === 2 && userCanEdit === true" @click="copyAdDocument()" class="editAdDocument profilMenu"
    :class="userIsOwner === false ? 'adminSuperPower' : ''">
    <div class="pi " ></div>
    <div>{{ t("copyAdDocument") }}</div>
  </a>
  <a  v-if="displayInModal === true" @click="maximizeAdDocument()" class="editAdDocument profilMenu"
     data-cy="cyMaximizeAdDocument" >
    <div class="pi pi-window-maximize" ></div>
    <div>{{ t("maximizeAdDocument") }}</div>
  </a>
  <a v-if="userIsOwner !== true" 
    @click="openReportModal(aAdDoc)" 
    class="reportAdDocument profilMenu">
    <div class="pi pi-exclamation-triangle" ></div>
    <div>{{ t("reportAdDocument") }}</div>
  </a>
  <a v-if="displayInModal === true"  @click="$emit('closeAdDocument')" 
    data-cy="cyCloseAdDocument" class="closeAdDocument profilMenu">
    <div class="pi pi-times" ></div>
    <div>{{ t("closeAdDocument") }}</div>
  </a>
</template>

<script lang="ts">
import { AdDocumentProjectTypes } from '@/engineproject/default/common/domainModel/adDocumentProjectTypes';


import { reactive, toRefs } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";
import { useDocumStore } from '@/engineproject/store/documStore';
import router from '@/router';
import { adDocCollection, adSugCollection } from '@/engineproject/backend/firebase';
import { useAppStore } from '@/engineproject/store/appStore';
import { useUserStore } from '@/engineproject/store/userStore';
import { editAdDocOptionsType, removeAdDocOptionsType } from '@/engineproject/store/documStoreTypes';
import { useFilterAttributesStore } from '@/engineproject/store/filterAttributesStore';

export default {
  emits: ["closeAdDocument", "closeMapModal"],
  props: {
    userCanEdit: {
      type: Boolean,
      required: true,
    },
    displayInModal: {
      type: Boolean,
      required: true,
    },
    userIsOwner: {
      type: Boolean,
      required: true,
    },
    aAdDoc: {
      type: Object as () => AdDocumentProjectTypes.AdDocument,
      required: true,
    },
  },

  setup(props: any, context: any) {
    const filterAttributesStore = useFilterAttributesStore();
    const documStore = useDocumStore();
    const appStore = useAppStore();
    const userStore = useUserStore();
    const {t} = useI18n()
    const page = reactive({
    });

    const copyDoc  = async (
      collectionFrom: string,
      docId: string,
      collectionTo: string,
      addData: any = {},
    ): Promise<boolean> => {
      const docRef = adDocCollection.doc(docId);
      const docData = await docRef
        .get()
        .then((doc) => doc.exists && doc.data())
        .catch((error) => {
          console.error('Error reading document', `${collectionFrom}/${docId}`, JSON.stringify(error));
        });

      if (docData) {
        await adSugCollection
          .doc(docId)
          .set({ ...docData, ...addData })
          .catch((error) => {
            console.error('Error creating document', `${collectionTo}/${docId}`, JSON.stringify(error));
          });

        return true;
      }
      return false;
    }
    const copyAdDocument = async () => {
      const collectionFrom ="/adDocuments"
      const docId = props.aAdDoc.OADData.ADId
      const collectionTo ="/adSuggestions"
      await copyDoc(
        collectionFrom,
        docId,
        collectionTo,
        {}
      )  
    }

    const openReportModal = async (aAdDoc: AdDocumentProjectTypes.AdDocument) => {
      appStore.commit("setInModalAdDocument", {inModalAdDocument: aAdDoc});
      appStore.commit("setShowReportModal", {showReportModal: true});
    }

    const editAdDocument = async () => {
      await documStore.dispatch("editAdDocFromUser", {
        appStore,
        userStore,
        aAdDoc: props.aAdDoc
      } as  editAdDocOptionsType);
    }

    const removeAdDocument = async () => {
      await documStore.dispatch("removeAdDocFromUser", { // removeAdDoc
        appStore,
        userStore,
        filterAttributesStore,
        cleanUpEmptyDoc: false
      } as  removeAdDocOptionsType);
    }

    const maximizeAdDocument = () => {
      context.emit('closeMapModal')
      router.push(props.aAdDoc.getADDocUrlRoute())
    }

    return { 
      ...toRefs(page),
      t,
      maximizeAdDocument,
      copyAdDocument,
      removeAdDocument,
      editAdDocument,
      openReportModal
    };
  },
};
</script>

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "imagesContainer"
}
const _hoisted_2 = ["src", "title"]
const _hoisted_3 = {
  key: 1,
  class: "listOfImages"
}
const _hoisted_4 = ["href", "title"]
const _hoisted_5 = ["src", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.listOfImages !== undefined)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([{ 'firstImageOnly': $props.firstImageOnly }, "imagesContainerOLD listOfImagesContainer"])
      }, [
        ($props.firstImageOnly === true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              ($props.listOfImages && $props.listOfImages[0])
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "firstImageOnly",
                    src: $props.listOfImages[0].AADDocumentUrl,
                    title: $props.listOfImages[0].AADDocumentName
                  }, null, 8, _hoisted_2))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.listOfImages, (existingImg) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: existingImg.AADDocumentUrl,
                  class: "imagesContainer"
                }, [
                  _createElementVNode("a", {
                    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                    target: "_blank",
                    class: "nogreenlink",
                    href: existingImg.AADDocumentUrl,
                    title: existingImg.AADDocumentName
                  }, [
                    _createElementVNode("img", {
                      src: existingImg.AADDocumentUrl,
                      title: existingImg.AADDocumentName
                    }, null, 8, _hoisted_5)
                  ], 8, _hoisted_4)
                ]))
              }), 128))
            ]))
      ], 2))
    : _createCommentVNode("", true)
}
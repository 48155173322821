{
  "enterpriseRemovedFromAllowed": "L'entreprise a été enlevée de la liste des entreprises autorisées",
  "enterpriseAllowed": "L'entreprise a été ajoutée à la liste des entreprises autorisées",
  "enterpriseAllowedList": "L'utilisateur (partenaire) est autorisé à poster des annonces pour les entreprises cochées ci-dessous:",
  "enterpriseExternTitle": "L'utilisateur (courtier/représentant) est autorisé à poster et voir les annonces comme si il était salarié de l'entreprise suivante:",
  "withoutEnterprise": "Sans entreprise",
    "addNewEnterpriseButton": "Ajouter une entreprise",
    "enterEnterpriseName": "Saisissez le nom de la nouvelle entreprise:",
    "enterEnterpriseEmailDomain": "Saisissez le domaine des emails de l'entreprise (par exemple {'@'}pagepersonnel.fr ):"
  }
  
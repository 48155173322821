<template>
  <div class="profileListViewItemLeftPart">
    <div>
      <button class="button" 
        data-cy="cyProfileActionInList"
        @click="invitationProfileActionInList(aUserProfile)"
        >
        {{ t(profileActionInListButtonText) }}
      </button>
    </div>
    <div>

      <div @click="viewUserProfile(aUserProfile.getUPUserId())"
                :title="t('viewProfileTitle')"
                >
        <span class="pi pi-eye"></span>
    </div>
    </div>

  </div>
</template>

<script lang="ts">
import { reactive, toRefs } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { UserProfileProjectObject } from '@/engineproject/default/common/domainModel/userProfileProjectToolsTypes';
import { useAppStore } from '@/engineproject/store/appStore';
import { useUserStore } from '@/engineproject/store/userStore';
import { useDocumStore } from '@/engineproject/store/documStore';
import { AdDocumentProjectTypes } from '@/engineproject/default/common/domainModel/adDocumentProjectTypes';

export default {
  props: {
    aAdDoc: {
      type: Object as () => AdDocumentProjectTypes.AdDocument,
      required: true,
    },
    profileActionInListButtonText: {
      type: String,
      required: false,
      default: "sendInvitationButtonText"
    },
    aUserProfile: {
      type: Object as () => UserProfileProjectObject,
      required: true
    },
  },

  setup(props: any, context: any) {
    const {t} = useI18n()
    const appStore = useAppStore();
    const userStore = useUserStore();
    const documStore = useDocumStore();
    const page = reactive({
    });
    const invitationProfileActionInList = async (anInvitedUserProfile: any) => {
      await documStore.dispatch("ownerAddInvitationAdDoc", {
        aAdDoc: props.aAdDoc,
        appStore: appStore,
        userStore: userStore,
        addInvitationText: "",
        anInvitedUserProfile
      });
    }
    const viewUserProfile = (aUserId: string) => {
      appStore.commit("setInModalUserProfileId", {inModalUserProfileId: aUserId});
    }


    return {
      ...toRefs(page),
      t,
      invitationProfileActionInList,
      viewUserProfile,
    }
  },
  components: {
  },
}
</script>

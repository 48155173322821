import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "partnerPromotionViewContent"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DisplayPostersInPage = _resolveComponent("DisplayPostersInPage")!

  return ($props.aAdDoc !== undefined && $props.aAdDoc.canBeSharedAndGetPromotion() === true)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_DisplayPostersInPage, {
          subpageSizeA: "6",
          dyncomp: $setup.dyncompPosterAdDocument,
          posterElementBaseId: "PosterAdDocument",
          dyncompData: {aAdDoc: $props.aAdDoc}
        }, null, 8, ["dyncomp", "dyncompData"])
      ]))
    : _createCommentVNode("", true)
}
<template>
  <div
    v-if="listOfImages !== undefined"
    :class="{ 'firstImageOnly': firstImageOnly }" class="imagesContainerOLD listOfImagesContainer"
    >
    <div v-if="firstImageOnly === true"  class="imagesContainer">
          <img v-if="listOfImages && listOfImages[0]"
            class="firstImageOnly"
            :src="listOfImages[0].AADDocumentUrl"
            :title="listOfImages[0].AADDocumentName"
          />
    </div>
    <div v-else  class="listOfImages">
      <div v-for="existingImg in listOfImages" :key="existingImg.AADDocumentUrl"  class="imagesContainer">
        <a @click.stop  target="_blank" class="nogreenlink"
            :href="existingImg.AADDocumentUrl"
            :title="existingImg.AADDocumentName">
          <img
            :src="existingImg.AADDocumentUrl"
            :title="existingImg.AADDocumentName"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs } from "vue";
import { AdDocumentProjectTypes } from '@/engineproject/default/common/domainModel/adDocumentProjectTypes';

export default {
  props: {
    listOfImages: {
      type: Object as () => Array<AdDocumentProjectTypes.AdAttachedDocument>,
      required: false,
    },
    firstImageOnly: {
      type: Boolean,
      required: false
    }
  },
  setup(props: any, context: any) {
    const page = reactive({
      comment: "",
    });

    return {
      ...toRefs(page),
    };
  },
};
</script>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-cy": "cyTransactionsLoaded"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 1,
  class: "transactionArea"
}
const _hoisted_4 = {
  key: 0,
  "data-cy": "cyTransactionsLoaded"
}
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdDocViewTransactionsAcceptRefuseButtons = _resolveComponent("AdDocViewTransactionsAcceptRefuseButtons")!
  const _component_AdDocViewTransactionsEmpty = _resolveComponent("AdDocViewTransactionsEmpty")!
  const _component_AdDocViewTransactionsRemoveButton = _resolveComponent("AdDocViewTransactionsRemoveButton")!
  const _component_AdDocViewTransactionsAddButton = _resolveComponent("AdDocViewTransactionsAddButton")!

  return ($props.userCanEdit === true)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([$props.userIsOwner === false ? 'adminSuperPower' : '', "transactionArea"])
      }, [
        ($props.ADColTransactions !== undefined && $props.ADColTransactions.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_AdDocViewTransactionsAcceptRefuseButtons, {
                aAdDoc: $props.aAdDoc,
                ADColTransactions: $props.ADColTransactions
              }, null, 8, ["aAdDoc", "ADColTransactions"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_AdDocViewTransactionsEmpty, { aAdDoc: $props.aAdDoc }, null, 8, ["aAdDoc"])
            ]))
      ], 2))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        ($props.ADColTransactions !== undefined && $props.ADColTransactions.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_AdDocViewTransactionsRemoveButton, {
                aAdDoc: $props.aAdDoc,
                ADColTransactions: $props.ADColTransactions
              }, null, 8, ["aAdDoc", "ADColTransactions"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_AdDocViewTransactionsAddButton, { aAdDoc: $props.aAdDoc }, null, 8, ["aAdDoc"])
            ]))
      ]))
}
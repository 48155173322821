<template>
  <div class="ownerProfileViewLink">
    <ProfileViewLink :userId="ownerMiniProfile.UOUserId"
      :userDisplayedName="userDisplayedName"
      :userDisplayedDescription="userDisplayedDescription"
      :displayAppBoss="displayAppBoss"
      :displayWannaBeAppBoss="displayWannaBeAppBoss"
      :prefixTextDisplayedBeforeName="prefixTextDisplayedBeforeName"
      :profilePictureUrl="profilePictureUrl"
      :appBoss="ownerMiniProfile.UOFromAppBoss"></ProfileViewLink>
  </div>
</template>
<script lang="ts">
import { computed, reactive, toRefs } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { UserProjectTypes } from '@/engineproject/default/common/domainModel/userProjectTypes';
import { useAppStore } from '@/engineproject/store/appStore';

import ProfileViewLink from '@/engineproject/components/UserProfile/ProfileViewLink.vue';

export default {
  props: {
    ownerMiniProfile: {
      type: Object as () => UserProjectTypes.OwnerMiniProfile,
      required: true
    },
    prefixTextId: {
      type: String,
      required: false,
      default: "itemProposedBy",
    },
    displayAppBoss: {
      type: Boolean,
      required: false,
      default: true
    },
    displayWannaBeAppBoss: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  setup(props: any, context: any) {
    const {t} = useI18n()
    const appStore = useAppStore();
    const page = reactive({
      userDisplayedName: computed(() => (props.ownerMiniProfile.UOName !== undefined && 
        props.ownerMiniProfile.UOName !== '') ? props.ownerMiniProfile.UOName : props.ownerMiniProfile.UOPseudo),
      userDisplayedDescription: computed(() => (props.ownerMiniProfile.UODescription !== undefined) ? props.ownerMiniProfile.UODescription : ""),
      prefixTextDisplayedBeforeName: computed(() => ((props.prefixTextId !== "") ? t(props.prefixTextId) : "")),
      profilePictureUrl: computed(() => props.ownerMiniProfile.UOPictureUrl),
      appDisplayOptions:  computed(() => appStore.state.appDisplayOptions),
    });
    return {
      ...toRefs(page),
      t, 
    }
  },
  components: {
    ProfileViewLink,
  },
}


</script>

import { createI18n, LocaleMessages, VueMessageType, useI18n } from 'vue-i18n'

function loadLocaleMessagesFromContext(aContext: __WebpackModuleApi.RequireContext, mergeInto: LocaleMessages<VueMessageType>): LocaleMessages<VueMessageType> {
  //const messages: LocaleMessages<VueMessageType> = {}
  aContext.keys().forEach(key => {
    // console.error("key " + key)
    const matched = key.match(/([A-Za-z0-9-_]+)\//i)
    if (matched && matched.length > 1) {
      //console.error("keymatched " + key)
      const locale = matched[1]
      //console.error("locale " + locale)
      //console.error("aContext(key).default " + aContext(key).default)
      mergeInto[locale] = {
        // eslint-disable-next-line 
        // @ts-ignore
        ...mergeInto[locale],
        //...aContext(key).default
        ... ((aContext(key).default !== undefined) ? aContext(key).default : aContext(key))
      }
    }
  })
  //console.error("loadLocaleMessages:")
  //console.dir(mergeInto)
  return mergeInto
}

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  let messages: LocaleMessages<VueMessageType> = {}
  messages = loadLocaleMessagesFromContext(locales, messages)
  // Doesn't for the moment plugin config is locale ???? 
  //messages = loadLocaleMessagesFromContext(projectCoopsnLocales, messages)
  //messages = loadLocaleMessagesFromContext(projectDefaultLocales, messages)
  
  /*
  locales.keys().forEach(key => {
    //console.error("key " + key)
    const matched = key.match(/([A-Za-z0-9-_]+)\//i)
    if (matched && matched.length > 1) {
      //console.error("keymatched " + key)
      const locale = matched[1]
      //console.error("locale " + locale)
      messages[locale] = {
        ...messages[locale],
        ...locales(key).default
      }
    }
  })
  */
  //console.error("loadLocaleMessages:")
  //console.dir(messages)
  return messages
}

const supportedLanguages = {
  fr: true,
  en: true
} as any;
//console.error("process.env.VUE_APP_I18N_LOCALE " + process.env.VUE_APP_I18N_LOCALE)
const getLocalFromBrowser = () => {
  if (navigator !== undefined &&
    navigator.language !== undefined) {
      // console.error("locale: " + navigator.language)
      //console.error("locale: navigator.language.split('-')[0] " + navigator.language.split('-')[0])
      // return navigator.language.split('-')[0]
      if (supportedLanguages[navigator.language] !== true) {
        const shortLang = navigator.language.split('-')[0]
        return shortLang
      }
      return navigator.language
    }
  return undefined
}
const getFullLocalFromBrowser = () => {
  if (navigator !== undefined &&
    navigator.language !== undefined) {
      return navigator.language
    }
  return "us"
}

const useI18nWithMessages = (messages: any) => {
  const ret =  useI18n({
    inheritLocale: true,
    //locale: "en",
    //locale: "fr",
    missingWarn: false,
    messages: messages
  });
  return ret;
/*  return {
    ...ret,
    //"$t": ret.t
  }*/
/*  return useI18n({
    inheritLocale: true,
    locale: "en",
    messages: messages
  });*/
  /*
  const { t } = useI18n({
    inheritLocale: true,
    locale: "en",
    messages: messages
  });
  return t*/
}

const i18n = createI18n({
  legacy: false,
  globalInjection: true, // $t ?
  locale: getLocalFromBrowser() || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: getLocalFromBrowser() || process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    // eslint-disable-next-line 
    // @ts-ignore
    messages: loadLocaleMessages()
})

const fullLocale = getFullLocalFromBrowser()
export {
  useI18nWithMessages,
  i18n,
  loadLocaleMessages,
  fullLocale
}
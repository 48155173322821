<template>
  <div v-if="aAdDoc.getInvitationsIsLoading() === true " data-cy="cyInvitationsLoading">
    {{ t('invitationIsLoadingText') }}
  </div>
  <div v-else  data-cy="cyInvitationsLoaded">
    <div class="invitationButtonArea">
      <button class="button" data-cy="cyOpenInvitationPopup"
        @click="openInvitationPopup(aAdDoc, addInvitationText)"
        :title="t('adDocOpenInvitationPopupTitle')"
      >
      {{ t( "adDocOpenInvitationPopup") }}
      </button>
      <a :href="buildMailTo()">


        <button @click="copyMailContent()" class="button" data-cy="cyOpenInvitationPopup"
          :title="t('adDocOpenInvitationPerMailPopupTitle')"
        >
        {{ t( "adDocOpenInvitationPerMailPopup") }}
        </button>
        
      </a>
      <textarea class="invisible" ref="mailBodyText" :value="buildMailToBodyText()">
      </textarea>


    </div>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs, ref } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";
import { useDocumStore } from '@/engineproject/store/documStore';
import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes';
import { useAppStore } from '@/engineproject/store/appStore';
import { useUserStore } from '@/engineproject/store/userStore';

export default {
  props: {
    aAdDoc: {
      type: Object as () => Array<any>,
      required: true,
    },
  },
  setup(props: any, context: any) {
    const documStore = useDocumStore();
    const { t } = useI18n();
    const appStore = useAppStore();
    const userStore = useUserStore();
    const page = reactive({
      addInvitationText: "",
    });
    const mailBodyText = ref(null) as any

    const openInvitationPopup = async (aAdDoc: AdDocumentProjectObject, addInvitationText: string) => {
      appStore.commit("setShowProfilesListModal", {showProfilesListModal: true});

    };

    function formatDate(aDate: string) {
      return (new Date( aDate )).toISOString().replaceAll("-", "").split('.')[0].replaceAll(":", "")+"Z"
    }

    const office365OLD = "https://outlook.office.com/calendar/0/deeplink/compose?body=Learn%20all%20about%20the%20rules%20of%20the%20Motorway%20and%20how%20to%20access%20the%20fast%20lane.%0A%0Ahttps%3A%2F%2Fen.wikipedia.org%2Fwiki%2FGridlock_%28Doctor_Who%29&enddt=2022-01-12T20%3A00%3A00%2B00%3A00&location=New%20Earth&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-01-12T18%3A00%3A00%2B00%3A00&subject=Welcome%20to%20the%20Motorway"
    const office365 = "https://outlook.office.com/calendar/0/deeplink/compose?body=" +
      encodeURIComponent(props.aAdDoc.getADDescription()) +
      "&enddt=" +formatDate(props.aAdDoc.getValueADDateHourEndsOn())  +
      "&path=%2Fcalendar%2Faction%2Fcompose" +
      "&rru=addevent" +
      "&startdt=" + formatDate(props.aAdDoc.getValueADDateHourStartsOn()) +
      "&subject=" + encodeURIComponent(props.aAdDoc.getADTitle())
    
    const googlaAgendaLinkOLD = "https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20220112T180000Z%2F20220112T200000Z&details=Learn%20all%20about%20the%20rules%20of%20the%20Motorway%20and%20how%20to%20access%20the%20fast%20lane.%0A%0Ahttps%3A%2F%2Fen.wikipedia.org%2Fwiki%2FGridlock_%28Doctor_Who%29&location=New%20Earth&text=Welcome%20to%20the%20Motorway"
    const googlaAgendaLink = "https://calendar.google.com/calendar/render?action=TEMPLATE&dates=" +
       formatDate(props.aAdDoc.getValueADDateHourStartsOn()) +
      "%2F" + formatDate(props.aAdDoc.getValueADDateHourEndsOn()) + 
      "&details=" + encodeURIComponent(props.aAdDoc.getADDescription()) +
      "&text=" + encodeURIComponent(props.aAdDoc.getADTitle())

      const buildMailToSubject  = () => {
        return encodeURIComponent(t('adDocOpenInvitationPerMailSubject'))
      }

    const buildMailToBody  = () => {
      return encodeURIComponent(buildMailToBodyText())
    }

const buildMailToBodyText  = () => {
  return `
${t('adDocOpenInvitationPerMailBodyLine1')}

${t('adDocOpenInvitationPerMailBodyLine2')}
${props.aAdDoc.getADDocUrlForOGAndSEO('?qr=invemail')}

${t('adDocOpenInvitationPerMailBodyLine3')}

${t('adDocOpenInvitationPerMailBodyLine4')}

${googlaAgendaLink}

${t('adDocOpenInvitationPerMailBodyLine5')}

${office365}

${t('adDocOpenInvitationPerMailBodyLine6')}



`
    };

    const buildMailTo  = () => {

      return 'mailto:?subject=' + buildMailToSubject() + '&body=' + buildMailToBody()
    }


    const copyMailContent  = () => {
      console.error('copyMailContent', mailBodyText)
      if (mailBodyText.value) {


        try {
          // var range = document.createRange();
          //   range.selectNode(mailBodyText.value);
          //   window.getSelection()?.addRange(range);
          mailBodyText.value.focus();
          mailBodyText.value.select();
            var successful = document.execCommand("copy");

          //var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Copying text command was ' + msg);
        } catch (err) {
          console.log('Oops, unable to copy', err);
        }
      }

    }



    return {
      ...toRefs(page),
      t,
      openInvitationPopup,
      googlaAgendaLink,
      buildMailTo,
      buildMailToBody,
      buildMailToBodyText,
      copyMailContent,
      mailBodyText,
    };
  },
  components: { 
  },
};
</script>

<style lang="scss">
.invisible {
  //display: none;
  // visibility: hidden;
  color: white;
  border: none;
  width: 1px;
  height: 1px;
  resize: none;
}
</style>

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "documentViewMainPart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdDocActionButtonsInListOfDocs = _resolveComponent("AdDocActionButtonsInListOfDocs")!
  const _component_SocialNetworksButtons = _resolveComponent("SocialNetworksButtons")!
  const _component_AdDocumentPromotion = _resolveComponent("AdDocumentPromotion")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["adDocumentViewMainPart", 
      $props.aAdDoc.isFromPremiumAccount() === true
        ? ' documentFromPremiumAccount'
        : ''
    ]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.viewAdDocOnClick($props.aAdDoc)))
  }, [
    ($props.displayInModal === true)
      ? (_openBlock(), _createBlock(_component_AdDocActionButtonsInListOfDocs, {
          key: 0,
          aAdDoc: $props.aAdDoc
        }, null, 8, ["aAdDoc"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "content", {
        aAdDoc: $props.aAdDoc,
        indexDocInList: $props.indexDocInList,
        displayCommentsLikesOnly: $props.displayCommentsLikesOnly
      })
    ]),
    ($props.displayFromList !== true && $props.displayInModal !== true && 1 === 3 + 2)
      ? (_openBlock(), _createBlock(_component_SocialNetworksButtons, {
          key: 1,
          aAdDoc: $props.aAdDoc
        }, null, 8, ["aAdDoc"]))
      : _createCommentVNode("", true),
    ($props.displayFromList !== true && $props.displayInModal !== true)
      ? (_openBlock(), _createBlock(_component_AdDocumentPromotion, {
          key: 2,
          aAdDoc: $props.aAdDoc
        }, null, 8, ["aAdDoc"]))
      : _createCommentVNode("", true)
  ], 2))
}
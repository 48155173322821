import { UserTypesCWB } from "./userTypesCWB";




// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace EnterpriseInfoTypesCWB {
  
  export interface EnterpriseInfo {
    EIEnterpriseId: string;
    EISearchId: string; // Used to filter the enterprise ads
    EIName: string;
    EILat?: number;
    EILng?: number;
    EIZipCode?: string;
    EIGeoHash?: string;
    EICity?: string;
    EIAddress?: string;
    EIPhone?: string;
    EIWeb?: string;
    EIColContributionsEvents?: Array<EnterpriseContributionsEvent>;

  }

  export interface EnterpriseContributionsEvent {
    ECId: string
    ECActivityId: string;
    ECActivityTitle: string;
    ECActivityDateHourStartsOn: Date;
    ECActivityUnivers: string;
    ECActivityCatsInUnivers: string;
    ECActivityOwnerMiniProfile: UserTypesCWB.OwnerMiniProfile;
    ECMiniProfile: UserTypesCWB.UserMiniProfile;
    ECNbHours: number;
  }
  
  export const allPublicWithoutEnterprise = {
    EIEnterpriseId: "a",
    EIName: "tout public",
    EISearchId: "*"
  }

}


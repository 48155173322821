import { toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "filter-container" }
const _hoisted_2 = { for: "filterSoireeOn" }
const _hoisted_3 = { for: "filterWeekendOn" }
const _hoisted_4 = ["value", "id", "onUpdate:modelValue", "onClick"]
const _hoisted_5 = { for: "assetObj.id" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString($setup.t( $props.titleTag )) + " ", 1),
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("input", {
        "data-cy": "cyFilterSoireeOn",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchCalendarFilterSoiree) = $event)),
        type: "checkbox",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.setSoireeOnOff && $setup.setSoireeOnOff(...args))),
        id: "filterSoireeOn"
      }, null, 512), [
        [_vModelCheckbox, _ctx.searchCalendarFilterSoiree]
      ]),
      _createElementVNode("label", _hoisted_2, _toDisplayString($setup.t('filterSoireeTitle')), 1)
    ]),
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("input", {
        "data-cy": "cyFilterWeekendOn",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchCalendarFilterWeekend) = $event)),
        type: "checkbox",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($setup.setWeekendOnOff && $setup.setWeekendOnOff(...args))),
        id: "filterWeekendOn"
      }, null, 512), [
        [_vModelCheckbox, _ctx.searchCalendarFilterWeekend]
      ]),
      _createElementVNode("label", _hoisted_3, _toDisplayString($setup.t('filterWeekendTitle')), 1)
    ]),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterForm.calendarShortCutSelectedDays, (assetObj) => {
        return (_openBlock(), _createElementBlock("div", {
          key: assetObj.id
        }, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            value: assetObj.checked,
            id: assetObj.id,
            "onUpdate:modelValue": ($event: any) => ((assetObj.checked) = $event),
            onClick: ($event: any) => ($setup.filterAttributesObjectValueChanged(assetObj.in365Days, assetObj.checked))
          }, null, 8, _hoisted_4), [
            [_vModelCheckbox, assetObj.checked]
          ]),
          _createElementVNode("label", _hoisted_5, _toDisplayString($setup.buildDateDisplay(assetObj)), 1)
        ]))
      }), 128))
    ])
  ]))
}
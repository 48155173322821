import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "inlineSeparatedBlockContainer adminSuperPower" }
const _hoisted_2 = {
  key: 0,
  class: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AllowedEnterprisesItem = _resolveComponent("AllowedEnterprisesItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loggedUserProfile.isAdmin() === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, _toDisplayString($setup.t('enterpriseAllowedList')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrayOfEnterpriseInfo, (aEnterpriseInfo, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createVNode(_component_AllowedEnterprisesItem, {
                allowedEnterprise: $setup.isAllowedEnterprise(aEnterpriseInfo),
                aUserProfile: $props.aUserProfile,
                aEnterpriseInfo: aEnterpriseInfo
              }, null, 8, ["allowedEnterprise", "aUserProfile", "aEnterpriseInfo"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
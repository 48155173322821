<template>
  <div>
    <img v-if="aAdDoc.OADData.ADDefaultAttDoc"
      class="firstImageOnly"
      :src="aAdDoc.OADData.ADDefaultAttDoc"
      :title="(aAdDoc.OADData.ADDefaultAttDocLicense !== undefined) ? aAdDoc.OADData.ADDefaultAttDocLicense : ''"
    />
    <div v-else class="noImageContainer">
      <div class="noImageTextPart">
        <div>
          {{ t(aAdDoc.OADData.ADCatsInUnivers) }}
        </div>
      </div>
      <img
        class="firstImageOnly"
        src="@/engineproject/default/common/assets/images/appboss/android-chrome-192x192.png"
        title="Aucune image associée, affichage de l'image par défaut de l'application"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs } from "vue";
import { AdDocumentProjectTypes } from '@/engineproject/default/common/domainModel/adDocumentProjectTypes';
import { useI18n } from '@/engineproject/default/common/i18n';


export default {
  props: {
    aAdDoc: {
      type: Object as () => AdDocumentProjectTypes.AdDocument,
      required: true,
    },
  },
  setup(props: any, context: any) {
    const { t } = useI18n();
    const page = reactive({
      comment: "",
    });

    return {
      ...toRefs(page),
      t
    };
  },
};
</script>


import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import { UserProjectTypes } from '../default/common/domainModel/userProjectTypes'


export interface FilterAttributesState {
  mainFilter: string;
  filterType: string;
  searchLocationFilter: UserProjectTypes.LocationPoint | undefined;
  searchDistanceInMFilter: number;
}

export const filterUsersKey: InjectionKey<Store<FilterAttributesState>> = Symbol()

// define your own `useStore` composition function
export function useFilterUsersStore () {
  return baseUseStore(filterUsersKey)
}
export const filterUsersStore = createStore<FilterAttributesState>({
  state: {
    mainFilter: "all",
    filterType: "allUsers", // allLocalUsers allUsers myContactUsers allLocalAdDocs allAdDocs myAdDocs
    searchLocationFilter: undefined,
    searchDistanceInMFilter: 5000
  },
  mutations: {
    setSearchLocationFilter(state, {searchLocationFilter}) {
      state.searchLocationFilter = searchLocationFilter
    },
    searchDistanceInMFilter(state, {searchDistanceInMFilter}) {
      state.searchDistanceInMFilter = searchDistanceInMFilter
    },
    setMainFilter(state, {mainFilterValue}) {
      state.mainFilter = mainFilterValue
    },
    setFilterType(state, {filterTypeValue}) {
      console.error("setFilterType" + filterTypeValue)
      state.filterType = filterTypeValue
    },
  },
  getters: {
    locationFilterLatLng: (state, getters) => {
      if (state.searchLocationFilter !== undefined &&
        state.searchLocationFilter.LPLat !== undefined  &&
        state.searchLocationFilter.LPLng !== undefined &&
        state.searchLocationFilter.LPLat !== 0  &&
        state.searchLocationFilter.LPLng !== 0 ) {
          return [state.searchLocationFilter.LPLat, state.searchLocationFilter.LPLng]
        }
      return undefined
    },

  },
  actions: {

  },
  modules: {
  }
})

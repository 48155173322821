import { renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _renderSlot(_ctx.$slots, "content", {
    showNav: $setup.showNav,
    pPseudo: _ctx.pPseudo,
    pUserPictureUrl: _ctx.pUserPictureUrl,
    isAdmin: _ctx.isAdmin,
    pUserNotifNotViewed: _ctx.pUserNotifNotViewed,
    pUserInvitWithGainNotViewed: _ctx.pUserInvitWithGainNotViewed,
    newAppVersion: _ctx.newAppVersion,
    logout: $setup.logout,
    displayMyAds: $setup.displayMyAds,
    goAndResetFilterIfSameRoute: $setup.goAndResetFilterIfSameRoute,
    menuProfilItems: $setup.menuProfilItems,
    adminMenuProfilItems: $setup.adminMenuProfilItems,
    installNewAppVersion: $setup.installNewAppVersion
  })
}
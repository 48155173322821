<template>
    <slot
      name="content"
      :showNav="showNav"
      :pPseudo="pPseudo"
      :pUserPictureUrl="pUserPictureUrl"
      :isAdmin="isAdmin"
      :pUserNotifNotViewed="pUserNotifNotViewed"
      :pUserInvitWithGainNotViewed="pUserInvitWithGainNotViewed"
      :newAppVersion="newAppVersion"
      :logout="logout"
      :displayMyAds="displayMyAds"
      :goAndResetFilterIfSameRoute="goAndResetFilterIfSameRoute"
      :menuProfilItems="menuProfilItems"
      :adminMenuProfilItems="adminMenuProfilItems"
      :installNewAppVersion="installNewAppVersion"
    ></slot>
</template>

<script lang="ts">
import { computed, reactive, toRefs, } from 'vue'
import { useFilterAttributesStore } from '@/engineproject/store/filterAttributesStore'
import { useAppStore } from '@/engineproject/store/appStore'
import { useUserStore } from '@/engineproject/store/userStore'
import { useI18n } from "@/engineproject/default/common/i18n";
import router from '@/router/index'


export default {
  props: {
  },
    setup(props: any, context: any) {
      const filterAttributesStore = useFilterAttributesStore()
      const appStore = useAppStore()
      const userStore = useUserStore()
      const {t} = useI18n()
      const page = reactive({
        vueAppVersion: process.env.VUE_APP_VERSION,
        aUserProfile: computed(() => userStore.state.aUserProfile),
        pUserPictureUrl: computed(() => (userStore.state.aUserProfile.getUPPictureUrl())),
        isAdmin: computed(() => (userStore.state.aUserProfile.isAdmin())),
        pUserNotifNotViewed: computed(() => (userStore.state.aUserProfile.getUPNotifNotViewed())),
        pUserInvitWithGainNotViewed: computed(() => (userStore.state.aUserProfile.getUPInvitWithGainNotViewed())),
        pPseudo: computed(() => (userStore.state.aUserProfile.getUPNameOrPseudo())),
        newAppVersion: computed(() => appStore.state.newAppVersion),
      });
      const logout = () => {
        userStore.dispatch('logout', {appStore})
      }
      const isSameRoute = (newRoute: string) => {
        const currentRoute = router.currentRoute.value
        return  (currentRoute.path.toLowerCase() === newRoute)
      }
      const goAndResetFilterIfSameRoute = (newRoute: string) => {
        if (isSameRoute(newRoute) === false) {
          router.push(newRoute)
        } 
        setTimeout(async () => {
          await filterAttributesStore.dispatch("resetAllFilters", {
            filterName: "allLocalMyFilter", 
            newSelectedValue: "allAdDocs",
            adDocFileType: ""
          })
        }, 3000)


      }
      const displayMyAds = async () => {
        await filterAttributesStore.dispatch("setSelectedOneOfAllFilters", {filterName: "allLocalMyFilter", 
          newSelectedValue: "myAdDocs"})
      }
    const showNav = () => {
      return page.aUserProfile.isCurrentLoggedUser()
    };
    
    const switchAdminMode = () => {
      console.error("switchAdminMode 1")
      alert(t("switchAdminModeDescription"))
    }
    
    const installNewAppVersion = () => {
      console.error("installNewAppVersion 1")
      appStore.dispatch("installNewAppVersion")
      /*
      if (navigator !== undefined && navigator.serviceWorker !== undefined) {
        console.error("installNewAppVersion 2")
        const event = document.createEvent('Event');
        // Define that the event name is 'RefreshServiceWorkerReceived'.
        event.initEvent('RefreshServiceWorkerReceived', true, true);
        document.dispatchEvent(event);
        appStore.commit("setNewAppVersionIsComing", { newAppVersionVal: false });
      }*/
    }
    const adminMenuProfilItems = [
      {
					label: t('adminViewProfiles'),
          class: 'cyMenuItemAdminViewProfiles',
					command: () => {
            router.push('/profiles')
					},
				},
				{
					label: t('adminViewEnterprises'),
          class: 'cyMenuItemAdminViewEnterprises',
					command: () => {
            router.push('/enterprises')
					},
				},
				{
					label: t('adminViewMySuggestions'),
          class: 'cyMenuItemAdminViewMySuggestions',
					command: () => {
            router.push('/suggestions/myAdDocs')
					},
				},
				{
					label: t('adminViewAllSuggestions'),
          class: 'cyMenuItemAdminViewAllSuggestions',
					command: () => {
            router.push('/suggestions/allAdDocs')
					},
				},
				{
					label: t('adminAddSuggestions'),
          class: 'cyMenuItemAdminAddSuggestions',
					command: () => {
            router.push('/addsug')
					},
				},
				{
					label: t('premiumViewAllPublicModels'),
          class: 'cyMenuItemAdminViewAllPublicModels',
					command: () => {
            router.push('/models/allAdDocs')
					},
				},
				{
					label: t('adminViewOther'),
          class: 'cyMenuItemAdminViewOther',
					command: () => {
            router.push('/adminViewOther')
					},
				},
				{
					label: t('adminInfo'),
          class: 'cyMenuItemAdminInfo',
					command: () => {
            switchAdminMode()
					},
				},
      ]
      const menuProfilItems = [
				{
					label: t('myProfile'),
          class: 'cyMenuItemMyProfile',
					//icon: 'pi pi-refresh',
					command: () => {
            router.push('/settings')
					},
				},
				{
					label: t('myCalendar'),
          class: 'cyMenuItemMyCalendar',
          command: () => {
            router.push('/calendar')
					},
				},
				{
					label: t('myContributions'),
          class: 'cyMenuItemMyContributions',
          command: () => {
            router.push('/contributions/')
					},
				},
				{
					label: t('myPromotionUsingQR'),
          class: 'cyMenuItemMyCalendar',
          command: () => {
            router.push('/o/')
					},
				},
				{
					label: t('myAds'),
          class: 'cyMenuItemMyAds',
          command: () => {
            router.push('/myAdDocs')
					},
				},
				{
					label: t('premiumAddModels'),
          class: 'cyMenuItemAdminAddModels',
          command: () => {
            router.push('/addtem')
					},
				},
				{
					label: t('mentionsLegales'),
          class: 'cyMenuHome',
          command: () => {
            router.push('/home')
					},
				},
        {
          label: t('logout'),
          class: 'cyMenuItemLogout',
					command: () => {
						logout();
					},
        }
			]


      return { 
        ...toRefs(page), 
        logout,
        displayMyAds,
        goAndResetFilterIfSameRoute,
        menuProfilItems,
        adminMenuProfilItems,
        showNav,
        installNewAppVersion,
      }
    },
}


</script>

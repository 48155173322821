<template>
    <div v-if="fMyAdsOn === true" class="filter-container" >
      <div>
        <label for="filterMyAdsOn">{{ (adDocFileType === 'T') ? t('filterMyModelsTitle') : 
          ( (adDocFileType === 'S') ? t('filterMySuggestionsTitle') : t('filterMyAdsTitle')) }}</label>
        <input data-cy="cyFilterMyAdsOn" v-model="fMyAdsOn" type="checkbox" @click="setMyAdsOnOff"
           id="filterMyAdsOn" />
      </div>
    </div>
</template>

<script lang="ts">
import {  reactive, toRefs, computed } from "vue";
import { useFilterAttributesStore , getOneOfAllFilters} from '@/engineproject/store/filterAttributesStore'
import { useI18n } from "@/engineproject/default/common/i18n";
import router from '@/router';

export default {
  props: {
    adDocFileType: {
      type: String,
      required: false,
      default: ""
    },
    stateObject: {
      type: String,
      required: false
    },
  },
  setup(props: any) {
    const filterAttributesStore = useFilterAttributesStore()
    const {t} = useI18n()
    const page = reactive({

      fMyAdsOn: computed(() => 
        ((getOneOfAllFilters(filterAttributesStore.state, "allLocalMyFilter")).filterSelected === "myAdDocs")), 

    });

    // "allLocalAdDocs", "allAdDocs", "myAdDocs"

    const setMyAdsOnOff = async () => {
      // await filterAttributesStore.dispatch("setSelectedOneOfAllFilters", {filterName:"allLocalMyFilter", 
      //  newSelectedValue: ((page.fMyAdsOn !== true) ? "myAdDocs" : "allAdDocs")})
      router.push("/")
    }
  
    return {
      ...toRefs(page),
      /// props,
      t,
      setMyAdsOnOff,
    }
  },
}
</script>

<style lang="scss" scoped>
.filter-container {
  margin: 4px;
  padding: 2px;
}
</style>

{
  "addTransactionTextPH": "Ajouter une note ...",
  "transactionIsLoadingText": "Chargement des transactions ...",

  "addTransactionErrorDocNotFoundOrNotAuthorized": "Document inexistant ou droits d'accès insuffisant",

  "itemTransactionsList": "Liste des demandes d'inscription",
  "itemTransactionsEmpty": "Aucune demande d'inscription pour votre annonce",
  "itemTransactions" : "Demandes d'inscription:",
  "itemMyTransaction": "Participer à cette annonce:",
  "podiumTitle": "Podium:",
  "itemYourTransactionsStatus": "Statut de votre demande d'inscription:",
  "adDocAddTransactionTitle": "Envoyer une demande à l'organisateur de l'annonce",
  "adDocAddTransactionRequest": "Subscription Request",
  "adDocParticipateRequestTitle": "Participer à l'annonce",
  "adDocParticipateRequest": "Participer",
  "adDocSeeMoreTitle": "Voir les détails pour participer à l'annonce",
  "adDocSeeMore": "En savoir plus",
  "adDocPromotionRequestTitle": "Faire la promotion de votre annonce",
  "adDocPromotionRequest": "Partager votre annonce",
  "adDocParticipateToFreeSubAdDoc": "Participer à cette annonce non modérée",
  "adDocRemoveMyTransaction": "Retirer ma demande d'inscription",
  "adDocRemoveMyFreeSubscription": "Retirer ma participation",
  "adDocAcceptTheTransaction": "Accept",
  "adDocAcceptTheTransactionTitle": "Accept la transaction",
  "adDocRejectTheTransaction": "Reject",
  "adDocRejectTheTransactionTitle": "Refuser la transaction",

  "transactionStatus_A": "Acceptée",
  "transactionStatus_F": "Inscription Libre",
  "transactionStatus_R": "Refusée",
  "transactionStatus_P": "En attente",

  "transactionNotifText_TA": "Votre demande de participation a été acceptée:",
  "transactionNotifText_TF": "Vous avez reçu une nouvelle inscription libre pour votre annonce:",
  "transactionNotifText_TR": "Votre demande de participation n'a pas été acceptée:",
  "transactionNotifText_TP": "Vous avez reçu une nouvelle demande de participation pour votre annonce:",
  "transactionNotifText_TC": "Vous avez reçu une annulation de participation pour votre annonce:",
  "transactionNotifText_DC": "L'annonce suivante a été modifiée:",
  "transactionNotifText_DR": "L'annonce suivante a été signalée:",
  "transactionNotifText_IN": "Vous avez reçu une invitation pour l'annonce:",
  "transactionNotifText_IG": "Vous avez reçu une invitation pour l'annonce:",

  "calendarEventText_EO": "Organisation de l'annonce: ",
  "calendarEventText_EF": "Participation à l'annonce (inscription libre): ",
  "calendarEventText_EP": "Participation à l'annonce:",
  
  "calendarEventStatus_C": "Annonce annulée par l'organisateur.",

  "transactionStatusTitle": "{theStatus}",

  "transactionAddedNotifMsg": 
  "Demande de participation envoyée à l'organisateur. Vous recevrez une notification quand l'organisateur aura traité votre inscription.",
  "transactionAddedFreeSubscriptionNotifMsg": 
  "L'annonce a été ajoutée à votre agenda. L'organisateur a reçu une notification suite à votre inscription libre.",
  "transactionRemovedNotifMsg": "Demande de participation retirée",
  "transactionUpdatedToAcceptedNotifMsg": "Une notification d'acceptation a été envoyée au demandeur.",
  "transactionUpdatedToRefusedNotifMsg": "Une notification de refus a été envoyée au demandeur."


}

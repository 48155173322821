import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "subtitle" }
const _hoisted_2 = { class: "transactionElement" }
const _hoisted_3 = { class: "transactionElementTitleArea" }
const _hoisted_4 = {
  key: 0,
  class: "leftAlign"
}
const _hoisted_5 = ["title", "onClick"]
const _hoisted_6 = ["title", "onClick"]
const _hoisted_7 = {
  key: 1,
  class: "leftAlign"
}
const _hoisted_8 = ["title", "onClick"]
const _hoisted_9 = ["onClick", "title"]
const _hoisted_10 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MiniProfileViewLink = _resolveComponent("MiniProfileViewLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString($setup.t("itemTransactionsList")), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortAndAddFootStepsPodium($props.ADColTransactions), (aTransaction) => {
      return (_openBlock(), _createElementBlock("div", {
        key: aTransaction.ATId,
        class: "transactions"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, [
              _createTextVNode(_toDisplayString($setup.formatDate(aTransaction.ATCreatedOn)) + " ", 1),
              _createVNode(_component_MiniProfileViewLink, {
                userMiniProfile: aTransaction.ATMUser,
                prefixTextId: '',
                displayAppBoss: false
              }, {
                default: _withCtx(() => [
                  _createTextVNode("ADColTransaction ")
                ]),
                _: 2
              }, 1032, ["userMiniProfile"]),
              _createTextVNode(" " + _toDisplayString($setup.displayStatus(aTransaction.ATStatus)), 1)
            ]),
            (aTransaction.ATStatus == 'P' && $props.aAdDoc.OADData.ADFreeSubscription === false)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("button", {
                    class: "button",
                    "data-cy": "cyUpdateTransactionAdDocToA",
                    title: $setup.t('adDocAcceptTheTransactionTitle'),
                    onClick: ($event: any) => ($setup.updateTransactionAdDoc({aTransaction, aAdDoc: $props.aAdDoc, newStatus: 'A' }))
                  }, _toDisplayString($setup.t("adDocAcceptTheTransaction")), 9, _hoisted_5),
                  _createElementVNode("button", {
                    class: "button",
                    "data-cy": "cyUpdateTransactionAdDocToR",
                    title: $setup.t('adDocRejectTheTransactionTitle'),
                    onClick: ($event: any) => ($setup.updateTransactionAdDoc({aTransaction, aAdDoc: $props.aAdDoc, newStatus: 'R' }))
                  }, _toDisplayString($setup.t("adDocRejectTheTransaction")), 9, _hoisted_6)
                ]))
              : (aTransaction.ATStatus == 'A')
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("button", {
                      class: "button",
                      "data-cy": "cyOpenProfileFromA",
                      title: $setup.t('openProfile'),
                      onClick: ($event: any) => ($setup.viewUserProfile(aTransaction.ATMUser.UMUserId))
                    }, [
                      _createTextVNode(_toDisplayString($setup.t("openProfileButtonText")) + " ", 1),
                      _createElementVNode("a", {
                        onClick: ($event: any) => ($setup.viewUserProfile(aTransaction.ATMUser.UMUserId)),
                        title: $setup.t('openProfile')
                      }, _toDisplayString(aTransaction.ATMUser.UMPseudo), 9, _hoisted_9)
                    ], 8, _hoisted_8)
                  ]))
                : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: "transactionComment",
            title: $setup.t('itemTransactionCommentTitle')
          }, [
            _createElementVNode("pre", null, _toDisplayString(aTransaction.ATComment), 1)
          ], 8, _hoisted_10)
        ])
      ]))
    }), 128))
  ], 64))
}
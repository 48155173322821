<template>
  <div v-if="fMyAdsOn === false" class="filter-container" >
    <div>
      <label for="filterGeoOn">{{ t('filterGeolocalisationTitle') }}</label>
      <input  data-cy="cyFilterGeoOn" v-model="fGeoOn" type="checkbox" @click="setGeoOnOff"
          id="filterGeoOn" />
    </div>
    <div v-if="fGeoOn === true">
      <div class="inlineSeparatedBlockContainer">
        <div>
          <label for="fZipcode">{{ t('filterZipcode') }}</label>
          <GeolocationFromZipCodes :currentLocation="searchLocationFilter"  id="fZipcode"  :placeholder="t('filterZipcodePH')"
          v-on:update:location="updateLocation($event)" inputClass="inputClass">
          </GeolocationFromZipCodes>
        </div>
        <div>
          <label for="fDistance">{{ t('filterDistance') }}</label>
          <InputText  v-model="searchDistanceInKMFilter" type="number" 
            :placeholder="t('filterDistancePH')" id="fDistance"
            @change="updateDistance($event)" />
        </div>
      </div>
      <div class="inlineSeparatedBlockContainer">
        <GeolocationView :aLocation="searchLocationFilter"
        ></GeolocationView>
      </div>
      <div class="inlineSeparatedBlockContainer" @click="openMap()" data-cy="cyFilterOpenLargerMap">
        <a>{{ t('openLargerMap') }}</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {  reactive, toRefs, computed, ref, watch } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";
import { UserProjectTypes } from '@/engineproject/default/common/domainModel/userProjectTypes';

import { useFilterAttributesStore, getOneOfAllFilters} from '@/engineproject/store/filterAttributesStore'
import { useUserStore } from '@/engineproject/store/userStore';
import { useAppStore } from '@/engineproject/store/appStore';

import GeolocationFromZipCodes from "@/engineproject/components/Geolocation/GeolocationFromZipCodes.vue";
import GeolocationView from '@/engineproject/components/Geolocation/GeolocationView.vue';

import InputText from 'primevue/inputtext';

export default {
  props: {
    stateObject: {
      type: String,
      required: false
    },
  },
  setup(props: any) {
    const filterAttributesStore = useFilterAttributesStore()
    const appStore = useAppStore();
    const userStore = useUserStore();
    const {t} = useI18n()
    const page: any = reactive({
      searchLocationFilter: computed(() => (filterAttributesStore.state.searchLocationFilter) ? 
        filterAttributesStore.state.searchLocationFilter :
        userStore.state.aUserProfile.getUPLocation()), 
      fGeoOn: computed(() => 
        ((getOneOfAllFilters(filterAttributesStore.state, "allLocalMyFilter")).filterSelected === "allLocalAdDocs")), 
      fMyAdsOn: computed(() => 
        ((getOneOfAllFilters(filterAttributesStore.state, "allLocalMyFilter")).filterSelected === "myAdDocs")), 

    });
    const searchDistanceInMFilter = computed(() => filterAttributesStore.state.searchDistanceInMFilter)
    const searchDistanceInKMFilter = ref(searchDistanceInMFilter.value / 1000 as number) // computed(() => searchDistanceInMFilter .value/ 1000)
    watch(searchDistanceInMFilter, (newValue, oldValue) => {
      console.error("searchDistanceInMFilter " + newValue);
      searchDistanceInKMFilter.value = newValue / 1000
    });

    // "allLocalAdDocs", "allAdDocs", "myAdDocs"
    const setGeoOnOff = async () => {
      await filterAttributesStore.dispatch("setSelectedOneOfAllFilters", {filterName:"allLocalMyFilter", 
        newSelectedValue: ((page.fGeoOn !== true) ? "allLocalAdDocs" : "allAdDocs")})
    }
    const updateLocation = (
      newLocation: UserProjectTypes.LocationPoint | undefined
    ) => {
          filterAttributesStore.commit("setSearchLocationFilter", {
            searchLocationFilter: newLocation
          });
    };
    const updateDistance = (event: any) => {
      const searchDistanceInKFilter = parseInt(event.currentTarget.value)
      console.error("updateDistance " + JSON.stringify(searchDistanceInKFilter))
      filterAttributesStore.commit("searchDistanceInMFilter", {
        searchDistanceInMFilter: searchDistanceInKFilter * 1000
      });
    };

    const openMap = () => {
      appStore.commit("setShowMapModal", {showMapModal: true});
    }
    return {
      ...toRefs(page),
      t,
      setGeoOnOff,
      updateLocation,
      updateDistance,
      searchDistanceInKMFilter,
      openMap,
    }
  },
  components: { 
    GeolocationFromZipCodes,
    InputText, 
    GeolocationView
  },
}
</script>

<style lang="scss" >
.inputClass {
  width: 100px !important;
  min-width: 100px !important;
}
#fZipcode, .p-autocomplete, .p-autocomplete > input  {
  width: 100px !important;
}
#fZipcode, .p-autocomplete, .p-autocomplete > input  {
  min-width: 100px !important;
}
#fDistance {
  width: 100px;
}
.filter-container {
  margin: 4px;
  padding: 2px;

  a {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>

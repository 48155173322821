import { PPConfig } from "@/projects/paypalConfigUsedByDefault";
import { firebaseStorage } from "../backend/firebase";
import { DEFAULT_I18N, fieldIsDefined } from "./globalTypes";
import { appConfig } from "@/engineproject/default/common/commonWithBackend/config/app"
import { userProfileControler } from "./controlers/userProfileControler";
import {  EnterpriseInfoTypes } from "./enterpriseInfoTypes";

const nbContactsToBeAnAppBoss = appConfig(process.env.VUE_APP_CONFIG_ENV).nbContactsToBeAnAppBoss
// console.error("nbContactsToBeAnAppBoss " + nbContactsToBeAnAppBoss);


export class EnterpriseInfoObject  {
  OEIData!: EnterpriseInfoTypes.EnterpriseInfo;
  mandatoryDataFieldNames!: Array<string>;
  originalNbHours: number[];

  constructor();
  constructor(srcAdDocumentObjectt: EnterpriseInfoObject) 
  constructor(srcOADData: EnterpriseInfoTypes.EnterpriseInfo) 

  constructor(src?: any) {
    this.originalNbHours = []
    if (src !== undefined ) {
      if (src.OEIData !== undefined) {
        //console.error("constructor src.OADData " + JSON.stringify(src.OADData))
        // this.OADData =  new AdDocumentDataObject(src.OADData)
        this.OEIData = this.deepCopyDocOEIData(src.OADData)
      } else {
        // console.error("constructor src " + JSON.stringify(src))
        // this.OADData =  new AdDocumentDataObject(src)
        /*if (src.UCMUser !== undefined) {
          this.OEIData = {
            ...this.getProfileFromMiniProfile(src.UCMUser)
          }
      
        } else {*/
          this.OEIData = this.deepCopyDocOEIData(src)
        //}
      }
    } else {
      this.OEIData = {} as EnterpriseInfoTypes.EnterpriseInfo
    }
    //console.error("OADData in constructor " + JSON.stringify(this))
  }

  exportToFirebaseObject() {
    const preparedDoc: any = {};
    Object.assign(preparedDoc, this.OEIData);

    delete preparedDoc.EIColContributionsEvents
    // console.error("exportToFirebaseObject preparedDoc " + JSON.stringify(preparedDoc))
    return preparedDoc
  }

  userProfilePropIsEditableByUser(prop: string, newValue: any) {
    let ret = true
    if (prop === "UPLastInvitationRequest" || 
    prop === "UPColContacts"|| 
    prop === "UPEmailValidationDate"|| 
    prop === "UPEmailValidated"|| 
    prop === "UPColAllowedEnterprises" || 
    prop === "UPEnterprise"  || 
    (prop === "UPPhoneNumber" && newValue === "00-00-00-00-00")
    
      ) {
      ret = false
    }
    //console.error("userProfilePropIsEditableByUser " + prop + " ret " + ret)
    return ret
  }

  detectChangesInEnterpriseInfo(newEnterpriseInfoProject: EnterpriseInfoObject) {
    const originalEnterpriseInfo = this.OEIData as any
    const newValue = newEnterpriseInfoProject.OEIData as any
    let changed = false
    for (const prop in originalEnterpriseInfo ) {
      if (JSON.stringify(originalEnterpriseInfo[prop]) !== JSON.stringify(newValue[prop]) &&
        this.userProfilePropIsEditableByUser(prop, newValue[prop]) === true) {
          changed = true
      }
    }
    // test also the newValue object because when the profile is created without phone number
    // the previous loop is not enought to detect an addition of a phone number  for instance.
    for (const prop in newValue) {
      if (JSON.stringify(originalEnterpriseInfo[prop]) !== JSON.stringify(newValue[prop]) &&
        this.userProfilePropIsEditableByUser(prop, newValue[prop]) === true) {
        changed = true
      }
    }
    return changed
  }

  getEIEnterpriseId() {
    return this.OEIData.EIEnterpriseId
  }

  getEIName() {
    return this.OEIData.EIName
  }

  getEISearchId() {
    return this.OEIData.EISearchId
  }

  getMiniEnterprise() {
    return {
      EIEnterpriseId: this.OEIData.EIEnterpriseId,
      EIName: this.OEIData.EIName,
      EISearchId: this.OEIData.EISearchId,
    }
  }
  deepCopyDocOEIData(aOEIData: EnterpriseInfoTypes.EnterpriseInfo) {
    const newOEIData = {
      ...aOEIData,
    }
    return newOEIData 
  }
  setMandatoryDataFieldNames(val: Array<string>) {
    this.mandatoryDataFieldNames = val
  }
  getContributionsEventsArray() {
    return (this.OEIData.EIColContributionsEvents !== undefined) ? 
      this.OEIData.EIColContributionsEvents : []
  }
  getOriginalNbHours() {
    return this.originalNbHours
  }

  setContributionsArrayInEnterprise(newData: { 
    aEIEnterpriseId: string; aEIColContributionsEvents: EnterpriseInfoTypes.EnterpriseContributionsEvent[]; }) {
    if (this.OEIData !== undefined && this.OEIData.EIEnterpriseId == newData.aEIEnterpriseId) {
      this.OEIData.EIColContributionsEvents = newData.aEIColContributionsEvents
      this.originalNbHours = this.OEIData.EIColContributionsEvents.map((obj) => obj.ECNbHours)
    }
  }

  setContributionsHoursInEnterprise(newData: { 
    aEIEnterpriseId: string; contributionsEventId: string; nbHours: number }) {
    if (this.OEIData !== undefined && this.OEIData.EIEnterpriseId == newData.aEIEnterpriseId) {
      const contributionsEvents = this.OEIData.EIColContributionsEvents?.find((aContribution) => {
        return aContribution.ECId === newData.contributionsEventId
      })
      if (contributionsEvents) {
        contributionsEvents.ECNbHours = newData.nbHours
        if (this.OEIData.EIColContributionsEvents) {
          this.originalNbHours = this.OEIData.EIColContributionsEvents.map((obj) => obj.ECNbHours)
        }

      }
    }
  }
  
}


<template>
  <div class="filter-container" >
    <div v-if="filterTitle && filterTitle !== ''">
      {{ t(filterTitle) }}
    </div>
    <Dropdown v-model="newcurrentFilterAttributesObjectsFilterSelected.filterSelected" 
      :options="newcurrentFilterAttributesObjects" optionLabel="lang" optionValue="id"  />
  </div>
</template>

<script lang="ts">
import {  reactive, toRefs, computed } from "vue";
import { useFilterAttributesStore , getOneOfAllFiltersValues, getOneOfAllFilters} from '@/engineproject/store/filterAttributesStore'
import { useI18n } from "@/engineproject/default/common/i18n";
import Dropdown from 'primevue/dropdown';

export default {
  props: {
    stateObject: {
      type: String,
      required: true
    },
    emptyValueAccepted: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  setup(props: any) {
    const filterAttributesStore = useFilterAttributesStore()
    const {t} = useI18n()
    const page = reactive({
      newcurrentFilterAttributesObjects: computed(() => {
        const retu =  getOneOfAllFiltersValues(filterAttributesStore.state, props.stateObject)
        return retu
      }),

      newcurrentFilterAttributesObjectsFilterSelected: computed(() => 
        getOneOfAllFilters(filterAttributesStore.state, props.stateObject)), 
      currentFilterAttributesObjects: computed(() => filterAttributesStore.state), 
      filterTitle: computed(() => props.stateObject + "Title"),

    });
    return {
      ...toRefs(page),
      /// props,
      t,
    }
  },
  components: { 
    Dropdown,
  },
}


</script>

<template>
  <div>
    <a :data-cy="'cyItemComments' + dataCYClass" @click.stop="openCommentModal(aAdDoc)">
      {{ t("itemComments") }} <span data-cy="cyNumericValue">
        {{ aAdDoc.OADData.ADTotComments }}
        </span>
    </a>
  </div>
  <div>
    <a :data-cy="'cyItemLikes' + dataCYClass" @click.stop="likeAdDoc(aAdDoc)">
      {{ t("itemLikes") }} <span  data-cy="cyNumericValue">
        {{ aAdDoc.OADData.ADTotLikes }}
      </span>
    </a>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs, computed } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";
import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes';

import { useAppStore } from '@/engineproject/store/appStore';
import { useDocumStore } from '@/engineproject/store/documStore';
import { useUserStore } from '@/engineproject/store/userStore';

export default {
  props: {
    aAdDoc: {
      type: Object as () => any,
      required: true,
    },
    dataCYClass: {
      type: String,
      default: "",
      required: false,
    },
  },
  setup(props: any, context: any) {
    const documStore = useDocumStore();
    const userStore = useUserStore();
    const appStore = useAppStore();
    const {t} = useI18n()
    const page = reactive({
      comment: "",
      aUserProfile: computed(() => userStore.state.aUserProfile),
    });

    const likeAdDoc = async (aAdDoc: AdDocumentProjectObject) => {
      if (aAdDoc === undefined) {
        return;
      }
      await documStore.dispatch("likeAdDoc", {
        aAdDoc,
        aUserProfile: page.aUserProfile,
        appStore: appStore,
      });
    };

    const openCommentModal = (aAdDoc: AdDocumentProjectObject) => {
      appStore.commit("setInModalAdDocument", {inModalAdDocument: aAdDoc});
      appStore.commit("setShowCommentModal", {showCommentModal: true});
    };

    return {
      ...toRefs(page),
      t, 
      openCommentModal,
      likeAdDoc
    };
  },
};
</script>

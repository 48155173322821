<template>
  <div class="profileListViewItem">
    <InvitationProfilesListViewButtons
     :aUserProfile="aUserProfile"
     :aAdDoc="aAdDoc"></InvitationProfilesListViewButtons>
    <ProfileDataInProfilesListView
      :aUserProfile="aUserProfile"></ProfileDataInProfilesListView>
  </div>
</template>

<script lang="ts">
import { inject, reactive, toRefs } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { UserProfileProjectObject } from '@/engineproject/default/common/domainModel/userProfileProjectToolsTypes';

import InvitationProfilesListViewButtons from '@/engineproject/components/UserProfile/InvitationProfilesListViewButtons.vue';
import ProfileDataInProfilesListView from '@/engineproject/components/UserProfile/ProfileDataInProfilesListView.vue';
import { AdDocumentProjectTypes } from '@/engineproject/default/common/domainModel/adDocumentProjectTypes';

export default {
  props: {
    aAdDoc: {
      type: Object as () => AdDocumentProjectTypes.AdDocument,
      required: true,
    },
    aUserProfile: {
      type: Object as () => UserProfileProjectObject,
      required: true
    },
  },

  setup(props: any, context: any) {
    const formatDate = inject('formatDate') as any;
    const {t} = useI18n()
    const page = reactive({
    });

    return {
      ...toRefs(page),
      formatDate,   
      t
    }
  },
  components: {
    ProfileDataInProfilesListView,
    InvitationProfilesListViewButtons, 
  },
}
</script>


const animationKeyFrames = `
0% {
    border-color: rgba(255, 0, 0, 1);
    outline-color: rgba(255, 234, 234, 1)
  }
  50% {
    border-color: rgba(255, 0, 0, 0.5);
    outline-color: rgba(255, 234, 234, 0.5)
  }
  100% {
    border-color: rgba(255, 0, 0, 0);
    outline-color: rgba(255, 234, 234, 0)
  }
`
export const styleRulesPoster = `
.poster {
  /*height: 125mm;
  border: solid 1px brown;*/
  /*margin: 10px;
  padding: 10px;*/
  text-align: center;
  /*height: 20%;
  width: 50%;
  float: left;*/
}
.poster6 {
  height: 33%;
  width: 49%;
  float: left;
  font-size: small;
  border: solid 1px lightgray;
  margin: 1px;
}
.bottomArea {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  height: 100%;
}
.qrcode {
  margin: 20px;
}
.subpage {
  /*
  display: flex;
  flex-direction: column;
  */
  height: 100%;
  display: block;
  float: left;
  width: 100%;
}
.subpageFlyers {
  height: 100%;
}
.subpageA {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.subpageA5 {
  height: 50%;
}
.subpageA5Top {
  border-bottom: dashed 1px lightgray;
}
.subpageContent {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.subpageA5Top .subpageContentA5 {
  margin-bottom: 20mm;
}
.subpageA5:not(.subpageA5Top) .subpageContentA5 {
  margin-top: 20mm;
}

.subpage6 {
  height: unset;
}
.subpage6 {
  display: block;
  float: left;
  height: 33%;
  width: 49%;
  font-size: small;
  border: solid 1px lightgray;
  margin: 1px;
}
.logoAppInPoster {
  width: 40%;
}
`

export const styleRulesA5 = `
.pageContent {
  font-size: x-large;
  padding: unset;
}

`


export const styleRulesA4Vertical = `
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #FAFAFA;
  font: 12pt "Tahoma";
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.pageContainer {
  padding: 20mm;
  margin: 10mm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
}
.pageContainerA4Vertical {
  width: 210mm;
  min-height: 297mm;
  max-height: 297mm;
}
.pageContent {
  font-size: x-large;
  /*padding: 1cm;*/
  /*height: 251mm;*/
  height: 257mm;
  border: 5px rgba(255, 0, 0, 0) solid;
  outline: 16mm rgba(255, 234, 234, 0) solid;
}
.pageContentAnimation {
  animation-name: flash_border;
  -moz-animation-name: flash_border;
  -webkit-animation-name: flash_border;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  /*
  animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;*/
}
@keyframes flash_border {
    ${animationKeyFrames}
}

@-webkit-keyframes flash_border {
    ${animationKeyFrames}
}

@-moz-keyframes flash_border {
    ${animationKeyFrames}
}


@page {
  size: A4;
  margin: 0;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;        
  }
  .pageContainer {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
  .pageContent {
    border: none;
    outline: 16mm rgba(255, 0, 0, 0) solid;
  }
}
`
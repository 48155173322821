<template>
    <div v-if="aEnterprise">
      <div >
        {{ t('enterpriseNameTitle') }} <span>{{aEnterprise.EIName}}</span>
         (<span>{{aEnterprise.EISearchId}}</span>)
      </div>
    </div>
    <div v-else-if="displayNoEnterprise">
      {{ t('enterpriseNotDefinedTitle') }} 
    </div>
</template>

<script lang="ts">
import { toRefs, reactive, computed } from 'vue'
import { useI18n } from "@/engineproject/default/common/i18n";

//import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

import { useAppStore } from '@/engineproject/store/appStore';
import { useUserStore } from '@/engineproject/store/userStore';
import { UserTypes } from "@/engineproject/domainModel/userTypes"
import { EnterpriseInfoTypes } from '@/engineproject/domainModel/enterpriseInfoTypes';

export default {
  props: {
    aEnterprise: {
      type: Object as () => EnterpriseInfoTypes.EnterpriseInfo,
      required: true,
    },
    displayNoEnterprise: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup(props: any, context: any) {
    const appStore = useAppStore();
    const userStore = useUserStore();
    const { t } = useI18n();
    const page = reactive({
    });


    return {
      ...toRefs(page),
      t,
    };
  },
  components: { 
    //Button,
    //InputText
  },
};
</script>

<style lang="scss" scoped>
input {
  width: 100%;
}

button {
  display: block;
  margin-top: 10px;
}
</style>

//import  "@/engineproject/domainModel/userProjectTypes"
import { EnterpriseInfoObject } from "@/engineproject/domainModel/enterpriseInfoToolsTypes";
import { EnterpriseInfoTypes } from "@/engineproject/domainModel/enterpriseInfoTypes";
import { EnterpriseInfoProjectTypes } from "./enterpriseInfoProjectTypes";


export class EnterpriseInfoProjectObject extends EnterpriseInfoObject  {
  //constructor(src: EnterpriseTypes.User);
  constructor();
  constructor(src: EnterpriseInfoTypes.EnterpriseInfo);
  constructor(src: EnterpriseInfoProjectTypes.EnterpriseInfo);
  constructor(src?: any) {
    super(src)
    // console.error(" IN constructor " + JSON.stringify(this))
    // console.error(" IN constructor " + this.getADId())
    this.setMandatoryDataFieldNames([
      "EIName", "EISearchId"
    ])
  }

  deepCopyProjectEnterpriseInfo = () => {
    const newDoc = new EnterpriseInfoProjectObject(this.OEIData);
    return newDoc 
  }
  detectChangesInProjectEnterpriseInfo = (newEnterpriseInfoProject: EnterpriseInfoProjectObject) => {
    return this.detectChangesInEnterpriseInfo(newEnterpriseInfoProject)
  }
  updateEnterpriseInfo = (newOEIData: EnterpriseInfoTypes.EnterpriseInfo) => {
    const newDoc = new EnterpriseInfoProjectObject({
      ...this.OEIData,
      ...newOEIData
    });
    return newDoc 
  }
  
  exportToFirebaseObject() {
    const preparedDoc: any = super.exportToFirebaseObject()
    return preparedDoc
  }
}
  
//import frMsg from "./locales/fr/frSelectUrlOrPrompt.json";
//import enMsg from "./locales/en/enSelectUrlOrPrompt.json";
import { LocaleMessages, VueMessageType } from 'vue-i18n'

import {useI18nWithMessages/*, loadLocaleMessages*/} from '@/engineproject/i18nApp'
function loadLocaleMessagesFromContext(aContext: __WebpackModuleApi.RequireContext, mergeInto: LocaleMessages<VueMessageType>): LocaleMessages<VueMessageType> {
  //const messages: LocaleMessages<VueMessageType> = {}
  aContext.keys().forEach(key => {
    //console.error("key " + key)
    const matched = key.match(/([A-Za-z0-9-_]+)\//i)
    if (matched && matched.length > 1) {
      //console.error("keymatched " + key)
      const locale = matched[1]
      //console.error("locale " + locale)
      //console.error("aContext(key).default " + aContext(key).default)
      mergeInto[locale] = {
    // eslint-disable-next-line 
    // @ts-ignore
    ...mergeInto[locale],
        ... ((aContext(key).default !== undefined) ? aContext(key).default : aContext(key))
      }
    }
  })
  //console.error("loadLocaleMessages:")
  //console.dir(mergeInto)
  return mergeInto
}

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  //const projectLocales = require.context('./engineproject/default/common/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  let messages: LocaleMessages<VueMessageType> = {}
  messages = loadLocaleMessagesFromContext(locales, messages)
  // Doesn't for the moment plugin config is locale ???? messages = loadLocaleMessagesFromContext(projectLocales, messages)
  
  /*
  locales.keys().forEach(key => {
    //console.error("key " + key)
    const matched = key.match(/([A-Za-z0-9-_]+)\//i)
    if (matched && matched.length > 1) {
      //console.error("keymatched " + key)
      const locale = matched[1]
      //console.error("locale " + locale)
      messages[locale] = {
        ...messages[locale],
        ...locales(key).default
      }
    }
  })
  */
  //console.error("loadLocaleMessages:")
  //console.dir(messages)
  return messages
}


const useI18n = () => {
  return useI18nWithMessages(loadLocaleMessages())
  //return {useI18n: useI18nWithMessages(loadLocaleMessages())}
  /*return useI18nWithMessages({
    en: enMsg,
    fr: frMsg,
  })*/
}

export {
  useI18n
}
<template>
  <slot
    name="content"
    :aUserProfile="aUserProfile"
  ></slot>
</template>

<script lang="ts">
import { inject, reactive, toRefs } from "vue";

import { UserProjectTypes } from '@/engineproject/default/common/domainModel/userProjectTypes';

export default {
  props: {
    aUserProfile: {
      type: Object as () => UserProjectTypes.UserProfile,
      required: true
    },
  },
  setup(props: any, context: any) {
    const formatDate = inject('formatDate') as any;
    const page = reactive({
      aUserProfile: props.aUserProfile,
    });
    return {
      ...toRefs(page),
      formatDate,   
    }
  },
}


</script>

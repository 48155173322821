import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "limitMaxReachedInCollection"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.limitMaxReachedInCollection > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, _toDisplayString($setup.t('searchMaxLimitReachedText1')), 1),
        _createElementVNode("div", null, _toDisplayString($setup.t('searchMaxLimitReachedText2')), 1)
      ]))
    : _createCommentVNode("", true)
}
<template>
  <div class="userProfileViewContent">
    <div>
      <div v-if="aUserProfile.getUPPictureUrl()" class="profilImgContainer">
        <img :src="aUserProfile.getUPPictureUrl()" />
      </div>
      <div>
        {{ t("userProfilePseudoTitle") }}{{ aUserProfile.getUPPseudo() }} 
      </div>
      <div v-if="aUserProfile.isPremiumUser() === true">
        {{ t("userProfilePremiumTitle") }}
      </div>
      <div v-if="aUserProfile.isAppBoss() === true" class="appBossArea">
        <div>
          {{ t("userProfileAppBossTitle") }}:
        </div>
        <div class="logoAppBossContainer">
          <div class="logoAppBoss"
            :title="t('appBossTitle')">
          </div>
        </div>
      </div>
      <div v-else class="appBossArea">
        <div>
          {{ t("userProfileWannaBeABossTitle") }}:
        </div>
        <div class="logoAppBossContainer"> 
          <div class="logoAppBossGreenAndRed"
            :title="t('userProfileWannaBeABossTitle')">
          </div>
        </div>
      </div>
      <div>
        Nbre de contacts: {{aUserProfile.getNbContacts()}}
        </div>
      <p v-if="aUserProfile.getUPDescription()">
        {{ t("userProfileDescTitle") }}{{ aUserProfile.getUPDescription() }}
      </p>
    </div>
    <div v-if="aUserProfile.getUPEmail()">
      <h4>{{ t("userProfilePersonnalSharedDataTitle") }}</h4> 
      <p v-if="aUserProfile.getUPName()">{{ t("userProfileNameTitle") }}
        {{ aUserProfile.getUPName() }} 
      </p>
      <div v-if="aUserProfile.getUPPhoneNumber()">{{ t("userProfilePhoneTitle") }}
        {{ aUserProfile.getUPPhoneNumber() }} 
        <PhoneNumberLinks :linkToPhoneNumber="aUserProfile.getUPPhoneNumber()"></PhoneNumberLinks>
      </div>

      <p v-if="aUserProfile.getUPEmail()">{{ t("userProfileEmailTitle") }}
        <a :href="'mailto:' + aUserProfile.getUPEmail()" >{{ aUserProfile.getUPEmail() }}
        </a>
      </p>
      <UserProfileViewTransactionsWithMe 
        v-on:refresh:display="$emit('refresh:display', $event)"
        :myContactUserProfile="aUserProfile"></UserProfileViewTransactionsWithMe>
    </div>
    <div v-else>
      <p>
        {{ t("userProfileNotInContactListTitle") }}
      </p>
      <p>
        {{ t("userProfileNoFullProfileDataTitle") }}
      </p>
    </div>
    <UserProfileBanControl :aUserProfile="aUserProfile">
    </UserProfileBanControl>
    <UserProfileClosedControl :aUserProfile="aUserProfile">
    </UserProfileClosedControl>
    <UserProfileExternOfEnterprise :aUserProfile="aUserProfile">
    </UserProfileExternOfEnterprise>
    <UserProfileAllowedEnterprises :aUserProfile="aUserProfile">
    </UserProfileAllowedEnterprises>

  </div>
</template>
<script lang="ts">
import { useI18n } from "@/engineproject/default/common/i18n";
import { UserProjectTypes } from '@/engineproject/default/common/domainModel/userProjectTypes';
import { reactive, toRefs } from "vue";

import UserProfileBanControl from '@/engineproject/components/UserProfile/UserProfileBanControl.vue'
import UserProfileAllowedEnterprises from '@/engineproject/components/Enterprise/UserProfileAllowedEnterprises.vue'
import UserProfileExternOfEnterprise from '@/engineproject/components/Enterprise/UserProfileExternOfEnterprise.vue'
import UserProfileClosedControl from '@/engineproject/components/UserProfile/UserProfileClosedControl.vue'
import UserProfileViewTransactionsWithMe from '@/engineproject/components/UserProfile/UserProfileViewTransactionsWithMe.vue'
import PhoneNumberLinks from '@/engineproject/components/UIElements/PhoneNumberLinks.vue';

export default {
  props: {
    aUserProfile: {
      type: Object as () => UserProjectTypes.UserProfile,
      required: true
    },
  },
  emits: ["refresh:display"],
  setup(props: any, context: any) {
    const {t} = useI18n()
    const page = reactive({
      aUserProfile: props.aUserProfile,
    });
    return { 
      ...toRefs(page),
      t,
    }
  },
  components: {
    PhoneNumberLinks,
    UserProfileBanControl,
    UserProfileClosedControl,
    UserProfileViewTransactionsWithMe,
    UserProfileAllowedEnterprises,
    UserProfileExternOfEnterprise,
  },
}
</script>

<template>
  <transition name="fade">
    <div  :class="((displayInModal === true) ? 'pModal ' : ' ') ">
      <div class="pContainer " :class="aAdDoc.getCSSClass()">
        <div class="resetBackground">
          <div class="headerLinks">
            <AdDocumentHeaderLinks  :aAdDoc="aAdDoc" 
              :userCanEdit="userCanEdit"
              :displayInModal="displayInModal"
              :userIsOwner="userIsOwner"
              @closeAdDocument="$emit('closeAdDocument')"
              @closeMapModal="$emit('closeMapModal')"
              ></AdDocumentHeaderLinks>
          </div>
          <div class="post">
            <ProjectAdDocumentViewMainPart :aAdDoc="aAdDoc" 
              :displayCommentsLikesOnly=true
              :displayInModal="displayInModal"
            ></ProjectAdDocumentViewMainPart>
          </div>
          <div v-if="aAdDoc.canHaveTransactions()" class="documentViewTransactions">
            <AdDocViewInvitations :aAdDoc="aAdDoc" 
              :ADColInvitations="aAdDoc.OADData.ADColInvitations" 
              :userIsOwner="userIsOwner" 
              :userCanEdit="userCanEdit"
            ></AdDocViewInvitations>
          </div>
          <div v-if="aAdDoc.canHaveTransactions()" class="documentViewTransactions">
            <AdDocViewPodiums :aAdDoc="aAdDoc" 
              :ADColPodiums="aAdDoc.OADData.ADColPodiums" 
              :userIsOwner="userIsOwner" 
              :userCanEdit="userCanEdit"
            ></AdDocViewPodiums>

            <AdDocViewTransactions :aAdDoc="aAdDoc" 
              :ADColTransactions="aAdDoc.OADData.ADColTransactions" 
              :userIsOwner="userIsOwner" 
              :userCanEdit="userCanEdit"
            ></AdDocViewTransactions>
          </div>
          <div v-if="aAdDoc.canHaveLikesAndComments()" class="documentViewLikesAndComments">
            <AdDocumentViewCommentsList :aAdDoc="aAdDoc" ></AdDocumentViewCommentsList>
            <AdDocumentViewLikesList :aAdDoc="aAdDoc" ></AdDocumentViewLikesList>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts">
import { useI18n } from "@/engineproject/default/common/i18n";
import { AdDocumentProjectTypes } from '@/engineproject/default/common/domainModel/adDocumentProjectTypes';

import AdDocumentViewCommentsList from '@/engineproject/components/AdDocument/AdDocumentViewCommentsList.vue';
import AdDocumentViewLikesList from '@/engineproject/components/AdDocument/AdDocumentViewLikesList.vue';
import AdDocViewInvitations from '@/engineproject/components/AdDocInvitation/AdDocViewInvitations.vue';
import AdDocViewTransactions from '@/engineproject/components/AdDocTransaction/AdDocViewTransactions.vue';
import AdDocViewPodiums from '@/engineproject/components/AdDocTransaction/AdDocViewPodiums.vue';
import AdDocumentHeaderLinks from '@/engineproject/components/AdDocument/AdDocumentHeaderLinks.vue';
import ProjectAdDocumentViewMainPart from '@/engineproject/default/common/components/AdDocument/ProjectAdDocumentViewMainPart.vue';

export default  {
  emits: ["close", "closeAdDocument"],
  props: {
    /*
    ATTENTION the following props are coming from the following parents
    2 DIFFERENT CASES:

    1-
    App.vue
      AdDocumentViewModalContainer (props are built and passed here to ...)
        ProjectAdDocumentView (no props here strange ??? but define here #content and #content contains ProjectAdDocumentViewMainPart)
          AdDocOnlyActionsOnOneDocTemplate (props are received here, strange ???, because <template><slot only ???)

          

    2-
    AdDocumentView or AdModelView or AdSuggestionView
      AdDocumentView  (props are built and passed here to ...)
        ProjectAdDocumentView (no props here strange ??? but define here #content and #content contains ProjectAdDocumentViewMainPart)
          AdDocOnlyActionsOnOneDocTemplate  (props are received here, strange ???, because <template><slot only ???)  
         
         AdDocActionsOnOneMainDoc

      DashboardSearchAndListView (can be for doc model or suggestion)  
        ProjectAdDocumentListView (define here #content)
          AdDocumentViewMainPartTemplate (AdDocActionButtonsInListOfDocsInListOfDocs + slot content + SocialNetworksButtons + AdDocumentPromotion)
        
      ProjectAdDocumentView
        ProjectAdDocumentViewMainPart (define here #content)
          AdDocumentViewMainPartTemplate (AdDocActionButtonsInListOfDocs + slot content + SocialNetworksButtons + AdDocumentPromotion)

    */
    aAdDoc: {
      type: Object as () => AdDocumentProjectTypes.AdDocument,
      required: true
    },
    userIsOwner: {
      type: Boolean,
      required: true
    },
    userCanEdit: {
      type: Boolean,
      required: true
    },
    displayInModal: {
      type: Boolean,
      required: true
    },
  },
  setup(props: any, context: any) {
    const {t} = useI18n()
    return {
      t,
    }
  },
  components: {
    ProjectAdDocumentViewMainPart, 
    AdDocumentViewLikesList,
    AdDocumentViewCommentsList,
    AdDocViewInvitations,
    AdDocViewTransactions,
    AdDocumentHeaderLinks,
    AdDocViewPodiums,
  },
}
</script>

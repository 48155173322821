<template>
  <FilterAdDocNavTemplate>
    <template #filterContent="{}">
      <Accordion  :activeIndex="pageActive" :multiple="true"  >

        <AccordionTab>
          <template #header>
            <span data-cy="">{{ t('filterLocalisationAccordeonTitle') }}</span>
            <i
              v-if="fGeoOn"
              class="pi pi-check requiredState"
              
            ></i>

          </template>      
            
          <FilterMyAds :adDocFileType="adDocFileType"></FilterMyAds>
          <FilterGeolocalisation></FilterGeolocalisation>

        </AccordionTab>
        <AccordionTab>
            <template #header>
              <span data-cy="">{{ t('filterCategoriesAccordeonTitle') }}</span>
            </template>      
            
            <div class="pContainer" v-for="aFilter in currentThemeFilters  "
              :key="aFilter.filterName">
            <FilterCheckbox v-if="aFilter.filterDataNameInDoc == 'ADCatsInUnivers'" :stateObject="aFilter.filterName"  ></FilterCheckbox>
            <FilterSelect v-if="aFilter.filterDataNameInDoc != 'ADCatsInUnivers'" :stateObject="aFilter.filterName"  ></FilterSelect>
          </div>
          </AccordionTab>
        <AccordionTab>
          <template #header>
            <span data-cy="">{{ t('filterDatesAccordeonTitle') }}</span>
          </template>      
          
          <FilterUsingCalendar titleTag=""></FilterUsingCalendar>
        </AccordionTab>
        <AccordionTab v-if="canSelectEntreprise">
          <template #header>
            <span data-cy="">{{ t('filterEnterpriseAccordeonTitle') }}</span>
          </template>      
            
           <FilterEnterpriseAds v-if="(adDocFileType !== 'T' && adDocFileType !== 'S')" titleTag=""></FilterEnterpriseAds>

        </AccordionTab>

      </Accordion> 

    </template>
  </FilterAdDocNavTemplate>
</template>

<script lang="ts">
import {  reactive, toRefs, computed, ref } from "vue";
import { useFilterAttributesStore } from "@/engineproject/store/filterAttributesStore";
import { useUserStore } from '@/engineproject/store/userStore';

import FilterCheckbox from '@/engineproject/components/Filter/FilterCheckbox.vue'; 
import FilterSelect from '@/engineproject/components/Filter/FilterSelect.vue'; 
import FilterGeolocalisation from '@/engineproject/components/Filter/FilterGeolocalisation.vue'; 
import FilterUsingCalendar from '@/engineproject/components/Filter/FilterUsingCalendar.vue'; 
import FilterEnterpriseAds from '@/engineproject/components/Filter/FilterEnterpriseAds.vue'; 
import FilterMyAds from '@/engineproject/components/Filter/FilterMyAds.vue'; 
import FilterAdDocNavTemplate from '@/engineproject/components/Filter/FilterAdDocNavTemplate.vue'; 
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import { useI18n } from "@/engineproject/default/common/i18n";
import { getOneOfAllFilters} from '@/engineproject/store/filterAttributesStore'

export default {
  props: {
    adDocFileType: {
      type: String,
      required: false,
      default: ""
    },
  },
  setup(props: any, context: any) {
    const { t } = useI18n();
    const userStore = useUserStore();

    const filterAttributesStore = useFilterAttributesStore()
    const fGeoOn = 
        ((getOneOfAllFilters(filterAttributesStore.state, "allLocalMyFilter")).filterSelected === "allLocalAdDocs")
    const pageActive = ref([])  // (fGeoOn) ? ref([0, 1]) : ref([1]) 

    const page = reactive({
      fGeoOn: computed(() => 
        ((getOneOfAllFilters(filterAttributesStore.state, "allLocalMyFilter")).filterSelected === "allLocalAdDocs")), 

      active: computed(() => 0),
      currentThemeFilters: computed(() => filterAttributesStore.getters.getAllFiltersForCurrentTheme),
      canSelectEntreprise: computed(() => {
          const ret =  userStore.state.aUserProfile.getUPColAllowedEnterprises().length > 0 || userStore.state.aUserProfile.getUPEnterprise() !== undefined
        return ret
       }
      ),
    });
    /*setTimeout(() => {
      pageActive.value = [1]
    }, 2000)*/
    return {
      ...toRefs(page),
      t,
      pageActive
    }
  },
  components: {
    FilterCheckbox,
    FilterAdDocNavTemplate,
    FilterSelect,
    FilterGeolocalisation,
    FilterEnterpriseAds,
    FilterMyAds,
    FilterUsingCalendar,
    Accordion,
    AccordionTab,
  },
}


</script>

<style lang="scss" scoped>

.requiredState {
  margin-left: auto;
}
</style>

const firebaseProjectConfigProduction = {
  apiKey: "AIzaSyBWnAJmyBNyivKay1Ws-lD_NC9XqGCjsvE",
  authDomain: "alomigopr.firebaseapp.com",
  projectId: "alomigopr",
  storageBucket: "alomigopr.appspot.com",
  messagingSenderId: "373356602860",
  appId: "1:373356602860:web:f09eb48bad1d5e1874ccc9",
  measurementId: "G-PJ5PP8W2LS",

  webAppClientIdForGoogleAPI: "373356602860-untjqvmutno3tn4dnjgcp4l492b54mn9.apps.googleusercontent.com",

  storageBucketBaseUrl: "https://firebasestorage.googleapis.com/v0/b/",
  storageBucketSuffix: "/o/",
};

export {
  firebaseProjectConfigProduction
}
<template>
  <SiteNavTemplate>
    <template #content="{showNav, newAppVersion, pPseudo, menuProfilItems, adminMenuProfilItems, pUserPictureUrl, 
      pUserNotifNotViewed, pUserInvitWithGainNotViewed, isAdmin,
      installNewAppVersion}">
      <header v-if="showNav() == true" :class="{OnSmallScreenOnly, OnLargeScreenOnly}">
        <section>
          <div class="col1 mainMenu">
            <ul class="inline floatLeft" >
              <li class="homeNameArea mainMenuAbout">
                <router-link to="/home">
                  <h3>
                    <AppColoredName></AppColoredName>
                    <img class="logoInMainMenu" src="@/engineproject/default/common/assets/images/android-chrome-192x192.png"/>
                    <div class="appVersion"> {{ vueAppVersion }} </div>
                  </h3>
                </router-link>
              </li>
              <li>
                <div class="">
                  <SwitchLanguage />
                </div>
              </li>
            </ul>
            <ul class="inline">
              <li v-if="newAppVersion!=''">
                <div class="profilMenu newAppVersionMenu" @click="installNewAppVersion()" :title="t('newAppVersionTitle')"
                   data-cy="cyMainMenuNewVersion">
                  <div class="pi pi-refresh"></div>
                  <div>{{ t('newAppVersionMenu') }}</div>
                </div>
              </li>
              <li v-if="isAdmin"  class="mainMenuAdmin" :class="isSelectedMenu('mainMenuAdmin')">
                <div class="profilMenu adminDisplayColor" data-cy="cyMainMenuAdmin"
                  @click="adminToggle"
                  :title="t('adminMode')">
                  <div class="pi pi-user-plus"></div>
                  <div>{{ t('adminMode') }}</div>
                </div>
                <Menu ref="adminMenu" :model="adminMenuProfilItems" :popup="true" />
              </li>
              <li class="mainMenuHome" :class="isSelectedMenu('mainMenuHome')">
                <router-link  :title="t('adsListingTitle')" :to="'/' + currentFilterAttributes" class="profilMenu" data-cy="cyMainMenuHome">
                  <div class="pi pi-home"></div>
                  <div  data-cy="cyAdsListingMenu" >{{t("adsListing")}}</div>
                </router-link>
              </li>
              <li class="mainMenuAdd" :class="isSelectedMenu('mainMenuAdd')">
                <router-link :title="t('addItemTitle')"  to="/add" class="profilMenu" data-cy="cyMainMenuAdd">
                <div class="pi pi-plus"></div>
                 <div>{{ t('addItem') }}</div>
                </router-link>
              </li>
              <li class="mainMenuNotifications" :class="isSelectedMenu('mainMenuNotifications')">
                <router-link :title="t('myNotificationsTitle')"  to="/notifications" class="profilMenu" data-cy="cyMainMenuNotif">
                  <div class="pi pi-bell"></div>
                  <div>{{ t('myNotifications') }}</div>
                  <div v-if="pUserInvitWithGainNotViewed>0" class="floatingCounter floatingCounterLeft"
                    :title="t('invitationWithGainReceived', {invitationsNumber: pUserInvitWithGainNotViewed})"
                    data-cy="cyMainInvitWithGainNotifNotViewedCounter">
                    <div>
                    €
                    </div>
                  </div>
                  <div v-if="pUserNotifNotViewed>0" class="floatingCounter" data-cy="cyMainMenuNotifNotViewedCounter">
                    <div>
                    {{ pUserNotifNotViewed }}
                    </div>
                  </div>
                </router-link>
              </li>
              <li class="mainMenuSettings" :class="isSelectedMenu('mainMenuSettings')">
                <div @click="toggle" class="profilMenu" data-cy="cyProfileMenu">
                  <div  class="profilImgContainer imgClassContainer " >
                    <FormatUserPicture :pUserPictureUrl="pUserPictureUrl">
                    </FormatUserPicture>
                  </div>
                  <div>
                    <div >{{ pPseudo }}</div>
                    <div >&#9660;</div>
                  </div>
                </div>
                <Menu ref="menu" :model="menuProfilItems" :popup="true" />
              </li>
            </ul>
          </div>
        </section>
      </header>
    </template>
  </SiteNavTemplate>

</template>

<script lang="ts">
import { reactive, toRefs, ref, computed } from 'vue'
import { useI18n } from "@/engineproject/default/common/i18n";
import { getOneOfAllFilters, useFilterAttributesStore } from '@/engineproject/store/filterAttributesStore';
import { useAppStore } from "@/engineproject/store/appStore";

import Menu from 'primevue/menu';

import SiteNavTemplate from '@/engineproject/components/AppMenu/SiteNavTemplate.vue';
import SwitchLanguage from '@/engineproject/components/AppMenu/SwitchLanguage.vue';
import FormatUserPicture from '@/engineproject/components/UIElements/FormatUserPicture.vue';
import AppColoredName from './AppColoredName.vue';

export default {
  props: {
    OnLargeScreenOnly: {
      type: Boolean,
      required: false,
      default: true
    },
    OnSmallScreenOnly: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  setup(props: any, context: any) {
    const filterAttributesStore = useFilterAttributesStore();
    const appStore = useAppStore();
    const {t} = useI18n()
    const page = reactive({
      vueAppVersion: process.env.VUE_APP_VERSION,
      mainMenuSelected: computed(() => appStore.state.mainMenuSelected),

      currentFilterAttributes: computed(() => getOneOfAllFilters(filterAttributesStore.state, "allLocalMyFilter").filterSelected),
      adminMenu: false
    });
    const menu = ref();
    const toggle = (event: any) => {
      menu.value.toggle(event);
    }
    const adminMenu = ref();
    const adminToggle = (event: any) => {
      adminMenu.value.toggle(event);
    }

    const isSelectedMenu = (menuClass: string) => {
      if (menuClass === page.mainMenuSelected) {
        return "mainMenuSelected"
      }
      return ""
    }
    return {
      ...toRefs(page),
      t,
      isSelectedMenu,
      menu,
      toggle,
      adminMenu,
      adminToggle,
    }
  },
  components: {
    SiteNavTemplate,
    SwitchLanguage,
    Menu,
    FormatUserPicture,
    AppColoredName
},
}


</script>

import * as fb from './firebase'

import {EnterpriseInfoProjectTypes} from  "@/engineproject/default/common/domainModel/enterpriseInfoProjectTypes"
import {UserTypes} from  "@/engineproject/domainModel/userTypes"
import firebase from 'firebase';
import { firebaseStorage, firebaseAnalytics } from './firebase';
import { fullLocale } from '@/engineproject/i18nApp';
import { EnterpriseInfoProjectObject } from '@/engineproject/default/common/domainModel/enterpriseInfoProjectToolsTypes';
import { geohashQueryBounds,  distanceBetween} from "geofire-common";

const defaultProfile = {
  EIEnterpriseId: "",
  EISearchId: "",
  EIName: "",
  /*
  EILat?: number;
  EILng?: number;
  EIZipCode?: string;
  EIGeoHash?: string;
  EICity?: string;
  EIAddress?: string;
  EIPhone?: string;
  EIWeb?: string;
  */
} as EnterpriseInfoProjectTypes.EnterpriseInfo


interface SignupData {
  email: string;
  password: string;
  name: string;
  pseudo: string;
  description: string;
  profileUrl: string;
}

export interface EnterpriseAPIClient {
  addEnterprise(newEnterprise: {
    name: string;
    searchId: string;
  }): Promise<any>;
  deleteEnterprise(enterpriseId: string): Promise<any>;
  // updateEnterpriseInfo(user: EnterpriseInfoProjectObject, updateEmail: any): Promise<any>;
  updateContributionsHours(aEIEnterpriseId: string, contributionsEventId: string, nbHours: number): Promise<any>

  queryEnterprisesForAdmin(optionsQuery: any): Promise<{ 
    backendActionDone: boolean; errorMsg: string | undefined;
    OUPDataArrayFromBackend: any[];
    deconnected: boolean;
    moreElementsInCollection: boolean;
  }>;
}

class EnterpriseFirebase implements EnterpriseAPIClient {
  
  unsubscribeCB = undefined as any;
  lastSentToken = "";


  isConnected() {
    if (fb.auth === null || fb.auth.currentUser === null) {
      return {
        errorMsg: "Not connected",
        userId: ""
      }
    }
    firebaseAnalytics.logEvent('isConnected-' + fb.auth.currentUser.uid, {
      AEDUid: fb.auth.currentUser.uid
    });
    return  {
      errorMsg: "",
      userId: fb.auth.currentUser.uid
    }
  }

  isUserOK(userId: string) {
    const isC = this.isConnected()
    if (isC !== undefined && isC.userId === ""){
      return {
        ...isC,
        userId: ""
      }
    }
    if (userId != fb!.auth!.currentUser!.uid) {
      return {
        errorMsg: "invalid userid in updateProfile",
        userId: ""
      }
    }
    return {
      errorMsg: "",
      userId: fb!.auth!.currentUser!.uid
    }
  }
  returnAuthCatchError(error: any) {
    console.log(error);
    const errorMessage = error.code.replace("/","-"); //error.message;
    /*if (errorMessage === "auth/weak-password") {
      //alert("The password is too weak.");
      return {
        backendActionDone: false,
        errorMsg: "The password is too weak.",
        userId: undefined
      }
    } else {*/
      //alert(errorMessage);
      return {
        backendActionDone: false,
        errorMsg: errorMessage,
        userId: undefined
      }
    //}
  }

  async getUserProfile(queryParams: {userId: string; aPseudo: string}) {
    try {
      // fetch user profile
      // console.error("user " + userId)
      let userPublicFBProfile: any
      if (queryParams.userId && queryParams.userId !== "") {
        userPublicFBProfile = await fb.usersPublicCollection.doc(queryParams.userId).get()
      } else {
        const userProfileSnapshot = await fb.usersPublicCollection.where("UPPseudo", "==", queryParams.aPseudo).limit(1).get()
        if (userProfileSnapshot.size === 1) {
          userPublicFBProfile = userProfileSnapshot.docs[0]
        } else {
          return {
            backendActionDone: false,
            errorMsg: "unknownPseudo",
            userProfileObj: undefined,
            userPrivateProfileDocObserver: undefined,
            userPublicProfileDocObserver: undefined
          }
        }
      }
      let userProfileObj = {} as EnterpriseInfoProjectTypes.EnterpriseInfo
      // set user profile in state
      if (userPublicFBProfile === undefined || userPublicFBProfile.data() === undefined) {
        userProfileObj = {
          ... defaultProfile
        }
      } else {
        userProfileObj = {
          ... defaultProfile,
          ...userPublicFBProfile.data()
        }
      }
      userProfileObj.EIEnterpriseId = userPublicFBProfile.id //queryParams.userId
        return {
          backendActionDone: true,
          errorMsg: undefined,
          userProfileObj: userProfileObj,
          userPrivateProfileDocObserver: undefined,
          userPublicProfileDocObserver: undefined
        }
    } catch(e) {
      console.error(e)
      return {
        backendActionDone: false,
        errorMsg: ""+e,
        userProfileObj: undefined,
        userPrivateProfileDocObserver: undefined,
        userPublicProfileDocObserver: undefined
      }
    }
  }

  async pseudoExists(aPseudo: string) {
    return await fb.usersPublicCollection.where("UPPseudo", "==", aPseudo).get().then((res) => {
      console.log(res.size + "  return pseudoExists : " + (res.size !== 0))
      return (res.size !== 0)
    });
  }
  async signupValidationStep(signupData: SignupData) {
    console.error("signupValidationStep")
    if (signupData.password === "") {
        return {
          backendActionDone: false,
          errorMsg: "InvalidPassword",
          userId: undefined
        }
    } else if (signupData.email === "" ) {
        return {
          backendActionDone: false,
          errorMsg: "InvalidEmail",
          userId: undefined
        }
    } else if (signupData.pseudo === "") {
        return {
          backendActionDone: false,
          errorMsg: "InvalidPseudo",
          userId: undefined
        }
    }
    if (fb.auth === null) {
      return {
        backendActionDone: false,
        errorMsg: "Firebase auth is null",
        userId: undefined
      }
    }
    // pseudo  already in our collection?
    // For the moment useless to make the test for email, it will be done by firebase createUserWithEmailAndPassword ?
    // TODO Verify the previous case when other type of signup will be available (facebook ...)
    if (await this.pseudoExists(signupData.pseudo) === true) {
      return {
        backendActionDone: false,
        errorMsg: "PseudoAlreadyUsed",
        userId: undefined
      }
    }
    return undefined
  }
  async addEnterprise(newEnterprise: {
    name: string;
    searchId: string;
  }): Promise<any> {
    //const newDoc = await fb.enterpriseInfoCollection.add(addedDoc)

    return fb.enterpriseInfoCollection.add({
      EISearchId: newEnterprise.searchId,
      EIName: newEnterprise.name,
      EICreatedOn: new Date(),
    }).then((res) => {
      console.log('Write user public part succeeded!');
      return {
        backendActionDone: true,
        enterprise: res,
        errorMsg: ""
      }
    }, (reason) => {
      return {
        backendActionDone: false,
        enterprise: undefined,
        errorMsg: "error " + reason
      }
    });
  }  
  
  async deleteEnterprise(enterpriseId: string): Promise<any> {

    return fb.enterpriseInfoCollection.doc(enterpriseId).delete().then(() => {
      console.log('Deleteenterprise succeeded!');
      return {
        backendActionDone: true,
        errorMsg: ""
      }
    }, (reason) => {
      return {
        backendActionDone: false,
        errorMsg: "error " + reason
      }
    });
  }

  async updateContributionsHours(aEIEnterpriseId: string, contributionsEventId: string, nbHours: number) {
    try {
      const doc = fb.enterpriseInfoCollection.doc(aEIEnterpriseId).collection('EIColContributionsEvents').doc(contributionsEventId)
      if (doc) {
        await doc.update({
          ECNbHours: nbHours
        })
        return {
          backendActionDone: true,
          errorMsg: ''
        }
      }
      return {
        backendActionDone: false,
        errorMsg: 'ContributionsEvents not found'
      }
    } catch (e) {
      const errorMsg = "Error in updateContributionsHours " + e
      console.error(errorMsg)
      return {
        backendActionDone: false,
        errorMsg
      }
    }
  
  }

  
  // NOT IMPLEMENTED
  async updateEnterpriseInfo(anEnterpriseInfoObject: EnterpriseInfoProjectObject, updateEmail: any) {
    // console.error("updateProfile")
    const enterpriseInfoFirebaseObject = anEnterpriseInfoObject.exportToFirebaseObject()
    const isC = this.isUserOK(enterpriseInfoFirebaseObject.UPUserId)
    if (isC.userId === ""){
      return {
        ...isC,
        backendActionDone: false
      }
    }
    const userId = isC.userId 
    // update user object
    // return this.updatePublicProfile(enterpriseInfoFirebaseObject, userId)
  }


  // async updatePublicProfile(user: EnterpriseInfoProjectTypes.EnterpriseInfo, userId: string) {
  //   console.error("updatePublicProfile")
  //   // update user object
  //   return fb.usersPublicCollection.doc(userId).update({
  //     EIName: (user.EIName !== undefined) ? user.EIName : "",
  //   }).then(async () => {
  //     return {
  //       backendActionDone: true,
  //       errorMsg: "",
  //       userId: userId
  //     }

  //   }, (reason) => {
  //     return {
  //       backendActionDone: false,
  //       errorMsg: "Error in update profile " + reason,
  //       userId: userId
  //     }
  //   })
  // }

  validAuth() {
    if (fb.auth === null || fb.auth.currentUser === null) {
      return false
    }
    return true
  }


  getEnterprisesCollection(optionsQuery: any) {
    const filteredCollection = fb.enterpriseInfoCollection
    return filteredCollection
  }
  cleanupProfileDocumentSnapshotFromBackend(doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>): EnterpriseInfoProjectTypes.EnterpriseInfo | undefined{
    const aProfileFirebase = doc.data()
    if (aProfileFirebase) {
      aProfileFirebase.EIEnterpriseId = doc.id
      return this.cleanupProfileFromBackend(aProfileFirebase)
    }
    return undefined
  }

  cleanupProfileFromBackend(doc: firebase.firestore.DocumentData): any {
    //const aAdDoc = doc.data()
    const aProfileFirebase = doc
    //aAdDoc!.ADId = doc.id
    //console.error("cleanupProfileFromBackend " + JSON.stringify(aProfileFirebase))
    // console.error("aProfileFirebase " + aProfileFirebase.UPName)
    // console.error("aProfileFirebase " + aProfileFirebase.EICreatedOn)
    if (aProfileFirebase && aProfileFirebase.UPLocation == undefined) {
      aProfileFirebase.UPLocation = {}
    }
    if (aProfileFirebase && aProfileFirebase.EICreatedOn !== undefined && aProfileFirebase.EICreatedOn.seconds !== undefined ) {
      aProfileFirebase.EICreatedOn = aProfileFirebase.EICreatedOn.toDate()
    }
    if (aProfileFirebase && aProfileFirebase.UPSubscriptionCreatedOn !== undefined && aProfileFirebase.UPSubscriptionCreatedOn.seconds !== undefined ) {
      aProfileFirebase.UPSubscriptionCreatedOn = aProfileFirebase.UPSubscriptionCreatedOn.toDate()
    }
    if (aProfileFirebase && aProfileFirebase.UPSubscriptionCancelledOn !== undefined && aProfileFirebase.UPSubscriptionCancelledOn.seconds !== undefined ) {
      aProfileFirebase.UPSubscriptionCancelledOn = aProfileFirebase.UPSubscriptionCancelledOn.toDate()
    }
    if (aProfileFirebase && aProfileFirebase.UPSubscriptionEndedOn !== undefined && aProfileFirebase.UPSubscriptionEndedOn.seconds !== undefined ) {
      aProfileFirebase.UPSubscriptionEndedOn = aProfileFirebase.UPSubscriptionEndedOn.toDate()
    }
    return aProfileFirebase
  }



  async queryLocalProfiles(optionsQuery: any, filteredCollection: any) {
    // Find cities within 50km of London
    const center = optionsQuery.nearLatLng; //[51.5074, 0.1278];
    const radiusInM = ((optionsQuery.nearRadiusInM !== undefined) ?
      optionsQuery.nearRadiusInM : 50000); //50 * 1000;

    // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    // a separate query for each pair. There can be up to 9 pairs of bounds
    // depending on overlap, but in most cases there are 4.
    const bounds = geohashQueryBounds(center, radiusInM);
    const promises = [];
    for (const b of bounds) {
      const q = filteredCollection 
        .orderBy('UPLocation.LPGeoHash')
        .startAt(b[0])
        .endAt(b[1]);

      promises.push(q.get());
    }

    // Collect all the query results together into a single list
    const snapshotAdDoc = await Promise.all(promises)
    const matchingAdDocs = Array<any>();

    for (const snap of snapshotAdDoc) {
      for (const doc of snap.docs) {
        if (doc !== undefined && doc.data() !== undefined) {
          const lat = doc.get('UPLocation.LPLat');
          const lng = doc.get('UPLocation.LPLng');

          // We have to filter out a few false positives due to GeoHash
          // accuracy, but most will match
          const distanceInKm = distanceBetween([lat, lng], center);
          const distanceInM = distanceInKm * 1000;
          if (distanceInM <= radiusInM) {
            /*
            const adDocFBD = doc.data()
            adDocFBD.ADId = doc.id
            matchingAdDocs.push(new EnterpriseInfoProjectObject(adDocFBD) )
            */
            const adDocFBD = this.cleanupProfileDocumentSnapshotFromBackend(doc)
            if (adDocFBD !== undefined //&& 
            // (optionsQuery.currentFilter === "myAdDocs" || (adDocFBD!.ADDescription != "" && adDocFBD!.ADDescription != null)
              ) {
              matchingAdDocs.push(new EnterpriseInfoProjectObject(adDocFBD))
            }
          }
        }
      }
    }
    return {
      profilesArray: matchingAdDocs,
      backendActionDone: false,
      errorMsg: undefined
    }
  }

  getTheLimit(optionsQuery: any) {
    // optionsQuery.maxLimit = 10
    return (optionsQuery !== undefined && optionsQuery.maxLimit !== undefined) ? optionsQuery.maxLimit : 3
  }

  addOrderToProfilesFilteredCollection(optionsQuery: any, filteredCollection: any) {
    let firebaseQuery
    const theLimit = this.getTheLimit(optionsQuery)
      //console.error("adminProfilesQuery " + optionsQuery.adminProfilesQuery)
      if (optionsQuery.nextProfile !== undefined) {
        console.error("optionsQuery.nextProfile " + optionsQuery.nextProfile.getUPUserId() + " - " + optionsQuery.nextProfile.getEICreatedOn())
        firebaseQuery = filteredCollection.orderBy('EICreatedOn', 'desc').startAfter(optionsQuery.nextProfile.getEICreatedOn()).limit(theLimit)
      } else if (optionsQuery.previousProfile !== undefined) {
        firebaseQuery = filteredCollection.orderBy('EICreatedOn', 'desc').endAt(optionsQuery.previousProfile.getEICreatedOn()).limit(theLimit)
      } else {
        //firebaseQuery = filteredCollection.orderBy('EICreatedOn', 'desc').limit(theLimit)
        //console.error("queryRecentProfiles theLimit " + theLimit)
        firebaseQuery = filteredCollection.orderBy('EICreatedOn', 'desc').limit(theLimit)
      }
    return firebaseQuery
}

  async queryRecentProfiles(optionsQuery: {
    previousProfile: EnterpriseInfoProjectObject;
    nextProfile: EnterpriseInfoProjectObject;
    maxLimit: number;
    suggestionFilter: boolean;
    templateFilter: boolean;
    currentFilter: string;
    nearLatLng: number;
    nearRadiusInM: number;
    universAndCatFilter: string;
    adminProfilesQuery: boolean;
  }, filteredCollection: any) {
  let firebaseQuery
  // console.error("queryRecentProfiles " + JSON.stringify(optionsQuery))
  const matchingAdDocs = Array<any>();
  let snapshotAdDocLength = 0
  try {
    firebaseQuery = this.addOrderToProfilesFilteredCollection(optionsQuery, filteredCollection)
    // console.error("queryRecentProfiles 2")
    const snapshotAdDoc = await firebaseQuery.get()
    snapshotAdDoc.forEach((doc: any) => {
      snapshotAdDocLength++
      if (doc !== undefined && doc.data() !== undefined) {
        const adDocFBD = this.cleanupProfileDocumentSnapshotFromBackend(doc)
        if (adDocFBD !== undefined //&& 
         // (optionsQuery.currentFilter === "myAdDocs" || (adDocFBD!.ADDescription != "" && adDocFBD!.ADDescription != null)
          ) {
          matchingAdDocs.push(new EnterpriseInfoProjectObject(adDocFBD))
        }
      }
    })
  } catch (e) {
    const errorMsg = "Error in queryRecentProfiles " + e
    console.error(errorMsg)
    return {
      profilesArray: matchingAdDocs,
      snapshotAdDocLength: snapshotAdDocLength,
      backendActionDone: false,
      errorMsg
    }
  }
  return {
    profilesArray: matchingAdDocs,
    snapshotAdDocLength: snapshotAdDocLength,
    backendActionDone: true,
    errorMsg: undefined
  }
}  
  async queryEnterprisesForAdmin(optionsQuery: any) {
    const profilesArray = Array<any>()
    let moreElementsInCollection = false
    const ret = {
      OUPDataArrayFromBackend: profilesArray,
      deconnected: true,
      moreElementsInCollection,
      backendActionDone: false,
      errorMsg: undefined
    }
    optionsQuery.adminProfilesQuery = false
    //console.error("queryEnterprisesForAdmin")
    if (this.validAuth() !== true) {
      return ret
    }
    ret.deconnected = false
    //console.error("queryEnterprisesForAdmin" + JSON.stringify(ret))
    try {
      let queryResult
      const filteredCollection = this.getEnterprisesCollection(optionsQuery)
      if (optionsQuery.nearLatLng !== undefined) {
        queryResult = await this.queryLocalProfiles(optionsQuery, filteredCollection)
      } else {
        queryResult = await this.queryRecentProfiles(optionsQuery, filteredCollection)
        const theLimit = this.getTheLimit(optionsQuery)
        // console.error(" profilesArray.length " + queryResult.profilesArray.length + " ==== " + theLimit)
        if (queryResult.snapshotAdDocLength === theLimit) {
          moreElementsInCollection = true
        }
      }
      // console.error("queryProfiles" + JSON.stringify(queryResult))
      return {
        OUPDataArrayFromBackend: queryResult.profilesArray,
        deconnected: false,
        moreElementsInCollection,
        backendActionDone: queryResult.backendActionDone,
        errorMsg: queryResult.errorMsg
      }
    } catch(e) {
      const errorMsg = "queryProfiles 1 " + e
      console.error(errorMsg)
      return {
        OUPDataArrayFromBackend: profilesArray,
        deconnected: false,
        moreElementsInCollection,
        backendActionDone: false,
        errorMsg,
      }
    }

  }

  
  async getContributionsForEnterprise(optionsQuery: any) {
    const res = Array<any>();
    try {
      console.error("getContributionsForEnterprise optionsQuery.EIEnterpriseId " + optionsQuery.aEIEnterpriseId)

      const docs = await fb.enterpriseInfoCollection.doc(optionsQuery.aEIEnterpriseId).collection('EIColContributionsEvents').get()
      console.error("getContributionsForEnterprise " + docs)
      docs.forEach((doc: any) => {
        const contributions: any = doc.data()
        contributions.ECId = doc.id
        contributions.ECActivityDateHourStartsOn = contributions.ECActivityDateHourStartsOn.toDate()

        res.push(contributions)
      })
    } catch (e) {
      console.error("error getContributionsForEnterprise ")
      console.error("error getContributionsForEnterprise " + e)
      return {
        backendActionDone: false,
        errorMsg: e + "",
        aEIColContributionsEvents: undefined
      }
    }
    console.error("getContributionsForEnterprise res " + res.length)
    return {
      backendActionDone: true,
      errorMsg: undefined,
      aEIColContributionsEvents: res
    }
  }

}

export default EnterpriseFirebase

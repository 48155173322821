
import { InjectionKey } from 'vue'
import { createStore, useStore, Store } from 'vuex'

import {  enterpriseFirebase } from '@/engineproject/backend/firebase';
import {  blockApplicationUI, saveDomainResultsInStores } from '@/engineproject/store/store'
import { AppState } from './appStore';
import { UserState } from './userStore';
import { EnterpriseInfoProjectObject } from '@/engineproject/default/common/domainModel/enterpriseInfoProjectToolsTypes';



export interface EnterpriseState {
  allEnterprises: Array<EnterpriseInfoProjectObject>; // Array<EnterpriseInfoTypes.EnterpriseInfo>;
  filteredEnterprises: Array<EnterpriseInfoProjectObject>; // Array<EnterpriseInfoTypes.EnterpriseInfo>;
  moreElementsInCollection: boolean;
  currentUserEnterprise?: EnterpriseInfoProjectObject;
}

export const enterpriseKey: InjectionKey<Store<EnterpriseState>> = Symbol()

// define your own `useStore` composition function
export function useEnterpriseStore() {
  return useStore(enterpriseKey)
}

export const enterpriseStore = createStore<EnterpriseState>({
  state: {
    // The following enterprises are registered as customers
    allEnterprises: [],
    filteredEnterprises: [],
    moreElementsInCollection: true,
    currentUserEnterprise: undefined,
  },
  mutations: {

    setAllEnterprises(state, { newEnterpriseList }) {
      state.allEnterprises = [...newEnterpriseList]
    },
    setCurrentUserEnterprise(state, { userEnterprise }) {
      state.currentUserEnterprise = userEnterprise
    },
    setFilteredEnterprises(state, { newEnterpriseList }) {
      state.filteredEnterprises = [...newEnterpriseList]
    },
    setMoreElementsInCollection(state, val) {
      state.moreElementsInCollection = val
    },
    addNewFilteredEnterprises(state, val) {
      // console.error("addNewFilteredEnterprises " + JSON.stringify(val))
      state.filteredEnterprises = state.filteredEnterprises.concat(val)
    },

    addNewAllEnterprises(state, val) {
      // console.error("addNewFilteredEnterprises " + JSON.stringify(val))
      state.allEnterprises = state.allEnterprises.concat(val)
    },
    setContributionsArrayInEnterprise(state, newData) {
      if (state.currentUserEnterprise) {
        console.error('newData ' + JSON.stringify(newData))
        state.currentUserEnterprise.setContributionsArrayInEnterprise(newData)
      }
    },
    setContributionsHoursInEnterprise(state, newData) {
      if (state.currentUserEnterprise) {
        state.currentUserEnterprise.setContributionsHoursInEnterprise(newData)
      }
    },

  },

  getters: {
   /* userProfileLatLng: (state, getters) => {
      return state.aUserProfile.getUserProfileLatLng()
    },
    getDefaultUniversAndCatsInUnivers: (state, getters) => {
      return state.aUserProfile.getDefaultUniversAndCatsInUnivers()
    },
    profileImgPathAndFile: (state) => {
      return  state.aUserProfile.getProfileImgPathAndFile()
    }*/

  },
  actions: {
 

    async setCurrentUserEnterprise({ commit }, newData) {
      console.error('setCurrentUserEnterprise ' + JSON.stringify(newData))
      commit('setCurrentUserEnterprise', newData)
    },

    async deleteEnterprise({ state }, options: {
      enterpriseId: string;
      appStore: Store<AppState>;
    }) {
      
      const res = await blockApplicationUI(options, async () => {
        const resultFor = await enterpriseFirebase.deleteEnterprise(options.enterpriseId)
        if (resultFor.backendActionDone === false) {
          options.appStore.commit("setErrorMsg", { errorMsg: resultFor.errorMsg });
        }
        return resultFor
      })
      console.error(" res " + JSON.stringify(res))
      return res

    },

    async addEnterprise({ state }, options: {
      newEnterprise: {
        name: string;
        searchId: string;
      };
      appStore: Store<AppState>;
    }) {
      
      const res = await blockApplicationUI(options, async () => {
        const resultFor = await enterpriseFirebase.addEnterprise(options.newEnterprise)
        if (resultFor.backendActionDone === false) {
          options.appStore.commit("setErrorMsg", { errorMsg: resultFor.errorMsg });
        }
        return resultFor
      })
      console.error(" res " + JSON.stringify(res))
      return res

    },
    
    async queryEnterprises({ state, commit, dispatch }, param: {
      optionsQuery: any;
      appStore: Store<AppState>; userStore: Store<UserState>;
    }) {
      //let queryResult
      //if (param.userStore.state.aUserProfile.isAdmin() === true) {
      const  queryResult = await enterpriseFirebase.queryEnterprisesForAdmin(param.optionsQuery)
      /*} else {
        queryResult = await userFirebase.queryContacts(param.optionsQuery)
      }*/
      if (queryResult.deconnected === true) {
        commit('setFilteredEnterprises', [])

        dispatch("logout", { appStore: param.appStore })
      } else {
        commit('setMoreElementsInCollection', queryResult.moreElementsInCollection)
        if (queryResult.backendActionDone === false) {
          param.appStore.commit("setErrorMsg", { errorMsg: queryResult.errorMsg });
          return
        }
        if (queryResult.OUPDataArrayFromBackend.length === 0) {
          commit('setMoreElementsInCollection', false)
          return
        }
        // console.error(" queryResult " + JSON.stringify(queryResult))

        await saveDomainResultsInStores(param.appStore, commit, queryResult, {
          processTag: 'addNewFilteredEnterprises',
          validObj: queryResult.OUPDataArrayFromBackend,
          unknownErrorMsg: "Unknown error during query queryEnterprises process"
        })
      }
    },
        
    async queryAllEnterprises({ state, commit, dispatch }, param: {
      optionsQuery: any;
      appStore: Store<AppState>; userStore: Store<UserState>;
    }) {
      //let queryResult
      //if (param.userStore.state.aUserProfile.isAdmin() === true) {
        //commit("setMoreElementsInCollection", true)
        commit("setAllEnterprises",  {newEnterpriseList: []} );
  
      const  queryResult = await enterpriseFirebase.queryEnterprisesForAdmin({
        previousProfile: undefined,
        nextProfile: undefined,
        maxLimit: 1000
      })
      /*} else {
        queryResult = await userFirebase.queryContacts(param.optionsQuery)
      }*/
      if (queryResult.deconnected === true) {
        commit('setAllEnterprises', [])
        dispatch("logout", { appStore: param.appStore })
      } else {
        //commit('setMoreElementsInCollection', queryResult.moreElementsInCollection)
        if (queryResult.backendActionDone === false) {
          param.appStore.commit("setErrorMsg", { errorMsg: queryResult.errorMsg });
          return
        }
        if (queryResult.OUPDataArrayFromBackend.length === 0) {
          //commit('setMoreElementsInCollection', false)
          return
        }
        //console.error(" queryResult " + JSON.stringify(queryResult))

        await saveDomainResultsInStores(param.appStore, commit, queryResult, {
          processTag: 'addNewAllEnterprises',
          validObj: queryResult.OUPDataArrayFromBackend,
          unknownErrorMsg: "Unknown error during query queryEnterprises process"
        })
      }
    },

    async updateContributionsHours({ state, commit }, { appStore, contributionsEventId, nbHours }) {
      //const result = await userFirebase.getContributionsForEnterprise({ EIEnterpriseId })
      console.error('state.currentUserEnterprise ' + JSON.stringify(state.currentUserEnterprise
        ))
        //     async updateContributionsHours(aEIEnterpriseId: string, contributionsEventId: string, nbHours: number) {

      const aEIEnterpriseId = state.currentUserEnterprise?.getEIEnterpriseId()
      if (!aEIEnterpriseId) {
        return
      }
      console.error('updateContributionsHours contributionsEventId ' + contributionsEventId)
      console.error('updateContributionsHours nbHours ' + nbHours)

      const result = await enterpriseFirebase.updateContributionsHours(aEIEnterpriseId,
        contributionsEventId, nbHours)
      await saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'setContributionsHoursInEnterprise',
        validObj: {
          aEIEnterpriseId,
          contributionsEventId,
          nbHours,
        },
        unknownErrorMsg: "Unknown error during updateContributionsHours process"
      })
    },

    async getContributionsForEnterprise({ state, commit }, { appStore }) {
      //const result = await userFirebase.getContributionsForEnterprise({ EIEnterpriseId })
      console.error('state.currentUserEnterprise ' + JSON.stringify(state.currentUserEnterprise
        ))
      const aEIEnterpriseId = state.currentUserEnterprise?.getEIEnterpriseId()
      const result = await enterpriseFirebase.getContributionsForEnterprise({ aEIEnterpriseId })
      await saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'setContributionsArrayInEnterprise',
        validObj: {
          aEIEnterpriseId,
          aEIColContributionsEvents: result.aEIColContributionsEvents
        },
        unknownErrorMsg: "Unknown error during getContributionsForEnterprise  process"
      })
    },
  
  },
  modules: {
  }
})


import { adDocFirebase, adminUsersCollection, userFirebase } from "../../backend/firebase";
import { EnterpriseAPIClient } from "../../backend/EnterpriseFirebase";
import { EnterpriseInfoProjectObject } from "../../default/common/domainModel/enterpriseInfoProjectToolsTypes";

export class EnterpriseInfoControler {
  enterpriseBackendAPI?: EnterpriseAPIClient;

  setAPI(enterpriseAPI: EnterpriseAPIClient) {
    this.enterpriseBackendAPI = enterpriseAPI
  }

  async updateEnterpriseInfo(user: EnterpriseInfoProjectObject, updateEmail: any) {
    const rForUpdateEnterpriseInfo = undefined // NOT IMPLEMENTED await this.enterpriseBackendAPI?.updateEnterpriseInfo(user, updateEmail)
    return {
      rForUpdateEnterpriseInfo
    }
  }

  async updateContributionsHours(aEIEnterpriseId: string, contributionsEventId: string, nbHours: number) {
    const rForUpdateContributionsHours = await this.enterpriseBackendAPI?.updateContributionsHours(aEIEnterpriseId, contributionsEventId, nbHours)
    return {
      rForUpdateContributionsHours
    }
  }

}


export const enterpriseInfoControler = new EnterpriseInfoControler()


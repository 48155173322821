
import {PPConfigSandbox} from "./sandbox/paypalConfig"
import {PPConfigStaging} from "./staging/paypalConfig"
import {PPConfigProduction} from "./production/paypalConfig"

//console.error("process.env.VUE_APP_CONFIG_ENV ;" + process.env.VUE_APP_CONFIG_ENV)
const PPConfig = (process.env.VUE_APP_CONFIG_ENV === "ProductionConfigEnv") ? PPConfigProduction : 
  ((process.env.VUE_APP_CONFIG_ENV === "StagingConfigEnv") ? PPConfigStaging : PPConfigSandbox)
export {
  PPConfig
}
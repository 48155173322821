<template>
  <div v-if="aAdDoc.geTransactionsIsLoading() === true " data-cy="cyTransactionsLoading">
    {{ t('transactionIsLoadingText') }}
  </div>
  <div v-else  data-cy="cyTransactionsLoaded">
    <div class="subtitle">{{ t("itemMyTransaction") }}</div>
    <div>
      <textarea class="addTransactionText" v-model.trim="addTransactionText"
        :placeholder="t('addTransactionTextPH')" >
      </textarea>
      <button class="button" data-cy="cyAddTransactionAdDoc"
        @click="addTransactionAdDoc(aAdDoc, addTransactionText)"
        :title="t('adDocAddTransactionTitle')"
      >
      {{ t((aAdDoc.OADData.ADFreeSubscription === true) ? "adDocParticipateToFreeSubAdDoc" : "adDocAddTransactionRequest") }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";
import { useDocumStore } from '@/engineproject/store/documStore';
import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes';
import { useAppStore } from '@/engineproject/store/appStore';
import { useUserStore } from '@/engineproject/store/userStore';

export default {
  props: {
    aAdDoc: {
      type: Object as () => Array<any>,
      required: true,
    },
  },
  setup(props: any, context: any) {
    const documStore = useDocumStore();
    const { t } = useI18n();
    const appStore = useAppStore();
    const userStore = useUserStore();
    const page = reactive({
      addTransactionText: "",
    });

    const addTransactionAdDoc = async (aAdDoc: AdDocumentProjectObject, addTransactionText: string) => {
      if (aAdDoc === undefined) {
        return;
      }
      await documStore.dispatch("addTransactionAdDoc", {
        aAdDoc,
        appStore: appStore,
        userStore: userStore,
        addTransactionText
      });
    };

    return {
      ...toRefs(page),
      t,
      addTransactionAdDoc,
    };
  },
  components: { 
  },
};
</script>

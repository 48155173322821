<template>
  <div class="ownerProfileViewLink">
    <ProfileViewLink :userId="userMiniProfile.UMUserId"
      :userDisplayedName="userDisplayedName"
      :userDisplayedDescription="userDisplayedDescription"
      :displayAppBoss="displayAppBoss"
      :displayWannaBeAppBoss="displayWannaBeAppBoss"
      prefixTextDisplayedBeforeName=""
      :profilePictureUrl="profilePictureUrl"
      :appBoss="userMiniProfile.UMFromAppBoss === true"></ProfileViewLink>
  </div>
</template>
<script lang="ts">
import { computed, reactive, toRefs } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { UserProjectTypes } from '@/engineproject/default/common/domainModel/userProjectTypes';
import { useAppStore } from "@/engineproject/store/appStore";

import ProfileViewLink from '@/engineproject/components/UserProfile/ProfileViewLink.vue';

export default {
  props: {
    userMiniProfile: {
      type: Object as () => UserProjectTypes.UserMiniProfile,
      required: true
    },
    prefixTextId: {
      type: String,
      required: false,
      default: "",
    },
    displayAppBoss: {
      type: Boolean,
      required: false,
      default: true
    },
    displayWannaBeAppBoss: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  setup(props: any, context: any) {
    const {t} = useI18n()
    const appStore = useAppStore();
    const page = reactive({
      userDisplayedName: computed(() => (props.userMiniProfile.UMName !== undefined && 
        props.userMiniProfile.UMName !== '') ? props.userMiniProfile.UMName : props.userMiniProfile.UMPseudo),
      userDisplayedDescription: computed(() => (props.userMiniProfile.UMDescription !== undefined) ? props.userMiniProfile.UMDescription : ""),
      prefixTextDisplayedBeforeName: computed(() => ((props.prefixTextId !== "") ? t(props.prefixTextId) : "")),
      profilePictureUrl: computed(() => props.userMiniProfile.UMPictureUrl),
      appDisplayOptions:  computed(() => appStore.state.appDisplayOptions),
    });
    return {
      ...toRefs(page),
      t, 
    }
  },
  components: {
    ProfileViewLink,
  },
}


</script>

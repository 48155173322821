{
  "allLocalAdDocs": "All local items",
  "allAdDocs": "All available items",
  "myAdDocs": "My items",
  "filterLocalisationAccordeonTitle": "Lieu",
  "filterCategoriesAccordeonTitle": "Catégories",
  "filterDatesAccordeonTitle": "Dates",
  "filterEnterpriseAccordeonTitle": "Participants",
  "filterEnterpriseTitle": "Participants:",
  "filterTodayTitle": "Aujourd'hui",
  "filterTomorrowTitle": "Demain",
  "filterCalendarTitle": "Heure et jour:",
  "filterSoireeTitle": "En soirée (après 18h)",
  "filterWeekendTitle": "Le week-end (samedi - dimanche)",
  "filterGeolocalisationTitle": "Local search:",
  "filterZipcode": "Code postal:",
  "filterZipcodePH": "69001",
  "filterCity": "Ville:",
  "filterCityPH": "ie: Paris, Lyon, Marseille",
  "filterAddress": "Adresse:",
  "filterAddressPH": "ie: rue ...",
  "filterLocationName": "Nom établissement:",
  "filterLocationNamePH": "ie: Le Zenith",
  "filterLocationPhone": "Tél établissement:",
  "filterLocationPhonePH": "",
  "filterLocationWeb": "URL établissement:",
  "filterLocationWebPH": "",
  "filterDisponibility": "Place(s) disponible(s):",
  "helpDisponibilityManually": "Pensez à mettre à jour le nombre de places disponibles, en particulier pour les activités à inscription non modérée.",
  "filterDisponibilityPH": "Nombre total de places disponibles",
  "filterDistance": "Distance en km:",
  "filterDistancePH": "50",
  "filterMyAdsTitle": "Mes annonces",
  "filterMyModelsTitle": "Mes modèles",
  "filterMySuggestionsTitle": "Mes suggestions",
  "filterSelectAnItem": "Sélectionner un élément",
  "openLargerMap": "Open larger map",
  "moreFiltersMenu": "Plus de filtres ...",
  "lessFiltersMenu": "Moins de filtres ..."
}
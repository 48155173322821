import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pModal profilesListModalContainer"
}
const _hoisted_2 = { class: "pContainer" }
const _hoisted_3 = { class: "resetBackground" }
const _hoisted_4 = { class: "headerLinks" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InvitationProfilesListView = _resolveComponent("InvitationProfilesListView")!
  const _component_DashboardProfilesSearchAndListView = _resolveComponent("DashboardProfilesSearchAndListView")!

  return (_ctx.showProfilesListModal === true)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("a", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.closeUserProfileModal())),
                class: "closeComment"
              }, _toDisplayString($setup.t("closeAdDocument")), 1)
            ]),
            _createVNode(_component_DashboardProfilesSearchAndListView, null, {
              content: _withCtx(({aUserProfile}) => [
                _createVNode(_component_InvitationProfilesListView, {
                  aUserProfile: aUserProfile,
                  aAdDoc: _ctx.aAdDoc
                }, null, 8, ["aUserProfile", "aAdDoc"])
              ]),
              _: 1
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
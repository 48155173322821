import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "resetBackground" }
const _hoisted_2 = { class: "headerLinks" }
const _hoisted_3 = { class: "post" }
const _hoisted_4 = {
  key: 0,
  class: "documentViewTransactions"
}
const _hoisted_5 = {
  key: 1,
  class: "documentViewTransactions"
}
const _hoisted_6 = {
  key: 2,
  class: "documentViewLikesAndComments"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdDocumentHeaderLinks = _resolveComponent("AdDocumentHeaderLinks")!
  const _component_ProjectAdDocumentViewMainPart = _resolveComponent("ProjectAdDocumentViewMainPart")!
  const _component_AdDocViewInvitations = _resolveComponent("AdDocViewInvitations")!
  const _component_AdDocViewPodiums = _resolveComponent("AdDocViewPodiums")!
  const _component_AdDocViewTransactions = _resolveComponent("AdDocViewTransactions")!
  const _component_AdDocumentViewCommentsList = _resolveComponent("AdDocumentViewCommentsList")!
  const _component_AdDocumentViewLikesList = _resolveComponent("AdDocumentViewLikesList")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass((($props.displayInModal === true) ? 'pModal ' : ' ') )
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["pContainer", $props.aAdDoc.getCSSClass()])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_AdDocumentHeaderLinks, {
                aAdDoc: $props.aAdDoc,
                userCanEdit: $props.userCanEdit,
                displayInModal: $props.displayInModal,
                userIsOwner: $props.userIsOwner,
                onCloseAdDocument: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeAdDocument'))),
                onCloseMapModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeMapModal')))
              }, null, 8, ["aAdDoc", "userCanEdit", "displayInModal", "userIsOwner"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ProjectAdDocumentViewMainPart, {
                aAdDoc: $props.aAdDoc,
                displayCommentsLikesOnly: true,
                displayInModal: $props.displayInModal
              }, null, 8, ["aAdDoc", "displayInModal"])
            ]),
            ($props.aAdDoc.canHaveTransactions())
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_AdDocViewInvitations, {
                    aAdDoc: $props.aAdDoc,
                    ADColInvitations: $props.aAdDoc.OADData.ADColInvitations,
                    userIsOwner: $props.userIsOwner,
                    userCanEdit: $props.userCanEdit
                  }, null, 8, ["aAdDoc", "ADColInvitations", "userIsOwner", "userCanEdit"])
                ]))
              : _createCommentVNode("", true),
            ($props.aAdDoc.canHaveTransactions())
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_AdDocViewPodiums, {
                    aAdDoc: $props.aAdDoc,
                    ADColPodiums: $props.aAdDoc.OADData.ADColPodiums,
                    userIsOwner: $props.userIsOwner,
                    userCanEdit: $props.userCanEdit
                  }, null, 8, ["aAdDoc", "ADColPodiums", "userIsOwner", "userCanEdit"]),
                  _createVNode(_component_AdDocViewTransactions, {
                    aAdDoc: $props.aAdDoc,
                    ADColTransactions: $props.aAdDoc.OADData.ADColTransactions,
                    userIsOwner: $props.userIsOwner,
                    userCanEdit: $props.userCanEdit
                  }, null, 8, ["aAdDoc", "ADColTransactions", "userIsOwner", "userCanEdit"])
                ]))
              : _createCommentVNode("", true),
            ($props.aAdDoc.canHaveLikesAndComments())
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_AdDocumentViewCommentsList, { aAdDoc: $props.aAdDoc }, null, 8, ["aAdDoc"]),
                  _createVNode(_component_AdDocumentViewLikesList, { aAdDoc: $props.aAdDoc }, null, 8, ["aAdDoc"])
                ]))
              : _createCommentVNode("", true)
          ])
        ], 2)
      ], 2)
    ]),
    _: 1
  }))
}
<template>
  <div v-if="adDocFileType === 'T'" class="bottomActionsArea">
    <div class="textAndActionButton" :title="t('proposeAnAdDocModelTitle')"
        @click.stop="openCreateModel()">
      <div>
        {{ t('proposeAnAdDocModel') }}
      </div>
      <div>
        <div class="pi pi-chevron-right">
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="adDocFileType === 'S'" class="bottomActionsArea">
    <div class="textAndActionButton" :title="t('proposeAnAdDocSuggestionTitle')"
        @click.stop="openCreateSuggestion()">
      <div>
        {{ t('proposeAnAdDocSuggestion') }}
      </div>
      <div>
        <div class="pi pi-chevron-right">
        </div>
      </div>
    </div>
  </div>
  <div v-else class="bottomActionsArea">
    <div class="textAndActionButton" :title="t('proposeAnAdDocTitle')"
        @click.stop="openPropose()">
      <div>
        {{ t('proposeAnAdDoc') }}
      </div>
      <div>
        <div class="pi pi-chevron-right">
        </div>
      </div>
    </div>
    <div class="textAndActionButton" :title="t('askCoachingTitle')"
        @click.stop="askCoaching()">
      <div>
        {{ t('askCoaching') }}
      </div>
      <div>
        <div class="pi pi-chevron-right">
        </div>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { reactive, toRefs } from 'vue';
import router from '@/router';
import { useI18n } from '@/engineproject/default/common/i18n';

export default {
  props: {
    adDocFileType: {
      type: String,
      required: false,
      default: ""
    },
  },
  setup(props: any, context: any) {
    const {t} = useI18n()
    const page = reactive({
    });

    const openPropose = () => {
      router.push("/add")
    }

    const openCreateModel = () => {
      router.push("/addtem")
    }

    const openCreateSuggestion = () => {
      router.push("/addsug")
    }

    const askCoaching = () => {
      window.location.href = "mailto:askToCoach@alomigo.com?subject=Besoin d'un coach pour ...";
    }

    return {
      ...toRefs(page),
      t,
      openPropose,
      openCreateModel,
      openCreateSuggestion,
      askCoaching,
    }
  },
  components: { 
  },
}


</script>

<template>
        <div  
          v-if="showProfilesListModal === true"
          class="pModal profilesListModalContainer">
          <div class="pContainer " >
            <div class="resetBackground">
              <div class="headerLinks">
                <a @click="closeUserProfileModal()" class="closeComment">{{ t("closeAdDocument") }}</a>
              </div>
              <DashboardProfilesSearchAndListView>
                <template #content="{aUserProfile}">
                  <InvitationProfilesListView  
                    :aUserProfile="aUserProfile"
                    :aAdDoc="aAdDoc"></InvitationProfilesListView>
                </template>
              </DashboardProfilesSearchAndListView>
            </div>
          </div>
        </div>
</template>

<script lang="ts">
import { toRefs, reactive, computed, watch } from "vue";
import { useI18n } from '@/engineproject/default/common/i18n';

import { UserProfileProjectObject } from '@/engineproject/default/common/domainModel/userProfileProjectToolsTypes';
import { useAppStore } from "@/engineproject/store/appStore";
import { useUserStore } from "@/engineproject/store/userStore";

import DashboardProfilesSearchAndListView from "@/engineproject/components/UserProfile/DashboardProfilesSearchAndListView.vue";
import InvitationProfilesListView from "@/engineproject/components/UserProfile/InvitationProfilesListView.vue";
import { useDocumStore } from '@/engineproject/store/documStore';

export default {
  setup() {
    const { t } = useI18n();
    const documStore = useDocumStore()
    const appStore = useAppStore();
    const page = reactive({
      showProfilesListModal: computed(() => appStore.state.showProfilesListModal),
      aAdDoc: computed(() => documStore.state.aAdDoc),
    });
    const closeUserProfileModal = () => {
      appStore.commit("setShowProfilesListModal", {showProfilesListModal: false});
    };

    return {
      ...toRefs(page),
      t,
      closeUserProfileModal,
    };
  },
  components: {
    DashboardProfilesSearchAndListView,
    InvitationProfilesListView,
  },
};
</script>

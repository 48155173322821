import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "listNavBottomMenu" }
const _hoisted_2 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('nextSubList'))),
      title: $setup.t('moreLinkTitle')
    }, _toDisplayString($setup.t("moreLink")), 9, _hoisted_2)
  ]))
}
<template>
    <div class="filter-container" data-cy="cyFilterContainerInLine" >
      <ul class="inline">
      <li v-for="assetObj in newcurrentFilterAttributesObjects  " :key="assetObj.id" 
        :data-cy="'cy'+assetObj.id"
        :class="{filerSelectedInline : newcurrentFilterAttributesObjectsFilterSelected.filterSelected == assetObj.id}"
        @click="changeFilterSelected(assetObj.id)">
          {{ assetObj.lang }}
        </li >
        <li class="filterVisibleCommand">
          <FilterVisibleCommand></FilterVisibleCommand>
        </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { reactive, toRefs, computed } from "vue";
import { useFilterAttributesStore , getOneOfAllFiltersValues, getOneOfAllFilters} from '@/engineproject/store/filterAttributesStore'
import FilterVisibleCommand from '@/engineproject/components/Filter/FilterVisibleCommand.vue';

export default {
  props: {
    stateObject: {
      type: String,
      required: true
    },
    emptyValueAccepted: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  setup(props: any) {
    const filterAttributesStore = useFilterAttributesStore()
    const page = reactive({
      newcurrentFilterAttributesObjects: computed(() => {
        const retu =  getOneOfAllFiltersValues(filterAttributesStore.state, props.stateObject)
        // console.error("getOneOfAllFilters retu: " + props.stateObject + "  =  " + JSON.stringify(retu))
        return retu
      }),

      newcurrentFilterAttributesObjectsFilterSelected: computed(() => 
        getOneOfAllFilters(filterAttributesStore.state, props.stateObject)), 
      currentFilterAttributesObjects: computed(() => filterAttributesStore.state), 
      filterTitle: computed(() => props.stateObject + "Title"),

    });
    const changeFilterSelected = async (newValue: string) => {
      page.newcurrentFilterAttributesObjectsFilterSelected.filterSelected = newValue
      await filterAttributesStore.dispatch("filterHasChanged")

    }
    return {
      ...toRefs(page),
      /// props,
      changeFilterSelected, 
    }
  },
  components: { 
    FilterVisibleCommand,
  },
}


</script>
<style lang="scss" scoped>

.filterVisibleCommand {
  font-size: 0.8rem;
  margin-left: 14px;
}
.filter-container {
  margin: 4px;
  padding: 2px;
  color:gray;
}

.filerSelectedInline {
  //text-decoration: underline;
  color:black;
  border-bottom: 2px solid #4472c4;
  
  //font-weight: bold;
}
  ul {
    //float: right;
    //text-align: right;

    li {
      margin-top: 4px;
      margin-left: 16px;
      cursor: pointer;

      a {
        display: block;
        padding: 5px;
      }
    }
  }

</style>

<template>
    <ProjectAdDocumentView
      v-if="showAdDocModal"
      :aAdDoc="selectedAdDoc"
      :userIsOwner="userIsOwner"
      :userCanEdit="userCanEdit"
      :displayInModal="true"
      @closeAdDocument="closeAdDocModal()"
    ></ProjectAdDocumentView>
    <CommentModal
      v-if="showCommentModal"
      :aAdDoc="selectedAdDoc"
      @close="closeCommentModal($event)"
    ></CommentModal>
    <ReportModal
      v-if="showReportModal"
      :aAdDoc="selectedAdDoc"
      @close="closeReportModal($event)"
    ></ReportModal>
</template>

<script lang="ts">
import { toRefs, reactive, computed, watch, onMounted } from "vue";
import { useAppStore } from "@/engineproject/store/appStore";
import { useUserStore } from "@/engineproject/store/userStore";
import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes';

import ProjectAdDocumentView from "@/engineproject/default/common/components/AdDocument/ProjectAdDocumentView.vue";
import CommentModal from "@/engineproject/components/AdDocument/CommentModal.vue";
import ReportModal from "@/engineproject/components/AdDocument/ReportModal.vue";

export default {
  setup() {
    const appStore = useAppStore();
    const userStore = useUserStore();
    const page = reactive({
      showCommentModal: computed(() => appStore.state.showCommentModal),
      showReportModal: computed(() => appStore.state.showReportModal),
      aUserProfile: computed(() => userStore.state.aUserProfile),
      userIsOwner: computed(() => appStore.state.inModalAdDocument.isOwner(userStore.state.aUserProfile.getUPUserId())),
      userCanEdit: computed(() => appStore.state.inModalAdDocument.isOwner(userStore.state.aUserProfile.getUPUserId()) 
        || userStore.state.aUserProfile.isAdmin() ),
      showAdDocModal: computed(() => appStore.state.showAdDocModal),
      selectedAdDoc: computed(() => appStore.state.inModalAdDocument),
    });

    const closeCommentModal = (closeStatus: { commentAdded: boolean }) => {
      appStore.commit("setShowCommentModal", {showCommentModal: false});
    };

    const closeReportModal = (closeStatus: { commentAdded: boolean }) => {
      appStore.commit("setShowReportModal", {showReportModal: false});
    };
    const closeAdDocModal = () => {
      appStore.commit("setInModalAdDocument", {inModalAdDocument: new AdDocumentProjectObject()});
      appStore.commit("setShowAdDocModal", {showAdDocModal: false});
    };
    return {
      ...toRefs(page),
      closeAdDocModal,
      closeCommentModal,
      closeReportModal,
    };
  },
  components: { 
    ProjectAdDocumentView,
    CommentModal,
    ReportModal
  },
};
</script>


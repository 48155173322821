{
    "confirmText1": "Veuillez confirmer votre email pour valider votre inscription!",
    "confirmText2": "N’oubliez pas de vérifier vos spams si besoin!",
    "confirmButton": "Recevoir de nouveau l'e-mail de confirmation.",
    "invalidEmailValidationLink": "Lien de validation incorrect ou périmé.",
    "missingEmailValidation": "Votre ",

    
    "emailHasBeenValidated": "Votre email a été validée.",
    "mailSentToYouWithValidationLink": "Un courrier avec un lien a été envoyé à votre email. Cliquez sur le lien pour valider votre email."
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "subpage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnerPromotionViewButtons = _resolveComponent("PartnerPromotionViewButtons")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PartnerPromotionViewButtons, {
      posterElementId: $props.posterElementBaseId,
      expandState: _ctx.showPreview,
      "onUpdate:expandState": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showPreview) = $event)),
      posterAdditionalClass: $setup.getPosterAdditionalClass()
    }, null, 8, ["posterElementId", "expandState", "posterAdditionalClass"]),
    _withDirectives(_createElementVNode("div", {
      class: "poster",
      id: $props.posterElementBaseId + 'Id'
    }, [
      _createElementVNode("div", _hoisted_2, [
        ($setup.mustBeVisible(1))
          ? (_openBlock(), _createBlock(_resolveDynamicComponent($props.dyncomp), {
              key: 0,
              subpageClassPos: $setup.getSubpageClass(1),
              subpageSizeA: $props.subpageSizeA,
              dyncompData: $props.dyncompData
            }, null, 8, ["subpageClassPos", "subpageSizeA", "dyncompData"]))
          : _createCommentVNode("", true),
        ($setup.mustBeVisible(2))
          ? (_openBlock(), _createBlock(_resolveDynamicComponent($props.dyncomp), {
              key: 1,
              subpageClassPos: $setup.getSubpageClass(2),
              subpageSizeA: $props.subpageSizeA,
              dyncompData: $props.dyncompData
            }, null, 8, ["subpageClassPos", "subpageSizeA", "dyncompData"]))
          : _createCommentVNode("", true),
        ($setup.mustBeVisible(3))
          ? (_openBlock(), _createBlock(_resolveDynamicComponent($props.dyncomp), {
              key: 2,
              subpageClassPos: $setup.getSubpageClass(3),
              subpageSizeA: $props.subpageSizeA,
              dyncompData: $props.dyncompData
            }, null, 8, ["subpageClassPos", "subpageSizeA", "dyncompData"]))
          : _createCommentVNode("", true),
        ($setup.mustBeVisible(4))
          ? (_openBlock(), _createBlock(_resolveDynamicComponent($props.dyncomp), {
              key: 3,
              subpageClassPos: $setup.getSubpageClass(4),
              subpageSizeA: $props.subpageSizeA,
              dyncompData: $props.dyncompData
            }, null, 8, ["subpageClassPos", "subpageSizeA", "dyncompData"]))
          : _createCommentVNode("", true),
        ($setup.mustBeVisible(5))
          ? (_openBlock(), _createBlock(_resolveDynamicComponent($props.dyncomp), {
              key: 4,
              subpageClassPos: $setup.getSubpageClass(5),
              subpageSizeA: $props.subpageSizeA,
              dyncompData: $props.dyncompData
            }, null, 8, ["subpageClassPos", "subpageSizeA", "dyncompData"]))
          : _createCommentVNode("", true),
        ($setup.mustBeVisible(6))
          ? (_openBlock(), _createBlock(_resolveDynamicComponent($props.dyncomp), {
              key: 5,
              subpageClassPos: $setup.getSubpageClass(6),
              subpageSizeA: $props.subpageSizeA,
              dyncompData: $props.dyncompData
            }, null, 8, ["subpageClassPos", "subpageSizeA", "dyncompData"]))
          : _createCommentVNode("", true)
      ])
    ], 8, _hoisted_1), [
      [_vShow, _ctx.showPreview]
    ])
  ], 64))
}
<template>
  <div class="filtersVisibleArea">
    <div v-if="filtersVisible===false" class="moreFilters"
      @click="changeFiltersVisible()">
      {{ t('moreFiltersMenu')}}
    </div>
    <div v-if="filtersVisible===true" class="moreFilters"
      @click="changeFiltersVisible()">
      {{ t('lessFiltersMenu')}}
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs, computed } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { useAppStore } from '@/engineproject/store/appStore'

export default {
  emits: ["filterChanged"],
  setup(props: any, context: any) {
    const {t} = useI18n()
    const appStore = useAppStore()
    const page = reactive({
      filtersVisible: computed(() => appStore.state.filtersVisible),
    });
    const changeFiltersVisible = () => {
      appStore.commit("swapFiltersVisible")
    }
    return {
      ...toRefs(page),
      t,
      changeFiltersVisible
    }
  },
}


</script>

import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-cy": "cyTransactionsLoading"
}
const _hoisted_2 = {
  key: 1,
  "data-cy": "cyTransactionsLoaded"
}
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.aAdDoc.geTransactionsIsLoading() === true )
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.t('transactionIsLoadingText')), 1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString($setup.t("itemMyTransaction")), 1),
        _createElementVNode("div", null, [
          _withDirectives(_createElementVNode("textarea", {
            class: "addTransactionText",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.addTransactionText) = $event)),
            placeholder: $setup.t('addTransactionTextPH')
          }, "\r\n      ", 8, _hoisted_4), [
            [
              _vModelText,
              _ctx.addTransactionText,
              void 0,
              { trim: true }
            ]
          ]),
          _createElementVNode("button", {
            class: "button",
            "data-cy": "cyAddTransactionAdDoc",
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.addTransactionAdDoc($props.aAdDoc, _ctx.addTransactionText))),
            title: $setup.t('adDocAddTransactionTitle')
          }, _toDisplayString($setup.t(($props.aAdDoc.OADData.ADFreeSubscription === true) ? "adDocParticipateToFreeSubAdDoc" : "adDocAddTransactionRequest")), 9, _hoisted_5)
        ])
      ]))
}
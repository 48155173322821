import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-cy": "cyTransactionsLoaded"
}
const _hoisted_2 = { class: "subtitle" }
const _hoisted_3 = { class: "transactionElement" }
const _hoisted_4 = { class: "transactionElementTitleArea" }
const _hoisted_5 = {
  key: 0,
  class: "leftAlign"
}
const _hoisted_6 = {
  key: 1,
  class: "leftAlign"
}
const _hoisted_7 = ["title"]
const _hoisted_8 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OwnerProfileViewLink = _resolveComponent("OwnerProfileViewLink")!

  return ($props.ADColTransactions !== undefined && $props.ADColTransactions.length )
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString($setup.t("itemYourTransactionsStatus")), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.ADColTransactions, (aTransaction) => {
          return (_openBlock(), _createElementBlock("div", {
            key: aTransaction.ATId,
            class: "transactions"
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createElementVNode("span", null, _toDisplayString($setup.formatDate(aTransaction.ATCreatedOn)) + " " + _toDisplayString($setup.displayStatus(aTransaction.ATStatus)), 1)
                ]),
                (aTransaction.ATStatus == 'P' || aTransaction.ATStatus == 'F' )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("button", {
                        class: "button",
                        "data-cy": "cyRemoveMyTransactionAdDoc",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.removeMyTransactionAdDoc($props.aAdDoc)))
                      }, _toDisplayString($setup.t(($props.aAdDoc.OADData.ADFreeSubscription === true) ? "adDocRemoveMyFreeSubscription" : "adDocRemoveMyTransaction")), 1)
                    ]))
                  : (aTransaction.ATStatus == 'A')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("button", {
                          class: "button",
                          "data-cy": "cyOpenProfileFromA",
                          title: $setup.t('openProfile'),
                          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.viewUserProfile($props.aAdDoc.OADData.ADOwnerMiniProfile.UOUserId)))
                        }, [
                          _createVNode(_component_OwnerProfileViewLink, {
                            ownerMiniProfile: $props.aAdDoc.OADData.ADOwnerMiniProfile,
                            prefixTextId: "openProfileButtonText"
                          }, null, 8, ["ownerMiniProfile"])
                        ], 8, _hoisted_7),
                        _createElementVNode("a", {
                          "data-cy": "cyRemoveMyTransactionAdDoc",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.removeMyTransactionAdDoc($props.aAdDoc)))
                        }, " X ")
                      ]))
                    : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", {
                class: "transactionComment",
                title: $setup.t('itemTransactionCommentTitle')
              }, [
                _createElementVNode("pre", null, _toDisplayString(aTransaction.ATComment), 1)
              ], 8, _hoisted_8)
            ])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
{
  "updateYourProfile": "Update your profile",
  "otherActionsOnYourProfile": "Autres actions: ",
  "buttonDefaultLocation": "Geolocation",
  "buttonLatLngLocation": "Valider",
  "editLatLngLocationPH": "latitude , longitude",
  "setLocationUsingLatLng": "Ajouter la localisation en utilisant la latitude et longitude",
  "setLocationUsingGeolocation": "Ajouter la localisation en utilisant la géolocalisation",
  "setDefaultLocationForLocalFilter": "Ajouter votre géolocalisation dans votre profil pour pouvoir filtrer localement plus facilement!",
  "setDefaultLocationForLocalFilterLink": "Cliquez ici pour compléter votre profil",
  "editLocationUsingTextSearchPH": "Ou chercher un lieu ...",
  "buttonRemoveDefaultLocation": "Remove your position",
  "openLocationInMap": "Open location in map",
  "openLocationInMapShort": "Map: ",
  "openZipCodeLocationInMap": "Situer le code postal {aZipCode} sur une carte",
  "addPicture": "Add a picture",
  "replacePicture": "Changer l'image",
  "removePicture": "Remove picture",

  "closeLink": "Close ",

  "removeCommentTitle": "Supprimer le commentaire",

  "openProfileButtonText": "Contacter",
  "openProfile": "Afficher le profil"
}

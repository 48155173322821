<template>
  <div class="filter-container"  data-cy="cyFilterContainerCkeckbox" 
    v-if="newcurrentFilterAttributesObjects.length > 1">
    <div class="filterContainerTitle">
      {{ t(filterTitle) }}
    </div>
    <div
      v-for="assetObj in newcurrentFilterAttributesObjects"
      :key="assetObj.id">
      <input
        type="checkbox"
        :value="assetObj.checked"
        :id="assetObj.id"
        v-model="assetObj.checked"
        @click="filterAttributesObjectValueChanged()"/>
      <label for="assetObj.id">{{ assetObj.lang }}</label>
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, toRefs, computed } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";
import { useFilterAttributesStore, getOneOfAllFiltersValues } from "@/engineproject/store/filterAttributesStore";

export default {
  props: {
    stateObject: {
      type: String,
      required: true,
    },
  },
  setup(props: any) {
    const filterAttributesStore = useFilterAttributesStore();
    const { t } = useI18n();
    const page = reactive({
      newcurrentFilterAttributesObjects: computed(() => {
        const retu = getOneOfAllFiltersValues(
          filterAttributesStore.state,
          props.stateObject
        );
        // console.error("getOneOfAllFilters retu: " + props.stateObject + "  =  " + JSON.stringify(retu))
        return retu;
      }),
      filterTitle: computed(() => props.stateObject + "Title"),
    });
    const filterAttributesObjectValueChanged = () => {
      setTimeout(async () => {
        await filterAttributesStore.dispatch("filterHasChanged")
      }, 50)
    }
    return { 
      ...toRefs(page),
      t,
      filterAttributesObjectValueChanged,
    };
  },
};
</script>
<style lang="scss" scoped>
.filter-container {
  margin-bottom: 10px;
  //margin: 4px;
  //margin-top: 0px;
  //padding: 2px;

  .filterContainerTitle {
    //margin-top: 10px;
    margin-bottom: 6px;
    padding-left: 4px;
    font-style: italic;
  }
}
</style>
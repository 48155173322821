<template>
  <div v-if="aAdDoc !== undefined && aAdDoc.canBeSharedAndGetPromotion() === true"  class="partnerPromotionViewContent">
    <DisplayPostersInPage
      subpageSizeA="6"
      :dyncomp="dyncompPosterAdDocument"
      posterElementBaseId="PosterAdDocument"
      :dyncompData="{aAdDoc}">
    </DisplayPostersInPage>
  </div>

</template>
<script lang="ts">
import { defineAsyncComponent, reactive, toRefs } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { AdDocumentProjectTypes } from '@/engineproject/default/common/domainModel/adDocumentProjectTypes';

import DisplayPostersInPage from '@/engineproject/components/Promotion/DisplayPostersInPage.vue'

export default {
  props: {
    aAdDoc: {
      type: Object as () => AdDocumentProjectTypes.AdDocument,
      required: true
    },
  },
  setup(props: any, context: any) {
    const {t} = useI18n()
    const page = reactive({
    });
    const dyncompPosterAdDocument = defineAsyncComponent(() => 
        import("@/engineproject/components/Promotion/DynamicComponents/PosterAdDocument.vue"));

    return {
      ...toRefs(page),
      t,
      dyncompPosterAdDocument,    
    }
  },
  components: { 
    DisplayPostersInPage,
  },
}


</script>

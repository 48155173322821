<template>
  <ProjectUserProfileViewModal
    v-if="showUserProfileModal"
    :aUserProfile="selectedUserProfile"
    @close="closeUserProfileModal()"
  ></ProjectUserProfileViewModal>
</template>

<script lang="ts">
import { toRefs, reactive, computed, watch } from "vue";

import { UserProfileProjectObject } from '@/engineproject/default/common/domainModel/userProfileProjectToolsTypes';
import { useAppStore } from "@/engineproject/store/appStore";
import { useUserStore } from "@/engineproject/store/userStore";

import ProjectUserProfileViewModal from "@/engineproject/default/common/components/UserProfile/ProjectUserProfileViewModal.vue";

export default {
  setup() {
    const appStore = useAppStore();
    const userStore = useUserStore();
    const page = reactive({
      showUserProfileModal: false,
      selectedUserProfile: new UserProfileProjectObject() //{} as UserProjectTypes.UserProfile,
    });
    const inModalUserProfileId = computed(
      () => appStore.state.inModalUserProfileId
    )

    const viewUserProfile = async (aUserId: string) => {
      // console.error("viewUserProfile " + aUserId)
      const aResultUserProfile = await  userStore.dispatch("getUserProfile", {
        aUserId,
        appStore: appStore,
      });
      if (aResultUserProfile.isValidUser()) {
        page.selectedUserProfile = aResultUserProfile;
        page.showUserProfileModal = true;
      }
    };
    const closeUserProfileModal = () => {
      appStore.commit("setInModalUserProfileId", {inModalUserProfileId: ""});
      page.selectedUserProfile = new UserProfileProjectObject()//{} as UserProjectTypes.UserProfile;
      page.showUserProfileModal = false;
    };

    watch(inModalUserProfileId, (newValue, oldValue) => {
      if (newValue !== undefined && newValue !== "") {
        viewUserProfile(newValue)
      } else {
        closeUserProfileModal()
      }
    });

    return {
      ...toRefs(page),
      closeUserProfileModal,
    };
  },
  components: {
    ProjectUserProfileViewModal,
  },
};
</script>

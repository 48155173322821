import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import {userFirebase} from '@/engineproject/backend/firebase';
import Dashboard from '../engineproject/views/Dashboard.vue'
// ATTENTION useAppStore DO NOT WORK IN ROUTER
//import { useAppStore } from "@/engineproject/store/appStore";
import { appStore } from '@/engineproject/store/appStore'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:routeParamFilter?',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuHome",
    }
  },
  {
    path: '/suggestions/:routeParamFilter?',
    name: 'suggestions',
    component: () => import('../engineproject/views/DashboardSuggestions.vue'),
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuAdmin",
    }
  },
  {
    path: '/models/:routeParamFilter?',
    name: 'models',
    component: () => import('../engineproject/views/DashboardModels.vue'),
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuSettings",
    }
  },
  {
    path: '/login/:routeParamLoginId?',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../engineproject/views/Login.vue')
  },
  {
    path: '/profiles',
    name: 'profiles',
    component: () => import('../engineproject/views/DashboardProfiles.vue'),
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuAdmin",
    }
  },
  {
    path: '/enterprises',
    name: 'enterprises',
    component: () => import('../engineproject/views/DashboardEnterprises.vue'),
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuAdmin",
    }
  },
  {
    path: '/adminViewOther',
    name: 'adminViewOther',
    component: () => import('../engineproject/views/AdminViewOther.vue'),
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuAdmin",
    }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('../engineproject/views/Notifications.vue'),
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuNotifications",
    }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import('../engineproject/views/Calendar.vue'),
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuSettings",
    }
  },
  {
    path: '/evl/:routeSettingsTabId/:emailValidationLink',
    name: 'evl',
    component: () => import('../engineproject/views/Settings.vue'),
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuSettings",
    }
  },
  {
    path: '/settings/:routeSettingsTabId?',
    name: 'settings',
    component: () => import('../engineproject/views/Settings.vue'),
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuSettings",
    }
  },
  {
    path: '/closing',
    name: 'closing',
    component: () => import('../engineproject/views/Closing.vue'),
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuSettings",
    }
  },
  {
    path: '/p/:routeParamPseudo',
    name: 'profile',
    component: () => import('../engineproject/views/Profile.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuSettings",
    }
  },
  {
    path: '/u/:routeParamUserId',
    name: 'profileU',
    component: () => import('../engineproject/views/Profile.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuSettings",
    }
  },
  {
    path: '/contributions/:routeParamUserId?',
    name: 'contributions',
    component: () => import('../engineproject/views/Contributions.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuSettings",
    }
  },
  {
    path: '/o/:routeParamUserId?',
    name: 'partnerPromotion',
    component: () => import('../engineproject/views/PartnerPromotion.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuSettings",
    }
  },
  {
    path: '/a/:routeParamAdDocumentId',
    name: 'AdDocumentView',
    component: () => import('../engineproject/views/AdDocumentView.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuHome",
    }
  },
  {
    path: '/at/:routeParamAdDocumentId',
    name: 'AdTemplateView',
    component: () => import('../engineproject/views/AdModelView.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuHome",
    }
  },
  {
    path: '/as/:routeParamAdDocumentId',
    name: 'AdSuggestionView',
    component: () => import('../engineproject/views/AdSuggestionView.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuHome",
    }
  },
  {
    path: '/edit/:routeParamDocIdForEdit',
    name: 'edit',
    component: () => import('../engineproject/views/AdDocumentCreate.vue'),
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuAdd",
    }
  },
  {
    path: '/add/:routeParamTemplateIdForAdd?',
    // path: '/add',
    name: 'add',
    component: () => import('../engineproject/views/AdDocumentCreate.vue'),
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuAdd",
    }
  },
  {
    path: '/addsug',
    name: 'addsug',
    component: () => import('../engineproject/views/AdSuggestionCreate.vue'),
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuAdmin",
    }
  },
  {
    path: '/addtem/:routeParamTemplateIdForAdd?',
    name: 'addtem',
    component: () => import('../engineproject/views/AdModelCreate.vue'),
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuSettings",
    }
  },
  {
    path: '/editsug/:routeParamDocIdForEdit',
    name: 'editsug',
    component: () => import('../engineproject/views/AdSuggestionCreate.vue'),
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuAdd",
    }
  },
  {
    path: '/edittem/:routeParamDocIdForEdit',
    name: 'edittem',
    component: () => import('../engineproject/views/AdModelCreate.vue'),
    meta: {
      requiresAuth: true,
      mainMenuClass: "mainMenuAdd",
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../engineproject/views/Home.vue'),
    meta: {
      requiresAuth: false,
      mainMenuClass: "mainMenuAbout",
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../engineproject/views/PrivacyPolicy.vue'),
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    component: () => import('../engineproject/views/TermsOfService.vue'),
    meta: {
      requiresAuth: false,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const updateMainMenuSelected = (appStore: any, newSelected: string | undefined, iLoop: number) => {
  if (appStore) {
    appStore.commit("setMainMenuSelected", { mainMenuSelected: newSelected });
  }
}

router.beforeEach(async (to, from, next) => {
  // console.log('beforeEach navigation')

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  console.error('requiresAuth ' + requiresAuth)
  if (to.matched[0]) {
    const mainMenuClass = to.matched[0].meta.mainMenuClass as string;
    updateMainMenuSelected(appStore, mainMenuClass, 0)
  }
  //const user = fb.auth.currentUser;
  // cycling stringify error console.error("route to " + JSON.stringify(to))
  // cycling stringify error console.error("route from " + JSON.stringify(from))
  if (requiresAuth && !await userFirebase.getCurrentUser()){
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    });
  }else{
    /*if (to.path === "/login" && from.path === "/login" && to.query.redirect == undefined) {
      console.error("beforeEach 1")
      next({
        path: '/login',
        query: { redirect: from.fullPath }
          }
      );
    } else {*/
      // console.error("beforeEach 2")
      next();
    //}
  }
});
/*
router.afterEach((to, from, failure) => {
    console.log('afterEach navigation')
})*/

export default router

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13a0ebee"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "filter-container",
  "data-cy": "cyFilterContainerCkeckbox"
}
const _hoisted_2 = { class: "filterContainerTitle" }
const _hoisted_3 = ["value", "id", "onUpdate:modelValue"]
const _hoisted_4 = { for: "assetObj.id" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.newcurrentFilterAttributesObjects.length > 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString($setup.t(_ctx.filterTitle)), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newcurrentFilterAttributesObjects, (assetObj) => {
          return (_openBlock(), _createElementBlock("div", {
            key: assetObj.id
          }, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              value: assetObj.checked,
              id: assetObj.id,
              "onUpdate:modelValue": ($event: any) => ((assetObj.checked) = $event),
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.filterAttributesObjectValueChanged()))
            }, null, 8, _hoisted_3), [
              [_vModelCheckbox, assetObj.checked]
            ]),
            _createElementVNode("label", _hoisted_4, _toDisplayString(assetObj.lang), 1)
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
<template>
  <div class="banUserArea inlineSeparatedBlockContainer adminSuperPower">
    <div v-if="loggedUserProfile.isAdmin() === true" class="inline" >
      <label class="inlineInput" >{{ t('banUser') }} : </label>
      <InputSwitch v-model="userBanned"  @change="banUser({ aUserProfile: aUserProfile })"></InputSwitch>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, reactive, toRefs } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { UserProjectTypes } from '@/engineproject/default/common/domainModel/userProjectTypes';
import { UserProfileProjectObject } from '@/engineproject/default/common/domainModel/userProfileProjectToolsTypes';
import { userStore } from '@/engineproject/store/userStore';
import { useAppStore } from '@/engineproject/store/appStore';

import InputSwitch from 'primevue/inputswitch';

export default {
  props: {
    aUserProfile: {
      type: Object as () => UserProfileProjectObject,
      required: true
    },
  },
  emits: ["banUser"],
  setup(props: any, context: any) {
    const {t} = useI18n()
    const appStore = useAppStore();
    const page = reactive({
      aUserProfile: props.aUserProfile,
      userBanned: computed(() => (props.aUserProfile.isBanned())),
      loggedUserProfile: computed(() => userStore.state.aUserProfile),
    });
    const banUser = (param: {aUserProfile: UserProfileProjectObject}) => {
      userStore.dispatch("banUser", {
        userId: param.aUserProfile.getUPUserId(),
        banValue: !page.userBanned,
        appStore,
        aUserProfile: param.aUserProfile
      })
    }
    return {
      ...toRefs(page),
      t,
      banUser,
    }
  },
  components: {
    InputSwitch 
  },
}


</script>

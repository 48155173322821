<template>
  <img v-if="pUserPictureUrl != ''" :src="pUserPictureUrl" />
  <div v-else class="imgPiUser pi pi-user"></div>
</template>
<script lang="ts">

import { useI18n } from "@/engineproject/default/common/i18n";
export default {
  props: {
    pUserPictureUrl: {
      type: String,
      required: true
    },
  },
  setup(props: any, context: any) {
    const {t} = useI18n()
    return {
      t,
    }
  },
}
</script>

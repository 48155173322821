import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_GMapCluster = _resolveComponent("GMapCluster")!
  const _component_GMapMap = _resolveComponent("GMapMap")!

  return (_openBlock(), _createElementBlock("div", {
    class: "m-container",
    onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
  }, [
    _createVNode(_component_GMapMap, {
      center: _ctx.mapCenter,
      zoom: 15,
      options: _ctx.mapOptions,
      class: "googleMap",
      style: {},
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickOnMap($event))),
      onDblclick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dblClickOnMap($event)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_GMapCluster, {
          minimumClusterSize: 2,
          styles: _ctx.clusterIcon,
          zoomOnClick: true
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrayOfMarkers, (m, index) => {
              return (_openBlock(), _createBlock(_component_GMapMarker, {
                key: index,
                title: m.title,
                label: m.label,
                position: m.position,
                clickable: true,
                draggable: false,
                icon: m.icon,
                onClick: ($event: any) => (_ctx.openAdDocFromMap(m.aAdDoc))
              }, null, 8, ["title", "label", "position", "icon", "onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["styles"])
      ]),
      _: 1
    }, 8, ["center", "options"])
  ]))
}
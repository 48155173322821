import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "activeTransactionsWithMe"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!

  return ($props.myContactUserProfile.getUPPseudo() !== _ctx.loggedUserProfile.getUPPseudo())
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.adDocsFromProfileWithOneOfMyActiveTransaction && _ctx.adDocsFromProfileWithOneOfMyActiveTransaction.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h5", null, _toDisplayString($setup.t('titleDocCreatedByPseudo', {aPseudo: $props.myContactUserProfile.getUPPseudo()})), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adDocsFromProfileWithOneOfMyActiveTransaction, (adDocsListInfo) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "documentInList",
                  key: adDocsListInfo.adDocIdInList
                }, [
                  _createVNode(_component_router_link, {
                    to: '/a/' + adDocsListInfo.adDocIdInList
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.closeAllModals()))
                      }, _toDisplayString(adDocsListInfo.adDocTitleInList), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.myAdDocsWithOneActiveTransactionFromProfile && _ctx.myAdDocsWithOneActiveTransactionFromProfile.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("h5", null, _toDisplayString($setup.t('titleDocCreatedByPseudo', {aPseudo: $props.myContactUserProfile.getUPPseudo()})), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myAdDocsWithOneActiveTransactionFromProfile, (adDocsListInfo) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "documentInList",
                  key: adDocsListInfo.adDocIdInList
                }, [
                  _createVNode(_component_router_link, {
                    to: '/a/' + adDocsListInfo.adDocIdInList
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.closeAllModals()))
                      }, _toDisplayString(adDocsListInfo.adDocTitleInList), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.adDocsFromProfileWithOneOfMyActiveTransaction && _ctx.adDocsFromProfileWithOneOfMyActiveTransaction.length === 0 &&
        _ctx.myAdDocsWithOneActiveTransactionFromProfile && _ctx.myAdDocsWithOneActiveTransactionFromProfile.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("h4", null, _toDisplayString($setup.t('titleNoTransaction')), 1),
              (_ctx.getContactFromContactsArray !== undefined && _ctx.getContactFromContactsArray !== false)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("div", null, _toDisplayString($setup.t('removeThePerson')), 1),
                    _createElementVNode("div", null, [
                      _createVNode(_component_Button, {
                        title: $setup.t('removeThePersonButtonTitle'),
                        onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.removeContact()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('removeThePersonButton')), 1)
                        ]),
                        _: 1
                      }, 8, ["title"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
<template>
  <div class="subtitle">{{ t("itemTransactionsList") }}</div>
  <div v-for="aTransaction in sortAndAddFootStepsPodium(ADColTransactions)"
    :key="aTransaction.ATId"
    class="transactions">
    <div class="transactionElement">
      <div class="transactionElementTitleArea">
        <span>{{ formatDate(aTransaction.ATCreatedOn)  }} 
          <MiniProfileViewLink :userMiniProfile="aTransaction.ATMUser"
              :prefixTextId="''"
              :displayAppBoss="false">ADColTransaction
          </MiniProfileViewLink>

          {{ displayStatus(aTransaction.ATStatus) }}
        </span>
        <div v-if="aTransaction.ATStatus == 'P' && aAdDoc.OADData.ADFreeSubscription === false" class="leftAlign">
          <button class="button"
              data-cy="cyUpdateTransactionAdDocToA"
            :title="t('adDocAcceptTheTransactionTitle')" @click="updateTransactionAdDoc({aTransaction, aAdDoc, newStatus: 'A' })">
          {{ t("adDocAcceptTheTransaction") }}
          </button>
          <button class="button" 
              data-cy="cyUpdateTransactionAdDocToR"
            :title="t('adDocRejectTheTransactionTitle')" @click="updateTransactionAdDoc({aTransaction, aAdDoc, newStatus: 'R' })">
          {{ t("adDocRejectTheTransaction") }}
          </button>
        </div>
        <div v-else-if="aTransaction.ATStatus == 'A'"  class="leftAlign">
            <button class="button" 
              data-cy="cyOpenProfileFromA"
              :title="t('openProfile')" @click="viewUserProfile(aTransaction.ATMUser.UMUserId)">
          {{ t("openProfileButtonText")}} 
            <a @click="viewUserProfile(aTransaction.ATMUser.UMUserId)"
              :title="t('openProfile')"
              >{{ aTransaction.ATMUser.UMPseudo }}</a>

            </button>
        </div>
      </div>
      <div class="transactionComment" :title="t('itemTransactionCommentTitle')">
        <pre>{{ aTransaction.ATComment }}</pre>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { inject } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { useDocumStore } from '@/engineproject/store/documStore';
import { useAppStore } from '@/engineproject/store/appStore';
import { useUserStore } from '@/engineproject/store/userStore';

import MiniProfileViewLink from '@/engineproject/components/UserProfile/MiniProfileViewLink.vue';
import AdDocViewTransactionFootStep from '@/engineproject/components/AdDocTransaction/AdDocViewTransactionFootStep.vue';
import { AdDocumentProjectTypes } from "@/engineproject/default/common/domainModel/adDocumentProjectTypes";


export default {
  props: {
    ADColTransactions: {
      type: Object as () => Array<any>,
      required: false,
    },
    aAdDoc: {
      type: Object as () => Array<any>,
      required: true,
    },
  },
  setup(props: any, context: any) {
    const formatDate = inject('formatDate') as any;
    const documStore = useDocumStore();
    const { t } = useI18n();
    const appStore = useAppStore();
    const userStore = useUserStore();

    const displayStatus = (aStatusValue: string) => {
      const readableStatus = t("transactionStatus_" + aStatusValue)
      return t("transactionStatusTitle", {theStatus : readableStatus})
    }

    const sortAndAddFootStepsPodium = (colTransactions: AdDocumentProjectTypes.AdTransaction[]) => {
      return colTransactions.sort((tA, tB) => {
        if (!tA.ATFootStepCount && tB.ATFootStepCount) {
          return 1
        }
        // if (!tB.ATFootStepCount) {
        //   return -1
        // }
        return (tB.ATFootStepCount - tA.ATFootStepCount)
      }).map((tr, index, arr) => {
        const firstWithStepCountEqualTo = arr.findIndex((trInArray: AdDocumentProjectTypes.AdTransaction) => {
          return (trInArray.ATFootStepCount === tr.ATFootStepCount)
        })
        const podium = firstWithStepCountEqualTo + 1
        return {
          ...tr,
        }
      })
    }

    const viewUserProfile = (aUserId: string) => {
      appStore.commit("setInModalUserProfileId", {inModalUserProfileId: aUserId});
    }

    const updateTransactionAdDoc = async (updateParams: any) => {
      if (updateParams.aAdDoc === undefined || updateParams.aTransaction === undefined) {
        return;
      }
      const result = await documStore.dispatch("updateTransactionAdDoc", {
        updateParams,
        appStore: appStore,
        userStore: userStore,
      });
    };

    return {
      t,
      displayStatus,
      formatDate,
      updateTransactionAdDoc,
      viewUserProfile,
      sortAndAddFootStepsPodium,
    };
  },
  components: { 
    MiniProfileViewLink,
  },
};
</script>
<style lang="scss" scoped>
</style>

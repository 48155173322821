<template>
    <div class="filter-container" >
      {{ t( titleTag ) }}
      <div>
        <input  data-cy="cyFilterSoireeOn" v-model="searchCalendarFilterSoiree" type="checkbox" @click="setSoireeOnOff"
           id="filterSoireeOn" />
        <label for="filterSoireeOn">{{ t('filterSoireeTitle') }}</label>
      </div>

      <div>
        <input  data-cy="cyFilterWeekendOn" v-model="searchCalendarFilterWeekend" type="checkbox" @click="setWeekendOnOff"
           id="filterWeekendOn" />
        <label for="filterWeekendOn">{{ t('filterWeekendTitle') }}</label>
      </div>

      <div>
        <div
          v-for="assetObj in filterForm.calendarShortCutSelectedDays"
          :key="assetObj.id">
          <input
            type="checkbox"
            :value="assetObj.checked"
            :id="assetObj.id"
            v-model="assetObj.checked"
            @click="filterAttributesObjectValueChanged(assetObj.in365Days, assetObj.checked)"
          />
          <label for="assetObj.id">{{ buildDateDisplay(assetObj) }}</label>
        </div>
      </div>
    </div>

</template>

<script lang="ts">
import {  reactive, toRefs, computed } from "vue";
import { daysBetweenJanurary1st } from '@/engineproject/epCommonWithBackend/dateUtils'
import { useI18n } from "@/engineproject/default/common/i18n";

import { useFilterAttributesStore } from '@/engineproject/store/filterAttributesStore'

export default {
  props: {
    titleTag: {
      type: String,
      required: false,
      default: 'filterCalendarTitle'
    },
  },
  setup(props: any) {
    const filterAttributesStore = useFilterAttributesStore()
    const {t, locale} = useI18n()

    const buildCalendarShortCutSelectedDays = (length: number, initValues: Array<number>) => {
      const ret = []
      const today = new Date()

      const newADDayStartsOn = daysBetweenJanurary1st(today);
      for (let i = 0; i < length; i++) {
        const aDate = new Date()
        aDate.setDate(aDate.getDate() + i)
        ret.push({
          id: i,
          checked: initValues.find((value) => value === newADDayStartsOn+i),
          in365Days: newADDayStartsOn+i,
          in365Date: aDate
        })
      }
      return ret;
    }
    const page: any = reactive({
      filterForm: {
        calendarShortCutIdToday: false,
        calendarShortCutIdTomorrow: false,
        calendarShortCutIdNextFriday: false,
        calendarShortCutSelectedDays: [...buildCalendarShortCutSelectedDays(8, 
          filterAttributesStore.state.searchCalendarFilter.calendarFilterDay365)]
      },
      searchCalendarFilterDay365: computed(() => filterAttributesStore.state.searchCalendarFilter.calendarFilterDay365),
      searchCalendarFilterSoiree: computed(() => filterAttributesStore.state.searchCalendarFilter.calendarFilterSoiree),
      searchCalendarFilterWeekend: computed(() => filterAttributesStore.state.searchCalendarFilter.calendarFilterWeekend),
    });
    const currentLocale = computed(() => locale.value);

    const filterAttributesObjectValueChanged = async (in365Days: number, checked: boolean) => {
      // setTimeout(async () => {
      await filterAttributesStore.dispatch("updateCalendarFilterDay365", {in365Days, actionUpdate: ((checked !== true) ? "A" : "R")});
      //}, 50)
    }

    const setSoireeOnOff = async () => {
      //setTimeout(async () => {
        await filterAttributesStore.dispatch("swapCalendarFilterSoiree");
      //}, 50)
    }

    const setWeekendOnOff = async () => {
      //setTimeout(async () => {
        await filterAttributesStore.dispatch("swapCalendarFilterWeekend");
      //}, 50)
    }

    const buildDateDisplay = (assetObj: any) => {
      // {{ assetObj.in365Days }} {{ assetObj.in365Date }}
      const dateShort = (aDate: Date) => {
        const options = { weekday: 'short', month: 'short', day: 'numeric' } as Intl.DateTimeFormatOptions ;
        // return ("0" + aDate.getDate()).slice(-2) + " / " + ("0"+(aDate.getMonth()+1)).slice(-2)
        return aDate.toLocaleDateString(currentLocale.value /*navigator.language*/, options)
      }
      if (assetObj.id === 0 ) {
        return t( "filterTodayTitle" ) + " (" + dateShort(assetObj.in365Date) + ")"
      } else if (assetObj.id === 1 ) {
        return t( "filterTomorrowTitle" ) + " (" + dateShort(assetObj.in365Date) + ")"
      }
      return  dateShort(assetObj.in365Date) 
    }
  
    return {
      ...toRefs(page),
      t,
      setSoireeOnOff,
      setWeekendOnOff,
      filterAttributesObjectValueChanged,
      buildDateDisplay,
    }
  },
  components: { 
  },
}


</script>
<style lang="scss" >
.inputClass {
  width: 100px !important;
  min-width: 100px !important;
}
#fZipcode, .p-autocomplete, .p-autocomplete > input  {
  width: 100px !important;
}
#fZipcode, .p-autocomplete, .p-autocomplete > input  {
  min-width: 100px !important;
}
#fDistance {
  width: 100px;
}
.filter-container {
  margin: 4px;
  padding: 2px;
}
</style>

<template>
  <div v-if="inputPrice === undefined || inputPrice == 0">
    {{ t("adDocPriceZero") }}
  </div>
  <div v-else>
    {{ t(inputPriceLabel, { inputPriceValue: inputPrice, priceCurrency: "€" }) }}
    <p v-if="inputPaidByOwner == 100">{{ t('percentagePaidByOwner100Title') }}</p>
    <p v-else-if="inputPaidByOwner !== undefined">
      {{ t('percentagePaidByOwnerNTitle', { percentagePaidByOwner: inputPaidByOwner })  }}
    </p>
  </div>
</template>
<script lang="ts">
import { reactive, toRefs } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

export default {
  props: {
    inputPrice: {
      type: Number,
      required: true
    },
    inputPaidByOwner: {
      type: Number || undefined,
      required: false
    },
    inputPriceLabel: {
      type: String,
      required: false,
      default: "adDocNormalPrice"
    },
  },
  setup(props: any, context: any) {
    const {t} = useI18n()
    const page = reactive({
      inputDate: props.inputDate,
    });
    return {
      ...toRefs(page),
      t,   
    }
  },
}
</script>

<style lang="scss" scoped>
.pi {
  font-size: 1rem;
}

.iconsContainer {
  display: flex;
}

.iconsContainer > a {
  margin: 20px;
}
</style>

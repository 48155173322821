//import  "@/engineproject/domainModel/adDocumentProjectTypes"
import { AdDocumentObject } from "@/engineproject/domainModel/adDocumentToolsTypes";
import { AdDocumentTypes } from "@/engineproject/domainModel/adDocumentTypes";
import { AdDocumentProjectTypes } from "./adDocumentProjectTypes";

export class AdDocumentProjectObject extends AdDocumentObject  {
  constructor();
  constructor(src: AdDocumentTypes.AdDocument);
  constructor(src: AdDocumentProjectTypes.AdDocument);
  constructor(src?: any) {
    super(src)
    // console.error(" IN constructor " + JSON.stringify(this))
    // console.error(" IN constructor " + this.getADId())
    this.setMandatoryDataFieldNames([
      "ADTitle",
      "ADDescription",
      "ADLocation",
      "ADDateHourStartsOn"
    ])
  }

  deepCopyProjectDoc = () => {
    const newDoc = new AdDocumentProjectObject(this.OADData);
    return newDoc 
  }
  detectChangesInProjectDoc = (aInitialDoc: AdDocumentProjectObject) => {
    return this.detectChangesInDoc(aInitialDoc)
  }
}
  
<template>
  <div id="mapContainer">
    <div v-if="showMapModal === true && getDisplayMapCenter !== undefined">
      <div style="position:relative;">
        <div style="position: fixed;display: block;top: 250px;width: 80%;left:100px;z-index:2000;">
        </div>
      </div>
      <div class="m-modal" @click="closeModal()">
        <AdDocDisplayMap></AdDocDisplayMap>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs } from 'vue'
import { useI18n } from "@/engineproject/default/common/i18n";

import { useFilterAttributesStore , getDisplayMapCenter} from '@/engineproject/store/filterAttributesStore'
import { useUserStore } from '@/engineproject/store/userStore'
import { useAppStore } from '@/engineproject/store/appStore';
import { useDocumStore } from '@/engineproject/store/documStore';

import AdDocDisplayMap from "@/engineproject/components/AdDocInMap/AdDocDisplayMap.vue";

export default defineComponent({
components: { 
  AdDocDisplayMap
  },
  setup() {
    const filterAttributesStore = useFilterAttributesStore()
    const documStore = useDocumStore();
    const appStore = useAppStore();
    const userStore = useUserStore();
    const {t} = useI18n()

    const page: any = reactive({
      openedMarkerID: null,
      showMapModal: computed(() => appStore.state.showMapModal),
      searchLocationFilter: computed(() => (filterAttributesStore.state.searchLocationFilter) ? 
        filterAttributesStore.state.searchLocationFilter :
        userStore.state.aUserProfile.getUPLocation()), 
      getDisplayMapCenter: computed(() => {return getDisplayMapCenter(filterAttributesStore.state, userStore.state)})

    });
    const closeModal = () => {
      appStore.commit("setShowMapModal", {showMapModal: false});
    }

    return {
      ...toRefs(page), 
      closeModal
    }
  },
})


</script>

import { AdDocumentTypes } from "./adDocumentTypes";
import { AdDocumentStates } from "./adDocumentStates";
import { AdDocumentProjectTypes } from '@/engineproject/default/common/domainModel/adDocumentProjectTypes';
import { mandatoryDataForEachTheme, defUrls, getDefaultUrlForUnivers, allFiltersConfig } from '@/engineproject/default/common/store/filterAttributesConfig'
import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes';
import { ADDateHourStartsOnNotRequiredAndNotDefined, DateUndefined } from "./dateToolsTypes";
import { fieldIsDefined } from "./globalTypes";
import { mixedFilter } from "@/projects/alomigo/common/store/filterAttributesConfig";
import { UserTypes } from "./userTypes";
import { allPublicWithoutEnterprise, EnterpriseInfoTypes } from "./enterpriseInfoTypes";



const isIntegerMinMax = (val: any, min: number, max: number) => {
  // console.error("isIntegerMinMax *" + val + "* " + (typeof val))
  /*if (typeof val !== "number") {
    return false
  }*/
  if (typeof val !== "number" && parseInt(val).toString() !== val) {
    // console.error("isIntegerMinMax 1")
    return false
  }
  if (parseInt(val) >= min && parseInt(val) <= max) {
    // console.error("isIntegerMinMax 2")
    return true
  }
  // console.error("isIntegerMinMax 3")
  return false
}
/*
const fieldIsDefined = (aDate: any) => {
  return  (aDate !== null &&
    aDate !== undefined &&
    aDate != ""
    )
}*/



export class AdDocumentObject {
  OADData!: AdDocumentTypes.AdDocument;
  mandatoryDataFieldNames!: Array<string>;
  ADocState!: AdDocumentStates.AdDocState;

  constructor();
  constructor(srcAdDocumentObjectt: AdDocumentObject)
  constructor(srcOADData: AdDocumentTypes.AdDocument)

  constructor(src?: any) {
    if (src !== undefined) {
      if (src.OADData !== undefined) {
        //console.error("constructor src.OADData " + JSON.stringify(src.OADData))
        // this.OADData =  new AdDocumentDataObject(src.OADData)
        this.OADData = this.deepCopyDocOADData(src.OADData)
      } else {
        //console.error("constructor src " + JSON.stringify(src))
        // this.OADData =  new AdDocumentDataObject(src)
        this.OADData = this.deepCopyDocOADData(src)
      }
    } else {
      this.OADData = {} as AdDocumentTypes.AdDocument
    }
    this.ADocState = {
      ADSCommentsIsLoading: false,
      ADSLikesIsLoading: false,
      ADSInvitationsIsLoading: false,
      ADSTransactionsIsLoading: false,
    }
    //console.error("OADData in constructor " + JSON.stringify(this))
  }

  deepCopyDocOADData = (aOADData: AdDocumentTypes.AdDocument) => {
    const newOADData = {
      ...aOADData,
      ADLocation: (aOADData.ADLocation) ? { ...aOADData.ADLocation } : undefined,
      ADColComments: (aOADData.ADColComments) ? [...aOADData.ADColComments] : [],
      ADColLikes: (aOADData.ADColLikes) ? [...aOADData.ADColLikes] : [],
      ADColAttDoc: (aOADData.ADColAttDoc) ? [...aOADData.ADColAttDoc] : [],
      ADColInvitations: (aOADData.ADColInvitations) ? [...aOADData.ADColInvitations] : [],
      ADColTransactions: (aOADData.ADColTransactions) ? [...aOADData.ADColTransactions] : [],
      ADColPodiums: (aOADData.ADColPodiums) ? [...aOADData.ADColPodiums] : [],
    }
    return newOADData
  }


  public static convertOADDataArrayFromBackend(aOADDataArrayFromBackend: Array<AdDocumentTypes.AdDocument>) {
    //  queryResult.OADDataArrayFromBackend
    return aOADDataArrayFromBackend.map((oOADData) => {
      return new AdDocumentObject(oOADData)
    })
  }


  convertToMiniModel() {
    if (this.OADData.ADId !== undefined && this.OADData.ADId !== "") {
      return {
        MADocId: this.OADData.ADId,
        MADocTitle: this.OADData.ADTitle,
        MADocFileType: this.OADData.ADDocFileType,
        MADocOwnerUserId: this.OADData.ADOwnerMiniProfile.UOUserId
      }
    }
    return {}
  }

  // TODO SEE HOW TO REMOVE ALL GETS
  getOwnerUserId() {
    if (this.OADData.ADOwnerMiniProfile !== undefined) {
      return this.OADData.ADOwnerMiniProfile.UOUserId
    }
    return "0"
    // TODO CLEANUP
    // return this.OADData.ADOwnerUserId
  }

  getADId() {
    return this.OADData.ADId
  }

  /*
  getADDocUrl()  {
    return "https://alomigoid.web.app/a/" + this.OADData.ADId
  }*/

  getADDocUrlForOGAndSEO(hashTag: string | undefined) {
    // return "https://alomigoid.web.app/ac/" + this.OADData.ADId
    return window.location.protocol + "//" + window.location.host + "/ac/" + this.OADData.ADId +
      (hashTag !== undefined ? hashTag : "");
  }

  getADDocUrlRoute() {
    // return "https://alomigoid.web.app/ac/" + this.OADData.ADId
    return (this.isTemplate() === true ? "/at/" : (this.isSuggestion() === true ? "/as/" : "/a/")) + this.OADData.ADId
  }

  getADDocFirstAttachedDocUrl() {
    if (this.OADData.ADColAttDoc[0]) {
      return this.OADData.ADColAttDoc[0].AADDocumentUrl
    }
    return ""
  }

  getADUnivers() {
    return this.OADData.ADUnivers
  }

  getADCatsInUnivers() {
    if (this.OADData.ADCatsInUnivers !== undefined) {
      return this.OADData.ADCatsInUnivers
    }
    return ""
  }

  getADLocation() {
    return this.OADData.ADLocation
  }

  getADColLikes() {
    return this.OADData.ADColLikes
  }

  addADColLikes(newLikeItem: any) {
    this.OADData.ADColLikes.push(newLikeItem)
  }

  removeADColLikes(newLikeItem: any) {
    this.OADData.ADColLikes = this.OADData.ADColLikes.reduce((prev, curr) => {
      if (curr.ALLUserId !== newLikeItem.ALLUserId) {
        prev.push(curr)
      }
      return prev
    }, [] as Array<AdDocumentProjectTypes.AdLike>)
  }

  getADTotLikes() {
    return this.OADData.ADTotLikes
  }
  getADTotComments() {
    return this.OADData.ADTotComments
  }
  getADDescription() {
    return this.OADData.ADDescription
  }
  getADTitle() {
    return this.OADData.ADTitle
  }
  getADDateHourStartsOn() {
    if (this.OADData.ADDateHourStartsOn === undefined &&
      this.isRequiredInPublishedState("ADDateHourStartsOn") === false) {
      return ADDateHourStartsOnNotRequiredAndNotDefined
    }

    return this.OADData.ADDateHourStartsOn
  }
  getValueADDateHourStartsOn() {
    return this.OADData.ADDateHourStartsOn
  }
  getValueADDateHourEndsOn() {
    return this.OADData.ADDateHourEndsOn
  }
  getADCreatedOn() {
    return this.OADData.ADCreatedOn
  }
  getAttDocWithOut(imgId: string) {
    let needToBeRemoved = false as boolean
    const newColAttDoc = this.OADData.ADColAttDoc.reduce((prev, curr) => {
      if (curr.AADId !== imgId) {
        prev.push(curr)
      } else {
        needToBeRemoved = true
      }
      return prev
    }, [] as Array<AdDocumentProjectTypes.AdAttachedDocument>)
    return ((needToBeRemoved === true) ? { newColAttDoc } : undefined)
  }

  getUserIdWithPendingOrAcceptedTransactions() {
    const userIdWithPendingOrAcceptedTransactions = this.OADData.ADColTransactions.reduce((prev: any, curr) => {
      if (curr.ATStatus == "A" || curr.ATStatus == "P") {
        prev.push(curr.ATMUser.UMUserId)
      }
      return prev
    }, [])
    return userIdWithPendingOrAcceptedTransactions
  }

  migrationOfworkFlowStates(preparedDoc: any) {
    if (preparedDoc.ADWorkFlowStates === undefined) {
      preparedDoc.ADWorkFlowStates = {
        ADResetDone: null,
        ADContributionsDone: null,
        ADState1: null,
        ADState2: null,
        ADState3: null,
      }
    } else if (preparedDoc.ADWorkFlowStates !== undefined) {
      if (preparedDoc.ADWorkFlowStates.ADResetDone === undefined) {
        preparedDoc.ADWorkFlowStates.ADResetDone = null
      }
      if (preparedDoc.ADWorkFlowStates.ADContributionsDone === undefined) {
        preparedDoc.ADWorkFlowStates.ADContributionsDone = null
      }
      if (preparedDoc.ADWorkFlowStates.ADState1 === undefined) {
        preparedDoc.ADWorkFlowStates.ADState1 = null
      }
      if (preparedDoc.ADWorkFlowStates.ADState2 === undefined) {
        preparedDoc.ADWorkFlowStates.ADState2 = null
      }
      if (preparedDoc.ADWorkFlowStates.ADState3 === undefined) {
        preparedDoc.ADWorkFlowStates.ADState3 = null
      }
    }
  }

  exportToFirebaseObject() {
    const preparedDoc: any = {};
    Object.assign(preparedDoc, this.OADData);
    // ADWorkFlowStates ADResetDone
    // Migration of old records
    this.migrationOfworkFlowStates(preparedDoc)
    // Fix date format
    if (preparedDoc.ADDateHourStartsOn !== undefined && typeof preparedDoc.ADDateHourStartsOn === "string") {
      preparedDoc.ADDateHourStartsOn = new Date(preparedDoc.ADDateHourStartsOn)
    }
    if (preparedDoc.ADDateHourEndsOn !== undefined && typeof preparedDoc.ADDateHourEndsOn === "string") {
      preparedDoc.ADDateHourEndsOn = new Date(preparedDoc.ADDateHourEndsOn)
    }
    if (preparedDoc.ADDateHourEndsOn === undefined && preparedDoc.ADDateHourStartsOn !== undefined ) {
      preparedDoc.ADDateHourEndsOn = preparedDoc.ADDateHourStartsOn
    }
    //console.error("exportToFirebaseObject " +  JSON.stringify(preparedDoc.ADEnterprise ))
    if(preparedDoc.ADEnterprise === undefined) {
      preparedDoc.ADEnterprise = allPublicWithoutEnterprise
    }
    //console.error("exportToFirebaseObject " +  JSON.stringify(preparedDoc.ADEnterprise ))
    // setDefaultBeforeSaveAdDoc
    delete preparedDoc.ADColInvitations
    delete preparedDoc.ADColTransactions
    delete preparedDoc.ADColPodiums
    delete preparedDoc.ADColComments
    delete preparedDoc.ADColLikes
    // console.error("exportToFirebaseObject preparedDoc " + JSON.stringify(preparedDoc))
    return preparedDoc
  }

  sameDateHourStartsOn(aInitialDoc: AdDocumentProjectObject) {
    return (aInitialDoc.OADData.ADDateHourStartsOn === this.OADData.ADDateHourStartsOn)
  }
  validateIdAndDateHourStartsOn(errorMsg: string | undefined) {
    //if (!this.OADData.ADDateHourStartsOn) {
    if (this.getADDateHourStartsOn() === undefined) {
      errorMsg = "UndefinedADDateHourStartsOn"
    }
    if (!this.OADData.ADId) {
      errorMsg = "UndefinedADId"
    }
    return errorMsg
  }

  setDefaultBeforeSaveAdDoc() {
    //console.error("setDefaultBeforeSaveAdDoc this.OADData " + JSON.stringify(this.OADData))
    if (this.OADData.ADColAttDoc === undefined ||
      this.OADData.ADColAttDoc.length === 0 &&
      this.OADData.ADUnivers && this.OADData.ADCatsInUnivers !== undefined
    ) {
      if ((!this.OADData.ADDefaultAttDoc || this.OADData.ADDefaultAttDoc === "") && this.OADData.ADCatsInUnivers !== undefined) {
        const defObj = getDefaultUrlForUnivers(defUrls, this.OADData.ADUnivers, this.OADData.ADCatsInUnivers)
        //console.error("setDefaultBeforeSaveAdDoc 3 " + JSON.stringify(defObj))
        if (defObj !== undefined && defObj.docUrl !== undefined) {
          this.OADData.ADDefaultAttDoc = defObj.docUrl + "&token=" + defObj.docTokenUrl;
          this.OADData.ADDefaultAttDocLicense = defObj.docLicense;
        } else {
          this.OADData.ADDefaultAttDoc = ""
          this.OADData.ADDefaultAttDocLicense = ""
        }
      }
    } else {
      this.OADData.ADDefaultAttDoc = ""
      this.OADData.ADDefaultAttDocLicense = ""

    }
  }

  isADId(aDocId: string) {
    return this.OADData.ADId === aDocId
  }
  setADId(aDocId: string) {
    this.OADData.ADId = aDocId
  }
  setADCatsInUnivers(newValue: any) {
    if (newValue === undefined) {
      delete this.OADData.ADCatsInUnivers
    } else {
      this.OADData.ADCatsInUnivers = newValue
    }
  }

  setADColAttDoc(newADCollAttDoc: Array<any>) {
    this.OADData.ADColAttDoc = newADCollAttDoc
  }
  setAdDocEnterprise(newEnterprise: EnterpriseInfoTypes.EnterpriseInfo | undefined) {
    this.OADData.ADEnterprise = newEnterprise
  }
  addNewAttDoc(anAttachedDoc: AdDocumentTypes.AdAttachedDocument) {
    if (this.OADData.ADColAttDoc === undefined) {
      this.OADData.ADColAttDoc = []
    }
    this.OADData.ADColAttDoc.push(anAttachedDoc)
  }
  mergeAttachedFiles(docAttachedFiles: AdDocumentProjectTypes.AdAttachedDocument[]) {
    if (this.OADData.ADColAttDoc) {
      // The attached documents can also be in the incoming aAdDoc when the 
      // process to add documents is aborted (the docData exists but the list 
      // of attached is not yet saved in the database)
      const missingAttachedDocs = this.OADData.ADColAttDoc.reduce((prev: Array<AdDocumentProjectTypes.AdAttachedDocument>,
        curr: AdDocumentProjectTypes.AdAttachedDocument) => {
        if (docAttachedFiles.some((ele: AdDocumentProjectTypes.AdAttachedDocument) => {
          return curr.AADId === ele.AADId
        }) === false) {
          prev.push(curr)
        }
        return prev
      }, Array<AdDocumentProjectTypes.AdAttachedDocument>())
      docAttachedFiles = docAttachedFiles.concat(missingAttachedDocs)
    }
    return docAttachedFiles
  }
  setMandatoryDataFieldNames(val: Array<string>) {
    this.mandatoryDataFieldNames = val
  }
  setADTotLikes(newADTotLikes: number) {
    this.OADData.ADTotLikes = newADTotLikes
  }

  setADColLikes(newADColLikes: Array<any>) {
    this.OADData.ADColLikes = newADColLikes
  }

  setADColComments(newADColComments: Array<any>) {
    this.OADData.ADColComments = newADColComments
  }
  setCommentsIsLoading(commentsIsLoading: boolean) {
    this.ADocState.ADSCommentsIsLoading = commentsIsLoading
  }
  setLikesIsLoading(likesIsLoading: boolean) {
    this.ADocState.ADSLikesIsLoading = likesIsLoading
  }
  setInvitationsIsLoading(invitationsIsLoading: boolean) {
    this.ADocState.ADSInvitationsIsLoading = invitationsIsLoading
  }
  setTransactionsIsLoading(transactionsIsLoading: boolean) {
    this.ADocState.ADSTransactionsIsLoading = transactionsIsLoading
  }
  getCommentsIsLoading() {
    return this.ADocState.ADSCommentsIsLoading
  }
  getLikesIsLoading() {
    return this.ADocState.ADSLikesIsLoading
  }
  getInvitationsIsLoading() {
    return this.ADocState.ADSInvitationsIsLoading
  }
  geTransactionsIsLoading() {
    return this.ADocState.ADSTransactionsIsLoading
  }
  addNewComment(aComment: AdDocumentTypes.AdComment) {
    if (this.OADData.ADColComments === undefined) {
      this.OADData.ADColComments = []
    }
    this.OADData.ADColComments.push(aComment)
  }
  removeComment(commentId: string) {
    if (this.OADData.ADColComments === undefined) {
      return
    }
    this.OADData.ADColComments = this.OADData.ADColComments.reduce((accumulator: Array<AdDocumentProjectTypes.AdComment>,
      currentValue: AdDocumentProjectTypes.AdComment) => {
      if (currentValue.ACId !== commentId) {
        accumulator.push(currentValue)
      }
      return accumulator
    }, Array<AdDocumentProjectTypes.AdComment>())
  }

  setADTotComments(newADTotComments: number) {
    this.OADData.ADTotComments = newADTotComments
  }

  getADColInvitations() {
    return this.OADData.ADColInvitations
  }

  getADColTransactions() {
    return this.OADData.ADColTransactions
  }

  getADColPodiums() {
    return this.OADData.ADColPodiums
  }

  getCSSClass() {
    let cssClass = ""
    if (this.isTemplate() === true) {
      cssClass += " classAdDocFileTypeT "
    } else if (this.isSuggestion() === true) {
      cssClass += " classAdDocFileTypeS "
    } else {
      if (this.isPublished() !== true) {
        cssClass += " classAdDocFileType "
      }
    }
    return cssClass
  }

  getCSSClassForPost() {
    let cssClass = ""
    if (this.isTemplate() === true) {
      cssClass += " adDocTemplate "
    } else if (this.isSuggestion() === true) {
      cssClass += " adDocSuggestion "
    } else {
      if (this.isPublished() !== true) {
        cssClass += " unpublishedAd "
      }
    }
    return cssClass
  }
  /*
    getIntentionI18NId() {
      if (this.OADData.) {
        mixedFilter.filterValues
      }
    }
  
    getMixedI18NId() {
  
    }
  */
  setADColInvitations(newADColInvitations: Array<any>) {
    this.OADData.ADColInvitations = newADColInvitations
  }

  setADColTransactions(newADColTransactions: Array<any>) {
    this.OADData.ADColTransactions = newADColTransactions
  }

  setADColPodiums(newADColTransactions: Array<any>) {
    this.OADData.ADColPodiums = newADColTransactions
  }
  replaceOrAddInvitation(aInvitation: AdDocumentTypes.AdInvitation) {
    this.OADData.ADColInvitations = this.OADData.ADColInvitations.reduce((accumulator: Array<AdDocumentProjectTypes.AdInvitation>,
      currentValue: AdDocumentProjectTypes.AdInvitation) => {
      if (currentValue.AIId !== aInvitation.AIId) {
        accumulator.push(currentValue)
      }
      return accumulator
    }, Array<AdDocumentProjectTypes.AdInvitation>())
    this.OADData.ADColInvitations.push(aInvitation)
  }
  replaceOrAddTransaction(aTransaction: AdDocumentTypes.AdTransaction) {
    this.OADData.ADColTransactions = this.OADData.ADColTransactions.reduce((accumulator: Array<AdDocumentProjectTypes.AdTransaction>,
      currentValue: AdDocumentProjectTypes.AdTransaction) => {
      if (currentValue.ATId !== aTransaction.ATId) {
        accumulator.push(currentValue)
      }
      return accumulator
    }, Array<AdDocumentProjectTypes.AdTransaction>())
    this.OADData.ADColTransactions.push(aTransaction)
  }
  updateTransaction(aTransaction: AdDocumentTypes.AdTransaction) {
    this.OADData.ADColTransactions = this.OADData.ADColTransactions.reduce((accumulator: Array<AdDocumentProjectTypes.AdTransaction>,
      currentValue: AdDocumentProjectTypes.AdTransaction) => {
      if (currentValue.ATId !== aTransaction.ATId) {
        accumulator.push(currentValue)
      } else {
        accumulator.push(aTransaction)
      }
      return accumulator
    }, Array<AdDocumentProjectTypes.AdTransaction>())
  }
  updatePodium(aTransaction: AdDocumentTypes.AdTransaction) {
    this.OADData.ADColPodiums = this.OADData.ADColPodiums.reduce((accumulator: Array<AdDocumentProjectTypes.AdTransaction>,
      currentValue: AdDocumentProjectTypes.AdTransaction) => {
      if (currentValue.ATId !== aTransaction.ATId) {
        accumulator.push(currentValue)
      } else {
        accumulator.push(aTransaction)
      }
      return accumulator
    }, Array<AdDocumentProjectTypes.AdTransaction>())
  }
  removeInvitation(removedInvitationId: string) {
    this.OADData.ADColInvitations = this.OADData.ADColInvitations.reduce((accumulator: Array<AdDocumentProjectTypes.AdInvitation>,
      currentValue: AdDocumentProjectTypes.AdInvitation) => {
      if (currentValue.AIId !== removedInvitationId) {
        accumulator.push(currentValue)
      }
      return accumulator
    }, Array<AdDocumentProjectTypes.AdInvitation>())
  }
  removeTransaction(removedTransactionId: string) {
    this.OADData.ADColTransactions = this.OADData.ADColTransactions.reduce((accumulator: Array<AdDocumentProjectTypes.AdTransaction>,
      currentValue: AdDocumentProjectTypes.AdTransaction) => {
      if (currentValue.ATId !== removedTransactionId) {
        accumulator.push(currentValue)
      }
      return accumulator
    }, Array<AdDocumentProjectTypes.AdTransaction>())
  }

  isDefinedLocation() {
    return (this.OADData.ADLocation !== undefined &&
      (this.OADData.ADLocation.LPLat !== undefined ||
        (this.OADData.ADLocation.LPZipCode !== undefined &&
          this.OADData.ADLocation.LPZipCode != "")
      ))
  }

  isFreeSubscription() {
    return (this.OADData.ADFreeSubscription === true)
  }

  isDefinedDateHourStartsOn() {
    return fieldIsDefined(this.OADData.ADDateHourStartsOn)
  }

  isDefinedUnivers() {
    // console.error("isDefinedUnivers")
    // console.error("this.OADData.ADUnivers " + this.OADData.ADUnivers)
    // console.error("mandatoryDataForEachTheme this.OADData.ADUnivers " + mandatoryDataForEachTheme[this.OADData.ADUnivers])
    return this.OADData.ADUnivers !== "" &&
      this.OADData.ADUnivers !== undefined &&
      mandatoryDataForEachTheme[this.OADData.ADUnivers] !== undefined &&
      mandatoryDataForEachTheme[this.OADData.ADUnivers].some((aDataFieldName: string | Array<string>) => {
        // console.error("this.OADData.ADUnivers " + JSON.stringify(this.OADData.ADUnivers))
        // console.error("aDataFieldName " + JSON.stringify(aDataFieldName))
        if (Array.isArray(aDataFieldName) === true) {
          const anArrayString = aDataFieldName as Array<string>
          return (anArrayString.some((aDataFieldNameOr: string) => {
            return this.OADData && this.OADData[aDataFieldNameOr as keyof AdDocumentTypes.AdDocument] !== undefined
              && this.OADData[aDataFieldNameOr as keyof AdDocumentTypes.AdDocument] !== ""
          }) === false)
        } else {
          // console.error("this.OADData " + this.OADData[aDataFieldName as keyof AdDocumentTypes.AdDocument])
          return this.OADData && this.OADData[aDataFieldName as keyof AdDocumentTypes.AdDocument] === undefined
        }
      }) === false
  }

  isDefinedTitleAndDescription() {
    return fieldIsDefined(this.OADData.ADDescription) &&
      fieldIsDefined(this.OADData.ADTitle)
  }
  isRequiredInPublishedState = (dataFieldName: string,) => {
    return this.mandatoryDataFieldNames.includes(dataFieldName)
  }

  isDefinedADId() {
    return fieldIsDefined(this.OADData.ADId)
  }

  isOwner(aUserId: string) {
    // console.error("isOwner aUserId " + aUserId)
    // console.error("isOwner this.OADData.ADOwnerMiniProfile " + JSON.stringify(this.OADData.ADOwnerMiniProfile))
    if (this.OADData.ADOwnerMiniProfile !== undefined) {
      return (this.OADData.ADOwnerMiniProfile.UOUserId === aUserId)
    }
    return false
    // TODO CLEANUP
    // return (this.OADData.ADOwnerUserId === aUserId)
  }
  isFromPremiumAccount() {
    if (this.OADData.ADOwnerMiniProfile !== undefined) {
      return (this.OADData.ADOwnerMiniProfile.UOFromPremiumAccount === undefined) ? false :
        this.OADData.ADOwnerMiniProfile.UOFromPremiumAccount
    }
    return false
    // TODO CLEANUP
    // return (this.OADData.ADFromPremiumAccount === undefined) ? false : this.OADData.ADFromPremiumAccount
  }
  isPublished() {
    return (this.OADData.ADPublishedStatus === true)
  }

  isAdDoc() {
    if (this.isSuggestion() === true) {
      return false
    }
    if (this.isTemplate() === true) {
      return false
    }
    return true
  }

  isSuggestion() {
    return (this.OADData.ADDocFileType === "S")
  }

  isTemplate() {
    return (this.OADData.ADDocFileType === "T")
  }

  getCollectionFromDocType(fb: any) {
    if (this.isSuggestion() === true) {
      return fb.adSugCollection
    }
    if (this.isTemplate() === true) {
      return fb.adTemCollection
    }
    return fb.adDocCollection
  }

  isPublicTemplate() {
    return (this.OADData.ADTemplatePublicStatus === true)
  }

  isVisibleSuggestion() {
    return (this.OADData.ADSuggestionVisibleStatus === true)
  }

  isEditing() {
    return (fieldIsDefined(this.OADData.ADDescription) ||
      fieldIsDefined(this.OADData.ADTitle) ||
      fieldIsDefined(this.OADData.ADDateHourStartsOn) ||
      this.isDefinedLocation())
  }

  isAnyInvitation() {
    return this.OADData.ADColInvitations && this.OADData.ADColInvitations.length > 0
  }

  isAnyTransaction() {
    return this.OADData.ADColTransactions && this.OADData.ADColTransactions.length > 0
  }

  isFieldNotRequiredOrRequiredAndDefined(fieldName: string) {
    if (this.isRequiredInPublishedState(fieldName) === false) {
      return true
    }
    return fieldIsDefined(this.OADData[fieldName as keyof AdDocumentTypes.AdDocument])
  }

  isLocationNotRequiredOrRequiredAndDefined() {
    if (this.isRequiredInPublishedState("ADLocation") === false) {
      return true
    }
    return this.isDefinedLocation()
  }

  canHaveTransactions() {
    if (this.isSuggestion() === true) {
      return false
    }
    if (this.isTemplate() === true) {
      return false
    }
    return true
  }

  canHaveLikesAndComments() {
    if (this.isSuggestion() === true) {
      return false
    }
    if (this.isTemplate() === true) {
      return false
    }
    return true
  }

  canBeSharedAndGetPromotion() {
    if (this.isSuggestion() === true) {
      return false
    }
    if (this.isTemplate() === true) {
      return false
    }
    return true
  }

  canBePublished() {
    // console.error("canBePublished " + JSON.stringify(this.OADData))

    return this.isFieldNotRequiredOrRequiredAndDefined("ADDescription") &&
      this.isFieldNotRequiredOrRequiredAndDefined("ADTitle") &&
      this.isFieldNotRequiredOrRequiredAndDefined("ADDateHourStartsOn") &&
      this.isLocationNotRequiredOrRequiredAndDefined() &&
      this.OADData.ADPublishedStatus === true &&
      this.OADData.ADYourPrice !== undefined &&
      (this.OADData.ADPercentagePaidByOwner === undefined || isIntegerMinMax(this.OADData.ADPercentagePaidByOwner, 0, 100))
  }

  deepCopyDoc = () => {
    const newDoc = new AdDocumentObject({
      ...this.OADData,
      ADLocation: (this.OADData.ADLocation) ? { ...this.OADData.ADLocation } : undefined,
      ADColComments: (this.OADData.ADColComments) ? [...this.OADData.ADColComments] : [],
      ADColLikes: (this.OADData.ADColLikes) ? [...this.OADData.ADColLikes] : [],
      ADColAttDoc: (this.OADData.ADColAttDoc) ? [...this.OADData.ADColAttDoc] : [],
      ADColInvitations: (this.OADData.ADColInvitations) ? [...this.OADData.ADColInvitations] : [],
      ADColTransactions: (this.OADData.ADColTransactions) ? [...this.OADData.ADColTransactions] : [],
      ADColPodiums: (this.OADData.ADColPodiums) ? [...this.OADData.ADColPodiums] : [],
    })
    return newDoc
  }

  detectChangesInDoc = (aInitialDoc: AdDocumentObject) => {
    if (!aInitialDoc) {
      return {
        docChanges: [],
        importantChangesCounter: 0
      }
    }
    const importantChangeInFields = [
      "ADLocation", "ADYourPrice", "ADPercentagePaidByOwner"
    ];
    const lessImportantChangeInFields = [
      "ADFreeSubscription", "ADTitle", "ADDescription"
    ];
    let importantChangesCounter = 0;
    let lessImportantChangesCounter = 0;

    const docChanges = [] as Array<any>
    if (aInitialDoc.OADData.ADDateHourStartsOn !== this.OADData.ADDateHourStartsOn) {
      //console.error("saveAdDoc Date change detected! ")
      importantChangesCounter++;
      docChanges.push({
        DCType: "ADDateHourStartsOn",
        DCNew: this.OADData.ADDateHourStartsOn.toISOString(),
        DCOld: aInitialDoc.OADData.ADDateHourStartsOn.toISOString(),
      })
    }
    importantChangeInFields.forEach((aFieldName: any) => {
      if (JSON.stringify(Object(aInitialDoc.OADData)[aFieldName]) !== JSON.stringify(Object(this.OADData)[aFieldName])) {
        importantChangesCounter++;
        docChanges.push({
          DCType: aFieldName,
          DCNew: Object(this.OADData)[aFieldName],
          DCOld: Object(aInitialDoc.OADData)[aFieldName],
        })
      }
    })
    lessImportantChangeInFields.forEach((aFieldName: any) => {
      if (JSON.stringify(Object(aInitialDoc.OADData)[aFieldName]) !== JSON.stringify(Object(this.OADData)[aFieldName])) {
        lessImportantChangesCounter++;
        docChanges.push({
          DCType: aFieldName,
          DCNew: Object(this.OADData)[aFieldName],
          DCOld: Object(aInitialDoc.OADData)[aFieldName],
        })
      }
    })
    return {
      docChanges,
      importantChangesCounter
    }
  }
}

// console.error("adDocFirebase in adDocumentToolsTypes 2 " + adDocFirebase)

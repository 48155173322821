<template>
  <div class="displayRemainingPlacesContainer">
    <div class="displayRemainingPlaces">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    adDocFileType: {
      type: String,
      required: false,
      default: "",
    },
    aAdDocSubList: {
      type: Object as () => Array<any>,
      required: true,
    },
  },
  setup(props: any, context: any) {
    return {};
  },
  components: {},
};
</script>
<style lang="scss">
.displayRemainingPlacesContainer {
  position: relative;
  .displayRemainingPlaces {
    font-size: xx-small;
    transform: rotate(45deg); /* Équivalent à rotateZ(45deg) */
    background-color: #4472c4; // lightblue;
    color: white;
    width: fit-content;
    position: absolute;
    right: -28px;
    top: -0px;
    opacity: 80%;
    padding: 4px;
    border-radius: 4px;
    font-weight: 600;
  }
}
</style>

import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-929b1efc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.inputPrice === undefined || $props.inputPrice == 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.t("adDocPriceZero")), 1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createTextVNode(_toDisplayString($setup.t($props.inputPriceLabel, { inputPriceValue: $props.inputPrice, priceCurrency: "€" })) + " ", 1),
        ($props.inputPaidByOwner == 100)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($setup.t('percentagePaidByOwner100Title')), 1))
          : ($props.inputPaidByOwner !== undefined)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($setup.t('percentagePaidByOwnerNTitle', { percentagePaidByOwner: $props.inputPaidByOwner })), 1))
            : _createCommentVNode("", true)
      ]))
}
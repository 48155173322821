{
  "addInvitationTextPH": "Ajouter une note ...",
  "invitationIsLoadingText": "Chargement des invitations ...",

  "addInvitationErrorDocNotFoundOrNotAuthorized": "Document inexistant ou droits d'accès insuffisant",

  "itemInvitationsList": "Liste des invitations",
  "itemInvitationsEmpty": "Aucune invitation pour votre annonce",
  "itemInvitations" : "Invitations:",
  "itemMyInvitation": "Inviter:",
  "sendInvitationButtonText": "Inviter",
  "itemYourInvitationsStatus": "Statut de vos invitations:",
  "adDocAddInvitationTitle": "Envoyer une demande à l'organisateur de l'annonce",
  "adDocAddInvitationRequest": "Subscription Request",
  "adDocOpenInvitationPopup": "Inviter des contacts",
  "adDocOpenInvitationPopupTitle": "Visualiser la liste de contacts pour les inviter à participer",
  "adDocOpenInvitationPerMailPopup": "Inviter par email",
  "adDocOpenInvitationPerMailPopupTitle": "Envoyer des invitations à une liste de personnes",

  "adDocOpenInvitationPerMailSubject": "Invitation Alomigo",
  "adDocOpenInvitationPerMailBodyLine1": "Bonjour,",
  "adDocOpenInvitationPerMailBodyLine2": "Je vous invite à une activité Alomigo:",
  "adDocOpenInvitationPerMailBodyLine3": "Ajoutez l'évènement à votre agenda en utilisant un des liens suivants:",
  "adDocOpenInvitationPerMailBodyLine4": "Google Agenda:",
  "adDocOpenInvitationPerMailBodyLine5": "Office 365 Agenda:",
  "adDocOpenInvitationPerMailBodyLine6": "A bientôt!",

  "adDocRemoveMyInvitation": "Retirer ma demande d'inscription",
  "adDocAcceptTheInvitation": "Accept",
  "adDocAcceptTheInvitationTitle": "Accept l'invitation",
  "adDocRejectTheInvitation": "Reject",
  "adDocRejectTheInvitationTitle": "Refuser l'invitation",

  "noAdDocFoundByAutoInvitationProcess": "Aucune invitation possible",
  "invitationSentMsg": "Invitation envoyée",
  "invitationAlreadySentMsg": "Invitation déjà existante.",

  "invitationStatus_A": "Envoyée (automatiquement par l'application).",
  "invitationStatus_R": "Envoyée (demandée par l'utilisateur).",
  "invitationStatus_S": "Envoyée (manuellement par vous).",

  "invitationNotifText_TA": "Votre demande de participation a été acceptée:",
  "invitationNotifText_TF": "Vous avez reçu une nouvelle inscription libre pour votre annonce:",
  "invitationNotifText_TR": "Votre demande de participation n'a pas été acceptée:",
  "invitationNotifText_TP": "Vous avez reçu une nouvelle demande de participation pour votre annonce:",
  "invitationNotifText_TC": "Vous avez reçu une annulation de participation pour votre annonce:",
  "invitationNotifText_DC": "L'annonce suivante a été modifiée:",
  "invitationNotifText_DR": "L'annonce suivante a été signalée:",

  "invitationStatusTitle": "{theStatus}",

  "invitationAddedNotifMsg": "Invitation envoyée.",
  "invitationAddedFreeSubscriptionNotifMsg": 
  "L'annonce a été ajoutée à votre agenda. L'organisateur a reçu une notification suite à votre inscription libre.",
  "invitationRemovedNotifMsg": "Invitation retirée",
  "invitationUpdatedToAcceptedNotifMsg": "Une notification d'acceptation a été envoyée au demandeur.",
  "invitationUpdatedToRefusedNotifMsg": "Une notification de refus a été envoyée au demandeur."
}

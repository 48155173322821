<template>
  <span class="alomigoBlue" :title="t('leftHomeName')">
 Alom<span>i</span>go
  </span>

</template>

<script lang="ts">
import { reactive, toRefs, ref, computed } from 'vue'
import { useI18n } from "@/engineproject/default/common/i18n";

export default {
  props: {
  },
  setup(props: any, context: any) {
    const {t} = useI18n()
    const page = reactive({
    });
    return {
      ...toRefs(page),
      t,
    }
  },
  components: {
  },
}


</script>

<style scoped lang="scss">
.alomigoBlue {
  color: #4472c4;

  span {
    color: #ffbf00;
  }
}
alomigoYellow {
}
</style>

<template>
  <div class="profileViewLink">
    <div >
      <span v-if="prefixTextDisplayedBeforeName && prefixTextDisplayedBeforeName !==''">
        {{ prefixTextDisplayedBeforeName }}
        <span>&nbsp;</span>
      </span> 
      <div v-if="appBoss === true /*&& displayAppBoss === true*/"
        class="logoContainer"
        @click.stop="viewUserProfile(userId)">
        <div class="logoProfileAppBoss logoProfile"  
        :title="t('appBossTitle')">
          <FormatUserPicture :pUserPictureUrl="firebaseStorage.getLongBucketStorageUrlForProfile(profilePictureUrl,
            userId)">
          </FormatUserPicture>
        </div>
      </div>
      <div v-else-if="appBoss === false /*&& displayWannaBeAppBoss === true*/"
        class="logoContainer"
        @click.stop="viewUserProfile(userId)">
        <div class="logoProfileAppBossGreenAndRedNOMOREUSED logoProfile"  
        :titleNOMOREUSED="t('userProfileWannaBeABossTitle')">
          <FormatUserPicture :pUserPictureUrl="firebaseStorage.getLongBucketStorageUrlForProfile(profilePictureUrl,
            userId)">
          </FormatUserPicture>
        </div>
      </div>
      <a @click.stop="viewUserProfile(userId)"
        :title="(userDisplayedDescription !== '') ? userDisplayedDescription : t('openProfile')"
        >{{ userDisplayedName }}
      </a>
    </div>
  </div>
</template>
<script lang="ts">
import { reactive, toRefs } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { firebaseStorage } from '@/engineproject/backend/firebase';
import { useAppStore } from '@/engineproject/store/appStore';

import FormatUserPicture from '@/engineproject/components/UIElements/FormatUserPicture.vue';

export default {
  props: {
    userDisplayedDescription: {
      type: String,
      required: false,
      default: "",
    },
    userDisplayedName: {
      type: String,
      required: true
    },
    prefixTextDisplayedBeforeName: {
      type: String,
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    appBoss: {
      type: Boolean,
      required: false,
      default: false
    },
    displayAppBoss: {
      type: Boolean,
      required: false,
      default: true
    },
    displayWannaBeAppBoss: {
      type: Boolean,
      required: false,
      default: true
    },
    profilePictureUrl: {
      type: String,
      required: false,
      default: ""
    },
  },
  setup(props: any, context: any) {
    const appStore = useAppStore();
    const {t} = useI18n()
    const page = reactive({
    });

    const viewUserProfile = (aUserId: string) => {
      appStore.commit("setInModalUserProfileId", {inModalUserProfileId: aUserId});
    }
    return {
      ...toRefs(page),
      t,
      firebaseStorage,
      viewUserProfile,
    }
  },
  components: {
    FormatUserPicture
  },
}
</script>

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "serverConfigState" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.appServerConfigIsOk === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : (_ctx.appServerConfigState !== undefined)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass('serverConfigState' + _ctx.appServerConfigState)
          }, _toDisplayString($setup.t('serverConfigState' + _ctx.appServerConfigState)), 3))
        : _createCommentVNode("", true)
  ]))
}
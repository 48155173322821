import firebase from "firebase/app";
import "firebase/storage";
import {firebaseProjectConfig} from "@/engineproject/default/common/envConfigs/firebaseProject"

class FirebaseStorage {
  // TODO Probably better in another class because it's also used for UserFibase
  putAnAttachedFile(fileAndPathName: string, file: any) {
    return firebase.storage().ref(fileAndPathName).put(file);
  }
  deleteAnAttachedFile(fileAndPathName: string) {
      return firebase.storage().ref(fileAndPathName).delete()
  }
  async getStorageUrl(storageURI: string)  {
    const  storage = firebase.storage();
    // Create a reference from a Google Cloud Storage URI
    const  gsReference = storage.refFromURL('gs://' + firebaseProjectConfig.storageBucket + storageURI); //'/countries/fr/postal-code-FR.csv');
    return await gsReference.getDownloadURL()
  }
  getShortBucketStorageUrl(longUrl: string): string {
    if (longUrl === undefined ||longUrl === "") {
      return ""
    }
    const storageBucketId =  firebaseProjectConfig.storageBucket + firebaseProjectConfig.storageBucketSuffix
    const index = longUrl.indexOf(storageBucketId)
    if (index != -1) {
      return longUrl.substr(index + storageBucketId.length)
    }
    return longUrl
  }

  getLongBucketStorageUrl(longOrShortUrl: string): string {
    if (longOrShortUrl === "") {
      return ""
    }
    const storageBucketId =  firebaseProjectConfig.storageBucket + firebaseProjectConfig.storageBucketSuffix
    const index = longOrShortUrl.indexOf(storageBucketId)
    if (index != -1) {
      return longOrShortUrl
    }
    return firebaseProjectConfig.storageBucketBaseUrl +  storageBucketId + longOrShortUrl
  }

  isDefaultProfileUrl(url: string) {
    const d = "d"
    const dWithttt = "d?ttt="
    if (url === d) {
      return ""
    }
    if (url !== undefined && url.startsWith(dWithttt) === true) {
      return "&" + url.substr(2)
    }
    return false
  }

  getLongBucketStorageUrlForProfile(longOrShortUrl: string, userId: string): string {
    const urlType = this.isDefaultProfileUrl(longOrShortUrl)
    // console.error("getLongBucketStorageUrlForProfile urlType " + urlType)
    let ret = ""
    if (urlType === false) {
      ret =  this.getLongBucketStorageUrl(longOrShortUrl)
    } else {
      ret = this.getDefaultLongBucketStorageUrlForProfile(userId, urlType)
    }
    // console.error("getLongBucketStorageUrlForProfile ret " + ret)
    return ret
  }

  // https://firebasestorage.googleapis.com/v0/b/alomigoid.appspot.com/o/UserDocs%2FFOPhDof2wueO3feOx5BAvz4xvme2?alt=media&token=33f99f9b-74f4-4fa5-9d95-1163a92ee7dd
  getDefaultLongBucketStorageUrlForProfile(userId: string, urlAndType: string) {
    return this.getLongBucketStorageUrl(this.getProfileImgPathAndFile("%2F", userId) + "?alt=media&token=" + userId + urlAndType)
  }

  getProfileImgPathAndFile(sep: string, userId: string) {
    return "UserDocs" + sep + userId + sep + "PPic"
  }
/*
  getDefaultPicturePathAndFile(sep: string, userId: string) {
    return "UserDocs" + sep + userId  + sep + "DefPic"
  }
*/
  getDefaultPicturePathOnly(sep: string, userId: string) {
    return "UserDocs" + sep + userId  + sep 
  }

  async updateTokenInFile(fileName: string, fileToken: string) {
    const gfoUpdateStorageFileToken = firebase.functions().httpsCallable("sf-gfoUpdateStorageFileToken");
    const myData = {
      queryParams: {
        fileName,
        fileToken
      }
    };
    try {
      const result = await gfoUpdateStorageFileToken(myData);
      return result.data;
    } catch (error) {
      // Getting the Error details.
      console.error("gfoUpdateStorageFileToken error " + JSON.stringify(error));
      return {
        backendActionDone: false,
        errorMsg: JSON.stringify(error)
      };
    }
  }
}

export default FirebaseStorage

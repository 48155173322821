<template>
  <div v-if="ADColInvitations !== undefined && ADColInvitations.length" data-cy="cyInvitationsLoaded">
    <div class="subtitle">{{ t("itemYourInvitationsStatus") }}</div>
    <div
      v-for="aInvitation in ADColInvitations"
      :key="aInvitation.AIId"
      class="invitations" >
      <div class="invitationElement">
        <div class="invitationElementTitleArea">
          <div>
            <span>{{ formatDate(aInvitation.AICreatedOn)  }}
            {{ displayStatus(aInvitation.AIStatus) }}</span>
          </div>
          <div  class="leftAlign">
            <MiniProfileViewLink :userMiniProfile="aInvitation.AIMUser"
              prefixTextId="openProfileButtonText"></MiniProfileViewLink>
            <div class="removeButtonArea">
              <a data-cy="cyRemoveMyInvitationAdDoc"
                @click="removeOneInvitationAdDoc(aAdDoc, aInvitation.AIId)">
              X
              </a> 
            </div>
          </div>
        </div>
        <div class="invitationComment" :title="t('itemInvitationCommentTitle')">
          <pre>{{ aInvitation.ATComment }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { inject } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";
import { useDocumStore } from '@/engineproject/store/documStore';
import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes';
import { useAppStore } from '@/engineproject/store/appStore';
import { useUserStore } from '@/engineproject/store/userStore';

import MiniProfileViewLink from '@/engineproject/components/UserProfile/MiniProfileViewLink.vue';

export default {
  props: {
    ADColInvitations: {
      type: Object as () => Array<any>,
      required: false,
    },
    aAdDoc: {
      type: Object as () => Array<any>,
      required: true,
    },
  },
  setup(props: any, context: any) {
    const formatDate = inject('formatDate') as any;
    const documStore = useDocumStore();
    const { t } = useI18n();
    const appStore = useAppStore();
    const userStore = useUserStore();

    const displayStatus = (aStatusValue: string) => {
      const readableStatus = t("invitationStatus_" + aStatusValue)
      return t("invitationStatusTitle", {theStatus : readableStatus})
    }

    const viewUserProfile = (aUserId: string) => {
      appStore.commit("setInModalUserProfileId", {inModalUserProfileId: aUserId});
    }

    const removeOneInvitationAdDoc = async (aAdDoc: AdDocumentProjectObject,
      aInvitationId: string) => {
      if (aAdDoc === undefined) {
        return;
      }
      await documStore.dispatch("removeOneInvitationAdDoc", {
        aAdDoc,
        appStore: appStore,
        aInvitationId,
      });
    };

    return {
      t,
      displayStatus,
      formatDate,
      removeOneInvitationAdDoc,
      viewUserProfile,
    };
  },
  components: { 
    MiniProfileViewLink,
  },
};
</script>
<style lang="scss" scoped>
</style>

<template>
  <div v-if="aAdDoc.OADData.ADColComments && aAdDoc.OADData.ADColComments.length > 0"  data-cy="cyCommentsLoaded">
    <div class="subtitle">{{ t("itemComments") }}</div>
    <div class="docComments">
      <div v-for="comment in aAdDoc.OADData.ADColComments" :key="comment.ACId" class="comment">
        <div class="commentHeader">
          <div>
            <a @click="viewUserProfile(comment.ACAUserId)"
              :title="t('openProfile')"
              >{{ comment.ACAPseudo }}</a>
          </div>
          <div> - {{ formatDate(comment.ACCreatedOn)  }}</div>
          <div v-if="canRemoveComment(comment.ACAUserId) === true" 
            @click="removeMyComment(comment.ACId)" class="removeComment" :title="t('removeCommentTitle')">X</div>
        </diV>
        <pre>{{ comment.ACContent }}</pre>
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="aAdDoc.getCommentsIsLoading() === true " data-cy="cyCommentsLoading">
      {{ t('loadingComments') }}
    </div>
    <div v-else  data-cy="cyCommentsLoaded">
    </div>
  </div>
</template>

<script lang="ts">
import { inject, reactive, toRefs } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";
import { AdDocumentProjectTypes } from '@/engineproject/default/common/domainModel/adDocumentProjectTypes';

import { useAppStore } from '@/engineproject/store/appStore';
import { useUserStore } from '@/engineproject/store/userStore';
import { useDocumStore } from '@/engineproject/store/documStore';

export default {
  props: {
    aAdDoc: {
      type: Object as () => AdDocumentProjectTypes.AdDocument,
      required: true,
    },
  },
  setup(props: any, context: any) {
    const formatDate = inject('formatDate') as any;
    const documeStore = useDocumStore()
    const userStore = useUserStore()
    const appStore = useAppStore()
    const {t} = useI18n()
    const page = reactive({
      aAdDoc: props.aAdDoc,
    });
    const viewUserProfile = (aUserId: string) => {
      appStore.commit("setInModalUserProfileId", {inModalUserProfileId: aUserId});
    }
    const canRemoveComment = (commentOwnerUserId: string) => {
      return userStore.state.aUserProfile.isOwnerOrAdmin(commentOwnerUserId)
    }
    const removeMyComment = async (commentId: string) => {
      await documeStore.dispatch('removeCommentFromAdDoc', {
        aAdDoc: props.aAdDoc,
        commentId,
        aUserId: userStore.state.aUserProfile.getUPUserId(),
        appStore: appStore
      })
    }
    return { 
      ...toRefs(page),
      t,
      formatDate,
      removeMyComment,
      canRemoveComment,
      viewUserProfile
     };
  },
};
</script>

<template>
  <span v-if="ADDateHourStartsOnNotRequiredAndNotDefined.isUndefined(convertedDate)">
      {{prefix}}
  </span>
  <span v-else-if="convertedDate && convertedDate.toLocaleDateString">
     {{prefix}}{{ convertedDate.toLocaleDateString('fr-FR',  { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}
          {{ convertedDate.toLocaleTimeString('fr-FR', {hour: '2-digit', minute:'2-digit'}) }}
  </span>
  <span v-else-if="convertedDate">
      {{prefix}}{{ convertedDate }}
  </span>
</template>
<script lang="ts">
import { computed, reactive, toRefs } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { ADDateHourStartsOnNotRequiredAndNotDefined } from '@/engineproject/domainModel/dateToolsTypes';

export default {
  props: {
    inputDate: {
      type: Object,
      required: false
    },
    prefix: {
      type: String,
      required: false
    },
  },
  setup(props: any, context: any) {
    const {t} = useI18n()
    const page = reactive({
      convertedDate: computed(() => {
        if (props.inputDate && props.inputDate.toDate) {
          return props.inputDate.toDate()
        } else if (props.inputDate && typeof props.inputDate === 'string') {
          return new Date(props.inputDate)
        } else {
          return props.inputDate
        }
      }),
    });
    return {
      ...toRefs(page),
      t,
      ADDateHourStartsOnNotRequiredAndNotDefined,
    }
  },
}

</script>
<style lang="scss" scoped>
.pi {
  font-size: 1rem;
}

.iconsContainer {
  display: flex;
}

.iconsContainer > a {
  margin: 20px;
}
</style>

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-cy": "cyInvitationsLoaded"
}
const _hoisted_2 = { class: "subtitle" }
const _hoisted_3 = { class: "invitationElement" }
const _hoisted_4 = { class: "invitationElementTitleArea" }
const _hoisted_5 = { class: "leftAlign" }
const _hoisted_6 = { class: "removeButtonArea" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MiniProfileViewLink = _resolveComponent("MiniProfileViewLink")!

  return ($props.ADColInvitations !== undefined && $props.ADColInvitations.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString($setup.t("itemYourInvitationsStatus")), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.ADColInvitations, (aInvitation) => {
          return (_openBlock(), _createElementBlock("div", {
            key: aInvitation.AIId,
            class: "invitations"
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createElementVNode("span", null, _toDisplayString($setup.formatDate(aInvitation.AICreatedOn)) + " " + _toDisplayString($setup.displayStatus(aInvitation.AIStatus)), 1)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_MiniProfileViewLink, {
                    userMiniProfile: aInvitation.AIMUser,
                    prefixTextId: "openProfileButtonText"
                  }, null, 8, ["userMiniProfile"]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("a", {
                      "data-cy": "cyRemoveMyInvitationAdDoc",
                      onClick: ($event: any) => ($setup.removeOneInvitationAdDoc($props.aAdDoc, aInvitation.AIId))
                    }, " X ", 8, _hoisted_7)
                  ])
                ])
              ]),
              _createElementVNode("div", {
                class: "invitationComment",
                title: $setup.t('itemInvitationCommentTitle')
              }, [
                _createElementVNode("pre", null, _toDisplayString(aInvitation.ATComment), 1)
              ], 8, _hoisted_8)
            ])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-735e779d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hiddenElement" }
const _hoisted_2 = {
  key: 0,
  "data-cy": "cyBlockedApplicationIndicatorTrue"
}
const _hoisted_3 = {
  key: 1,
  "data-cy": "cyBlockedApplicationIndicatorFalse"
}
const _hoisted_4 = {
  key: 1,
  style: {"position":"fixed","top":"0px","left":"0px","z-index":"500"}
}
const _hoisted_5 = {
  key: 0,
  class: "blockAppUIIndicatorTrue"
}
const _hoisted_6 = {
  key: 1,
  class: "blockAppUIIndicatorFalse"
}
const _hoisted_7 = { class: "blockedApplicationCounter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["blockAppUI", (_ctx.blockedApplication === true) ? 
    'blockedApplicationFromParentTrue' : 'blockedApplicationFromParentFalse']),
    style: {"borderDEBUG":"2px solid red"}
  }, [
    _createVNode(_component_BlockUI, {
      blocked: /*blockedApplication === true || */_ctx.blockedApplication === true,
      fullScreen: true,
      baseZIndex: 5000
    }, null, 8, ["blocked"]),
    (/*blockedApplication === true || */_ctx.blockedApplication === true)
      ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
          key: 0,
          class: "centered",
          style: {"width":"50px","height":"50px","z-index":"5000"},
          strokeWidth: "8",
          fill: "#EEEEEE",
          animationDuration: ".5s"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      ($setup.blockedApplicationCounter > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($setup.blockedApplicationCounter), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($setup.blockedApplicationCounter), 1))
    ]),
    (1 === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(_ctx.blockedApplication) + " " + _toDisplayString($setup.blockedApplicationCounter), 1),
          (_ctx.blockedApplication === true)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, "blockAppUIIndicatorTrue"))
            : _createCommentVNode("", true),
          (_ctx.blockedApplication !== true)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, "blockAppUIIndicatorFalse"))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, _toDisplayString($setup.blockedApplicationCounter), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
<template>
  <AdDocumentViewMainPartTemplate
    :displayInModal="false"
    :displayCommentsLikesOnly="false"
    :displayFromList="true"
    :indexDocInList="indexDocInList"
  >
    <template #content="{ aAdDoc, indexDocInList }">
      <div>
        <DisplayRemainingPlaces
            v-if="indexDocInList < 3 && aAdDoc.OADData.ADDisponibility < 5"
          >
            {{
              t(
                aAdDoc.OADData.ADDisponibility > 1
                  ? "itemRemainingPlacesTitle"
                  : "itemRemainingOnePlaceTitle",
                {
                  ramainingPlaces: aAdDoc.OADData.ADDisponibility,
                }
              )
            }}</DisplayRemainingPlaces
          >
        <h4>
          <FormatDate
            prefix="Le "
            :inputDate="aAdDoc.OADData.ADDateHourStartsOn"
          >
          </FormatDate>

          <span v-if="aAdDoc.isFromPremiumAccount() === true">
            {{ t("itemPremiumTitle") }}
          </span>
        </h4>
        <h4>{{ aAdDoc.OADData.ADTitle }}</h4>
        <div
          v-if="aAdDoc.OADData.ADColAttDoc && aAdDoc.OADData.ADColAttDoc.length"
        >
          <ImagesView
            :listOfImages="aAdDoc.OADData.ADColAttDoc"
            :firstImageOnly="true"
          ></ImagesView>
        </div>
        <div v-else>
          <DefaultImagesView :aAdDoc="aAdDoc"></DefaultImagesView>
        </div>
        <p v-if="toggleViewDetails === true">
          {{ aAdDoc.OADData.ADDescription }}
        </p>
        <p v-if="toggleViewDetails === true && aAdDoc.OADData.ADDisponibility">
          {{ t("adDocDetailPlacesTitle") }} {{ aAdDoc.OADData.ADDisponibility }}
        </p>
        <FormatPrice
          :inputPrice="aAdDoc.OADData.ADYourPrice"
          :inputPaidByOwner="aAdDoc.OADData.ADPercentagePaidByOwner"
          :inputPriceLabel="
            aAdDoc.isFromPremiumAccount() === true
              ? 'adDocPremiumPrice'
              : 'adDocNormalPrice'
          "
        ></FormatPrice>
        <FormatDate
          v-if="toggleViewDetails === true"
          prefix="Termine le: "
          :inputDate="aAdDoc.OADData.ADDateHourEndsOn"
        ></FormatDate>
        <div v-if="aAdDoc.OADData.ADLocation">
          <GeolocationView
            :aLocation="aAdDoc.OADData.ADLocation"
          ></GeolocationView>
        </div>
        <AdDocViewMyTransactionInAdDocList :aAdDoc="aAdDoc">
        </AdDocViewMyTransactionInAdDocList>
        <div v-if="toggleViewDetails === true">
          <OwnerProfileViewLink
            :ownerMiniProfile="aAdDoc.OADData.ADOwnerMiniProfile"
            :displayWannaBeAppBoss="false"
          ></OwnerProfileViewLink>
        </div>
        <div v-if="toggleViewDetails === true">
          <EnterpriseDisplay
          :displayNoEnterprise="false"
          :aEnterprise="aAdDoc.OADData.ADEnterprise"
          ></EnterpriseDisplay>
        </div>
        <div v-if="toggleViewDetails === true">
          {{ t("itemPublishedOn") }}
          {{ formatDate(aAdDoc.OADData.ADCreatedOn) }}
        </div>
        <CommentsLikes
          v-if="toggleViewDetails === true"
          :aAdDoc="aAdDoc"
          dataCYClass="InListView"
        ></CommentsLikes>
      </div>
    </template>
  </AdDocumentViewMainPartTemplate>
</template>

<script lang="ts">
import { reactive, toRefs, inject } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import ImagesView from "@/engineproject/components/FilesAndImages/ImagesView.vue";
import DefaultImagesView from "@/engineproject/components/FilesAndImages/DefaultImagesView.vue";
import AdDocumentViewMainPartTemplate from "@/engineproject/components/AdDocument/AdDocumentViewMainPartTemplate.vue";
import AdDocViewMyTransactionInAdDocList from "@/engineproject/components/AdDocTransaction/AdDocViewMyTransactionInAdDocList.vue";
import GeolocationView from "@/engineproject/components/Geolocation/GeolocationView.vue";
import FormatDate from "@/engineproject/components/UIElements/FormatDate.vue";
import FormatPrice from "@/engineproject/components/UIElements/FormatPrice.vue";
import OwnerProfileViewLink from "@/engineproject/components/UserProfile/OwnerProfileViewLink.vue";
import CommentsLikes from "@/engineproject/components/AdDocument/CommentsLikes.vue";
import DisplayRemainingPlaces from "@/engineproject/components/AdDocList/DisplayRemainingPlaces.vue";
import EnterpriseDisplay from "@/engineproject/components/Enterprise/EnterpriseDisplay.vue";

export default {
  props: {
    indexDocInList: {
      type: Number,
      required: false,
    },
  },
  setup(props: any, context: any) {
    const formatDate = inject("formatDate") as any;
    const { t } = useI18n();
    const page = reactive({
      toggleViewDetails: false,
    });
    return { ...toRefs(page), formatDate, t };
  },
  components: {
    ImagesView,
    DefaultImagesView,
    AdDocumentViewMainPartTemplate,
    AdDocViewMyTransactionInAdDocList,
    GeolocationView,
    FormatDate,
    FormatPrice,
    OwnerProfileViewLink,
    CommentsLikes,
    DisplayRemainingPlaces,
    EnterpriseDisplay,
  },
};
</script>

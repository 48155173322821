import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "inlineSeparatedBlockContainer adminSuperPower" }
const _hoisted_2 = {
  key: 0,
  class: ""
}
const _hoisted_3 = ["selected", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loggedUserProfile.isAdmin() === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, _toDisplayString($setup.t('enterpriseExternTitle')), 1),
          _createElementVNode("select", {
            onChange: _cache[0] || (_cache[0] = ($event: any) => ($setup.updateEnterprise($event)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrayOfEnterpriseInfo, (aEnterpriseInfo, index) => {
              return (_openBlock(), _createElementBlock("option", {
                key: index,
                selected: $props.aUserProfile.sameEnterprise(aEnterpriseInfo),
                value: index
              }, _toDisplayString(aEnterpriseInfo.getEIName()) + " - " + _toDisplayString(aEnterpriseInfo.getEISearchId()), 9, _hoisted_3))
            }), 128))
          ], 32)
        ]))
      : _createCommentVNode("", true)
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-modal" }
const _hoisted_2 = { class: "c-container" }
const _hoisted_3 = { class: "headerLinks" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
          class: "closeComment"
        }, _toDisplayString($setup.t("closeAdDocument")), 1)
      ]),
      _createElementVNode("p", null, _toDisplayString($setup.t("addAComment")), 1),
      _createElementVNode("form", {
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _withDirectives(_createElementVNode("textarea", {
          "data-cy": "cyCommentTextArea",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.comment) = $event))
        }, null, 512), [
          [
            _vModelText,
            _ctx.comment,
            void 0,
            { trim: true }
          ]
        ]),
        _createElementVNode("button", {
          "data-cy": "cyAddCommentButton",
          onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.addComment())),
          disabled: _ctx.comment == '',
          class: "button"
        }, _toDisplayString($setup.t("addComment")), 9, _hoisted_4)
      ], 32)
    ])
  ]))
}
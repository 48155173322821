import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  id: "dashboard",
  class: "dashboardArea"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLoading = _resolveComponent("AppLoading")!
  const _component_DashboardSearchAndListView = _resolveComponent("DashboardSearchAndListView")!

  return (_ctx.aUserProfile.isCurrentLoggedUser() !== true)
    ? (_openBlock(), _createBlock(_component_AppLoading, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_DashboardSearchAndListView, { adDocFileType: "" })
      ]))
}
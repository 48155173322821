{
  "leftHomeName": "The Engine Project",
  "leftHomeDescription": "The easiest framework to develop vue3.js application connected to Google Cloud",
  "serverConfigStateWarning":"Attention l'application va temporairement passée en maintenance ... Enregistrez votre travail si nécessaire ...",
  "serverConfigStateMaintenance":"L'application est temporairement en maintenance ... Reconnectez vous plus tard!",
  "home": "Home",
  "adsListing": "Buy",
  "adsListingTitle": "Buy a service",
  "addItem": "Sell",
  "addItemTitle": "Sell a service",
  "settings": "Settings",
  "settingsMainTitle": "Paramètres dans Engine Project",
  "closingMainTitle": "Clôture de votre compte",
  "closingAboStatusTitle": "Statut de votre abonnement",
  "closingAdDocsStatusTitle": "Statut de vos documents",
  "closingTransactionsStatusTitle": "Statut de vos participations",
  "settingsTitle": "Update your profile data",
  "myProfile": "My Profile",
  "myNotifications": "Notifications",
  "myNotificationsTitle": "Display your notifications",
  "invitationWithGainReceived": "Vous avez {invitationsNumber} invitation(s) avec réduction non lue(s)",
  "refreshToSeeNewNotifs": "Click to refresh",
  "myAds": "My Ads",
  "myCalendar": "My agenda",
  "myPromotionUsingQR": "Ma communication",
  "myContributions": "Mes contributions",
  "adminViewProfiles": "Gérer les profils",
  "adminViewEnterprises": "Gérer les entreprises",
  "adminViewMySuggestions": "Mes suggestions (publiées ou non publiées)",
  "adminViewAllSuggestions": "Toutes les suggestions (publiées)",
  "adminAddSuggestions": "Ajouter une suggestion",
  "premiumViewMyModels": "Mes modèles ",
  "premiumViewAllPublicModels": "Les idées de modèles",
  "premiumAddModels": "Ajouter un modèle",
  "adminViewOther": "Autres fonctions",
  "adminInfo": "A propos Admin",
  "about": "About",
  "mentionsLegales": "Mentions Légales",
  "logout": "Logout",
  "logoutTitle": "Logout from your account",  
  "newAppVersionMenu": "Installer",
  "adminMode": "Admin",
  "newAppVersionTitle": "Installer la nouvelle version de l'application",
  "newAppVersionNotif": "Nouvelle version de l'application disponible, cliquez dans le menu sur 'Installer'",
  "newAppVersionNotifLink": "Cliquez sur 'Installer'",
  "newUpdateFoundInfoMsg": "Nouvelle version de l'application en cours de téléchargement ...'",
  "switchAdminModeDescription": "Attention en mode Admin vous pouvez supprimer/modifier les données des autres utilisateurs!"

}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "noResults",
  "data-cy": "cyDocumentNotFoundInSearchList"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdDocListNavCallToAction = _resolveComponent("AdDocListNavCallToAction")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString($setup.t("noResults")), 1),
    _createElementVNode("div", null, [
      _createVNode(_component_AdDocListNavCallToAction, { adDocFileType: $props.adDocFileType }, null, 8, ["adDocFileType"])
    ])
  ], 64))
}
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "listNavBottomMenu"
}
const _hoisted_2 = ["title"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdDocListNavCallToAction = _resolveComponent("AdDocListNavCallToAction")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_ctx.moreElementsInCollection === true)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createElementBlock("a", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('nextSubList'))),
          title: $setup.t('moreLinkTitle')
        }, [
          _createTextVNode(_toDisplayString($setup.t("moreLink")), 1)
        ], 8, _hoisted_2)), [
          [_directive_observe_visibility, $setup.visibilityChanged]
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_component_AdDocListNavCallToAction, { adDocFileType: $props.adDocFileType }, null, 8, ["adDocFileType"])
      ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pModal" }
const _hoisted_2 = { class: "pContainer" }
const _hoisted_3 = { class: "headerLinks" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserProfileViewContent = _resolveComponent("UserProfileViewContent")!
  const _component_UserProfileViewModalTemplate = _resolveComponent("UserProfileViewModalTemplate")!

  return (_openBlock(), _createBlock(_component_UserProfileViewModalTemplate, null, {
    content: _withCtx(({aUserProfile}) => [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("a", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
                  class: "closeUserProfile"
                }, _toDisplayString($setup.t("closeAdDocument")), 1)
              ]),
              _createVNode(_component_UserProfileViewContent, {
                "onRefresh:display": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
                aUserProfile: aUserProfile
              }, null, 8, ["aUserProfile"])
            ])
          ])
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }))
}
import { AdDocAPIClient } from "../../backend/AdDocFirebase";
import { AdDocInvitationsAPIClient } from "../../backend/AdDocInvitationsFirebase";
import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes';
import { UserProjectTypes } from "@/engineproject/default/common/domainModel/userProjectTypes";


/*
Domain Model, and only Domain Model:
- no vue3.js object or code or concept
- just domain objects AdDocumentProjectObject, UserProjectTypes.UserProfile, ...
- Dependency Inversion Principle (DIP) regarding the lower backend modules (xxxAPIClient)

Design issues:
- some APIClient methods, return a low level object (Firebase Document) in order to
  improve the performances. This Firebase Object is just pass back to the store, and the store
  will give it back.
  => solution : implement a cache in the low level code using the Doc Id. The Firebase Document is not
  returned but moved in the library cache. When the action can use the cached Firebase Doc, the
  low level code should be able to do it!
*/
export class AdDocumentInvitationsControler  {
  adDocBackendAPI?: AdDocAPIClient;
  adDocInvitationsBackendAPI?: AdDocInvitationsAPIClient;
  setAPI(aDocAPI: AdDocAPIClient, aDocInvitationsBackendAPI: AdDocInvitationsAPIClient) {
    this.adDocBackendAPI = aDocAPI
    this.adDocInvitationsBackendAPI = aDocInvitationsBackendAPI
  }


  async ownerAddInvitationAdDoc(aAdDoc: AdDocumentProjectObject,
    aUserMiniProfile: UserProjectTypes.UserMiniProfile,
    addInvitationText: string) {

    return await this.adDocInvitationsBackendAPI?.ownerAddInvitationAdDoc(aAdDoc, aUserMiniProfile, addInvitationText)

  }

  async removeOneInvitationAdDoc(aAdDoc: AdDocumentProjectObject,
    aInvitationId: string) {

    return await this.adDocInvitationsBackendAPI?.removeOneInvitationAdDoc(aAdDoc, aInvitationId)

  }


  async getAllInvitationsForAdDoc(options: any) {

    return await this.adDocInvitationsBackendAPI?.getAllInvitationsForAdDoc(options)

  }

}



export const adDocumentInvitationsControler = new AdDocumentInvitationsControler()


<template>
  <div v-if="myContactUserProfile.getUPPseudo() !== loggedUserProfile.getUPPseudo()" class="activeTransactionsWithMe">
    <div v-if="adDocsFromProfileWithOneOfMyActiveTransaction && adDocsFromProfileWithOneOfMyActiveTransaction.length > 0">
      <h5>{{t('titleDocCreatedByPseudo', {aPseudo: myContactUserProfile.getUPPseudo()}) }}
      </h5>
      <li class="documentInList" v-for="adDocsListInfo in adDocsFromProfileWithOneOfMyActiveTransaction" 
        :key="adDocsListInfo.adDocIdInList">
        <router-link  :to="'/a/' + adDocsListInfo.adDocIdInList">
          <span  @click="closeAllModals()">{{adDocsListInfo.adDocTitleInList}}</span>
        </router-link>
      </li>
    </div>
    <div v-if="myAdDocsWithOneActiveTransactionFromProfile && myAdDocsWithOneActiveTransactionFromProfile.length > 0">
      <h5>{{t('titleDocCreatedByPseudo', {aPseudo: myContactUserProfile.getUPPseudo()}) }}
      </h5>
      <li class="documentInList" v-for="adDocsListInfo in myAdDocsWithOneActiveTransactionFromProfile" :key="adDocsListInfo.adDocIdInList">
        <router-link  :to="'/a/' + adDocsListInfo.adDocIdInList">
          <span  @click="closeAllModals()">{{adDocsListInfo.adDocTitleInList}}</span>
        </router-link>
      </li>
    </div>
    <div v-if="adDocsFromProfileWithOneOfMyActiveTransaction && adDocsFromProfileWithOneOfMyActiveTransaction.length === 0 &&
        myAdDocsWithOneActiveTransactionFromProfile && myAdDocsWithOneActiveTransactionFromProfile.length === 0">
        <h4>{{t('titleNoTransaction')}}
        </h4>
        <div v-if="getContactFromContactsArray !== undefined && getContactFromContactsArray !== false">
          <div>
          {{ t('removeThePerson')}}
          </div>
          <div>
          <Button :title="t('removeThePersonButtonTitle')"
            @click="removeContact()">{{t('removeThePersonButton')}}</Button>
          </div>
      </div>
    </div>

  </div>
</template>
<script lang="ts">
import { computed, onMounted, reactive, toRefs } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { UserProfileProjectObject } from '@/engineproject/default/common/domainModel/userProfileProjectToolsTypes';
import { userStore } from '@/engineproject/store/userStore';
import { useAppStore } from '@/engineproject/store/appStore';

import Button from 'primevue/button';

export default {
  props: {
    myContactUserProfile: {
      type: Object as () => UserProfileProjectObject,
      required: true
    },
  },
  emits: ["refresh:display"],
  setup(props: any, context: any) {
    const {t} = useI18n()
    const appStore = useAppStore();
    const page = reactive({
      loggedUserProfile: computed(() => userStore.state.aUserProfile),
      getContactFromContactsArray: computed(() => 
        userStore.state.aUserProfile.getContactFromContactsArray(props.myContactUserProfile.getUPUserId())),
      adDocsFromProfileWithOneOfMyActiveTransaction: computed(() => 
        userStore.state.adDocsFromProfileWithOneOfMyActiveTransaction[props.myContactUserProfile.getUPUserId()]),
      myAdDocsWithOneActiveTransactionFromProfile: computed(() => 
        userStore.state.myAdDocsWithOneActiveTransactionFromProfile[props.myContactUserProfile.getUPUserId()]),
    });
    const removeContact = async () => {
      await userStore.dispatch("removeMyContact", {
        appStore,
        myContactUserProfile: props.myContactUserProfile}) 
      context.emit("refresh:display")
    }
    const closeAllModals = () => {
      appStore.dispatch("closeAllModal");
    }
    onMounted(async () => {
      userStore.dispatch("getActiveTransactionsWithAContact", {
        appStore,
        myContactUserProfile: props.myContactUserProfile}) 
    })
    return {
      ...toRefs(page),
      t,
      removeContact,
      closeAllModals,
    }
  },
  components: {
    Button, 
  },
}


</script>

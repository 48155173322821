import * as fb from './firebase'

import {UserProjectTypes} from  "@/engineproject/default/common/domainModel/userProjectTypes"
import {UserTypes} from  "@/engineproject/domainModel/userTypes"
import firebase from 'firebase';
import { firebaseStorage, firebaseAnalytics } from './firebase';
import { fullLocale } from '@/engineproject/i18nApp';
import { UserProfileProjectObject } from '@/engineproject/default/common/domainModel/userProfileProjectToolsTypes';
import { geohashQueryBounds,  distanceBetween} from "geofire-common";
import { EnterpriseInfoTypes } from '@/engineproject/domainModel/enterpriseInfoTypes';
import { EnterpriseInfoProjectObject } from '@/engineproject/default/common/domainModel/enterpriseInfoProjectToolsTypes';

const defaultProfile = {
  UPUserId: "",
  UPName: "",
  UPPseudo: "",
  UPDescription: "",
  UPPictureUrl: "",
  UPIsAdmin: false,
  UPLocation: undefined as UserProjectTypes.LocationPoint | undefined
} as UserProjectTypes.UserProfile


interface SignupData {
  email: string;
  password: string;
  name: string;
  pseudo: string;
  description: string;
  profileUrl: string;
  acceptCGU: boolean;
}

export interface UserAPIClient {
  sendPasswordResetEmail(anEmail: string): Promise<any>;
  cancelUserSubscription(options: { aUserId: string; aSubscriptionId: string }): Promise<any>;
  updateProfile(user: UserProfileProjectObject, updateEmail: any): Promise<any>;
  updateInvitationStatusInProfile(aUserId: string, invitationSent: boolean): Promise<any>;
  updateProfilePlan(aUserId: string, subscriptionId: string, planId: string): Promise<any>;
  sendEmailValidationLinkToUser(): Promise<any>;
  validationLinkFromUser(linkHash: string): Promise<any>;
  doClosingAccount(options: { trRequesterUserId: string }): { backendActionDone: boolean; errorMsg: string | undefined } | 
    PromiseLike<{ backendActionDone: boolean; errorMsg: string | undefined }>;
  removeMyContact(options: { contactUserId: string; requesterUserId: string }): Promise<any>;
  getMyUserProfile(userId: string, userPrivateProfileDocObserverReceived: any, userPublicProfileDocObserverReceived: any): Promise<any>;
  updateLastModelUsedInProfile( aUserId: string, lastModelsUsed: Array<UserTypes.MiniAdDocModel>): Promise<any>;
  cancelCalendarEvent(queryParams: {  aUserCalendarEvent: UserTypes.UserCalendarEvent}): Promise<any>;
  addCalendarEvent(queryParams: {  aUserCalendarEvent: UserTypes.UserCalendarEvent}): Promise<any>;
  updateCalendarEvent(queryParams: {  aUserCalendarEvent: UserTypes.UserCalendarEvent}): Promise<any>;
  addNotificationsList(queryParams: {  aUserNotification: any;
    userIdWithPendingOrAcceptedTransactions: Array<string>; importantChangesCounter: number;}): Promise<any>;
}

class UserFirebase implements UserAPIClient {
  
  unsubscribeCB = undefined as any;
  lastSentToken = "";

  async updateInvitationStatusInProfile(aUserId: string, invitationSent: boolean) {
    console.error("updateInvitationStatusInProfile")
    const isC = this.isUserOK(aUserId)
    if (isC.userId === ""){
      return {
        errorMsg: "Invalid userId",
        backendActionDone: false,
      }
    }

    return fb.usersPrivateCollection.doc(aUserId).update({
      UPLastInvitationRequest: new Date(),
      UPInvitationsSentDuringLastInvitationRequest: (invitationSent === true) ? 1 : 0,
    }).then(async () => {
      return {
        backendActionDone: true,
        errorMsg: undefined,
      }
    }, (reason) => {
      return {
        backendActionDone: false,
        errorMsg: "Error in updateInvitationStatusInProfile  " + reason,
      }
    })
  } 
  
  async updateLastModelUsedInProfile(aUserId: string, lastModelsUsed: Array<UserTypes.MiniAdDocModel>) {
    //console.error("updateLastModelUsedInProfile")
    const isC = this.isUserOK(aUserId)
    if (isC.userId === ""){
      return {
        errorMsg: "Invalid userId",
        backendActionDone: false,
      }
    }
    return fb.usersPrivateCollection.doc(aUserId).update({
      UPLastModelsUsed: lastModelsUsed,
    }).then(async () => {
      return {
        backendActionDone: true,
        errorMsg: undefined,
      }
    }, (reason) => {
      return {
        backendActionDone: false,
        errorMsg: "Error in updateLastModelUsedInProfile  " + reason,
      }
    })
  } 

  isConnected() {
    if (fb.auth === null || fb.auth.currentUser === null) {
      return {
        errorMsg: "Not connected",
        userId: ""
      }
    }
    firebaseAnalytics.logEvent('isConnected-' + fb.auth.currentUser.uid, {
      AEDUid: fb.auth.currentUser.uid
    });
    return  {
      errorMsg: "",
      userId: fb.auth.currentUser.uid
    }
  }

  isUserOK(userId: string) {
    const isC = this.isConnected()
    if (isC !== undefined && isC.userId === ""){
      return {
        ...isC,
        userId: ""
      }
    }
    if (userId != fb!.auth!.currentUser!.uid) {
      return {
        errorMsg: "invalid userid in updateProfile",
        userId: ""
      }
    }
    return {
      errorMsg: "",
      userId: fb!.auth!.currentUser!.uid
    }
  }
  returnAuthCatchError(error: any) {
    console.log(error);
    const errorMessage = error.code.replace("/","-"); //error.message;
    /*if (errorMessage === "auth/weak-password") {
      //alert("The password is too weak.");
      return {
        backendActionDone: false,
        errorMsg: "The password is too weak.",
        userId: undefined
      }
    } else {*/
      //alert(errorMessage);
      return {
        backendActionDone: false,
        errorMsg: errorMessage,
        userId: undefined
      }
    //}
  }

  async setHasBeenViewed(notifId: string, hasBeenViewed: boolean, 
    aUserId: string) {
    //  userProfile: UserProjectTypes.UserProfile) {
        try {
      console.error("setHasBeenViewed")
      if (fb.auth === null || fb.auth.currentUser === null ||
        fb.auth.currentUser.uid !== aUserId) {
        //  fb.auth.currentUser.uid !== userProfile.UPUserId) {
        console.error("setHasBeenViewed 11")
        return {
          backendActionDone: false,
          updatedNotificationId: undefined,
          errorMsg: "Not connected or invalid userids ..."
        }
      }
      const userId = fb.auth.currentUser.uid

      const notificationsCol = await fb.usersPrivateCollection.doc(userId).collection('UPColNotifications')
      const notificationDoc = notificationsCol.doc(notifId)
      const notificationDocs = await notificationDoc.get()
      if (notificationDocs.exists) {
        console.error("setHasBeenViewed 22")
        return await notificationDoc.update({
          UNHasBeenViewed: hasBeenViewed
        }).then(async () => {
          console.log("Notification successfully updated!");
          return {
            backendActionDone: true,
            updatedNotificationId: notifId,
            errorMsg: undefined
          }
          }).catch((error) => {
          console.error("Error removing notification: " + error);
          console.dir(error)
          //  + error.message + error.code
          return {
            backendActionDone: false,
            updatedNotificationId: undefined,
            errorMsg: "Error removing notificationin  document: " + error.message,
          }
        });
        } else {
        console.error("setHasBeenViewed 33")
        return {
          backendActionDone: false,
          updatedNotificationId: undefined,
          errorMsg: "Notification doesn't exist, so it cannot be removed"
        }
      }

    } catch (e) {
      console.error("error setHasBeenViewed ")
      console.error("error setHasBeenViewed " + e)
      return {
        backendActionDone: false,
        errorMsg: e + ""
      }
    }
  }
  async removeNotification(notifId: string, 
    aUserId: string) {
    //  userProfile: UserProjectTypes.UserProfile) {
    try {
      // console.error("removeNotification")
      if (fb.auth === null || fb.auth.currentUser === null ||
        fb.auth.currentUser.uid !== aUserId) {
        //  fb.auth.currentUser.uid !== userProfile.UPUserId) {
        console.error("removeNotification 11")
        return {
          backendActionDone: false,
          removedNotificationId: undefined,
          errorMsg: "Not connected or invalid userids ..."
        }
      }
      const userId = fb.auth.currentUser.uid

      const notificationsCol = await fb.usersPrivateCollection.doc(userId).collection('UPColNotifications')
      const notificationDoc = notificationsCol.doc(notifId)
      const notificationDocs = await notificationDoc.get()
      if (notificationDocs.exists) {
        // console.error("removeNotification 22")
        return await notificationDoc.delete().then(async () => {
          // console.log("Notification successfully deleted!");
          return {
            backendActionDone: true,
            removedNotificationId: notifId,
            errorMsg: undefined
          }
          }).catch((error) => {
          console.error("Error removing notification: " + error);
          console.dir(error)
          //  + error.message + error.code
          return {
            backendActionDone: false,
            removedNotificationId: undefined,
            errorMsg: "Error removing notificationin  document: " + error.message,
          }
        });
        } else {
        console.error("removeNotification 33")
        return {
          backendActionDone: false,
          removedNotificationId: undefined,
          errorMsg: "Notification doesn't exist, so it cannot be removed"
        }
      }

    } catch (e) {
      console.error("error removeNotification ")
      console.error("error removeNotification " + e)
      return {
        backendActionDone: false,
        errorMsg: e + ""
      }
    }
  }
  async refreshMyCalendarEventsArray() {
    // console.error("refreshMyCalendarEventsArray" )
    
    const res = Array<UserTypes.UserCalendarEvent>();
    try {
      const isC = this.isConnected()
      if (isC !== undefined && isC.userId === ""){
        return {
          ...isC,
          backendActionDone: false,
          calendarEventsData: undefined
        }
      }
      const userId = isC.userId 
      const docs = await fb.usersPrivateCollection.doc(userId).collection('UPColCalendarEvents').orderBy('UEDateHourStartsOn', 'asc').get()
      // console.error("refreshMyCalendarEventsArray " + docs)
      docs.forEach((doc: any) => {
        const notif = doc.data()
        notif.UECalendarEventId = doc.id
        notif.UEDateHourStartsOn = notif.UEDateHourStartsOn.toDate()
        res.push(notif as UserTypes.UserCalendarEvent)
      })
      // console.error("refreshMyCalendarEventsArray res " + res.length)
      await fb.usersPrivateCollection.doc(userId).update({
        UPEventNotViewed: 0,
      });
      return {
        backendActionDone: true,
        errorMsg: undefined,
        calendarEventsData: {
          UPUserId: userId,
          UPColCalendarEvents: res
        }
      }
    } catch (e) {
      console.error("error refreshMyCalendarEventsArray ")
      console.error("error refreshMyCalendarEventsArray " + e)
      return {
        backendActionDone: false,
        errorMsg: e + "",
        calendarEventsData: undefined
      }
    }
  }
  async refreshMyNotifsArray() {
    // console.error("refreshMyNotifsArray" )
    
    const res = Array<UserTypes.UserNotification>();
    try {
      const isC = this.isConnected()
      if (isC !== undefined && isC.userId === ""){
        return {
          ...isC,
          backendActionDone: false,
          notifData: undefined
        }
      }
      const userId = isC.userId 
      const docs = await fb.usersPrivateCollection.doc(userId).collection('UPColNotifications').orderBy('UNCreatedOn', 'desc').get()
      // console.error("refreshMyNotifsArray " + docs)
      docs.forEach((doc: any) => {
        const notif: UserTypes.UserNotification = doc.data()
        notif.UNNotificationId = doc.id
        res.push(notif)
      })
      // console.error("refreshMyNotifsArray res " + res.length)
      await fb.usersPrivateCollection.doc(userId).update({
        UPNotifNotViewed: 0,
        UPInvitWithGainNotViewed: 0,
      });
      return {
        backendActionDone: true,
        errorMsg: undefined,
        notifData: {
          UPUserId: userId,
          UPColNotifications: res
        }
      }
    } catch (e) {
      console.error("error refreshMyNotifsArray ")
      console.error("error refreshMyNotifsArray " + e)
      return {
        backendActionDone: false,
        errorMsg: e + "",
        notifData: undefined
      }
    }
  }
  async setUPI18N() {
    console.error("setUPI18N" )
    
    try {
      const isC = this.isConnected()
      if (isC !== undefined && isC.userId === ""){
        return {
          ...isC,
          backendActionDone: false,
        }
      }
      const userId = isC.userId 
      await fb.usersPrivateCollection.doc(userId).update({
        UPI18N: fullLocale
      });
      return {
        backendActionDone: true,
        errorMsg: undefined,
      }
    } catch (e) {
      console.error("error refreshMyNotifsArray ")
      console.error("error refreshMyNotifsArray " + e)
      return {
        backendActionDone: false,
        errorMsg: e + "",
      }
    }
  }

  async getAdminUserProfile(userId: string, userProfileObj: UserProjectTypes.UserProfile) {
    
    try {
      const adminUserProfileDoc = await fb.adminUsersCollection.doc(userId)
      const adminUserProfile = await adminUserProfileDoc.get()
      if (adminUserProfile === undefined || adminUserProfile.data() === undefined) {
        return {
          backendActionDone: false,
          errorMsg: "is not an admin",
          userProfileObj: undefined,
        }
      } else {
        userProfileObj = {
          ...userProfileObj,
          UPIsAdmin: true
        }
      }
      return {
        backendActionDone: true,
        errorMsg: undefined,
        userProfileObj: userProfileObj,
      }
    } catch(e) {
      console.error(e)
      return {
        backendActionDone: false,
        errorMsg: ""+e,
        userProfileObj: undefined,
      }
    }
  }

  cleanupUserPrivateProfileSnapshotFromBackend(user: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>): any{
    const aUserPrivateProfileFirebase = user.data()
    if (aUserPrivateProfileFirebase) {
     // aProfileFirebase.ADId = doc.id
      return this.cleanupUserPrivateProfileFromBackend(aUserPrivateProfileFirebase)
    }
    return undefined
  }

  cleanupUserPrivateProfileFromBackend(user: firebase.firestore.DocumentData): any {
    //const aAdDoc = doc.data()
    const aUserPrivateProfileFirebase = user
    //aAdDoc!.ADId = doc.id
    if (aUserPrivateProfileFirebase && aUserPrivateProfileFirebase.UPSubscriptionCreatedOn !== undefined &&
        aUserPrivateProfileFirebase.UPSubscriptionCreatedOn.seconds !== undefined ) {
      aUserPrivateProfileFirebase.UPSubscriptionCreatedOn = aUserPrivateProfileFirebase.UPSubscriptionCreatedOn.toDate()
    }
    if (aUserPrivateProfileFirebase && aUserPrivateProfileFirebase.UPSubscriptionEndedOn !== undefined &&
      aUserPrivateProfileFirebase.UPSubscriptionEndedOn.seconds !== undefined ) {
      aUserPrivateProfileFirebase.UPSubscriptionEndedOn = aUserPrivateProfileFirebase.UPSubscriptionEndedOn.toDate()
    }
    if (aUserPrivateProfileFirebase && aUserPrivateProfileFirebase.UPLastInvitationRequest !== undefined &&
      aUserPrivateProfileFirebase.UPLastInvitationRequest.seconds !== undefined ) {
      aUserPrivateProfileFirebase.UPLastInvitationRequest = aUserPrivateProfileFirebase.UPLastInvitationRequest.toDate()
    }
    return aUserPrivateProfileFirebase
  }
  async getUserPrivateProfile(userId: string, userProfileObj: UserProjectTypes.UserProfile, 
    userPrivateProfileDocObserverReceived: any,
    userPublicProfileDocObserver: any) {
    if (fb.auth == null || fb.auth.currentUser == null) {
      return {
        backendActionDone: false,
        errorMsg: "notConnected",
        userProfileObj: undefined,
        userPrivateProfileDocObserver: undefined,
        userPublicProfileDocObserver: undefined
      }
    }
    // console.error("getUserPrivateProfile fb.auth.currentUser " + fb.auth.currentUser!.uid)
    
    try {
      const userPrivateProfileDoc = fb.usersPrivateCollection.doc(userId)
      const userPrivateProfile = await userPrivateProfileDoc.get()
      if (userPrivateProfile === undefined || userPrivateProfile.data() === undefined) {
        const errorMsg = "cannotAccessToPrivateUserData"
        console.error("getUserPrivateProfile " + errorMsg)
        console.error("getUserPrivateProfile " + userId)
        return {
          backendActionDone: false,
          errorMsg,
          userProfileObj: undefined,
          userPrivateProfileDocObserver: undefined,
          userPublicProfileDocObserver: undefined
        }
      } else {
        const aUserPrivateProfileFBD = this.cleanupUserPrivateProfileSnapshotFromBackend(userPrivateProfile)

        userProfileObj = {
          ...userProfileObj,
          ...aUserPrivateProfileFBD
          //...userPrivateProfile.data()
        }
      }
      if (userProfileObj.UPI18N === undefined) {
        this.setUPI18N()
      }
      let userPrivateProfileDocObserver = undefined
      if (userPrivateProfileDocObserverReceived) {
        userPrivateProfileDocObserver = userPrivateProfileDoc.onSnapshot(docSnapshot => {
          // console.error(`Received doc snapshot: ${docSnapshot}`);
          if (docSnapshot.exists) {
            const aUserPrivateProfileFBD = this.cleanupUserPrivateProfileSnapshotFromBackend(docSnapshot)   
            userPrivateProfileDocObserverReceived(aUserPrivateProfileFBD)
          }
          // ...
        }, err => {
          console.error(`Encountered error: ${err}`);
        });
      }
      // console.error("getUserPrivateProfile " + JSON.stringify(userProfileObj))
      // console.error("getUserPrivateProfile userPrivateProfile.data()" + JSON.stringify(userPrivateProfile.data()))
      return {
        backendActionDone: true,
        errorMsg: undefined,
        userProfileObj: userProfileObj,
        userPrivateProfileDocObserver,
        userPublicProfileDocObserver
      }
    } catch(e) {
      // console.error(e)
      return {
        backendActionDone: false,
        errorMsg: ""+e,
        userProfileObj: undefined,
        userPrivateProfileDocObserver: undefined,
        userPublicProfileDocObserver: undefined
      }
    }
  }
  async getMyUserProfile(userId: string, userPrivateProfileDocObserverReceived: any,
    userPublicProfileDocObserverReceived: any) {
    // console.error("getMyUserProfile")
    try {
      // fetch user profile
      // console.error("user " + userId)
      const isC = this.isUserOK(userId)
      if (isC.userId === ""){
        return {
          errorMsg: "Invalid userId",
          userProfileObj: undefined,
          backendActionDone: false,
          userPrivateProfileDocObserver: undefined,
          userPublicProfileDocObserver: undefined
        }
      }
      const userPublicFBProfileDoc = fb.usersPublicCollection.doc(userId)
      const userPublicFBProfile = await userPublicFBProfileDoc.get()
      let userProfileObj = {} as UserProjectTypes.UserProfile
      // set user profile in state
      if (userPublicFBProfile === undefined || userPublicFBProfile.data() === undefined) {
        userProfileObj = {
          ... defaultProfile
        }
      } else {
        userProfileObj = {
          ... defaultProfile,
          ...userPublicFBProfile.data()
        }
      }
      userProfileObj.UPUserId = userId

      let userPublicProfileDocObserver = undefined
      if (userPublicProfileDocObserverReceived) {
        userPublicProfileDocObserver = userPublicFBProfileDoc.onSnapshot(docSnapshot => {
          // console.error(`Received doc snapshot: ${docSnapshot}`);
          if (docSnapshot.exists) {
            const aUserPublicProfileFBD = this.cleanupUserPrivateProfileSnapshotFromBackend(docSnapshot)   
            userPublicProfileDocObserverReceived(aUserPublicProfileFBD)
          }
          // ...
        }, err => {
          console.error(`Encountered error: ${err}`);
        });
      }


      firebaseAnalytics.logEvent('getMyUserProfile-' + userProfileObj.UPPseudo, {
        AEDPseudo: userProfileObj.UPPseudo
      });

      if (userProfileObj.UPBanned === true) {
        return {
          errorMsg: "UserBanned",
          userProfileObj: undefined,
          backendActionDone: false,
          userPrivateProfileDocObserver: undefined,
          userPublicProfileDocObserver: undefined
        }
      }
      if (userProfileObj.UPClosed === true) {
        return {
          errorMsg: "UserClosed",
          userProfileObj: undefined,
          backendActionDone: false,
          userPrivateProfileDocObserver: undefined,
          userPublicProfileDocObserver: undefined
        }
      }
      const adminRes = await this.getAdminUserProfile(userId, userProfileObj)
      if (adminRes && adminRes.backendActionDone && adminRes.userProfileObj) {
        userProfileObj = {
          ...adminRes.userProfileObj
        }
      }
      //userProfileObj.UPIsAdmin = true
      return await this.getUserPrivateProfile(userId, userProfileObj, userPrivateProfileDocObserverReceived, userPublicProfileDocObserver)
    } catch(e) {
      console.error(e)
      return {
        backendActionDone: false,
        errorMsg: ""+e,
        userProfileObj: undefined,
        userPrivateProfileDocObserver: undefined,
        userPublicProfileDocObserver: undefined
      }
    }
  }
  async getUserProfile(queryParams: {userId: string; aPseudo: string}) {
    try {
      // fetch user profile
      // console.error("user " + userId)
      let userPublicFBProfile: any
      if (queryParams.userId && queryParams.userId !== "") {
        userPublicFBProfile = await fb.usersPublicCollection.doc(queryParams.userId).get()
      } else {
        const userProfileSnapshot = await fb.usersPublicCollection.where("UPPseudo", "==", queryParams.aPseudo).limit(1).get()
        if (userProfileSnapshot.size === 1) {
          userPublicFBProfile = userProfileSnapshot.docs[0]
        } else {
          return {
            backendActionDone: false,
            errorMsg: "unknownPseudo",
            userProfileObj: undefined,
            userPrivateProfileDocObserver: undefined,
            userPublicProfileDocObserver: undefined
          }
        }
      }
      let userProfileObj = {} as UserProjectTypes.UserProfile
      // set user profile in state
      if (userPublicFBProfile === undefined || userPublicFBProfile.data() === undefined) {
        userProfileObj = {
          ... defaultProfile
        }
      } else {
        userProfileObj = {
          ... defaultProfile,
          ...userPublicFBProfile.data()
        }
      }
      userProfileObj.UPUserId = userPublicFBProfile.id //queryParams.userId
      const ret = await this.getUserPrivateProfile(userPublicFBProfile.id, userProfileObj, undefined, undefined)
      if (ret && ret.backendActionDone === false) {
        return {
          backendActionDone: true,
          errorMsg: undefined,
          userProfileObj: userProfileObj,
          userPrivateProfileDocObserver: undefined,
          userPublicProfileDocObserver: undefined
        }
      } else {
        return ret
      }
    } catch(e) {
      console.error(e)
      return {
        backendActionDone: false,
        errorMsg: ""+e,
        userProfileObj: undefined,
        userPrivateProfileDocObserver: undefined,
        userPublicProfileDocObserver: undefined
      }
    }
  }

  async pseudoExists(aPseudo: string) {
    return await fb.usersPublicCollection.where("UPPseudo", "==", aPseudo).get().then((res) => {
      console.log(res.size + "  return pseudoExists : " + (res.size !== 0))
      return (res.size !== 0)
    });
  }
  async signupValidationStep(signupData: SignupData) {
    console.error("signupValidationStep")
    if (signupData.password === "") {
        return {
          backendActionDone: false,
          errorMsg: "InvalidPassword",
          userId: undefined
        }
    } else if (signupData.email === "" ) {
        return {
          backendActionDone: false,
          errorMsg: "InvalidEmail",
          userId: undefined
        }
    } else if (signupData.pseudo === "") {
        return {
          backendActionDone: false,
          errorMsg: "InvalidPseudo",
          userId: undefined
        }
    } else if (signupData.acceptCGU === false) {
      return {
        backendActionDone: false,
        errorMsg: "InvalidAccept",
        userId: undefined
      }
    }
    if (fb.auth === null) {
      return {
        backendActionDone: false,
        errorMsg: "Firebase auth is null",
        userId: undefined
      }
    }
    // pseudo  already in our collection?
    // For the moment useless to make the test for email, it will be done by firebase createUserWithEmailAndPassword ?
    // TODO Verify the previous case when other type of signup will be available (facebook ...)
    if (await this.pseudoExists(signupData.pseudo) === true) {
      return {
        backendActionDone: false,
        errorMsg: "PseudoAlreadyUsed",
        userId: undefined
      }
    }
    return undefined
  }
  async createUserPublicPart(user: any, signupData: SignupData) {
    return fb.usersPublicCollection.doc(user.uid).set({
      UPPseudo: signupData.pseudo,
      UPDescription: (signupData.description !== undefined) ? signupData.description : "",
      UPPictureUrl: this.getUPPictureUrlForFirebase(signupData.profileUrl), // (signupData.profileUrl !== undefined) ? signupData.profileUrl : ""
    }).then(() => {
      console.log('Write user public part succeeded!');
      return this.createUserPrivatePart(user, signupData)
    }, (reason) => {
      return {
        backendActionDone: false,
        userId: undefined,
        errorMsg: "error " + reason
      }
    });
  }
  async createUserPrivatePart(user: any, signupData: SignupData) {
    return fb.usersPrivateCollection.doc(user.uid).set({
      UPName: (signupData.name !== undefined) ? signupData.name : "" ,
      UPI18N: fullLocale,
      UPEmail: signupData.email,
      UPCreatedOn: new Date(),
      //UPCreatedOn: new Date().toISOString(),
    }).then(() => {
      console.log('Write user private part succeeded!');
      return {
        backendActionDone: true,
        errorMsg: undefined,
        userId: user.uid
      }
    }, (reason) => {
      return {
        backendActionDone: false,
        userId: undefined,
        errorMsg: "error " + reason
      }
    });
  }
  async signup(signupData: SignupData) {
    // console.error("signup")
    // sign user up
    try {
      const val = await this.signupValidationStep(signupData)
      if (val !== undefined) {
        return val
      }
      // await is not working with fb.auth search google for the reason (the others firebase modules work well with await)
      //const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)
      return fb.auth.createUserWithEmailAndPassword(signupData.email, signupData.password)
        .then((userCredential) => {

            const user = userCredential.user;
            console.error("signup user " + user)
            if (user === null) {
              return {
                backendActionDone: false,
                errorMsg: "Create user returns invalid data",
                userId: undefined
              }
            }
            // create user object in userCollections
            return this.createUserPublicPart(user, signupData)

        } )
        .catch((error) => {
          return this.returnAuthCatchError(error)
        });

    } catch(e) {
      console.error(e)
      return {
        backendActionDone: false,
        errorMsg: ""+e,
        userId: undefined
      }
    }
  }
  async login(email: string, password: string) {
    // console.error("login")
    // sign user in
    //const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)
    if (fb.auth === null) {
      return {
        backendActionDone: false,
        errorMsg: "Firebase auth is null",
        userId: undefined
      }
    }
    return fb.auth.signInWithEmailAndPassword(email, password).then((userCredential) => {
        const user = userCredential.user;
        if (user !== null && user.uid !== undefined) {
          return {
            backendActionDone: true,
            errorMsg: undefined,
            userId: user.uid
          }
        } else {
          return {
            backendActionDone: false,
            errorMsg: "User credential returns invalid data",
            userId: undefined
          }
        }
      //return auth;
    }).catch((error) => {
      return this.returnAuthCatchError(error)
    });
  }
  /*
  Find a better way to set or unset the cookie __session
  at login/logout times
  */
  async setAuthorisationToken( token: any) {
    // console.error('Sending request to',  'with ID token in Authorization header.');
    if (this.lastSentToken === token) {
      // console.error('setAuthorisationToken same token');
      return
    }
    // console.error('setAuthorisationToken different  token this.lastSentToken ' , this.lastSentToken);
    this.lastSentToken = token
    try {
      const req = new XMLHttpRequest();
      req.onreadystatechange=function() {
        if (req.readyState === 4){   //if complete
            if(req.status === 200){  //check if "OK" (200)
                //success
            } else {
              console.error("Error in onreadystatechange setAuthorisationToken, could be ok in localhost")
            }
        } 
      }
      req.onload = function() {
        console.log("setAuthorisationToken onload")
      }.bind(this);
      req.onerror = function(err: any) {
        console.log("setAuthorisationToken onerror")
      }.bind(this);
      //req.open('GET', "https://alomigoid.web.app/ac/ffff", true);
      req.open('GET', "/ac/ffff", true);
      req.setRequestHeader('Authorization', token);
      req.send();
    } catch(err) {
      console.error("Error in setAuthorisationToken, could be ok in localhost")
    }
  }
  async getUserToken(user: firebase.User) {
    //firebase.auth().currentUser
    // console.error("getUserToken 1")
    user.getIdToken().then(async (idToken: any) => {
      //final idToken = await user.getIdToken();
      //final token = idToken.token;
      // console.log("idToken " + idToken); // Nothing happens. No errors and the function not continues
      // console.error("getUserToken 2 " + idToken)
      await this.setAuthorisationToken('Bearer ' + idToken)
   }).catch(function(error: any) {
       console.log("getUserToken " + error); // Nothing
   });
  }
  async getCurrentUser() {
    // console.error("getCurrentUser 1")
    return new Promise((resolve, reject) => {
      // console.error("getCurrentUser 2")
      if (this.unsubscribeCB === undefined || fb.auth === null || fb.auth.currentUser === null) {
        this.unsubscribeCB = fb.auth.onIdTokenChanged(async user => {
          // const unsubscribe = fb.auth.onAuthStateChanged(async user => {
          // console.error("getCurrentUser 3")
          ///this.unsubscribeCB();
          if (user) {
            this.getUserToken(user)
          } else {
            this.unsubscribeCB();
            this.unsubscribeCB = undefined
            await this.setAuthorisationToken("no")
          }
          // this.currentUser = user;
          resolve(user);
        }, reject);
      } else {
        resolve(fb.auth.currentUser);
      }
    })
  }

  async logout() {
    console.error("logout")
    if (fb.auth === null) {
      return {
        backendActionDone: false,
        errorMsg: "Firebase auth is null",
        userId: undefined
      }
    }
    return fb.auth.signOut().then(async ()=> {
      console.error("In logout after fb.auth.signOut")
      await this.setAuthorisationToken("no")
      return {
        backendActionDone: true,
        errorMsg: undefined,
        userId: undefined
      }
    }).catch(function(error) {
      const errorMessage = error.message;
      return {
        backendActionDone: false,
        errorMsg: errorMessage,
        userId: undefined
      }
    });
  }
  /*
  TODO Not correct
  */
  async updateProfileInComment(user: UserProjectTypes.UserProfile, userId: string) {
    // update all comments by user
    const commentDocs = await fb.commentsCollection.where('ADOwnerMiniProfile.UOUserId', '==', userId).get()
    commentDocs.forEach(async doc => {
      await doc.ref.update({
      //fb.commentsCollection.doc(doc.id).update({
        userName: user.UPName
      })
    })
  }

  async sendPasswordResetEmail(anEmail: string) {
    return fb.auth.sendPasswordResetEmail(anEmail).then(function() {
      // sendPasswordResetEmail successful.
      return {
        backendActionDone: true,
        errorMsg: undefined,
      }
    }).catch((error) => {
      return this.returnAuthCatchError(error)
    });
  }

  async updateEmail(newEmail: string) {
    const user = firebase.auth().currentUser;
    if (user !== null) {
      return user.updateEmail(newEmail).then(function() {
        // Update successful.
        return {
          backendActionDone: true,
          errorMsg: undefined,
          userId: undefined
        }
      }).catch(function(reason) {
        // An error happened.
        return {
          backendActionDone: false,
          errorMsg: "Error in update profile " + reason,
          userId: undefined
        }
      });
    }
    return {
      backendActionDone: false,
      errorMsg: "NotConnected",
      userId: undefined
    }
  }

  async updateProfile(aUser: UserProfileProjectObject, updateEmail: any) {
    // console.error("updateProfile")
    const user = aUser.exportToFirebaseObject() // UserProjectTypes.UserProfile
    const isC = this.isUserOK(user.UPUserId)
    if (isC.userId === ""){
      return {
        ...isC,
        backendActionDone: false
      }
    }
    const userId = isC.userId //fb!.auth!.currentUser!.uid
    let emailUpdated = false
    if (updateEmail && updateEmail.newEmail !== undefined && updateEmail.previousEmail) {
      // console.error("updateEmail " + JSON.stringify(updateEmail))
      // change auth email in google auth
      const resUpdate = await this.updateEmail(updateEmail.newEmail)
      if (resUpdate.backendActionDone === false) {
        return resUpdate
      }
      emailUpdated = true
    }
    // update user object
    return this.updatePublicProfile(user, userId /*, aUser.getOwnerMiniProfile(), aUser.getMiniProfile()*/, emailUpdated)
  }

  getUPPictureUrlForFirebase(aUPPictureUrl: string) {
    if (aUPPictureUrl !== undefined && aUPPictureUrl.startsWith("d?ttt=") === true) {
      return "d"
    }
    return (aUPPictureUrl !== undefined) ? aUPPictureUrl : ""
  }

  async updatePublicProfile(user: UserProjectTypes.UserProfile, userId: string , emailUpdated: boolean/*,
    ownerMiniProfile: UserProjectTypes.OwnerMiniProfile,
    miniProfile: UserProjectTypes.UserMiniProfile*/) {
    // update user object
    return fb.usersPublicCollection.doc(userId).update({
      //UPPseudo: (user.UPPseudo !== undefined) ? user.UPPseudo : "",
      UPEnterprise: (user.UPEnterprise !== undefined) ? user.UPEnterprise : firebase.firestore.FieldValue.delete() ,
      UPDescription: (user.UPDescription !== undefined) ? user.UPDescription : "",
      UPPictureUrl: this.getUPPictureUrlForFirebase(user.UPPictureUrl), // (user.UPPictureUrl !== undefined) ? user.UPPictureUrl : "",
    }).then(async () => {
      /*
      await this.updateProfileInAdDoc(user, userId, ownerMiniProfile)
      // await this.updateProfileInComment(user, userId)
      await this.updateProfileInTransaction(user, userId, miniProfile)
      */
      return await this.updatePrivateProfile(user, userId, emailUpdated)

    }, (reason) => {
      return {
        backendActionDone: false,
        errorMsg: "Error in update profile " + reason,
        userId: userId
      }
    })
  }

  async updatePrivateProfile(user: UserProjectTypes.UserProfile, userId: string, emailUpdated: boolean) {
    // console.error("updatePrivateProfile")
    // update user object
    // console.error("updatePrivateProfile " + user.UPLocation)
    const newPrivateData: any = {
      UPName: (user.UPName !== undefined) ? user.UPName : "",
      UPEmail: (user.UPEmail !== undefined) ? user.UPEmail : "",
      UPPhoneNumber: (user.UPPhoneNumber !== undefined) ? user.UPPhoneNumber : "",
      UPLocation: (user.UPLocation !== undefined) ? user.UPLocation : firebase.firestore.FieldValue.delete() ,
      UPDefaults: (user.UPDefaults !== undefined) ? user.UPDefaults : firebase.firestore.FieldValue.delete() ,
    }
    if (emailUpdated) {
      newPrivateData['UPEmailValidated'] = false
      newPrivateData['UPEmailValidationDate'] = firebase.firestore.FieldValue.delete()
    }
    return fb.usersPrivateCollection.doc(userId).update(newPrivateData).then(async () => {
      return {
        backendActionDone: true,
        errorMsg: undefined,
        userId: userId
      }
    }, (reason) => {
      return {
        backendActionDone: false,
        errorMsg: "Error in update profile " + reason,
        userId: userId
      }
    })
  }
  
  async updateProfilePlan(aUserId: string, subscriptionId: string, planId: string) {
    return fb.usersPrivateCollection.doc(aUserId).update({
      UPSubscriptionId: subscriptionId,
      UPSubscriptionPlanId: planId,
      UPSubscriptionCreatedOn: new Date(),
      UPSubscriptionCancelledOn: firebase.firestore.FieldValue.delete(),
      UPSubscriptionEndedOn: firebase.firestore.FieldValue.delete()
    }).then(async () => {
      return {
        backendActionDone: true,
        errorMsg: undefined,
      }
    }, (reason) => {
      return {
        backendActionDone: false,
        errorMsg: "Error in updateProfilePlan  " + reason,
      }
    })
  } 

  async doClosingAccount(options: { 
    trRequesterUserId: string; 
  }) {
    let ret;
    const errorsArray = []
    ret = await this.doClosingAccountInMyContactsPart({
      requesterUserId: options.trRequesterUserId
    })
    if (ret.backendActionDone === false) {
      errorsArray.push(ret.errorMsg)
    }
    
    ret = await this.doClosingAccountMyContactsPart(options)
    if (ret.backendActionDone === false) {
      errorsArray.push(ret.errorMsg)
    }
    
    ret = await this.doClosingAccountNotificationsPart(options)
    if (ret.backendActionDone === false) {
      errorsArray.push(ret.errorMsg)
    }
    
    ret = await this.doClosingAccountCalendarEventsPart(options)
    if (ret.backendActionDone === false) {
      errorsArray.push(ret.errorMsg)
    }
    ret = await this.closingUserAccount(options)
    if (ret.backendActionDone === false) {
      errorsArray.push(ret.errorMsg)
    }
    
    return {
      backendActionDone: (errorsArray.length === 0) ? true : false,
      errorMsg: (errorsArray.length === 0) ? undefined : errorsArray.join(" - ")
    }
  }
  
  
  async doClosingAccountCalendarEventsPart(options: { 
    trRequesterUserId: string; 
  }) {
    try {
      const adDocFBDs2 = await fb.usersPrivateCollection.doc(options.trRequesterUserId).collection('UPColCalendarEvents').get()
      const batch = fb.db.batch();
      adDocFBDs2.forEach(async (doc: any) => {
        batch.delete(doc.ref);
      })
      await batch.commit();
    } catch (e) {
      const errorMsg = "error doClosingAccountCalendarEventsPart " + e
      console.error(errorMsg);
      return {
        backendActionDone: false,
        errorMsg
      }
    }
    return {
      backendActionDone: true,
      errorMsg: ""
    }
  }

  async doClosingAccountNotificationsPart(options: { 
    trRequesterUserId: string; 
  }) {
    try {
      console.log("doClosingAccountNotificationsPart ");
      const adDocFBDs2 = await fb.usersPrivateCollection.doc(options.trRequesterUserId).collection('UPColNotifications').get()
      console.log("doClosingAccountNotificationsPart before batch");
      const batch = fb.db.batch();
      adDocFBDs2.forEach(async (doc: any) => {
        console.log("doClosingAccountNotificationsPart in batch");
        batch.delete(doc.ref);
      })
      await batch.commit();
      console.log("doClosingAccountNotificationsPart Finished async");
    } catch (e) {
      const errorMsg = "error doClosingAccountNotificationsPart " + e
      console.error(errorMsg);
      return {
        backendActionDone: false,
        errorMsg
      }
    }
    return {
      backendActionDone: true,
      errorMsg: ""
    }
  }

  async sendEmailValidationLinkToUser() {
    try {
      const isC = this.isConnected()
      if (isC !== undefined && isC.userId === ""){
        return {
          backendActionDone: false,
          errorMsg: "Not connected"
        }
      }
      const userId = isC.userId 

      console.log("sendEmailValidationLinkToUser ");
      const gfocSendEmailValidationLinkToUser = firebase.functions().httpsCallable("pv-gfocSendEmailValidationLinkToUser");
      const myData = {
        queryParams: {
          userId
        }
      };
      return gfocSendEmailValidationLinkToUser(myData)
        .then((result: any) => {
          return result.data
        }).catch((error: any) => {
          // Getting the Error details.
          console.error("sendEmailValidationLinkToUser error " + JSON.stringify(error));
          return {
            backendActionDone: false,
            errorMsg: JSON.stringify(error)
          }
      });
    } catch (e) {
      const errorMsg = "error sendEmailValidationLinkToUser " + e
      console.error(errorMsg);
      return {
        backendActionDone: false,
        errorMsg
      }
    }
  }

  async validationLinkFromUser(linkHash: string) {
    try {
      const isC = this.isConnected()
      if (isC !== undefined && isC.userId === ""){
        return {
          backendActionDone: false,
          errorMsg: "Not connected"
        }
      }
      const userId = isC.userId 

      console.log("gfocValidationLinkFromUser ");
      const gfocValidationLinkFromUser = firebase.functions().httpsCallable("pv-gfocValidationLinkFromUser");
      const myData = {
        queryParams: {
          userId,
          linkHash,
        }
      };
      return gfocValidationLinkFromUser(myData)
        .then((result: any) => {
          return result.data
        }).catch((error: any) => {
          // Getting the Error details.
          console.error("gfocValidationLinkFromUser error " + JSON.stringify(error));
          return {
            backendActionDone: false,
            errorMsg: JSON.stringify(error)
          }
      });
    } catch (e) {
      const errorMsg = "error gfocValidationLinkFromUser " + e
      console.error(errorMsg);
      return {
        backendActionDone: false,
        errorMsg
      }
    }
  }

  async doClosingAccountInMyContactsPart(queryParams: { 
    requesterUserId: string; 
  }) {
      console.log("doClosingAccountInMyContactsPart ");
      const gfocRemoveMeFromAllMyContacts = firebase.functions().httpsCallable("uo-gfocRemoveMeFromAllMyContacts");
      const myData = {
        queryParams
      };
      return gfocRemoveMeFromAllMyContacts(myData)
        .then((result: any) => {
          return result.data
        }).catch((error: any) => {
          // Getting the Error details.
          console.error("doClosingAccountInMyContactsPart error " + JSON.stringify(error));
          return {
            backendActionDone: false,
            errorMsg: JSON.stringify(error)
          }
      });
  }

  async doClosingAccountMyContactsPart(options: { 
    trRequesterUserId: string; 
  }) {
    try {
      console.log("doClosingAccountMyContactsPart ");
      const adDocFBDs2 = await fb.usersPublicCollection.doc(options.trRequesterUserId).collection('UPColContacts').get()
      console.log("doClosingAccountMyContactsPart before batch");
      const batch = fb.db.batch();
      adDocFBDs2.forEach(async (doc: any) => {
        console.log("doClosingAccountMyContactsPart in batch");
        batch.delete(doc.ref);
      })
      await batch.commit();
      console.log("doClosingAccountMyContactsPart Finished async");
    } catch (e) {
      const errorMsg = "error doClosingAccountMyContactsPart " + e
      console.error(errorMsg);
      return {
        backendActionDone: false,
        errorMsg
      }
    }
    return {
      backendActionDone: true,
      errorMsg: ""
    }
  }

  async removeMyContact(queryParams: {  contactUserId: string; 
    requesterUserId: string; }) {
    console.error("removeMyContact")
    const gfocRemoveMyContact = firebase.functions().httpsCallable("uo-gfocRemoveMyContact");
    const myData = {
      queryParams
    };
    return gfocRemoveMyContact(myData)
      .then((result: any) => {
        return result.data
      }).catch((error: any) => {
        // Getting the Error details.
        console.error("gfocRemoveMyContact error " + JSON.stringify(error));
        return {
          backendActionDone: false,
          errorMsg: JSON.stringify(error)
        }
    });
  }

  async cancelUserSubscription(queryParams: {  aUserId: string; 
      aSubscriptionId: string;}) {
    console.error("cancelSubscription")
    const gfocCancelUserSubscription = firebase.functions().httpsCallable("us-gfocCancelUserSubscription");
    const myData = {
      queryParams: this.convertDateObjectToStringForFirebase(queryParams)
    };
    return gfocCancelUserSubscription(myData)
      .then((result: any) => {
        return result.data
      }).catch((error: any) => {
        // Getting the Error details.
        console.error("gfocCancelUserSubscription error " + JSON.stringify(error));
        return {
          backendActionDone: false,
          errorMsg: JSON.stringify(error)
        }
    });
  }

  async removeProfilePicture(pathAndFile: string) {
    try {
      return firebaseStorage.deleteAnAttachedFile(pathAndFile).then(function () {
        // File deleted successfully 
        // console.error("removeAttachedFiles DONE " + pathAndFile)
        return {
          backendActionDone: true,
          errorMsg: ""
        }
      }).catch(function (error: any) {
        // There has been an error      
        console.error("error removeProfilePicture " + pathAndFile + "  " + error)
        return {
          backendActionDone: false,
          errorMsg: error
        }
      });
    } catch (e) {
      console.error("error removeProfilePicture " + e)
      return {
        backendActionDone: false,
        errorMsg: e + ""
      }
    }
    
  }

  async setExternEnterpriseToUser(optionsQuery: { userId: string; newEnterprise: any  }) {
    try {
      return fb.usersPublicCollection.doc(optionsQuery.userId).update({
        UPEnterprise: (optionsQuery.newEnterprise !== undefined) ? optionsQuery.newEnterprise : firebase.firestore.FieldValue.delete() ,
      }).then(async () => {
        return {
          backendActionDone: true,
          errorMsg: undefined,
        }
      }, (reason) => {
        return {
          backendActionDone: false,
          errorMsg: "Error in setExternEnterpriseToUser action " + reason,
        }
      })
    } catch (e) {
      console.error("error setExternEnterpriseToUser " + e)
      return {
        backendActionDone: false,
        errorMsg: e + ""
      }
    }
  }


  async banUser(optionsQuery: { userId: string; banValue: boolean }) {
    try {
      return fb.usersPublicCollection.doc(optionsQuery.userId).update({
        UPBanned: optionsQuery.banValue,
        UPBannedOn: new Date(),
      }).then(async () => {
        return {
          backendActionDone: true,
          errorMsg: undefined,
        }
      }, (reason) => {
        return {
          backendActionDone: false,
          errorMsg: "Error in ban action " + reason,
        }
      })
    } catch (e) {
      console.error("error banUser " + e)
      return {
        backendActionDone: false,
        errorMsg: e + ""
      }
    }
  }

  async closingUserAccount(optionsQuery: { trRequesterUserId: string }) {
    console.error("closingUserAccount")
    try {
      return fb.usersPublicCollection.doc(optionsQuery.trRequesterUserId).update({
        UPClosed: true,
        UPClosedOn: new Date(),
      }).then(async () => {
        return {
          backendActionDone: true,
          errorMsg: undefined,
        }
      }, (reason) => {
        return {
          backendActionDone: false,
          errorMsg: "Error in closingUserAccount action " + reason,
        }
      })
    } catch (e) {
      console.error("error closingUserAccount " + e)
      return {
        backendActionDone: false,
        errorMsg: e + ""
      }
    }
  }

  async getContactsForUser(optionsQuery: any) {
    const res = Array<any>();
    try {
      const isC = this.isUserOK(optionsQuery.UPUserId)
      if (isC.userId === ""){
        return {
          ...isC,
          backendActionDone: false,
          aUPColContacts: undefined
        }
      }
      const userId = isC.userId //fb!.auth!.currentUser!.uid
      const docs = await fb.usersPublicCollection.doc(userId).collection('UPColContacts').get()
      // console.error("getContactsForUser " + docs)
      docs.forEach((doc: any) => {
        const contact: any = doc.data()
        //contact.UCUserId = doc.id
        res.push(contact)
      })
    } catch (e) {
      console.error("error getContactsForUser ")
      console.error("error getContactsForUser " + e)
      return {
        backendActionDone: false,
        errorMsg: e + "",
        aUPColContacts: undefined
      }
    }
    // console.error("getContactsForUser res " + res.length)
    return {
      backendActionDone: true,
      errorMsg: undefined,
      aUPColContacts: res
    }
  }

  async getAllowedEnterprisesForUser(optionsQuery: any) {
    const res = Array<any>();
    try {
      //const isC = this.isUserOK(optionsQuery.UPUserId)
      const isC = this.isConnected()
      if (isC === undefined || isC.userId === ""){
        return {
          ...isC,
          backendActionDone: false,
          aUPColAllowedEnterprises: undefined
        }
      }
      const userId = optionsQuery.UPUserId
      // console.error("userId " + userId)
      // console.error("isC.userId " + isC.userId)
      const docs = await fb.usersPublicCollection.doc(userId).collection('UPColAllowedEnterprises').get()
      // console.error("getAllowedEnterprisesForUser " + docs)
      docs.forEach((doc: any) => {
        const enterprise: any = doc.data()
        //contact.UCUserId = doc.id
        
        res.push(new EnterpriseInfoProjectObject(enterprise.UAEEnterpriseInfo))
      })
    } catch (e) {
      console.error("error getAllowedEnterprisesForUser ")
      console.error("error getAllowedEnterprisesForUser " + e)
      return {
        backendActionDone: false,
        errorMsg: e + "",
        aUPColAllowedEnterprises: undefined
      }
    }
    // console.error("getAllowedEnterprisesForUser res " + res.length)
    return {
      backendActionDone: true,
      errorMsg: undefined,
      aUPColAllowedEnterprises: res
    }
  }


  async addMutualContacts(queryParams: {ATMUser: UserTypes.UserMiniProfile; 
    UPMUser: UserTypes.UserMiniProfile;}) {
    const res = await this.addNewContact({newContact: queryParams.ATMUser, 
      inContact: queryParams.UPMUser})
    if (res && res.backendActionDone === true) {
      return await this.addNewContact({newContact: queryParams.UPMUser, 
        inContact: queryParams.ATMUser})
    }
    return res
  }
  async addReportList(queryParams: {  aUserNotification: any}) {
    // console.error("addReportList")
    // console.error("addReportList queryParams " + JSON.stringify(queryParams))
    const gfocAddReportList = firebase.functions().httpsCallable("nf-gfocAddReportList");
    const myData = {
      queryParams
    };
    return gfocAddReportList(myData)
      .then((result: any) => {
        // Read result of the Cloud Function.
        // console.error("gfocAddReportList" );
        // console.error("gfocAddReportList" + JSON.stringify(result.data));
        return result.data
      }).catch((error: any) => {
        // Getting the Error details.
        console.error("gfocAddReportList error " + JSON.stringify(error));
        return {
          backendActionDone: false,
          errorMsg: JSON.stringify(error)
        }
    });
  }
  
  async addNotificationsList(queryParams: {  aUserNotification: any;
      userIdWithPendingOrAcceptedTransactions: Array<string>; importantChangesCounter: number;}) {
    // queryParams.aUserNotification.UNDateHourStartsOn = queryParams.aUserNotification.UNDateHourStartsOn.toString()
    const gfocAddNotificationsList = firebase.functions().httpsCallable("nf-gfocAddNotificationsList");
    const myData = {
      queryParams: this.convertDateObjectToStringForFirebase(queryParams)
    };
    return gfocAddNotificationsList(myData)
      .then((result: any) => {
        // Read result of the Cloud Function.
        console.error("gfocAddNotificationsList" );
        console.error("gfocAddNotificationsList" + JSON.stringify(result.data));
        return result.data
      }).catch((error: any) => {
        // Getting the Error details.
        console.error("gfocAddNotificationsList error " + JSON.stringify(error));
        return {
          backendActionDone: false,
          errorMsg: JSON.stringify(error)
        }
    });
  }
  async addNotification(queryParams: {  aUserNotification: any}) {
    // queryParams.aUserNotification.UNDateHourStartsOn = queryParams.aUserNotification.UNDateHourStartsOn.toString()
    const gfocAddNotification = firebase.functions().httpsCallable("nf-gfocAddNotification");
    const myData = {
      queryParams: this.convertDateObjectToStringForFirebase(queryParams)
    };
    return gfocAddNotification(myData)
      .then((result: any) => {
        // Read result of the Cloud Function.
        console.error("gfocAddNotification" );
        console.error("gfocAddNotification" + JSON.stringify(result.data));
        return result.data
      }).catch((error: any) => {
        // Getting the Error details.
        // console.error("gfocAddNotification error " + JSON.stringify(error));
        return {
          backendActionDone: false,
          errorMsg: JSON.stringify(error)
        }
    });
  }
  
  async cancelCalendarEvent(queryParams: {  aUserCalendarEvent: UserTypes.UserCalendarEvent}) {
    // console.error("cancelCalendarEvent")
    const gfocCancelUserCalendarEvents = firebase.functions().httpsCallable("uc-gfocCancelUserCalendarEvents");
    const myData = {
      queryParams: this.convertDateObjectToStringForFirebase(queryParams)
    };
    return gfocCancelUserCalendarEvents(myData)
      .then((result: any) => {
        // Read result of the Cloud Function.
        // console.error("gfocCancelUserCalendarEvents" );
        // console.error("gfocCancelUserCalendarEvents" + JSON.stringify(result.data));
        return result.data
      }).catch((error: any) => {
        // Getting the Error details.
        console.error("gfocCancelUserCalendarEvents error " + JSON.stringify(error));
        return {
          backendActionDone: false,
          errorMsg: JSON.stringify(error)
        }
    });
  }

  
  async removeCalendarEventUsingId(queryParams: {  
    aUEOwnerUserId: string; 
    calendarEventId: string;
  }) {
    // console.error("removeCalendarEventUsingId")
    try {
      const isC = this.isConnected()
      if (isC !== undefined && isC.userId === "") {
        return {
          ...isC,
          backendActionDone: false,
        }
      }
      if (queryParams.aUEOwnerUserId !== isC.userId) {
        return {
          ...isC,
          backendActionDone: false,
        }
      }
      const userId = isC.userId 

      const calendarEventsCol = fb.usersPrivateCollection.doc(userId).collection('UPColCalendarEvents')
      const calendarEventDoc = calendarEventsCol.doc(queryParams.calendarEventId)
      const calendarEventDocs = await calendarEventDoc.get()
      if (calendarEventDocs.exists) {
        // console.error("removeNotification 22")
        return await calendarEventDoc.delete().then(async () => {
          // console.log("Notification successfully deleted!");
          return {
            backendActionDone: true,
            errorMsg: undefined
          }
          }).catch((error) => {
          console.error("Error removeCalendarEventUsingId: " + error);
          console.dir(error)
          //  + error.message + error.code
          return {
            backendActionDone: false,
            errorMsg: "Error removing calendarEvent in  document: " + error.message,
          }
        });
        } else {
        console.error("removeCalendarEventUsingId 33")
        return {
          backendActionDone: false,
          errorMsg: "calendarEvent doesn't exist, so it cannot be removed"
        }
      }

    } catch (e) {
      console.error("error removeCalendarEventUsingId ")
      console.error("error removeCalendarEventUsingId " + e)
      return {
        backendActionDone: false,
        errorMsg: e + ""
      }
    }
  }
  
  async removeCalendarEvent(queryParams: {  aUserCalendarEvent: UserTypes.UserCalendarEvent}) {
    // console.error("addCalendarEvent")
    const gfocRemoveUserCalendarEvents = firebase.functions().httpsCallable("uc-gfocRemoveUserCalendarEvents");
    const myData = {
      queryParams: this.convertDateObjectToStringForFirebase(queryParams)
    };
    return gfocRemoveUserCalendarEvents(myData)
      .then((result: any) => {
        // Read result of the Cloud Function.
        // console.error("gfocRemoveUserCalendarEvents" );
        // console.error("gfocRemoveUserCalendarEvents" + JSON.stringify(result.data));
        return result.data
      }).catch((error: any) => {
        // Getting the Error details.
        console.error("gfocRemoveUserCalendarEvents error " + JSON.stringify(error));
        return {
          backendActionDone: false,
          errorMsg: JSON.stringify(error)
        }
    });
  }

  convertDateObjectToStringForFirebase(anObject: any) {
    for (const prop in anObject) {
      if (anObject[prop] !== undefined) {
        if (anObject[prop] != "Invalid Date" && typeof anObject[prop].getMonth === 'function') {
          anObject[prop] = anObject[prop].toISOString()
        } else if (typeof anObject[prop] === 'object') {
          anObject[prop] = this.convertDateObjectToStringForFirebase(anObject[prop])
        }
      }
    }
    return anObject
}
  
  async addCalendarEvent(queryParams: {  aUserCalendarEvent: UserTypes.UserCalendarEvent}) {
    // console.error("addCalendarEvent")
    const gfocAddUserCalendarEvents = firebase.functions().httpsCallable("uc-gfocAddUserCalendarEvents");
    const myData = {
      queryParams: this.convertDateObjectToStringForFirebase(queryParams)
    };
    // console.error("addCalendarEvent " + JSON.stringify(myData))
    return gfocAddUserCalendarEvents(myData)
      .then((result: any) => {
        // Read result of the Cloud Function.
        // console.error("gfocAddUserCalendarEvents" );
        // console.error("gfocAddUserCalendarEvents" + JSON.stringify(result.data));
        return result.data
      }).catch((error: any) => {
        // Getting the Error details.
        console.error("gfocAddUserCalendarEvents error " + JSON.stringify(error));
        return {
          backendActionDone: false,
          errorMsg: JSON.stringify(error)
        }
    });
  }
  
  
  async updateCalendarEvent(queryParams: {  aUserCalendarEvent: UserTypes.UserCalendarEvent}) {
    // console.error("updateCalendarEvent")
    const gfocUpdateUserCalendarEvents = firebase.functions().httpsCallable("uc-gfocUpdateUserCalendarEvents");
    const myData = {
      queryParams: this.convertDateObjectToStringForFirebase(queryParams)
    };
    /*
    const tM = testMock?.({
      backendActionDone: false,
      errorMsg: "Mock error 111"
    })*/
    /*return {
      backendActionDone: false,
      errorMsg: "Mock error 111"
    }*/
    return /*tM || */gfocUpdateUserCalendarEvents(myData)
      .then((result: any) => {
        return result.data
      }).catch((error: any) => {
        // Getting the Error details.
        console.error("gfocUpdateUserCalendarEvents error " + JSON.stringify(error));
        return {
          backendActionDone: false,
          errorMsg: JSON.stringify(error)
        }
    });
  }



  async addNewContact(queryParams: {newContact: UserTypes.UserMiniProfile; inContact: UserTypes.UserMiniProfile}) {
    // console.error("updatePublicProfile")
    // update user object
    try {
      const userContacts = fb.usersPublicCollection.doc(queryParams.inContact.UMUserId).collection('UPColContacts')
      const transactionContact = await userContacts.doc(queryParams.newContact.UMUserId).get()
      if (transactionContact.exists) {
        console.error("addMutualContacts 22")
        return {
          backendActionDone: true,
          errorMsg: undefined
        }
      } else {
        return userContacts.doc(queryParams.newContact.UMUserId).set({
          UCCreatedOn: new Date(),
          UCMUser: queryParams.newContact
        }).then(() => {
          console.log('addMutualContacts Write succeeded!');
          // update AdDoc likes count
          return {
            backendActionDone: true,
            errorMsg: undefined
          }
        }, (reason: any) => {
          console.error("error addMutualContacts 33")
          return {
            backendActionDone: false,
            errorMsg: "error " + reason
          }
        });
      }
    } catch(e) {
      console.error("error addNewContact " + e)
      return {
        backendActionDone: false,
        errorMsg: e + ""
      }
    }
  }

  async addNewAllowedEnterprise(queryParams: {newAllowedEnterprise: EnterpriseInfoTypes.EnterpriseInfo; inContactUserId: string}) {
    try {
      const userAllowedEnterprises = fb.usersPublicCollection.doc(queryParams.inContactUserId).collection('UPColAllowedEnterprises')
      const transactionContact = await userAllowedEnterprises.doc(queryParams.newAllowedEnterprise.EIEnterpriseId).get()
      if (transactionContact.exists) {
        console.error("addNewAllowedEnterprise 22")
        return {
          backendActionDone: true,
          errorMsg: undefined
        }
      } else {
        console.log('addNewAllowedEnterprise queryParams.inContactUserId ' + queryParams.inContactUserId);
        console.log('addNewAllowedEnterprise queryParams.newAllowedEnterprise.EIEnterpriseId ' + queryParams.newAllowedEnterprise.EIEnterpriseId);

        return userAllowedEnterprises.doc(queryParams.newAllowedEnterprise.EIEnterpriseId).set({
          UAECreatedOn: new Date(),
          UAEEnterpriseInfo: queryParams.newAllowedEnterprise
        }).then(() => {
          console.log('addNewAllowedEnterprise Write succeeded!');
          return {
            backendActionDone: true,
            errorMsg: undefined
          }
        }, (reason: any) => {
          console.error("error addNewAllowedEnterprise 33")
          return {
            backendActionDone: false,
            errorMsg: "error " + reason
          }
        });
      }
    } catch(e) {
      console.error("error addNewAllowedEnterprise " + e)
      return {
        backendActionDone: false,
        errorMsg: e + ""
      }
    }
  }

  async removeAllowedEnterprise(queryParams: {enterpriseId: string; inContactUserId: string}) {
    // console.error("removeAllowedEnterprise")
    try {
      const isC = this.isConnected()
      if (isC !== undefined && isC.userId === "") {
        return {
          ...isC,
          backendActionDone: false,
        }
      }
      const userId = queryParams.inContactUserId 

      const calendarEventsCol = fb.usersPublicCollection.doc(userId).collection('UPColAllowedEnterprises')
      const calendarEventDoc = calendarEventsCol.doc(queryParams.enterpriseId)
      const calendarEventDocs = await calendarEventDoc.get()
      if (calendarEventDocs.exists) {
        // console.error("removeAllowedEnterprise 22")
        return await calendarEventDoc.delete().then(async () => {
          // console.log("removeAllowedEnterprise successfully deleted!");
          return {
            backendActionDone: true,
            errorMsg: undefined
          }
          }).catch((error) => {
          console.error("Error removeAllowedEnterprise: " + error);
          console.dir(error)
          //  + error.message + error.code
          return {
            backendActionDone: false,
            errorMsg: "Error removing removeAllowedEnterprise: " + error.message,
          }
        });
        } else {
        console.error("removeAllowedEnterprise 33")
        return {
          backendActionDone: false,
          errorMsg: "removeAllowedEnterprise doesn't exist, so it cannot be removed"
        }
      }

    } catch (e) {
      console.error("error removeAllowedEnterprise ")
      console.error("error removeAllowedEnterprise " + e)
      return {
        backendActionDone: false,
        errorMsg: e + ""
      }
    }
  }
  async queryLocalProfiles(optionsQuery: any, filteredCollection: any) {
    // Find cities within 50km of London
    const center = optionsQuery.nearLatLng; //[51.5074, 0.1278];
    const radiusInM = ((optionsQuery.nearRadiusInM !== undefined) ?
      optionsQuery.nearRadiusInM : 50000); //50 * 1000;

    // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    // a separate query for each pair. There can be up to 9 pairs of bounds
    // depending on overlap, but in most cases there are 4.
    const bounds = geohashQueryBounds(center, radiusInM);
    const promises = [];
    for (const b of bounds) {
      const q = filteredCollection 
        .orderBy('UPLocation.LPGeoHash')
        .startAt(b[0])
        .endAt(b[1]);

      promises.push(q.get());
    }

    // Collect all the query results together into a single list
    const snapshotAdDoc = await Promise.all(promises)
    const matchingAdDocs = Array<any>();

    for (const snap of snapshotAdDoc) {
      for (const doc of snap.docs) {
        if (doc !== undefined && doc.data() !== undefined) {
          const lat = doc.get('UPLocation.LPLat');
          const lng = doc.get('UPLocation.LPLng');

          // We have to filter out a few false positives due to GeoHash
          // accuracy, but most will match
          const distanceInKm = distanceBetween([lat, lng], center);
          const distanceInM = distanceInKm * 1000;
          if (distanceInM <= radiusInM) {
            /*
            const adDocFBD = doc.data()
            adDocFBD.ADId = doc.id
            matchingAdDocs.push(new UserProfileProjectObject(adDocFBD) )
            */
            const adDocFBD = this.cleanupProfileDocumentSnapshotFromBackend(doc)
            if (adDocFBD !== undefined //&& 
            // (optionsQuery.currentFilter === "myAdDocs" || (adDocFBD!.ADDescription != "" && adDocFBD!.ADDescription != null)
              ) {
              matchingAdDocs.push(new UserProfileProjectObject(adDocFBD))
            }
          }
        }
      }
    }
    return {
      profilesArray: matchingAdDocs,
      backendActionDone: false,
      errorMsg: undefined
    }
  }

  getTheLimit(optionsQuery: any) {
    // optionsQuery.maxLimit = 10
    return (optionsQuery !== undefined && optionsQuery.maxLimit !== undefined) ? optionsQuery.maxLimit : 3
  }

  addOrderToProfilesFilteredCollection(optionsQuery: any, filteredCollection: any) {
    let firebaseQuery
    const theLimit = this.getTheLimit(optionsQuery)
      //console.error("adminProfilesQuery " + optionsQuery.adminProfilesQuery)
      if (optionsQuery.nextProfile !== undefined) {
        console.error("optionsQuery.nextProfile " + optionsQuery.nextProfile.getUPUserId() + " - " + optionsQuery.nextProfile.getUPCreatedOn())
        firebaseQuery = filteredCollection.orderBy('UPCreatedOn', 'desc').startAfter(optionsQuery.nextProfile.getUPCreatedOn()).limit(theLimit)
      } else if (optionsQuery.previousProfile !== undefined) {
        firebaseQuery = filteredCollection.orderBy('UPCreatedOn', 'desc').endAt(optionsQuery.previousProfile.getUPCreatedOn()).limit(theLimit)
      } else {
        //firebaseQuery = filteredCollection.orderBy('UPCreatedOn', 'desc').limit(theLimit)
        //console.error("queryRecentProfiles theLimit " + theLimit)
        firebaseQuery = filteredCollection.orderBy('UPCreatedOn', 'desc').limit(theLimit)
      }
    return firebaseQuery
}

  addOrderToContactsFilteredCollection(optionsQuery: any, filteredCollection: any) {
    const theLimit = this.getTheLimit(optionsQuery)
    const firebaseQuery = filteredCollection.orderBy('UCCreatedOn', 'desc').limit(theLimit)
    return firebaseQuery
  }

  async queryRecentProfiles(optionsQuery: {
    previousProfile: UserProfileProjectObject;
    nextProfile: UserProfileProjectObject;
    maxLimit: number;
    suggestionFilter: boolean;
    templateFilter: boolean;
    currentFilter: string;
    nearLatLng: number;
    nearRadiusInM: number;
    universAndCatFilter: string;
    adminProfilesQuery: boolean;
  }, filteredCollection: any) {
  let firebaseQuery
  // console.error("queryRecentProfiles " + JSON.stringify(optionsQuery))
  const matchingAdDocs = Array<any>();
  let snapshotAdDocLength = 0
  try {
    firebaseQuery = this.addOrderToProfilesFilteredCollection(optionsQuery, filteredCollection)
    // console.error("queryRecentProfiles 2")
    const snapshotAdDoc = await firebaseQuery.get()
    snapshotAdDoc.forEach((doc: any) => {
      snapshotAdDocLength++
      if (doc !== undefined && doc.data() !== undefined) {
        const adDocFBD = this.cleanupProfileDocumentSnapshotFromBackend(doc)
        if (adDocFBD !== undefined //&& 
         // (optionsQuery.currentFilter === "myAdDocs" || (adDocFBD!.ADDescription != "" && adDocFBD!.ADDescription != null)
          ) {
          matchingAdDocs.push(new UserProfileProjectObject(adDocFBD))
        }
      }
    })
  } catch (e) {
    const errorMsg = "Error in queryRecentProfiles " + e
    console.error(errorMsg)
    return {
      profilesArray: matchingAdDocs,
      snapshotAdDocLength: snapshotAdDocLength,
      backendActionDone: false,
      errorMsg
    }
  }
  return {
    profilesArray: matchingAdDocs,
    snapshotAdDocLength: snapshotAdDocLength,
    backendActionDone: true,
    errorMsg: undefined
  }
}

  async queryRecentContacts(optionsQuery: {
    previousProfile: UserProfileProjectObject;
    nextProfile: UserProfileProjectObject;
    maxLimit: number;
    suggestionFilter: boolean;
    templateFilter: boolean;
    currentFilter: string;
    nearLatLng: number;
    nearRadiusInM: number;
    universAndCatFilter: string;
    adminProfilesQuery: boolean;
  }, filteredCollection: any) {
    let firebaseQuery
    const matchingAdDocs = Array<any>();
    let snapshotAdDocLength = 0
    try {
      firebaseQuery = this.addOrderToContactsFilteredCollection(optionsQuery, filteredCollection)
      const snapshotAdDoc = await firebaseQuery.get()
      snapshotAdDoc.forEach((doc: any) => {
        snapshotAdDocLength++
        if (doc !== undefined && doc.data() !== undefined) {
          const adDocFBD = this.cleanupContactDocumentSnapshotFromBackend(doc)
          if (adDocFBD !== undefined //&& 
          // (optionsQuery.currentFilter === "myAdDocs" || (adDocFBD!.ADDescription != "" && adDocFBD!.ADDescription != null)
            ) {
            matchingAdDocs.push(new UserProfileProjectObject(adDocFBD))
          }
        }
      })
    } catch (e) {
      const errorMsg = "Error in queryRecentContacts " + e
      console.error(errorMsg)
      return {
        profilesArray: matchingAdDocs,
        snapshotAdDocLength: snapshotAdDocLength,
        backendActionDone: false,
        errorMsg
      }
    }
    return {
      profilesArray: matchingAdDocs,
      snapshotAdDocLength: snapshotAdDocLength,
      backendActionDone: true,
      errorMsg: undefined
    }
  }

  cleanupProfileDocumentSnapshotFromBackend(doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>): UserProjectTypes.UserProfile | undefined{
    const aProfileFirebase = doc.data()
    if (aProfileFirebase) {
      aProfileFirebase.UPUserId = doc.id
      return this.cleanupProfileFromBackend(aProfileFirebase)
    }
    return undefined
  }

  cleanupProfileFromBackend(doc: firebase.firestore.DocumentData): any {
    //const aAdDoc = doc.data()
    const aProfileFirebase = doc
    //aAdDoc!.ADId = doc.id
    //console.error("cleanupProfileFromBackend " + JSON.stringify(aProfileFirebase))
    // console.error("aProfileFirebase " + aProfileFirebase.UPName)
    // console.error("aProfileFirebase " + aProfileFirebase.UPCreatedOn)
    if (aProfileFirebase && aProfileFirebase.UPLocation == undefined) {
      aProfileFirebase.UPLocation = {}
    }
    if (aProfileFirebase && aProfileFirebase.UPCreatedOn !== undefined && aProfileFirebase.UPCreatedOn.seconds !== undefined ) {
      aProfileFirebase.UPCreatedOn = aProfileFirebase.UPCreatedOn.toDate()
    }
    if (aProfileFirebase && aProfileFirebase.UPSubscriptionCreatedOn !== undefined && aProfileFirebase.UPSubscriptionCreatedOn.seconds !== undefined ) {
      aProfileFirebase.UPSubscriptionCreatedOn = aProfileFirebase.UPSubscriptionCreatedOn.toDate()
    }
    if (aProfileFirebase && aProfileFirebase.UPSubscriptionCancelledOn !== undefined && aProfileFirebase.UPSubscriptionCancelledOn.seconds !== undefined ) {
      aProfileFirebase.UPSubscriptionCancelledOn = aProfileFirebase.UPSubscriptionCancelledOn.toDate()
    }
    if (aProfileFirebase && aProfileFirebase.UPSubscriptionEndedOn !== undefined && aProfileFirebase.UPSubscriptionEndedOn.seconds !== undefined ) {
      aProfileFirebase.UPSubscriptionEndedOn = aProfileFirebase.UPSubscriptionEndedOn.toDate()
    }
    return aProfileFirebase
  }

  cleanupContactDocumentSnapshotFromBackend(doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>): UserProjectTypes.UserMiniProfile | undefined{
    const aProfileFirebase = doc.data()
    if (aProfileFirebase) {
      // aProfileFirebase.UPUserId = doc.id
      return this.cleanupContactFromBackend(aProfileFirebase)
    }
    return undefined
  }
  /*    
  UMUserId: string; // Contact UserId
    UMPseudo: string; // Contact Pseudo
    UMName: string; // Contact Name
    UMPictureUrl: string;
    UMDescription: string;
    UMFromAppBoss: boolean;
    UMI18N: string;

  */

  cleanupContactFromBackend(doc: firebase.firestore.DocumentData): any {
    //const aAdDoc = doc.data()
    const aProfileFirebase = doc
    return aProfileFirebase
  }

  getProfilesCollection(optionsQuery: any) {
    let filteredCollection: any
      // contact
        filteredCollection = fb.usersPrivateCollection

      // console.error("optionsQuery.currentFilter " + optionsQuery.currentFilter)
      // console.error("optionsQuery.currentFilterType " + optionsQuery.currentFilterType)
      if (optionsQuery.currentFilter === "premium") {
        // filteredCollection = fb.usersPrivateCollection.where("UPSubscriptionCreatedOn" , ">", start)         
        filteredCollection = fb.usersPrivateCollection.where("UPSubscriptionPlanId" , "in", optionsQuery.premiumPlanIds)
        /*[
          "P-6A7440045H446553NMDXKFSI", "P-6HH464802G832890GMDXNJ2Y", "P-37T45588KY053453GMDXNK7A"
        ])  */       
      }
      return filteredCollection
  }

  getContactsCollection(optionsQuery: any) {
    const filteredCollection = fb.usersPublicCollection.doc(fb.auth.currentUser?.uid).collection('UPColContacts') // .get()

    return filteredCollection
  }

  validAuth() {
    if (fb.auth === null || fb.auth.currentUser === null) {
      return false
    }
    return true
  }

  async queryContacts(optionsQuery: any) {
    const profilesArray = Array<any>()
    let moreElementsInCollection = false
    const ret = {
      OUPDataArrayFromBackend: profilesArray,
      deconnected: true,
      moreElementsInCollection,
      backendActionDone: false,
      errorMsg: undefined
    }
    optionsQuery.adminProfilesQuery = false
    if (this.validAuth() !== true) {
      return ret
    }
    try {
      const filteredCollection = this.getContactsCollection(optionsQuery)
      /*if (optionsQuery.nearLatLng !== undefined) {
        queryResult = await this.queryLocalProfiles(optionsQuery, filteredCollection)
      } else {*/
        const queryResult = await this.queryRecentContacts(optionsQuery, filteredCollection)
        const theLimit = this.getTheLimit(optionsQuery)
        // console.error(" profilesArray.length " + queryResult.profilesArray.length + " ==== " + theLimit)
        if (queryResult.snapshotAdDocLength === theLimit) {
          moreElementsInCollection = true
        }
      //}
      // console.error("queryProfiles" + JSON.stringify(queryResult))
      return {
        OUPDataArrayFromBackend: queryResult.profilesArray,
        deconnected: false,
        moreElementsInCollection,
        backendActionDone: queryResult.backendActionDone,
        errorMsg: queryResult.errorMsg
      }
    } catch(e) {
      const errorMsg = "queryProfiles 1 " + e
      console.error(errorMsg)
      return {
        OUPDataArrayFromBackend: profilesArray,
        deconnected: false,
        moreElementsInCollection,
        backendActionDone: false,
        errorMsg,
      }
    }

  }

  async queryProfilesForAdmin(optionsQuery: any) {
    const profilesArray = Array<any>()
    let moreElementsInCollection = false
    const ret = {
      OUPDataArrayFromBackend: profilesArray,
      deconnected: true,
      moreElementsInCollection,
      backendActionDone: false,
      errorMsg: undefined
    }
    optionsQuery.adminProfilesQuery = false
    if (this.validAuth() !== true) {
      return ret
    }
    try {
      let queryResult
      const filteredCollection = this.getProfilesCollection(optionsQuery)
      if (optionsQuery.nearLatLng !== undefined) {
        queryResult = await this.queryLocalProfiles(optionsQuery, filteredCollection)
      } else {
        queryResult = await this.queryRecentProfiles(optionsQuery, filteredCollection)
        const theLimit = this.getTheLimit(optionsQuery)
        // console.error(" profilesArray.length " + queryResult.profilesArray.length + " ==== " + theLimit)
        if (queryResult.snapshotAdDocLength === theLimit) {
          moreElementsInCollection = true
        }
      }
      // console.error("queryProfiles" + JSON.stringify(queryResult))
      return {
        OUPDataArrayFromBackend: queryResult.profilesArray,
        deconnected: false,
        moreElementsInCollection,
        backendActionDone: queryResult.backendActionDone,
        errorMsg: queryResult.errorMsg
      }
    } catch(e) {
      const errorMsg = "queryProfiles 1 " + e
      console.error(errorMsg)
      return {
        OUPDataArrayFromBackend: profilesArray,
        deconnected: false,
        moreElementsInCollection,
        backendActionDone: false,
        errorMsg,
      }
    }

  }
}

export default UserFirebase

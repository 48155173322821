import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "dashboardProfilesSearchAndListView" }
const _hoisted_2 = { class: "col1" }
const _hoisted_3 = { class: "col2" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  class: "noResults",
  "data-cy": "cyProfileNotFoundInSearchList"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterProfilesInLine = _resolveComponent("FilterProfilesInLine")!
  const _component_ProfilesFilterInCol = _resolveComponent("ProfilesFilterInCol")!
  const _component_UserProfileListNav = _resolveComponent("UserProfileListNav")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", null, [
      _createVNode(_component_FilterProfilesInLine, {
        onFilterChanged: _cache[0] || (_cache[0] = ($event: any) => ($setup.filterChanged($event)))
      })
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ProfilesFilterInCol)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.arrayOfProfiles.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.calculateNumberOfRows(_ctx.arrayOfProfiles), (i) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["profileRow", 'postRow'+_ctx.numberOfPostInRow]),
                  key: i
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getArrayElementsAtPosition(_ctx.arrayOfProfiles, i), (aUserProfile) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: aUserProfile?.OUPData?.UPUserId,
                      class: "post"
                    }, [
                      aUserProfile
                        ? _renderSlot(_ctx.$slots, "content", {
                            key: 0,
                            aUserProfile: aUserProfile
                          })
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ], 2))
              }), 128)),
              (_ctx.moreElementsInCollection === true)
                ? (_openBlock(), _createBlock(_component_UserProfileListNav, {
                    key: 0,
                    aAdDocSubList: _ctx.arrayOfProfiles,
                    onPreviousSubList: _cache[1] || (_cache[1] = ($event: any) => (_ctx.previousSubList())),
                    onNextSubList: _cache[2] || (_cache[2] = ($event: any) => ($setup.nextSubList()))
                  }, null, 8, ["aAdDocSubList"]))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString($setup.t("noResults")), 1)
            ]))
      ])
    ])
  ]))
}
import firebase from "firebase/app";
import "firebase/storage";

class FirebaseAnalytics {
  // TODO Probably better in another class because it's also used for UserFibase
  logEvent(	eventName:  string, eventDetails: any) {
    const eventParams  = {
      "app_name": process.env.VUE_APP_NAME,
      "app_version": process.env.VUE_APP_VERSION,
      ...eventDetails
    } as any//as { app_id?: string; app_installer_id?: string; app_name: string; app_version?: string; screen_name: any }
    /*
    TODO Remove them for the moment
    firebase.analytics().logEvent(eventName, eventParams);
    firebase.analytics().logEvent("screen_view", eventParams);
    */
  }
}

export default FirebaseAnalytics

<template>
  <AdDocumentViewMainPartTemplate
    :displayInModal="displayInModal"
    :displayCommentsLikesOnly="false"
    :displayFromList="false"
  >
    <template #content="{ aAdDoc, displayCommentsLikesOnly }">
      <h3>
        <FormatDate
          v-if="aAdDoc.OADData.ADDateHourStartsOn"
          prefix="Le "
          :inputDate="aAdDoc.OADData.ADDateHourStartsOn"
        ></FormatDate>
      </h3>
      <h3>{{ aAdDoc.OADData.ADTitle }}</h3>
      <div
        v-if="aAdDoc.OADData.ADColAttDoc && aAdDoc.OADData.ADColAttDoc.length"
      >
        <ImagesView :listOfImages="aAdDoc.OADData.ADColAttDoc"></ImagesView>
      </div>
      <div v-else>
        <DefaultImagesView :aAdDoc="aAdDoc"></DefaultImagesView>
      </div>
      <div>
        {{ t("adDocDescriptionTitle") }}
      </div>
      <p>{{ aAdDoc.OADData.ADDescription }}</p>
      <p v-if="aAdDoc.OADData.ADDisponibility">
        {{ t("adDocDetailPlacesTitle") }} {{ aAdDoc.OADData.ADDisponibility }}
      </p>
      <div v-if="aAdDoc.OADData.ADIntention || aAdDoc.OADData.ADMixed">
        {{ t("adDocCreateTabIntentionTitle") }}:
        <span v-if="aAdDoc.OADData.ADIntention">
          {{ t(aAdDoc.OADData.ADIntention) }}
          <span v-if="aAdDoc.OADData.ADMixed"> - </span>
        </span>
        <span v-if="aAdDoc.OADData.ADMixed">
          {{ t(aAdDoc.OADData.ADMixed) }}
        </span>
      </div>
      <FormatPrice
        v-if="aAdDoc.OADData.ADYourPrice"
        :inputPrice="aAdDoc.OADData.ADYourPrice"
        :inputPaidByOwner="aAdDoc.OADData.ADPercentagePaidByOwner"
        :inputPriceLabel="
          aAdDoc.isFromPremiumAccount() === true
            ? 'adDocPremiumPrice'
            : 'adDocNormalPrice'
        "
      ></FormatPrice>
      <FormatDate
        v-if="aAdDoc.OADData.ADDateHourEndsOn"
        prefix="Termine le:"
        :inputDate="aAdDoc.OADData.ADDateHourEndsOn"
      ></FormatDate>

      <div v-if="displayCommentsLikesOnly === true">
        <div>
          <GeolocationView
            :aLocation="aAdDoc.OADData.ADLocation"
          ></GeolocationView>
        </div>
        <div>
          <OwnerProfileViewLink
            :ownerMiniProfile="aAdDoc.OADData.ADOwnerMiniProfile"
          ></OwnerProfileViewLink>
        </div>
        <div>
          <EnterpriseDisplay
          :displayNoEnterprise="false"
          :aEnterprise="aAdDoc.OADData.ADEnterprise"
          ></EnterpriseDisplay>
        </div>
        <div>
          {{ t("itemPublishedOn") }}
          {{ formatDate(aAdDoc.OADData.ADCreatedOn) }}
        </div>
        <CommentsLikes
          v-if="aAdDoc.canHaveLikesAndComments() === true"
          :aAdDoc="aAdDoc"
          dataCYClass="InDetailView"
        ></CommentsLikes>
      </div>
      <div v-else>
        <CommentsLikes
          v-if="aAdDoc.canHaveLikesAndComments() === true"
          :aAdDoc="aAdDoc"
          dataCYClass="InDetailView"
        ></CommentsLikes>
        <div>
          <GeolocationView
            :aLocation="aAdDoc.OADData.ADLocation"
          ></GeolocationView>
        </div>
        <div>
          <OwnerProfileViewLink
            :ownerMiniProfile="aAdDoc.OADData.ADOwnerMiniProfile"
          ></OwnerProfileViewLink>
        </div>
        <div>
          <EnterpriseDisplay
            :displayNoEnterprise="false"
            :aEnterprise="aAdDoc.OADData.ADEnterprise"
          ></EnterpriseDisplay>
        </div>
        <div>
          {{ t("itemPublishedOn") }}
          {{ formatDate(aAdDoc.OADData.ADCreatedOn) }}
        </div>
      </div>
    </template>
  </AdDocumentViewMainPartTemplate>
</template>


<script lang="ts">
import { useI18n } from "@/engineproject/default/common/i18n";
import { inject, reactive, toRefs } from "vue";

import GeolocationView from "@/engineproject/components/Geolocation/GeolocationView.vue";
import ImagesView from "@/engineproject/components/FilesAndImages/ImagesView.vue";
import DefaultImagesView from "@/engineproject/components/FilesAndImages/DefaultImagesView.vue";
import AdDocumentViewMainPartTemplate from "@/engineproject/components/AdDocument/AdDocumentViewMainPartTemplate.vue";
import FormatDate from "@/engineproject/components/UIElements/FormatDate.vue";
import OwnerProfileViewLink from "@/engineproject/components/UserProfile/OwnerProfileViewLink.vue";
import FormatPrice from "@/engineproject/components/UIElements/FormatPrice.vue";
import CommentsLikes from "@/engineproject/components/AdDocument/CommentsLikes.vue";
import EnterpriseDisplay from "@/engineproject/components/Enterprise/EnterpriseDisplay.vue";

export default {
  props: {
    displayInModal: {
      type: Boolean,
      required: true,
    },
  },
  setup(props: any, context: any) {
    const formatDate = inject("formatDate") as any;
    const { t } = useI18n();
    const page = reactive({});

    return {
      ...toRefs(page),
      t,
      formatDate,
    };
  },
  components: {
    GeolocationView,
    ImagesView,
    DefaultImagesView,
    AdDocumentViewMainPartTemplate,
    FormatDate,
    FormatPrice,
    OwnerProfileViewLink,
    CommentsLikes,
    EnterpriseDisplay
},
};
</script>

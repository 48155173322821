<template>
  <div class="dashboardProfilesSearchAndListView">
    <section>
      <FilterProfilesInLine v-on:filterChanged="filterChanged($event)"></FilterProfilesInLine>
    </section>
    <section>
      <div class="col1">
        <ProfilesFilterInCol></ProfilesFilterInCol>
      </div>
      <div class="col2">
        <div v-if="arrayOfProfiles.length">
          <!-- next-previous navigation menu -->
          <div class="profileRow" :class="'postRow'+numberOfPostInRow" v-for="i in calculateNumberOfRows(arrayOfProfiles) " :key="i"
            >
            <div v-for="aUserProfile in getArrayElementsAtPosition(arrayOfProfiles, i)" :key="aUserProfile?.OUPData?.UPUserId" 
              class="post">
                <slot v-if="aUserProfile"
                  name="content"
                  :aUserProfile="aUserProfile"
                ></slot>
            </div>
          </div>
          <!-- next-previous navigation menu -->
          <UserProfileListNav
            v-if="moreElementsInCollection === true"
            :aAdDocSubList="arrayOfProfiles"
            @previousSubList="previousSubList()"
            @nextSubList="nextSubList()"
          ></UserProfileListNav>
        </div>
        <div v-else>
          <p class="noResults" data-cy="cyProfileNotFoundInSearchList">{{ t("noResults") }}</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { toRefs, reactive, computed, watch, onMounted, onBeforeMount } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { useAppStore } from "@/engineproject/store/appStore";
import {useFilterUsersStore} from "@/engineproject/store/filterUsersStore";
import { useUserStore } from "@/engineproject/store/userStore";
import { AdDocumentProjectTypes } from "@/engineproject/default/common/domainModel/adDocumentProjectTypes";

import UserProfileListNav from "@/engineproject/components/UserProfileList/UserProfileListNav.vue";
import ProfilesFilterInCol from "@/engineproject/default/common/components/filters/ProfilesFilterInCol.vue";
import FilterProfilesInLine from "@/engineproject/components/Filter/FilterProfilesInLine.vue";

import { PPConfig } from '@/projects/paypalConfigUsedByDefault';

export default {
  props: {
    profileDisplayType: {
      type: String,
      required: false,
      default: ""
    },
  },
  setup() {
    let reQueryOnMounted = true
    const appStore = useAppStore();
    const filterUsersStore = useFilterUsersStore();
    const userStore = useUserStore();
    const { t } = useI18n();
    const calcNumberOfPostInRow = (ww: number) => (ww > 742) ? 2 : 1
    const page = reactive({
      selectedAdDoc: {} as AdDocumentProjectTypes.AdDocument,
      moreElementsInCollection: computed(() => userStore.state.moreElementsInCollection),
      maxLimitInResultListForProfile:  computed(() => appStore.state.maxLimitInResultListForProfile),
      appDisplayOptions:  computed(() => appStore.state.appDisplayOptions),
      aUserProfile: computed(() => userStore.state.aUserProfile),
      arrayOfProfiles: computed(() => userStore.state.ProfilesArray),
      currentMainFilter: computed(() => filterUsersStore.state.mainFilter),
      currentFilterType: computed(() => filterUsersStore.state.filterType),
      numberOfPostInRow: calcNumberOfPostInRow(appStore.state.windowWidth)
    });

    const premiumPlanIds = PPConfig.planConfigs.map((ele) => {
      return ele.planId
    })
    const currentFilterType2 = computed(() => {return filterUsersStore.state.filterType;});
    const searchLocationFilter = computed(() => filterUsersStore.state.searchLocationFilter)
    const searchDistanceInMFilter = computed(() => filterUsersStore.state.searchDistanceInMFilter)
    const windowWidth = computed(() => appStore.state.windowWidth);

    watch(windowWidth, (newValue, oldValue) => {
      page.numberOfPostInRow = calcNumberOfPostInRow(newValue)
    })

    const calculateNumberOfRows = (arrayOfProfiles: any) => {
      return Math.ceil(arrayOfProfiles.length / page.numberOfPostInRow)
    }

    const getArrayElementsAtPosition = (arrayOfProfiles: any, i: number) => {
      return arrayOfProfiles.slice((i - 1) * page.numberOfPostInRow, i * page.numberOfPostInRow)
    }

    const isLocalFilter = () => {
      return (
        page.currentFilterType !== undefined &&
        page.currentFilterType === "allLocalUsers"
      );
    };

    const getFilterParameters = () => {
      if (page.currentMainFilter !== undefined) {
        // console.error("page.currentMainFilter " + page.currentMainFilter)
        if (isLocalFilter() === true) {
          const nearLatLng = (filterUsersStore.getters.locationFilterLatLng !== undefined) ? 
            filterUsersStore.getters.locationFilterLatLng : userStore.getters.userProfileLatLng
          const nearRadiusInM = filterUsersStore.state.searchDistanceInMFilter
          return {
            currentFilter: page.currentMainFilter,
            currentFilterType: page.currentFilterType,
            premiumPlanIds,
            nearLatLng,
            nearRadiusInM,
          };
        }
        return {
          currentFilter: page.currentMainFilter,
          currentFilterType: page.currentFilterType,
          premiumPlanIds,
          nearLatLng: undefined,
          nearRadiusInM: undefined,
        };
      } else {
        console.error(
          "page.currentMainFilter " + page.currentMainFilter
        );
        return {
          currentFilter: "allAdDocs",
          currentFilterType: page.currentFilterType,
          premiumPlanIds,
          nearLatLng: undefined,
          nearRadiusInM: undefined,
        };
      }
    };

    const nextSubList = async () => {
      // console.error("nextSubList " + JSON.stringify(page.arrayOfProfiles));
      await userStore.dispatch("queryProfiles", { optionsQuery: {
        nextProfile: page.arrayOfProfiles[page.arrayOfProfiles.length - 1],
        previousProfile: undefined,
        ...getFilterParameters(),
        maxLimit: page.maxLimitInResultListForProfile
        }, 
        appStore,
        userStore,
      });
    };

    const reQuery = async () => {
      reQueryOnMounted = false
      userStore.commit("setMoreElementsInCollection", true)
      userStore.commit("setProfiles",  [] );
      await userStore.dispatch("queryProfiles", {optionsQuery: {
        previousProfile: undefined,
        nextProfile: undefined,
        ...getFilterParameters(),
        maxLimit: page.maxLimitInResultListForProfile
        },
        appStore,
        userStore,
      });
    };

    const filterChanged = async () => {
      if (
        isLocalFilter() === true &&
        appStore.state.defaultLocationForLocalFilterAlreadyDisplayed === false && 
        filterUsersStore.getters.locationFilterLatLng === undefined &&
        userStore.getters.userProfileLatLng === undefined
      ) {
        appStore.commit("setDefaultLocationForLocalFilterAlreadyDisplayed", true);
        appStore.commit("setNotifMsg", {
          notifMsg: t("setDefaultLocationForLocalFilter")
        });
      }
      await reQuery();
    };
    watch(searchLocationFilter, (newValue, oldValue) => {
        console.error("searchLocationFilter" + JSON.stringify(newValue));
      filterChanged();
    });
    watch(searchDistanceInMFilter, (newValue, oldValue) => {
        console.error("searchDistanceInMFilter" + JSON.stringify(newValue));
      filterChanged();
    });
    watch(currentFilterType2, (newValue, oldValue) => {
        console.error("currentFilterType2" + JSON.stringify(newValue));
        filterChanged();
      }, { deep: true }
    );
    onBeforeMount(async () => {
      filterUsersStore.commit("setMainFilter", {mainFilterValue:"all"})
      // console.error(" onBeforeMount page.arrayOfProfiles " + page.arrayOfProfiles)
      // TODO length === 0 is not the better test
      // The test should be : do we have already get data from firebase for the current filter?
      // The returned data can be an empty list, and in this case we made again the query but we should not?
      // Except when the user has no item and just come back from the add page and the filter is : "see my items"
      // Not easy to get a smart test here ...
      /*
      if (page.arrayOfProfiles.length === 0) {
        // console.error("  onBeforeMount page.arrayOfProfiles " + page.arrayOfProfiles.length)
        //await reQuery();
      } else {
        // console.error(" onBeforeMount page.arrayOfProfiles " + page.arrayOfProfiles.length)
      }*/
    });
    onMounted(async () => {
      if (page.arrayOfProfiles.length === 0 && reQueryOnMounted === true) {
        await reQuery();
      } else {
        // console.error(" onBeforeMount page.arrayOfProfiles " + page.arrayOfProfiles.length)
      }
    })

    return {
      ...toRefs(page),
      nextSubList,
      calculateNumberOfRows,
      getArrayElementsAtPosition,
      filterChanged,
      t,
    };
  },
  components: { 
      UserProfileListNav,
      ProfilesFilterInCol,
      FilterProfilesInLine,
  },
};
</script>


{
  "itemTitle": "Titre:",
  "itemTitlePH": "Titre ...",
  "itemDescription": "Description:",
  "itemDescriptionPH": "Saisissez la description ici.",
  "yourPrice": "Saisissez le prix",
  "yourPremiumPrice": "Saisissez le tarif préférentiel",
  "percentagePaidByOwner": "Pourcentage pris en charge:",
  "notEnterpriseActivity": "Activité tout public (décocher pour spécifier une entreprise) ",
  "enterpriseActivity": "Activité réservée aux salariés de: ",
  "enterpriseNameTitle": "Entreprise: ",
  "enterpriseNotDefinedTitle": "Aucune entreprise",
  "allOrEnterpriseActivityTitle": "Personnes autorisées: ",
  "subscriptionTitle": "Inscriptions:",
  "freeSubscription": "Libres",
  "moderatedSubscription": "Modérées",
  "freeSubscriptionTitle": "Toutes les inscriptions sont automatiquement acceptées; il n'y a pas de modération des inscriptions par l'organisateur",
  "moderatedSubscriptionTitle": "Les inscriptions sont modérées par l'organisateur",
  "publishStatus": "Annonces Publiée",
  "openToInvit": "Autoriser la promotion via des invitations automatiques",
  "suggestionStatus": "Suggestion d'annonce",
  "suggestionVisibleStatus": "Suggestion Visible",
  "saveOnlyTitle": "Sauvegarder votre annonce sans la publier sur le réseau.",
  "saveOnly": "Sauvegarder dans votre bibliothèque",
  "saveOnlySuggestion": "Sauvegarder cette suggestion dans votre bibliothèque",
  "saveOnlySuggestionTitle": "Sauvegarder cette suggestion dans votre bibliothèque sans la rendre visible comme suggestion",
  "saveVisibleSuggestion": "Sauvegarder cette suggestion",
  "saveVisibleSuggestionTitle": "Sauvegarder et rendre visible cette suggestion",
  "saveAndPublishTitle": "Sauvegarder et Publier votre annonce sur le réseau.",
  "saveAndPublish": "Sauvegarder et Publier ",
  "templatePublicStatus": "Modèle public visible dans les idées de modèles",
  "saveOnlyTemplate": "Sauvegarder ce modèle dans votre bibliothèque",
  "saveOnlyTemplateTitle": "Sauvegarder ce modèle dans votre bibliothèque sans la rendre visible comme idée de modèle",
  "saveAndPublishTemplate": "Sauvegarder et publier ce modèle",
  "saveAndPublishTemplateTitle": "Sauvegarder ce modèle et le rendre visible comme idée de modèle",
  "templateOnlyForPremium": "Disponible uniquement pour les comptes Premium",
  "visibleOnlyForAdmin": "Disponible uniquement pour les administrateurs",
  "fromSuggestionPartnerText0": "L'activité va être validée par le partenaire. Vous serez prévenu par e-mail quand ce sera le cas.",
  "fromSuggestionPartnerText1": "Vous pouvez aussi réserver directement vos places chez notre partenaire:",
  "fromSuggestionPartnerText2": "Des promotions sont réservées aux utilisateurs sous certaines conditions.",
  "fromSuggestionPartnerTitleOnPhone": "Appeler le ",
  "fromSuggestionPartnerConfirmed": "Activité validée par le partenaire:",
  
  "adDocCreateTabDateTitle": "Date et places disponibles",
  "adDocCreateTabImagesTitle": "Images",
  "adDocCreateTabIntentionTitle": "Intention",
  "adDocCreateTabTitleAndDescTitle": "Titre et descriptif",
  "adDocCreateTabGeolocalisationTitle": "Lieu",
  "adDocCreateTabUniversTitle": "Type",
  "adDocPriceAndPercentTabTitle": "Conditions générales",
  "adDocCreateTabSavePublishAndQuitTitle": "Enregistrer et publier",
  "adDocCreateTabTemplateTitle": "Utiliser des modèles",

  "adDocInitialysedUsingModel": "Votre annonce a été initalisée en utilisant le modèle. Précisez la date pour terminer.",

  "adDocDateHourStartsLabel": "Début:",
  "adDocDateHourEndsLabel": "Fin:",
 
  "adDocUpdated": "Document mis à jour",
  "adDocRemoved": "Annonce supprimée",
  "adDocAdded": "Annonce ajoutée",
  "adDocSaved": "Annonce sauvegardée"
  
}
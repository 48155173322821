<template>
  <div v-if="ADColLikes!== undefined && ADColLikes.length > 0" data-cy="cyLikeLoaded">
    <div class="subtitle">{{ t("itemLikedBy") }}</div>
    <div v-for="like in ADColLikes" :key="like.ALId" class="likes">
      <p>
        <a @click="viewUserProfile(like.ALLUserId)"
          :title="t('openProfile')"
          >{{ like.ALLPseudo }}
        </a>
      </p>
    </div>
  </div>
  <div v-else>
    <div v-if="aAdDoc.getLikesIsLoading() === true " data-cy="cyLikesLoading">
      {{ t('loadingLikes')}}
    </div>
    <div v-else  data-cy="cyLikeLoaded">
    </div>
  </div>
</template>

<script lang="ts">

import { reactive, toRefs } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";
import { useAppStore } from '@/engineproject/store/appStore';
import { AdDocumentProjectTypes } from '@/engineproject/default/common/domainModel/adDocumentProjectTypes';

export default {
  props: {
    aAdDoc: {
      type: Object as () => AdDocumentProjectTypes.AdDocument,
      required: true,
    },
  },
  setup(props: any, context: any) {
    const appStore = useAppStore();
    const {t} = useI18n()
    const page = reactive({
      ADColLikes: props.aAdDoc.OADData.ADColLikes
    });

    const viewUserProfile = (aUserId: string) => {
      appStore.commit("setInModalUserProfileId", {inModalUserProfileId: aUserId});
    }

    return {
      ...toRefs(page),
      t,
      viewUserProfile
    };
  },
  components: { 
  },
};
</script>

import { AdDocAPIClient } from "@/engineproject/backend/AdDocFirebase";
import { AdDocInvitationsAPIClient } from "@/engineproject/backend/AdDocInvitationsFirebase";
import { AdDocTransactionsAPIClient } from "@/engineproject/backend/AdDocTransactionsFirebase";
import { AppServerConfigAPIClient } from "@/engineproject/backend/AppServerConfigFirebase";
import { UserAPIClient } from "@/engineproject/backend/UserFirebase";
import { EnterpriseAPIClient } from "@/engineproject/backend/EnterpriseFirebase";
import { adDocumentControler } from "./adDocumentControler";
import { adDocumentInvitationsControler } from "./adDocumentInvitationsControler";
import { adDocumentTransactionsControler } from "./adDocumentTransactionsControler";
import { appServerConfigControler } from "./appServerConfigControler";
import { userProfileControler } from "./userProfileControler";
import { enterpriseInfoControler } from "./enterpriseControler";

export const setAPI = (aDocAPI: AdDocAPIClient, aUserAPI: UserAPIClient, aAppServerConfigAPIClient: AppServerConfigAPIClient,
    aDocInvitationsAPI: AdDocInvitationsAPIClient, aDocTransactionsAPI: AdDocTransactionsAPIClient,
    aEnterpriseAPI: EnterpriseAPIClient) => {
    userProfileControler.setAPI(aDocAPI, aDocInvitationsAPI, aDocTransactionsAPI, aUserAPI)
    appServerConfigControler.setAPI(aAppServerConfigAPIClient)
    adDocumentControler.setAPI(aDocAPI, aUserAPI)
    adDocumentInvitationsControler.setAPI(aDocAPI, aDocInvitationsAPI)
    adDocumentTransactionsControler.setAPI(aDocAPI, aDocTransactionsAPI)
    enterpriseInfoControler.setAPI(aEnterpriseAPI)
}
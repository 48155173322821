<template>
  <FilterProfilesTemplate>
    <template #filterContent="{}">
      <FilterMyContacts></FilterMyContacts>
      <FilterProfilesGeolocalisation></FilterProfilesGeolocalisation>
    </template>
  </FilterProfilesTemplate>
</template>

<script lang="ts">
import {  reactive, toRefs, computed } from "vue";
import { useFilterAttributesStore } from "@/engineproject/store/filterAttributesStore";

import FilterProfilesGeolocalisation from '@/engineproject/components/Filter/FilterProfilesGeolocalisation.vue';
import FilterProfilesTemplate from '@/engineproject/components/Filter/FilterProfilesTemplate.vue';

export default {
  setup(props: any, context: any) {
    const filterAttributesStore = useFilterAttributesStore()
    const page = reactive({
      currentThemeFilters: computed(() => filterAttributesStore.getters.getAllFiltersForCurrentTheme),
    });
    return {
      ...toRefs(page),
    }
  },
  components: {
    FilterProfilesTemplate,
    FilterProfilesGeolocalisation,
  },
}


</script>

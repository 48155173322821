import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "appLoading" }
const _hoisted_2 = { class: "loginLink" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, _toDisplayString($setup.t('appLoginText')), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => {$setup.logout();})
        }, _toDisplayString($setup.t("loginLink")), 1)
      ])
    ])
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "partnerPromotionViewButtons" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]
const _hoisted_5 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString($setup.t($props.posterElementId + 'Title')), 1),
    _createElementVNode("span", {
      class: "pi pi-eye",
      title: $setup.t('previewButton'),
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.vueToHtml($props.posterElementId + 'Id', true)))
    }, null, 8, _hoisted_2),
    _createElementVNode("span", {
      class: "pi pi-print",
      title: $setup.t('printButton'),
      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.vueToHtml($props.posterElementId + 'Id')))
    }, null, 8, _hoisted_3),
    ($props.expandState===false)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:expandState', !$props.expandState))),
          class: "pi pi-sliders-v",
          title: $setup.t('expandButton')
        }, null, 8, _hoisted_4))
      : ($props.expandState===true)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:expandState', !$props.expandState))),
            class: "pi pi-chevron-up",
            title: $setup.t('reduceButton')
          }, null, 8, _hoisted_5))
        : _createCommentVNode("", true)
  ]))
}
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href", "title"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["href", "title"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.location !== null && _ctx.location !== undefined && _ctx.location.LPLat !== undefined && _ctx.location.LPLng !== undefined)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
          target: "_blank",
          href: 'https://www.google.com/maps/search/?api=1&query=' + _ctx.location.LPLat +
        ',' + _ctx.location.LPLng,
          title: 'lat/lng: ' + _ctx.location.LPLat +
        ' - ' + _ctx.location.LPLng +
        ' - ' + _ctx.location.LPGeoHash
        }, [
          (!_ctx.location.LPAddress && !_ctx.location.LPZipCode)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($setup.t("openLocationInMap")), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($setup.t("openLocationInMapShort")), 1)),
          (_ctx.location.LPAddress)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.location.LPAddress), 1))
            : _createCommentVNode("", true),
          (_ctx.location.LPZipCode)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, " " + _toDisplayString(_ctx.location.LPZipCode), 1))
            : _createCommentVNode("", true),
          (_ctx.location.LPCity)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, " " + _toDisplayString(_ctx.location.LPCity), 1))
            : _createCommentVNode("", true)
        ], 8, _hoisted_2)
      ]))
    : (_ctx.location !== null && _ctx.location !== undefined && _ctx.location.LPZipCode !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("a", {
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
            target: "_blank",
            href: 'https://maps.google.com/maps?q=France+' + _ctx.location.LPZipCode,
            title: 'Code Postal: ' + _ctx.location.LPZipCode
          }, [
            _createTextVNode(_toDisplayString($setup.t("openZipCodeLocationInMap", {aZipCode: _ctx.location.LPZipCode})) + " ", 1),
            (_ctx.location.LPCity)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, " " + _toDisplayString(_ctx.location.LPCity), 1))
              : _createCommentVNode("", true),
            (_ctx.location.LPAddress)
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, " " + _toDisplayString(_ctx.location.LPAddress), 1))
              : _createCommentVNode("", true)
          ], 8, _hoisted_9)
        ]))
      : _createCommentVNode("", true)
}
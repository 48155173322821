
import {appSandbox} from "./sandbox/app";
import {appStaging} from "./staging/app";
import {appProduction} from "./production/app";

// console.error("process.env.VUE_APP_CONFIG_ENV ;" +
// process.env.VUE_APP_CONFIG_ENV);
const appConfig = (configEnv: string | undefined) => {
  return (configEnv === "ProductionConfigEnv") ?
  appProduction : ((configEnv === "StagingConfigEnv") ?
  appStaging : appSandbox);
};
export {
  appConfig,
};

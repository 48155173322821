const firebaseProjectConfigSandbox = {
  apiKey: "AIzaSyCn4DVBn0y971Dhsno25WfaX17fmlR_aqk",
  authDomain: "alomigoid.firebaseapp.com",
  projectId: "alomigoid",
  storageBucket: "alomigoid.appspot.com",
  messagingSenderId: "558631565850",
  appId: "1:558631565850:web:6e03208fe989c35adb6fa3",
  measurementId: "G-GBSK8JJ877",
  
  webAppClientIdForGoogleAPI: "558631565850-pouuclqjmm48pumactgf6vg581f83lhg.apps.googleusercontent.com",

  storageBucketBaseUrl: "https://firebasestorage.googleapis.com/v0/b/",
  storageBucketSuffix: "/o/",
};

export {
  firebaseProjectConfigSandbox
}
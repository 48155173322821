import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectAdDocumentView = _resolveComponent("ProjectAdDocumentView")!
  const _component_CommentModal = _resolveComponent("CommentModal")!
  const _component_ReportModal = _resolveComponent("ReportModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showAdDocModal)
      ? (_openBlock(), _createBlock(_component_ProjectAdDocumentView, {
          key: 0,
          aAdDoc: _ctx.selectedAdDoc,
          userIsOwner: _ctx.userIsOwner,
          userCanEdit: _ctx.userCanEdit,
          displayInModal: true,
          onCloseAdDocument: _cache[0] || (_cache[0] = ($event: any) => ($setup.closeAdDocModal()))
        }, null, 8, ["aAdDoc", "userIsOwner", "userCanEdit"]))
      : _createCommentVNode("", true),
    (_ctx.showCommentModal)
      ? (_openBlock(), _createBlock(_component_CommentModal, {
          key: 1,
          aAdDoc: _ctx.selectedAdDoc,
          onClose: _cache[1] || (_cache[1] = ($event: any) => ($setup.closeCommentModal($event)))
        }, null, 8, ["aAdDoc"]))
      : _createCommentVNode("", true),
    (_ctx.showReportModal)
      ? (_openBlock(), _createBlock(_component_ReportModal, {
          key: 2,
          aAdDoc: _ctx.selectedAdDoc,
          onClose: _cache[2] || (_cache[2] = ($event: any) => ($setup.closeReportModal($event)))
        }, null, 8, ["aAdDoc"]))
      : _createCommentVNode("", true)
  ], 64))
}

import { defUrls } from "./defaultUrlForEachUnivers"
import { mapToObjectId, getDefaultUrlForUnivers } from "@/engineproject/store/filterAttributesTools"


const allLocalMyFilter = {
  filterName: "allLocalMyFilter",
  filterValues: mapToObjectId(["allLocalAdDocs", "allAdDocs", "myAdDocs"/*,"soonAvalaibleAdDocs"*/]),
  filterSelected: "allLocalAdDocs",
  filterDataNameInDoc: ""
  // ProductLocationOrOwner ProductTheme ProductSaleType - ProductCategories - ProductAgeSize - ProductFormat - 
  // ProductState - ProductDistributionMode

}

export const intentionFilter = {
  filterName: "intentionFilter",
  filterValues: mapToObjectId(["intNoIntention", "intMakeFriends", "intDating"]),
  filterSelected: "intNoIntention",
  filterDataNameInDoc: "ADIntention"
}

export const mixedFilter = {
  filterName: "mixedFilter",
  filterValues: mapToObjectId(["mixedOn", "mixedOnlyGirls", "mixedOnlyBoys"]),
  filterSelected: "mixedOn",
  filterDataNameInDoc: "ADMixed"
}

const universFilter = {
  filterName: "universFilter",
  filterValues: mapToObjectId([ "uniSocial", "uniSport", "uniWelfare", "uniEntertainment", "uniTravel", "uniProf","uniOther"]),
  filterSelected: "uniSport",
  filterDataNameInDoc: "ADUnivers"
}

/*
const catFoodRestaurant = {
  filterName: "catFoodRestaurant",
  filterValues: mapToObjectId(["Indian", "Burger", "Japanese", "Chinese", "Thai", 
  "Korean", "Bistronomy", "SeaFood", "Starry", "Vegetarian", "Organic", "Healthy", "OtherFoodRestaurant"]),
  filterSelected: undefined,
  filterDataNameInDoc:"ADCatsInUnivers"
}
*/

const catDrink = {
  filterName: "catDrink",
  filterValues: mapToObjectId(["Restaurant", "Bar", "Cafe", "Nightclub", "OtherDrink"]),
  filterSelected: undefined,
  filterDataNameInDoc: "ADCatsInUnivers"
}

const catEntertainment = {
  filterName: "catEntertainment",
  filterValues: mapToObjectId(["EscapeGame", "Bowling", "AxeThrowing", "OtherEntertainment"]),
  filterSelected: undefined,
  filterDataNameInDoc: "ADCatsInUnivers"
}

const catCulture = {
  filterName: "catCulture",
  filterValues: mapToObjectId(["Cinema", "Theater", "Museum", "TouristicSite", "OtherCulture"]),
  filterSelected: undefined,
  filterDataNameInDoc: "ADCatsInUnivers"
}

const catsFood = [
  // catFoodRestaurant,
  catDrink,
  catEntertainment,
  catCulture
]

const catsSport1 = {
  filterName: "catSportExt",
  filterValues: mapToObjectId(["Rando", "VTT", "DefiPodometre", "OtherSportExt"]),
  filterSelected: undefined,
  filterDataNameInDoc: "ADCatsInUnivers"
}

const catsSport2 = {
  filterName: "catSportInt",
  filterValues: mapToObjectId(["FootEnSalle", "Squash", "Crossfit", "OtherSportInt"]),
  filterSelected: undefined,
  filterDataNameInDoc: "ADCatsInUnivers"
}

const catsSport = [
  catsSport1,
  catsSport2,
]

const catsWelfare1 = {
  filterName: "catWelfare",
  filterValues: mapToObjectId(["Yoga", "Nutrition", "Osteopathy", "Relaxation", "Sophrology", "OtherWelfare"]),
  filterSelected: undefined,
  filterDataNameInDoc: "ADCatsInUnivers"
}

const catsWelfare = [
  catsWelfare1
]


const catsSocial1 = {
  filterName: "catSocialRepas",
  filterValues: mapToObjectId(["PetitDejeunerSolidaire", "CafeSolidaire", "OtherSocialRepas"]),
  filterSelected: undefined,
  filterDataNameInDoc: "ADCatsInUnivers"
}

const catsSocial2 = {
  filterName: "catTempsSolidaire",
  filterValues: mapToObjectId(["Plogging", "Maraude", "DistributionAlimentaire",
    "DistributionFournitureScolaire", "OtherTempsSolidaire"]),
  filterSelected: undefined,
  filterDataNameInDoc: "ADCatsInUnivers"
}

const catsSocial = [
  catsSocial1,
  catsSocial2,
]

const catsProf1 = {
  filterName: "catProf",
  filterValues: mapToObjectId(["CarsharingInUniProf", "RecruitmentInUniProf", "NetworkingInUniProf", "OtherInUniProf"]),
  filterSelected: undefined,
  filterDataNameInDoc: "ADCatsInUnivers"
}


const catsProf = [
  catsProf1,
]

const catsOther1 = {
  filterName: "catOther",
  filterValues: mapToObjectId(["OtherInUniOther"]),
  filterSelected: undefined,
  filterDataNameInDoc: "ADCatsInUnivers"
}

const catsOther = [
  catsOther1,
]

const catsTravel1 = {
  filterName: "catTravel1",
  filterValues: mapToObjectId(["GroupTravel", "SportTravel",
    "FamilyAndSportTravel", "OtherTravel1"]),
  filterSelected: undefined,
  filterDataNameInDoc: "ADCatsInUnivers"
}

const catsTravel = [
  catsTravel1,
]

const filtersForEachTheme = {
} as any;

filtersForEachTheme["uniSport"] = [
  ...catsSport
]

filtersForEachTheme["uniWelfare"] = [
  ...catsWelfare
]

filtersForEachTheme["uniTravel"] = [
  ...catsTravel
]

filtersForEachTheme["uniEntertainment"] = [
  ...catsFood
]

filtersForEachTheme["uniProf"] = [
  ...catsProf
]

filtersForEachTheme["uniSocial"] = [
  ...catsSocial
]

filtersForEachTheme["uniOther"] = [
  ...catsOther
]

const allFiltersConfig = [
  allLocalMyFilter,
  intentionFilter,
  mixedFilter,
  universFilter,
  ...catsFood,
  ...catsWelfare,
  ...catsSport,
  ...catsProf,
  ...catsSocial,
  ...catsTravel,
  ...catsOther,
]


const mandatoryDataForEachTheme = {
} as any;

mandatoryDataForEachTheme["uniSport"] = [
  "ADCatsInUnivers",
]
mandatoryDataForEachTheme["uniWelfare"] = [
  "ADCatsInUnivers",
]
mandatoryDataForEachTheme["uniTravel"] = [
  "ADCatsInUnivers",
]
mandatoryDataForEachTheme["uniEntertainment"] = [
  "ADCatsInUnivers",
]
mandatoryDataForEachTheme["uniProf"] = [
  "ADCatsInUnivers",
]
mandatoryDataForEachTheme["uniSocial"] = [
  "ADCatsInUnivers",
]
mandatoryDataForEachTheme["uniOther"] = [
  "ADCatsInUnivers",
]

export {
  allFiltersConfig,
  filtersForEachTheme,
  mandatoryDataForEachTheme,
  defUrls,
  getDefaultUrlForUnivers
}
//import * as firebase from 'firebase/app'
//import firebase from 'firebase/app';
//import * as firebase from "firebase/app";
/*
import firebase from '@firebase/app';
import 'firebase/auth'
import 'firebase/firestore'
*/
import {firebaseProjectConfig} from "@/engineproject/default/common/envConfigs/firebaseProject"
import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import AdDocFirebase from './AdDocFirebase'
import UserFirebase from './UserFirebase'
import EnterpriseFirebase from './EnterpriseFirebase'
import FirebaseStorage from './FirebaseStorage'
import FirebaseAnalytics from './FirebaseAnalytics'
import ZipcodeFirebase from './ZipcodeFirebase'
import AppServerConfigFirebase from "./AppServerConfigFirebase"
import { setAPI } from "../domainModel/controlers"
import AdDocInvitationsFirebase from "./AdDocInvitationsFirebase"
import AdDocTransactionsFirebase from "./AdDocTransactionsFirebase"


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = firebaseProjectConfig;

app.initializeApp(firebaseConfig)
if (firebaseConfig.measurementId && firebaseConfig.measurementId !== "") {
  // console.error("analytics started with " + firebaseConfig.measurementId)
  // TODO Remove it for the moment 
  // app.analytics();
}


// utils
const db = app.firestore()
const auth = app.auth()

// Generate a random Firebase location
//const firebaseRef = app.database().ref().push();

  // Create a new GeoFire instance at the random Firebase location
//  const geofire = new geofire.GeoFire(firebaseRef);
//const geoFireApp = new GeoFire(firebaseRef);
//const geoFireApp = geofirex.init(app); // <-- admin not admin.app()

// collection references

const appServerConfigCollection = db.collection('appServerConfig')
const usersPublicCollection = db.collection('usersPublic')
const usersPrivateCollection = db.collection('usersPrivate')
const adminUsersCollection = db.collection('adminUsers')
const adDocCollection = db.collection('adDocuments')
const adSugCollection = db.collection('adSuggestions')
const adTemCollection = db.collection('adTemplates')
const commentsCollection = db.collection('comments')
const likesCollection = db.collection('likes')
const enterpriseInfoCollection = db.collection('enterpriseInfo')


const adDocFirebase = new AdDocFirebase()
const adDocInvitationsFirebase = new AdDocInvitationsFirebase()
const adDocTransactionsFirebase = new AdDocTransactionsFirebase()
const appServerConfigFirebase = new AppServerConfigFirebase()
const userFirebase = new UserFirebase()
const enterpriseFirebase = new EnterpriseFirebase()
const firebaseStorage = new FirebaseStorage()
const firebaseAnalytics = new FirebaseAnalytics()
const zipcodeFirebase = new ZipcodeFirebase()

setAPI(adDocFirebase, userFirebase, appServerConfigFirebase, adDocInvitationsFirebase, adDocTransactionsFirebase, enterpriseFirebase)

// export utils/refs
export {
  db,
  auth,
  appServerConfigCollection,
  usersPublicCollection,
  usersPrivateCollection,
  adminUsersCollection,
  adDocCollection,
  adSugCollection,
  adTemCollection,
  enterpriseInfoCollection,
  adDocFirebase,
  adDocInvitationsFirebase,
  adDocTransactionsFirebase,
  appServerConfigFirebase,
  userFirebase,
  enterpriseFirebase,
  firebaseStorage,
  firebaseAnalytics,
  commentsCollection,
  likesCollection,
  zipcodeFirebase,
//  geoFireApp
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-cy"]
const _hoisted_2 = { "data-cy": "cyNumericValue" }
const _hoisted_3 = ["data-cy"]
const _hoisted_4 = { "data-cy": "cyNumericValue" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("a", {
        "data-cy": 'cyItemComments' + $props.dataCYClass,
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.openCommentModal($props.aAdDoc)), ["stop"]))
      }, [
        _createTextVNode(_toDisplayString($setup.t("itemComments")) + " ", 1),
        _createElementVNode("span", _hoisted_2, _toDisplayString($props.aAdDoc.OADData.ADTotComments), 1)
      ], 8, _hoisted_1)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("a", {
        "data-cy": 'cyItemLikes' + $props.dataCYClass,
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.likeAdDoc($props.aAdDoc)), ["stop"]))
      }, [
        _createTextVNode(_toDisplayString($setup.t("itemLikes")) + " ", 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString($props.aAdDoc.OADData.ADTotLikes), 1)
      ], 8, _hoisted_3)
    ])
  ], 64))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "profileListViewItem" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InvitationProfilesListViewButtons = _resolveComponent("InvitationProfilesListViewButtons")!
  const _component_ProfileDataInProfilesListView = _resolveComponent("ProfileDataInProfilesListView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InvitationProfilesListViewButtons, {
      aUserProfile: $props.aUserProfile,
      aAdDoc: $props.aAdDoc
    }, null, 8, ["aUserProfile", "aAdDoc"]),
    _createVNode(_component_ProfileDataInProfilesListView, { aUserProfile: $props.aUserProfile }, null, 8, ["aUserProfile"])
  ]))
}
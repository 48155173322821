<template>
    <div class="listNavBottomMenu">
      <a @click="$emit('nextSubList')" :title="t('moreLinkTitle')">{{ t("moreLink") }}</a>
    </div>
</template>

<script lang="ts">
import { useI18n } from '@/engineproject/default/common/i18n';

export default {
  props: {
      aAdDocSubList: {
        type: Object as () => Array<any>,
        required: true
      },
  },
  emits: ["nextSubList"],
    setup(props: any, context: any) {
      const {t} = useI18n()
      return { t}
    },
}
</script>

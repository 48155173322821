import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterMyContacts = _resolveComponent("FilterMyContacts")!
  const _component_FilterProfilesGeolocalisation = _resolveComponent("FilterProfilesGeolocalisation")!
  const _component_FilterProfilesTemplate = _resolveComponent("FilterProfilesTemplate")!

  return (_openBlock(), _createBlock(_component_FilterProfilesTemplate, null, {
    filterContent: _withCtx(({}) => [
      _createVNode(_component_FilterMyContacts),
      _createVNode(_component_FilterProfilesGeolocalisation)
    ]),
    _: 1
  }))
}
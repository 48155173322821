import moment from 'moment';
import { useI18n } from "@/engineproject/default/common/i18n";
import { computed } from 'vue';

export default {
  install: (app: any, options: any) => {

    const formatDate = (val: any, options: any) => {
      const { locale } = useI18n();
      const currentLocale = computed(() => locale.value).value;
      // console.error("currentLocale " + currentLocale)
      moment.locale((currentLocale !== undefined) ? currentLocale : "fr")
      // console.error("formatDate " + JSON.stringify(val))
      if (!val) { return '-' }
      let date = val
      if (val.toDate !== undefined) {
        date = val.toDate()
      }
      const diff = moment(date)
      if (options === undefined) {
        const diffMoment = diff.fromNow()
        return diffMoment.charAt(0).toUpperCase() + diffMoment.slice(1) + ".";
        // return moment(date).fromNow()
      } else if (options.noUpperCase === true) {
        const diffMoment = diff.fromNow()
        return diffMoment + ".";
        // return moment(date).fromNow()
      }
      return date + "  " + diff.hours() + "-" + diff.minutes()
      /*
      const rtf = new Intl.RelativeTimeFormat("en", {
        localeMatcher: "best fit", // autre valeur possible : "lookup"
        numeric: "always", // autre valeur possible : "auto"
        style: "long", // autre valeur possible : "short" ou "narrow"
      });
      const date1 = new Date(date) as any
      const dateNow =new Date() as any
      const diff = dateNow - date1
      return rtf.format(diff, "day");
      */
    }

    app.provide("formatDate", formatDate);
  },
};

<template>
  <div class="serverConfigState">
    <div v-if="appServerConfigIsOk === true">
    </div>
    <div v-else-if="appServerConfigState !== undefined"  :class="'serverConfigState' + appServerConfigState">
      {{ t('serverConfigState' + appServerConfigState) }}
    </div>
  </div>
</template>

<script lang="ts">
import { toRefs, reactive, computed, watch, onMounted } from "vue";
import { useAppStore } from "@/engineproject/store/appStore";
import { useI18n } from '@/projects/alomigo/common/i18n';

export default {
  setup() {
    const appStore = useAppStore();
    const { t  } = useI18n()
    const page = reactive({
      appServerConfigIsOk: computed(() => appStore.state.aAppServerConfig.serverIsOk()),
      appServerConfigState: computed(() => appStore.state.aAppServerConfig.getServerState()),
    });

    return {
      ...toRefs(page),
      t,
    };
  },
  components: { 
  },
};
</script>

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00131c9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.aEnterprise)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createTextVNode(_toDisplayString($setup.t('enterpriseNameTitle')) + " ", 1),
          _createElementVNode("span", null, _toDisplayString($props.aEnterprise.EIName), 1),
          _createTextVNode(" ("),
          _createElementVNode("span", null, _toDisplayString($props.aEnterprise.EISearchId), 1),
          _createTextVNode(") ")
        ])
      ]))
    : ($props.displayNoEnterprise)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.t('enterpriseNotDefinedTitle')), 1))
      : _createCommentVNode("", true)
}
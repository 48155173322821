<template>
  <div v-if="userCanEdit === true" :class="userIsOwner === false ? 'adminSuperPower' : ''"
    class="transactionArea">
    <div v-if="ADColTransactions !== undefined && ADColTransactions.length" data-cy="cyTransactionsLoaded">
      <AdDocViewTransactionsAcceptRefuseButtons :aAdDoc="aAdDoc" :ADColTransactions="ADColTransactions">
      </AdDocViewTransactionsAcceptRefuseButtons>
    </div>
    <div v-else>
      <AdDocViewTransactionsEmpty :aAdDoc="aAdDoc"></AdDocViewTransactionsEmpty>
    </div>
  </div>
  <div v-else class="transactionArea">
    <div v-if="ADColTransactions !== undefined && ADColTransactions.length" data-cy="cyTransactionsLoaded">
      <AdDocViewTransactionsRemoveButton :aAdDoc="aAdDoc" :ADColTransactions="ADColTransactions">
      </AdDocViewTransactionsRemoveButton>
    </div>
    <div v-else>
      <AdDocViewTransactionsAddButton :aAdDoc="aAdDoc"></AdDocViewTransactionsAddButton>
    </div>
  </div>
</template>

<script lang="ts">

import AdDocViewTransactionsAcceptRefuseButtons from '@/engineproject/components/AdDocTransaction/AdDocViewTransactionsAcceptRefuseButtons.vue';
import AdDocViewTransactionsRemoveButton from '@/engineproject/components/AdDocTransaction/AdDocViewTransactionsRemoveButton.vue';
import AdDocViewTransactionsAddButton from '@/engineproject/components/AdDocTransaction/AdDocViewTransactionsAddButton.vue';
import AdDocViewTransactionsEmpty from '@/engineproject/components/AdDocTransaction/AdDocViewTransactionsEmpty.vue';

export default {
  props: {
    ADColTransactions: {
      type: Object as () => Array<any>,
      required: false,
    },
    aAdDoc: {
      type: Object as () => Array<any>,
      required: true,
    },
    userIsOwner: {
      type: Boolean,
      required: true,
    },
    userCanEdit: {
      type: Boolean,
      required: true,
    },
  },
  setup(props: any, context: any) {

    return {
    };
  },
  components: { 
    AdDocViewTransactionsAcceptRefuseButtons,
    AdDocViewTransactionsRemoveButton,
    AdDocViewTransactionsAddButton,
    AdDocViewTransactionsEmpty,
  },
};
</script>

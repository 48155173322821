import { appConfig } from "@/engineproject/default/common/commonWithBackend/config/app"
import { AppServerConfigAPIClient } from "../../backend/AppServerConfigFirebase";

export class AppServerConfigControler  {
  appServerConfigBackendAPI?: AppServerConfigAPIClient;
  setAPI(aAppServerConfigAPIClient: AppServerConfigAPIClient) {
    this.appServerConfigBackendAPI = aAppServerConfigAPIClient
  }

  async getAppServerConfig( userPrivateProfileDocObserverReceived: any) {
    const rForGetgetAppServerConfig =  await this.appServerConfigBackendAPI?.getAppServerConfig(userPrivateProfileDocObserverReceived)
    return {
      rForGetgetAppServerConfig
    }
  }

}


export const appServerConfigControler = new AppServerConfigControler()



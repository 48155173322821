<template>
  <div class=" inlineSeparatedBlockContainer adminSuperPower">
    <div v-if="loggedUserProfile.isAdmin() === true" class="" >
      <div>
        {{ t('enterpriseExternTitle')}}
      </div>
      <select  @change="updateEnterprise($event)" >
        <option v-for="(aEnterpriseInfo, index) in arrayOfEnterpriseInfo" :key="index"
            :selected="aUserProfile.sameEnterprise(aEnterpriseInfo)"
            :value="index">{{ aEnterpriseInfo.getEIName() }} - {{ aEnterpriseInfo.getEISearchId() }}
        </option>
      </select>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, reactive, toRefs, onMounted, watch } from "vue";
import { useI18n } from "@/engineproject/default/common/i18n";

import { UserProjectTypes } from '@/engineproject/default/common/domainModel/userProjectTypes';
import { UserProfileProjectObject } from '@/engineproject/default/common/domainModel/userProfileProjectToolsTypes';
import { useUserStore } from '@/engineproject/store/userStore';
import { useAppStore } from '@/engineproject/store/appStore';
import { useEnterpriseStore } from "@/engineproject/store/enterpriseStore";


import InputSwitch from 'primevue/inputswitch';
import { EnterpriseInfoTypes } from "@/engineproject/domainModel/enterpriseInfoTypes";
import { EnterpriseInfoProjectObject } from "@/projects/alomigo/common/domainModel/enterpriseInfoProjectToolsTypes";

export default {
  props: {
    aUserProfile: {
      type: Object as () => UserProfileProjectObject,
      required: true
    },
  },
  emits: ["banUser"],
  setup(props: any, context: any) {
    const {t} = useI18n()
    const appStore = useAppStore();
    const userStore = useUserStore();
    const enterpriseStore = useEnterpriseStore();
    const emptyEnterprise = new EnterpriseInfoProjectObject({
      EIEnterpriseId: "",
      EIName: t('withoutEnterprise'),
      EISearchId: ""
    })

    const page = reactive({
      aUserProfile: computed(() => props.aUserProfile),
      allowedEnterprises: computed(() => props.aUserProfile.getUPColAllowedEnterprises()),
      arrayOfEnterpriseInfo: computed(() => [
        emptyEnterprise,
        ...enterpriseStore.state.allEnterprises]),
      loggedUserProfile: computed(() => userStore.state.aUserProfile),
    });


    const queryAllowed = async ()  => {
      const result = await userStore.dispatch("resetAllowedEnterprisesForUser", {
          aUserProfile: props.aUserProfile,
          appStore,
        });

    }

    const isAllowedEnterprise = (aEnterprise: EnterpriseInfoProjectObject) => {
     const res =  undefined !== page.allowedEnterprises.find((entr: EnterpriseInfoProjectObject) => {
        return entr.getEIEnterpriseId() === aEnterprise.getEIEnterpriseId()
      })
      return res
    }

    onMounted(async () => {
        await queryAllowed();
    })
    const userProfileId = computed(() => props.aUserProfile.getUPUserId())

    watch(userProfileId, (newValue, oldValue) => {
      if (newValue !== oldValue)
       queryAllowed();
    });

    const updateUserEnterprise = async (newEnterprise: EnterpriseInfoTypes.EnterpriseInfo) => {
        const updateResult = await userStore.dispatch("setExternEnterpriseToUser", {
          newEnterprise: (newEnterprise.EIEnterpriseId === '') ? undefined : newEnterprise,
          aUserProfile: props.aUserProfile,
          appStore: appStore,
          notifMsg: "profileUpdatedEnterprisePart"
        });
      }

    const updateEnterprise = async (event: any) => {
        updateUserEnterprise(page.arrayOfEnterpriseInfo[event.target.value].getMiniEnterprise())
    }

    return {
      ...toRefs(page),
      isAllowedEnterprise,
      updateEnterprise,
      t,
    }
  },
  components: {
  },
}


</script>

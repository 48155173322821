import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "country-item country-item-value"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "country-item" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "titleInOptions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dropdown, {
      class: "countrySelect",
      modelValue: $setup.locale,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.locale) = $event)),
      options: _ctx.langsFlag,
      optionValue: "language",
      optionLabel: "title"
    }, {
      value: _withCtx((slotProps) => [
        (slotProps.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("img", {
                class: "flag flag-au",
                src: $setup.getFlagFromValue(slotProps.value)
              }, null, 8, _hoisted_2)
            ]))
          : _createCommentVNode("", true)
      ]),
      option: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            class: "flag flag-au",
            src: slotProps.option.flagImg
          }, null, 8, _hoisted_4),
          _createElementVNode("span", _hoisted_5, _toDisplayString(slotProps.option.title), 1)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "options"])
  ]))
}

export const daysBetweenJanurary1st = (StartDate: Date) => {
    // The number of milliseconds in all UTC days (no DST)
    const oneDay = 1000 * 60 * 60 * 24;
  
    // A day in UTC always lasts 24 hours (unlike in other time formats)
    const start = Date.UTC(StartDate.getFullYear(), 0, 1);
    const end = Date.UTC(StartDate.getFullYear(), StartDate.getMonth(), StartDate.getDate());
  
    // so it's safe to divide by 24 hours
    return (end - start) / oneDay;
  };
  
<template>
  <div v-if="aAdDoc.isTemplate() === true"  class="adDocActionButtons">
    <div class="pi pi-plus"  :title="t('applyModeleToCreateNewAdDoc')"
      @click.stop="createAdDocUsingTemplate()">
    </div>
    <div v-if="1 === 1" class="pi pi-clone" :title="t('duplicateModeleInYourModeleList')"
      @click.stop="createAdDocUsingTemplate('T')">
    </div>
  </div>

</template>
<script lang="ts">
import { reactive, toRefs } from "vue";
import router from '@/router';
import { useI18n } from "@/engineproject/default/common/i18n";
import { AdDocumentProjectTypes } from '@/engineproject/default/common/domainModel/adDocumentProjectTypes';

export default {
  props: {
    aAdDoc: {
      type: Object as () => AdDocumentProjectTypes.AdDocument,
      required: true
    },
  },
  setup(props: any, context: any) {
    const {t} = useI18n()
    const page = reactive({
    });

    const createAdDocUsingTemplate = (adDocFileType: string) => {
      const aRoute = (adDocFileType === "T") ? "/addtem/" : "/add/"
      router.push(aRoute + props.aAdDoc.getADId())
      // router.push('/add' + (props.aAdDoc.isSuggestion() ? 'sug' : (props.aAdDoc.isTemplate() ? 'tem' : '')) + '/' + props.aAdDoc.getADId())
    }

    return {
      ...toRefs(page),
      t,
      createAdDocUsingTemplate,
    }
  },
}


</script>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ownerProfileViewLink" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileViewLink = _resolveComponent("ProfileViewLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ProfileViewLink, {
      userId: $props.ownerMiniProfile.UOUserId,
      userDisplayedName: _ctx.userDisplayedName,
      userDisplayedDescription: _ctx.userDisplayedDescription,
      displayAppBoss: $props.displayAppBoss,
      displayWannaBeAppBoss: $props.displayWannaBeAppBoss,
      prefixTextDisplayedBeforeName: _ctx.prefixTextDisplayedBeforeName,
      profilePictureUrl: _ctx.profilePictureUrl,
      appBoss: $props.ownerMiniProfile.UOFromAppBoss
    }, null, 8, ["userId", "userDisplayedName", "userDisplayedDescription", "displayAppBoss", "displayWannaBeAppBoss", "prefixTextDisplayedBeforeName", "profilePictureUrl", "appBoss"])
  ]))
}

import { InjectionKey } from 'vue'
import { createStore, useStore, Store } from 'vuex'

import router from '@/router/index'
import { firebaseStorage, userFirebase } from '@/engineproject/backend/firebase';
import { UserProjectTypes } from "@/engineproject/default/common/domainModel/userProjectTypes"
import { applyToStoreDomainResults, blockApplicationUI, saveDomainResultsInStores } from '@/engineproject/store/store'
import { UserTypes } from "@/engineproject/domainModel/userTypes"
import { fullLocale } from '@/engineproject/i18nApp'
import { AppState, timerToFixBlockUIValue, waitTimeout } from './appStore';
import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes';
import { UserProfileProjectObject } from '@/engineproject/default/common/domainModel/userProfileProjectToolsTypes';
import { userProfileControler } from '../domainModel/controlers/userProfileControler';
import { EnterpriseInfoTypes } from '../domainModel/enterpriseInfoTypes';
//import { useEnterpriseStore } from "@/engineproject/store/enterpriseStore";
import { enterpriseStore } from '@/engineproject/store/enterpriseStore'
import { EnterpriseInfoObject } from '../domainModel/enterpriseInfoToolsTypes';

const verifyUserAndDocData = (
  aAdDoc: AdDocumentProjectObject,
  appStore: any) => {
  let errorMsg = undefined
  ///if (!userProfile) {
  ///  errorMsg = "UndefinedUserProfile"
  ///}
  errorMsg = aAdDoc.validateIdAndDateHourStartsOn(errorMsg)
  appStore.commit("setErrorMsg", { errorMsg });
  return errorMsg
}


export interface UserState {
  myActiveTransactionsWithAll: Array<Array<{
    adDocIdInList: string;
    adDocTitleInList: string;
  }>>;
  adDocsFromProfileWithOneOfMyActiveTransaction: Array<Array<{
    adDocIdInList: string;
    adDocTitleInList: string;
  }>>;
  myAdDocsWithOneActiveTransactionFromProfile: Array<Array<{
    adDocIdInList: string;
    adDocTitleInList: string;
  }>>;
  userPrivateProfileDocObserver: any;
  userPublicProfileDocObserver: any;
  aUserProfile: UserProfileProjectObject;
  ProfilesArray: Array<UserProfileProjectObject>;
  moreElementsInCollection: boolean;
}

export const userKey: InjectionKey<Store<UserState>> = Symbol()

// define your own `useStore` composition function
export function useUserStore() {
  return useStore(userKey)
}

export const userStore = createStore<UserState>({
  state: {
    myActiveTransactionsWithAll: [],
    adDocsFromProfileWithOneOfMyActiveTransaction: [],
    myAdDocsWithOneActiveTransactionFromProfile: [],
    //userProfile: undefined,
    userPrivateProfileDocObserver: undefined,
    userPublicProfileDocObserver: undefined,
    aUserProfile: new UserProfileProjectObject(),
    ProfilesArray: [],
    moreElementsInCollection: true,
  },
  mutations: {
    setMyActiveTransactionsWithAll(state, newData) {
      state.myActiveTransactionsWithAll = newData.listOfAdDocs
    },
    setAdDocsFromProfileWithOneOfMyActiveTransaction(state, newData) {
      state.adDocsFromProfileWithOneOfMyActiveTransaction[newData.trDocOwnerUserId] = newData.listOfAdDocs
    },
    setMyAdDocsWithOneActiveTransactionFromProfile(state, newData) {
      state.myAdDocsWithOneActiveTransactionFromProfile[newData.trRequesterUserId] = newData.listOfAdDocs
    },
    updateUserProfile(state, newData) {
      //state.aAdDoc = (val === undefined) ? new AdDocumentProjectObject() /*val*/ : new AdDocumentProjectObject(val)
      state.aUserProfile = state.aUserProfile.updateUserProfile({
        ...newData.docUpdate
      })
    },
    setUserProfile(state, newData) {
      state.aUserProfile = new UserProfileProjectObject({ ...newData.userProfileObj })
      /// state.userProfile = newData.userProfileObj
      if (state.userPrivateProfileDocObserver) {
        state.userPrivateProfileDocObserver()
      }
      state.userPrivateProfileDocObserver = newData.userPrivateProfileDocObserver
      if (state.userPublicProfileDocObserver) {
        state.userPublicProfileDocObserver()
      }
      state.userPublicProfileDocObserver = newData.userPublicProfileDocObserver
    },
    setUserProfileUrl(state, val) {
      state.aUserProfile.setUserProfileUrl(val)
    },
    setUserEnterprise(state, { newEnterprise }) {
      state.aUserProfile.setUserEnterprise(newEnterprise)
    },
    setDefaultPictureUrl(state, { pictureUrl, pictureFileName }) {
      state.aUserProfile.setDefaultPictureUrl(pictureUrl, pictureFileName)
    },
    setDefaultCatsInUnivers(state, { newDefaultCatsInUnivers }) {
      state.aUserProfile.setDefaultCatsInUnivers(newDefaultCatsInUnivers)
    },
    setDefaultUnivers(state, { newDefaultUnivers }) {
      state.aUserProfile.setDefaultUnivers(newDefaultUnivers)
    },
    setUserProfileLocation(state, val) {
      state.aUserProfile.setUserProfileLocation(val)
      /*if (state.userProfile) {
        state.userProfile.UPLocation = val
      }*/
    },
    setHasBeenViewedInArray(state, newData) {
      state.aUserProfile.setHasBeenViewedInArray(newData)
      /*if (state.userProfile && state.userProfile.UPUserId == newData.UPUserId &&
        state.userProfile.UPColNotifications) {
          // updatedNotificationId
          state.userProfile.UPColNotifications = state.userProfile.UPColNotifications.reduce((accumulator:  Array<UserTypes.UserNotification>, 
              currentValue: UserTypes.UserNotification) => {
                if (currentValue.UNNotificationId === newData.updatedNotificationId) {
                  currentValue.UNHasBeenViewed = newData.hasBeenViewed
                }
                accumulator.push(currentValue)
            return accumulator
          },  Array<UserTypes.UserNotification>())
      }*/
    },
    removeNotificationInArray(state, newData) {
      state.aUserProfile.removeNotificationInArray(newData)
      /*if (state.userProfile && state.userProfile.UPUserId == newData.UPUserId &&
        state.userProfile.UPColNotifications) {
          state.userProfile.UPColNotifications = state.userProfile.UPColNotifications.reduce((accumulator:  Array<UserTypes.UserNotification>, 
              currentValue: UserTypes.UserNotification) => {
                if (currentValue.UNNotificationId !== newData.removedNotificationId) {
                  accumulator.push(currentValue)
                }
            return accumulator
          },  Array<UserTypes.UserNotification>())
      }*/
    },
    removeCalendarEventInArray(state, newData) {
      state.aUserProfile.removeCalendarEventInArray(newData)
      /*if (state.userProfile && state.userProfile.UPUserId == newData.aUEOwnerUserId &&
        state.userProfile.UPColCalendarEvents) {
          state.userProfile.UPColCalendarEvents = state.userProfile.UPColCalendarEvents.reduce((accumulator:  Array<UserTypes.UserCalendarEvent>, 
              currentValue: UserTypes.UserCalendarEvent) => {
                if (currentValue.UECalendarEventId !== newData.calendarEventId) {
                  accumulator.push(currentValue)
                }
            return accumulator
          },  Array<UserTypes.UserCalendarEvent>())
      }*/
    },

    setNotificationsArrayInMyProfile(state, newData) {
      state.aUserProfile.setNotificationsArrayInMyProfile(newData)
      /*if (state.userProfile && state.userProfile.UPUserId == newData.UPUserId) {
        state.userProfile.UPColNotifications = newData.UPColNotifications
      }*/
    },

    setCalendarEventsArrayInMyProfile(state, newData) {
      state.aUserProfile.setCalendarEventsArrayInMyProfile(newData)
    },
    setContactsArrayInUserProfile(state, newData) {
      state.aUserProfile.setContactsArrayInUserProfile(newData)
    },
    setAllowedEnterprisesArrayInUserProfile(state, newData) {
      state.aUserProfile.setAllowedEnterprisesArrayInUserProfile(newData)
    },
    setBannedInUserProfile(state, newData) {
      state.aUserProfile.setBannedInUserProfile(newData)
    },
    
    subscriptionCancelled(state) {
      state.aUserProfile.subscriptionCancelled()
    },
    setProfiles(state, val: Array<UserProfileProjectObject>) {
      state.ProfilesArray = val
    },
    setMoreElementsInCollection(state, val) {
      state.moreElementsInCollection = val
    },
    addNewProfiles(state, val) {
      // console.error("addNewProfiles " + JSON.stringify(val))
      state.ProfilesArray = state.ProfilesArray.concat(val)
    },
  },

  getters: {
    userProfileLatLng: (state, getters) => {
      return state.aUserProfile.getUserProfileLatLng()
    },
    getDefaultUniversAndCatsInUnivers: (state, getters) => {
      return state.aUserProfile.getDefaultUniversAndCatsInUnivers()
    },
    /*profileImgPathAndFile: (state) => {
      return  state.aUserProfile.getProfileImgPathAndFile()
    }*/

  },
  actions: {
    async login({ dispatch, commit }, { form, appStore }) {
      //const result = await userProfileControler.login(form.email, form.password)
      const result = await userFirebase.login(form.email, form.password)

      saveDomainResultsInStores(appStore, dispatch, result, {
        processTag: 'fetchUserProfile',
        validObj: { userId: result.userId, appStore },
        unknownErrorMsg: "Unknown error during Login process"
      })
    },

    async fetchUserProfile({ commit, dispatch, state }, options: {
      userId: string;
      appStore: Store<AppState>;
      afterSignup: boolean;
    }) {
      // TODO find a way to remove this callback !!!!
      const userPrivateProfileDocObserverReceived = (docUpdate: any) => {
        commit("updateUserProfile", {
          docUpdate
        })

      }
      const userPublicProfileDocObserverReceived = (docUpdate: any) => {
        commit("updateUserProfile", {
          docUpdate
        })
        // Update the enterprise store with the user enterprise
        //const enterpriseStore = useEnterpriseStore();
        if (enterpriseStore) {
          enterpriseStore.commit("setCurrentUserEnterprise", {
            userEnterprise: new EnterpriseInfoObject({
              ...docUpdate.UPEnterprise
            })
          });
          }
        dispatch("getContactsForUser", {
          UPUserId: options.userId,
          appStore: options.appStore,
        });
        dispatch("getAllowedEnterprisesForMe", {
          UPUserId: options.userId,
          appStore: options.appStore,
        });
      }

      const manageRet = async (result: any) => {
        // if (result.errorMsg == "UserBanned" || result.errorMsg == "UserClosed") {
        if (result.errorMsg !== undefined) {
          await waitTimeout(500);
          // setTimeout(() => {
          await dispatch("logout", {
            appStore: options.appStore
          })

          //}, 500)
          return
        }
        // change route to dashboard
        // cycling stringify error in prod !!!! console.error("currentRoute " + JSON.stringify(router.currentRoute))
        const currentRoute = router.currentRoute.value
        //console.error("currentRoute.path.toLowerCase()  " + currentRoute.path.toLowerCase())
        if (state.aUserProfile.getUserProfileLatLng() === undefined &&
          options.afterSignup === true
        ) {
          router.push('/settings/itemContactProfileData')
          options.appStore.commit("setNotifMsg", {
            notifMsg: "enterYourLocationToReceiveInvitations"
          });
          dispatch("sendEmailValidationLinkToUser", {
            appStore: options.appStore,
          }) 
    
        } else {
          if (currentRoute.path.toLowerCase() === '/login' || currentRoute.path.toLowerCase() === '/login/') {
            if (currentRoute.query.redirect) {
              router.push(currentRoute.query.redirect.toString())
            } else {
              router.push('/')

            }
          }
          await dispatch("getContactsForUser", {
            UPUserId: options.userId,
            appStore: options.appStore,
          });
          await dispatch("getAllowedEnterprisesForMe", {
            UPUserId: options.userId,
            appStore: options.appStore,
          });
        }
      }

      await blockApplicationUI(options, async () => {
        const resultFor = await userProfileControler.getMyUserProfile(options.userId, userPrivateProfileDocObserverReceived,
          userPublicProfileDocObserverReceived)
        const rStoreProcess = [{
          processInputResult: resultFor.rForGetMyUserProfile,
          unknownErrorMsg: "Unknown error during getMyUserProfile process",
          processAction: commit,
          processActionTag: 'setUserProfile',
          validObj: {
            userProfileObj: resultFor.rForGetMyUserProfile.userProfileObj,
            userPrivateProfileDocObserver: resultFor.rForGetMyUserProfile.userPrivateProfileDocObserver,
            userPublicProfileDocObserver: resultFor.rForGetMyUserProfile.userPublicProfileDocObserver
          },
        }]
        await applyToStoreDomainResults({
          appStore: options.appStore,
        }, rStoreProcess);
        await manageRet(resultFor.rForGetMyUserProfile)
      })
    },

    async signup({ commit, dispatch }, { form, appStore }) {
      const result = await userFirebase.signup({
        ...form
      })
      saveDomainResultsInStores(appStore, dispatch, result, {
        processTag: 'fetchUserProfile',
        validObj: {
          userId: result.userId,
          appStore,
          afterSignup: true,
        },
        unknownErrorMsg: "Unknown error during Signup process"
      })
    },

    logoutAction({ commit }, { appStore }) {
      // clear user data from state
      console.error("logoutAction outside timeout " + appStore)
      // We have to remove the blockui before the route /login
      appStore.commit("resetBlockedApplicationCounter")
      const currentRoute = router.currentRoute.value
      if (currentRoute.path.toLowerCase() === '/login' || currentRoute.path.toLowerCase() === '/login/') {
        return
      }
      commit('setUserProfile', {
        userProfileObj: undefined,
        userPrivateProfileDocObserver: undefined
      })
      // redirect to login view
      setTimeout(() => {
        console.error("logoutAction in timeout " + appStore)
        // We have to remove the blockui before the route /login
        appStore.commit("resetBlockedApplicationCounter")
        const currentRoute = router.currentRoute.value
        console.error('currentRoute.path ' + currentRoute.path)
        if (currentRoute.path.toLowerCase() === '/login' || currentRoute.path.toLowerCase() === '/login/') {
          return
        }
        if (currentRoute.meta.requiresAuth === false) {
          return
        }
        const redirect = (currentRoute.query !== undefined && currentRoute.query.redirect !== undefined) ?
          "/?redirect=" + currentRoute.query.redirect : ""
        router.push('/login' + redirect)
      }, 1000)
    },

    async logout({ state, commit, dispatch }, options: { appStore: Store<AppState> }) {
      // log user out
      console.error("logout")
      const waitTimeout = async (aDurationInMS: number) => {
        await new Promise(resolve => {
          setTimeout(resolve, aDurationInMS)
        })
      }
      await blockApplicationUI(options, async () => {
        // TODO replace the next wait
        // In some cases, the logout can be done before the end of another process
        // Detected in cypress test: add a private doc, then logout
        await waitTimeout(5000)
        if (state.userPrivateProfileDocObserver) {
          state.userPrivateProfileDocObserver()
          state.userPrivateProfileDocObserver = undefined
        }
        if (state.userPublicProfileDocObserver) {
          state.userPublicProfileDocObserver()
          state.userPublicProfileDocObserver = undefined
        }

        const result = await userFirebase.logout()
        console.error("logout result " + result)
        saveDomainResultsInStores(options.appStore, dispatch, result, {
          processTag: 'logoutAction',
          validObj: {
            appStore: options.appStore,
          },
          unknownErrorMsg: "Unknown error during Logout process"
        })
      })
    },

    async getUserProfile({ commit }, { aUserId, aPseudo, appStore }) {
      const result = await userFirebase.getUserProfile({ userId: aUserId, aPseudo })
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: '',
        validObj: {
        },
        unknownErrorMsg: "Unknown error during getUserProfile  process"
      })
      if (result.userProfileObj !== undefined) {
        return new UserProfileProjectObject({
          ...result.userProfileObj
        })
      }
      return new UserProfileProjectObject()
      // return result
    },


    async sendPasswordResetEmail({ state, dispatch }, options: {
      appStore: Store<AppState>;
      anEmail: string;
    }) {

      return await blockApplicationUI(options, async () => {
        const resultFor = await userProfileControler.sendPasswordResetEmail(options.anEmail)
        const rStoreProcess = [{
          processInputResult: resultFor.rForSendPasswordResetEmail,
          unknownErrorMsg: "Unknown error during sendPasswordResetEmail process",
          validObj: {
            notifMsg: "resetEmailDone"
          },
        }]
        await applyToStoreDomainResults({
          appStore: options.appStore,
        }, rStoreProcess);
        return resultFor.rForSendPasswordResetEmail
      })
    },

    async updateProfile({ state, dispatch }, options: {
      user: UserProfileProjectObject; appStore: Store<AppState>; fetchFromServer: boolean;
      notifMsg: string;
      updateEmail: any;
    }) {
      /*
    const result = await userFirebase.updateProfile(user, updateEmail)
    
    // ATTENTION: dispach in saveDomainResultsInStores, the user is refreshed from the database
    saveDomainResultsInStores(appStore , dispatch , result, {
      processTag: (fetchFromServer === true) ? "fetchUserProfile" : "",
      validObj:  {userId: result.userId, notifMsg} ,
      unknownErrorMsg:"Unknown error during update Profile process"
    })
    return result
    */

      return await blockApplicationUI(options, async () => {
        const resultFor = await userProfileControler.updateProfile(options.user, options.updateEmail)
        const rStoreProcess = [{
          processInputResult: resultFor.rForUpdateProfile,
          processAction: dispatch,
          processActionTag: (options.fetchFromServer === true) ? "fetchUserProfile" : "",
          validObj: { userId: resultFor.rForUpdateProfile.userId, notifMsg: options.notifMsg },
          unknownErrorMsg: "Unknown error during update Profile process"
        }]
        await applyToStoreDomainResults({
          appStore: options.appStore,
        }, rStoreProcess);

        dispatch("triggerInvitationProcess", {
          appStore: options.appStore,
          manualCallAndDisplayInfoMsg: false,
        });

        return resultFor.rForUpdateProfile
      })
    },

    async removeMyContact({ state, commit }, options: {
      appStore: Store<AppState>;
      myContactUserProfile: UserProfileProjectObject;
    }) {

      await blockApplicationUI(options, async () => {
        const resultFor = await userProfileControler.removeMyContact({
          contactUserId: options.myContactUserProfile.getUPUserId(),
          requesterUserId: state.aUserProfile.getUPUserId()
        })
        const rStoreProcess = [{
          processInputResult: resultFor.rForRemoveMyContact,
          unknownErrorMsg: "Unknown error during removeMyContact process",
          validObj: {
            notifMsg: "contactRemoved"
          },
        }]
        await applyToStoreDomainResults({
          appStore: options.appStore,
        }, rStoreProcess);
        if (resultFor.rForRemoveMyContact.backendActionDone === true) {
          userStore.dispatch("fetchUserProfile", {
            userId: state.aUserProfile.getUPUserId(),
            appStore: options.appStore,
            afterSignup: false,
          });
          // const currentRoute = router.currentRoute.value
          // router.push(currentRoute.fullPath + "?t=" + Date.now())
        }

      })

    },

    async sendEmailValidationLinkToUser({ state, dispatch }, options: {
      appStore: Store<AppState>;
    }) {
      await blockApplicationUI(options, async () => {
        const resultFor = await userProfileControler.sendEmailValidationLinkToUser()
        const rStoreProcess = [{
          processInputResult: resultFor.rForSendEmailValidationLinkToUser,
          unknownErrorMsg: "Unknown error during sendEmailValidationLinkToUser process",
          validObj: {
            notifMsg: "mailSentToYouWithValidationLink"
          },

        }]
        await applyToStoreDomainResults({
          appStore: options.appStore,
        }, rStoreProcess);
        return resultFor.rForSendEmailValidationLinkToUser.backendActionDone
      })
    },

    async validationLinkFromUser({ state, dispatch }, options: {
      appStore: Store<AppState>;
      linkHash: string;
    }) {
      await blockApplicationUI(options, async () => {
        const resultFor = await userProfileControler.validationLinkFromUser({
          linkHash: options.linkHash
        })
        const rStoreProcess = [{
          processInputResult: resultFor.rForValidationLinkFromUser,
          unknownErrorMsg: "Unknown error during validationLinkFromUser process",
          validObj: {
            notifMsg: "emailHasBeenValidated"
          },
        }]
        await applyToStoreDomainResults({
          appStore: options.appStore,
        }, rStoreProcess);
        return resultFor.rForValidationLinkFromUser.backendActionDone
      })
    },

    async doClosingAccount({ state, dispatch }, options: {
      appStore: Store<AppState>;
    }) {
      const logoutRequiredDelayedForBetterE2EIntegration = await blockApplicationUI(options, async () => {
        const resultFor = await userProfileControler.doClosingAccount({
          trRequesterUserId: state.aUserProfile.getUPUserId()
        })
        const rStoreProcess = [{
          processInputResult: resultFor.rForDoClosingAccount,
          unknownErrorMsg: "Unknown error during rForDoClosingAccount process"
        }]
        await applyToStoreDomainResults({
          appStore: options.appStore,
        }, rStoreProcess);
        return resultFor.rForDoClosingAccount.backendActionDone
      })
      // logout must be done just after blockApp UI has been removed
      // E2E test needs to get the removal of the block UI before the change of the url
      // The logout blockui must also be done after the timerToFixBlockUIValue
      if (logoutRequiredDelayedForBetterE2EIntegration === true) {
        options.appStore.commit("setNotifMsg", {
          notifMsg: "closingAccountDone"
        });
        setTimeout(async () => {
          await dispatch("logout", { appStore: options.appStore })
        }, 2 * timerToFixBlockUIValue)
      }
      return logoutRequiredDelayedForBetterE2EIntegration
    },

    async getMyActiveTransactionsWithAll({ state, commit }, options: {
      appStore: Store<AppState>;
    }) {

      return await blockApplicationUI(options, async () => {
        const resultFor = await userProfileControler.getMyActiveTransactionsWithAll({
          trRequesterUserId: state.aUserProfile.getUPUserId()
        })
        const rStoreProcess = [{
          processInputResult: resultFor.rForGetActiveTransactionsWithAContact,
          processAction: commit,
          processActionTag: "setMyActiveTransactionsWithAll",
          validObj: resultFor.rForGetActiveTransactionsWithAContact.activeTransactionsWithAContact,
          unknownErrorMsg: "Unknown error during getMyActiveTransactionsWithAll process"
        }]
        await applyToStoreDomainResults({
          appStore: options.appStore,
        }, rStoreProcess);
        return resultFor.rForGetActiveTransactionsWithAContact.backendActionDone
      })
    },

    async getActiveTransactionsWithAContact({ state, commit }, options: {
      appStore: Store<AppState>;
      myContactUserProfile: UserProfileProjectObject;
    }) {

      await blockApplicationUI(options, async () => {
        const resultFor = await userProfileControler.getActiveTransactionsWithAContact({
          trDocOwnerUserId: options.myContactUserProfile.getUPUserId(),
          trRequesterUserId: state.aUserProfile.getUPUserId()
        })
        const rStoreProcess = [{
          processInputResult: resultFor.rForGetActiveTransactionsWithAContact,
          processAction: commit,
          processActionTag: "setAdDocsFromProfileWithOneOfMyActiveTransaction",
          validObj: resultFor.rForGetActiveTransactionsWithAContact.activeTransactionsWithAContact,
          unknownErrorMsg: "Unknown error during getActiveTransactionsWithAContact process"
        }]
        await applyToStoreDomainResults({
          appStore: options.appStore,
        }, rStoreProcess);

        const resultFor2 = await userProfileControler.getActiveTransactionsWithAContact({
          trDocOwnerUserId: state.aUserProfile.getUPUserId(),
          trRequesterUserId: options.myContactUserProfile.getUPUserId()
        })
        const rStoreProcess2 = [{
          processInputResult: resultFor2.rForGetActiveTransactionsWithAContact,
          processAction: commit,
          processActionTag: "setMyAdDocsWithOneActiveTransactionFromProfile",
          validObj: resultFor2.rForGetActiveTransactionsWithAContact.activeTransactionsWithAContact,
          unknownErrorMsg: "Unknown error during getActiveTransactionsWithAContact process"
        }]
        await applyToStoreDomainResults({
          appStore: options.appStore,
        }, rStoreProcess2);


      })

    },

    async removeProfilePicture({ dispatch }, { pathAndFile, appStore }) {
      // console.error("removeProfilePicture")
      const result = await userFirebase.removeProfilePicture(pathAndFile)
      saveDomainResultsInStores(appStore, dispatch, result, {
        processTag: '',
        validObj: { pathAndFile: pathAndFile, appStore },
        unknownErrorMsg: "Unknown error during removeProfilePicture process"
      })
    },

    async updateProfilePicture({ commit, dispatch, state }, { pictureUrl, appStore, notifMsg }) {
      // console.error("updateProfilePicture")

      commit('setUserProfileUrl', pictureUrl + ((pictureUrl === "") ? "" : "?ttt=" + (Date.now())))
      if (pictureUrl === "") {
        const pathAndFile = state.aUserProfile.getProfileImgPathAndFile() //userStore.getters.profileImgPathAndFile
        const result = await userFirebase.removeProfilePicture(pathAndFile)
        saveDomainResultsInStores(appStore, dispatch, result, {
          processTag: '',
          validObj: { pathAndFile: pathAndFile, appStore },
          unknownErrorMsg: "Unknown error during removeProfilePicture process"
        })
      }
      // const newMiniProfile = state.aUserProfile.getMiniProfile()
      dispatch("updateProfile", {
        user: state.aUserProfile,
        appStore, fetchFromServer: false, notifMsg,
        /*updateMiniProfile: true, 
        newMiniProfile,*/
        updateEmail: false
      })
    },

    async updateUserEnterprise({ commit, dispatch, state }, { newEnterprise, appStore, notifMsg}) {
      if (newEnterprise.EIEnterpriseId  === undefined) {
        return
      }
      commit('setUserEnterprise', {
        newEnterprise
      })
      // Init to a default phone number to avoid the mandatory
      // phone number for enterprise users.
      if (newEnterprise && !state.aUserProfile.OUPData.UPPhoneNumber) [
        state.aUserProfile.OUPData.UPPhoneNumber = '00-00-00-00-00'
      ]
      dispatch("updateProfile", {
        user: state.aUserProfile,
        appStore, fetchFromServer: false, notifMsg,
        updateEmail: false
      })
    },

    async setExternEnterpriseToUser({ commit }, { appStore, newEnterprise, aUserProfile, notifMsg  }) {
      const userId = aUserProfile.getUPUserId()
      const result = await userFirebase.setExternEnterpriseToUser({ 
        userId, newEnterprise })
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: '',
        validObj: {
          appStore,
          notifMsg,
        },
        unknownErrorMsg: "Unknown error during update of extern enterprise process"
      })
      if (result.backendActionDone === true) {
        
        aUserProfile.setUserEnterprise(newEnterprise)
        
      }
    },


    async updateProfileDefaultPictureUrl({ commit, dispatch, state }, { pictureUrl, pictureFileName, appStore, notifMsg }) {
      console.error("updateProfileDefaultPictureUrl")
      const pathAndFile = state.aUserProfile.getDefaultPicturePathAndFile()// getProfileImgPathAndFile()
      commit('setDefaultPictureUrl', {
        pictureUrl: firebaseStorage.getShortBucketStorageUrl(pictureUrl),
        pictureFileName: (pictureUrl === "") ? "" : pictureFileName
      })
      if (pictureUrl === "") {
        const result = await userFirebase.removeProfilePicture(pathAndFile)
        saveDomainResultsInStores(appStore, dispatch, result, {
          processTag: '',
          validObj: { pathAndFile: pathAndFile, appStore },
          unknownErrorMsg: "Unknown error during updateProfileDefaultPictureUrl process"
        })
      }
      dispatch("updateProfile", {
        user: state.aUserProfile,
        appStore, fetchFromServer: false, notifMsg,
        updateEmail: false
      })
    },

    async cancelUserSubscription({ state, commit }, options: {
      appStore: Store<AppState>;
      notifMsg: string; planId: string;
      subscriptionId: string;
    }) {
      // console.error("updateProfile")
      // console.error("updateProfile: " + JSON.stringify(user))
      await blockApplicationUI(options, async () => {
        const resultFor = await userProfileControler.cancelUserSubscription({
          aUserId: state.aUserProfile.getUPUserId(),
          subscriptionId: options.subscriptionId,
          planId: options.planId
        })

        const rStoreProcess = [{
          processInputResult: resultFor.rForUpdateProfilePlan,
          unknownErrorMsg: "Unknown error during cancelUserSubscription process ",
          processAction: commit,
          processActionTag: 'subscriptionCancelled',
          validObj: {
            notifMsg: "subscriptionCancelled"
          },
        }]

        await applyToStoreDomainResults({
          appStore: options.appStore,
        }, rStoreProcess);

        if (resultFor.rForUpdateProfilePlan.backendActionDone === true) {
          userStore.dispatch("fetchUserProfile", {
            userId: state.aUserProfile.getUPUserId(),
            appStore: options.appStore,
            afterSignup: false,
          });
        }

      })
    },

    async updateProfilePlan({ state, commit }, options: {
      appStore: Store<AppState>;
      notifMsg: string; planId: string;
      subscriptionId: string;
    }) {
      // console.error("updateProfile")
      // console.error("updateProfile: " + JSON.stringify(user))
      await blockApplicationUI(options, async () => {
        const resultFor = await userProfileControler.updateProfilePlan({
          aUserId: state.aUserProfile.getUPUserId(),
          subscriptionId: options.subscriptionId,
          planId: options.planId
        })
        const rStoreProcess = [{
          processInputResult: resultFor.rForUpdateProfilePlan,
          unknownErrorMsg: "Unknown error during updateProfilePlan process "
        }]
        await applyToStoreDomainResults({
          appStore: options.appStore,
          validObj: {
            notifMsg: "subscriptionUpdated"
          },
        }, rStoreProcess);
        if (resultFor.rForUpdateProfilePlan.backendActionDone === true) {
          userStore.dispatch("fetchUserProfile", {
            userId: state.aUserProfile.getUPUserId(),
            appStore: options.appStore,
            afterSignup: false,
          });
        }
      })
    },

    async updateProfileLocation({ commit, dispatch, state },
      { location, appStore, notifMsg }) {
      // console.error("updateProfileLocation")
      commit('setUserProfileLocation', location)
      dispatch("updateProfile", {
        user: state.aUserProfile, //userStore.state.userProfile, 
        appStore, fetchFromServer: false, notifMsg
      })
    },

    async addMutualContacts({ commit, dispatch }, { ATMUser, UPMUser, appStore }) {
      //console.error("addMutualContacts")
      const result = await userFirebase.addMutualContacts({ ATMUser, UPMUser })
      saveDomainResultsInStores(appStore, dispatch, result, {
        processTag: '',
        validObj: { appStore },
        unknownErrorMsg: "Unknown error during addMutualContacts process"
      })
    },


    async refreshMyCalendarEventsArray({ commit }, { appStore }) {
      const result = await userFirebase.refreshMyCalendarEventsArray()
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'setCalendarEventsArrayInMyProfile',
        validObj: {
          ...result.calendarEventsData,
          appStore,
        },
        unknownErrorMsg: "Unknown error during refreshMyCalendarEventsArray  process"
      })
      return result
    },

    async refreshMyNotifsArray({ commit }, { appStore }) {
      const result = await userFirebase.refreshMyNotifsArray()
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'setNotificationsArrayInMyProfile',
        validObj: {
          ...result.notifData,
          appStore,
        },
        unknownErrorMsg: "Unknown error during refreshMyNotifsArray  process"
      })
      return result
    },

    async removeNotification({ state, commit }, { notifId,
      //aUser, 
      appStore }) {
      const aUserProfile = state.aUserProfile
      const result = await userFirebase.removeNotification(notifId,
        aUserProfile.getUPUserId())
      //aUser)
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'removeNotificationInArray',
        validObj: {
          UPUserId: aUserProfile.getUPUserId(),
          //UPUserId: aUser.UPUserId,
          removedNotificationId: result.removedNotificationId,
          notifMsg: "Notification enlevée"
        },
        unknownErrorMsg: "Unknown error during removeNotification process"
      })
    },

    async setHasBeenViewed({ state, commit }, { notifId, hasBeenViewed,
      //aUser, 
      appStore }) {
      const aUserProfile = state.aUserProfile
      const result = await userFirebase.setHasBeenViewed(notifId, hasBeenViewed,
        aUserProfile.getUPUserId())
      //aUser)
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'setHasBeenViewedInArray',
        validObj: {
          UPUserId: aUserProfile.getUPUserId(),
          // UPUserId: aUser.UPUserId,
          updatedNotificationId: result.updatedNotificationId,
          hasBeenViewed,
          notifMsg: ""
        },
        unknownErrorMsg: "Unknown error during setHasBeenViewed process"
      })
    },

    async sendReportAboutAdDoc({ dispatch, state }, aCommentData: {
      aAdDoc: AdDocumentProjectObject;
      ACContent: string;
      //userProfile: UserProjectTypes.UserProfile;
      appStore: Store<AppState>;
    }) {
      const reportedData = [{
        DCType: "ReportedAdDoc",
        DCNew: aCommentData.ACContent,
        DCOld: aCommentData.aAdDoc.getADDescription(),
      }]

      const aUserNotification = {
        // UNOwnerUserId: aUNOwnerUserId,  // The user receiving the notification
        UNFromUserId: state.aUserProfile.getUPUserId(),   // The user sending the notification
        //UNFromUserId: state.userProfile?.UPUserId,   // The user sending the notification
        UNPictureSrcUrl: "",
        UNUrl: aCommentData.aAdDoc.getADId(),
        UNText: aCommentData.aAdDoc.getADTitle(),
        UNAttachedData: reportedData,
        UNType: "DR", // Document reported
        UNI18N: fullLocale,
        UNHasBeenViewed: false
      } as UserTypes.UserNotification
      const result = await userFirebase.addReportList({ aUserNotification })
      saveDomainResultsInStores(aCommentData.appStore, dispatch, result, {
        processTag: '',
        validObj: { appStore: aCommentData.appStore },
        unknownErrorMsg: "Unknown error during addNotification process"
      })
      return result.backendActionDone
    },

    async changesInDoc({ state, dispatch }, params: {
      aUser: any; userIdWithPendingOrAcceptedTransactions: string[];
      initialDoc: AdDocumentProjectObject; aAdDoc: AdDocumentProjectObject; docChanges: any;
      importantChangesCounter: any; appStore: any;
    }) {
      if (verifyUserAndDocData(/*state.userProfile,*/ params.aAdDoc, params.appStore) !== undefined) {
        return
      }
      //const UNDateHourStartsOn = (aAdDoc.OADData.ADDateHourStartsOn.seconds !== undefined ) ? 
      //  aAdDoc.OADData.ADDateHourStartsOn.toDate().toString() : new Date(aAdDoc.OADData.ADDateHourStartsOn).toString();
      const UNDateHourStartsOn = params.aAdDoc.getADDateHourStartsOn();

      //console.error("changesInDoc UNDateHourStartsOn.seconds " + UNDateHourStartsOn.seconds)
      const aUserNotification = {
        // UNOwnerUserId: aUNOwnerUserId,  // The user receiving the notification
        UNFromUserId: state.aUserProfile.getUPUserId(),   // The user sending the notification
        //UNFromUserId: state.userProfile?.UPUserId,   // The user sending the notification
        UNPictureSrcUrl: "",
        UNUrl: params.aAdDoc.getADId(),
        UNText: params.aAdDoc.getADTitle(),
        UNAttachedData: params.docChanges, //JSON.stringify(docChanges),
        UNDateHourStartsOn,
        UNType: "DC", // Document changed
        UNI18N: fullLocale,
        UNHasBeenViewed: false
      } as UserTypes.UserNotification
      const result = await userFirebase.addNotificationsList({
        aUserNotification,
        userIdWithPendingOrAcceptedTransactions: params.userIdWithPendingOrAcceptedTransactions,
        importantChangesCounter: params.importantChangesCounter
      })
      saveDomainResultsInStores(params.appStore, dispatch, result, {
        processTag: '',
        validObj: { appStore: params.appStore },
        unknownErrorMsg: "Unknown error during addNotification process"
      })
    },

    async addNotification({ state, dispatch }, params: {
      aUNOwnerUserId: string;
      aAdDoc: AdDocumentProjectObject; notifType: string; appStore: any;
    }) {
      if (verifyUserAndDocData(/*state.userProfile,*/ params.aAdDoc, params.appStore) !== undefined) {
        return
      }
      //const UNDateHourStartsOn = (aAdDoc.OADData.ADDateHourStartsOn.seconds !== undefined ) ? 
      //  aAdDoc.OADData.ADDateHourStartsOn.toDate().toString() : new Date(aAdDoc.OADData.ADDateHourStartsOn).toString();
      const UNDateHourStartsOn = params.aAdDoc.getADDateHourStartsOn();

      // console.error("addNotification UNDateHourStartsOn " + JSON.stringify(UNDateHourStartsOn))
      // console.error("addNotification aAdDoc.OADData.ADDateHourStartsOn " + JSON.stringify(aAdDoc.OADData.ADDateHourStartsOn))

      const aUserNotification = {
        UNOwnerUserId: params.aUNOwnerUserId,  // The user receiving the notification
        UNFromUserId: state.aUserProfile.getUPUserId(),   // The user sending the notification
        //UNFromUserId: state.userProfile?.UPUserId,   // The user sending the notification
        UNPictureSrcUrl: "",
        UNUrl: params.aAdDoc.getADId(),
        UNText: params.aAdDoc.getADTitle(),
        UNDateHourStartsOn,
        UNType: params.notifType,
        UNI18N: fullLocale,
        UNHasBeenViewed: false
      } as UserTypes.UserNotification
      const result = await userFirebase.addNotification({ aUserNotification })
      saveDomainResultsInStores(params.appStore, dispatch, result, {
        processTag: '',
        validObj: { appStore: params.appStore },
        unknownErrorMsg: "Unknown error during addNotification process"
      })
    },

    async removeCalendarEvent({ state, dispatch }, params: {
      aUNOwnerUserId: string;
      aAdDoc: AdDocumentProjectObject; eventType: string; appStore: any;
    }) {
      console.error("removeCalendarEvent")

      if (verifyUserAndDocData(/*state.userProfile,*/ params.aAdDoc, params.appStore) !== undefined) {
        return
      }
      // const UEDateHourStartsOn = (param.aAdDoc.OADData.ADDateHourStartsOn.seconds !== undefined ) ? 
      //    aAdDoc.OADData.ADDateHourStartsOn.toDate().toString() : new Date(aAdDoc.OADData.ADDateHourStartsOn).toString();
      const UEDateHourStartsOn = new Date(params.aAdDoc.getADDateHourStartsOn())
      const aUserCalendarEvent = {
        UEDateHourStartsOn,
        UEOwnerUserId: params.aUNOwnerUserId,
        UEFromUserId: state.aUserProfile.getUPUserId(),
        //UEFromUserId: state.userProfile?.UPUserId,
        UEUrl: params.aAdDoc.getADId(),
        UEType: params.eventType,
      } as UserTypes.UserCalendarEvent
      const result = await userFirebase.removeCalendarEvent({ aUserCalendarEvent })
      saveDomainResultsInStores(params.appStore, dispatch, result, {
        processTag: '',
        validObj: { appStore: params.appStore },
        unknownErrorMsg: "Unknown error during removeCalendarEvent process"
      })
    },

    async removeCalendarEventUsingId({ state, commit }, {
      //aUEOwnerUserId, 
      calendarEventId, appStore }) {
      const aUEOwnerUserId = state.aUserProfile.getUPUserId()
      // console.error("removeCalendarEventUsingId")
      // console.error("removeCalendarEventUsingId calendarEventId " + calendarEventId)
      const result = await userFirebase.removeCalendarEventUsingId({ aUEOwnerUserId, calendarEventId })
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'removeCalendarEventInArray',
        validObj: {
          appStore, calendarEventId, aUEOwnerUserId,
          notifMsg: "Elément enlevé"
        },
        unknownErrorMsg: "Unknown error during removeCalendarEventUsingId process"
      })
    },

    async cancelCalendarEvent({ state, dispatch }, params: {
      aUNOwnerUserId: string;
      aAdDoc: AdDocumentProjectObject; eventType: string; appStore: any;
    }) {
      console.error("cancelCalendarEvent")
      if (params.aAdDoc.getADDateHourStartsOn() === undefined) {
        // When the AdDoc has not been published, the date can be empty
        return
      }

      //const UEDateHourStartsOn = (aAdDoc.OADData.ADDateHourStartsOn.seconds !== undefined ) ? 
      //   aAdDoc.OADData.ADDateHourStartsOn.toDate().toString() : new Date(aAdDoc.OADData.ADDateHourStartsOn).toString();
      const UEDateHourStartsOn = new Date(params.aAdDoc.getADDateHourStartsOn())

      const aUserCalendarEvent = {
        UEDateHourStartsOn,
        UEOwnerUserId: params.aUNOwnerUserId,  // The user receiving the notification
        UEFromUserId: state.aUserProfile.getUPUserId(),
        //UEFromUserId: state.userProfile?.UPUserId,   // The user sending the notification
        UEUrl: params.aAdDoc.getADId(),
        UEType: params.eventType,
      } as UserTypes.UserCalendarEvent
      const result = await userFirebase.cancelCalendarEvent({ aUserCalendarEvent })
      saveDomainResultsInStores(params.appStore, dispatch, result, {
        processTag: '',
        validObj: { appStore: params.appStore },
        unknownErrorMsg: "Unknown error during cancelCalendarEvent process"
      })
    },

    async addCalendarEvent({ state, dispatch }, params: {
      aUNOwnerUserId: string;
      aAdDoc: AdDocumentProjectObject; eventType: string; appStore: any;
    }) {
      console.error("addCalendarEvent")
      if (verifyUserAndDocData(/*state.userProfile,*/ params.aAdDoc, params.appStore) !== undefined) {
        return
      }

      // const UEDateHourStartsOn = (aAdDoc.OADData.ADDateHourStartsOn.seconds !== undefined ) ? 
      //     aAdDoc.OADData.ADDateHourStartsOn.toDate().toString() : new Date(aAdDoc.OADData.ADDateHourStartsOn).toString();
      const UEDateHourStartsOn = new Date(params.aAdDoc.getADDateHourStartsOn())
      const aUserCalendarEvent = {
        UEOwnerUserId: params.aUNOwnerUserId,  // The user receiving the notification
        UEFromUserId: state.aUserProfile.getUPUserId(),
        //UEFromUserId: state.userProfile?.UPUserId,   // The user sending the notification
        UEUrl: params.aAdDoc.getADId(),
        UETitle: params.aAdDoc.getADTitle(),
        UEType: params.eventType,
        UEI18N: fullLocale,
        UEDateHourStartsOn,
      } as UserTypes.UserCalendarEvent
      const result = await userFirebase.addCalendarEvent({ aUserCalendarEvent })
      saveDomainResultsInStores(params.appStore, dispatch, result, {
        processTag: '',
        validObj: { appStore: params.appStore },
        unknownErrorMsg: "Unknown error during addCalendarEvent process"
      })
    },

    async updateCalendarEvent({ state, dispatch }, params: {
      aUNOwnerUserId: string;
      aAdDoc: AdDocumentProjectObject; eventType: string; appStore: any;
    }) {
      console.error("updateCalendarEvent")
      if (verifyUserAndDocData(/*state.userProfile,*/ params.aAdDoc, params.appStore) !== undefined) {
        return
      }

      // const UEDateHourStartsOn = (aAdDoc.OADData.ADDateHourStartsOn.seconds !== undefined ) ? 
      //    aAdDoc.OADData.ADDateHourStartsOn.toDate().toString() : new Date(aAdDoc.OADData.ADDateHourStartsOn).toString();
      const UEDateHourStartsOn = new Date(params.aAdDoc.getADDateHourStartsOn())
      const aUserCalendarEvent = {
        UEOwnerUserId: params.aUNOwnerUserId,  // The user receiving the notification
        UEFromUserId: state.aUserProfile.getUPUserId(),
        //UEFromUserId: state.userProfile?.UPUserId,   // The user sending the notification
        UEUrl: params.aAdDoc.getADId(),
        UETitle: params.aAdDoc.getADTitle(),
        UEType: params.eventType,
        UEI18N: fullLocale,
        UEDateHourStartsOn,
      } as UserTypes.UserCalendarEvent
      const result = await userFirebase.updateCalendarEvent({ aUserCalendarEvent })
      saveDomainResultsInStores(params.appStore, dispatch, result, {
        processTag: '',
        validObj: { appStore: params.appStore },
        unknownErrorMsg: "Unknown error during updateCalendarEvent process"
      })
    },

    async banUser({ commit }, { userId, appStore, banValue, aUserProfile }) {
      const result = await userFirebase.banUser({ userId, banValue })
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: '',
        validObj: {
          appStore
        },
        unknownErrorMsg: "Unknown error during get Comments For AdDoc  process"
      })
      if (result.backendActionDone === true) {
        aUserProfile.setBannedInUserProfile( {
          UPUserId: userId,
          aBannedState: banValue
        })
      }
    },

    async triggerInvitationProcess({ state }, options: {
      appStore: Store<AppState>;
      manualCallAndDisplayInfoMsg: boolean;
    }) {

      const buildValidObj = (manualCallAndDisplayInfoMsg: boolean) => {
        if (manualCallAndDisplayInfoMsg === true) {
          return {
            appStore: options.appStore,
            notifMsg: "invitationSentMsg"
          }
        } else {
          return undefined
        }
      }
      if (state.aUserProfile.isCurrentLoggedUser() === false) {
        return
      }

      const resultFor = await userProfileControler.triggerInvitationProcess({
        aUser: state.aUserProfile,
        manualCallAndDisplayInfoMsg: options.manualCallAndDisplayInfoMsg,
      })
      const rStoreProcess = [{
        processInputResult: resultFor.rForDoActionsOnUserBecomesAlive as any,
        validObj: buildValidObj(options.manualCallAndDisplayInfoMsg),
        unknownErrorMsg: "Unknown error during triggerInvitationProcess process "
      }] as any;
      if (options.manualCallAndDisplayInfoMsg === true &&
        resultFor.rForUpdateInvitationStatusInProfile.backendActionDone === false) {
        rStoreProcess.push({
          processInputResult: resultFor.rForUpdateInvitationStatusInProfile,
          unknownErrorMsg: "Unknown error during rForUpdateInvitationStatusInProfile process "
        })
      }
      await applyToStoreDomainResults({
        appStore: options.appStore,
      }, rStoreProcess);

    },

    async getContactsForUser({ commit }, { UPUserId, appStore }) {
      const result = await userFirebase.getContactsForUser({ UPUserId })
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'setContactsArrayInUserProfile',
        validObj: {
          UPUserId: UPUserId,
          aUPColContacts: result.aUPColContacts
        },
        unknownErrorMsg: "Unknown error during get Comments For AdDoc  process"
      })
    },

    async getAllowedEnterprisesForMe({ state, commit }, { appStore, UPUserId }) {
      const userId = state.aUserProfile.getUPUserId() || UPUserId
      // console.error('userId 22 ' + userId)
      const result = await userFirebase.getAllowedEnterprisesForUser({ UPUserId: userId })
      saveDomainResultsInStores(appStore, commit, result, {
        processTag: 'setAllowedEnterprisesArrayInUserProfile',
        validObj: {
          UPUserId: userId,
          aUPColAllowedEnterprises: result.aUPColAllowedEnterprises
        },
        unknownErrorMsg: "Unknown error during get Allowed Enterprises for user  process"
      })
    },

    async getAllowedEnterprisesForUser({ commit }, { UPUserId, appStore }) {
      const result = await userFirebase.getAllowedEnterprisesForUser({ UPUserId })
      return result
    },

    async resetAllowedEnterprisesForUser({ commit }, { aUserProfile, appStore }) {
      const result = await userFirebase.getAllowedEnterprisesForUser({ UPUserId: aUserProfile.getUPUserId() })
      aUserProfile.setAllowedEnterprisesArrayInUserProfile({
        UPUserId: aUserProfile.getUPUserId(),
        aUPColAllowedEnterprises: result.aUPColAllowedEnterprises
      })
      /*
      DO NOT WORK, TRY TO SET A PLAN for partenaire
      const enterprise = aUserProfile.getUPEnterprise()
      console.error('enterprise ' + JSON.stringify(enterprise))
      console.error('aUPColAllowedEnterprises ' + JSON.stringify(result.aUPColAllowedEnterprises))

      if (result.aUPColAllowedEnterprises && result.aUPColAllowedEnterprises.length > 0) {
        await userStore.dispatch("updateProfilePlan", {
          appStore: appStore,
          planId: "partenaire",
          subscriptionId: "partenaire",
          notifMsg: "", //t("profilePlanUpdated")
        });
      }
      */
      return result
    },

    async addNewAllowedEnterprise({ commit, dispatch }, { newAllowedEnterprise, inContactUserId, appStore }) {
      //console.error("addNewAllowedEnterprise")
      //async addNewAllowedEnterprise(queryParams: {newAllowedEnterprise: EnterpriseInfoTypes.EnterpriseInfo; inContact: UserTypes.UserMiniProfile}) {

      const result = await userFirebase.addNewAllowedEnterprise({ newAllowedEnterprise, inContactUserId })
      saveDomainResultsInStores(appStore, dispatch, result, {
        processTag: '',
        validObj: { 
          appStore,
          notifMsg: "enterpriseAllowed" 
        },
        unknownErrorMsg: "Unknown error during addNewAllowedEnterprise process"
      })
    },
    async removeAllowedEnterprise({ commit, dispatch }, { enterpriseId, inContactUserId, appStore }) {
      //console.error("removeAllowedEnterprise")

      const result = await userFirebase.removeAllowedEnterprise({ enterpriseId, inContactUserId })
      saveDomainResultsInStores(appStore, dispatch, result, {
        processTag: '',
        validObj: { 
          appStore,
          notifMsg: "enterpriseRemovedFromAllowed" 
        },
        unknownErrorMsg: "Unknown error during removeAllowedEnterprise process"
      })
    },
    

    async setProfiles({ commit }, newData) {
      console.error("setProfiles " + JSON.stringify(newData.ProfilesArray))
      commit('setProfiles', newData.ProfilesArray)
    },

    async queryProfiles({ state, commit, dispatch }, param: {
      optionsQuery: any;
      appStore: Store<AppState>; userStore: Store<UserState>;
    }) {
      let queryResult
      if (state.aUserProfile.isAdmin() === true) {
        queryResult = await userFirebase.queryProfilesForAdmin(param.optionsQuery)
      } else {
        queryResult = await userFirebase.queryContacts(param.optionsQuery)
      }
      if (queryResult.deconnected === true) {
        commit('setProfiles', [])
        dispatch("logout", { appStore: param.appStore })
      } else {
        commit('setMoreElementsInCollection', queryResult.moreElementsInCollection)
        if (queryResult.backendActionDone === false) {
          param.appStore.commit("setErrorMsg", { errorMsg: queryResult.errorMsg });
          return
        }
        if (queryResult.OUPDataArrayFromBackend.length === 0) {
          commit('setMoreElementsInCollection', false)
          return
        }

        await saveDomainResultsInStores(param.appStore, commit, queryResult, {
          processTag: 'addNewProfiles',
          validObj: queryResult.OUPDataArrayFromBackend,
          unknownErrorMsg: "Unknown error during query queryProfiles process"
        })
      }
    }

  },
  modules: {
  }
})


import { UserTypes } from "../userTypes";
import { AdDocAPIClient } from "../../backend/AdDocFirebase";
import { adDocFirebase, userFirebase } from "../../backend/firebase";
import { UserAPIClient } from "../../backend/UserFirebase";
import { AdDocumentProjectObject } from '@/engineproject/default/common/domainModel/adDocumentProjectToolsTypes';
import { UserProfileProjectObject } from "../../default/common/domainModel/userProfileProjectToolsTypes";
import { allPublicWithoutEnterprise } from "../enterpriseInfoTypes";


/*
Domain Model, and only Domain Model:
- no vue3.js object or code or concept
- just domain objects AdDocumentProjectObject, UserProjectTypes.UserProfile, ...
- Dependency Inversion Principle (DIP) regarding the lower backend modules (xxxAPIClient)

Design issues:
- some APIClient methods, return a low level object (Firebase Document) in order to
  improve the performances. This Firebase Object is just pass back to the store, and the store
  will give it back.
  => solution : implement a cache in the low level code using the Doc Id. The Firebase Document is not
  returned but moved in the library cache. When the action can use the cached Firebase Doc, the
  low level code should be able to do it!
*/
export class AdDocumentControler  {
  adDocBackendAPI?: AdDocAPIClient;
  userBackendAPI?: UserAPIClient;
  setAPI(aDocAPI: AdDocAPIClient, aUserAPI: UserAPIClient) {
    this.adDocBackendAPI = aDocAPI
    this.userBackendAPI = aUserAPI
  }


  async updateLastModelUsedInProfile(options: { 
    fromMiniModel: UserTypes.MiniAdDocModel;
    aUserProfile: UserProfileProjectObject;
    }) {
    const aUserId = options.aUserProfile.getUPUserId();
    const lastModelsUsed = options.aUserProfile.addOneModelInLastModelsUsed(options.fromMiniModel)
    //console.error("updateLastModelUsedInProfile")

    return await this.userBackendAPI?.updateLastModelUsedInProfile( aUserId, lastModelsUsed)
  }

  async saveAdDoc(options: {aAdDoc: AdDocumentProjectObject; 
    aUserProfile: UserProfileProjectObject; 
    fromMiniModel: UserTypes.MiniAdDocModel; 
    fullLocale: string;
    aInitialDoc: AdDocumentProjectObject | undefined;}) {

    let rForUpdateExistingAdDocUsingDocIdOrCache
    let rForCreateANewAdDoc
    let resultForDetectChangesInDoc

    options.aAdDoc.setDefaultBeforeSaveAdDoc()
    if (options.aAdDoc.isDefinedADId() === true) {
      // console.error("update existing doc using docid or cache")
      rForUpdateExistingAdDocUsingDocIdOrCache = await this.updateExistingAdDocUsingDocIdOrCache({
        aAdDoc: options.aAdDoc, 
        aUserId: options.aUserProfile.getUPUserId()})
        //aUser: options.aUser})
      // console.error("updateExistingAdDocUsingDocIdOrCache result.updatedDoc: " + JSON.stringify(rForUpdateExistingAdDocUsingDocIdOrCache))

      resultForDetectChangesInDoc = await this.detectChangesInDoc(
        //options
        {
          aAdDoc: options.aAdDoc,
          aUserId: options.aUserProfile.getUPUserId(),
          fullLocale: options.fullLocale,
          aInitialDoc: options.aInitialDoc,
        }
      )
    } else {
      // console.error("dispatch createANewDoc")
      rForCreateANewAdDoc = await  this.createANewAdDoc({
        aAdDoc: options.aAdDoc,
        aUserProfile: options.aUserProfile,
        // aUser: options.aUser,
        cleanUpEmptyDoc: false,
        fullLocale: options.fullLocale,
        //notifMsg: "adDocSaved" //"Annonce sauvegardée"
      })
    }
    let rForUpdateLastModelUsedInProfile = undefined
    // rForUpdateLastModelUsedInProfile = await this.cancelCalendarEvent(options, rForRemoveOneAdDocInAdDocs.removedDoc)
    //console.error("updateLastModelUsedInProfile options.fromMiniModel " + JSON.stringify(options.fromMiniModel))

    if (options.fromMiniModel !== undefined && options.fromMiniModel.MADocId !== undefined && options.fromMiniModel.MADocId !== "") {
      rForUpdateLastModelUsedInProfile = await this.updateLastModelUsedInProfile({
        fromMiniModel: options.fromMiniModel,
        aUserProfile: options.aUserProfile,
      })
    }

    return {
      rForCreateANewAdDoc,
      rForUpdateExistingAdDocUsingDocIdOrCache,
      rForUpdateLastModelUsedInProfile,
      resultForDetectChangesInDoc,
    }
  }

  async detectChangesInDoc(options: {aAdDoc: AdDocumentProjectObject; 
      aUserId: string;
      //aUser: UserProjectTypes.UserProfile;
      fullLocale: string; aInitialDoc: AdDocumentProjectObject | undefined;}) {
      // console.error("detectChangesInDoc")
      let rForDetectChangesInDocAndNotifyTransactionsOwners: any
      // ATTENTION aInitialDoc can be undefined, in the case of a creation od doc with images
      /*if (options.aInitialDoc === undefined) {
        return
      }*/
      if ( options.aAdDoc.isAnyTransaction() ) {
        rForDetectChangesInDocAndNotifyTransactionsOwners = await 
          this.detectChangesInDocAndNotifyTransactionsOwners(options);
      }
      const rForDetectDocOwnerCalendarChanges = await this.detectDocOwnerCalendarChanges(
        options);
      return {
        rForDetectChangesInDocAndNotifyTransactionsOwners,
        rForDetectDocOwnerCalendarChanges
      }
  }

  async detectChangesInDocAndNotifyTransactionsOwners(options: {
    aAdDoc: AdDocumentProjectObject; 
    aUserId: string;
    //aUser: UserProjectTypes.UserProfile;
    fullLocale: string; 
    aInitialDoc: AdDocumentProjectObject | undefined;}) {
    // console.error("detectChangesInDocAndNotifyTransactionsOwners")
    if (options.aInitialDoc === undefined) {
      return
    }
    const userIdWithPendingOrAcceptedTransactions = options.aAdDoc.getUserIdWithPendingOrAcceptedTransactions()
    if (userIdWithPendingOrAcceptedTransactions.length <= 0) {
      return
    }
    
    const {docChanges, importantChangesCounter} = options.aAdDoc.detectChangesInProjectDoc(options.aInitialDoc)

    if (Object.keys(docChanges).length > 0) {
      return await this.changesInDoc( {
        // ...options,
        aAdDoc: options.aAdDoc,
        fullLocale: options.fullLocale,
        aUserId: options.aUserId,
        userIdWithPendingOrAcceptedTransactions,
        docChanges,
        importantChangesCounter,
        //appStore: options.appStore
      })
    }
  }

  async changesInDoc(params: {
    aUserId: string; 
    //aUser: any; 
    userIdWithPendingOrAcceptedTransactions: string[]; 
    aAdDoc: AdDocumentProjectObject; docChanges: any;
    importantChangesCounter: any; fullLocale: string;}) {
    console.error("changesInDoc")

    const UNDateHourStartsOn = params.aAdDoc.getADDateHourStartsOn();

    console.error("changesInDoc UNDateHourStartsOn " + JSON.stringify(UNDateHourStartsOn))
    console.error("changesInDoc docChanges " + JSON.stringify(params.docChanges))
    console.error("changesInDoc typeof UNDateHourStartsOn " + (typeof UNDateHourStartsOn))
    //console.error("changesInDoc UNDateHourStartsOn.seconds " + UNDateHourStartsOn.seconds)
    const aUserNotification = {
      // UNOwnerUserId: aUNOwnerUserId,  // The user receiving the notification
      UNFromUserId: params.aUserId, // state.userProfile?.UPUserId,   // The user sending the notification
      // UNFromUserId: params.aUser.UPUserId, // state.userProfile?.UPUserId,   // The user sending the notification
      UNPictureSrcUrl: "",
      UNUrl: params.aAdDoc.getADId(),
      UNText: params.aAdDoc.getADTitle(),
      UNAttachedData: params.docChanges, //JSON.stringify(docChanges),
      UNDateHourStartsOn,
      UNType: "DC", // Document changed
      UNI18N: params.fullLocale,
      UNHasBeenViewed: false
    } as UserTypes.UserNotification
    return await this.userBackendAPI?.addNotificationsList({aUserNotification, 
      userIdWithPendingOrAcceptedTransactions: params.userIdWithPendingOrAcceptedTransactions, 
      importantChangesCounter: params.importantChangesCounter
    })
}

  async detectDocOwnerCalendarChanges(options: {aAdDoc: AdDocumentProjectObject; 
    aUserId: string;
    //aUser: UserProjectTypes.UserProfile;
    fullLocale: string; aInitialDoc: AdDocumentProjectObject | undefined;}) {
      // console.error("detectDocOwnerCalendarChanges")
      const isOwnerCalendarNeedsToBeChanged = (aAdDoc: AdDocumentProjectObject, 
        aInitialDoc: AdDocumentProjectObject | undefined) => {
        return aAdDoc.isAdDoc() &&
        aAdDoc.isPublished() === true && 
        //(aInitialDoc.ADDateHourStartsOn !== aAdDoc.OADData.ADDateHourStartsOn ||
        (aInitialDoc === undefined || aAdDoc.sameDateHourStartsOn(aInitialDoc) === false ||
            aInitialDoc.isPublished() !== true)
      }
      // detect date change to update the owner calendar or
      // publish status change
      if (isOwnerCalendarNeedsToBeChanged(options.aAdDoc, options.aInitialDoc)) {
        // console.error("detectDocOwnerCalendarChanges 1")
        if (options.aInitialDoc !== undefined && options.aInitialDoc.isPublished() === true) {
          // It was already published, so it's an agenda update
          // console.error("detectDocOwnerCalendarChanges 2")
          return this.updateCalendarEvent({
            //...options,
            fullLocale: options.fullLocale,
            aUserId: options.aUserId,
            aUNOwnerUserId: options.aAdDoc.getOwnerUserId(),
            aAdDoc: options.aAdDoc, 
            eventType: "EO", 
            //appStore: options.appStore,
          });
        } else {
          // It was NOT published, so it's an agenda new element
          // console.error("detectDocOwnerCalendarChanges 3")
          return await this.addCalendarEvent({
            //...options,
            aAdDoc: options.aAdDoc, 
            fullLocale: options.fullLocale,
            aUserId: options.aUserId,
                aUNOwnerUserId: options.aAdDoc.getOwnerUserId(),
            eventType: "EO", 
          });
        }
      }
  }
  
  async updateCalendarEvent(params: {
    aUserId: string; 
    //aUser: UserProjectTypes.UserProfile; 
    aUNOwnerUserId: string;
    aAdDoc: AdDocumentProjectObject; fullLocale: string; eventType: string;}) {
    console.error("updateCalendarEvent")
    
    const UEDateHourStartsOn = new Date(params.aAdDoc.getADDateHourStartsOn())
    const aUserCalendarEvent = {
      UEOwnerUserId: params.aUNOwnerUserId,  // The user receiving the notification
      UEFromUserId: params.aUserId,   // The user sending the notification
      //UEFromUserId: params.aUser.UPUserId,   // The user sending the notification
      UEUrl: params.aAdDoc.getADId(),
      UETitle: params.aAdDoc.getADTitle(),
      UEType: params.eventType,
      UEI18N: params.fullLocale,
      UEDateHourStartsOn, 
    } as UserTypes.UserCalendarEvent
    return await this.userBackendAPI?.updateCalendarEvent({aUserCalendarEvent})
  }

  async createANewAdDoc(options: {aAdDoc: AdDocumentProjectObject; 
    aUserProfile: UserProfileProjectObject;
    //    aUser: UserProjectTypes.UserProfile;
    cleanUpEmptyDoc: boolean; 
    fullLocale: string;}) {
      
    let rForAddCalendarEvent = undefined;
    const isEnterpriseDoc = (options.aAdDoc.OADData.ADEnterprise !== undefined && 
        options.aAdDoc.OADData.ADEnterprise.EIEnterpriseId !== allPublicWithoutEnterprise.EIEnterpriseId) 

    const rForSetAdDocUsingAdDocObject = await this.adDocBackendAPI?.createANewAdDoc(
      options.aAdDoc, 
      {
        UPUserId: options.aUserProfile.getUPUserId(),
        UPLocation: options.aUserProfile.getUPLocation(),
        // userNameOrPseudo: options.aUserProfile.getUserNameOrPseudo(),
        // premiumAccount: options.aUserProfile.isPremiumUser(),
        ownerData: options.aUserProfile.getOwnerMiniProfile(isEnterpriseDoc)
      }, 
      //options.aUser, 
      options.cleanUpEmptyDoc)
    if (rForSetAdDocUsingAdDocObject?.addedDoc !== undefined && 
      // aUser !== undefined && userStore !== undefined &&
      options.cleanUpEmptyDoc === false &&
      // result.addedDoc.ADPublishedStatus === true) {
        rForSetAdDocUsingAdDocObject.addedDoc.isAdDoc() === true &&
        rForSetAdDocUsingAdDocObject.addedDoc.isPublished() === true) {
      const optionsCalendar = {
        //...options,
        fullLocale: options.fullLocale,
        aUserId: options.aUserProfile.getUPUserId(),
        aUNOwnerUserId: rForSetAdDocUsingAdDocObject.addedDoc.getOwnerUserId(),  // The owner of the adDoc
        aAdDoc: rForSetAdDocUsingAdDocObject.addedDoc, 
        eventType: "EO", 
      }
      rForAddCalendarEvent = await this.addCalendarEvent(optionsCalendar)
    }

    return {
      rForSetAdDocUsingAdDocObject: {
        ...rForSetAdDocUsingAdDocObject,
        validObj: {
          addedDoc: rForSetAdDocUsingAdDocObject?.addedDoc
        }
      },
      rForAddCalendarEvent
    }
  }

  async updateExistingAdDocUsingDocIdOrCache(options: {
    aAdDoc: AdDocumentProjectObject; 
    aUserId: string;}) {
    //  aUser: UserProjectTypes.UserProfile;}) {
    const rForUpdateOneAdDocInAdDocs = await 
      this.adDocBackendAPI?.updateExistingAdDocUsingDocIdOrCache(
        options.aAdDoc, options.aUserId)
    return {
      rForUpdateOneAdDocInAdDocs: {
        ...rForUpdateOneAdDocInAdDocs,
        validObj: {
          updatedDoc: rForUpdateOneAdDocInAdDocs?.updatedDoc
        }
      }
    }
  }

  async removeAdDoc(options: {aAdDoc: AdDocumentProjectObject; 
    aUserId: string;
    //aUser: UserProjectTypes.UserProfile;
    cleanUpEmptyDoc: boolean;}) {

    let rForCancelCalendarEvent = undefined
    const rForRemoveOneAdDocInAdDocs = await this.adDocBackendAPI?.removeAdDoc(
      options.aAdDoc, options.aUserId)
      //options.aAdDoc, options.aUser)
    if (options.aAdDoc.getADDateHourStartsOn() === undefined) {
      // When the AdDoc has not been published, the date can be empty
      // No cancel calendar event process
      return {
        rForRemoveOneAdDocInAdDocs: rForRemoveOneAdDocInAdDocs,
        rForCancelCalendarEvent: rForCancelCalendarEvent
      }
    }

    if (rForRemoveOneAdDocInAdDocs?.removedDoc !== undefined && 
      //aUser !== undefined && 
      options.cleanUpEmptyDoc !== true) {
      rForCancelCalendarEvent = await this.cancelCalendarEvent(options, rForRemoveOneAdDocInAdDocs.removedDoc)
    }
    return {
      rForRemoveOneAdDocInAdDocs: rForRemoveOneAdDocInAdDocs,
      rForCancelCalendarEvent: rForCancelCalendarEvent
    }
  }

  async likeAdDoc(options: {aAdDoc: AdDocumentProjectObject; 
    aUserProfile: UserProfileProjectObject;
    //aUser: UserProjectTypes.UserProfile;
    }) {
    console.error("likeAdDoc " + options.aUserProfile.getUPUserId())
    const rForLikeAdDoc = await adDocFirebase.likeAdDoc(options.aAdDoc, 
      {
        aPseudo: options.aUserProfile.getUPPseudo(),
        aUserId: options.aUserProfile.getUPUserId()      
      })
      //options.aUserProfile)
      //options.aUser)
    if (rForLikeAdDoc.backendActionDone === true) {
      options.aAdDoc.setADTotLikes(options.aAdDoc.getADTotLikes() + rForLikeAdDoc.addToCounter)
      if (rForLikeAdDoc.addToCounter > 0) {
        options.aAdDoc.addADColLikes({
          ALLPseudo: options.aUserProfile.getUPPseudo(),
          ALLUserId: options.aUserProfile.getUPUserId()
          //ALLPseudo: options.aUser.UPPseudo,
          //ALLUserId: options.aUser.UPUserId
        })
      } else {
        options.aAdDoc.removeADColLikes({
          ALLPseudo: options.aUserProfile.getUPPseudo(),
          ALLUserId: options.aUserProfile.getUPUserId()
          //ALLPseudo: options.aUser.UPPseudo,
          //ALLUserId: options.aUser.UPUserId
        })
      }
    }

    return {
      rForLikeAdDoc: rForLikeAdDoc,
    }
  }

  async removeCommentFromAdDoc(options: {aAdDoc: AdDocumentProjectObject; 
    aUserId: string;
    // aUser: UserProjectTypes.UserProfile; 
    commentId: string;
  }) {
    const rForRemoveCommentFromAdDoc = await adDocFirebase.removeCommentFromAdDoc(options)
    if (rForRemoveCommentFromAdDoc && rForRemoveCommentFromAdDoc.backendActionDone === true) {
      options.aAdDoc.setADTotComments(options.aAdDoc.getADTotComments() - 1)
      options.aAdDoc.removeComment(options.commentId)
    }
    return {
      rForRemoveCommentFromAdDoc
    }
  }

  async addCommentToAdDoc(options: {aAdDoc: AdDocumentProjectObject; 
    aUserProfile: UserProfileProjectObject; 
    //aUser: UserProjectTypes.UserProfile; 
    ACContent: string;
  }) {

    const rForAddCommentToAdDoc = await adDocFirebase.addCommentToAdDoc({
      aAdDoc: options.aAdDoc,
      ACContent: options.ACContent,
      aUserAbstractData: {
        UPUserId: options.aUserProfile.getUPUserId(),
        UPPseudo: options.aUserProfile.getUPPseudo(),
      },
    })
    if (rForAddCommentToAdDoc && rForAddCommentToAdDoc.backendActionDone === true && rForAddCommentToAdDoc.newAddedComment) {
      options.aAdDoc.setADTotComments(options.aAdDoc.getADTotComments() + 1)
      options.aAdDoc.addNewComment(rForAddCommentToAdDoc.newAddedComment)
    }
    return {
      rForAddCommentToAdDoc
    }
  }

  /*
  If the doc has been removed but calendar event have not been correctly updated
  The calendar event cannot be removed but the doc is no more there.
  To avoid this situation, when a removed doc is found, the calendar events are cancelled (if any).
  */
  async cancelCalendarEventForAlreadyRemovedDoc(options: {aAdDocId: string; 
    aUserId: string;}) {
    //  aUser: UserProjectTypes.UserProfile;}) {
        const aAdDoc= {
        ADId: options.aAdDocId,
        ADTitle: "",
        ADDescription: "",
        ADLocation: {},
        ADColAttDoc:[],
        ADUnivers:"",
        ADCatsInUnivers:""
      } as any
    
      const dummyDoc = new AdDocumentProjectObject(aAdDoc)
      return await this.cancelCalendarEvent(options, dummyDoc)

  }

  async cancelCalendarEvent(options: { 
    aUserId: string;
    //aUser: UserProjectTypes.UserProfile;
    }, removedDoc: AdDocumentProjectObject) {
    const UEDateHourStartsOn = new Date(removedDoc.getADDateHourStartsOn())

    const aUserCalendarEvent = {
      UEDateHourStartsOn,
      UEOwnerUserId: removedDoc.getOwnerUserId(),  // The owner of the adDoc  // The user receiving the notification
      UEFromUserId: options.aUserId,   // The user sending the notification
      // UEFromUserId: options.aUser.UPUserId,   // The user sending the notification
      UEUrl: removedDoc.getADId(),
      UEType: "",
    } as UserTypes.UserCalendarEvent
    return await this.userBackendAPI?.cancelCalendarEvent({aUserCalendarEvent})
  }

  async addCalendarEvent(options: {aAdDoc: AdDocumentProjectObject; 
    aUserId: string;
    //aUser: UserProjectTypes.UserProfile;
    aUNOwnerUserId: string; 
    fullLocale: string; 
    eventType: string;}) {

    console.error("addCalendarEvent")
    const UEDateHourStartsOn = new Date(options.aAdDoc.getADDateHourStartsOn())
    const aUserCalendarEvent = {
      UEOwnerUserId: options.aUNOwnerUserId,  // The user receiving the notification
      UEFromUserId: options.aUserId,   // The user sending the notification
      //UEFromUserId: options.aUser.UPUserId,   // The user sending the notification
      UEUrl: options.aAdDoc.getADId(),
      UETitle: options.aAdDoc.getADTitle(),
      UEType: options.eventType,
      UEI18N: options.fullLocale,
      UEDateHourStartsOn, 
    } as UserTypes.UserCalendarEvent
    return await this.userBackendAPI?.addCalendarEvent({aUserCalendarEvent})

  }
}

// console.error("adDocFirebase in adDocumentToolsTypes 1 " + adDocFirebase)


export const adDocumentControler = new AdDocumentControler()


import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["data-cy"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "toastTextContainer",
    "data-cy": 'cyToastErrorID' + $props.toastIdOrText
  }, [
    _createTextVNode(_toDisplayString($props.toastText) + " ", 1),
    ($props.toastLinkText !== '')
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "toastLinkText",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.linkClicked && $setup.linkClicked(...args)))
        }, _toDisplayString($props.toastLinkText), 1))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}
<template>
  <section>
    <div class="appLoading">
      <div>{{ t('appLoginText') }}</div>
      <div class="loginLink">
        <a @click="logout();">{{ t("loginLink") }}</a>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { toRefs, reactive, computed, watch, onMounted } from "vue";

import { useAppStore } from "@/engineproject/store/appStore";
import { useI18n } from '@/projects/alomigo/common/i18n';
import { useUserStore } from '@/engineproject/store/userStore';

export default {
  setup() {
    const userStore = useUserStore();
    const appStore = useAppStore();
    const { t  } = useI18n()
    const page = reactive({
      aUserProfile: computed(() => userStore.state.aUserProfile),
    });
    const logout = () => {
      userStore.dispatch('logout', {appStore})
    }

    return {
      ...toRefs(page),
    // eslint-disable-next-line 
    // @ts-ignore
    t,
      logout,
    };
  },
  components: { 
  },
};
</script>


const mapToObjectId = (anArray: any[]) => {
    return anArray.map((ele) => {
      return {id: ele}
    })
  }

const getDefaultUrlForUnivers = (defUrlsObj: any, univers: string, catInUnivers: string) => {
  let retDefaultUrlForUnivers;
  // console.error("defUrlsObj " + JSON.stringify(defUrlsObj))
  if (defUrlsObj && defUrlsObj[univers] && defUrlsObj[univers][catInUnivers] &&
    defUrlsObj[univers][catInUnivers].length > 0) {
    const len = defUrlsObj[univers][catInUnivers].length - 1
    const rI = Math.round(Math.random() * len)
    // console.error("rI " + rI + " len " + len)
    retDefaultUrlForUnivers = defUrlsObj[univers][catInUnivers][ rI ]
  } else if (defUrlsObj && defUrlsObj[univers] && defUrlsObj[univers]["default"] &&
    defUrlsObj[univers]["default"].length > 0) {
      const len = defUrlsObj[univers]["default"].length - 1
      const rI = Math.round(Math.random() * len)
      // console.error("rI " + rI + " len " + len)
      retDefaultUrlForUnivers = defUrlsObj[univers]["default"][ rI ]
  }
  return retDefaultUrlForUnivers
}

const getStrategiesToFindBestModels = (def: {
    defaultUnivers: string;
    defaultCatInUnivers: string;
  }, optionsQuery: any) => {
  const strategiesToFindBestModelsWithoutPrefs = [
    { ...optionsQuery,
      currentFilter: "myAdDocs",
      adDocFileType: "T",
    },
    {
      ...optionsQuery,
      "templateFilter": true,
    }
  ]
  let strategiesToFindBestModels = []
  if (def.defaultCatInUnivers !== "") {
    strategiesToFindBestModels.push({
      ...strategiesToFindBestModelsWithoutPrefs[0],
      universAndCatFilter:{
        ADUnivers:[def.defaultUnivers],
        ADCatsInUnivers:[def.defaultCatInUnivers]
      }
    })
    strategiesToFindBestModels.push({
      ...strategiesToFindBestModelsWithoutPrefs[0],
      universAndCatFilter:{
        ADUnivers:[def.defaultUnivers]
      }
    })
    strategiesToFindBestModels.push({
      ...strategiesToFindBestModelsWithoutPrefs[1],
      universAndCatFilter:{
        ADUnivers:[def.defaultUnivers],
        ADCatsInUnivers:[def.defaultCatInUnivers]
      }
    })
    strategiesToFindBestModels.push({
      ...strategiesToFindBestModelsWithoutPrefs[1],
      universAndCatFilter:{
        ADUnivers:[def.defaultUnivers]
      }
    })
    strategiesToFindBestModels.push({
      ...strategiesToFindBestModelsWithoutPrefs[0],
    })
    strategiesToFindBestModels.push({
      ...strategiesToFindBestModelsWithoutPrefs[1],
    })
  } else if (def.defaultUnivers !== "") {
    strategiesToFindBestModels.push({
      ...strategiesToFindBestModelsWithoutPrefs[0],
      universAndCatFilter:{
        ADUnivers:[def.defaultUnivers]
      }
    })
    strategiesToFindBestModels.push({
      ...strategiesToFindBestModelsWithoutPrefs[1],
      universAndCatFilter:{
        ADUnivers:[def.defaultUnivers]
      }
    })
    strategiesToFindBestModels.push({
      ...strategiesToFindBestModelsWithoutPrefs[0],
    })
    strategiesToFindBestModels.push({
      ...strategiesToFindBestModelsWithoutPrefs[1],
    })
  } else {
    strategiesToFindBestModels = strategiesToFindBestModelsWithoutPrefs
  }
  return strategiesToFindBestModels
}

const du = (firebaseProjectConfig: any) => {
  return firebaseProjectConfig.storageBucketBaseUrl + firebaseProjectConfig.storageBucket + 
  firebaseProjectConfig.storageBucketSuffix +"DefaultImages%2F"
}

const wikimediaSubDir = "%2Fwikimedia.org%2F"

export {
    mapToObjectId,
    getDefaultUrlForUnivers,
    getStrategiesToFindBestModels,
    du,
    wikimediaSubDir
}




export class DateUndefined extends Date {
    constructor() {
        super(2166, 9, 13);
    }
    isUndefined(inputDate: any) {
        return ((inputDate && inputDate.toDateString && inputDate.toDateString() === this.toDateString()) ||
            (inputDate && inputDate.toDate && inputDate.toDate().toDateString() === this.toDateString()))
    }
  
  }
  
  export const ADDateHourStartsOnNotRequiredAndNotDefined = new DateUndefined()
